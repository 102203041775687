import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { BDM_REPORT_GET } from "../../constants";
import axios from "axios";
import FilterComponent from "./FilterComponent";
import dayjs from "dayjs";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import for the filter icon
import DataDisplayCard from "./DataDisplayCard";
import PivotTable from "./PivotTable";
import Loader from "../reusable Component/Loader";
import { Link, useLocation, useParams } from "react-router-dom";
import DetailsFilterComponent from "./DetailsFilter";

function BdmDetailsComponent({}) {
  const [loading, setLoading] = useState(false);

  const { bdm_id } = useParams();

  const location = useLocation();
  const { prevfilters } = location.state || {}; // Access filters from the state

  const [bdmStatisticsList, setBdmStatisticsList] = useState([]);
  const [filteredBdmList, setFilteredBdmList] = useState([]);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    fetchBdmReportData();
  }, [filters]); // Fetch data whenever filters change

  useEffect(() => {
    if (prevfilters) {
      setFilters(prevfilters); // Initialize filters with prevfilters if available
    }
  }, [prevfilters]);

  const fetchBdmReportData = () => {
    const { startDate, endDate } = filters; // Destructure filters
    setLoading(true);
    // Convert dates to YYYY-MM-DD format using dayjs
    const formattedStartDate = startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("startDate", formattedStartDate);
    if (endDate) queryParams.append("endDate", formattedEndDate);
    if (bdm_id) queryParams.append("bdmIds", bdm_id); // Use bdm_id from URL params

    axios
      .get(`${BDM_REPORT_GET}?${queryParams.toString()}`)
      .then((response) => {
        const { data } = response.data;
        setBdmStatisticsList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [filterShow, setFilterShow] = useState(false);

  return (
    <>
      {loading && <Loader open={loading} />}
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link underline="hover" key="1" color="inherit" to="/bdmReport">
          BDM Report
        </Link>
        <Typography key="2" color="primary">
          Details
        </Typography>
      </Breadcrumbs>
      {/* Container with flex layout to align items */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/* IconButton aligned to the right */}
        <IconButton
          onClick={() => setFilterShow((prev) => !prev)}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "40px", // Increased width for a more comfortable click area
            height: "40px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Box>

      {filterShow && (
        <DetailsFilterComponent
          filters={filters}
          setFilters={setFilters}
          bdmStatisticsList={bdmStatisticsList}
        />
      )}

      <DataDisplayCard bdmStatisticsList={bdmStatisticsList}></DataDisplayCard>
    </>
  );
}

export default BdmDetailsComponent;
