import * as React from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { MenuItem, FormControl, Select , useMediaQuery, useTheme, IconButton} from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ModalComponent from '../reusable Component/modalComponent';
import CallIcon from '@mui/icons-material/Call';

function CallLogTable({setTableMobile}) {
  const [rowDetails, setRowDetails] = useState([]);
    const rows = [
        { id: 1, 
            // col1: 'C002', 
            c_name: 'Shikhar Lohiya', 
            m_num: '7723037733',
            w_num: '-',
            a_num: '-',
            mail_id: 'abc@example.com',
            pincode: '493001',
            region: 'Region1',
            state: 'Chhattisgarh',
            location: 'Location1',
            lead_transfer_date: '12-04-2024',
            lead_owner: 'Rajiv Singh',
            lead_source: 'Enquiry By Call',
            remark: 'Assigned to BDM',
            campaign: 'Campaign1',
            category: ['Hot'],
            follow_up_date: '12-04-2024',
            status: ['Connected'],
            direction: 'Inbound'
         },
         { id: 2, 
            // col1: 'C002', 
            c_name: 'Anshita Gupta', 
            m_num: '7974136059',
            w_num: '-',
            a_num: '-',
            mail_id: 'abcd@example.com',
            pincode: '493001',
            region: 'Region1',
            state: 'Chhattisgarh',
            location: 'Location1',
            lead_transfer_date: '12-04-2024',
            lead_owner: 'Gaurav Singh',
            lead_source: 'Enquiry By Call',
            remark: 'Assigned to BDM',
            campaign: 'Campaign1',
            category: ['Warm'],
            follow_up_date: '12-04-2024',
            status: ['Not Connected'],
            direction: 'Outbound'
         },

         
      ];
    const columns = [
    // { field: 'col1', headerName: 'Customer ID', width: 120, type: 'string',  headerAlign: 'center', cellClassName: 'super-app-theme--cell' , headerClassName: 'super-app-theme--header', responsive: true},
    { field: 'c_name', headerName: 'Customer Name', flex: 1 , type: 'string',  headerAlign: 'center', responsive: true, cellClassName: 'super-app-theme--cell'},
    { field: 'm_num', headerName: 'Customer Number', flex: 1 , type: 'number',  headerAlign: 'center', responsive: true},
    { field: 'state', headerName: 'State', flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
    { field: 'region', headerName: 'Region', flex: 1, type: 'string',  headerAlign: 'center', responsive: true},
    { field: 'follow_up_date', headerName: 'Follow-up Date', flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
    { field: 'direction', headerName: 'Direction', flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
    { field: 'status', headerName: 'Status', flex: 1, headerAlign: 'center',
         renderCell: (params) => (
        <div>
          {params.value.map((tag, index) => (
            <span
              key={index}
              style={{
                display: 'inline',
                border: '1px solid',
                borderColor: getColorForTag(tag), // Function to determine tag color
                color: getColorForTag(tag),
                padding: '2px 8px',
                borderRadius: '4px',
                margin: '0 4px',
              }}
            >
              {tag}
            </span>
          ))}
        </div>
      ), },
    { field: 'lead_owner', headerName: 'Lead Shared By', flex: 1 , type: 'string',  headerAlign: 'center',  responsive: true},
    { field: 'action', headerName: 'Action', flex: 1 , headerAlign: 'center', responsive: true, sortable: false,
        renderCell: (params) => (
        <IconButton color='primary' onClick={(e)=> {setTableMobile(params.row.m_num)}}>
            <CallIcon />
        </IconButton>
      ),} 
    ];

    const getColorForTag = (tag) => {
        switch (tag) {
          case 'Connected':
            return '#17C05B'; // React blue
          case 'Not Connected':
            return '#f0db4f'; // JavaScript yellow
          case 'Missed':
            return 'red'; // UI dark blue
          default:
            return '#888'; // Default gray
        }
      };

    const ActionSelect = ({ value, onChange, children}) => (
    <FormControl variant="standard" style={{ width: '100%' }}>
        <Select value={value} onChange={onChange} label="Action" className={`mt-1`}>
        {children}
        </Select>
    </FormControl>
    );


      const [open, setOpen] = useState(false);
      const theme = useTheme();
    
return (
      <>
      <Box sx={{
        height: 'auto',
        width: '100%',
        maxWidth: '100vw',
        '& .super-app-theme--cell': {
          textAlign: 'left',
        },
        overflowX: 'hidden'
      }}>
      <DataGrid rows={rows} columns={columns} columnHeaderHeight={50}  
      rowHeight={50}
      autoHeight
      disableExtendRowFullWidth
      // hideFooterPagination={theme.breakpoints.down('md')}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      disableRowSelectionOnClick
      disableDensitySelector
      disableColumnSelector
      checkboxSelection
      sx={{
        '& .MuiDataGrid-cell': {
          textAlign: 'center',
        },
        '& .MuiDataGrid-columnHeader':{
            backgroundColor: '#EEF0F4'
        }
      }} />
    </Box>
    {/* <ModalComponent open={open} modalTitle={modalTitle} setOpen={setOpen} rowDetails={rowDetails}></ModalComponent> */}
    </>
);
}

export default CallLogTable;