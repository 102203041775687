import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LongTextComponent from "../Leads Page/LongTextComponent";
import axios from "axios";
import {
  GET_DETAILS_BY_MOBILE_NUMBER,
  GET_ENQUIRY_DATA_BY_AGENT,
  GET_ENQUIRY_DATE_BY_AGENT,
  GET_TIMELINE,
} from "../../constants";
import CallIcon from "@mui/icons-material/Call";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import FilterPanel from "../Leads Page/FilterPanel";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function EnquiryListTable({}) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0); // Material-UI's page index starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(10); // pageSize from API
  const [totalCount, setTotalCount] = useState(0); // totalCount from API

  const [enquiryList, setEnquiryList] = useState([]);

  const fetchEnquiryList = () => {
    const empId = localStorage.getItem("username");
    const currentPage = page + 1; // Convert zero-based index to one-based for the API
    axios
      .get(
        `${GET_ENQUIRY_DATA_BY_AGENT}/${empId}?page=${currentPage}&pageSize=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const { data, totalCount } = response.data;
        handleRowData(data);
        setTotalCount(totalCount);
        // setLoading(false);
      })
      .catch((error) => {
        //   setSeverity('error');
        //   setMessage(error.response.data.message);
        //   setOpenSnackbar(true);
        //   setLoading(false);
      });
  };

  // Handle page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Handle rowsPerPage (pageSize) change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when pageSize changes
  };

  // Fetch data whenever page or rowsPerPage changes
  useEffect(() => {
    fetchEnquiryList();
  }, [page, rowsPerPage]);

  const handleRowData = (data, type) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        ApproachableRoad: rd.ApproachableRoad || "-",
        ContactNumber: rd.ContactNumber || "-",
        CurrentBirdCapacity: rd.CurrentBirdCapacity || "-",
        CurrentShedDirection: rd.CurrentShedDirection || "-",
        CustomerMailId: rd.CustomerMailId || "-",
        CustomerName: rd.CustomerName || "-",
        EC_Shed_Plan: rd.EC_Shed_Plan || "-",
        Electricity: rd.Electricity || "-",
        ElectricityPhase: rd.ElectricityPhase || "-",
        IntegrationCompany: rd.IntegrationCompany || "-",
        Investment_Budget: rd.Investment_Budget || "-",
        LandAvailable: rd.LandAvailable || "-",
        Land_Size: rd.Land_Size || "-",
        NUmberOfShed: rd.NUmberOfShed || "-",
        Project: rd.Project || "-",
        Remark: rd.Remark || "-",
        ShedSize: rd.ShedSize || "-",
        Source: rd.Source || "-",
        StateName: rd.StateName || "-",
        Unit: rd.Unit || "-",
        WaterAvailabilty: rd.WaterAvailabilty || "-",
        WhatsAppNumber: rd.WhatsAppNumber || "-",
        createdAt: formatDate(rd.createdAt) || "-",
        location: rd.location || "-",
        otherLocation: rd.otherLocation || "-",
        pincode: rd.pincode || "-",
        sourceDetails: rd.sourceDetails || [],
        updatedAt: formatDate(rd.updatedAt) || "-",
      };
      // console.log(a)
      transformedRows.push(a);
    });
    setEnquiryList(transformedRows);
  };

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "hot":
        return "error"; // React blue
      case "warm":
        return "warning"; // JavaScript yellow
      case "cold":
        return "info"; // UI dark blue
      case "pending":
        return "warning";
      default:
        return "default"; // Default gray
    }
  };

  const theme = useTheme();
  const isXs = useMediaQuery("(max-width:600px)"); // 0px - 600px
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // 600px - 900px
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // 900px - 1200px
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl")); // 1200px - 1536px
  const isXl = useMediaQuery(theme.breakpoints.up("xl")); // 1536px and up

  const getHeight = () => {
    if (isXs) return 400;
    if (isSm) return 450;
    if (isMd) return 500;
    if (isLg) return 500;
    if (isXl) return "100vh";
    return "auto";
  };

  const IconCell = ({ row }) => (
    <>
      {/* <Tooltip title="Timeline" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handletimelineClick(row)}
        >
          <VisibilityIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip> */}

      <Tooltip title="Call" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handleCallIconClick(row)}
        >
          <CallIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Edit" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handleIconClick(row)}
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip> */}
    </>
  );

  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineLogs, setTimelineLogs] = useState([]);
  const [custData, setCustData] = useState([]);

  const handletimelineClick = (row) => {
    setTimelineOpen(true);
    setCustData(row);
    axios
      .get(`${GET_TIMELINE}/${row.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadLogs } = response.data;
        setTimelineLogs(leadLogs);
      })
      .catch((error) => {});
  };

  const handleCallIconClick = (row) => {
    navigate("/dashboard", {
      state: {
        mobNo: row.ContactNumber,
        lotNum: row.MobileNo,
      },
    });
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const handleIconClick = (row) => {
    axios
      .get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${row.MobileNo}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { lead } = response.data;
        // Pass the data to handlingRowData
        handlingRowData(lead);
      })
      .catch((error) => {
        console.error("Error fetching lead details:", error);
      });
  };

  const handlingRowData = (data) => {
    if (!data || data.length === 0) {
      console.warn("No lead data available to transform.");
      return;
    }
    const transformedRows = [data].map((rd, index) => ({
      id: index + 1,
      SNo: index + 1,
      leadId: rd.id,
      CustomerName: rd.CustomerName?.trim() || "-",
      MobileNo: rd.MobileNo || "-",
      AlternateMobileNo: rd.AlternateMobileNo || "-",
      state_name: rd.state_name || "-",
      source_of_lead_generated: rd.Campaign?.CampaignName || "-",
      category: rd.category || "-",
      AgentId: rd.AgentId,
      BDMId: rd.BDMId,
      CustomerMailId: rd.CustomerMailId || "-",
      InquiryType: rd.InquiryType || "-",
      Project: rd.Project || "-",
      Superviser: rd.Superviser,
      SuperviserID: rd.SuperviserID,
      WhatsappNo: rd.WhatsappNo || "-",
      agent_remark: rd.agent_remark || "-",
      close_month: rd.close_month || "-",
      follow_up_date: rd.follow_up_date ? formatDate(rd.follow_up_date) : "-",
      lead_owner: rd.BDM?.EmployeeName || "-",
      lead_transfer_date: rd.lead_transfer_date
        ? formatDate(rd.lead_transfer_date)
        : "-",
      location: rd.location || "-",
      site_location_address: rd.site_location_address || "-",
      region_name: rd.region_name || "-",
      sub_category: rd.sub_category || "-",
      Agent: rd.Agent,
      AgentName: rd.Agent?.EmployeeName || "-",
      BDM: rd.BDM,
      pincode: rd.pincode || "-",
      call_status: rd.call_status,
      call_type: rd.call_type,
      createdDate: rd.createdAt ? formatDate(rd.createdAt) : "-",
      bdm_remark: rd.bdm_remark || "-",
      lead_calling_date: "-",
      bdm_calling_date: "-",
      updatedAt: rd.updatedAt ? formatDate(rd.updatedAt) : "-",
      last_action: rd.last_action || "-",
    }));

    // Store transformed data in localStorage
    localStorage.setItem("leadDetails", JSON.stringify(transformedRows[0]));

    setTimeout(() => navigate("/leadDetails"), 100);
  };

  const [filters, setFilters] = useState({
    InquiryType: "",
    Project: "",
    CustomerName: "",
    MobileNo: "",
    region: "",
    category: "",
    subcategory: "",
    campaignName: "",
    search: "",
  });

  const handleApplyFilters = (newFilters) => {};

  const handleExport = () => {
    // Map the data into an array of objects for Excel export
    const exportData = enquiryList.map((row, index) => ({
      SNo: page * rowsPerPage + index + 1,
      "Enquiry Date": row.createdAt || "-",
      "Customer Name": row.CustomerName || "-",
      "Mobile Number": row.ContactNumber || "-",
      Pincode: row.pincode || "-",
      State: row.StateName || "-",
      Location: row.location || "-",
      "Mail ID": row.CustomerMailId || "-",
      "Shed Plan": row.EC_Shed_Plan || "-",
      "Land Available?": row.LandAvailable === true ? "Yes" : "No" || "-",
      "Land Size (LxW)": row.Land_Size || "-",
      Unit: row.Unit || "-",
      Electricity: row.Electricity || "-",
      "Water Availability": row.WaterAvailabilty === true ? "Yes" : "No" || "-",
      "Approachable Road": row.ApproachableRoad === true ? "Yes" : "No" || "-",
      "Integration Company": row.IntegrationCompany || "-",
      "Shed Size (LxW)": row.ShedSize || "-",
      "Current Shed Direction": row.CurrentShedDirection || "-",
      "Electricity Phase": row.ElectricityPhase || "-",
      "Current Bird Capacity": row.CurrentBirdCapacity || "-",
      "Investment Budget": row.Investment_Budget || "-",
      "No of Shed": row.NUmberOfShed || "-",
      Source: row.Source || "-",
      Remark: row.Remark || "-",
    }));

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enquiry List");

    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, "EnquiryListData.xlsx");
  };

  return (
    <>
      <Paper>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              backgroundColor: "#f5f5f5", // Grey background
              padding: "8px 16px", // Add some padding for spacing
              borderRadius: "8px", // Rounded corners
              border: "1px solid #ccc", // Light border color
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Elevated box shadow
              display: "inline-block", // Ensure it takes only as much space as needed
              marginBottom: "4px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold", // Make the text bold
                fontSize: "1.2rem", // Increase font size for prominence
                color: "#333", // Set a darker color for better visibility
              }}
            >
              Total Leads: {totalCount}
            </Typography>
          </Box>

          {/* Export Button */}
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleExport}
            style={{
              border: "1px solid #fff", // Change border color to white for contrast
              borderRadius: "4px",
              margin: "5px",
              width: "40px", // Increased width for a more comfortable click area
              height: "40px", // Increased height for a more comfortable click area
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#097969",
              color: "#fff",
              transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
              e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#097969"; // Original color
              e.currentTarget.style.transform = "scale(1)"; // Reset scale
            }}
          >
            <FileDownloadIcon />
          </IconButton>
        </div>
        {/* <FilterPanel
          filters={filters}
          setFilters={setFilters}
          onApplyFilters={handleApplyFilters}
        /> */}

        <TableContainer
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            // margin: "16px 0",
            height: enquiryList.length > 10 ? getHeight() : "auto",
          }}
        >
          <Table
            sx={{
              minWidth: 600,
              tableLayout: "fixed",
              border: "1px solid #e0e0e0",
              "& .MuiTableHead-root": {
                backgroundColor: "#f5f5f5",
              },
              "& .MuiTableCell-root": {
                fontSize: "0.875rem",
                textAlign: "center",
              },
            }}
            aria-label="fancy table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  width={50}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Enquiry Date
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Pincode
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  State
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Location
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Mail ID
                </TableCell>
                <TableCell
                  width={200}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Shed Plan
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Land Available?
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Land Size (LxW)
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Electricity
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Water Availability
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Approachable Road
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Integration Company
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Shed Size (LxW)
                </TableCell>
                <TableCell
                  width={180}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Current Shed Direction
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Electricity Phase
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Current Bird Capacity
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Investment Budget
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  No of Shed
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Source
                </TableCell>
                <TableCell
                  width={180}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Remark
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "80px",
                    position: "sticky", // Make the cell sticky
                    right: 0, // Fix to the right side
                    backgroundColor: "#f5f5f5", // Set background color for visibility
                    zIndex: 100, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    top: 0,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enquiryList.length > 0 ? (
                enquiryList.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                      "&:hover": { backgroundColor: "#e8f0fe" },
                      transition: "background-color 0.3s",
                    }}
                  >
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{row.createdAt || "-"}</TableCell>
                    <TableCell>{row.CustomerName || "-"}</TableCell>
                    <TableCell>{row.ContactNumber || "-"}</TableCell>
                    <TableCell>{row.pincode || "-"}</TableCell>
                    <TableCell>{row.StateName || "-"}</TableCell>
                    <TableCell
                      sx={{
                        wordWrap: "break-word",
                        maxWidth: "200px",
                        whiteSpace: "normal",
                      }}
                    >
                      {row.location || "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        wordWrap: "break-word",
                        maxWidth: "200px",
                        whiteSpace: "normal",
                      }}
                    >
                      {row.CustomerMailId || "-"}
                    </TableCell>
                    <TableCell>{row.EC_Shed_Plan || "-"}</TableCell>
                    <TableCell>
                      {row.LandAvailable === true ? "Yes" : "No" || "-"}
                    </TableCell>
                    <TableCell>{row.Land_Size || "-"}</TableCell>
                    <TableCell>{row.Unit || "-"}</TableCell>
                    <TableCell>{row.Electricity || "-"}</TableCell>
                    <TableCell>
                      {row.WaterAvailabilty === true ? "Yes" : "No" || "-"}
                    </TableCell>
                    <TableCell>
                      {row.ApproachableRoad === true ? "Yes" : "No" || "-"}
                    </TableCell>
                    <TableCell>{row.IntegrationCompany || "-"}</TableCell>
                    <TableCell>{row.ShedSize || "-"}</TableCell>
                    <TableCell>{row.CurrentShedDirection || "-"}</TableCell>
                    <TableCell>{row.ElectricityPhase || "-"}</TableCell>
                    <TableCell>{row.CurrentBirdCapacity || "-"}</TableCell>
                    <TableCell>{row.Investment_Budget || "-"}</TableCell>
                    <TableCell>{row.NUmberOfShed || "-"}</TableCell>
                    <TableCell>{row.Source || "-"}</TableCell>
                    <TableCell>
                      <LongTextComponent
                        text={row.Remark || "-"}
                        maxLength={30}
                      ></LongTextComponent>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "80px",
                        padding: 1,
                        position: "sticky", // Make the cell sticky
                        right: 0, // Fix to the right side
                        backgroundColor: "#f5f5f5", // Set background color for visibility
                        zIndex: 10, // Higher z-index to stay above other cells
                        boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Add a shadow to the column
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          // marginRight: "5px",
                        }}
                      >
                        <IconCell row={row} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={24}
                    sx={{ textAlign: "left", padding: "16px" }}
                  >
                    No Data Available Yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount} // Use totalCount from API
          rowsPerPage={rowsPerPage}
          page={page} // Controlled page state
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default EnquiryListTable;
