import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import WavesIcon from "@mui/icons-material/Waves";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LockIcon from "@mui/icons-material/Lock";
import { GET_LEAD_BY_AGENT_API, PENDING_AND_COLD_LEAD } from "../../constants";
import axios from "axios";
import { Alert, AlertTitle, Button, Snackbar, Stack } from "@mui/material";
import LeadTable from "./LeadTable";
import WarmLeadTable from "./WarmLeadTable";
import ColdLeadTable from "./ColdLeadTable";
import PendingLeadTable from "./PendingLeadTable";
import ClosedLeadTable from "./ClosedLeadTable";
import AllLeadTable from "./AllLeadTable";
import Loader from "../reusable Component/Loader";
import ModalComponent from "../reusable Component/modalComponent";
import moment from "moment";
import HotLeadTable from "./HotLeadTable";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function LeadTabComponent() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");

  const [loading, setLoading] = useState(false);

  const [runEffect, setRunEffect] = useState(false);

  useEffect(() => {
    setLoading(true);
    const empId = localStorage.getItem("username");
    const requestBody = {};
    axios
      .get(`${GET_LEAD_BY_AGENT_API}/${empId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads } = response.data;
        filterHotLeadData(leads);
        filterWarmLeadData(leads);
        filterAllLeadData(leads);
        filterColdLeadData(leads);
        filterPendingLeadData(leads);
        filterClosedLeadData(leads);
        // fetchPendingandColdData();
        setLoading(false);
      })
      .catch((error) => {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenSnackbar(true);
        setLoading(false);
      });
  }, [runEffect]);

  const fetchPendingandColdData = () => {
    const empId = localStorage.getItem("username");
    axios
      .get(`${PENDING_AND_COLD_LEAD}/${empId}/pending-cold`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads } = response.data;
        filterColdLeadData(leads);
        filterPendingLeadData(leads);
        filterClosedLeadData(leads);
      })
      .catch((error) => {});
  };

  const [hotLeadRowData, setHotLeadRowData] = useState([]);
  const [warmLeadRowData, setWarmLeadRowData] = useState([]);
  const [coldLeadRowData, setColdRowLeadData] = useState([]);
  const [pendingLeadRowData, setPendingLeadRowData] = useState([]);
  const [closedLeadRowData, setClosedLeadRowData] = useState([]);
  const [allLeadRowData, setAllLeadRowData] = useState([]);

  const [hotLeadCount, setHotCountLead] = useState(0);
  const [warmLeadCount, setWarmCountLead] = useState(0);
  const [coldLeadCount, setColdCountLead] = useState(0);
  const [pendingLeadCount, setPendingCountLead] = useState(0);
  const [closedLeadCount, setClosedCountLead] = useState(0);
  const [allLeadCount, setAllLeadCount] = useState(0);

  const filterHotLeadData = (data) => {
    // Filter the data to include only those records where status is 'hot'
    const filteredData = data.filter(
      (rd) => rd.category === "hot" || rd.category === "Hot"
    );
    handlingRowData(filteredData, "hot");
    setHotCountLead(filteredData.length);
  };

  const filterWarmLeadData = (data) => {
    // Filter the data to include only those records where status is 'warm'
    const filteredData = data.filter(
      (rd) => rd.category === "warm" || rd.category === "Warm"
    );
    handlingRowData(filteredData, "warm");
    setWarmCountLead(filteredData.length);
  };

  const filterColdLeadData = (data) => {
    // Filter the data to include only those records where status is 'cold'
    const filteredData = data.filter(
      (rd) => rd.category === "cold" || rd.category === "Cold"
    );
    handlingRowData(filteredData, "cold");
    setColdCountLead(filteredData.length);
  };

  const filterPendingLeadData = (data) => {
    // Filter the data to include only those records where status is 'Pending'
    const filteredData = data.filter(
      (rd) => rd.category === "pending" || rd.category === "Pending"
    );

    handlingRowData(filteredData, "pending");
    setPendingCountLead(filteredData.length);
  };

  const filterClosedLeadData = (data) => {
    // Filter the data to include only those records where status is 'closed'
    const filteredData = data.filter(
      (rd) => rd.category === "closed" || rd.category === "Closed"
    );
    handlingRowData(filteredData, "closed");
    setClosedCountLead(filteredData.length);
  };

  const filterAllLeadData = (data) => {
    handlingRowData(data);
    setAllLeadCount(data.length);
  };

  const handlingRowData = (data, type) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        // "SNo": index + 1,
        leadId: rd.id,
        CustomerName: rd.CustomerName == "" ? "-" : rd.CustomerName,
        MobileNo: rd.MobileNo == "" || rd.MobileNo === null ? "-" : rd.MobileNo,
        AlternateMobileNo:
          rd.AlternateMobileNo == "" || rd.AlternateMobileNo === null
            ? "-"
            : rd.AlternateMobileNo,
        state_name:
          rd.state_name == "" || rd.state_name === null ? "-" : rd.state_name,
        source_of_lead_generated:
          rd.Campaign == null || rd.Campaign === ""
            ? "-"
            : rd.Campaign.CampaignName,
        category: rd.category == "" || rd.category === null ? "-" : rd.category,
        AgentId: rd.AgentId,
        BDMId: rd.BDMId,
        CustomerMailId:
          rd.CustomerMailId == "" || rd.CustomerMailId === null
            ? "-"
            : rd.CustomerMailId,
        InquiryType: rd.InquiryType,
        Project: rd.Project,
        Superviser: rd.Superviser,
        SuperviserID: rd.SuperviserID,
        WhatsappNo:
          rd.WhatsappNo == "" || rd.WhatsappNo == null ? "-" : rd.WhatsappNo,
        agent_remark:
          rd.agent_remark == "" || rd.agent_remark == null
            ? "-"
            : rd.agent_remark,
        close_month:
          rd.close_month == "" || rd.close_month === null
            ? "-"
            : rd.close_month,
        follow_up_date:
          rd.follow_up_date == "" || rd.follow_up_date === null
            ? "-"
            : formatDate(rd.follow_up_date),
        lead_owner: rd.BDM == null ? "-" : rd.BDM.EmployeeName,
        lead_transfer_date:
          rd.lead_transfer_date == null || rd.lead_transfer_date == ""
            ? "-"
            : formatDate(rd.lead_transfer_date),
        location: rd.location == "" || rd.location == null ? "-" : rd.location,
        site_location_address:
          rd.site_location_address == "" || rd.site_location_address == null
            ? "-"
            : rd.site_location_address,
        region_name:
          rd.region_name == "" || rd.region_name == null ? "-" : rd.region_name,
        sub_category:
          rd.sub_category == "" || rd.sub_category == null
            ? "-"
            : rd.sub_category,
        Agent: rd.Agent,
        AgentName: rd.Agent == null ? "-" : rd.Agent.EmployeeName,
        BDM: rd.BDM,
        pincode: rd.pincode == "" ? "-" : rd.pincode,
        call_status:
          rd.call_status == "" || rd.call_status === null
            ? "-"
            : rd.call_status,
        call_type:
          rd.call_type == "" || rd.call_type === null ? "-" : rd.call_type,
        createdDate: rd.createdAt == null ? "-" : formatDate(rd.createdAt),
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        bdm_remark:
          rd.bdm_remark === "" || rd.bdm_remark === null ? "-" : rd.bdm_remark,
        lead_calling_date: "-",
        bdm_calling_date: "-",
        updatedAt: rd.updatedAt == "" ? "-" : formatDate(rd.updatedAt),
        last_action:
          rd.last_action === null || rd.last_action === ""
            ? "-"
            : rd.last_action,
      };
      // console.log(a)
      transformedRows.push(a);
    });
    if (type === "hot") {
      setHotLeadRowData(transformedRows);
    } else if (type === "warm") {
      setWarmLeadRowData(transformedRows);
    } else if (type === "cold") {
      setColdRowLeadData(transformedRows);
    } else if (type === "pending") {
      setPendingLeadRowData(transformedRows);
    } else if (type === "closed") {
      setClosedLeadRowData(transformedRows);
    } else {
      setAllLeadRowData(transformedRows);
    }
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      <div className={`float-right`}>
        {localStorage.getItem("empRole") != 3 && (
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            sx={{ fontSize: "1rem", fontWeight: "700", borderWidth: "1.5px" }}
          >
            {" "}
            + Add Lead
          </Button>
        )}
      </div>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        modalTitle={"Lead Generation"}
        setOpenSnackbar={setOpenSnackbar}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setalertTitle={setalertTitle}
      ></ModalComponent>

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        sx={{ padding: "6px 10px" }}
      >
        <Tab
          label={`Hot (${hotLeadCount})`}
          id="tab-0"
          icon={<LocalFireDepartmentIcon />}
          iconPosition="start"
          aria-controls="tabpanel-1"
          sx={{ fontSize: "0.8rem" }}
        />
        <Tab
          label={`Warm (${warmLeadCount})`}
          id="tab-1"
          icon={<WavesIcon />}
          iconPosition="start"
          aria-controls="tabpanel-1"
          sx={{ fontSize: "0.8rem" }}
        />
        <Tab
          label={`Cold (${coldLeadCount})`}
          id="tab-2"
          icon={<AcUnitIcon />}
          iconPosition="start"
          aria-controls="tabpanel-2"
          sx={{ fontSize: "0.8rem" }}
        />
        <Tab
          label={`Pending (${pendingLeadCount})`}
          id="tab-3"
          icon={<PendingActionsIcon />}
          iconPosition="start"
          aria-controls="tabpanel-3"
          sx={{ fontSize: "0.8rem" }}
        />
        <Tab
          label={`Closed (${closedLeadCount})`}
          id="tab-4"
          icon={<LockIcon />}
          iconPosition="start"
          aria-controls="tabpanel-4"
          sx={{ fontSize: "0.8rem" }}
        />
        <Tab
          label={`All (${allLeadCount})`}
          id="tab-5"
          icon={<LockIcon />}
          iconPosition="start"
          aria-controls="tabpanel-5"
          sx={{ fontSize: "0.8rem" }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        {/* <LeadTable hotLeadRowData={hotLeadRowData}></LeadTable> */}
        <HotLeadTable hotLeadRowData={hotLeadRowData}></HotLeadTable>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WarmLeadTable warmLeadRowData={warmLeadRowData}></WarmLeadTable>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ColdLeadTable coldLeadRowData={coldLeadRowData}></ColdLeadTable>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PendingLeadTable
          pendingLeadRowData={pendingLeadRowData}
        ></PendingLeadTable>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ClosedLeadTable
          closedLeadRowData={closedLeadRowData}
        ></ClosedLeadTable>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <AllLeadTable allLeadRowData={allLeadRowData}></AllLeadTable>
      </TabPanel>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default LeadTabComponent;
