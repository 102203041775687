import React, {useContext, useState, useEffect} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import auditFormStyles from './auditFromStyles.module.css';
import { Divider, Stack, Grid, Button, Snackbar, Card } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { GET_AUDIT_REMARK_LIST, POST_AUDIT_REMARK } from "../../constants";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { MenuItem, FormControl, Select , useMediaQuery, useTheme, IconButton} from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Loader from '../reusable Component/Loader';
import LongTextComponent from '../Leads Page/LongTextComponent';
import StatusChangeDialog from "./StatusChangeDialog";

function AuditDetailsPageComponent() {

    const storedLeadDetails = JSON.parse(localStorage.getItem('leadDetails'));
    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate(); 

    const initialFormValues = {
        CH: '',
        AGE: '',
        BWT: '',
        M_QTY: '',
        REASON: '',
        MED: '',
        FEED: '',
        STOCK: '',
        IFFT_IN: '',
        IFFT_OUT: '',
        LS_VISIT: '',
        BM_VISIT: '',
        DAILY_ENT: '',
        FEED_ENT: '',
        MORT_ENT: '',
        BWT_ENT: '',
        MED_ENT: '',
        REMARKS: '',
        DATE: null,
      };

    const [formValues, setFormValues] = useState({
        CH: '',
        AGE: '',
        BWT: '',
        M_QTY: '',
        REASON: '',
        MED: '',
        FEED: '',
        STOCK: '',
        IFFT_IN: '',
        IFFT_OUT: '',
        LS_VISIT: '',
        BM_VISIT: '',
        DAILY_ENT: '',
        FEED_ENT: '',
        MORT_ENT: '',
        BWT_ENT: '',
        MED_ENT: '',
        REMARKS: '',
        DATE: null,
        Lot_Number: storedLeadDetails.Lot_Number,
        AgentId: empDetails.EmployeeId
      });
    
      const [formErrors, setFormErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };

      const handleDateChange = (newDate) => {
        setFormValues({
          ...formValues,
          DATE: newDate
        });
      };

    //   const validate = () => {
    //     const errors = {};
    
    //     if (!formValues.CH) errors.CH = 'Chicks Housed is required';
    //     if (!formValues.AGE) errors.AGE = 'Mean Age is required';
    //     if (!formValues.BWT) errors.BWT = 'Body Weight is required';
    //     if (!formValues.M_QTY) errors.M_QTY = 'Mortality Quantity is required';
    //     if (!formValues.REASON) errors.REASON = 'Reason is required';
    //     if (!formValues.MED) errors.MED = 'Medicine is required';
    //     if (!formValues.FEED) errors.FEED = 'Feed is required';
    //     if (!formValues.STOCK) errors.STOCK = 'Stock is required';
    //     if (!formValues.IFFT_IN) errors.IFFT_IN = 'IFFT In is required';
    //     if (!formValues.IFFT_OUT) errors.IFFT_OUT = 'IFFT Out is required';
    //     if (!formValues.LS_VISIT) errors.LS_VISIT = 'LS Visit is required';
    //     if (!formValues.BM_VISIT) errors.BM_VISIT = 'BM Visit is required';
    //     if (!formValues.DAILY_ENT) errors.DAILY_ENT = 'Daily Ent is required';
    //     if (!formValues.FEED_ENT) errors.FEED_ENT = 'Feed Ent is required';
    //     if (!formValues.MORT_ENT) errors.MORT_ENT = 'Mortality Ent is required';
    //     if (!formValues.BWT_ENT) errors.BWT_ENT = 'BWT Ent is required';
    //     if (!formValues.MED_ENT) errors.MED_ENT = 'Medicine Ent is required';
    //     if (!formValues.REMARKS) errors.REMARKS = 'Remarks is required';
    //     if (!formValues.DATE) errors.DATE = 'Date is required';
    
    //     setFormErrors(errors);
    //     return Object.keys(errors).length === 0;
    //   };

      const onSubmit = (e) => {
        // e.preventDefault();
        // if (validate()) {
          
          axios.post(POST_AUDIT_REMARK, formValues, {
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }})
            .then(response => {
                setSeverity('success');
                setAlertTitle(response.data.message);
                setOpenSnackbar(true);
                resetForm();
                setRunEffect(true);
            }).catch(error => {
                setSeverity('error');
                setAlertTitle(error.message);
                setOpenSnackbar(true);
                resetForm();
            });
        
      };

      // To reset formValues to initial state
        const resetForm = () => {
            setFormValues(initialFormValues);
        };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

      const [row, setRow] = useState([]);

      const columns = [
        { field: 'SNo', headerName: 'SNo',width: 50, type: 'string',  headerAlign: 'center', responsive: true},
        // { field: 'createdAt', headerName: 'Created Date',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Lot_Number', headerName: 'Lot Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CH', headerName: 'CH',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'AGE', headerName: 'AGE',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'BWT', headerName: 'BWT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'M_QTY', headerName: 'M_QTY',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'REASON', headerName: 'REASON',width: isMobile ? 120 : 190 , type: 'string',  headerAlign: 'center', responsive: true,
            renderCell: (params) => (
                <LongTextComponent text={params.value === null ? '-' : params.value} maxLength={20} />
              ),
        },
        { field: 'MED', headerName: 'MED',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'FEED', headerName: 'FEED',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'STOCK', headerName: 'STOCK',width: isMobile ? 120 : 190 , type: 'string',  headerAlign: 'center', responsive: true,
            renderCell: (params) => (
                <LongTextComponent text={params.value === null ? '-' : params.value} maxLength={20} />
              ),
        },
        { field: 'IFFT_IN', headerName: 'IFFT IN',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'IFFT_OUT', headerName: 'IFFT OUT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'LS_VISIT', headerName: 'LS VISIT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'BM_VISIT', headerName: 'BM VISIT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'DAILY_ENT', headerName: 'DAILY ENT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'FEED_ENT', headerName: 'FEED ENT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'MORT_ENT', headerName: 'MORT ENT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'BWT_ENT', headerName: 'BWT ENT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'MED_ENT', headerName: 'MED ENT',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true,
            renderCell: (params) => (
                <LongTextComponent text={params.value === null ? '-' : params.value} maxLength={20} />
              ),
        },
        { field: 'REMARKS', headerName: 'REMARKS',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true,
            renderCell: (params) => (
                <LongTextComponent text={params.value === null ? '-' : params.value} maxLength={20} />
              ),
        },
        // { field: 'DATE', headerName: 'DATE',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'AgentName', headerName: 'CSE Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'updatedAt', headerName: 'Last Modified On',width: isMobile ? 120 : 150 , type: 'dateTime',  headerAlign: 'center', responsive: true,
           
        },  
        
    ];

    const [runEffect, setRunEffect] = useState(false);

    useEffect(()=>{
        setLoading(true);
    axios.get(`${GET_AUDIT_REMARK_LIST}/${storedLeadDetails.Lot_Number}`,{
        headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }})
    .then(response => {
        const {auditLeadRemarks} = response.data;
        handleRowData(auditLeadRemarks);
        setLoading(false);
        setRunEffect(false);
    }).catch(error => {
        // setSeverity('error');
        //     setAlertTitle(error.message);
        //     setOpenSnackbar(true);
        setLoading(false);
        setRunEffect(false);
    });

    },[runEffect]);

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
            a = {
            "id": index + 1 ,
            "SNo": index + 1,
            "CH": rd.CH,
            "AGE": rd.AGE,
            "BWT": rd.BWT,
            "M_QTY": rd.M_QTY,
            "REASON": rd.REASON,
            "MED": rd.MED,
            "FEED": rd.FEED,
            "STOCK": rd.STOCK,
            "IFFT_IN": rd.IFFT_IN,
            "IFFT_OUT": rd.IFFT_OUT,
            "LS_VISIT": rd.LS_VISIT,
            "BM_VISIT": rd.BM_VISIT,
            "DAILY_ENT": rd.DAILY_ENT,
            "FEED_ENT": rd.FEED_ENT,
            "MORT_ENT": rd.MORT_ENT,
            "BWT_ENT": rd.BWT_ENT,
            "MED_ENT": rd.MED_ENT,
            "REMARKS": rd.REMARKS,
            "DATE": rd.DATE,
            "AgentId":rd.AgentId,
            "AgentName": rd.Agent.EmployeeName,
            "Lot_Number": rd.Lot_Number,
            "createdAt": new Date(rd.createdAt),
            "updatedAt": new Date(rd.updatedAt)
        }
        transformedRows.push(a);
        })
        setRow(transformedRows);
    }

    const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
    const [status, setStatus] = useState(''); // To store the selected status

    const handleOpenDialog = (e) => {
        e.preventDefault();
        setDialogOpen(true); // Open the dialog on submit
    };
    
    return (
        <>
        {loading && <Loader open={loading} />}
        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${auditFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Lead Details</Typography>
        </AccordionSummary>
            <AccordionDetails>
            <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            >

            <Grid container spacing={2}  p={3} >
            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Lot Number:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Lot_Number}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Farmer Name:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Farmer_Name}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Mobile Number:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Mobile}
                </Typography>
            </Grid>
            
            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Zone Name:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Zone_Name}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Branch Name:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Branch_Name}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Vendor:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Vendor}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Shed Type:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Shed_Type}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Hatch Date:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Hatch_Date}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Chicks Housed Quantity:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Placed_Qty}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    CA:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.CA}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Age:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Age_SAP}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Diff:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Diff}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    First Week Mortality Quantity:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.first_Week_M}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    First Week Mortality Percentage:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.First_Week_Mortality_Percentage}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Total Mortality Quantity:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Total_Mortality}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Total Mortality Percentage:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Total_Mortality_Percentage}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Lifting EA:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Lifting_EA}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Lifting Percentage:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Lift_Percentage}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                     Average Lifted Weight:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Avg_Lift_Wt}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Balance Birds:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Bal_Birds}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Average Body Weight:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.ABWT}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    BWT Age:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.BWT_Age}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Feed Cons:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Feed_Cons}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Previous Grade:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Prev_Grade}
                </Typography>
            </Grid>


            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    FCR:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.FCR}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Line:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Line}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${auditFormStyles.label_margin}`}>
                    Hatchery Name:
                </Typography>
                <Typography variant='body2'>
                     {storedLeadDetails.Hatchery_Name}
                </Typography>
            </Grid>
            
            </Grid>

            </Box>
        </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${auditFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Remarks List</Typography>
        </AccordionSummary>
            <AccordionDetails>

            <DataGrid 
                rows={row} 
                columns={columns} 
                columnHeaderHeight={50}  
                getRowHeight={() => 'auto'} 
                autoHeight={row.length < 10}
                disableExtendRowFullWidth
                // hideFooterPagination={theme.breakpoints.down('md')}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true }
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                    pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                    },
                }} 
                
                disableRowSelectionOnClick
                disableDensitySelector
                sx={{
                    height: '400px',
                    '& .MuiDataGrid-cell': {
                    textAlign: 'center',
                    placeContent: 'center',
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#EEF0F4'
                    },
                    '& .super-app-theme--header':{
                        // width: isMobile == true ? '200px !important' : '150px !important',
                        // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                    },
                }} 
                />    

            </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${auditFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Remark Form</Typography>
        </AccordionSummary>
            <AccordionDetails>
            
            <Card>
            <form>
                <Grid container spacing={2} p={3}>
                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Chicks Housed"
                        id="outlined-size-small"
                        name="CH"
                        value={formValues.CH}
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                            handleChange(e)
                            }
                        }}
                        error={!!formErrors.CH}
                        helperText={formErrors.CH}
                        size="small"
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Mean Age"
                        id="outlined-size-small"
                        name="AGE"
                        value={formValues.AGE}
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                            handleChange(e)
                            }
                        }}
                        error={!!formErrors.AGE}
                        helperText={formErrors.AGE}
                        size="small"
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Body Weight"
                        id="outlined-size-small"
                        name="BWT"
                        value={formValues.BWT}
                        onChange={(e) => {
                            if (/^\d?(\.\d{0,3})?$/.test(e.target.value)) {
                            handleChange(e)
                            }
                        }}
                        error={!!formErrors.BWT}
                        helperText={formErrors.BWT}
                        size="small"
                        fullWidth
                        inputProps={{ inputMode: 'decimal', pattern: '\\d?(\\.\\d{0,3})?' }}
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Mortality Quantity"
                        id="outlined-size-small"
                        name="M_QTY"
                        value={formValues.M_QTY}
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                            handleChange(e)
                            }
                        }}
                        error={!!formErrors.M_QTY}
                        helperText={formErrors.M_QTY}
                        size="small"
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Reason"
                        id="outlined-size-small"
                        name="REASON"
                        multiline
                        rows={4}
                        value={formValues.REASON}
                        onChange={handleChange}
                        error={!!formErrors.REASON}
                        helperText={formErrors.REASON}
                        size="small"
                        fullWidth
                        style={{ resize: 'both', overflow: 'auto' }}
                        sx={{
                            '& .MuiInputLabel-root': {
                            top: '0',  // Adjust to align the label properly
                            '&.MuiInputLabel-shrink': {
                                top: '6px',  // Ensure the label is fully visible when it shrinks
                            },
                            }
                        }}
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Medicine"
                        id="outlined-size-small"
                        name="MED"
                        multiline
                        rows={4}
                        value={formValues.MED}
                        onChange={handleChange}
                        error={!!formErrors.MED}
                        helperText={formErrors.MED}
                        size="small"
                        fullWidth
                        style={{ resize: 'both', overflow: 'auto' }}
                        sx={{
                            '& .MuiInputLabel-root': {
                            top: '0',  // Adjust to align the label properly
                            '&.MuiInputLabel-shrink': {
                                top: '6px',  // Ensure the label is fully visible when it shrinks
                            },
                            }
                        }}
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Feed"
                        id="outlined-size-small"
                        name="FEED"
                        value={formValues.FEED}
                        onChange={handleChange}
                        error={!!formErrors.FEED}
                        helperText={formErrors.FEED}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Stock"
                        id="outlined-size-small"
                        name="STOCK"
                        value={formValues.STOCK}
                        onChange={handleChange}
                        error={!!formErrors.STOCK}
                        helperText={formErrors.STOCK}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="IFFT In"
                        id="outlined-size-small"
                        name="IFFT_IN"
                        value={formValues.IFFT_IN}
                        onChange={handleChange}
                        error={!!formErrors.IFFT_IN}
                        helperText={formErrors.IFFT_IN}
                        size="small"
                        multiline
                        rows={4}
                        fullWidth
                        style={{ resize: 'both', overflow: 'auto' }}
                        sx={{
                            '& .MuiInputLabel-root': {
                            top: '0',  // Adjust to align the label properly
                            '&.MuiInputLabel-shrink': {
                                top: '6px',  // Ensure the label is fully visible when it shrinks
                            },
                            }
                        }}      
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="IFFT Out"
                        id="outlined-size-small"
                        name="IFFT_OUT"
                        value={formValues.IFFT_OUT}
                        onChange={handleChange}
                        error={!!formErrors.IFFT_OUT}
                        helperText={formErrors.IFFT_OUT}
                        size="small"
                        multiline
                        rows={4}
                        fullWidth
                        style={{ resize: 'both', overflow: 'auto' }}
                        sx={{
                            '& .MuiInputLabel-root': {
                            top: '0',  // Adjust to align the label properly
                            '&.MuiInputLabel-shrink': {
                                top: '6px',  // Ensure the label is fully visible when it shrinks
                            },
                            }
                        }} 
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="LS Visit"
                        id="outlined-size-small"
                        name="LS_VISIT"
                        value={formValues.LS_VISIT}
                        onChange={handleChange}
                        error={!!formErrors.LS_VISIT}
                        helperText={formErrors.LS_VISIT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="BM Visit"
                        id="outlined-size-small"
                        name="BM_VISIT"
                        value={formValues.BM_VISIT}
                        onChange={handleChange}
                        error={!!formErrors.BM_VISIT}
                        helperText={formErrors.BM_VISIT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Daily Ent"
                        id="outlined-size-small"
                        name="DAILY_ENT"
                        value={formValues.DAILY_ENT}
                        onChange={handleChange}
                        error={!!formErrors.DAILY_ENT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Feed Ent"
                        id="outlined-size-small"
                        name="FEED_ENT"
                        value={formValues.FEED_ENT}
                        onChange={handleChange}
                        error={!!formErrors.FEED_ENT}
                        helperText={formErrors.FEED_ENT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Mortality Ent"
                        id="outlined-size-small"
                        name="MORT_ENT"
                        value={formValues.MORT_ENT}
                        onChange={handleChange}
                        error={!!formErrors.MORT_ENT}
                        helperText={formErrors.MORT_ENT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="BWT Ent"
                        id="outlined-size-small"
                        name="BWT_ENT"
                        value={formValues.BWT_ENT}
                        onChange={handleChange}
                        error={!!formErrors.BWT_ENT}
                        helperText={formErrors.BWT_ENT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Medicine Ent"
                        id="outlined-size-small"
                        name="MED_ENT"
                        value={formValues.MED_ENT}
                        onChange={handleChange}
                        error={!!formErrors.MED_ENT}
                        helperText={formErrors.MED_ENT}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                    <DesktopDatePicker
                        label="Select Date"
                        inputFormat="MM/DD/YYYY"
                        value={formValues.DATE}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                            //   helperText: 'MM/DD/YYYY',
                              clearable: true
                            },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: '100%'}}
                    />
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                    <TextField
                        label="Remarks"
                        id="outlined-size-small"
                        name="REMARKS"
                        multiline
                        rows={4}
                        value={formValues.REMARKS}
                        onChange={handleChange}
                        error={!!formErrors.REMARKS}
                        helperText={formErrors.REMARKS}
                        size="small"
                        fullWidth
                    />
                    </Grid>

                    
                </Grid>

                <Box display="flex" justifyContent="flex-end" p={3}>
                    <Button type="submit" variant="contained" onClick={(e) => handleOpenDialog(e)}>
                    Save
                    </Button>
                </Box>
                </form>
            </Card>

            </AccordionDetails>
        </Accordion>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {/* <AlertTitle>{alertTitle}</AlertTitle> */}
            {alertTitle}
        </Alert>
        </Snackbar>

        <StatusChangeDialog open={dialogOpen} setOpen={setDialogOpen} formValues={formValues} setFormValues={setFormValues} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setAlertTitle={setAlertTitle} resetForm={resetForm} setRunEffect={setRunEffect} empDetails={empDetails} storedLeadDetails={storedLeadDetails} ></StatusChangeDialog>
        </>
    );

}

export default AuditDetailsPageComponent;