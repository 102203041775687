import React, { useMemo, useEffect, useState } from "react";
import { Grid, TextField, Button, Box, Autocomplete, Select, MenuItem } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs"; // Ensure dayjs is imported

const FilterComponent = ({ filters, setFilters, setPage }) => {
  const today = dayjs().startOf("day");

  const sourceOptions = [
    { value: "", label: "Select a source" },
    { value: "Hyderabad Expo 24", label: "Hyderabad Expo 24" },
    { value: "Lucknow Expo 24", label: "Lucknow Expo 24" },
    { value: "Company Website", label: "Company Website" },
    { value: "Friends", label: "Friends" },
    { value: "Other Farmers", label: "Other Farmers" },
    { value: "Facebook", label: "Facebook" },
    { value: "WhatsApp Messages", label: "WhatsApp Messages" },
    { value: "Other", label: "Other" },
  ];

  const shedPlanOptions = [
    { value: "Planning New EC Shed", label: "Planning New EC Shed" },
    { value: "Open to EC Shed", label: "Open to EC Shed" },
  ];

  const projectOptions = [
    { value: "Parivartan", label: "Parivartan" },
    { value: "Gen Nxt", label: "Gen Nxt" },
    { value: "Don't know", label: "Don't know" },
  ];

  const handleInputChange = (key, value) => {
    setFiltersData((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

 // Predefined date ranges
 const predefinedRanges = [
  { label: "Today", startDate: today, endDate: today },
  {
    label: "Yesterday",
    startDate: today.subtract(1, "day"),
    endDate: today.subtract(1, "day"),
  },
  {
    label: "Last 7 Days",
    startDate: today.subtract(6, "day"),
    endDate: today,
  },
  { label: "Custom", startDate: null, endDate: null },
];

const [filtersData, setFiltersData] = useState({
  dateRange: predefinedRanges[1], // Default to "Yesterday"
  customStartDate: null,
  customEndDate: null,
  shed_plan: filters.shed_plan || "",
  project: filters.project || "",
  source: filters.source || "",
});

const handleDateRangeChange = (range) => {
  setFiltersData((prevFilters) => ({
    ...prevFilters,
    dateRange: range,
    // Reset custom range if not "Custom"
    customStartDate: range.label === "Custom" ? prevFilters.customStartDate : null,
    customEndDate: range.label === "Custom" ? prevFilters.customEndDate : null,
  }));
};

const handleCustomDateChange = (key, value) => {
  setFiltersData((prevFilters) => ({
    ...prevFilters,
    [key]: value,
  }));
};

const handleApply = () => {
  const { dateRange, customStartDate, customEndDate } = filtersData;

  let startDate = dateRange.startDate;
  let endDate = dateRange.endDate;

  if (dateRange.label === "Custom") {
    startDate = customStartDate ? dayjs(customStartDate) : null;
    endDate = customEndDate ? dayjs(customEndDate) : null;
  }

  if (startDate && endDate && startDate.isValid() && endDate.isValid()) {
    setFilters({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      shed_plan: filtersData.shed_plan,
      project: filtersData.project,
      source: filtersData.source,
    });
    setPage(0);
  } else {
    console.error("Please ensure valid custom dates are selected.");
  }
};

const handleClear = () => {
  setFilters({
    startDate: null,
    endDate: null,
    shed_plan: "",
    project: "",
    source: "",
  });
  setFiltersData({
    dateRange: predefinedRanges[1], // Reset to "Today"
    customStartDate: null,
    customEndDate: null,
    shed_plan: "",
    project: "",
    source: "",
  });
};
  return (
    <Box
      sx={{
        backgroundColor: "#f0f4f8",
        padding: 1,
        borderRadius: 2,
        boxShadow: 3,
        marginBottom: 3,
      }}
    >
      <Grid container spacing={2}>
        {/* Date Range Picker */}
        <Grid item xs={12} sm={4} xl={2}>
          <Select
            fullWidth
            value={filtersData.dateRange.label}
            size="small"
            onChange={(e) =>
              handleDateRangeChange(
                predefinedRanges.find((range) => range.label === e.target.value)
              )
            }
          >
            {predefinedRanges.map((range) => (
              <MenuItem key={range.label} value={range.label}>
                {range.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Custom Date Range Inputs */}
        {filtersData.dateRange.label === "Custom" && (
          <>
            <Grid item xs={6} sm={4} xl={2}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  filtersData.customStartDate
                    ? dayjs(filtersData.customStartDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) =>
                  handleCustomDateChange("customStartDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={4} xl={2}>
              <TextField
                label="End Date"
                type="date"
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  filtersData.customEndDate
                    ? dayjs(filtersData.customEndDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) =>
                  handleCustomDateChange("customEndDate", e.target.value)
                }
              />
            </Grid>
          </>
        )}

        {/* Source Autocomplete */}
        <Grid item xs={12} sm={4} xl={2}>
          <Autocomplete
            options={sourceOptions}
            value={sourceOptions.find(
              (option) => option.value === filtersData.source
            ) || null}
            onChange={(event, newValue) =>
              handleInputChange("source", newValue ? newValue.value : "")
            }
            renderInput={(params) => (
              <TextField {...params} label="Source" size="small" fullWidth />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>

        {/* Shed Plan Autocomplete */}
        <Grid item xs={12} sm={4} xl={2}>
          <Autocomplete
            options={shedPlanOptions}
            value={
              shedPlanOptions.find(
                (option) => option.value === filtersData.shed_plan
              ) || null
            }
            onChange={(event, newValue) =>
              handleInputChange("shed_plan", newValue ? newValue.value : "")
            }
            renderInput={(params) => (
              <TextField {...params} label="Shed Plan" size="small" fullWidth />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>

        {/* Project Autocomplete */}
        <Grid item xs={12} sm={4} xl={2}>
          <Autocomplete
            options={projectOptions}
            value={
              projectOptions.find(
                (option) => option.value === filtersData.project
              ) || null
            }
            onChange={(event, newValue) =>
              handleInputChange("project", newValue ? newValue.value : "")
            }
            renderInput={(params) => (
              <TextField {...params} label="Project" size="small" fullWidth />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>

        {/* Search Textbox */}
        {/* <Grid item xs={12} sm={4}>
          <TextField
            label="Search"
            value={filtersData.search}
            onChange={(e) => handleInputChange("search", e.target.value)}
            fullWidth
            size="small"
          />
        </Grid> */}
        <Grid item xs={12} sm={4} xl={2}>
        <Box sx={{ display: "flex"}}>
        <Button
          onClick={handleClear}
          variant="outlined"
          color="secondary"
          sx={{ marginRight: 2 }}
        >
          Clear
        </Button>
        <Button onClick={handleApply} variant="contained">
          Apply
        </Button>
      </Box>
      </Grid>
      </Grid>

      
    </Box>
  );
};

export default FilterComponent;
