import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ModalComponent from "../reusable Component/modalComponent";
import { GET_LEAD_BY_BDM_API, PENDING_AND_COLD_LEAD } from "../../constants";
import Box from "@mui/material/Box";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import WavesIcon from "@mui/icons-material/Waves";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import {
  Alert,
  Button,
  Fab,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Loader from "../reusable Component/Loader";
import AssignedCustomerTable from "./assignedCustomerTable";
import WarmLeadTable from "./WarmLeadTable";
import ColdLeadTable from "./ColdLeadTable";
import PendingLeadTable from "./PendingLeadTable";
import AllLeadTable from "./AllLeadTable";
import ClosedLeadTable from "./ClosedLeadTable";
import MobileDatatable from "../MobileView/MobileDatatable";
import { useTheme } from "@emotion/react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import moment from "moment";
import MobileWarmDatatable from "../MobileView/MobileWarmDatatable";
import MobileColdDatatable from "../MobileView/MobileColdDatatable";
import MobilePendingDatatable from "../MobileView/MobilePendingDatable";
import MobileClosedDatatable from "../MobileView/MobileClosedDatatable";
import MobileAllDatatable from "../MobileView/MobileAllDatatable";
import { useLocation } from "react-router-dom";
import HotLeadTable from "./HotLeadTable";
import AddIcon from "@mui/icons-material/Add";

const CustomToolbar = ({ data, columns, setFilteredRows }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Custom export to Excel function
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      columns.map((column) => column.headerName),
      ...data.map((row) => columns.map((column) => row[column.field])),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Crm_Leads.xlsx");
    handleMenuClose(); // Close the menu after exporting
  };

  const exportToCSV = () => {
    const csvData = [
      columns.map((column) => column.headerName).join(","), // CSV Header
      ...data.map((row) =>
        columns.map((column) => row[column.field]).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Crm_Lead.csv");
    handleMenuClose(); // Close the menu after exporting
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },

        }}
      /> */}
      <Tooltip title="Export Options">
        <Button variant="text" onClick={handleMenuClick}>
          <SaveAltIcon
            sx={{ fontSize: "1.3rem", paddingRight: "2px" }}
          ></SaveAltIcon>{" "}
          Export
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
        <MenuItem onClick={exportToCSV}>Export to CSV</MenuItem>
        {/* Add more menu items for additional export options if needed */}
      </Menu>
      <Box sx={{ flexGrow: 1 }}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function BdmTabComponent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.state && location.state.tab !== undefined) {
      setValue(location.state.tab);
    }
  }, [location.state]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [hotLeadCount, setHotCountLead] = useState(0);
  const [warmLeadCount, setWarmCountLead] = useState(0);
  const [coldLeadCount, setColdCountLead] = useState(0);
  const [pendingLeadCount, setPendingCountLead] = useState(0);
  const [closedLeadCount, setClosedCountLead] = useState(0);
  const [allLeadCount, setAllLeadCount] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [runEffect, setRunEffect] = useState(false);

  const empId = localStorage.getItem("username");

  useEffect(() => {
    setLoading(true);

    const requestBody = {};
    axios
      .get(`${GET_LEAD_BY_BDM_API}/${empId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads, counts, categoryStats, totalCount } = response.data;

        setHotCountLead(categoryStats.hot);
        setWarmCountLead(categoryStats.warm);
        setColdCountLead(categoryStats.cold);
        setClosedCountLead(categoryStats.closed);
        setPendingCountLead(categoryStats.pending);
        setAllLeadCount(totalCount);

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        //   setSeverity('error');
        //   setMessage(error.response.data.message);
        //   setOpenSnackbar(true);
        setLoading(false);
      });
  }, [runEffect]);

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  return (
    <>
      {loading && <Loader open={loading} />}
      <Box
        sx={{
          display: isMobile ? "flex" : "",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "stretch" : "center",
          justifyContent: isMobile ? "center" : "space-between",
          padding: isMobile ? "0px" : "16px",
        }}
      >
        {/* Regular Button for non-mobile view */}
        {!isMobile && (
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            sx={{
              fontSize: "1rem",
              fontWeight: "700",
              borderWidth: "1.5px",
              marginBottom: 0,
              float: "right",
            }}
          >
            + Add Lead
          </Button>
        )}

        {/* Floating Action Button for mobile view */}
        {isMobile && (
          <Box
            sx={{
              position: "fixed",
              bottom: `calc(16px + ${isMobile ? "56px" : "0px"})`,
              right: 16,
              zIndex: 10,
            }}
          >
            <Fab
              color="primary"
              aria-label="add lead"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "primary", // Customize FAB color
                "&:hover": {
                  backgroundColor: "#115293",
                },
              }}
            >
              <AddIcon />
            </Fab>
          </Box>
        )}
        <ModalComponent
          open={open}
          modalTitle={"BDM Lead Generation"}
          setOpen={setOpen}
          rowDetails={{}}
          setOpenSnackbar={setOpenSnackbar}
          setSeverity={setSeverity}
          setMessage={setMessage}
        ></ModalComponent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          variant={isMobile ? "scrollable" : "standard"} // Scrollable tabs on mobile
          scrollButtons="auto"
          sx={{
            flexGrow: 1,
            "& .MuiTab-root": {
              minWidth: isMobile ? 80 : 100, // Adjust min-width for tabs on mobile
              fontSize: "0.875rem",
              padding: "8px 6px",
            },
            "& .MuiTabs-indicator": {
      display: "none", // Hide the indicator line
    },
          }}
        >
          <Tab
            label={`Hot (${hotLeadCount})`}
            id="tab-0"
            icon={<LocalFireDepartmentIcon />}
            iconPosition="start"
            aria-controls="tabpanel-1"
          />
          <Tab
            label={`Warm (${warmLeadCount})`}
            id="tab-1"
            icon={<WavesIcon />}
            iconPosition="start"
            aria-controls="tabpanel-1"
          />
          <Tab
            label={`Cold (${coldLeadCount})`}
            id="tab-2"
            icon={<AcUnitIcon />}
            iconPosition="start"
            aria-controls="tabpanel-2"
          />
          <Tab
            label={`Pending (${pendingLeadCount})`}
            id="tab-3"
            icon={<PendingActionsIcon />}
            iconPosition="start"
            aria-controls="tabpanel-3"
          />
          <Tab
            label={`Closed (${closedLeadCount})`}
            id="tab-4"
            icon={<LockIcon />}
            iconPosition="start"
            aria-controls="tabpanel-4"
          />
          <Tab
            label={`All (${allLeadCount})`}
            id="tab-5"
            icon={<LockIcon />}
            iconPosition="start"
            aria-controls="tabpanel-5"
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {isMobile ? (
          <MobileDatatable setLoading={setLoading} empId={empId} />
        ) : (
          <HotLeadTable setLoading={setLoading} empId={empId}></HotLeadTable>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isMobile ? (
          <MobileWarmDatatable
            setLoading={setLoading}
            empId={empId}
          ></MobileWarmDatatable>
        ) : (
          <WarmLeadTable setLoading={setLoading} empId={empId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {isMobile ? (
          <MobileColdDatatable
            setLoading={setLoading}
            empId={empId}
          ></MobileColdDatatable>
        ) : (
          <ColdLeadTable setLoading={setLoading} empId={empId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {isMobile ? (
          <MobilePendingDatatable
            setLoading={setLoading}
            empId={empId}
          ></MobilePendingDatatable>
        ) : (
          <PendingLeadTable setLoading={setLoading} empId={empId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {isMobile ? (
          <MobileClosedDatatable
            setLoading={setLoading}
            empId={empId}
          ></MobileClosedDatatable>
        ) : (
          <ClosedLeadTable setLoading={setLoading} empId={empId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {isMobile ? (
          <MobileAllDatatable
            setLoading={setLoading}
            empId={empId}
          ></MobileAllDatatable>
        ) : (
          <AllLeadTable setLoading={setLoading} empId={empId} />
        )}
      </TabPanel>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default BdmTabComponent;
