import * as React from 'react';
import { useState, useContext, useRef, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { MenuItem, FormControl, Select , useMediaQuery, useTheme, IconButton, Chip, Grid, Button, Snackbar, Alert, DialogTitle, DialogContent, DialogContentText, LinearProgress, Dialog, DialogActions} from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { AUDIT_TRADER_LIST, TRADER_LIST_BULK_UPLOAD } from '../../constants';
import axios from 'axios';
import Loader from '../reusable Component/Loader';
import { useNavigate } from 'react-router-dom';

function TraderListComponent({}) {

    const fileInputRef = useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const [traderList, setTraderList] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [runEffect,setRunEffect] = useState(false);

    useEffect(()=>{
        setLoading(true);
        const empId = localStorage.getItem('username');
        axios.get(`${AUDIT_TRADER_LIST}?agentId=${empId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
          .then(response => {
            const { data } = response.data;
            handleRowData(data);
            setLoading(false);
          }).catch(error => {
            setLoading(false);
          });
    },[runEffect]);

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
            a = {
            "id": index + 1 ,
            "AadharNo": rd.AadharNo || '-',
            "BillingBlockForSalesArea": rd.BillingBlockForSalesArea || '-',
            "CentralBillingBlock": rd.CentralBillingBlock || '-',
            "CentralDeliveryBlock": rd.Branch_Name || '-',
            "CentralOrderBlock": rd.CentralOrderBlock || '-',
            "City": rd.City || '-',
            "CreationDate": rd.CreationDate || '-',
            "CustomerID": rd.CustomerID || '-',
            "DelIndicatorForSalesArea": rd.DelIndicatorForSalesArea || '-',
            "DeliveryBlockForSalesArea": rd.DeliveryBlockForSalesArea || '-',
            "GSTNumber": rd.GSTNumber || '-',
            "Group2Name": rd.Group2Name || '-',
            "IncotermsPartTwo": rd.IncotermsPartTwo || '-',
            "Name": rd.Name || '-',
            "OrderBlockForSalesArea": rd.OrderBlockForSalesArea || '-',
            "PANNo": rd.PANNo || '-',
            "PayTermDesc": rd.PayTermDesc || '-',
            "Region": rd.Region || '-',
            "RegionName": rd.RegionName || '-',
            "Telephone1": rd.Telephone1 || '-',
            "createdAt": rd.createdAt || '-',
            "updatedAt": rd.updatedAt || '-',
        }
        transformedRows.push(a);
        })
        setTraderList(transformedRows);
    };

    const columns = [
        { field: 'SNo', headerName: 'SNo',width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1, type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CustomerID', headerName: 'Customer ID',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Name', headerName: 'Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'City', headerName: 'City',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Region', headerName: 'Region',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'RegionName', headerName: 'Region Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Telephone1', headerName: 'Mobile Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CentralOrderBlock', headerName: 'Central Order Block',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'OrderBlockForSalesArea', headerName: 'Order Block for Sales Area',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CentralDeliveryBlock', headerName: 'Central Delivery Block',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'DeliveryBlockForSalesArea', headerName: 'Delivery Block for Sales Area',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CentralBillingBlock', headerName: 'Central Billing Block',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'BillingBlockForSalesArea', headerName: 'Billing Block for Sales Area',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'DelIndicatorForSalesArea', headerName: 'Delivery Indicator for Sales Area',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'IncotermsPartTwo', headerName: 'Icoterms',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'PayTermDesc', headerName: 'PayTermDesc',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CreationDate', headerName: 'Created Date',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'GSTNumber', headerName: 'GST Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'PANNo', headerName: 'PAN Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Group2Name', headerName: 'Group Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'AadharNo', headerName: 'Adhaar Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
    ];

    const isXs = useMediaQuery('(max-width:600px)'); // 0px - 600px
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 600px - 900px
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg')); // 900px - 1200px
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl')); // 1200px - 1536px
    const isXl = useMediaQuery(theme.breakpoints.up('xl')); // 1536px and up


    const getHeight = () => {
        if (isXs) return 400;
        if (isSm) return 450;
        if (isMd) return 500;
        if (isLg) return 500;
        if (isXl) return '100vh';
        return 'auto';
    };

    // Set page size based on screen size
    const getPageSize = () => {
    let pageSize;
    if (isLg) {
        pageSize = 50; // 1200px and up
    } else if (isMd) {
        pageSize = 15; // 900px - 1200px
    } else if (isSm) {
        pageSize = 15; // 600px - 900px
    } else if (isXs) {
        pageSize = 10; // 0px - 600px
    } else {
        pageSize = 25;  
    }

    return pageSize;
    }

    const handleTraderClick = (e) => {
        e.preventDefault();
    };

    const handleInHouseClick = (e) =>{
       if (localStorage.getItem('empRole') === '101'){
        navigate('/auditMaster');
       } else{
        navigate('/auditLead');}
    };

    const [open, setOpen] = useState(false);
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [file, setFile] = useState(null);

    const handleBulkUploadClick = () => {
        fileInputRef.current.click();
      };

      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          setOpen(true);
          setFile(file);
          // uploadFile(file);
      }
      
    };

    const handleUpload = () => {
        setUploading(true);
        setProgress(0);
        
        // Simulate file upload
        const uploadInterval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(uploadInterval);
              uploadFile(file);
              // setTimeout(() => setOpen(false), 1000);
              return 100;
            }
            return prevProgress + 10;
          });
        }, 500);
      };
  
      const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
  
        axios.post(TRADER_LIST_BULK_UPLOAD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
        .then(response => {
            console.log('File uploaded successfully:', response.data);
            setSeverity('success');
            setAlertTitle(response.data.message);
            setMessage('Updated Count:' + response.data.updatedCount);
            setOpenSnackbar(true);
            
            setUploading(false);
            setOpen(false);
  
            fileInputRef.current.value = null;
            setRunEffect(true);
            // navigate(0);
            
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            // setRunEffect(false);
            setSeverity('error');
            setMessage(error.response.data.message);
            setOpenSnackbar(true);
            
        });
      };

      const handleCloseDialog = () => {
        if (!uploading) {
        setOpen(false);
        setFile(null);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };


    return(
        <>
        {loading && <Loader open={loading} />}

        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={2}>
            <Button  variant="contained"
                color="primary"
                onClick={handleInHouseClick}>
                In-House Farmer
            </Button>
            <Button variant="contained"
                color="secondary"
                onClick={handleTraderClick}>
                Trader List
            </Button>
            {/* <Button variant="contained" onClick={handleTraderClick}>
            Button 3
            </Button>
            <Button variant="contained" onClick={handleTraderClick}>
            Button 4
            </Button> */}
        </Box>
        <div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".xlsx, .xls"
                onChange={handleFileChange}
            />
        </div>
        <Button variant="contained" onClick={handleBulkUploadClick}>
            Bulk Upload
        </Button>
        </Box>

        <Box sx={{
        height: 'auto',
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        marginTop: '10px'
      }}>
        <DataGrid 
        rows={traderList} 
        columns={columns} 
        columnHeaderHeight={50}  
        getRowHeight={() => 'auto'}
        autoHeight={traderList.length < 10}
        disableExtendRowFullWidth
        // hideFooterPagination={theme.breakpoints.down('md')}
        slots={{ toolbar: GridToolbar }}
        
        slotProps={{
            toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true } //Disable the print option in export
            },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{
            pagination: {
            paginationModel: { pageSize: getPageSize(), page: 0 },
            },
        }} 
        disableRowSelectionOnClick
        disableDensitySelector
        sx={{
            height: traderList.length > 10 ? getHeight() : 'auto',
            '& .MuiDataGrid-cell': {
            textAlign: 'center',
            placeContent: 'center',
            minHeight: 50,
            fontSize: '0.8rem'
            },
            '& .MuiDataGrid-columnHeader':{
                backgroundColor: '#EEF0F4',
                fontSize: '0.8rem'
            },
            '& .super-app-theme--header':{
                // width: isMobile == true ? '200px !important' : '150px !important',
                // maxWidth: isMobile == true ? '200px !important' : '150px !important'
            },
        }} 
        />
   
        </Box>

            <Dialog
                open={open}
                onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth="sm"
            >
            <DialogTitle>{"Confirm Upload"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {uploading ? "Uploading..." : "Are you sure you want to upload the selected file?"}
                </DialogContentText>
                {uploading && (
                    <Box sx={{ width: '100%', mt: 2, height: '30px' }}>
                    <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
                </DialogContent>
                <DialogActions>
                {!uploading && (
                    <>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpload} color="primary" autoFocus>
                        Confirm
                    </Button>
                    </>
                )}
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert
                onClose={handleClose}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {/* <AlertTitle>{alertTitle}</AlertTitle> */}
                {alertTitle}
            </Alert>
            </Snackbar>
        </>
    );
}

export default TraderListComponent;