import React, { useState, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import { Typography } from '@mui/material';
import {ROLES} from '../../constants';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { LoginContext } from '../LoginPage/LoginContext';
import { Stack, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function ProfileComponent() {



    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [ name, setName] = useState(empDetails == [] ? '' : empDetails.EmployeeName);
    const [ empID, setEmpID] = useState(empDetails == [] ? '' : empDetails.EmployeeId);
    const [ mobileNum, setMobileNum] = useState(empDetails == [] ? '' : empDetails.EmployeePhone);
    const [ mailId, setMailId] = useState(empDetails == [] ? '' : empDetails.EmployeeMailId);
    const [ region, setRegion] = useState(empDetails == [] ? '' : empDetails.EmployeeRegion);
    const [ role, setRole] = useState(empDetails == [] ? '' : empDetails.EmployeeRole);

    console.log(empDetails)
    // Extract names into an array
    const namesArray = empDetails.Campaigns.map(item => item.CampaignName);

    // Join names array into a comma-separated string
    const namesString = namesArray.join(', ');
    

    const [ campaign, setCampaign] = useState(empDetails.Campaigns.length == 0 ? '' : namesString);
    console.log(campaign)

    const handleSubmit = (e)=>{
        e.preventDefault();
        const formData = {
            "name": name,
            "empID": empID,
            "mobileNum": mobileNum,
            "mailId": mailId,
            "region": region,
            "role": role
        }
        console.log('form',formData);
    };

  return (
    <>
    <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Profile Details</Typography>
        </AccordionSummary>
            <AccordionDetails>
            <form onSubmit={handleSubmit}>
            <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
            <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={4} xl={6}>
            <TextField
            label="Employee ID"
            id="outlined-size-small"
            value={empID}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            />
            </Grid>
            
            <Grid item xs={12} md={4} xl={6}>
            <TextField
            label="Employee Name"
            id="outlined-size-small"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            />
            </Grid>
            
            <Grid item xs={12} md={4} xl={6}>
            <TextField
            label="Mobile Number"
            id="outlined-size-small"
            value={mobileNum}
            onChange={(e)=>setMobileNum(e.target.value)}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            />
            </Grid>

            <Grid item xs={12} md={4} xl={6}>
            <TextField
            label="Mail ID"
            id="outlined-size-small"
            value={mailId}
            onChange={(e)=>setMailId(e.target.value)}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            />
            </Grid>

            <Grid item xs={12} md={4} xl={6}>
            <TextField
            id="outlined-select-currency"
            select
            label="Role"
            size='small'
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className={`w-auto`}
            InputProps={{
              readOnly: true,
            }}
            >
            {ROLES.map((r) => (
            <MenuItem key={r.id} value={r.id}>{r.role}</MenuItem>
            ))}
            </TextField>
            </Grid>

            {role == 2 && <Grid item xs={12} md={4} xl={6}>
            <TextField
            id="outlined-select-currency"
            // select
            label="Region"
            size='small'
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            InputProps={{
              readOnly: true,
            }}
            >
            {/* <MenuItem value={'RJN'}>Rajnandgaon</MenuItem> */}
            </TextField>
            </Grid>}

            {role == 1 || role == 5 &&<Grid item xs={12} md={4} xl={6}>
            <TextField
            label="Campaign"
            id="outlined-size-small"
            value={campaign}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            />
            </Grid>}

            <Grid item xs={12}>
            {/* <Button type="submit" variant="contained" color="primary" className={`float-right`}>
              Update
            </Button> */}
          </Grid>
            
            </Grid>
            </Box>
            </form>
            </AccordionDetails>
        </Accordion>
    </>
  );
}

export default ProfileComponent;