import React from "react";
import ActionTabComponent from "./ActionTabComponent";

function ActionWiseLeadComponent() {
    return(
        <>
        <ActionTabComponent></ActionTabComponent>
        </>
    );
}

export default ActionWiseLeadComponent;