import React from 'react';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const EstimationDetailPanelTable = ({ data }) => {
    if (!data || data.length === 0) return <Typography>No updates available.</Typography>;

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case 'hot':
        return 'error'; // React blue
      case 'warm':
        return 'warning'; // JavaScript yellow
      case 'cold':
        return 'info'; // UI dark blue
      case 'closed':
        return 'default'
      case 'pending':
        return 'error';
      case 'generated':
        return 'success';
      case 'need for approval':
        return 'warning';
      default:
        return 'default'; // Default gray
    }
  };

    return(
        <>
         <TableContainer component={Paper} sx={{width: 'fit-content'}}>
            <Table sx={{ width: 'fit-content' }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#EEF0F4'}}>
                <TableRow>
                <TableCell style={{textAlign: 'center', minWidth: '20px'}}>S.No</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Estimation Submitted On</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Status</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px', textTransform: 'capitalize'}}>Category</TableCell>
                <TableCell  style={{textAlign: 'center', minWidth:'50px'}}>Sub Category</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Follow-up Date</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Closure Month</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Estimation Requirement</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Civil Constructed Started</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Shed Length</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Equipement Placement Length</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Shed Width</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>C-Shape</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Model Type</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Side Wall Column To Column Gap</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Number of Sheds</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Curtain Requirment</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Diesel Brooder Requirment</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Water Available</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Remarks</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell style={{textAlign: 'center', minWidth: '20px'}}>{row.sno}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.createdAt}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>
                    <Chip key={`index_${row.category}`} size='small' color={`${getColorForTag(row.status)}`} label={row.status} sx={{textTransform: 'capitalize', height: '20px'}}></Chip>
                    </TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px', textTransform: 'capitalize'}}>
                        <Chip key={`index_${row.category}`} size='small' color={`${getColorForTag(row.category)}`} label={row.category} sx={{textTransform: 'capitalize', height: '20px'}}></Chip>
                    </TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.sub_category}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.follow_up_date}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.closure_month}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px', textTransform: 'capitalize'}}><strong>{row.EstimationRequirement}</strong></TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.CivilConstructedStarted}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.ShedLength}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.EquipementPlacementLength}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.ShedWidth}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.CShape}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.MOdalType}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.SideWallColumnToColumnGap}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.NumberOfSheds}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.CurtainRequirment}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.DiselBrooderRequirment}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.PowerSupply}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.Remarks}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}

export default EstimationDetailPanelTable;