import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { Grid, Button, Card } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

function BiDetailsComponent() {
    // Mock data for lead details
    const mockLeadDetails = {
        Lot_Number: "B758B37402",
        Farmer_Name: "John Doe",
        Mobile: "1234567890",
        Zone_Name: "North",
        Branch_Name: "Branch A",
        Vendor: "Vendor X",
        Shed_Type: "Type 1",
        Hatch_Date: "2023-01-01",
        Placed_Qty: 1000,
        CA: "CA Value",
        Age_SAP: 30,
        Diff: "Diff Value",
        First_Week_M: 10,
        First_Week_Mortality_Percentage: "1%",
        Total_Mortality: 50,
        Total_Mortality_Percentage: "5%",
        Lifting_EA: "Lifting EA Value",
        Lift_Percentage: "95%",
        Avg_Lift_Wt: 2000,
        Bal_Birds: 950,
        ABWT: 2100,
        BWT_Age: 28,
        Feed_Cons: 3000,
        Prev_Grade: "A",
        FCR: 1.5,
        Line: "Line A",
        Hatchery_Name: "Hatchery X"
    };

    // Mock data for remarks list
    const mockRemarks = [
        {
            id: 1,
            Branch: 'Branch A',
            Branch_Description: 'Description A',
            Farm_Name: 'Farm A',
            Farmer_Mob: '1234567890',
            Lot_Number: 'B758B37402',
            Age: 30,
            Chicks_Housed_Quantity: 1000,
            Mortality_Quantity: 50,
            Mortality_Percentage: '5%',
            Balance_Birds: 950,
            Mort_On_Date: '5%',
            Mort_Date_1: '4%',
            Mort_Date_2: '6%',
            Mort_Date_3: '5%',
            Mort_Date_4: '5%'
        },
        // More data items
    ];

    // Update the initial form values based on the new column names
    const [formValues, setFormValues] = useState({
        Branch: '',
        Branch_Description: '',
        Farm_Name: '',
        Farmer_Mob: '',
        Lot_Number: '',
        Age: '',
        Chicks_Housed_Quantity: '',
        Mortality_Quantity: '',
        Mortality_Percentage: '',
        Balance_Birds: '',
        Mort_Percentage_On_Date: '',
        Mort_Date_1: '',
        Mort_Date_2: '',
        Mort_Date_3: '',
        Mort_Date_4: '',
        Remarks: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formValues);
        // Here you would typically send the data to an API
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Branch', headerName: 'Branch', width: 130 },
        { field: 'Branch_Description', headerName: 'Branch Description', width: 180 },
        { field: 'Farm_Name', headerName: 'Farm Name', width: 150 },
        { field: 'Farmer_Mob', headerName: 'Farmer Mob', width: 150 },
        { field: 'Lot_Number', headerName: 'Lot Number', width: 150 },
        { field: 'Age', headerName: 'Age', width: 80 },
        { field: 'Chicks_Housed_Quantity', headerName: 'Chicks Housed Quantity', width: 200 },
        { field: 'Mortality_Quantity', headerName: 'Mortality Quantity', width: 150 },
        { field: 'Mortality_Percentage', headerName: 'Mortality %', width: 150 },
        { field: 'Balance_Birds', headerName: 'Balance Birds', width: 150 },
        { field: 'Mort_Percentage_On_Date', headerName: 'Mort(%):On Date', width: 150 },
        { field: 'Mort_Date_1', headerName: 'Mort(%):Date-1', width: 150 },
        { field: 'Mort_Date_2', headerName: 'Mort(%):Date-2', width: 150 },
        { field: 'Mort_Date_3', headerName: 'Mort(%):Date-3', width: 150 },
        { field: 'Mort_Date_4', headerName: 'Mort(%):Date-4', width: 150 },
    ];

    return (
        <>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography>Lead Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    {Object.entries(mockLeadDetails).map(([key, value]) => (
                        <Grid item xs={12} md={4} key={key}>
                            <Typography variant='body2'>
                                <strong>{key.replace(/_/g, ' ')}:</strong> {value}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography>Remarks List</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <DataGrid 
                    rows={mockRemarks} 
                    columns={columns} 
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    slots={{ toolbar: GridToolbar }}
                    sx={{ minHeight: 250 }}
                />
            </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography>Remark Form</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Card>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={2} p={3}>
                            {Object.keys(formValues).map((key) => (
                                <Grid item xs={12} md={6} key={key}>
                                    <TextField
                                        label={key.replace(/_/g, ' ')}
                                        name={key}
                                        value={formValues[key]}
                                        onChange={handleChange}
                                        fullWidth
                                        size="small"
                                        multiline={['Remarks'].includes(key)}
                                        rows={['Remarks'].includes(key) ? 4 : 1}
                                        type={['Chicks_Housed_Quantity', 'Mortality_Quantity'].includes(key) ? 'number' : 'text'}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" p={3}>
                            <Button type="submit" variant="contained">
                                Save
                            </Button>
                        </Box>
                    </form>
                </Card>
            </AccordionDetails>
        </Accordion>
        </>
    );
}

export default BiDetailsComponent;
