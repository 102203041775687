import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment/moment";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import CallIcon from "@mui/icons-material/Call";
import CalculateIcon from "@mui/icons-material/Calculate";
import AddIcon from "@mui/icons-material/Add";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { Chip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import PlaceIcon from "@mui/icons-material/Place";

const LogTimelineComponent = ({ timelineData }) => {
  // Function to determine icon based on action_type
  const getIcon = (actionType) => {
    switch (actionType) {
      case "Lead Created by Agent":
        return <AddIcon />;
      case "Lead Created by bulk upload":
        return <AddIcon />;
      case "Lead Created by BDM":
        return <AddIcon />;
      case "Follow-up by Agent":
        return <EditCalendarIcon />;
      case "On Call Discussion By BDM":
        return <CallIcon />;
      case "Meeting Completed":
        return <MeetingRoomIcon />;
      case "Site Visit Completed":
        return <PlaceIcon />;
      case "Estimation Requested":
        return <CalculateIcon />;
      case "Estimation Need for Approval":
        return <CalculateIcon />;
      case "Estimation Generated":
        return <CalculateIcon />;
      case "Estimation Shared":
        return <CalculateIcon />;
      case "Estimation Rejected":
        return <CalculateIcon />;
      case "Lead Converted":
        return <SyncIcon />;
      default:
        return <StarIcon />;
    }
  };

  // Function to determine icon style based on action_type
  const getIconStyle = (actionType) => {
    switch (actionType) {
      case "Lead Created by Agent":
        return {
          background: "linear-gradient(to right, #42e695, #3bb2b8)",
          color: "#fff",
        }; // Green-Teal Gradient
      case "Lead Created by bulk upload":
        return {
          background: "linear-gradient(to right, #ff7e5f, #feb47b)",
          color: "#fff",
        }; // Peach-Orange Gradient
      case "Lead Created by BDM":
        return {
          background: "linear-gradient(to right, #ff7e5f, #feb47b)",
          color: "#fff",
        }; // Peach-Orange Gradient
      case "Follow-up by Agent":
        return {
          background: "linear-gradient(to right, #6a11cb, #2575fc)",
          color: "#fff",
        }; // Purple-Blue Gradient
      case "On Call Discussion By BDM":
        return {
          background: "linear-gradient(to right, #ff9966, #ff5e62)",
          color: "#fff",
        }; // Orange-Red Gradient
      case "Meeting Completed":
        return {
          background: "linear-gradient(to right, #f857a6, #ff5858)",
          color: "#fff",
        }; // Pink-Red Gradient
      case "Site Visit Completed":
        return {
          background: "linear-gradient(to right, #43cea2, #185a9d)",
          color: "#fff",
        }; // Teal-Navy Blue Gradient
      case "Estimation Requested":
        return {
          background: "linear-gradient(to right, #00c6ff, #0072ff)",
          color: "#fff",
        }; // Light Blue-Dark Blue Gradient
      case "Estimation Need for Approval":
        return {
          background: "linear-gradient(to right, #f7971e, #ffd200)",
          color: "#fff",
        }; // Orange-Yellow Gradient
      case "Estimation Generated":
        return {
          background: "linear-gradient(to right, #4facfe, #00f2fe)",
          color: "#fff",
        }; // Sky Blue Gradient
      case "Estimation Shared":
        return {
          background: "linear-gradient(to right, #36d1dc, #5b86e5)",
          color: "#fff",
        }; // Cyan-Indigo Gradient
      case "Estimation Rejected":
        return {
          background: "linear-gradient(to right, #36d1dc, #5b86e5)",
          color: "#fff",
        }; // Cyan-Indigo Gradient
      case "Lead Converted":
        return {
          background: "linear-gradient(to right, #56ab2f, #a8e063)",
          color: "#fff",
        }; // Green Gradient
      default:
        return {
          background: "linear-gradient(to right, #bdc3c7, #2c3e50)",
          color: "#fff",
        }; // Default Grey Gradient
    }
  };

  const formatDate = (dateString) =>
    moment(dateString).format("MMMM D, YYYY h:mm A");

  const getColorForActionType = (actionType) => {
    switch (actionType) {
      case "Lead Created by Agent":
        return "linear-gradient(to right, #42e695, #3bb2b8)"; // Green-Teal Gradient
      case "Lead Created by bulk upload":
        return "linear-gradient(to right, #ff7e5f, #feb47b)"; // Peach-Orange Gradient
      case "Lead Created by BDM":
        return "linear-gradient(to right, #ff7e5f, #feb47b)"; // Peach-Orange Gradient
      case "Follow-up by Agent":
        return "linear-gradient(to right, #6a11cb, #2575fc)"; // Purple-Blue Gradient
      case "On Call Discussion By BDM":
        return "linear-gradient(to right, #ff9966, #ff5e62)"; // Orange-Red Gradient
      case "Meeting Completed":
        return "linear-gradient(to right, #f857a6, #ff5858)"; // Pink-Red Gradient
      case "Site Visit Completed":
        return "linear-gradient(to right, #43cea2, #185a9d)"; // Teal-Navy Blue Gradient
      case "Estimation Requested":
        return "linear-gradient(to right, #00c6ff, #0072ff)"; // Light Blue-Dark Blue Gradient
      case "Estimation Need for Approval":
        return "linear-gradient(to right, #f7971e, #ffd200)"; // Orange-Yellow Gradient
      case "Estimation Generated":
        return "linear-gradient(to right, #4facfe, #00f2fe)"; // Sky Blue Gradient
      case "Estimation Shared":
        return "linear-gradient(to right, #36d1dc, #5b86e5)"; // Cyan-Indigo Gradient
      case "Estimation Rejected":
        return "linear-gradient(to right, #36d1dc, #5b86e5)"; // Cyan-Indigo Gradient
      case "Lead Converted":
        return "linear-gradient(to right, #56ab2f, #a8e063)"; // Green Gradient
      default:
        return "linear-gradient(to right, #bdc3c7, #2c3e50)"; // Default Grey Gradient
    }
  };

  return (
    <VerticalTimeline>
      {/* Static timeline element for a special case */}
      <VerticalTimelineElement
        iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
        icon={<StarIcon />}
      />

      {/* Map over timelineData to create dynamic timeline elements */}
      {timelineData.map((log, index) => (
        <VerticalTimelineElement
          key={index}
          className={`vertical-timeline-element--${log.action_type
            .toLowerCase()
            .replace(/ /g, "-")}`}
          date={
            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              <span>
                {log.createdAt
                  ? moment(log.createdAt).format("MMMM D, YYYY")
                  : ""}
              </span>
              <br />
              <span>
                {log.createdAt ? moment(log.createdAt).format("h:mm A") : ""}
              </span>
            </div>
          }
          iconStyle={{ ...getIconStyle(log.action_type) }}
          icon={getIcon(log.action_type)}
        >
          <div style={{ marginBottom: "5px" }}>
            <Chip
              label={log.action_type}
              style={{
                background: getColorForActionType(log.action_type), // Use the background gradient
                color: "#fff", // White text for contrast
                fontWeight: "bold", // Bold text
                padding: "10px 6px", // Add padding for a larger chip
                borderRadius: "8px", // Rounded chip for a modern look
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                letterSpacing: "0.5px", // Slight letter spacing for a more refined text
              }}
            />
          </div>

          <h4 className="vertical-timeline-element-subtitle">
            Status updated as{" "}
            <span style={{ textTransform: "capitalize" }}>
              <strong>{log.category}</strong>
            </span>{" "}
            by <strong>{log.performed_by}</strong> on{" "}
            {formatDate(log.createdAt)}
          </h4>
          <p
            style={{
              whiteSpace: "normal", // Allow wrapping
              wordWrap: "break-word", // Break long words if necessary
              overflowWrap: "break-word", // Ensures long words wrap correctly
              marginTop: "5px",
            }}
          >
            <strong>Remark:</strong>
            {log.remarks}
            <br />
            <strong>Next follow-up on</strong>{" "}
            {log.follow_up_date === null ? "-" : formatDate(log.follow_up_date)}
            <br />
            {/* <strong>Expected Closure on</strong> {log.follow_up_date === null ? '-' : formatDate(log.follow_up_date)} */}
          </p>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default LogTimelineComponent;
