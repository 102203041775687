import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import {
  BDM_LIST,
  CALL_STATUS,
  CALL_TYPE,
  CATEGORY,
  CLOSED_SUB_CATEGORY,
  COLD_SUB_CATEGORY,
  CREATE_LEAD_API,
  ENQUIRY_TYPE,
  FOLLOW_UP_BY_AGENT,
  GET_DETAILS_BY_MOBILE_NUMBER,
  GET_REGIONS,
  HOT_SUB_CATEGORY,
  PENDING_SUB_CATEGORY,
  PROJECT,
  REGION_TO_BDM,
  STATE_API,
  STATE_TO_REGION,
  TITLE,
  WARM_SUB_CATEGORY,
} from "../../constants";
import dashboardStyles from "./dashboardStyles.module.css";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

function LeadFormComponent({
  setFormOpen,
  FormMobileNo,
  setOpenSnackbar,
  setSeverity,
  setMessage,
  setalertTitle,
  mobNo,
}) {
  const empDetails = JSON.parse(localStorage.getItem("userData"));

  const [project, setProject] = useState("");
  const [callType, setCallType] = useState("");
  const [title, setTitle] = useState("");

  const [siteLocationAddress, setSiteLocationAddress] = useState("");
  const [leadTransferDate, setLeadTransferDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [leadOwner, setLeadOwner] = useState("");

  const [agentRemark, setAgentRemark] = useState("");

  const [followUpDate, setFollowUpDate] = useState(undefined);
  const [closureMonth, setClosureMonth] = useState(undefined);
  const [callStatus, setCallStatus] = useState("");
  const [bdmID, setBdmId] = useState("");
  const [stateList, setStateList] = useState({});
  const [placesList, setPlacesList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [titleError, setTitleError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    if (value.trim() === "") {
      setFirstName("");
      setFirstNameError("First Name is required.");
    } else {
      // Check if value consists only of alphabetic characters
      if (/^[A-Za-z]*$/.test(value)) {
        setFirstName(value);
        setFirstNameError("");
      } else {
        setFirstNameError("Only alphabetic characters are allowed.");
      }
    }
  };

  const [middleName, setMiddleName] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const handleMiddleNameChange = (e) => {
    const value = e.target.value;
    // Check if value consists only of alphabetic characters
    if (/^[A-Za-z]*$/.test(value)) {
      setMiddleName(value);
      setMiddleNameError("");
    } else {
      setMiddleNameError("Only alphabetic characters are allowed.");
    }
  };

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const handleLastNameChange = (e) => {
    const value = e.target.value;
    if (value.trim() === "") {
      setLastName("");
      setLastNameError("Last Name is required.");
    } else {
      // Check if value consists only of alphabetic characters
      if (/^[A-Za-z]*$/.test(value)) {
        setLastName(value);
        setLastNameError("");
      } else {
        setLastNameError("Only alphabetic characters are allowed.");
      }
    }
  };

  const [loading, setLoading] = useState(false);

  const [mobileNumber, setMobileNumber] = useState(FormMobileNo || mobNo || "");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;

    // Validate for empty value
    if (value.trim() === "") {
      setMobileNumber("");
      handleReset();
      setMobileNumberError("Mobile Number is required");
    } else {
      // Validate for numeric characters only and length
      if (/^\d{0,10}$/.test(value)) {
        setMobileNumber(value);
        setMobileNumberError("");
        mobileNumberApiChange(value);
      } else {
        setMobileNumberError("Mobile Number should be 10 digits");
      }
    }
  };

  // useEffect(()=>{
  //   mobileNumberApiChange(mobNo);
  // },[mobNo]);

  const [fetchStatus, setFetchStatus] = useState(false);

  const [isFollowUp, setIsFollowUp] = useState(false);

  const mobileNumberApiChange = (value) => {
    if (value.length == 10) {
      // setLoading(true);
      axios
        .get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${value}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          const { lead } = response.data;
          console.log(response.data.message);
          if (response.data.message == undefined) {
            setFetchStatus(true);
            setIsFollowUp(true);
            setLeadData(lead, response.data.message);
          } else {
            setFetchStatus(false);
            setIsFollowUp(false);
            handleReset();
          }
          // setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (FormMobileNo !== "") {
      mobileNumberApiChange(FormMobileNo);
    }
  }, [FormMobileNo]);

  const [leadId, setLeadId] = useState("");

  const setLeadData = (data, message) => {
    if (message == undefined) {
      setMessage(
        `Lead with this Mobile number already exists. Please fill the follow-up details for them through edit option.`
      );
    } else {
      setMessage(message);
    }
    setSeverity("success");
    setOpenSnackbar(true);

    setLeadId(data.id);
    setEnquiryType(data.InquiryType);
    setCallType(data.call_type);
    setProject(data.Project);
    setFirstName(data.CustomerName);
    setMobileNumber(data.MobileNo);
    setAlternateNumber(data.AlternateMobileNo);
    setWhatsappNumber(data.WhatsappNo);
    setMailId(data.CustomerMailId);
    setPincode(data.pincode);
    setState(data.state_name);
    setRegion(data.region_name);
    setLocation(data.location);
    setSiteLocationAddress(data.site_location_address);
    setLeadSource(data.source_of_lead_generated);
    setLeadOwner(formatLeadOwner(data.BDMId));
  };

  const formatFollowUpDate = (date) => {
    if (date === null) {
      setFollowUpDate(undefined);
    } else {
      const initialDate = dayjs(date, "MM/DD/YYYY hh:mm A");
      setFollowUpDate(initialDate);
    }
  };

  const formatLeadOwner = (data) => {
    const bdm = BDM_LIST.filter((item) => item.value == data);
    return bdm[0].label;
  };

  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [whatsappNumberError, setWhatsappNumberError] = useState("");
  const handleWhatsappNumberChange = (e) => {
    const value = e.target.value;
    // Validate for numeric characters only and length
    if (/^\d{0,10}$/.test(value)) {
      setWhatsappNumber(value);
      setWhatsappNumberError("");
    } else {
      setWhatsappNumberError("Whatsapp Number should be 10 digits");
    }
  };

  const [alternateNumber, setAlternateNumber] = useState("");
  const [alternateNumberError, setAlternateNumberError] = useState("");
  const handleAlternateNumberChange = (e) => {
    const value = e.target.value;
    // Validate for numeric characters only and length
    if (/^\d{0,10}$/.test(value)) {
      setAlternateNumber(value);
      setAlternateNumberError("");
    } else {
      setAlternateNumberError("Alternate Number should be 10 digits");
    }
  };

  const [mailId, setMailId] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (e) => {
    const value = e.target.value;
    // Validate for email format
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setMailId(value);
      setEmailError("");
    } else {
      setMailId(value);
      setEmailError("Invalid email format.");
    }
  };

  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const handlePincodeChange = (e) => {
    const value = e.target.value;

    setState("");
    setRegion("");
    if (project === "Parivartan") {
      setLeadOwner("");
      console.log("here");
    }
    setLocation("");

    // if(value == ''){
    //     setState('');
    //     setRegion('');
    //     setLocation('');
    //     setLeadOwner('');
    // }

    // Validate pincode length and numeric characters
    if (value.trim() === "") {
      setPincode(value);
      setPincodeError("Pincode is required");
    } else if (/^\d*$/.test(value)) {
      setPincode(value);
      setPincodeError("");

      // Call API when pincode is valid
      // handlePincodeApi(value);
      getStateApi(value);
    } else {
      setPincode(value);
      setPincodeError("Invalid pincode format");
    }
  };

  const getStateApi = (value) => {
    if (value.length == 6) {
      axios
        .get(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          console.log(response.data[0].PostOffice);
          if (response.data[0].PostOffice == null) {
            console.log("response.Message", response.data[0]);
            setSeverity("error");
            setMessage(response.data[0].Message);
            setOpenSnackbar(true);
            return;
          }

          const stateName =
            response.data[0].PostOffice[0].State === "Chattisgarh"
              ? "Chhattisgarh"
              : response.data[0].PostOffice[0].State;
          setState(stateName);
          getRegionFromState(stateName);

          // Map each item to an object with the 'names' key
          const namesArray = response.data[0].PostOffice.map((item) => ({
            names: item.Name,
          }));

          setPlacesList(namesArray);
        })
        .catch((error) => {
          console.error("Error fetching pincode data:", error);
        });
    }
  };

  const getRegionFromState = (state_name) => {
    axios
      .get(`${STATE_TO_REGION}/${state_name}`)
      .then((response) => {
        setCityList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pincode data:", error);
      });
  };

  const [bdmNameList, setBdmNameList] = useState([]);
  const handleRegionApi = (state_name) => {
    axios
      .get(`${GET_REGIONS}${state_name}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setCityList(response.data);
      })
      .catch((error) => {});
  };

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const handleStateChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setState(value);
      setStateError("State is required.");
    } else {
      setState(value);
      setStateError("");
    }
  };

  const [region, setRegion] = useState("");
  const [regionError, setRegionError] = useState("");
  const handleRegionChange = (e) => {
    const value = e.target.value;
    setHideLocationList(true);
    setLocationType(false);
    setLocation("");
    if (value.trim() === "") {
      setRegion(value);
      setRegionError("State is required.");
    } else {
      setRegion(value);
      setRegionError("");
      if (project === "Parivartan") {
        getBDMNameByRegion(value);
      }
    }
  };

  // Function to get BDMName based on RegionName
  const getBDMNameByRegion = (regionName) => {
    axios
      .get(`${REGION_TO_BDM}/${regionName}`)
      .then((response) => {
        setLeadOwner(response.data.EmployeeName);
        setBdmId(response.data.EmployeeId);
      })
      .catch((error) => {
        console.error("Error fetching pincode data:", error);
      });
  };

  const [locationType, setLocationType] = useState(false);
  const [hideLocationList, setHideLocationList] = useState(true);

  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const handleLocationChange = (e) => {
    const value = e.target.value;
    if (value == "other") {
      setLocationType(true);
      setHideLocationList(false);
    } else {
      setHideLocationList(true);
    }

    if (value.trim() === "") {
      setLocation(value);
      setLocationError("Location is required.");
    } else {
      setLocation(value);
      setLocationError("");
    }
  };

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const handleCategoryChange = (e) => {
    const value = e.target.value;
    feildsShow(value);
    if (value.trim() === "") {
      setCategory(value);
      setCategoryError("State is required.");
    } else {
      setCategory(value);
      setCategoryError("");
    }
  };

  const [subCategory, setSubCategory] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const handleSubCategoryChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setSubCategory(value);
      setSubCategoryError("Sub-Category is required.");
    } else {
      setSubCategory(value);
      setSubCategoryError("");
    }
  };

  const [leadSource, setLeadSource] = useState("");
  const [leadSourceError, setLeadSourceError] = useState("");
  const [agentRemarkError, setAgentRemarkError] = useState("");

  const [enquiryType, setEnquiryType] = useState("");
  const [enquiryTypeError, setEnquiryTypeError] = useState("");
  const handleEnquiryTypeChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setEnquiryType(value);
      setEnquiryTypeError("Enquiry Type is required.");
    } else {
      setEnquiryType(value);
      setEnquiryTypeError("");
    }
  };

  const handleFollowupDateTime = (e) => {
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Function to pad numbers with leading zeros
    const pad = (num) => (num < 10 ? "0" + num : num);

    // Extract date components
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Formatted date and time string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    setFollowUpDate(formattedDate);
  };

  const handleChangeExpectedClosureMonth = (e) => {
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract month and year
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Formatted string
    const formattedDate = `${monthName} ${year}`;
    setClosureMonth(formattedDate);
  };
  const formatDate = (dateString) => dayjs(dateString).format("YYYY-MM-DD");

  const [showField, setShowField] = useState(false);
  const [enableField, setEnableField] = useState(false);

  const feildsShow = (cat) => {
    if (cat == "hot" || cat == "warm") {
      setEnableField(true);
    } else {
      setEnableField(false);
    }
    if (cat == "pending") {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };

  const handleReset = () => {
    setEnquiryType("");
    setCallType("");
    setProject("");
    setFirstName("");
    setMiddleName("");
    setTitle("");
    setLastName("");
    // setMobileNumber('');
    setAlternateNumber("");
    setWhatsappNumber("");
    setMailId("");
    setPincode("");
    setState("");
    setRegion("");
    setLocation("");
    setSiteLocationAddress("");
    setCategory("");
    setSubCategory("");
    setAgentRemark("");
    setFollowUpDate(undefined);
    setLeadTransferDate(dayjs().format("YYYY-MM-DD"));
    setLeadOwner("");
    setLeadSource("");
    setClosureMonth(undefined);
    setCallStatus("");
    setFetchStatus(false);

    setMobileNumberError("");
    setEnquiryTypeError("");
    setFirstNameError("");
    setLastNameError("");
    setPincodeError("");
    setStateError("");
    setRegionError("");
    setCategoryError("");
    setSubCategoryError("");
    setLeadSourceError("");
    setLocationError("");
    setTitleError("");
    setHideLocationList(true);
  };

  const [isMandatory, setIsMandatory] = useState(true);
  const handleIsMandatory = (value) => {
    if (value == "Failed" || value == "pending") {
      setIsMandatory(false);
    } else {
      setIsMandatory(true);
    }
  };

  const handleSubmit = (e) => {
    let isValid = true;

    if (isFollowUp === true) {
      if (category.trim() === "") {
        setCategoryError("Category is required.");
        isValid = false;
      }

      if (subCategory.trim() === "") {
        setSubCategoryError("Sub-Category is required.");
        isValid = false;
      }

      // if (agentRemark.trim() === ''){
      //     setAgentRemarkError('Remark is required.');
      //     isValid = false;
      // }

      if (isValid) {
        const formData = {
          follow_up_date: followUpDate,
          category: category,
          sub_category: subCategory,
          LeadDetailId: leadId,
          AgentId: empDetails.EmployeeId,
          remark: agentRemark,
          closure_month: closureMonth,
        };
        console.log("formData", formData);
        axios
          .post(FOLLOW_UP_BY_AGENT, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            setSeverity("success");
            setMessage(response.data.message);
            setOpenSnackbar(true);
            setFormOpen(false);
            setFetchStatus(false);
          })
          .catch((error) => {
            setFormOpen(false);
            setFetchStatus(false);
            if (error.response) {
              if (error.response.status == 404) {
                setSeverity("error");
                setMessage("404 Not Found.");
                setOpenSnackbar(true);
              } else {
                setSeverity("error");
                setMessage(error.response.data.message);
                setOpenSnackbar(true);
              }
            } else if (error.request) {
              // The request was made but no response was received
              console.log("Request:", error.request);
              setSeverity("error");
              setMessage("Internal Server Error");
              setOpenSnackbar(true);
            }
          });
      } else {
        console.log("Form submission blocked due to validation errors.");
        setSeverity("error");
        setMessage("Please check for missing fields.");
        setOpenSnackbar(true);
      }
    } else {
      if (callStatus == "Failed" || category == "pending") {
        if (enquiryType.trim() === "") {
          setEnquiryTypeError("Enquiry Type is required.");
          isValid = false;
        }

        if (leadSource.toString().trim() == "") {
          setLeadSourceError("Lead Source is required.");
          isValid = false;
        }

        if (agentRemark.trim() === "") {
          setAgentRemarkError("Remark is required.");
          isValid = false;
        }
      } else {
        if (enquiryType.trim() === "") {
          setEnquiryTypeError("Enquiry Type is required.");
          isValid = false;
        }

        if (firstName.trim() === "") {
          setFirstNameError("First Name is required.");
          isValid = false;
        }

        if (!/^[A-Za-z]*$/.test(middleName)) {
          setMiddleNameError("Only alphabetic characters are allowed.");
          isValid = false;
        }

        if (lastName.trim() === "") {
          setLastNameError("Last Name is required.");
          isValid = false;
        }

        if (title.trim() === "") {
          setTitleError("Title is required.");
          isValid = false;
        }

        if (mobileNumber.trim() === "") {
          setMobileNumberError("Mobile Number is required.");
          isValid = false;
        }

        if (mobileNumber.length < 10) {
          setMobileNumberError("Mobile Number should be 10 digits.");
          isValid = false;
        }

        if (pincode.trim() === "") {
          setPincodeError("Pincode is required.");
          isValid = false;
        }

        if (pincode.length < 6) {
          setPincodeError("Pincode should be 6 digits.");
          isValid = false;
        }

        if (state.trim() === "") {
          setStateError("State is required.");
          isValid = false;
        }

        if (region.trim() === "") {
          setRegionError("Region is required.");
          isValid = false;
        }

        if (location.trim() === "") {
          setLocation("Location is required.");
          isValid = false;
        }

        if (category.trim() === "") {
          setCategoryError("Category is required.");
          isValid = false;
        }

        if (subCategory.trim() === "") {
          setSubCategoryError("Sub-Category is required.");
          isValid = false;
        }

        if (whatsappNumber !== "") {
          if (whatsappNumber.length < 10) {
            setWhatsappNumberError("Whatsapp Number should be 10 digits");
            isValid = false;
          }
        }

        if (alternateNumber !== "") {
          if (alternateNumber.length < 10) {
            setAlternateNumberError(
              "Alternate Mobile Number should be 10 digits"
            );
            isValid = false;
          }
        }

        if (leadSource.toString().trim() == "") {
          setLeadSourceError("Lead Source is required.");
          isValid = false;
        }

        if (agentRemark.trim() === "") {
          setAgentRemarkError("Remark is required.");
          isValid = false;
        }
      }

      if (isValid) {
        // Perform your submit action here
        console.log("Form is valid. Perform submission.");
        const formData = {
          InquiryType: enquiryType === "" ? null : enquiryType,
          call_type: "Outbound",
          Project: project === "" ? null : project,
          CustomerName:
            title + " " + firstName + " " + middleName + " " + lastName,
          MobileNo: mobileNumber,
          WhatsappNo: whatsappNumber === "" ? null : whatsappNumber,
          AlternateMobileNo: alternateNumber === "" ? null : alternateNumber,
          CustomerMailId: mailId === "" ? null : mailId,
          pincode: pincode === "" ? null : pincode,
          state_name: state === "" ? null : state,
          region_name: region === "" ? null : region,
          location: location === "" ? null : location,
          siteLocationAddress:
            siteLocationAddress === "" ? null : siteLocationAddress,
          category: category === "" ? null : category,
          sub_category: subCategory === "" ? null : subCategory,
          agent_remark: agentRemark === "" ? null : agentRemark,
          follow_up_date:
            followUpDate == undefined ? null : formatDate(followUpDate),
          lead_transfer_date:
            leadTransferDate == undefined ? null : formatDate(leadTransferDate),
          lead_owner: leadOwner === "" ? null : leadOwner,
          source_of_lead_generated: leadSource == "" ? null : leadSource,
          close_month: closureMonth === "" ? null : closureMonth,
          AgentId: Number(localStorage.getItem("username")),
          lead_created_by: localStorage.getItem("empRole"),
          call_status: "connected",
          BDMId: bdmID === "" ? null : Number(bdmID),
        };

        axios
          .post(CREATE_LEAD_API, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            console.log(response.data);

            setSeverity("success");
            setMessage(response.data.message);
            setOpenSnackbar(true);

            setFormOpen(false);
          })
          .catch((error) => {
            setFormOpen(false);
            if (error.response) {
              console.log(error.response.data.message);

              setSeverity("error");
              setMessage(error.response.data.message);
              setOpenSnackbar(true);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.response.data.message);

              setSeverity("error");
              setMessage("Internal Server Error.");
              setOpenSnackbar(true);
            }
          });
      } else {
        console.log("Form submission blocked due to validation errors.");
        setSeverity("error");
        setMessage("Please check for missing fields.");
        setOpenSnackbar(true);
      }
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {/* Close Button */}
        {/* <IconButton
                aria-label="close"
                onClick={(e)=> setFormOpen(false)}
                sx={{
                position: 'absolute',
                top: 6,
                right: 8,
                zIndex: 1, // Ensure it stays on top of other elements
                }}
            >
    <CloseIcon />
  </IconButton> */}

        {/* Contact Details */}
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${dashboardStyles.accordian_heading} h-auto`}
            sx={{
              "& .Mui-expanded": {
                margin: "10px 0 !important",
              },
            }}
          > 
            <Typography>Contact Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box component={"form"} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mobile Number"
                    id="outlined-size-small"
                    value={mobileNumber}
                    autoComplete="off"
                    onChange={handleMobileNumberChange}
                    size="small"
                    fullWidth
                    error={!!mobileNumberError}
                    helperText={mobileNumberError}
                    required
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Enquiry Type"
                    fullWidth
                    defaultValue=""
                    // helperText="Please select your currency"
                    size="small"
                    value={enquiryType}
                    onChange={handleEnquiryTypeChange}
                    error={!!enquiryTypeError}
                    helperText={enquiryTypeError}
                    required
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {ENQUIRY_TYPE.map((eq) => (
                      <MenuItem
                        value={eq.label}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {eq.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Project"
                    fullWidth
                    defaultValue=""
                    size="small"
                    // helperText="Please select your project"
                    value={project}
                    onChange={(e) => {
                      setProject(e.target.value);
                      if (e.target.value === "Gen Nxt") {
                        setLeadOwner("Krishan Gopal");
                        setBdmId(10005413);
                        setCategory("");
                        setSubCategory("");
                      } else if (e.target.value === "Open Shed") {
                        setLeadOwner("Arshad");
                        setBdmId(10010792);
                        setCategory("cold");
                        setSubCategory("Want Only Integration");
                      } else {
                        setLeadOwner("");
                        setBdmId("");
                        setCategory("");
                        setSubCategory("");
                      }
                    }}
                  >
                    {PROJECT.map((p) => (
                      <MenuItem
                        value={p.label}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {p.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {fetchStatus ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Customer Name"
                      id="outlined-size-small"
                      value={firstName}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Title"
                        fullWidth
                        defaultValue=""
                        size="small"
                        // helperText="Please select your project"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        error={!!titleError}
                        helperText={titleError}
                        required={isMandatory}
                      >
                        {TITLE.map((p) => (
                          <MenuItem
                            value={p.label}
                            sx={{
                              borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                              marginBottom: "8px",
                              marginTop: "8px",
                            }}
                          >
                            {p.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Customer First Name"
                        id="outlined-size-small"
                        value={firstName}
                        autoComplete="off"
                        fullWidth
                        onChange={handleFirstNameChange}
                        size="small"
                        error={!!firstNameError}
                        helperText={firstNameError}
                        required={isMandatory}
                        InputProps={{
                          inputProps: {
                            pattern: "^[A-Za-z]*$",
                            title: "Only alphabetic characters are allowed",
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Customer Middle Name"
                        id="outlined-size-small"
                        value={middleName}
                        fullWidth
                        autoComplete="off"
                        onChange={handleMiddleNameChange}
                        size="small"
                        error={!!middleNameError}
                        helperText={middleNameError}
                        InputProps={{
                          inputProps: {
                            pattern: "^[A-Za-z]*$",
                            title: "Only alphabetic characters are allowed",
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Customer Last Name"
                        id="outlined-size-small"
                        value={lastName}
                        fullWidth
                        autoComplete="off"
                        onChange={handleLastNameChange}
                        size="small"
                        error={!!lastNameError}
                        helperText={lastNameError}
                        required={isMandatory}
                        InputProps={{
                          inputProps: {
                            pattern: "^[A-Za-z]*$",
                            title: "Only alphabetic characters are allowed",
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Whatsapp Number"
                    id="outlined-size-small"
                    autoComplete="off"
                    value={whatsappNumber}
                    onChange={handleWhatsappNumberChange}
                    size="small"
                    fullWidth
                    error={!!whatsappNumberError}
                    helperText={whatsappNumberError}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Alternate Number"
                    id="outlined-size-small"
                    autoComplete="off"
                    value={alternateNumber}
                    fullWidth
                    onChange={handleAlternateNumberChange}
                    size="small"
                    error={!!alternateNumberError}
                    helperText={alternateNumberError}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Customer Mail ID"
                    id="outlined-size-small"
                    value={mailId}
                    autoComplete="off"
                    onChange={handleEmailChange}
                    fullWidth
                    size="small"
                    error={!!emailError}
                    helperText={emailError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Postal Pincode"
                    id="outlined-size-small"
                    fullWidth
                    value={pincode}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handlePincodeChange(e);
                      }
                    }}
                    size="small"
                    required={isMandatory}
                    autoComplete="off"
                    error={!!pincodeError}
                    helperText={pincodeError}
                    InputProps={{
                      inputProps: {
                        maxLength: 6,
                        inputMode: "numeric",
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    label="State"
                    value={state}
                    onChange={handleStateChange}
                    // helperText="Please select your currency"
                    size="small"
                    fullWidth
                    required={isMandatory}
                    error={!!stateError}
                    helperText={stateError}
                    InputProps={{
                      inputProps: {
                        pattern: "^[A-Za-z]*$",
                        title: "Only alphabetic characters are allowed",
                        readOnly: true, // Makes the input read-only
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                  {/* {stateList && [stateList].map((pl) => (
                        <MenuItem value={pl.stateName}>{pl.stateName}</MenuItem>
                        ))} */}
                  {/* </TextField> */}
                </Grid>

                <Grid item xs={12} md={6}>
                  {fetchStatus ? (
                    <TextField
                      id="outlined-select-currency"
                      label="Region"
                      value={region}
                      fullWidth
                      //  onChange={handleStateChange}
                      size="small"
                    />
                  ) : (
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Region"
                      value={region}
                      onChange={handleRegionChange}
                      // helperText="Please select your currency"
                      size="small"
                      fullWidth
                      required={isMandatory}
                      error={!!regionError}
                      helperText={regionError}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          fontSize: "0.65rem",
                          marginLeft: "0",
                        },
                      }}
                    >
                      {/* {placesList} */}
                      {cityList &&
                        cityList.map((cl) => (
                          <MenuItem value={cl.RegionName}>
                            {cl.RegionName}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>

                {hideLocationList && (
                  <Grid item xs={12} md={6}>
                    {fetchStatus ? (
                      <TextField
                        id="outlined-select-currency"
                        label="Location"
                        fullWidth
                        value={location}
                        //  onChange={handleStateChange}
                        size="small"
                      />
                    ) : (
                      <TextField
                        label="Location"
                        select
                        id="outlined-size-small"
                        value={location}
                        fullWidth
                        onChange={handleLocationChange}
                        size="small"
                        required={isMandatory}
                        error={!!locationError}
                        helperText={locationError}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      >
                        {placesList &&
                          placesList.map((pl) => (
                            <MenuItem value={pl.names}>{pl.names}</MenuItem>
                          ))}
                        <MenuItem value={"other"}>Other</MenuItem>
                      </TextField>
                    )}
                  </Grid>
                )}

                {locationType && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Location"
                      id="outlined-size-small"
                      fullWidth
                      autoComplete="off"
                      // value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      size="small"
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Lead Source"
                    select
                    id="outlined-size-small"
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value)}
                    size="small"
                    fullWidth
                    required
                    error={!!leadSourceError}
                    helperText={leadSourceError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {empDetails.Campaigns.map((ls) => (
                      <MenuItem
                        value={ls.CampaignId}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {ls.CampaignName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Lead Owner"
                    id="outlined-size-small"
                    value={leadOwner}
                    fullWidth
                    onChange={(e) => {
                      setLeadOwner(e.target.value);
                      BDM_LIST.map((item) => {
                        if (item.label == e.target.value) {
                          setBdmId(item.value);
                        }
                      });
                    }}
                    size="small"
                    InputProps={{
                      readOnly: true, // Makes the input read-only
                    }}
                  ></TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Site Location Address"
                    id="outlined-size-small"
                    multiline
                    rows={4}
                    autoComplete="off"
                    fullWidth
                    value={siteLocationAddress}
                    onChange={(e) => setSiteLocationAddress(e.target.value)}
                    size="small"
                    InputProps={{
                      inputProps: {
                        maxLength: 100,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Disposition Status */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${leadFormStyles.accordian_heading} h-auto`}
            sx={{
              "& .Mui-expanded": {
                margin: "10px 0 !important",
              },
            }}
          >
            <Typography>Disposition Summary</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Category"
                    value={category}
                    onChange={(e) => {
                      handleCategoryChange(e);
                      handleIsMandatory(e.target.value);
                    }}
                    // helperText="Please select your currency"
                    size="small"
                    required={isMandatory}
                    error={!!categoryError}
                    helperText={categoryError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {CATEGORY.map((c) => (
                      <MenuItem
                        value={c.value}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {c.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Sub-Category"
                    value={subCategory}
                    onChange={handleSubCategoryChange}
                    size="small"
                    required={isMandatory}
                    error={!!subCategoryError}
                    helperText={subCategoryError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {category.toLowerCase() == "hot" &&
                      HOT_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "warm" &&
                      WARM_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "cold" &&
                      COLD_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "pending" &&
                      PENDING_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "closed" &&
                      CLOSED_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                {enableField && (
                  <Grid item xs={12} md={6}>
                    {fetchStatus ? (
                      <DatePicker
                        label="Lead Transfer Date"
                        value={dayjs(leadTransferDate)}
                      />
                    ) : (
                      <DatePicker
                        label="Lead Transfer Date"
                        value={dayjs(leadTransferDate)}
                        onChange={(e) => {
                          {
                            const date = new Date(e.$d);
                            const year = date.getFullYear();
                            const month = ("0" + (date.getMonth() + 1)).slice(
                              -2
                            ); // Months are zero indexed, so we add 1
                            const day = ("0" + date.getDate()).slice(-2);
                            const formattedDate = `${year}-${month}-${day}`;
                            setLeadTransferDate(formattedDate);
                          }
                        }}
                        disablePast
                      />
                    )}
                  </Grid>
                )}

                {(enableField || showField) && (
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {fetchStatus ? (
                        <DateTimePicker
                          label="Follow-up Date"
                          name="startDateTime"
                          value={dayjs(followUpDate)}
                          disablePast
                        />
                      ) : (
                        <DateTimePicker
                          label="Follow-up Date"
                          name="startDateTime"
                          onChange={handleFollowupDateTime}
                          disablePast
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    </LocalizationProvider>
                  </Grid>
                )}

                {enableField && (
                  <Grid item xs={12} md={6}>
                    {fetchStatus ? (
                      <DatePicker
                        label={"Expected Closure Month"}
                        views={["month", "year"]}
                        value={dayjs(closureMonth)}
                      />
                    ) : (
                      <DatePicker
                        label={"Expected Closure Month"}
                        views={["month", "year"]}
                        onChange={handleChangeExpectedClosureMonth}
                        disablePast
                      />
                    )}
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Remarks"
                    multiline
                    rows={4}
                    fullWidth
                    value={agentRemark}
                    inputProps={{
                      inputProps: {
                        maxLength: "250",
                      },
                    }}
                    style={{ resize: "both", overflow: "auto" }}
                    onChange={(e) => {
                      if (e.target.value.length <= 250) {
                        setAgentRemark(e.target.value);
                      }
                    }}
                    error={!!agentRemarkError}
                    helperText={agentRemarkError}
                  />
                  <Typography
                    variant="body2"
                    color={
                      agentRemark.length > 250 ? "error" : "text.secondary"
                    }
                    sx={{ marginLeft: "7px" }}
                  >
                    {agentRemark.length}/250
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2} // for spacing between buttons
          mt={2} // optional margin from top
        >
          <Button onClick={handleReset} variant="contained">
            Reset
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </Box>
      </div>
    </>
  );
}

export default LeadFormComponent;
