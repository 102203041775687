import * as React from "react";
import { useState, useContext, useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  MenuItem,
  FormControl,
  Select,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../LoginPage/LoginContext";
import axios from "axios";
import { BI_STATUS_UPDATE, BULK_UPLOAD_BI, GET_BI_LIST } from "../../constants";
import Button from "@mui/material/Button";
import biFormStyles from "./BiFromStyles.module.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { format, parseISO } from "date-fns";
import Loader from "../reusable Component/Loader";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";

function BiBoilerLeadListComponent({ rowOpen, setRunEffect }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const empDetails = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();

  const fileInputRef = useRef();

  const { setleadDetails } = useContext(LoginContext);

  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [message, setMessage] = useState("");

  const [openStatusDialog, setOpenStatusDialog] = useState(false);

  const IconCell = ({ row }) => (
    <IconButton
      style={{
        border: "1px solid",
        borderRadius: "4px",
        padding: "2px",
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
      color="primary"
      onClick={() => handleIconClick(row)}
    >
      <EditIcon fontSize="small" color="primary" />
    </IconButton>
  );

  const handleIconClick = (row) => {
    console.log(row);
    setleadDetails(row);
    navigate("/broodingLeadDetails");
  };

  // State for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { field: "SNo", headerName: "S.NO", width: 50 },
    { field: "Zone", headerName: "ZONE", width: isMobile ? 120 : 150 },
    { field: "Region", headerName: "REGION", width: isMobile ? 120 : 150 },
    { field: "Branch", headerName: "BRANCH", width: isMobile ? 120 : 150 },
    { field: "Lot_Number", headerName: "LOT NO", width: isMobile ? 120 : 150 },
    {
      field: "Farmer_Name",
      headerName: "FARMER NAME",
      width: isMobile ? 120 : 150,
    },
    { field: "Age", headerName: "AGE", width: isMobile ? 120 : 150 },
    {
      field: "Shed_Type",
      headerName: "SHED TYPE (EC/OPEN)",
      width: isMobile ? 120 : 150,
    },
    {
      field: "Chicks_Housed_Quantity",
      headerName: "CHICKS PLANNED/HOUSED",
      width: isMobile ? 120 : 150,
    },
    {
      field: "Farmer_Contact_No",
      headerName: "FARMER CONTACT NO",
      width: isMobile ? 120 : 150,
    },
    {
      field: "Lot_Status",
      headerName: "LOT STATUS",
      width: isMobile ? 120 : 150,
    },
    { field: "status", headerName: "Status", width: isMobile ? 180 : 80 },
    { field: "action", headerName: "Action", width: 80 },
  ];

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "open":
        return "error";
      case "closed":
        return "success";
      default:
        return "default";
    }
  };

  const [statusLotNumber, setStatusLotNumber] = useState("");

  const handleChipClick = (row) => {
    if (row.status == "closed") {
      setSeverity("warning");
      setAlertTitle(
        `The status for this Lot Number ${row.Lot_Number} is already closed.`
      );
      setOpenSnackbar(true);
    } else {
      setOpenStatusDialog(true);
      setStatusLotNumber(row.Lot_Number);
    }
  };

  const handleStatusChange = () => {
    const formData = {
      lotNumber: statusLotNumber,
      newStatus: "closed",
    };

    axios
      .put(BI_STATUS_UPDATE, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setSeverity("success");
        setAlertTitle(response.data.message);
        setOpenSnackbar(true);
        setOpenStatusDialog(false);
        setRunEffect(true);
      })
      .catch((error) => {
        setSeverity("error");
        setAlertTitle(error.message);
        setOpenSnackbar(true);
        setOpenStatusDialog(false);
      });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setOpen(true);
      setFile(file);
      // uploadFile(file);
    }
  };

  const handleUpload = () => {
    setUploading(true);
    setProgress(0);

    // Simulate file upload
    const uploadInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(uploadInterval);
          uploadFile(file);
          // setTimeout(() => setOpen(false), 1000);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 500);
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(BULK_UPLOAD_BI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        console.log("File uploaded successfully:", response.data);
        setSeverity("success");
        setAlertTitle(response.data.message);
        setMessage("Updated Count:" + response.data.updatedCount);
        setOpenSnackbar(true);

        setUploading(false);
        setOpen(false);

        fileInputRef.current.value = null;
        setRunEffect(true);
        // navigate(0);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // setRunEffect(false);
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenSnackbar(true);
      });
  };

  const handleCloseDialog = () => {
    if (!uploading) {
      setOpen(false);
      setFile(null);
    }
  };

  const handleStatusDialogClose = () => {
    setOpenStatusDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      {loading && <Loader open={loading} />}

      {/* <div className={`${auditFormStyles.upload_div}`}>
      <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".xlsx, .xls"
                onChange={handleFileChange}
      />
      <Button variant='outlined' color='primary'  onClick={handleButtonClick}  className={`float-right mr-2 ${auditFormStyles.upload_div}`}>
        <FileUploadIcon></FileUploadIcon>
        Bulk Upload
        </Button>
    </div> */}

      <Box
        sx={{
          height: "auto",
          width: "100%",
          maxWidth: "100vw",
          overflowX: "auto",
        }}
      >
        <TableContainer sx={{ maxHeight: "450px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    key={col.field}
                    align="center"
                    style={{ minWidth: col.width, backgroundColor: "#EEF0F4" }}
                  >
                    {col.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowOpen
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((col) => (
                      <TableCell
                        key={col.field}
                        align="center"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {col.field === "status" ? (
                          row[col.field] === "-" ? (
                            <p>-</p>
                          ) : (
                            <Chip
                              size="small"
                              label={row[col.field]}
                              color={getColorForTag(row[col.field])}
                              sx={{
                                textTransform: "capitalize",
                                height: "20px",
                              }}
                              onClick={() => handleChipClick(row)}
                            />
                          )
                        ) : col.field === "action" ? (
                          <IconCell row={row} />
                        ) : (
                          row[col.field]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rowOpen.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{"Confirm Upload"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {uploading
              ? "Uploading..."
              : "Are you sure you want to upload the selected file?"}
          </DialogContentText>
          {uploading && (
            <Box sx={{ width: "100%", mt: 2, height: "30px" }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {!uploading && (
            <>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpload} color="primary" autoFocus>
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStatusDialog}
        onClose={handleStatusDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{"Confirm Status Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to change the status for this ${statusLotNumber}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStatusChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* <AlertTitle>{alertTitle}</AlertTitle> */}
          {alertTitle}
        </Alert>
      </Snackbar>
    </>
  );
}

export default BiBoilerLeadListComponent;
