import React, {useState, useRef, useEffect} from 'react';
import BdmStatus from './BdmStatus';
import { FormControl, Grid, IconButton, InputLabel, Select, Typography } from '@mui/material';
import TeamOfferingTime from './TeamOfferingTime';
import TeamPerformance from './TeamPerformance';
import { Stack, TextField, MenuItem, Divider } from '@mui/material';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import DoneIcon from '@mui/icons-material/Done';
import { LEAD_ANALYTICS } from '../../constants';
import axios from 'axios';
import LeadAnalyticsTable from './LeadAnalyticsTable';
import AnalyticsActionCountTable from './AnalyticsActionCountTable';

function SupervisorDashboard({dateFilter}) {

  const [view, setView] = useState('region'); // Default view is 'region'

  return (
    <>
   <Grid container spacing={1} className="mb-4" wrap="wrap">
  <Grid item xs={12} sm={6} md={6} xl={6}>
    <BdmStatus dateFilter={dateFilter} />
  </Grid>

  <Grid item xs={12} sm={12} md={12} xl={12}>
    <FormControl  variant="outlined" style={{ marginBottom: '10px', width: '20%' }}>
      <InputLabel>Select View</InputLabel>
      <Select
        value={view}
        onChange={(e) => setView(e.target.value)} // Handle select change
        label="Select View"
        size='small'
      >
        <MenuItem value="region">Region</MenuItem>
        <MenuItem value="bdm">BDM</MenuItem>
      </Select>
    </FormControl>
  </Grid>

  <Grid item xs={12} sm={6} md={6} xl={7}>
    <LeadAnalyticsTable filter={view} dateFilter={dateFilter} />
  </Grid>

  <Grid item xs={12} sm={6} md={6} xl={5}>
    <AnalyticsActionCountTable filter={view} dateFilter={dateFilter} />
  </Grid>
</Grid>

    </>
  );
}

export default SupervisorDashboard;