import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import './analyticstable.css';
import axios from 'axios';
import { LEAD_ANALYTICS } from '../../constants';
import { Box, Typography } from '@mui/material';

function RegionWiseActionListComponent({regionWiseList}) {

    const totalRow = useMemo(() => {
        if (!regionWiseList || regionWiseList.length === 0) return null;

        return regionWiseList.reduce(
            (acc, item) => {
                return {
                    ...acc,
                    meeting_count: (acc.meeting_count || 0) + (item.meeting_count || 0),
                   
                };
            },
            {
                region_name: 'Total',
                meeting_count: 0,
            }
        );
    }, [regionWiseList]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'region_name', // Ensure this matches the data key
        header: 'Region Name',
        size: 150,
        footer: 'Total', // Static text for region_name column
    },
    {
        accessorKey: 'meeting_count',
        header: 'Meeting',
        size: 50,
        footer: totalRow?.meeting_count ?? 0, // Show the total for hot_count
    },
    
    ],
    []
  );
    

 // Prepare data without the total row in the main table body
 const data = useMemo(() => {
    return [...regionWiseList]; // Regular data without total row
}, [regionWiseList]);

const table = useMaterialReactTable({
    columns: columns,
    data: data,
    enableStickyHeader: true,
    enableStickyFooter: true, // Enable sticky footer
    enablePagination: false,
    enableGlobalFilter:false,
    enableColumnFilters: false,
    enableColumnActions: false, // Hide the column menu
    muiTableHeadCellProps:{
        sx: {
            padding: '8px',
            boxSizing: 'border-box',
            textAlign: 'center',
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
        },
    },
    muiTableBodyCellProps:({ row }) => ({
        sx: {
          
            padding: '8px',
            borderBottom: '1px solid #ddd',
            borderRight: '1px solid #ddd',
        },
    }),
    muiTableFooterCellProps:{
        sx: {
            
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
        },
    },
        //Simply adding a table title to the top-left of the top toolbar
        renderTopToolbarCustomActions: () => (
            <Typography variant="h5">Meeting Summary Report</Typography>
        ),
  });

return (
    <MaterialReactTable
    table={table}
    />
);
}

export default RegionWiseActionListComponent;
