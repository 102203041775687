import React, { useState , useEffect, useRef} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AuditLeadListComponent from './AuditLeadList';
import { GET_AUDIT_LIST } from '../../constants';
import Loader from '../reusable Component/Loader';
import axios from 'axios';
import { Button, Grid, useMediaQuery, useTheme} from '@mui/material';
import AuditClosedTableComponent from './AuditClosedTableComponent';
import AuditAllTableComponent from './AllStatusTableComponent';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AuditOpenList from './AuditOpenList';
import AuditClosedList from './AuditClosedList';
import AuditAllList from './AuditAllList';
import OpenDataListComponent from './OpenDataList';
import ClosedDataListComponent from './ClosedDataList';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};


function AuditTabComponent() {
    const [value, setValue] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [loading, setLoading] = useState(false);

    const [row, setRow] = useState([]);
    const [rowClosed, setRowClosed] = useState([]);
    const [rowAll, setRowAll] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const [runEffect,setRunEffect] = useState(false);

     

        const handleTraderClick = () => {
            navigate('/auditTraderList');
        };

        const handleInHouseClick = (e) =>{
            e.preventDefault();
        };

        const [statusCounts, setStatusCounts] = useState({ open: 0, closed: 0, working: 0 }); // Modify according to your actual status names
        const [statusCounts1, setStatusCounts1] = useState({ open: 0, closed: 0, working: 0 }); // Modify according to your actual status names
 
        console.log(statusCounts1)

    return (
        <>        
        {loading && <Loader open={loading} />}

        <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        >
            <Tab label={`Open (${statusCounts })`} id="tab-0" icon={<NewReleasesIcon />} iconPosition="start" aria-controls="tabpanel-1" />
            <Tab label={`Closed (${statusCounts1})`} id="tab-1" icon={<VerifiedIcon />} iconPosition="start" aria-controls="tabpanel-1" />
    
        </Tabs>
        <TabPanel value={value} index={0}>
            <OpenDataListComponent setStatusCounts={setStatusCounts} setStatusCounts1={setStatusCounts1}  ></OpenDataListComponent>
            {/* <AuditOpenList setLoading={setLoading}></AuditOpenList> */}
        </TabPanel>

        <TabPanel value={value} index={1}>
            <ClosedDataListComponent setStatusCounts1={setStatusCounts1}></ClosedDataListComponent>
        </TabPanel>
        
        </>
      );
    }
    
export default AuditTabComponent;