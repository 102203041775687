import React, { useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Grid, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, Select , useMediaQuery, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import dayjs from 'dayjs';
import { ENQUIRY_TYPE, PROJECT, CATEGORY,LEAD_SOURCE,HOT_SUB_CATEGORY,WARM_SUB_CATEGORY,COLD_SUB_CATEGORY,CLOSED_SUB_CATEGORY,PENDING_SUB_CATEGORY,STATE_API, BDM_REMARK_UPDATE, CREATE_MEETING, CREATE_SITE_VISIT, CREATE_ESTIMATION } from '../../constants';
import bdmFormStyles from '../BDM Assigned Page/bdmFormStyles.module.css';

function DispositionModalComponent({open, setOpen, meetingFormData,siteVisitFormData, estimateFormData, setShowMeetingForm,setSiteVisitForm,setEstimationForm, setSeverity, setMessage, setOpenSnackbar, formType, setRunEffect, resetForm}) {

    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [followUpDate, setFollowUpDate] =  useState(undefined);
    const [closureMonth, setClosureMonth] =  useState(undefined);
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const handleClose = (event, reason) =>{
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
        setOpen(false)
    }
   };
   
   const [error, setError] = useState('');

   const handleFollowupDateTime = (e) =>{
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Function to pad numbers with leading zeros
    const pad = (num) => (num < 10 ? '0' + num : num);

    // Extract date components
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Formatted date and time string
    const formattedDate = `${year}-${month}-${day}`;
    setFollowUpDate(formattedDate);
    if (formattedDate === undefined) {
        setError('Follow-up Date is required');
    } else {
        setError('');
    }
    
    // console.log(formattedDate);
};

const [ClosureMontherror, setClosureMonthError] = useState('');

const handleChangeExpectedClosureMonth = (e) =>{
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Extract month and year
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Formatted string
    const formattedDate = `${monthName} ${year}`;
    setClosureMonth(formattedDate);

    if (formattedDate === undefined) {
        setClosureMonthError('Closure Month is required');
    } else {
        setClosureMonthError('');
    }
}

const [categoryError, setCategoryError] = useState('');
const [subCategoryError, setSubCategoryError] = useState('');

const handleSubmit = () =>{
    let isValid = true;


    if(category.trim() == ''){
        setCategoryError('Category is required.');
        isValid = false;
    }

    if(subCategory.trim() == ''){
        setSubCategoryError('Sub-Category is required');
        isValid = false;
    }

    if (category == 'hot' || category == 'warm' || category == 'pending'){
        if (followUpDate === undefined) {
            setError('Follow-up Date is required');
            isValid = false;
        }
    } 

    if (category == 'hot' || category == 'warm'){
        if (closureMonth === undefined) {
            setClosureMonthError('Closure Month is required');
            isValid = false;
        }
    }

    if (isValid) {
    if (formType === 'meeting'){
    meetingFormData.append('category', category);
    meetingFormData.append('sub_category', subCategory);
    meetingFormData.append('follow_up_date', followUpDate);
    meetingFormData.append('closure_month', closureMonth);

    handleMeetingSubmit(meetingFormData);

    } else if (formType === 'site_visit'){
        siteVisitFormData.append('category', category);
        siteVisitFormData.append('sub_category', subCategory);
        siteVisitFormData.append('follow_up_date', followUpDate);
        siteVisitFormData.append('closure_month', closureMonth);

    handleSiteVisitSubmit(siteVisitFormData);
    } else if(formType === 'estimate'){
        console.log(estimateFormData)
        estimateFormData.category = category;
        estimateFormData.sub_category = subCategory;
        estimateFormData.follow_up_date = followUpDate;
        estimateFormData.closure_month = closureMonth;   
        
    handleEstimateSubmit(estimateFormData);
    }
    }else{
        console.log('Form submission blocked due to validation errors.');
            setSeverity('error');
            setMessage("Please check for missing fields.");
            setOpenSnackbar(true);
    }

}

const handleMeetingSubmit = (formData) =>{
    setLoading(true);
    axios.post(CREATE_MEETING, formData,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })
        .then(response => {
            console.log(response.data);
            setLoading(false);
            setSeverity('success');
            setMessage(response.data.message);
            setOpenSnackbar(true);
            setShowMeetingForm(false);
            setRunEffect(true);
            setOpen(false);
            resetForm();
        })
        .catch(error => {
            setLoading(false);
            if (error.response) {
            setShowMeetingForm(false);
            setSeverity('error');
            setMessage(error.response.data.details);
            setOpenSnackbar(true);
            setRunEffect(false);
            setOpen(false);
            resetForm();
            }else if (error.request) {
                // The request was made but no response was received
            console.log(error.response.data.details);
            setSeverity('error');
            setMessage("Internal Server Error.");
            setOpenSnackbar(true);
            setShowMeetingForm(false);
            setRunEffect(false);
            setOpen(false);
            resetForm();
            }
        });
}

const handleSiteVisitSubmit = (formData)=>{
    setLoading(true);
    axios.post(CREATE_SITE_VISIT, formData,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })
        .then(response => {
            console.log(response.data);
            setLoading(false);
            setSeverity('success');
            setMessage(response.data.message);
            setOpenSnackbar(true);
            setSiteVisitForm(false);
            setRunEffect(true);
            setOpen(false);
            resetForm();
        })
        .catch(error => {
            setLoading(false)
            if (error.response) {
            console.log(error.response.data.message);
            setSiteVisitForm(false);
            setSeverity('error');
            setMessage(error.response.data.message);
            setOpenSnackbar(true);
            setRunEffect(false);
            setOpen(false);
            resetForm();
            }else if (error.request) {
                // The request was made but no response was received
            console.log(error.response.data.message);
            setSeverity('error');
            setMessage("Internal Server Error.");
            setOpenSnackbar(true);
            setSiteVisitForm(false);
            setRunEffect(false);
            setOpen(false);
            resetForm();
                }
        });

}

const handleEstimateSubmit = (formData) =>{
    setLoading(true);
    axios.post(CREATE_ESTIMATION, formData,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })
    .then(response => {
        console.log(response.data);
        setLoading(false);
        setSeverity('success');
        setMessage(response.data.message);
        setOpenSnackbar(true);
        setEstimationForm(false);
        setRunEffect(true);
        setOpen(false);
            resetForm();
    })
    .catch(error => {
        console.log(error.response)
        setLoading(false);
        if (error.response) {
        console.log(error.response.data.error);
        setEstimationForm(false);
        setSeverity('error');
        setMessage(error.response.data.error);
        setOpenSnackbar(true);
        setRunEffect(false);
        setOpen(false);
            resetForm();
        }else if (error.request) {
            // The request was made but no response was received
        console.log(error.response.data.error);
        setSeverity('error');
        setMessage("Internal Server Error.");
        setOpenSnackbar(true);
        setEstimationForm(false);
        setRunEffect(false);
        setOpen(false);
            resetForm();
            }
    });
};

    const handleReset = () =>{
        setCategory('');
        setSubCategory('');
        setFollowUpDate(undefined);
        setClosureMonth(undefined);

        setEnableField(false);
        setShowField(false);
        setError('');
        setClosureMonthError('');
        setCategoryError('');
        setSubCategoryError('')
    }

    const [showField, setShowField] = useState(false);
    const [enableField, setEnableField] = useState(false);

    const feildsShow = (cat) =>{
        if(cat == 'hot' || cat == 'warm'){
            setEnableField(true);
        } else{
            setEnableField(false);
        }
        if (cat == 'pending'){
            setShowField(true);
        } else{
            setShowField(false);
        }
    }

    const inputRef = useRef(null);

  return (
    <>
   <Dialog
    open={open}
    onClose={handleClose}
    disableEscapeKeyDown
    fullScreen={fullScreen} // Adjusts dialog size based on screen width
    maxWidth="sm" // Limits maximum width of the dialog
    fullWidth // Ensures dialog takes full width of the screen
    scroll="paper" // Enables scrolling if content overflows
    
>
    <DialogTitle sx={{
        background: (theme) => theme.palette.primary.main,
        color: '#fff',
    }}>
        Add Category
    </DialogTitle>

    <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
        }}
    >
        <CloseIcon />
    </IconButton>

    <DialogContent dividers>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' }, // Adjust width for small screens
                '@media (max-width: 600px)': {
                    '& .MuiTextField-root': { m: 1, width: '100%' }, // Full width on mobile
                },
            }}
            noValidate
            autoComplete="off"
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        select
                        label="Category"
                        value={category}
                        onChange={(e) => {
                            setCategory(e.target.value);
                            setSubCategory('');
                            feildsShow(e.target.value);
                        }}
                        size="small"
                        required
                        error={!!categoryError}
                        helperText={categoryError}
                    >
                        {CATEGORY.map((c) => (
                            <MenuItem key={c.value} value={c.value}>
                                {c.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        select
                        label="Sub-Category"
                        value={subCategory}
                        onChange={(e) => setSubCategory(e.target.value)}
                        size="small"
                        required
                        error={!!subCategoryError}
                        helperText={subCategoryError}
                    >
                        {category === 'hot' && HOT_SUB_CATEGORY.map((h) => (
                            <MenuItem key={h.label} value={h.label}>
                                {h.label}
                            </MenuItem>
                        ))}
                        {category === 'warm' && WARM_SUB_CATEGORY.map((h) => (
                            <MenuItem key={h.label} value={h.label}>
                                {h.label}
                            </MenuItem>
                        ))}
                        {category === 'cold' && COLD_SUB_CATEGORY.map((h) => (
                            <MenuItem key={h.label} value={h.label}>
                                {h.label}
                            </MenuItem>
                        ))}
                        {category === 'pending' && PENDING_SUB_CATEGORY.map((h) => (
                            <MenuItem key={h.label} value={h.label}>
                                {h.label}
                            </MenuItem>
                        ))}
                        {category === 'closed' && CLOSED_SUB_CATEGORY.map((h) => (
                            <MenuItem key={h.label} value={h.label}>
                                {h.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {(enableField || showField) && (
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Follow-up Date"
                            onChange={handleFollowupDateTime}
                            disablePast
                            slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error,
                                },
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'flip',
                                      options: {
                                        fallbackPlacements: ['top'], // Forces the popper to open on top
                                      },
                                    },
                                  ],
                                },
                              }}
                        />
                    </Grid>
                )}

                {enableField && (
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Closure Month"
                            views={['month', 'year']}
                            onChange={handleChangeExpectedClosureMonth}
                            disablePast
                            slotProps={{
                                textField: {
                                  error: !!ClosureMontherror,
                                  helperText: ClosureMontherror,
                                },
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'flip',
                                      options: {
                                        fallbackPlacements: ['top'], // Forces the popper to open on top
                                      },
                                    },
                                  ],
                                },
                              }}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    </DialogContent>

    <DialogActions>
        <Button onClick={handleReset}>Reset</Button>
        <Button onClick={handleSubmit}>Save</Button>
    </DialogActions>
</Dialog>



        {loading && (
                <Box
                    sx={{
                        position: 'fixed', // Or 'absolute' depending on your layout
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: (theme) => theme.zIndex.modal + 1, // Higher than dialog z-index
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
         
    </>
  );
}

export default DispositionModalComponent;