import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { Divider, Stack, Grid, styled, FormHelperText } from "@mui/material";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Card,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonalDetailsFormComponent from "../BDM Assigned Page/personalDetailsForm";
import bdmFormStyles from "./bdmFormStyles.module.css";
import { CREATE_MEETING, MODEL_TYPE } from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import DispositionModalComponent from "../reusable Component/DispositionModalComponent";
import { useMediaQuery, useTheme, Chip } from "@mui/material";

const ResizableTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    "& textarea": {
      resize: "both", // Allow resizing both horizontally and vertically
      overflow: "auto", // Show scrollbars when necessary
      minHeight: "100px", // Set a minimum height for the textarea
    },
  },
});

function SiteVisitFormComponent({
  rowDetails,
  setSiteVisitForm,
  leadDetailsId,
  setSeverity,
  setMessage,
  setOpenSnackbar,
  setRunEffect,
}) {
  const [birdCapacity, setBirdCapacity] = useState("");
  const [landDimension, setLandDimension] = useState("");
  const [shedSize, setShedSize] = useState("");
  const [modelType, setModelType] = useState("");
  const [landDirection, setLandDirection] = useState("");
  const [directionDeviation, setDirectionDeviation] = useState("");
  const [electricityPower, setElectricityPower] = useState("");
  const [water, setWater] = useState("");
  const [approachRoad, setApproachRoad] = useState("");
  const [estimationReq, setEstimationReq] = useState("");

  const [images, setImages] = useState([]);
  const [bdmSelfie, setBdmSelfie] = useState(null);
  const [bdmSelfieShow, setBdmSelfieShow] = useState(null);
  const [customerSelfie, setCustomerSelfie] = useState(null);
  const [customerSelfieShow, setCustomerSelfieShow] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [otherImagesShow, setOtherImagesShow] = useState([]);
  const [imagesError, setImagesError] = useState("");
  const [imagesCustomerError, setImagesCustomerError] = useState("");

  const handleBDMSelfieChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const imageUrl = URL.createObjectURL(file);
      setBdmSelfie(file);
      setBdmSelfieShow(imageUrl);
      setImagesError("");
    } else {
      setImagesError("Only PNG and JPEG files are allowed.");
    }
  };

  const handleCustomerSelfieImageChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const imageUrl = URL.createObjectURL(file);
      setCustomerSelfie(file);
      setCustomerSelfieShow(imageUrl);
      setImagesCustomerError("");
    } else {
      setImagesCustomerError("Only PNG and JPEG files are allowed.");
    }
  };

  const handleMultipleUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + otherImages.length > 3) {
      setImagesError("You can upload a maximum of 3 images.");
      return;
    }

    const validImages = files.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    if (validImages.length !== files.length) {
      setImagesError("Only PNG and JPEG files are allowed.");
      return;
    }

    const newImagesShow = validImages.map((file) => URL.createObjectURL(file));
    setOtherImagesShow([...otherImagesShow, ...newImagesShow]);
    const newImages = validImages;
    setOtherImages([...otherImages, ...newImages]);
    setImagesError("");
  };

  const handleBDMSelfieRemoveImage = () => {
    setBdmSelfie(null);
    setBdmSelfieShow(null);
  };

  const handleCustomerSelfieRemoveImage = () => {
    setCustomerSelfie(null);
    setCustomerSelfieShow(null);
  };

  const handleRemoveMultipleImage = (index) => {
    const updatedImages = otherImages.filter((_, i) => i !== index);
    setOtherImages(updatedImages);

    const updatedImages1 = otherImagesShow.filter((_, i) => i !== index);
    setOtherImagesShow(updatedImages1);
  };

  const [remark, setRemark] = useState("");
  const [remarkError, setRemarkError] = useState("");

  const handleChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  const validateRemark = (value) => {
    if (value == "") {
      setRemarkError("Feild is required.");
      return false;
    } else {
      setRemarkError("");
      return true;
    }
  };

  const [dDShow, setDDShow] = useState(false);

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleImageChange = (event) => {
    const files = event.target.files;
    const uploadedImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const imageURL = URL.createObjectURL(file);
      uploadedImages.push({ file, imageURL });
    }

    setImages((prevImages) => [...prevImages, ...uploadedImages]);
    console.log(uploadedImages);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    // onImagesChange(updatedImages.map((image) => image.file)); // Pass updated array of file objects to parent component
  };

  const handleChangeLandDirection = (e) => {
    setLandDirection(e.target.value);
    if (e.target.value == "true") {
      setDDShow(false);
    } else {
      setDDShow(true);
    }
  };

  const handleChangeElectricityPower = (e) => {
    setElectricityPower(e.target.value);
  };

  const handleChangeWater = (e) => {
    setWater(e.target.value);
  };

  const handleChangeApproachRoad = (e) => {
    setApproachRoad(e.target.value);
  };

  const handleChangeEstimationReq = (e) => {
    setEstimationReq(e.target.value);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setSiteVisitForm(false);
    // if(viewMode){
    // handleViewMode(false);}
  };

  const [siteVisitFormData, setSiteVisitFormData] = useState({});

  const [birdCapacityError, setBirdCapacityError] = useState("");
  const [landDimensionError, setLandDimensionError] = useState("");
  const [ShedSizeError, setShedSizeError] = useState("");
  const [ModelTypeError, setModelTypeError] = useState("");
  const [landDirectionError, setLandDirectionError] = useState("");
  const [electricityPowerError, setElectricityPowerError] = useState("");
  const [waterError, setWaterError] = useState("");
  const [approachRoadError, setApproachRoadError] = useState("");
  const [bdmSelfieError, setBdmSelfieError] = useState("");
  const [EstimationReqError, setEstimationReqError] = useState("");
  const [customerSelfieError, setCustomerSelfieError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation flags
    let isValid = true;

    // Validate bird capacity
    if (!birdCapacity || isNaN(birdCapacity) || parseInt(birdCapacity) <= 0) {
      setBirdCapacityError("Please enter a valid bird capacity");
      isValid = false;
    } else {
      setBirdCapacityError("");
    }

    // Validate land dimension
    if (
      !landDimension ||
      isNaN(landDimension) ||
      parseInt(landDimension) <= 0
    ) {
      setLandDimensionError("Please enter a valid land dimension");
      isValid = false;
    } else {
      setLandDimensionError("");
    }

    // Validate shed size
    if (!shedSize || !/^\d+ ?X ?\d+$/.test(shedSize)) {
      setShedSizeError("Please enter a valid shed size (e.g., 10 X 10)");
      isValid = false;
    } else {
      setShedSizeError("");
    }

    // Validate model type
    if (!modelType) {
      setModelTypeError("Please select a model type");
      isValid = false;
    } else {
      setModelTypeError("");
    }

    // Validate land direction
    if (landDirection === null || landDirection === undefined) {
      setLandDirectionError("Please select a valid land direction");
      isValid = false;
    } else {
      setLandDirectionError("");
    }

    // Validate electricity power
    if (electricityPower === null || electricityPower === undefined) {
      setElectricityPowerError(
        "Please select if electricity power is available"
      );
      isValid = false;
    } else {
      setElectricityPowerError("");
    }

    // Validate water availability
    if (water === null || water === undefined) {
      setWaterError("Please select if water is available");
      isValid = false;
    } else {
      setWaterError("");
    }

    // Validate approach road
    if (approachRoad === null || approachRoad === undefined) {
      setApproachRoadError("Please select if approach road is available");
      isValid = false;
    } else {
      setApproachRoadError("");
    }

    // Validate estimation requirement
    if (estimationReq === null || estimationReq === undefined) {
      setEstimationReqError("Please select if estimation is required");
      isValid = false;
    } else {
      setEstimationReqError("");
    }

    // Validate BDM Selfie
    if (!bdmSelfie) {
      setBdmSelfieError("BDM Selfie is required");
      isValid = false;
    } else {
      setBdmSelfieError("");
    }

    // Validate Customer Selfie
    if (!customerSelfie) {
      setCustomerSelfieError("Customer Selfie is required");
      isValid = false;
    } else {
      setCustomerSelfieError("");
    }

    if (remark === "") {
      setRemarkError("Remark is required");
      isValid = false;
    } else {
      setRemarkError("");
    }

    // If all validations pass, proceed to form submission
    if (isValid) {
      const formData = new FormData();

      // Add regular form fields
      formData.append("LeadDetailId", leadDetailsId);
      formData.append("BDMId", localStorage.getItem("username"));
      formData.append("BirdsCapacity", birdCapacity);
      formData.append("LandDimension", landDimension);
      formData.append("ShedSize", shedSize);
      formData.append("ModelType", modelType);
      formData.append("IsLandDirectionEastWest", landDirection === "true");
      formData.append("ElectricityPower", electricityPower === "true");
      formData.append("Water", water === "true");
      formData.append("ApproachRoad", approachRoad === "true");
      formData.append("EstimationRequirement", estimationReq === "true");
      formData.append("DirectionDeviationDegree", directionDeviation);
      formData.append("ActionType", "sit-visit");
      formData.append("remark", remark);

      // Add single images (bdmSelfie, customerSelfie) if available
      if (bdmSelfie) {
        formData.append("images", bdmSelfie); // Append as images array
      }

      if (customerSelfie) {
        formData.append("images", customerSelfie); // Append as images array
      }

      // Add otherImages dynamically with the same 'images' key for each image
      otherImages.forEach((image) => {
        formData.append("images", image); // Append each image with the same key
      });

      console.log("formData", formData);
      setSiteVisitFormData(formData);
      setOpen(true);
    } else {
      console.log("Form validation failed");
    }
  };

  const resetForm = () => {
    setBirdCapacity("");
    setLandDimension("");
    setShedSize("");
    setModelType("");
    setLandDirection(null);
    setDirectionDeviation("");
    setElectricityPower(null);
    setWater(null);
    setApproachRoad(null);
    setEstimationReq(null);
    setImages([]);
    setRemark("");
    setBdmSelfie(null);
    setCustomerSelfie(null);
    setOtherImages([]);
  };

  return (
    <>
      <Card className={`${bdmFormStyles.form_card_style} mt-2`}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3}>
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Bird Capacity"
                id="outlined-size-small"
                value={birdCapacity}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setBirdCapacity(e.target.value);
                  }
                }}
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={!!birdCapacityError}
                helperText={birdCapacityError}
                required
              />
            </Grid>
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Land Dimension(Sqft)"
                id="outlined-size-small"
                value={landDimension}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setLandDimension(e.target.value);
                  }
                }}
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={!!landDimensionError}
                helperText={landDimensionError}
                required
              />
            </Grid>
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Shed Size (L X W)"
                id="outlined-size-small"
                value={shedSize}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9X]/g, ""); // Allow only numbers and 'X'

                  // Automatically insert 'X' after 3 characters if it's not there already
                  if (value.length > 3 && !value.includes("X")) {
                    value = value.slice(0, 3) + "X" + value.slice(3);
                  }

                  setShedSize(value);
                }}
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={!!ShedSizeError}
                helperText={ShedSizeError}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              className={`${bdmFormStyles.fields_padding}`}
            >
              <TextField
                id="outlined-select-currency"
                select
                label="Model Type"
                value={modelType}
                onChange={(e) => setModelType(e.target.value)}
                // helperText="Please select your currency"
                size="small"
                error={!!ModelTypeError}
                helperText={ModelTypeError}
                required
              >
                {MODEL_TYPE.map((mt) => (
                  <MenuItem
                    value={mt.label}
                    sx={{
                      borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {mt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Is land direction East West?{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Is land direction East West?"
                  name="gender"
                  value={landDirection}
                  onChange={handleChangeLandDirection}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {landDirectionError && (
                  <FormHelperText error>{landDirectionError}</FormHelperText>
                )}
              </div>
            </Grid>

            {dDShow && (
              <Grid item xs={12} md={4} xl={6}>
                <TextField
                  label="Direction Deviation Degree"
                  id="outlined-size-small"
                  value={directionDeviation}
                  onChange={(e) => setDirectionDeviation(e.target.value)}
                  size="small"
                />
              </Grid>
            )}

            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Electricity Power (Minimum 30kVa){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Electricity Power (Minimum 30kVa)"
                  name="epower"
                  value={electricityPower}
                  onChange={handleChangeElectricityPower}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {electricityPowerError && (
                  <FormHelperText error>{electricityPowerError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              className={`${bdmFormStyles.fields_padding}`}
            >
              <div className={`px-2`}>
                <Typography variant="body2">
                  Water(Minimum 10000 lit./day){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Water(Minimum 10000 lit./day)"
                  name="water"
                  value={water}
                  onChange={handleChangeWater}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {waterError && (
                  <FormHelperText error>{waterError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              className={`${bdmFormStyles.fields_padding}`}
            >
              <div className={`px-2`}>
                <Typography variant="body2">
                  Approachable Road <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Approachable Road"
                  name="approch_road"
                  value={approachRoad}
                  onChange={handleChangeApproachRoad}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {approachRoadError && (
                  <FormHelperText error>{approachRoadError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              className={`${bdmFormStyles.fields_padding}`}
            >
              <div className={`px-2`}>
                <Typography variant="body2">
                  Estimation Requirement <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Estimation Requirement"
                  name="ereq"
                  value={estimationReq}
                  onChange={handleChangeEstimationReq}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {EstimationReqError && (
                  <FormHelperText error>{EstimationReqError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} xl={6}>
              <ResizableTextField
                label={"Remarks"}
                value={remark}
                onChange={(e) => {
                  if (e.target.value.length <= 250) {
                    handleChangeRemark(e);
                  }
                }}
                multiline
                fullWidth
                variant="outlined" // Use 'outlined' for better border control
                style={{ width: "100%" }}
                required
                error={!!remarkError}
                helperText={remarkError}
              />
              <Typography
                variant="body2"
                color={remark.length > 250 ? "error" : "text.secondary"}
                sx={{ marginLeft: "7px" }}
              >
                {remark.length}/250
              </Typography>
            </Grid>

            <Grid container spacing={2} p={2}>
              {/* Label for the entire upload section */}
              <Grid item xs={12}>
                <Typography variant="body2">
                  Upload Images <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              {/* BDM Selfie */}
              <Grid item xs={12} md={4} xl={6}>
                <div className="px-2">
                  {/* <Typography variant="body2">BDM Selfie</Typography> */}
                  {!bdmSelfie ? (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{
                          width: "60%",
                          marginTop: "8px",
                          textTransform: "none",
                          border: "2px dashed black",
                          backgroundColor: "lightgray",
                          "&:hover": {
                            backgroundColor: "gray",
                          },
                        }}
                      >
                        BDM Selfie
                        <input
                          accept=".png, .jpeg, .jpg"
                          type="file"
                          onChange={(e) => handleBDMSelfieChange(e)}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <p style={{ color: "gray", fontSize: "0.875rem" }}>
                        Only PNG and JPEG files are allowed.
                      </p>
                      {imagesError && (
                        <FormHelperText error>{imagesError}</FormHelperText>
                      )}
                      {bdmSelfieError && (
                        <FormHelperText error>{bdmSelfieError}</FormHelperText>
                      )}
                    </>
                  ) : (
                    <div>
                      <img
                        src={bdmSelfieShow}
                        alt="Uploaded BDM Selfie"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                      />
                      <IconButton
                        onClick={() => handleBDMSelfieRemoveImage()}
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>

              {/* Customer Selfie */}
              <Grid item xs={12} md={4} xl={6}>
                <div className="px-2">
                  {/* <Typography variant="body2">Customer Selfie</Typography> */}
                  {!customerSelfie ? (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{
                          width: "60%",
                          marginTop: "8px",
                          textTransform: "none",
                          border: "2px dashed black",
                          backgroundColor: "lightgray",
                          "&:hover": {
                            backgroundColor: "gray",
                          },
                        }}
                      >
                        Customer Selfie
                        <input
                          accept=".png, .jpeg, .jpg"
                          type="file"
                          onChange={(e) => handleCustomerSelfieImageChange(e)}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <p style={{ color: "gray", fontSize: "0.875rem" }}>
                        Only PNG and JPEG files are allowed.
                      </p>
                      {imagesCustomerError && (
                        <FormHelperText error>
                          {imagesCustomerError}
                        </FormHelperText>
                      )}
                      {customerSelfieError && (
                        <FormHelperText error>
                          {customerSelfieError}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    <div>
                      <img
                        src={customerSelfieShow}
                        alt="Uploaded Customer Selfie"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                      />
                      <IconButton
                        onClick={() => handleCustomerSelfieRemoveImage()}
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>

              {/* Other Images */}
              <Grid item xs={12} md={4} xl={6}>
                <div className="px-2">
                  {/* <Typography variant="body2">Other Images (Max 3)</Typography> */}
                  {otherImages.length > 0 ? (
                    <Grid container spacing={1}>
                      {otherImagesShow.map((image, index) => (
                        <Grid item key={index}>
                          <img
                            src={image}
                            alt={`Other ${index}`}
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                          <IconButton
                            onClick={() => handleRemoveMultipleImage(index)}
                            aria-label="delete"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{
                          width: "60%",
                          marginTop: "8px",
                          textTransform: "none",
                          border: "2px dashed black",
                          backgroundColor: "lightgray",
                          "&:hover": {
                            backgroundColor: "gray",
                          },
                        }}
                      >
                        Other Images
                        <input
                          accept=".png, .jpeg, .jpg"
                          type="file"
                          multiple
                          onChange={handleMultipleUpload}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <p style={{ color: "gray", fontSize: "0.875rem" }}>
                        Only PNG and JPEG files are allowed.
                      </p>
                      {imagesError && (
                        <FormHelperText error>{imagesError}</FormHelperText>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <div className={`float-right p-2`}>
            {!isMobile && (
              <Button
                onClick={handleClose}
                variant="contained"
                className={`${bdmFormStyles.button_space_style}`}
              >
                Cancel
              </Button>
            )}
            {/* {viewMode == true ? (<></>) : ( */}
            <Button type="submit" onClick={handleSubmit} variant="contained">
              Save
            </Button>
            {/* )} */}
          </div>
        </Box>
      </Card>

      <DispositionModalComponent
        open={open}
        setOpen={setOpen}
        siteVisitFormData={siteVisitFormData}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setOpenSnackbar={setOpenSnackbar}
        setSiteVisitForm={setSiteVisitForm}
        formType={"site_visit"}
        setRunEffect={setRunEffect}
        resetForm={resetForm}
      />
    </>
  );
}

export default SiteVisitFormComponent;
