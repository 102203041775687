import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { Box, Grid, TextField, Button, IconButton, Snackbar, Alert, Stack } from "@mui/material";
import { Delete as DeleteIcon, UploadFile as UploadFileIcon } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../reusable Component/Loader";
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import { LEAD_CONVERTED } from "../../constants";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function MobileConveretedComponent() {
    const storedLeadDetails = JSON.parse(localStorage.getItem("leadDetails"));
    const navigate = useNavigate();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const initialDocuments = [
        { id: 1, key: 'payment_amount' ,name: 'Payment Amount', type: 'input',file: [], isMandatory: true},
        { id: 2, key: 'payment_slip' ,name: 'Payment Slip Document',type: 'upload', file: [], isMandatory: true },
        { id: 3, key: 'customer_creation_form' ,name: 'Customer Creation Form',type: 'upload', file: [] , isMandatory: true},
        { id: 4, key: 'pan_card' ,name: 'PAN Card',type: 'upload', file: [] , isMandatory: true },
        { id: 5, key: 'aadhar_card' ,name: 'Aadhar Card',type: 'upload', file: [] , isMandatory: true },
        { id: 6, key: 'land_certificate' ,name: 'Land Certificate for unloading address',type: 'upload', file: [] , isMandatory: false },
        { id: 7, key: 'gst_certificate' ,name: 'GST Certificate',type: 'upload', file: [] ,isMandatory: false },
        { id: 8, key: 'bank_account_details' ,name: 'Bank Account Details',type: 'upload', file: [] , isMandatory: true },
        { id: 8, key: 'bank_cheques' ,name: '3 Bank Cheques',type: 'upload', file: [] , isMandatory: false},
        { id: 10, key: 'legal_agreement_copy' ,name: 'Legal Agreement Copy',type: 'upload', file: [] , isMandatory: false },
        { id: 11, key: 'affidavit_property' ,name: 'Affidavit (Property), If applicable',type: 'upload', file: [], isMandatory: false },
        { id: 12, key: 'consent_letter_dispatch' ,name: 'Consent Letter for dispatch',type: 'upload', file: [], isMandatory: false },
        { id: 13, key: 'consent_letter_third_party_payment' ,name: 'Consent Letter for 3rd Party payment, if applicable',type: 'upload', file: [], isMandatory: false },
        { id: 14, key: 'estimation' ,name: 'Estimation ',type: 'upload', file: [] , isMandatory: true},
        { id: 15, key: 'final_quotation' ,name: 'Final Quotation',type: 'upload', file: [] , isMandatory: false},
        { id: 16, key: 'annexure' ,name: 'Annexure',type: 'upload', file: [], isMandatory: false },
        { id: 17, key: 'udyam_registration_certificate' ,name: 'Udyam Registration Certificate',type: 'upload', file: [] , isMandatory: false},
        { id: 18, key: 'gram_panchayat_noc' ,name: 'Gram Panchayat NOC',type: 'upload', file: [] , isMandatory: false},
        // { id: 19, key: 'payment_amount' ,name: 'Other',type: 'upload', file: [] , isMandatory: false},
        { id: 19, key: 'remark' ,name: 'Remarks', type: 'textarea', file: [] , isMandatory: true},
    ];

    const [documents, setDocuments] = useState(initialDocuments);

    const handleFileChange = (index, event) => {
        const newDocuments = [...documents];
    
        // Ensure the index is within bounds and that the object has a `file` property
        if (newDocuments[index] && Array.isArray(newDocuments[index].file)) {
            const newFiles = Array.from(event.target.files);
            
            
            // Extract only the URLs from the new files
            const fileUrls = newFiles.map(file => URL.createObjectURL(file));
    
            // Append or replace file URLs
            newDocuments[index].file = [...newDocuments[index].file, ...newFiles];
    
            setDocuments(newDocuments);
    
            // Reset the input field to allow re-uploading the same file
            event.target.value = null;
        } else {
            console.error(`Document at index ${index} is undefined or improperly structured.`);
        }
    };

    const handleRemoveRow = (index) => {
        const newDocuments = [...documents];
        
        // Revoke all URLs for the files in the row being removed
        newDocuments[index].file.forEach(({ url }) => URL.revokeObjectURL(url));
        
        newDocuments.splice(index, 1);
        setDocuments(newDocuments);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        // Validation: Ensure mandatory documents are uploaded
        const mandatoryDocs = documents.filter(doc => doc.isMandatory);
        const missingDocs = mandatoryDocs.filter(doc => doc.type === 'upload' && doc.file.length === 0);

        if (missingDocs.length > 0) {
            setLoading(false);
            alert(`Please upload the following mandatory documents: ${missingDocs.map(doc => doc.name).join(', ')}`);
            return;
        } else {
            // Handle form submission logic here
            console.log('Submitted documents:', documents);
            // Create FormData object
            const formData = new FormData();
            formData.append("LeadDetailId", storedLeadDetails.leadId);
            formData.append("employeeId", localStorage.getItem('username'));
            formData.append("status", "Converted");
            formData.append("estimationId", localStorage.getItem('estimation_id'));

           // Append files to FormData using keys from `initialDocuments`
            documents.forEach((document) => {
                document.file.forEach((file, fileIndex) => {
                    console.log(`Appending file: ${document.key}`, file);
                    // Append each file with a unique key based on the document key and file index
                    formData.append(`${document.key}`, file);
                });
            });
            

            axios.post(LEAD_CONVERTED, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                setLoading(false);
                setSeverity('success');
                setMessage(response.data.message);
                setOpenSnackbar(true);

                
                setTimeout(() => {
                    navigate('/mobilebdmpage');
                }, 1000); // Delay for 1 seconds
        
            }).catch(error => {
                setLoading(false);
            });   

        }  
    };

    const handleRemoveFile = (docIndex, fileIndex) => {
        console.log('here',docIndex, fileIndex)
        const newDocuments = [...documents];
         // Safely check if the document and file exist
        if (newDocuments[docIndex] && newDocuments[docIndex].file[fileIndex]) {
        const [removedFile] = newDocuments[docIndex].file.splice(fileIndex, 1);
        
        // Revoke the object URL if it exists
        if (removedFile.url) {
            URL.revokeObjectURL(removedFile.url);
        }
        setDocuments(newDocuments);
        } else {
            console.error('File or Document not found');
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };

      const handleBackButton = () =>{
        navigate('/mobilebdmpage');
      };

    return (
        <>
        {loading && <Loader open={loading} />}

        <IconButton onClick={handleBackButton}>
            <ArrowBackIcon></ArrowBackIcon>
        </IconButton>

        <Accordion defaultExpanded style={{margin: '0px'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading} h-auto`}
        sx={{
            '& .Mui-expanded': {
                margin: '10px 0 !important'
            }
        }}  
        >
        <Typography>Lead Details</Typography>
        </AccordionSummary>
            <AccordionDetails style={{padding: '8px 9px 16px'}}>
            <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            >
            
            <Grid container  >
            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Customer Name:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.CustomerName} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6}  className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Mobile Number:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.MobileNo} 
                </Typography>
            </Grid> 

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Alternate Mobile Number:
                </Typography>

                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.AlternateMobileNo} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Whatsapp Number:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.WhatsappNo} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={'flex'}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Customer Mail ID:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.CustomerMailId} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Postal Pincode:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.pincode} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    State:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.state_name} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Region Name:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.region_name} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    City/Village/Location:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.location}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Site Location Address:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.site_location_address}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Follow-up Date:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.follow_up_date === "-" ? '-' : new Date(storedLeadDetails.follow_up_date).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                        })
                    }
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Lead Transfer Date:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.lead_transfer_date === "-" ? '-' : new Date(storedLeadDetails.lead_transfer_date).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                        })
                    }
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Campaign:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.source_of_lead_generated}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Lead Shared By:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {storedLeadDetails.lead_owner}
                </Typography>
            </Grid>

            </Grid>

           
            </Box>
            </AccordionDetails>
        </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header"
                className={`${leadFormStyles.accordian_heading} h-auto`}
                sx={{
                    '& .Mui-expanded': {
                        margin: '10px 0 !important'
                    }
                }} >
                    <Typography>Converted Form</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <form onSubmit={handleSubmit}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>SNo</th>
                                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Document</th>
                                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documents.map((document, index) => (
                                    <tr key={index}  style={{ height: '90px' }}>
                                        <td data-label="SNo" style={{ padding: '8px' , width: '3%'}}>
                                            {index + 1}.
                                        </td>
                                        <td  data-label="Document" style={{ padding: '8px' , width: '30%'}}>
                                            {document.name} {document.isMandatory && <span style={{ color: 'red' }}>*</span>}
                                        </td>
                                        <td data-label="Action" style={{ padding: '8px' , width: '50%'}}>
                                            {document.type === 'input' && (
                                                <TextField
                                                    variant="outlined"
                                                    value={document.file[0] || ''}
                                                    onChange={(event) => {
                                                        if (/^\d*$/.test(event.target.value)) {
                                                            const newDocuments = [...documents];
                                                            newDocuments[index].file[0] = event.target.value;
                                                            setDocuments(newDocuments);
                                                        }
                                                    }}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                />
                                            )}
                                            {document.type === 'textarea' && (
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    value={document.file[0] || ''}
                                                    onChange={(event) => {
                                                        const newDocuments = [...documents];
                                                        newDocuments[index].file[0] = event.target.value;
                                                        setDocuments(newDocuments);
                                                    }}
                                                />
                                            )}
                                            {document.type === 'upload' && (
                                                <Stack direction="column" alignItems="center" spacing={2}>
                                                   {document.file.length === 0 ? ( <Button
                                                        variant="contained"
                                                        component="label"
                                                        sx={{
                                                            minWidth: '20px',
                                                            // minHeight: '100px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <UploadFileIcon sx={{ fontSize: 20 }} />
                                                        {/* Upload */}
                                                        <input
                                                            type="file"
                                                            hidden
                                                            multiple
                                                            accept="application/pdf"
                                                            onChange={(event) => handleFileChange(index, event)}
                                                        />
                                                    </Button>
                                                    ) : (
                                                    <Box>
                                                        {document.file.map((file, fileIndex) => (
                                                            <Stack key={fileIndex} direction="row" alignItems="center" spacing={1}>
                                                                <Typography variant={"caption"}style={{
                                                                    display: 'inline-block',
                                                                    maxWidth: '100%',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    verticalAlign: 'middle',
                                                                }}>{file.name}</Typography>
                                                                <IconButton onClick={() => handleRemoveFile(index, fileIndex)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                                
                                                            </Stack>
                                                            
                                                        ))}
                                                    </Box>
                                                    )}
                                                </Stack>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                            Submit
                        </Button>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}


export default MobileConveretedComponent;