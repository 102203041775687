import React, { useState, useEffect } from 'react';
import TaskListModal from './TaskListModal';
import { useTheme } from '@mui/material/styles';
import { BASE_URL } from '../../constants';

export const BdmToDoPage = () => {
  const [initialTaskSections, setInitialTaskSections] = useState([]);
  const [dailyTaskSections, setDailyTaskSections] = useState([]);
  const [openTaskListModal, setOpenTaskListModal] = useState(false);
  const [modalJustClosed, setModalJustClosed] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedRemarks, setExpandedRemarks] = useState({});
  const theme = useTheme();
  const storedId = localStorage.getItem('username');

  useEffect(() => {
    fetchInitialTasks();
    fetchDailyTasks();
  }, []);

  useEffect(() => {
    if (modalJustClosed) {
      fetchDailyTasks();
      setModalJustClosed(false);
    }
  }, [modalJustClosed]);

  const fetchInitialTasks = async () => {
    try {
      const hoResponse = await fetch(`${BASE_URL}/bdm-followup-tasks?bdmId=${storedId}`);
      if (!hoResponse.ok) throw new Error('Network response for HO tasks was not ok');

      const hoData = await hoResponse.json();
      const hoTasks = hoData.Ho_task ? hoData.Ho_task.map(task => ({
        id: task.id,
        CustomerName: task.CustomerName,
        MobileNo: task.MobileNo,
        category: task.category,
        sub_category: task.sub_category,
        location: task.location,
        agent_remark: task.agent_remark,
        bdm_remark: task.bdm_remark,
        follow_up_date: task.follow_up_date,
        disabled: false,
        postponedDate: null,
        confirmed: false,
        isSelfTask: false
      })) : [];

      const selfResponse = await fetch(`${BASE_URL}/bdm-self-tasks?bdmId=${storedId}`);
      if (!selfResponse.ok) throw new Error('Network response for Self tasks was not ok');

      const selfData = await selfResponse.json();
      const selfTasks = selfData.self_task ? selfData.self_task.map(task => ({
        id: task.id,
        InquiryType: task.InquiryType,
        CustomerName: task.CustomerName,
        MobileNo: task.MobileNo,
        location: task.location,
        category: task.category,
        sub_category: task.sub_category,
        agent_remark: task.agent_remark,
        bdm_remark: task.bdm_remark,
        follow_up_date: task.follow_up_date,
        disabled: false,
        postponedDate: null,
        confirmed: false,
        isSelfTask: true
      })) : [];

      setInitialTaskSections([
        { title: 'HO Task', tasks: hoTasks },
        { title: 'Self Task', tasks: selfTasks },
      ]);
    } catch (error) {
      console.error('Error fetching initial tasks:', error);
    }
  };

  const fetchDailyTasks = async () => {
    try {
      const response = await fetch(`${BASE_URL}/bdm-daily-tasks/${storedId}`);
      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();

      const newTaskSections = [
        { 
          title: 'HO Task', 
          tasks: data.tasks.HO_task.map(task => ({
            id: task.id,
            CustomerName: task.lead.CustomerName,
            MobileNo: task.lead.MobileNo,
            category: task.lead.category,
            sub_category: task.lead.sub_category,
            location: task.lead.location,
            agent_remark: task.lead.agent_remark,
            bdm_remark: task.lead.bdm_remark || task.remarks,
            follow_up_date: task.action_date,
            disabled: false,
            postponedDate: task.new_follow_up_date,
            confirmed: task.action_type === 'confirm',
            isSelfTask: false,
            specific_action: task.specific_action,
            remarks: task.lead.agent_remark
          }))
        },
        { 
          title: 'Self Task', 
          tasks: data.tasks.self_task.map(task => ({
            id: task.id,
            CustomerName: task.lead.CustomerName,
            MobileNo: task.lead.MobileNo,
            location: task.lead.location,
            category: task.lead.category,
            sub_category: task.lead.sub_category,
            agent_remark: task.lead.agent_remark,
            bdm_remark: task.lead.bdm_remark || task.remarks,
            follow_up_date: task.action_date,
            disabled: false,
            postponedDate: task.new_follow_up_date,
            confirmed: task.action_type === 'confirm',
            isSelfTask: true,
            specific_action: task.specific_action,
            remarks: task.lead.agent_remark
          }))
        },
        { 
          title: 'Other Tasks', 
          tasks: data.tasks.other_task.map(task => ({
            id: task.id,
            task_name: task.task_name,
            remarks: task.remarks,
            follow_up_date: task.action_date,
            disabled: false,
            confirmed: false,
          }))
        },
      ];

      setDailyTaskSections(newTaskSections);

      const allSectionsEmpty = newTaskSections.every(section => section.tasks.length === 0);
      setOpenTaskListModal(allSectionsEmpty);

    } catch (error) {
      console.error('Error fetching daily tasks:', error);
    }
  };

  const allRequiredTasksHandled = () => {
    return dailyTaskSections.every(section =>
      section.tasks.every(task => task.confirmed || task.postponedDate)
    );
  };

  const handleCloseModal = () => {
    setOpenTaskListModal(false);
    setModalJustClosed(true);
  };

  const toggleRow = (taskId) => {
    setExpandedRow(expandedRow === taskId ? null : taskId);
  };

  const toggleRemarks = (taskId) => {
    setExpandedRemarks(prev => ({ ...prev, [taskId]: !prev[taskId] }));
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto px-2 py-4">
        <header style={{ backgroundColor: theme.palette.primary.main }} className="text-white rounded-t-lg shadow-md mb-6">
          <h1 className="text-2xl font-bold p-2">To Do List</h1>
        </header>

        <TaskListModal
          open={openTaskListModal}
          onClose={handleCloseModal}
          taskSections={initialTaskSections}
          setTaskSections={setInitialTaskSections}
          allRequiredTasksHandled={allRequiredTasksHandled}
        />

{dailyTaskSections.map((section, index) => {
  const hasTasks = section.tasks.length > 0;

  return hasTasks && (
    <div key={index} className="mb-6">
      <h2 className="text-xl font-bold mb-2 text-black p-2 rounded">
        {section.title}
      </h2>
      <div className="grid grid-cols-4 gap-1 bg-gray-200 p-1 rounded-lg">
        <div className="font-bold">S No.</div>
        <div className="font-bold">Customer Name</div>
        <div className="font-bold">{section.title !== 'Other Tasks' ? 'Location' : 'Remark'}</div>
        {section.title !== 'Other Tasks' && <div className="font-bold">Action</div>}
      </div>
      {section.tasks.map((task, idx) => (
        <div key={task.id} className="grid grid-cols-4 gap-4 border-b p-2 hover:bg-gray-100">
          <div className="p-2 whitespace-nowrap">
            {idx + 1}
            {section.title !== 'Other Tasks' && (
              <span
                className={`ml-2 cursor-pointer ${expandedRow === task.id ? 'rotate-180' : ''}`}
                onClick={() => toggleRow(task.id)}
              >
                ▼
              </span>
            )}
          </div>
          <div>{section.title !== 'Other Tasks' ? task.CustomerName : task.task_name}</div>
          <div>{section.title !== 'Other Tasks' ? task.location : task.remarks}</div>
          
          {section.title !== 'Other Tasks' && (
  <div className={task.specific_action === 'Postpone' ? 'text-red-500' : ''}>
    {task.specific_action ? task.specific_action : <span className="text-red-500">Postpone</span>}
  </div>
)}





          {/* Expanded content for HO and Self Tasks */}
          {expandedRow === task.id && section.title !== 'Other Tasks' && (
            <div className="col-span-4 bg-gray-300 p-2 mt-2 rounded">
              <p><strong>Mobile Number:</strong> {task.MobileNo}</p>
              <p><strong>Category:</strong> {task.category}</p>
              <p><strong>Sub Category:</strong> {task.sub_category}</p>
              <p><strong>Remarks:</strong> {task.remarks}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
})}
      </main>
    </div>
  );
};

export default BdmToDoPage;
