import * as React from 'react';
import bdmDashboardStyles from '../BdmDashboard/bdmDashboardStyles.module.css';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import  totalCalls from '../../icons/total_calls.png';
import connected from '../../icons/connected.png';
import notconnected from '../../icons/not_connected.png';
import followup from '../../icons/follow-up.png';
import { Grid } from '@mui/material';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { useMediaQuery, useTheme, Chip} from '@mui/material';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import HubIcon from '@mui/icons-material/Hub';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import dashboradStyles from './dashboardStyles.module.css';

function AgentPerformance({statisticsData}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    console.log('statisticsData', statisticsData);
 
    return (
        <>
        <div>
          <p className="text-2xl font-bold mb-4">My Performance</p>
        </div>

        <Grid container spacing={1} className={`mb-4`}>
        {[
              { label: "Total Calls", value: statisticsData.length === 0 ? 0 : statisticsData?.totalCalls, imgSrc: <PermPhoneMsgIcon className={`w-3/4 float-right ${dashboradStyles.total_calls_icon} ${dashboradStyles.font_size}`} ></PermPhoneMsgIcon> },
              { label: "Connected", value: statisticsData.length === 0 ? 0 : statisticsData?.connectedCalls , imgSrc: <HubIcon className={`w-3/4 float-right ${dashboradStyles.font_size}`} color='secondary' ></HubIcon> },
              { label: "Not Connected", value: statisticsData.length === 0 ? 0 : statisticsData?.notConnectedCalls, imgSrc: <PhoneDisabledIcon className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`}></PhoneDisabledIcon> },
              {
                label: "Missed",
                value: 0,
                imgSrc: <PhoneMissedIcon className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`} />,
              },
              // { label: "Follow-up", value: 23, imgSrc: <PhoneMissedIcon className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`}></PhoneMissedIcon> },
            ].map((item, index) => (
                <Grid item xs={6} sm={3}>
                    <Paper className={`flex flex-col place-items-center justify-center h-full p-1`} >
                        <div className={`w-full text-center`}>
                            <p className={`font-bold ${smallScreen ? 'text-xs' : 'text-sm'}`}>{item.label}</p>
                            <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                            <p className={`font-bold text-xl`}>{item.value}</p> {/* Smaller count font */}
                        </div>
                        {item.imgSrc}
                        {/* <img src={item.imgSrc} loading="lazy" alt='totalCalls' className={`w-1/4`} /> Smaller image */}
                    </Paper>
                </Grid>
            ))}
        </Grid>
      </>
      
      );
    }
    
    export default AgentPerformance;
    