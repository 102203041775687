import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { POST_AUDIT_REMARK } from '../../constants';

function StatusChangeDialogCaller({ open, setOpen, formValues, setOpenSnackbar,setSeverity, setAlertTitle, resetForm, setRunEffect, empDetails, storedLeadDetails, lotNum, setFormOpen }) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false);
        }
    };

    const handleDialogClose = () => {
        setOpen(false); // Close dialog without taking action
    };

    const handleStatusChange = (newStatus) => {
       
        // Build the formValues object in raw JSON format
            const formData = {
                AGE: formValues.AGE,
                BM_VISIT: formValues.BM_VISIT,
                BWT: formValues.BWT,
                BWT_ENT: formValues.BWT_ENT,
                CH: formValues.CH,
                DAILY_ENT: formValues.DAILY_ENT,
                DATE: formValues.DATE,
                FEED: formValues.FEED,
                FEED_ENT: formValues.FEED_ENT,
                IFFT_IN: formValues.IFFT_IN,
                IFFT_OUT: formValues.IFFT_OUT,
                LS_VISIT: formValues.LS_VISIT,
                MED: formValues.MED,
                MED_ENT: formValues.MED_ENT,
                MORT_ENT: formValues.MORT_ENT,
                M_QTY: formValues.M_QTY,
                REASON: formValues.REASON,
                REMARKS: formValues.REMARKS,
                STOCK: formValues.STOCK,
                status: newStatus, // Add the new status
                Lot_Number: String(lotNum), // Ensure Lot_Number is a string
                AgentId: empDetails.EmployeeId
            };


        handleFormSubmit(formData);
    };

    const handleFormSubmit = (formData) =>{
        axios.post(POST_AUDIT_REMARK, formData, {
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }})
            .then(response => {
                console.log('here');
                setSeverity('success');
                setAlertTitle(response.data.message);
                setOpenSnackbar(true);
                setRunEffect(true);
                setFormOpen(false);
                resetForm();
                

                setOpen(false)
            }).catch(error => {
                setSeverity('error');
                setAlertTitle(error.message);
                setOpenSnackbar(true);
                resetForm();
            });
    }
    console.log(formValues)

    return (
        <Dialog open={open} onClose={handleDialogClose} fullScreen={fullScreen}>
            <DialogTitle>Change Record Status</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Do you want to change the status of this record to "Open" or "Closed"?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleStatusChange('Open')} color="primary">
                    Open
                </Button>
                <Button onClick={() => handleStatusChange('Closed')} color="primary">
                    Closed
                </Button>
                <Button onClick={handleDialogClose} color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default StatusChangeDialogCaller;
