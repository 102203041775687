import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
  } from "@mui/material";
  import moment from "moment";
  import * as React from "react";
  import { useState } from "react";
  import LongTextComponent from "../Leads Page/LongTextComponent";
  
  function ConfirmedTaskTable({ taskData }) {
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  
    // Handle page change
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0); // Reset to the first page
    };
  
    const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");
  
    return (
      <>
      <Paper>
        <TableContainer component={Paper} elevation={3}>
          <Table
            sx={{
              minWidth: 650,
              height: "100%",
              tableLayout: "fixed",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  width={50}
                  sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}
                >
                  SNo
                </TableCell>
                <TableCell width={160} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Customer Name
                </TableCell>
                <TableCell width={150} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Mobile Number
                </TableCell>
                <TableCell width={150} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Action
                </TableCell>
                <TableCell width={120} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Action Date
                </TableCell>
                <TableCell width={120} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Action Type
                </TableCell>
                <TableCell width={150} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Remarks
                </TableCell>
                <TableCell width={100} sx={{ fontWeight: "bold", textAlign: "center", padding: 1 }}>
                  Completion
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskData.length > 0 ? (
                taskData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        "&:hover": { backgroundColor: "#f9f9f9" },
                      }}
                    >
                      <TableCell width={50} sx={{ textAlign: "center" }}>
                        {page * rowsPerPage + rowIndex + 1}
                      </TableCell>
                      <TableCell width={160} sx={{ textAlign: "center" }}>
                        {row.customer_name || '-'}
                      </TableCell>
                      <TableCell width={150} sx={{ textAlign: "center" }}>
                        {row.mobile_no || '-'}
                      </TableCell>
                      <TableCell width={150} sx={{ textAlign: "center" }}>
                        {row.specific_action || '-'}
                      </TableCell>
                      <TableCell width={120} sx={{ textAlign: "center" }}>
                        {row.action_date === ""
                          ? "-"
                          : formatDate(row.action_date)}
                      </TableCell>
                      <TableCell width={120}
                        sx={{ textAlign: "center", textTransform: "capitalize" }}
                      >
                        {row.action_type || '-'}
                      </TableCell>
                      <TableCell width={150} sx={{ textAlign: "center" }}>
                        <LongTextComponent text={row.remarks || ''} maxLength={20}></LongTextComponent>
                      </TableCell>
                      <TableCell width={100} sx={{ textAlign: "center" }}>
                        {row.completion_status === null
                          ? "-"
                          : row.completion_status}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No details yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
            component="div"
            count={taskData.length} // Total number of rows
            rowsPerPage={rowsPerPage} // Current rows per page
            page={page} // Current page
            onPageChange={handleChangePage} // Handle page change
            onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
          />
          </Paper>
      </>
    );
  }
  
  export default ConfirmedTaskTable;
  