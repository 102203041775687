


import React, { useState, useEffect } from "react";
import axios from "axios";
import companySymbol from '../../icons/company-symbol.png';
import companyLogo from '../../icons/vistaar.jfif';
import { BASE_URL } from '../../constants';

const VistaarBroilerDistributionForm = () => {
  // Form Data State
  const [formData, setFormData] = useState({
    CustomerName: "",
    MobileNumber: "",
    whatsappNo: "",
    sameAsContact: false,
    Pincode: "",
    StateName: "",
    Location: "",
    otherLocation: "",
    CurrentProfession: "",
    InterestedState: "",
    InterestedCity: "",
    PreviousExposure: "",
    SourceOfInformation: "",
  });

  // UI State Management
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodeData, setPincodeData] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [isCustomCity, setIsCustomCity] = useState(false);
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  // Event Handlers
//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;

//     if (name === "sameAsContact") {
//       setFormData((prevData) => ({
//         ...prevData,
//         sameAsContact: checked,
//         whatsappNo: checked ? prevData.MobileNumber : ""
//       }));
//     } else if (name === "MobileNumber") {
//       const phoneNumber = value.slice(0, 10);
//       setFormData((prevData) => ({
//         ...prevData,
//         MobileNumber: phoneNumber,
//         whatsappNo: prevData.sameAsContact ? phoneNumber : prevData.whatsappNo
//       }));
//     } else {
//       setFormData((prevData) => ({ ...prevData, [name]: value }));
//     }
//   };


useEffect(() => {
    if (citySearchTerm) {
      const filtered = cities.filter(city => 
        city.CityName.toLowerCase().includes(citySearchTerm.toLowerCase())
      );
      setFilteredCities(filtered);
      // If no cities found, enable custom input
      setIsCustomCity(filtered.length === 0);
    } else {
      setFilteredCities(cities);
      setIsCustomCity(false);
    }
  }, [citySearchTerm, cities]);


const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name === "citySearch") {
        setCitySearchTerm(value);
        // If typing in search, update InterestedCity as well
        setFormData(prevData => ({
          ...prevData,
          InterestedCity: value
        }));
      }
    // Handle different input types
    if (name === "sameAsContact") {
      setFormData((prevData) => ({
        ...prevData,
        sameAsContact: checked,
        whatsappNo: checked ? prevData.MobileNumber : ""
      }));
    } 
    else if (name === "MobileNumber") {
      const phoneNumber = value.slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        MobileNumber: phoneNumber,
        whatsappNo: prevData.sameAsContact ? phoneNumber : prevData.whatsappNo
      }));
    }
    else if (name === "whatsappNo") {
      const phoneNumber = value.slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        whatsappNo: phoneNumber
      }));
    }
    else if (name === "Pincode") {
      const pincodeValue = value.replace(/[^0-9]/g, "").slice(0, 6);
      setFormData((prevData) => ({
        ...prevData,
        Pincode: pincodeValue
      }));
    }
    else if (name === "PreviousExposure") {
      setFormData(prevData => ({
        ...prevData,
        PreviousExposure: value === "true"  // Convert string "true"/"false" to boolean true/false
      }));
    }
    else if (name === "InterestedState") {
      setFormData((prevData) => ({
        ...prevData,
        InterestedState: value,
        InterestedCity: ""  // Reset city when state changes
      }));
    }
    else if (name === "CustomerName") {
      // Only allow letters and spaces in customer name
      const nameValue = value.replace(/[^A-Za-z\s]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        CustomerName: nameValue
      }));
    }
    else if (name === "otherLocation") {
      // Only allow letters and spaces in other location
      const locationValue = value.replace(/[^A-Za-z\s]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        otherLocation: locationValue
      }));
    }
    else {
      // Handle all other fields normally
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleBack = () => {
    setFormSubmitted(false);
    setFormData({
      CustomerName: "",
      MobileNumber: "",
      whatsappNo: "",
      sameAsContact: false,
      Pincode: "",
      StateName: "",
      Location: "",
      otherLocation: "",
      CurrentProfession: "",
      InterestedState: "",
      InterestedCity: "",
      PreviousExposure: null,
      SourceOfInformation: "",
    });
  };
  // Fetch States Effect
  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/states/search`);
        if (response.data.success) {
          setStates(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching states:', error);
        alert('Failed to fetch states. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  // Fetch Cities Effect
  useEffect(() => {
    const fetchCities = async () => {
      if (formData.InterestedState) {
        setLoading(true);
        try {
          const selectedState = states.find(
            state => state.StateName === formData.InterestedState
          );
          
          if (selectedState) {
            const response = await axios.get(`${BASE_URL}/cities/search/${selectedState.StateCode}`);
            if (response.data.success) {
              setCities(response.data.data);
            }
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
          alert('Failed to fetch cities. Please try again later.');
        } finally {
          setLoading(false);
        }
      } else {
        setCities([]);
      }
    };

    fetchCities();
  }, [formData.InterestedState, states]);

  // Pincode Data Effect
  useEffect(() => {
    const fetchPincodeData = async () => {
      if (formData.Pincode.length === 6) {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${formData.Pincode}`
          );
          setPincodeData(response.data[0]);
          if (response.data[0]?.PostOffice) {
            setFormData((prevData) => ({
              ...prevData,
              StateName: response.data[0]?.PostOffice[0]?.State || "",
            }));
            setLocationOptions(
              response.data[0]?.PostOffice.map((office) => office.Name)
            );
          } else {
            setFormData((prevData) => ({
              ...prevData,
              StateName: "",
              Location: "",
            }));
            setLocationOptions([]);
          }
        } catch (error) {
          console.error("Error fetching pincode data:", error);
          setFormData((prevData) => ({
            ...prevData,
            StateName: "",
            Location: "",
          }));
          setLocationOptions([]);
        }
      } else {
        setFormData((prevData) => ({
          ...prevData,
          StateName: "",
          Location: "",
        }));
        setPincodeData(null);
        setLocationOptions([]);
      }
    };

    fetchPincodeData();
  }, [formData.Pincode]);

  // Form Submit Handler
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `${BASE_URL}/vistaar-lead`,
//         formData
//       );
//       console.log(response.data);
//       setFormSubmitted(true);
//     } catch (error) {
//       console.error("Error submitting form:", error.response?.data || error.message);
//       alert("Error submitting form. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate customer name before submission
    const customerName = formData.CustomerName.trim();
    if (!customerName || customerName.length === 0) {
      alert("Please enter a valid customer name");
      return;
    }
  
    if (!/^[A-Za-z]+[A-Za-z\s]*[A-Za-z]+$|^[A-Za-z]+$/.test(customerName)) {
      alert("Customer name must start and end with letters and contain only letters and spaces");
      return;
    }
  
    setLoading(true);
    try {
      const submissionData = {
        ...formData,
        CustomerName: customerName, // Use the trimmed value
        PreviousExposure: Boolean(formData.PreviousExposure)
      };
  
      const response = await axios.post(
        `${BASE_URL}/vistaar-lead`,
        submissionData
      );
      console.log(response.data);
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
      alert("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // Success Modal
  if (formSubmitted) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-8 max-w-md w-full">
          <div className="text-center">
            <div className="mb-4">
              <svg
                className="mx-auto h-16 w-16 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Thank You for Your Submission!
            </h2>
            <p className="text-gray-600 mb-8">
              We have received your information and will get back to you soon.
            </p>
            <button
              onClick={handleBack}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 focus:outline-none transition-colors"
            >
              Back to Form
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Form Header
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md">
      <div className="bg-gradient-to-r from-green-600 to-green-500 py-4 px-6 rounded-t-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <img
              src={companySymbol}
              alt="Company Symbol"
              className="h-14 w-14 p-1 bg-white rounded-full shadow-lg"
            />
            <div className="h-12 w-px bg-white/30 mx-2" />
            <h2 className="text-xl sm:text-2xl font-bold text-white tracking-wide drop-shadow-md">
              INQUIRY FORM
            </h2>
          </div>
          <div className="bg-white/12 p-1 rounded-lg">
            <img src={companyLogo} alt="Company Logo" className="h-16 " />
          </div>
        </div>
      </div>
      <form
  onSubmit={handleSubmit}
  className="grid grid-cols-1 md:grid-cols-2 gap-3.5 p-3"
>
  {/* Customer Name */}
  <div>
    <label
      htmlFor="CustomerName"
      className="block mb-1 font-bold text-gray-700"
    >
      Customer Name
    </label>
    <input
      type="text"
      id="CustomerName"
      name="CustomerName"
      value={formData.CustomerName}
      onChange={handleChange}
      onKeyDown={(e) => {
        const key = e.key;
        if (
          !/^[A-Za-z\s]$/.test(key) &&
          key !== "Backspace" &&
          key !== "Delete" &&
          key !== "ArrowLeft" &&
          key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>

  {/* Mobile Number */}
  <div>
    <div className="block mb-1">
      <div className="flex items-center gap-2">
        <label
          htmlFor="MobileNumber"
          className="font-bold text-gray-700"
        >
          Mobile Number
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            id="sameAsContact"
            name="sameAsContact"
            checked={formData.sameAsContact}
            onChange={handleChange}
            className="mr-2"
          />
          <span className="text-gray-700">Same No. For WhatsApp</span>
        </label>
      </div>
    </div>
    <input
      type="tel"
      id="MobileNumber"
      name="MobileNumber"
      value={formData.MobileNumber}
      onChange={(e) => {
        handleChange(e);
        setFormData((prevData) => ({
          ...prevData,
          MobileNumber: e.target.value.slice(0, 10),
          whatsappNo: prevData.sameAsContact
            ? e.target.value.slice(0, 10)
            : prevData.whatsappNo,
        }));
        if (e.target.value.length < 10) {
          e.target.setCustomValidity(
            "Mobile Number must be 10 digits"
          );
        } else {
          e.target.setCustomValidity("");
        }
      }}
      onKeyDown={(e) => {
        const key = e.key;
        if (
          !/^\d$/.test(key) &&
          key !== "Backspace" &&
          key !== "Delete" &&
          key !== "ArrowLeft" &&
          key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      maxLength={10}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>

  {/* WhatsApp Number (if not same as mobile) */}
  {!formData.sameAsContact && (
    <div>
      <label
        htmlFor="whatsappNo"
        className="block mb-1 font-bold text-gray-700"
      >
        WhatsApp Number
      </label>
      <input
        type="tel"
        id="whatsappNo"
        name="whatsappNo"
        value={formData.whatsappNo}
        onChange={(e) => {
          handleChange(e);
          setFormData((prevData) => ({
            ...prevData,
            whatsappNo: e.target.value.slice(0, 10),
          }));
          if (e.target.value.length < 10) {
            e.target.setCustomValidity(
              "WhatsApp Number must be 10 digits"
            );
          } else {
            e.target.setCustomValidity("");
          }
        }}
        onKeyDown={(e) => {
          const key = e.key;
          if (
            !/^\d$/.test(key) &&
            key !== "Backspace" &&
            key !== "Delete" &&
            key !== "ArrowLeft" &&
            key !== "ArrowRight"
          ) {
            e.preventDefault();
          }
        }}
        maxLength={10}
        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
      />
    </div>
  )}

  {/* Pincode */}
  <div>
    <label
      htmlFor="Pincode"
      className="block mb-1 font-bold text-gray-700"
    >
      Pincode
    </label>
    <input
      type="text"
      id="Pincode"
      name="Pincode"
      value={formData.Pincode}
      maxLength={6}
      onChange={(e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setFormData((prev) => ({
          ...prev,
          Pincode: value,
        }));
      }}
      onKeyDown={(e) => {
        if (
          !/^\d$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Delete" &&
          e.key !== "ArrowLeft" &&
          e.key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      onInvalid={(e) => {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          e.target.setCustomValidity("Please enter a valid 6-digit pincode");
        }
      }}
      onInput={(e) => e.target.setCustomValidity("")}
      pattern="\d{6}"
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>

  {/* State Name (from pincode) */}
  <div>
    <label
      htmlFor="StateName"
      className="block mb-1 font-bold text-gray-700"
    >
      State Name
    </label>
    <input
      type="text"
      id="StateName"
      name="StateName"
      value={formData.StateName}
      onChange={handleChange}
      readOnly
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none bg-gray-100"
    />
  </div>

  {/* Location */}
  <div>
    <label
      htmlFor="Location"
      className="block mb-1 font-bold text-gray-700"
    >
      Location
    </label>
    <select
      id="Location"
      name="Location"
      value={formData.Location}
      onChange={handleChange}
      required={formData.StateName !== ""}
      disabled={formData.StateName === ""}
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    >
      <option value="">Select a location</option>
      {locationOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      {formData.StateName !== "" && <option value="Other">Other</option>}
    </select>
  </div>

  {/* Other Location */}
  {formData.Location === "Other" && formData.StateName !== "" && (
    <div>
      <label
        htmlFor="otherLocation"
        className="block mb-1 font-bold text-gray-700"
      >
        Other Location
      </label>
      <input
        type="text"
        id="otherLocation"
        name="otherLocation"
        value={formData.otherLocation}
        onChange={handleChange}
        pattern="^[A-Za-z\s]+$"
        title="Please enter only letters and spaces"
        required
        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
      />
    </div>
  )}

  {/* Current Profession */}
  <div>
    <label
      htmlFor="CurrentProfession"
      className="block mb-1 font-bold text-gray-700"
    >
      Current Profession
    </label>
    <select
      id="CurrentProfession"
      name="CurrentProfession"
      value={formData.CurrentProfession}
      onChange={handleChange}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    >
      <option value="">Select a profession</option>
      <option value="Service">Service</option>
      <option value="Business">Business</option>
      <option value="Others">Others</option>
    </select>
  </div>

  {/* Interested State */}
  <div>
    <label
      htmlFor="InterestedState"
      className="block mb-1 font-bold text-gray-700"
    >
      Interested Business State 
    </label>
    <select
      id="InterestedState"
      name="InterestedState"
      value={formData.InterestedState}
      onChange={(e) => {
        setFormData((prevData) => ({
          ...prevData,
          InterestedState: e.target.value,
          InterestedCity: "",
        }));
      }}
      required
      disabled={loading}
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    >
      <option value="">Select a state</option>
      {states.map((state) => (
        <option key={state.StateCode} value={state.StateName}>
          {state.StateName}
        </option>
      ))}
    </select>
  </div>

  {/* Interested City */}
  
      {/* <div>
      <label
        htmlFor="InterestedCity"
        className="block mb-1 font-bold text-gray-700"
      >
        Interested Business City
      </label>
      <div className="relative">
        <input
          type="text"
          id="citySearch"
          name="citySearch"
          value={citySearchTerm}
          onChange={handleChange}
          placeholder="Type to search or enter new city"
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          disabled={!formData.InterestedState || loading}
          required
        />
        
        {citySearchTerm && filteredCities.length > 0 && !isCustomCity && (
          <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
            {filteredCities.map((city) => (
              <div
                key={city.CityId}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setCitySearchTerm(city.CityName);
                  setFormData(prevData => ({
                    ...prevData,
                    InterestedCity: city.CityName
                  }));
                  setIsCustomCity(false);
                }}
              >
                {city.CityName}
              </div>
            ))}
          </div>
        )}

        
        {isCustomCity && citySearchTerm && (
          <div className="text-sm text-gray-600 mt-1">
            No matching cities found. Your entry will be saved as a city.
          </div>
        )}
      </div>
    </div> */}

<div>
  <label
    htmlFor="InterestedCity"
    className="block mb-1 font-bold text-gray-700"
  >
    Interested Business City
  </label>
  <div className="relative">
    <input
      type="text"
      id="citySearch"
      name="citySearch"
      value={citySearchTerm}
      onChange={(e) => {
        const value = e.target.value;
        setCitySearchTerm(value);
        setFormData(prevData => ({
          ...prevData,
          InterestedCity: value
        }));
        setShowCityDropdown(true);
      }}
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
      disabled={!formData.InterestedState || loading}
      required
    />
    
    {/* Dropdown only shows when typing and not after selection */}
    {showCityDropdown && filteredCities.length > 0 && (
      <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
        {filteredCities.map((city) => (
          <div
            key={city.CityId}
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            onClick={() => {
              setCitySearchTerm(city.CityName);
              setFormData(prevData => ({
                ...prevData,
                InterestedCity: city.CityName
              }));
              setShowCityDropdown(false); // Hide dropdown immediately after selection
              setFilteredCities([]); // Clear filtered cities list
            }}
          >
            {city.CityName}
          </div>
        ))}
      </div>
    )}
  </div>
</div>
  <div>
  <label
    htmlFor="PreviousExposure"
    className="block mb-1 font-bold text-gray-700"
  >
    Previous Working Exposure in Poultry
  </label>
  <select
    id="PreviousExposure"
    name="PreviousExposure"
    value={String(formData.PreviousExposure)} // Convert boolean to string for select
    onChange={handleChange}
    required
    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
  >
    <option value="">Select an option</option>
    <option value="true">Yes</option>
    <option value="false">No</option>
  </select>
</div>

  {/* Source of Information */}
  <div>
    <label
      htmlFor="SourceOfInformation"
      className="block mb-1 font-bold text-gray-700"
    >
      Source of Information about Vistaar Scheme
    </label>
    <select
      id="SourceOfInformation"
      name="SourceOfInformation"
      value={formData.SourceOfInformation}
      onChange={handleChange}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    >
      <option value="">Select a source</option>
      <option value="Social Media">Social Media</option>
      <option value="Employee Referral">Employee Referral</option>
      <option value="Vistaar Team">Vistaar Team</option>
      <option value="Offline Market">Offline Market</option>
      <option value="Others">Others</option>
    </select>
  </div>

  {/* Submit Button */}
  <div className="md:col-span-2">
    <button
      type="submit"
      disabled={loading}
      className="w-full px-4 py-2 font-bold text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none disabled:bg-green-400"
    >
      {loading ? "Please wait..." : "Submit"}
    </button>
  </div>
</form>
    </div>
  );
};

export default VistaarBroilerDistributionForm;






