import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Box,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { GET_MEETING_SV } from "../../constants";
import moment from "moment";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LongTextComponent from "../Leads Page/LongTextComponent";
import Loader from "../reusable Component/Loader";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const MeetingListComponent = ({ dateFilter }) => {
  const [page, setPage] = useState(0); // Zero-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0); // Total records from API
  const [expandedRows, setExpandedRows] = useState({});

  const [meetingList, setMeetingList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleExpandClick = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  // Handle Page Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Zero-based index
  };

  // Handle Rows Per Page Change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const memoizedDateFilter = useMemo(() => dateFilter, [dateFilter]);

  useEffect(() => {
    fetchMeetingData();
  }, [page, rowsPerPage, memoizedDateFilter]);

  // API Call
  const fetchMeetingData = () => {
    const currentPage = page + 1; // Convert zero-based index to one-based
    const limit = rowsPerPage;

    setLoading(true);

    let url = `${GET_MEETING_SV}?page=${currentPage}&limit=${limit}`;
    if (Array.isArray(memoizedDateFilter)) {
      const [StartDate, EndDate] = memoizedDateFilter;
      url += `&StartDate=${StartDate}&EndDate=${EndDate}`;
    } else if (memoizedDateFilter) {
      url += `&date=${memoizedDateFilter}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads, pagination } = response.data;
        handlingRowData(leads);
        setTotalRows(pagination.total);
      })
      .catch((error) => {
        console.error("Error fetching meeting data:", error);
      });
  };

  const handlingRowData = (rowData) => {
    const transformedRows = [];
    var a = {};
    rowData.map((rd, index) => {
      a = {
        id: index + 1,
        SNo: index + 1,
        leadId: rd.id,
        CustomerName: rd.CustomerName == "" ? "-" : rd.CustomerName,
        MobileNo: rd.MobileNo == "" ? "-" : rd.MobileNo,
        AlternateMobileNo:
          rd.AlternateMobileNo == "" ? "-" : rd.AlternateMobileNo,
        state_name: rd.state_name == "" ? "-" : rd.state_name,
        source_of_lead_generated:
          rd.Campaign == null ? "-" : rd.Campaign.CampaignName,
        category: rd.category == "" ? "-" : rd.category,
        AgentId: rd.AgentId,
        BDMId: rd.BDMId,
        CustomerMailId: rd.CustomerMailId == "" ? "-" : rd.CustomerMailId,
        InquiryType: rd.InquiryType,
        Project: rd.Project,
        Superviser: rd.Superviser,
        SuperviserID: rd.SuperviserID,
        WhatsappNo: rd.WhatsappNo == "" ? "-" : rd.WhatsappNo,
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        close_month: rd.close_month,
        follow_up_date:
          rd.follow_up_date == "" ? "-" : formatDate(rd.follow_up_date),
        lead_owner: rd.BDM == null ? "-" : rd.BDM.EmployeeName,
        lead_transfer_date:
          rd.lead_transfer_date == "" || rd.lead_transfer_date == null
            ? "-"
            : formatDate(rd.lead_transfer_date),
        location: rd.location == "" ? "-" : rd.location,
        site_location_address:
          rd.site_location_address == "" ? "-" : rd.site_location_address,
        region_name: rd.region_name == "" ? "-" : rd.region_name,
        sub_category: rd.sub_category == "" ? "-" : rd.sub_category,
        Agent: rd.Agent,
        AgentName: rd.Agent == null ? "-" : rd.Agent.EmployeeName,
        BDM: rd.BDM,
        pincode: rd.pincode == "" ? "-" : rd.pincode,
        call_status: rd.call_status,
        call_type: rd.call_type,
        createdDate: rd.createdAt == "" ? "-" : formatDate(rd.createdAt),
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        bdm_remark: rd.bdm_remark == "" ? "-" : rd.bdm_remark,
        lead_calling_date: "-",
        bdm_calling_date: "-",
        lead_meetings:
          rd.lead_meetings == undefined
            ? []
            : handleSubArrayData(rd.lead_meetings),
      };
      transformedRows.push(a);
    });
    console.log(transformedRows, "-----------");
    setMeetingList(transformedRows);
    setLoading(false);
  };

  const handleSubArrayData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        sno: index + 1,
        category: rd.category,
        closure_month: rd.closure_month,
        createdAt: formatDate(rd.createdAt),
        follow_up_date: formatDate(rd.follow_up_date),
        id: rd.id,
        sub_category: rd.sub_category,
        updatedAt: formatDate(rd.updatedAt),
        ApproachRoad: rd.ApproachRoad == true ? "Yes" : "No",
        BirdsCapacity: rd.BirdsCapacity,
        DirectionDeviationDegree:
          rd.DirectionDeviationDegree == null
            ? "-"
            : rd.DirectionDeviationDegree,
        ElectricityPower: rd.ElectricityPower == true ? "Yes" : "No",
        EstimationRequirement: rd.EstimationRequirement == true ? "Yes" : "No",
        Image: rd.Image,
        IsLandDirectionEastWest:
          rd.IsLandDirectionEastWest == true ? "Yes" : "No",
        LandDimension: rd.LandDimension,
        ModelType: rd.ModelType,
        ShedSize: rd.ShedSize,
        Water: rd.Water == true ? "Yes" : "No",
      };
      transformedRows.push(a);
    });
    return transformedRows;
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const renderRowExpansion = (data) => (
    <Box sx={{ margin: 1 }}>
      <Typography sx={{ fontWeight: "700", marginBottom: "3px" }}>
        Meetings ({data.lead_meetings?.length || 0})
      </Typography>
      <TableContainer
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
        }}
      >
        <Table
          sx={{ minWidth: 450, height: "100%", tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
              <TableCell
                width={50}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 1 }}
              >
                SNo
              </TableCell>
              <TableCell
                width={80}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Date of Visit
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Follow-up Date
              </TableCell>
              <TableCell
                width={80}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Category
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Sub-Category
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Closure Month
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Birds Capacity
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Land Dimension
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Shed Size
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Is Land Direction East West
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Direction Deviation Degree
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Electricity Power
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Water
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Approach Road
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Model Type
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Estimation Requirement
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.lead_meetings?.map((meeting, index) => (
              <TableRow key={index}>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {index + 1}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.createdAt}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.follow_up_date}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "0.8rem", textTransform: "capitalize" }}
                >
                  {meeting.category}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.sub_category}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.closure_month}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.BirdsCapacity}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.LandDimension}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.ShedSize}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.IsLandDirectionEastWest ? "Yes" : "No"}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.DirectionDeviationDegree === "null"
                    ? "-"
                    : meeting.DirectionDeviationDegree}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.ElectricityPower}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.Water}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.ApproachRoad}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.ModelType}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {meeting.EstimationRequirement}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const handleExport = () => {
    // Combine main row data and expanded row data
    const exportData = meetingList.flatMap((row, index) => {
      // Base row data
      const mainRow = {
        SNo: row.SNo,
        "Lead Owner": row.lead_owner,
        "Farmer Name": row.CustomerName,
        "Mobile Number": row.MobileNo,
        Region: row.region_name,
        Category: row.category,
        "Sub-Category": row.sub_category,
        "CSE Remarks": row.agent_remark || "-",
        "BDM/BI Remarks": row.bdm_remark || "-",
        "City/Village/Location": row.location,
        State: row.state_name,
        "Mail ID": row.CustomerMailId,
        "Date of First Calling": row.createdDate,
        "Follow-up Date": row.follow_up_date,
        "Closure Month": row.close_month,
        "Date of Lead Transfer": row.lead_transfer_date,
        "Date of BDM Calling": row.bdm_calling_date,
        "Campaign Name": row.source_of_lead_generated,
        "CSE Name": row.AgentName,
        Project: row.Project,
        "Alternate Number": row.AlternateMobileNo,
        "Whatsapp Number": row.WhatsappNo,
      };

      // If expanded data exists, add it as separate rows
      const expandedRows = row.lead_meetings
        ? row.lead_meetings.map((exp, expIndex) => ({
            ...mainRow,
            "Date of Visit": exp.createdAt, // Example field from expanded data
            "Follow-up Date": exp.follow_up_date, // Example field from expanded data
            Category: exp.category || "-", // Example field from expanded data
            "Sub-Category": exp.sub_category,
            "Closure Month": exp.closure_month,
            "Birds Capacity": exp.BirdsCapacity,
            "Land Dimension": exp.LandDimension,
            "Shed Size": exp.ShedSize,
            "Is Land Direction East West?": exp.IsLandDirectionEastWest,
            "Direction Deviation Degree": exp.DirectionDeviationDegree,
            "Electricity Power": exp.ElectricityPower,
            Water: exp.Water,
            "Approach Road": exp.ApproachRoad,
            "Model Type": exp.ModelType,
            "Estimation Requirement": exp.EstimationRequirement,
          }))
        : [];

      // Return combined main row and expanded rows
      return [mainRow, ...expandedRows];
    });

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BDMActionList");

    // Export to Excel file
    XLSX.writeFile(workbook, "BDMActionList.xlsx");
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      <Paper>
        {/* Export Button */}
        <Tooltip title="Export" arrow>
        <IconButton
          onClick={handleExport}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "35px", // Increased width for a more comfortable click area
            height: "35px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            float: "right",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FileDownloadIcon /> {/* Change icon based on state */}
        </IconButton>
        </Tooltip>

        <TableContainer
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            margin: "8px 0",
          }}
        >
          <Table
            sx={{ minWidth: 650, height: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  align="right"
                  sx={{
                    width: 30,
                    padding: 0,
                  }}
                />
                <TableCell
                  align="center"
                  sx={{
                    width: 50,
                    padding: 1,
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Lead Owner
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Farmer Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Region
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Sub-Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  CSE Remark
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  BDM/BI Remark
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  City/Village/Location
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  State
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Mail Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of First Calling
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Follow-up Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Closure Month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of Lead Transfer
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of BDM Calling
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Campaign Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  CSE Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Project
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Alternate Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Whatsapp Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetingList.length === 0 ? (
                <TableCell colSpan={22}>No Data Available Yet.</TableCell>
              ) : (
                meetingList.map((row, index) => (
                  <React.Fragment key={row.SNo}>
                    <TableRow>
                      <TableCell align="right" sx={{ padding: 0 }}>
                        <IconButton
                          size="small"
                          onClick={() => handleExpandClick(row.SNo)}
                        >
                          {expandedRows[row.SNo] ? (
                            <RemoveIcon></RemoveIcon>
                          ) : (
                            <AddIcon></AddIcon>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="center">{row.lead_owner}</TableCell>
                      <TableCell align="center">{row.CustomerName}</TableCell>
                      <TableCell align="center">{row.MobileNo}</TableCell>
                      <TableCell align="center">{row.region_name}</TableCell>
                      <TableCell
                        align="center"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell align="center">{row.sub_category}</TableCell>
                      <TableCell align="center">
                        <LongTextComponent
                          text={row.agent_remark || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                      </TableCell>
                      <TableCell align="center">
                        <LongTextComponent
                          text={row.bdm_remark || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                      </TableCell>
                      <TableCell align="center">{row.location}</TableCell>
                      <TableCell align="center">{row.state_name}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          wordWrap: "break-word",
                          maxWidth: "200px",
                          whiteSpace: "normal",
                        }}
                      >
                        {row.CustomerMailId}
                      </TableCell>
                      <TableCell align="center">{row.createdDate}</TableCell>
                      <TableCell align="center">{row.follow_up_date}</TableCell>
                      <TableCell align="center">{row.close_month}</TableCell>
                      <TableCell align="center">
                        {row.lead_transfer_date}
                      </TableCell>
                      <TableCell align="center">
                        {row.bdm_calling_date}
                      </TableCell>
                      <TableCell align="center">
                        {row.source_of_lead_generated}
                      </TableCell>
                      <TableCell align="center">{row.AgentName}</TableCell>
                      <TableCell align="center">{row.Project}</TableCell>
                      <TableCell align="center">
                        {row.AlternateMobileNo}
                      </TableCell>
                      <TableCell align="center">{row.WhatsappNo}</TableCell>
                    </TableRow>
                    {expandedRows[row.SNo] && (
                      <TableRow>
                        <TableCell colSpan={24}>
                          <Box
                            sx={{
                              padding: 1,
                              width: "80%", // Set to a specific width if needed, e.g., "80%"
                              overflowX: "auto", // Add horizontal scrolling if content exceeds the container width
                            }}
                          >
                            {renderRowExpansion(row)}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalRows} // Total records from API
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          labelRowsPerPage="Rows per page"
        />
      </Paper>
    </>
  );
};

export default MeetingListComponent;
