import * as React from 'react';
import bdmDashboardStyles from '../BdmDashboard/bdmDashboardStyles.module.css';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import onCall from '../../icons/On_call.png';
import onHold from '../../icons/on_hold.png';
import onBreak from '../../icons/break.png';
import autoCallOff from '../../icons/ato_call_off.png';
import waiting from '../../icons/waiting.png';
import { Grid, IconButton, Stack } from '@mui/material';
import { useMediaQuery, useTheme, Chip} from '@mui/material';

function TeamOfferingTime() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
       <>
        <h4 className={`font-bold text-2xl mb-2`}>Team Offering Time</h4>

        <Grid container spacing={2} className={`mb-4`} wrap='wrap'>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>On Call</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-base' : 'text-lg'}`}>02:54:34</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={onCall}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>On Hold</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-base' : 'text-lg'}`}>00:17:20</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={onHold}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>On Break</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-base' : 'text-lg'}`}>02:13:59</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={onBreak}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>Waiting</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-base' : 'text-lg'}`}>00:13:59</p>
            </div>
            <div className={`w-1/2`}>
                <img
                    src={waiting}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        {/* <Grid item xs={12} md={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold text-sm`}>Auto Call-off</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold text-xl`}>00:13:59</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={autoCallOff}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid> */}
        </Grid>
       </>
      );
    }
    
export default TeamOfferingTime;