import React, { useEffect, useState } from "react";
import {
  IconButton,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Grid,
  Autocomplete,
  Typography,
  Drawer,
  Toolbar,
  Paper,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {
  EXCEL_EXPORT_ALL_DATA_SUPERVISOR,
  GET_CAMPAIGN_NAME,
  GET_CATEGORY,
  GET_INQUIRY_TYPE,
  GET_PROJECT,
  GET_REGION_LIST,
} from "../../constants";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import for the filter icon
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";

const FilterPanel = ({ filters, setFilters, onApplyFilters }) => {
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer visibility

  const handleInputChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value ? (dayjs.isDayjs(value) ? formatDate(value) : value) : null,
    }));
  };

  const ApplyClearAll = () => {
    const clearedFilters = {
      InquiryType: "",
      Project: "",
      CustomerName: "",
      MobileNo: "",
      region: "",
      category: "",
      subcategory: "",
      campaignName: "",
      search: "",
      call_status: "",
      fromDate: null,
      toDate: null,
      sortBy: "",
      sortOrder: "",
    };

    setFilters(clearedFilters); // Reset all filters to their default state
    onApplyFilters(clearedFilters); // Optionally apply cleared filters
    setDrawerOpen(false); // Close the drawer
  };

  // Format function with more robust handling
  const formatDate = (date) => {
    // Ensure the date is a valid Day.js object
    const formattedDate = dayjs.isDayjs(date) ? date : dayjs(date);

    // Return formatted date or null if invalid
    return formattedDate.isValid() ? formattedDate.format("YYYY-MM-DD") : null;
  };

  return (
    <div>
      <div style={{ float: "right" }}>
        <IconButton
          onClick={() => setDrawerOpen((prev) => !prev)}
          style={{
            border: "1px solid #fff",
            borderRadius: "4px",
            margin: "5px",
            width: "40px",
            height: "40px",
            backgroundColor: "#097969",
            color: "#fff",
          }}
        >
          {drawerOpen ? <FilterListOffIcon /> : <FilterListIcon />}
        </IconButton>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: 320, overflowY: "auto" }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Filter Options
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </Toolbar>

        <div
          style={{
            padding: 16,
            overflowY: "auto",
            maxHeight: "calc(100vh - 64px)",
          }}
        >
          <Grid container spacing={1} direction="column">
            {/* Call Status Dropdown */}
            <Grid item xs={12}>
              <TextField
                select
                label="Call Status"
                fullWidth
                value={filters.call_status}
                onChange={(e) =>
                  handleInputChange("call_status", e.target.value)
                }
              >
                <MenuItem value="connected">Connected</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
              </TextField>
            </Grid>

            {/* From Date Picker */}
            <Grid item xs={12}>
              <DatePicker
                label="From Date"
                format="DD/MM/YYYY"
                value={filters.fromDate ? dayjs(filters.fromDate) : null}
                onChange={(newDate) => handleInputChange("fromDate", newDate)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            {/* To Date Picker */}
            <Grid item xs={12}>
              <DatePicker
                label="To Date"
                format="DD/MM/YYYY"
                value={filters.toDate ? dayjs(filters.toDate) : null}
                onChange={(newDate) => handleInputChange("toDate", newDate)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            {/* Sort By Dropdown */}
            <Grid item xs={12}>
              <TextField
                select
                label="Sort By"
                fullWidth
                value={filters.sortBy}
                onChange={(e) => handleInputChange("sortBy", e.target.value)}
              >
                <MenuItem value="createdAt">Date of First Calling</MenuItem>
                <MenuItem value="updatedAt">Last Action Date</MenuItem>
              </TextField>
            </Grid>

            {/* Sort Order Dropdown */}
            <Grid item xs={12}>
              <TextField
                select
                label="Sort Order"
                fullWidth
                value={filters.sortOrder}
                onChange={(e) => handleInputChange("sortOrder", e.target.value)}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </TextField>
            </Grid>

            {/* Buttons */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onApplyFilters(filters);
                    setDrawerOpen(false);
                  }}
                  fullWidth
                >
                  Apply Filters
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={ApplyClearAll}
                  fullWidth
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterPanel;
