import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../reusable Component/Loader";
import moment from "moment";
import { GET_ALL_LEADS, GET_TIMELINE } from "../../constants";
import TimelineDialog from "../reusable Component/TimelineDialog";
import LongTextComponent from "../Leads Page/LongTextComponent";
import FilterPanel from "./FilterPanel";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import for the filter icon

function MasterListTableComponent() {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Zero-based index
  const [pageSize, setPageSize] = useState(20); // Rows per page
  const [totalRows, setTotalRows] = useState(0); // Total rows count
  const [totalPages, setTotalPages] = useState(0); // Total rows count

  const [filters, setFilters] = useState({
    InquiryType: "",
    Project: "",
    CustomerName: "",
    MobileNo: "",
    region: "",
    category: "",
    subcategory: "",
    campaignName: "",
    leadowner: "",
    agentName: "",
    search: "",
  });

  useEffect(() => {
    fetchLeads(currentPage, pageSize, filters);
  }, [currentPage, pageSize]);

  const fetchLeads = async (page, size, filters) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${GET_ALL_LEADS}?page=${page + 1}&limit=${size}&InquiryType=${
          filters.InquiryType
        }&Project=${filters.Project}&CustomerName=${
          filters.CustomerName
        }&MobileNo=${filters.MobileNo}&region=${filters.region}&category=${
          filters.category
        }&subcategory=${filters.subcategory}&campaignName=${
          filters.campaignName
        }&agentName=${filters.agentName}&search=${filters.search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      const { leads, totalLeads, totalPages } = response.data;
      handlingRowData(leads);
      setTotalRows(totalLeads);
      setTotalPages(totalPages); // Save total pages to state

      setLoading(false);
    } catch (error) {
      console.error("Error fetching leads:", error);
      setLoading(false);
    }
  };

  const handlingRowData = (rowData) => {
    const transformedRows = rowData.map((rd, index) => ({
      id: index + 1,
      SNo: index + 1,
      leadId: rd.id,
      CustomerName: rd.CustomerName || "-",
      MobileNo: rd.MobileNo || "-",
      AlternateMobileNo: rd.AlternateMobileNo || "-",
      state_name: rd.state_name || "-",
      source_of_lead_generated: rd.Campaign?.CampaignName || "-",
      category: rd.category || "-",
      AgentId: rd.AgentId,
      BDMId: rd.BDMId,
      CustomerMailId: rd.CustomerMailId || "-",
      InquiryType: rd.InquiryType || "-",
      Project: rd.Project,
      Superviser: rd.Superviser,
      SuperviserID: rd.SuperviserID,
      WhatsappNo: rd.WhatsappNo || "-",
      agent_remark: rd.agent_remark || "-",
      close_month: rd.close_month || "-",
      follow_up_date: rd.follow_up_date ? formatDate(rd.follow_up_date) : "-",
      lead_owner: rd.BDM?.EmployeeName || "-",
      lead_transfer_date: rd.lead_transfer_date
        ? formatDate(rd.lead_transfer_date)
        : "-",
      location: rd.location || "-",
      site_location_address: rd.site_location_address || "-",
      region_name: rd.region_name || "-",
      sub_category: rd.sub_category || "-",
      Agent: rd.Agent,
      AgentName:
        rd.Campaign?.CampaignName === "BDM_Lead"
          ? rd.BDM?.EmployeeName || "-"
          : rd.Agent?.EmployeeName || "-",
      BDM: rd.BDM,
      pincode: rd.pincode || "-",
      call_status: rd.call_status || "-",
      call_type: rd.call_type || "-",
      createdDate: rd.createdAt ? formatDate(rd.createdAt) : "-",
      bdm_remark: rd.bdm_remark || "-",
      lead_calling_date: "-", // Placeholder
      bdm_calling_date: "-", // Placeholder
      updatedAt: rd.updatedAt ? formatDate(rd.updatedAt) : "-",
      last_action: rd.last_action || "-",
    }));
    setRow(transformedRows);
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const columns = [
    { key: "SNo", header: "SNo" },
    { key: "last_action", header: "Last Action" },
    { key: "lead_owner", header: "Lead Owner" },
    { key: "CustomerName", header: "Customer Name" },
    { key: "MobileNo", header: "Mobile Number" },
    { key: "region_name", header: "Region Name" },
    { key: "category", header: "Category" },
    { key: "sub_category", header: "Sub-Category" },
    { key: "agent_remark", header: "CSE Remark" },
    { key: "bdm_remark", header: "BDM Remark" },
    { key: "InquiryType", header: "Enquiry Type" },
    { key: "call_status", header: "Call Status" },
    { key: "call_type", header: "Call Type" },
    { key: "location", header: "Location" },
    { key: "state_name", header: "State" },
    { key: "CustomerMailId", header: "Customer Mail ID" },
    { key: "createdDate", header: "Date of First Calling" },
    { key: "follow_up_date", header: "Follow-Up Date" },
    { key: "close_month", header: "Closure Month" },
    { key: "lead_transfer_date", header: "Date of Lead Transfer" },
    { key: "bdm_calling_date", header: "Date of BDM Calling" },
    { key: "source_of_lead_generated", header: "Campaign Name" },
    { key: "AgentName", header: "CSE Name" },
    { key: "Project", header: "Project" },
    { key: "AlternateMobileNo", header: "Alt Mobile Number" },
    { key: "WhatsappNo", header: "Whatsapp Number" },
    { key: "updatedAt", header: "Lead Updated On" },
  ];

  const [openTimeline, setopenTimeline] = useState(false);
  const [timelineLogs, setTimelineLogs] = useState([]);
  const [custData, setCustData] = useState([]);

  const handletimelineClick = (row) => {
    console.log(row);
    setopenTimeline(true);
    setCustData(row);
    axios
      .get(`${GET_TIMELINE}/${row.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadLogs } = response.data;
        setTimelineLogs(leadLogs);
      })
      .catch((error) => {});
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage); // Convert MUI's 0-based to 1-based page number
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to page 1 on page size change
  };

  // Define your column widths in a mapping
  const columnWidths = {
    SNo: "50px",
    last_action: "150px",
    lead_owner: "180px",
    CustomerName: "150px",
    MobileNo: "150px",
    region_name: "150px",
    category: "120px",
    sub_category: "150px",
    agent_remark: "200px",
    bdm_remark: "200px",
    InquiryType: "150px",
    call_status: "120px",
    call_type: "120px",
    location: "120px",
    state_name: "120px",
    CustomerMailId: "150px",
    createdDate: "150px",
    follow_up_date: "150px",
    close_month: "150px",
    lead_transfer_date: "120px",
    bdm_calling_date: "120px",
    source_of_lead_generated: "150px",
    AgentName: "150px",
    Project: "120px",
    AlternateMobileNo: "150px",
    WhatsappNo: "150px",
    updatedAt: "150px",
    action: "100px",
  };

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "hot":
        return "error"; // React blue
      case "warm":
        return "warning"; // JavaScript yellow
      case "cold":
        return "info"; // UI dark blue
      default:
        return "default"; // Default gray
    }
  };

  const handleApplyFilters = (newFilters) => {
    fetchLeads(0, 20, newFilters); // Adjust page and size as needed
  };

  const [showFilters, setShowFilters] = useState(false); // State to toggle the filter panel

  const handleFilterToggle = () => {
    setShowFilters(!showFilters); // Toggle filter panel visibility
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      <Paper>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              backgroundColor: "#f5f5f5", // Grey background
              padding: "8px 16px", // Add some padding for spacing
              borderRadius: "8px", // Rounded corners
              border: "1px solid #ccc", // Light border color
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Elevated box shadow
              display: "inline-block", // Ensure it takes only as much space as needed
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold", // Make the text bold
                fontSize: "1.2rem", // Increase font size for prominence
                color: "#333", // Set a darker color for better visibility
              }}
            >
              Total Leads: {totalRows}
            </Typography>
          </Box>

          <FilterPanel
            filters={filters}
            setFilters={setFilters}
            onApplyFilters={handleApplyFilters}
          />
        </div>

        <TableContainer
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            margin: "16px 0",
          }}
        >
          <Table
            sx={{ minWidth: 650, height: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.key}
                    align="center"
                    sx={{
                      width: columnWidths[column.key] || "auto",
                      padding: 0,
                    }}
                  >
                    {column.header}
                  </TableCell>
                ))}
                <TableCell
                  key={"action"}
                  align="center"
                  sx={{
                    width: columnWidths["action"] || "auto",
                    padding: 0,
                    position: "sticky", // Make the cell sticky
                    right: 0, // Align it next to the Action column
                    backgroundColor: "#EEF0F4", // Set background color for visibility
                    zIndex: 10, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["SNo"] || "auto", padding: 0 }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["last_action"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.last_action}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["lead_owner"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.lead_owner}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["CustomerName"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.CustomerName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["MobileNo"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.MobileNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["region_name"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.region_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["category"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.category === "-" ? (
                      "-"
                    ) : (
                      <Chip
                        label={row.category}
                        color={`${getColorForTag(row.category)}`}
                        size="small"
                        sx={{ textTransform: "capitalize" }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["sub_category"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.sub_category}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["agent_remark"] || "auto",
                      padding: 0,
                    }}
                  >
                    <LongTextComponent
                      text={row.agent_remark || "-"}
                      maxLength={50}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["bdm_remark"] || "auto",
                      padding: 0,
                    }}
                  >
                    <LongTextComponent
                      text={row.bdm_remark || "-"}
                      maxLength={50}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["InquiryType"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.InquiryType}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["call_status"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.call_status}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["call_type"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.call_type}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["location"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.location}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["state_name"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.state_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["CustomerMailId"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.CustomerMailId}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["createdDate"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.createdDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["follow_up_date"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.follow_up_date}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["close_month"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.close_month}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["lead_transfer_date"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.lead_transfer_date}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["bdm_calling_date"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.bdm_calling_date}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["source_of_lead_generated"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.source_of_lead_generated}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["AgentName"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.AgentName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Project"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Project}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["AlternateMobileNo"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.AlternateMobileNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["WhatsappNo"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.WhatsappNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["updatedAt"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.updatedAt}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["action"] || "auto",
                      padding: 0,
                      position: "sticky", // Make the cell sticky
                      right: 0, // Align it next to the Action column
                      backgroundColor: "#EEF0F4", // Set background color for visibility
                      zIndex: 10, // Higher z-index to stay above other cells
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Tooltip title="Timeline" arrow>
                      <IconButton
                        onClick={() => handletimelineClick(row)}
                        style={{
                          border: "1px solid",
                          borderRadius: "4px",
                          margin: "5px",
                          width: "24px",
                          height: "24px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        color="primary"
                      >
                        <VisibilityIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalRows} // Total items for MUI pagination
          page={currentPage} // 0-based for MUI
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 25]}
        />
      </Paper>
      <TimelineDialog
        open={openTimeline}
        setOpen={setopenTimeline}
        timelineLogs={timelineLogs}
        custData={custData}
      ></TimelineDialog>
    </>
  );
}

export default MasterListTableComponent;
