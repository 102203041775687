import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

function CountListComponent({ row }) {

    const totalRow = useMemo(() => {
        if (!row || row.length === 0) return null;

        return row.reduce(
            (acc, item) => {
                return {
                    ...acc,
                    openCount: (acc.openCount || 0) + (item.openCount || 0),
                    closedCount: (acc.closedCount || 0) + (item.closedCount || 0),
                    totalLeads: (acc.totalLeads || 0) + (item.totalLeads || 0),
                };
            },
            {
                agentName: 'Total',
                openCount: 0,
                closedCount: 0,
                totalLeads: 0,
                
            }
        );
    }, [row]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'agentName',
                header: 'Agent Name',
                size: 150,
                footer: 'Total', // Static text for region_name column
            },
            {
                accessorKey: 'openCount',
                header: 'Open',
                size: 50,
                footer: totalRow?.openCount ?? 0, // Show the total for hot_count
            },
            {
                accessorKey: 'closedCount',
                header: 'Closed',
                size: 50,
                footer: totalRow?.closedCount ?? 0, // Show the total for warm_count
            },
            {
                accessorKey: 'totalLeads',
                header: 'Total',
                size: 50,
                footer: totalRow?.totalLeads ?? 0, // Show the total for cold_count
            },
            
        ],
        [totalRow] // Make sure columns update when the totalRow changes
    );

    // Prepare data without the total row in the main table body
    const data = useMemo(() => {
        return [...row]; // Regular data without total row
    }, [row]);

    const table = useMaterialReactTable({
        columns: columns,
        data: row,
        enableStickyHeader: true,
        enableStickyFooter: true, // Enable sticky footer
        enablePagination: false,
        enableGlobalFilter:false,
        enableColumnFilters: false,
        enableColumnActions: false, // Hide the column menu
        muiTableHeadCellProps:{
            sx: {
                padding: '8px',
                boxSizing: 'border-box',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
            },
        },
        muiTableBodyCellProps:({ row }) => ({
            sx: {
              
                padding: '8px',
                borderBottom: '1px solid #ddd',
                borderRight: '1px solid #ddd',
            },
        }),
        muiTableFooterCellProps:{
            sx: {
                
                padding: '8px',
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
            },
        },
            //Simply adding a table title to the top-left of the top toolbar
            renderTopToolbarCustomActions: () => (
                <Typography variant="h5">Audit Summary Report</Typography>
            ),
      });

    return(
        <>
         <MaterialReactTable
            table={table}
        />
        </>
    );

}

export default CountListComponent;