import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LoginContext } from "../LoginPage/LoginContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import bdmFormStyles from "./bdmFormStyles.module.css";
import {
  Divider,
  Stack,
  Grid,
  Button,
  Snackbar,
  IconButton,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  ENQUIRY_TYPE,
  PROJECT,
  CATEGORY,
  LEAD_SOURCE,
  CLOSED_SUB_CATEGORY,
  COLD_SUB_CATEGORY,
  HOT_SUB_CATEGORY,
  PENDING_SUB_CATEGORY,
  WARM_SUB_CATEGORY,
  CREATE_LEAD_API,
  STATE_API,
  CALL_TYPE,
  TITLE,
  BDM_LIST,
  CALL_STATUS,
  BDM_REMARK_UPDATE,
  GET_REMARK_LIST,
  UPDATE_STATUS_AGENT,
  PAGE_SECTIONS,
  GET_MEETING,
  GET_SITE_VISIT,
  GET_ESTIMATION,
  DOWNLOAD_AND_GET_CONVERTED,
  ON_CALL_DISCUSSION_BDM,
  GET_TIMELINE,
  GET_ESTIMATION_GENERATED,
  ESTIMATION_DOC_DOWNLOAD,
  GET_ESTIMATION_SHARED,
  GET_ON_CALL_LIST,
  GET_CONVERTED_LIST,
  HO_DOCUMENT_DOWNLOAD,
  GET_DETAILS_BY_MOBILE_NUMBER,
} from "../../constants";
import MeetingFormComponent from "./meetingForm";
import SiteVisitFormComponent from "./siteVisitForm";
import EstimationFormComponent from "./estimationForm";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Fab from "@mui/material/Fab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { useMediaQuery, useTheme, Chip } from "@mui/material";
import LongTextComponent from "../Leads Page/LongTextComponent";
import OnCallFormComponent from "./OnCallForm";
import Loader from "../reusable Component/Loader";
import EditIcon from "@mui/icons-material/Edit";
import EditMasterDetails from "./editMasterDetails";
import SyncIcon from "@mui/icons-material/Sync";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TimelineDialog from "../reusable Component/TimelineDialog";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function BDMDetailsPage() {
  const [runEffect, setRunEffect] = useState(false);

  
  const empDetails = JSON.parse(localStorage.getItem("userData"));
  
  const leadDetails = JSON.parse(localStorage.getItem("leadDetails"));

  const [storedLeadDetails, setStoredLeadDetails] = useState(
    leadDetails || null
  );

  useEffect(() => {
    const storedLeadDetails = JSON.parse(localStorage.getItem("leadDetails"));

    if (storedLeadDetails) {
      axios
        .get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${storedLeadDetails.MobileNo}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          const { lead } = response.data;
          handlingRowData(lead);
        })
        .catch((error) => {
          console.error("Error fetching lead details:", error);
        });
    }
  }, [runEffect]);

  const handlingRowData = (data) => {
    if (!data) {
      console.warn("No lead data available to transform.");
      return;
    }

    const transformedRow = {
      id: 1,
      SNo: 1,
      leadId: data.id,
      CustomerName: data.CustomerName?.trim() || "-",
      MobileNo: data.MobileNo || "-",
      AlternateMobileNo: data.AlternateMobileNo || "-",
      state_name: data.state_name || "-",
      source_of_lead_generated: data.Campaign?.CampaignName || "-",
      category: data.category || "-",
      AgentId: data.AgentId,
      BDMId: data.BDMId,
      CustomerMailId: data.CustomerMailId || "-",
      InquiryType: data.InquiryType || "-",
      Project: data.Project || "-",
      Superviser: data.Superviser,
      SuperviserID: data.SuperviserID,
      WhatsappNo: data.WhatsappNo || "-",
      agent_remark: data.agent_remark || "-",
      close_month: data.close_month || "-",
      follow_up_date: data.follow_up_date
        ? formatDate(data.follow_up_date)
        : "-",
      lead_owner: data.BDM?.EmployeeName || "-",
      lead_transfer_date: data.lead_transfer_date
        ? formatDate(data.lead_transfer_date)
        : "-",
      location: data.location || "-",
      site_location_address: data.site_location_address || "-",
      region_name: data.region_name || "-",
      sub_category: data.sub_category || "-",
      Agent: data.Agent,
      AgentName: data.Agent?.EmployeeName || "-",
      BDM: data.BDM,
      pincode: data.pincode || "-",
      call_status: data.call_status,
      call_type: data.call_type,
      createdDate: data.createdAt ? formatDate(data.createdAt) : "-",
      bdm_remark: data.bdm_remark || "-",
      lead_calling_date: "-",
      bdm_calling_date: "-",
      updatedAt: data.updatedAt ? formatDate(data.updatedAt) : "-",
      last_action: data.last_action || "-",
    };

    // Update state and localStorage with transformed data
    setStoredLeadDetails(transformedRow);
    localStorage.setItem("leadDetails", JSON.stringify(transformedRow));
  };

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setSubCategory("");
    feildsShow(value);
    if (value.trim() === "") {
      setCategory(value);
      setCategoryError("Category is required.");
    } else {
      setCategory(value);
      setCategoryError("");
    }
  };

  const [showField, setShowField] = useState(false);
  const [enableField, setEnableField] = useState(false);

  const feildsShow = (cat) => {
    if (cat == "hot" || cat == "warm") {
      setEnableField(true);
    } else {
      setEnableField(false);
    }
    if (cat == "pending") {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };

  const [subCategory, setSubCategory] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const handleSubCategoryChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setSubCategory(value);
      setSubCategoryError("Sub-Category is required.");
    } else {
      setSubCategory(value);
      setSubCategoryError("");
    }
  };

  const [followUpDate, setFollowUpDate] = useState(undefined);
  const [closureMonth, setClosureMonth] = useState(undefined);
  const [bdmRemark, setBdmRemark] = useState("");
  const [bdmRemarkError, setBdmRemarkError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickList = (event) => {
    const value = event.currentTarget.textContent;
    if (value === "On-Call Discussion") {
      const element = document.getElementById("onCall_element");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
      }

      handleClose();
    } else if (value === "Meeting") {
      handleClickOnMeeting(event);
    } else if (value === "Site Visit") {
      handleClickOnSiteVisit(event);
    } else if (value === "Estimation Request") {
      handleClickOnEstimate(event);
    } else if (value === "Estimation Generated") {
      handleClickOnEstimateGenerated(event);
    } else if (value === "Estimation Shared") {
      handleClickOnEstimateShared(event);
    } else if (value === "Converted") {
      handleClickOnConverted(event);
    }
  };

  const handleClickOnMeeting = (event) => {
    const value = event.currentTarget.textContent;
    const element = document.getElementById("meeting_element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
    }
    handleClose();
  };

  const handleClickOnSiteVisit = (event) => {
    const value = event.currentTarget.textContent;
    const element = document.getElementById("siteVisit_element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
    }
    handleClose();
  };

  const handleClickOnEstimate = (event) => {
    const value = event.currentTarget.textContent;
    const element = document.getElementById("estimate_element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
    }
    handleClose();
  };

  const handleClickOnEstimateGenerated = (event) => {
    const value = event.currentTarget.textContent;
    const element = document.getElementById("estimate_generated_element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
    }
    handleClose();
  };
  const handleClickOnEstimateShared = (event) => {
    const value = event.currentTarget.textContent;
    const element = document.getElementById("estimate_shared_element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
    }
    handleClose();
  };
  const handleClickOnConverted = (event) => {
    const value = event.currentTarget.textContent;
    const element = document.getElementById("converted_element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scrolls to a specific element
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [error, setError] = useState("");

  const handleFollowupDateTime = (e) => {
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Function to pad numbers with leading zeros
    const pad = (num) => (num < 10 ? "0" + num : num);

    // Extract date components
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();

    // Formatted date and time string
    const formattedDate = `${year}-${month}-${day}`;
    setFollowUpDate(formattedDate);
    if (category == "hot" || category == "warm" || category == "pending") {
      if (formattedDate === undefined) {
        setError("Follow-up Date is required");
      } else {
        setError("");
      }
    }
    // console.log(formattedDate);
  };

  const handleChangeExpectedClosureMonth = (e) => {
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract month and year
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Formatted string
    const formattedDate = `${monthName} ${year}`;

    setClosureMonth(formattedDate);
  };

  const [showMeetingForm, setShowMeetingForm] = useState(false);
  const handleNewMeetingClick = () => {
    setShowMeetingForm(true);
  };

  const [showSiteVisitForm, setSiteVisitForm] = useState(false);
  const handleNewSiteVisitClick = () => {
    setSiteVisitForm(true);
  };

  const [showEstimationForm, setEstimationForm] = useState(false);
  const [newDisabled, setNewDisabled] = useState(false);
  const handleNewEStimationClick = () => {
    setEstimationForm(true);
  };

  const handleOnCallFromSubmit = () => {
    let isValid = true;

    if (category.trim() === "") {
      setCategoryError("Category is required.");
      isValid = false;
    }

    if (subCategory.trim() === "") {
      setSubCategoryError("Sub-Category is required.");
      isValid = false;
    }

    if (bdmRemark.trim() === "") {
      setBdmRemarkError("Remark is required.");
      isValid = false;
    }

    if (category == "hot" || category == "warm" || category == "pending") {
      if (followUpDate === undefined) {
        setError("Follow-up Date is required");
        isValid = false;
      }
    }

    if (isValid) {
      const fromData = {
        follow_up_date: followUpDate,
        category: category,
        sub_category: subCategory,
        LeadDetailId: leadDetails.leadId,
        BDMId: empDetails.EmployeeId,
        closure_month: closureMonth,
        remark: bdmRemark,
      };

      axios
        .post(ON_CALL_DISCUSSION_BDM, fromData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          setSeverity("success");
          setMessage(response.data.message);
          setOpenSnackbar(true);
          setBdmRemark("");
          setCategory("");
          setSubCategory("");
          setFollowUpDate(undefined);
          setClosureMonth(undefined);
          setEnableField(false);
          setShowField(false);
          setRunEffect(true);
        })
        .catch((error) => {
          if (error.response) {
            setSeverity("error");
            setMessage(error.response.data.message);
            setOpenSnackbar(true);
            setRunEffect(false);
          } else if (error.request) {
            // The request was made but no response was received

            setSeverity("error");
            setMessage("Internal Server Error.");
            setOpenSnackbar(true);
            setRunEffect(false);
          }
        });
    } else {
      console.log("Form submission blocked due to validation errors.");
      setSeverity("error");
      setMessage("Please check for missing fields.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [remarkList, setRemarkList] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [siteVisitList, setSiteVisitList] = useState([]);
  const [estimationList, setEstimationtList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${GET_ON_CALL_LIST}/${leadDetails.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadUpdates } = response.data;

        setRunEffect(false);
        handlingOnCallRowData(leadUpdates);
        setLoading(false);

        // Update lead details in local storage
        localStorage.setItem("leadDetails", JSON.stringify(leadDetails));
      })
      .catch((error) => {
        setLoading(false);
      });

    axios
      .get(`${GET_MEETING}/${leadDetails.leadId}/meetings`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setMeetingList(response.data);

        setRunEffect(false);
        handlingMeetingRowData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    axios
      .get(`${GET_SITE_VISIT}/${leadDetails.leadId}/site-visit`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setSiteVisitList(response.data);

        setRunEffect(false);
        handlingSiteVisitRowData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    axios
      .get(`${GET_ESTIMATION}/${leadDetails.leadId}/estimation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setEstimationtList(response.data);

        setRunEffect(false);
        handlingEstimationRowData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    axios
      .get(`${GET_ESTIMATION_GENERATED}/${leadDetails.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { data } = response.data;

        setRunEffect(false);
        handlingGeneratedEstimationRowData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    axios
      .get(`${GET_ESTIMATION_SHARED}/${leadDetails.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { data } = response.data;

        setRunEffect(false);
        handlingSharedEstimationRowData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    axios
      .get(`${GET_CONVERTED_LIST}/${leadDetails.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { data } = response.data;

        setRunEffect(false);
        handlingConvertedEstimationRowData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [runEffect]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scrolls to the top of the page
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const columnsOnCall = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 30 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      disableColumnFilter: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      flex: 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      flex: 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{ textTransform: "capitalize", height: "20px" }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      flex: 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "remark",
      headerName: "Remarks",
      flex: 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "AddedBy",
      headerName: "Added By",
      flex: 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
  ];

  const [finalOnCallRow, setFinalOnCallRow] = useState([]);

  const handlingOnCallRowData = (data) => {
    // Log the incoming data

    if (!Array.isArray(data) || data.length === 0) {
      setFinalOnCallRow([]); // In case data is empty
      return;
    }

    const transformedRows = data.map((rd, index) => {
      return {
        id: index + 1,
        sno: index + 1,
        category: rd.category ?? "-", // fallback to '-' if null or undefined
        sub_category: rd.sub_category ?? "-", // same as above
        closure_month: rd.closure_month ?? "-",
        follow_up_date: rd.follow_up_date ? formatDate(rd.follow_up_date) : "-",
        remark: rd.remark ?? "",
        AddedBy: rd.BDMId == null ? "-" : rd.BDM?.EmployeeName ?? "Unknown",
        LeadDetailId: rd.LeadDetailId ?? "-", // Ensure LeadDetailId is not undefined
        createdAt: rd.createdAt ? formatDate(rd.createdAt) : "-",
        updatedAt: rd.updatedAt ? formatDate(rd.updatedAt) : "-",
      };
    });

    // Set the final data
    setFinalOnCallRow(transformedRows);
  };

  const columnsMeeting = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 20 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      disableColumnFilter: true,
    },
    {
      field: "createdAt",
      headerName: "Meeting Created Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{ textTransform: "capitalize", height: "20px" }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "ModelType",
      headerName: "Model Type",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "EstimationRequirement",
      headerName: "Estimation Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "BirdsCapacity",
      headerName: "Birds Capacity",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "LandDimension",
      headerName: "Land Dimension",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedSize",
      headerName: "Shed Size",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "IsLandDirectionEastWest",
      headerName: "Is Land Direction East West",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "DirectionDeviationDegree",
      headerName: "Direction Deviation Degree",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ElectricityPower",
      headerName: "Electricity Power",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Water",
      headerName: "Water",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ApproachRoad",
      headerName: "Approach Road",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },

    {
      field: "Image",
      headerName: "Images",
      width: isMobile ? 150 : 200,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {params.value && params.value.length > 0 ? (
            params.value.map((imageUrl, index) => (
              <a
                href={imageUrl}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  window.open(imageUrl, "_blank"); // Open in new tab
                }}
              >
                <img
                  src={imageUrl}
                  alt={`img_${index}`}
                  style={{ width: 20, height: 20 }}
                />
              </a>
            ))
          ) : (
            <p>-</p> // Show a dash if no images are available
          )}
        </div>
      ),
    },
  ];

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "hot":
        return "error";
      case "warm":
        return "warning";
      case "cold":
        return "info";
      case "pending":
        return "error";
      case "need for approval":
        return "warning";
      case "generated":
        return "success";
      case "estimation shared":
        return "secondary";
      case "converted":
        return "success";
      default:
        return "default";
    }
  };

  const [finalMeetingRow, setFinalMeetingRow] = useState([]);
  const handlingMeetingRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        sno: index + 1,
        BirdsCapacity: rd.BirdsCapacity === null ? "-" : rd.BirdsCapacity,
        LandDimension: rd.LandDimension === "" ? "-" : rd.LandDimension,
        ShedSize: rd.ShedSize === "" ? "-" : rd.ShedSize,
        IsLandDirectionEastWest:
          rd.IsLandDirectionEastWest === true ? "Yes" : "No",
        DirectionDeviationDegree:
          rd.DirectionDeviationDegree === null
            ? "-"
            : rd.DirectionDeviationDegree,
        ElectricityPower: rd.ElectricityPower === true ? "Yes" : "No",
        Water: rd.Water === true ? "Yes" : "No",
        ApproachRoad: rd.ApproachRoad === true ? "Yes" : "No",
        ModelType: rd.ModelType === null ? "-" : rd.ModelType,
        EstimationRequirement: rd.EstimationRequirement === true ? "Yes" : "No",
        Image: rd.Image,
        category: rd.category === null ? "-" : rd.category,
        sub_category: rd.sub_category === null ? "-" : rd.sub_category,
        closure_month: rd.closure_month === null ? "-" : rd.closure_month,
        follow_up_date:
          rd.follow_up_date === null ? "-" : formatDate(rd.follow_up_date),
        LeadDetailId: rd.LeadDetailId,
        createdAt: formatDate(rd.createdAt),
        remark: rd.remark,
      };
      transformedRows.push(a);
    });
    setFinalMeetingRow(transformedRows);
  };

  const columnsSiteVisit = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 20 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      disableColumnFilter: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={getColorForTag(params.value)}
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                margin: "10px",
              }}
            />
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "ModelType",
      headerName: "Model Type",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "EstimationRequirement",
      headerName: "Estimation Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "BirdsCapacity",
      headerName: "Birds Capacity",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "LandDimension",
      headerName: "Land Dimension",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedSize",
      headerName: "Shed Size",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "IsLandDirectionEastWest",
      headerName: "Is Land Direction East West",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "DirectionDeviationDegree",
      headerName: "Direction Deviation Degree",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ElectricityPower",
      headerName: "Electricity Power",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Water",
      headerName: "Water",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ApproachRoad",
      headerName: "Approach Road",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },

    {
      field: "Image",
      headerName: "Images",
      width: isMobile ? 150 : 200,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {params.value && params.value.length > 0 ? (
            params.value.map((imageUrl, index) => (
              <a
                href={imageUrl}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <img
                  src={imageUrl}
                  alt={`img_${index}`}
                  style={{ width: 20, height: 20 }}
                />
              </a>
            ))
          ) : (
            <p>-</p> // Show a dash if no images are available
          )}
        </div>
      ),
    },
  ];

  const [finalSiteVisitRow, setFinalSiteVisitRow] = useState([]);
  const handlingSiteVisitRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        sno: index + 1,
        BirdsCapacity: rd.BirdsCapacity === null ? "-" : rd.BirdsCapacity,
        LandDimension: rd.LandDimension === "" ? "-" : rd.LandDimension,
        ShedSize: rd.ShedSize === "" ? "-" : rd.ShedSize,
        IsLandDirectionEastWest:
          rd.IsLandDirectionEastWest === true ? "Yes" : "No",
        DirectionDeviationDegree:
          rd.DirectionDeviationDegree === null
            ? "-"
            : rd.DirectionDeviationDegree,
        ElectricityPower: rd.ElectricityPower === true ? "Yes" : "No",
        Water: rd.Water === true ? "Yes" : "No",
        ApproachRoad: rd.ApproachRoad === true ? "Yes" : "No",
        ModelType: rd.ModelType === null ? "-" : rd.ModelType,
        EstimationRequirement: rd.EstimationRequirement === true ? "Yes" : "No",
        Image: rd.Image,
        category: rd.category === null ? "-" : rd.category,
        sub_category: rd.sub_category === null ? "-" : rd.sub_category,
        closure_month: rd.closure_month === null ? "-" : rd.closure_month,
        follow_up_date:
          rd.follow_up_date === null ? "-" : formatDate(rd.follow_up_date),
        LeadDetailId: rd.LeadDetailId,
        remark: rd.remark,
      };
      transformedRows.push(a);
    });
    setFinalSiteVisitRow(transformedRows);
  };

  const IconCell = ({ row }) => (
    <>
      <Tooltip title="Convert" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
            backgroundColor: "#097969",
          }}
          onClick={() => handleConvertIconClick(row)}
        >
          <SyncIcon sx={{ fontSize: "1rem", color: "#fff" }}></SyncIcon>
        </IconButton>
      </Tooltip>

      {/* Download button */}
      {row.ho_document && row.ho_document.length > 0 && (
        <Tooltip title="Download" arrow>
          <IconButton
            style={{
              border: "1px solid",
              borderRadius: "4px",
              padding: "2px",
              width: "24px",
              height: "24px",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "4px",
            }}
            color="success"
            onClick={() => handleDownload(row)} // Pass the document URL
          >
            <DownloadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {/* <Tooltip title="View" arrow>
        <IconButton
        style={{
            border: '1px solid',
            borderRadius: '4px',
            padding: '2px',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '5px',
            backgroundColor: '#097969'
        }}
        color='primary'
          onClick={() => handleOnConvertedViewClick(row)}
        >
        <VisibilityIcon sx={{fontSize: '1rem', color: '#fff'}}></VisibilityIcon>
        </IconButton>
        </Tooltip>

        <Tooltip title="Download All" arrow>
        <IconButton
        style={{
            border: '1px solid',
            borderRadius: '4px',
            padding: '2px',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#097969'
        }}
        color='primary'
          onClick={() => handleDownloadClick(row)}
        >
        <CloudDownloadIcon sx={{fontSize: '1rem', color: '#fff'}}></CloudDownloadIcon>
        </IconButton>
        </Tooltip> */}
    </>
  );

  // Function to handle the download
  const handleDownload = (row) => {
    setLoading(true);

    axios
      .get(`${HO_DOCUMENT_DOWNLOAD}/${row.estimation_id}`,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Documents.zip"); // Specify the filename here
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Clean up after download

        setLoading(false);

        setSeverity("success");
        setMessage("Documents Downloaded Successfully");
        setOpenSnackbar(true);

        setRunEffect(true);
      })
      .catch((error) => {
        setSeverity("error");
        setMessage("Unable to Download.");
        setOpenSnackbar(true);
        setLoading(false);
      });
  };

  const handleConvertIconClick = (row) => {
    localStorage.setItem("estimation_id", row.estimation_id);
    navigate("/bdmDetails/converted");
  };

  const handleOnConvertedViewClick = (row) => {
    navigate("/bdmDetails/converted/view");
  };

  const handleDownloadClick = (row) => {
    setLoading(true);
    axios
      .get(
        `${DOWNLOAD_AND_GET_CONVERTED}?LeadDetailId=${row.LeadDetailId}&zip=true`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Lead_documents.zip"); // Specify the filename here
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Clean up after download

        setLoading(false);

        setSeverity("success");
        setMessage("Documents Downloaded Successfully");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSeverity("error");
        setMessage("Unable to Download.");
        setOpenSnackbar(true);
        setLoading(false);
      });
  };

  const columnsEstimation = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 30 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
    },
    {
      field: "EstimationRequirement",
      headerName: "Estimation Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Typography
              key={`index_${params.value}`}
              size="small"
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                fontWeight: "700",
                fontSize: "0.8rem",
              }}
            >
              {params.value}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                margin: "10px",
              }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: isMobile ? 300 : 500,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "estimationNumber",
      headerName: "Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimationNumber",
      headerName: "Last Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimation_amount",
      headerName: "Estimation Value",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "firm_farmer_name",
      headerName: "Firm/Farmer Name",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Estimation_generated_date",
      headerName: "Estimation Generated Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Estimation_request_date",
      headerName: "Estimation Request Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{ textTransform: "capitalize", height: "20px" }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },

    {
      field: "CivilConstructedStarted",
      headerName: "Civil Construction Started",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedLength",
      headerName: "Shed Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "EquipementPlacementLength",
      headerName: "Equipment Placement Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedWidth",
      headerName: "Shed Width",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CShape",
      headerName: "C-Shape",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ModalType",
      headerName: "Model Type",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "SideWallColumnToColumnGap",
      headerName: "Side Wall Column to Column Gap",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "NumberOfSheds",
      headerName: "Number of Sheds",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CurtainRequirment",
      headerName: "Curtain Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "DiselBrooderRequirment",
      headerName: "Diesel Brooder Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "PowerSupply",
      headerName: "Power Supply",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "WaterSupply",
      headerName: "Water Available",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
  ];

  const [finalEstimationRow, setEstimationRow] = useState([]);
  const handlingEstimationRowData = (data) => {
    const transformedRows = [];
    var a = {};
    const filteredData = data.filter(
      (est) => est.status === "Pending" || est.status === "Need for Approval"
    );
    filteredData.map((rd, index) => {
      a = {
        id: index + 1,
        sno: index + 1,
        status: rd.status,
        EstimationRequirement:
          rd.EstimationRequirement == null ? "-" : rd.EstimationRequirement,
        CivilConstructedStarted:
          rd.CivilConstructedStarted == true ? "Yes" : "No",
        ShedLength: rd.ShedLength == null ? "-" : rd.ShedLength,
        EquipementPlacementLength:
          rd.EquipementPlacementLength == null
            ? "-"
            : rd.EquipementPlacementLength,
        ShedWidth: rd.ShedWidth == true ? "Yes" : "No",
        CShape: rd.CShape == true ? "Yes" : "No",
        ModalType: rd.ModalType == null ? "-" : rd.ModalType,
        SideWallColumnToColumnGap:
          rd.SideWallColumnToColumnGap == null
            ? "-"
            : rd.SideWallColumnToColumnGap,
        NumberOfSheds: rd.NumberOfSheds,
        CurtainRequirment: rd.CurtainRequirment == true ? "Yes" : "No",
        DiselBrooderRequirment:
          rd.DiselBrooderRequirment == true ? "Yes" : "No",
        PowerSupply: rd.PowerSupply == true ? "Yes" : "No",
        WaterSupply: rd.WaterSupply == true ? "Yes" : "No",
        Remarks: rd.Remarks == null ? "-" : rd.Remarks,
        category: rd.category == null ? "-" : rd.category,
        sub_category: rd.sub_category == null ? "-" : rd.sub_category,
        follow_up_date:
          rd.follow_up_date == null ? "-" : formatDate(rd.follow_up_date),
        closure_month: rd.closure_month == null ? "-" : rd.closure_month,
        createdAt: rd.createdAt == null ? "-" : formatDate(rd.createdAt),
        LeadDetailId: rd.LeadDetailId,
        estimationNumber:
          rd.estimationNumber === null ? "-" : rd.estimationNumber,
        estimation_amount:
          rd.estimation_amount === null ? "-" : rd.estimation_amount,
        firm_farmer_name:
          rd.firm_farmer_name === null ? "-" : rd.firm_farmer_name,
        Estimation_generated_date:
          rd.Estimation_generated_date === null
            ? "-"
            : formatDate(rd.Estimation_generated_date),
        Estimation_request_date:
          rd.Estimation_request_date === null
            ? "-"
            : formatDate(rd.Estimation_request_date),
      };
      transformedRows.push(a);
    });
    setEstimationRow(transformedRows);
  };

  

  const [downloadLoading, setDownloadLoading] = useState(false);

  const IconCellGen = ({ row }) => (
    <>
      {row.ho_document.length > 0 && (
        <Tooltip title="Download Documents" arrow>
          <IconButton
            style={{
              border: "1px solid",
              borderRadius: "4px",
              padding: "2px",
              width: "24px",
              height: "24px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#097969",
            }}
            color="primary"
            onClick={() => handleDownloadDoc(row)}
          >
            <CloudDownloadIcon
              sx={{ fontSize: "1rem", color: "#fff" }}
            ></CloudDownloadIcon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const handleDownloadDoc = (row) => {
    setLoading(true);

    const fromData = {
      estimation_id: row.estimation_id,
      download_done: true,
      employeeId: localStorage.getItem("username"),
    };

    axios
      .post(`${ESTIMATION_DOC_DOWNLOAD}`, fromData, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Documents.zip"); // Specify the filename here
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Clean up after download

        setLoading(false);

        setSeverity("success");
        setMessage("Documents Downloaded Successfully");
        setOpenSnackbar(true);

        setRunEffect(true);
      })
      .catch((error) => {
        setSeverity("error");
        setMessage("Unable to Download.");
        setOpenSnackbar(true);
        setLoading(false);
      });
  };

  const columnsGenEstimation = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 30 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
    },
    {
      field: "EstimationRequirement",
      headerName: "Estimation Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Typography
              key={`index_${params.value}`}
              size="small"
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                fontWeight: "700",
                fontSize: "0.8rem",
              }}
            >
              {params.value}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{ textTransform: "capitalize", height: "20px" }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "estimationNumber",
      headerName: "Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimationNumber",
      headerName: "Last Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimation_amount",
      headerName: "Estimation Value",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "firm_farmer_name",
      headerName: "Firm/Farmer Name",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Estimation_generated_date",
      headerName: "Estimation Generated Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Estimation_request_date",
      headerName: "Estimation Request Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                margin: "10px",
              }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },

    {
      field: "CivilConstructedStarted",
      headerName: "Civil Construction Started",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedLength",
      headerName: "Shed Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "EquipementPlacementLength",
      headerName: "Equipment Placement Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedWidth",
      headerName: "Shed Width",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CShape",
      headerName: "C-Shape",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ModalType",
      headerName: "Model Type",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "SideWallColumnToColumnGap",
      headerName: "Side Wall Column to Column Gap",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "NumberOfSheds",
      headerName: "Number of Sheds",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CurtainRequirment",
      headerName: "Curtain Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "DiselBrooderRequirment",
      headerName: "Diesel Brooder Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "PowerSupply",
      headerName: "Power Supply",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "WaterSupply",
      headerName: "Water Available",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      headerAlign: "center",
      headerClassName: isMobile ? "" : "fixedColumn--headeraction",
      cellClassName: isMobile ? "" : "fixedColumn--cellaction",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
      renderCell: (params) => <IconCellGen {...params} />,
    },
  ];

  const [finalGenEstimationRow, setFinalGenEstimationRow] = useState([]);
  const handlingGeneratedEstimationRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      const isNewRequirement =
        rd.EstimationRequirement &&
        rd.EstimationRequirement.toLowerCase() === "new";

      if (isNewRequirement) {
        setNewDisabled(true); // Update state when condition is met
      }

      a = {
        id: index + 1,
        sno: index + 1,
        status: rd.status,
        estimation_id: rd.id,
        EstimationRequirement:
          rd.EstimationRequirement == null ? "-" : rd.EstimationRequirement,
        CivilConstructedStarted:
          rd.CivilConstructedStarted == true ? "Yes" : "No",
        ShedLength: rd.ShedLength == null ? "-" : rd.ShedLength,
        EquipementPlacementLength:
          rd.EquipementPlacementLength == null
            ? "-"
            : rd.EquipementPlacementLength,
        ShedWidth: rd.ShedWidth == true ? "Yes" : "No",
        CShape: rd.CShape == true ? "Yes" : "No",
        ModalType: rd.ModalType == null ? "-" : rd.ModalType,
        SideWallColumnToColumnGap:
          rd.SideWallColumnToColumnGap == null
            ? "-"
            : rd.SideWallColumnToColumnGap,
        NumberOfSheds: rd.NumberOfSheds,
        CurtainRequirment: rd.CurtainRequirment == true ? "Yes" : "No",
        DiselBrooderRequirment:
          rd.DiselBrooderRequirment == true ? "Yes" : "No",
        PowerSupply: rd.PowerSupply == true ? "Yes" : "No",
        WaterSupply: rd.WaterSupply == true ? "Yes" : "No",
        Remarks: rd.Remarks == null ? "-" : rd.Remarks,
        category: rd.category == null ? "-" : rd.category,
        sub_category: rd.sub_category == null ? "-" : rd.sub_category,
        follow_up_date:
          rd.follow_up_date == null ? "-" : formatDate(rd.follow_up_date),
        closure_month: rd.closure_month == null ? "-" : rd.closure_month,
        createdAt: rd.createdAt == null ? "-" : rd.createdAt,
        LeadDetailId: rd.LeadDetailId,
        ho_document: rd.ho_document,
        estimationNumber:
          rd.estimationNumber === null ? "-" : rd.estimationNumber,
        estimation_amount:
          rd.estimation_amount === null ? "-" : rd.estimation_amount,
        firm_farmer_name:
          rd.firm_farmer_name === null ? "-" : rd.firm_farmer_name,
        Estimation_generated_date:
          rd.Estimation_generated_date === null
            ? "-"
            : formatDate(rd.Estimation_generated_date),
        Estimation_request_date:
          rd.Estimation_request_date === null
            ? "-"
            : formatDate(rd.Estimation_request_date),
        Ho_executive_id: rd.Ho_executive_id,
        Is_downloadable: rd.Is_downloadable,
      };
      transformedRows.push(a);
    });
    setFinalGenEstimationRow(transformedRows);
  };

  const [showEditForm, setShowEditForm] = useState(false);

  const handleIconClick = () => {
    setShowEditForm(true);
  };

  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineLogs, setTimelineLogs] = useState([]);

  const handletimelineClick = () => {
    setTimelineOpen(true);

    axios
      .get(`${GET_TIMELINE}/${leadDetails.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadLogs } = response.data;
        setTimelineLogs(leadLogs);
      })
      .catch((error) => {});
  };

  const columnsSharedEstimation = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 30 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
    },
    {
      field: "EstimationRequirement",
      headerName: "Estimation Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Typography
              key={`index_${params.value}`}
              size="small"
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                fontWeight: "700",
                fontSize: "0.8rem",
              }}
            >
              {params.value}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{ textTransform: "capitalize", height: "20px" }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "estimationNumber",
      headerName: "Last Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimationNumber",
      headerName: "Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimation_amount",
      headerName: "Estimation Value",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "firm_farmer_name",
      headerName: "Firm/Farmer Name",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Estimation_generated_date",
      headerName: "Estimation Generated Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "createdAt",
      headerName: "Estimation Request Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                margin: "10px",
              }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },

    {
      field: "CivilConstructedStarted",
      headerName: "Civil Construction Started",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedLength",
      headerName: "Shed Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "EquipementPlacementLength",
      headerName: "Equipment Placement Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedWidth",
      headerName: "Shed Width",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CShape",
      headerName: "C-Shape",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ModalType",
      headerName: "Model Type",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "SideWallColumnToColumnGap",
      headerName: "Side Wall Column to Column Gap",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "NumberOfSheds",
      headerName: "Number of Sheds",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CurtainRequirment",
      headerName: "Curtain Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "DiselBrooderRequirment",
      headerName: "Diesel Brooder Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "PowerSupply",
      headerName: "Power Supply",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "WaterSupply",
      headerName: "Water Available",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      headerAlign: "center",
      headerClassName: isMobile ? "" : "fixedColumn--headeraction",
      cellClassName: isMobile ? "" : "fixedColumn--cellaction",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
      renderCell: (params) => <IconCell {...params} />,
    },
  ];

  const [finalSharedEstimationRow, setFinalSharedEstimationRow] = useState([]);
  const handlingSharedEstimationRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        sno: index + 1,
        status: rd.status,
        estimation_id: rd.id,
        EstimationRequirement:
          rd.EstimationRequirement == null ? "-" : rd.EstimationRequirement,
        CivilConstructedStarted:
          rd.CivilConstructedStarted == true ? "Yes" : "No",
        ShedLength: rd.ShedLength == null ? "-" : rd.ShedLength,
        EquipementPlacementLength:
          rd.EquipementPlacementLength == null
            ? "-"
            : rd.EquipementPlacementLength,
        ShedWidth: rd.ShedWidth == true ? "Yes" : "No",
        CShape: rd.CShape == true ? "Yes" : "No",
        ModalType: rd.ModalType == null ? "-" : rd.ModalType,
        SideWallColumnToColumnGap:
          rd.SideWallColumnToColumnGap == null
            ? "-"
            : rd.SideWallColumnToColumnGap,
        NumberOfSheds: rd.NumberOfSheds,
        CurtainRequirment: rd.CurtainRequirment == true ? "Yes" : "No",
        DiselBrooderRequirment:
          rd.DiselBrooderRequirment == true ? "Yes" : "No",
        PowerSupply: rd.PowerSupply == true ? "Yes" : "No",
        WaterSupply: rd.WaterSupply == true ? "Yes" : "No",
        Remarks: rd.Remarks == null ? "-" : rd.Remarks,
        category: rd.category == null ? "-" : rd.category,
        sub_category: rd.sub_category == null ? "-" : rd.sub_category,
        follow_up_date:
          rd.follow_up_date == null ? "-" : formatDate(rd.follow_up_date),
        closure_month: rd.closure_month == null ? "-" : rd.closure_month,
        createdAt: rd.createdAt == null ? "-" : formatDate(rd.createdAt),
        LeadDetailId: rd.LeadDetailId,
        ho_document: rd.ho_document,
        estimationNumber:
          rd.estimationNumber === null ? "-" : rd.estimationNumber,
        estimation_amount:
          rd.estimation_amount === null ? "-" : rd.estimation_amount,
        firm_farmer_name:
          rd.firm_farmer_name === null ? "-" : rd.firm_farmer_name,
        Estimation_generated_date:
          rd.Estimation_generated_date === null
            ? "-"
            : formatDate(rd.Estimation_generated_date),
        Estimation_request_date:
          rd.Estimation_request_date === null
            ? "-"
            : formatDate(rd.Estimation_request_date),
        Ho_executive_id: rd.Ho_executive_id,
        Is_downloadable: rd.Is_downloadable,
      };
      transformedRows.push(a);
    });
    setFinalSharedEstimationRow(transformedRows);
  };
  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const columnsConvertedEstimation = [
    {
      field: "sno",
      headerName: "SNo",
      width: isMobile ? 30 : 50,
      type: "string",
      headerAlign: "center",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
    },
    {
      field: "EstimationRequirement",
      headerName: "Estimation Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Typography
              key={`index_${params.value}`}
              size="small"
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                fontWeight: "700",
                fontSize: "0.8rem",
              }}
            >
              {params.value}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{ textTransform: "capitalize", height: "20px" }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "estimationNumber",
      headerName: "Last Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimationNumber",
      headerName: "Estimation Number",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "estimation_amount",
      headerName: "Estimation Value",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "firm_farmer_name",
      headerName: "Firm/Farmer Name",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Estimation_generated_date",
      headerName: "Estimation Generated Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "createdAt",
      headerName: "Estimation Request Date & Time",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "closure_month",
      headerName: "Closure Month",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <>
          {params.value === "-" ? (
            <p>-</p>
          ) : (
            <Chip
              key={`index_${params.value}`}
              size="small"
              color={`${getColorForTag(params.value)}`}
              label={params.value}
              sx={{
                textTransform: "capitalize",
                height: "20px",
                margin: "10px",
              }}
            ></Chip>
          )}
        </>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },

    {
      field: "CivilConstructedStarted",
      headerName: "Civil Construction Started",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedLength",
      headerName: "Shed Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "EquipementPlacementLength",
      headerName: "Equipment Placement Length",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ShedWidth",
      headerName: "Shed Width",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CShape",
      headerName: "C-Shape",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "ModalType",
      headerName: "Model Type",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "SideWallColumnToColumnGap",
      headerName: "Side Wall Column to Column Gap",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "NumberOfSheds",
      headerName: "Number of Sheds",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "CurtainRequirment",
      headerName: "Curtain Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "DiselBrooderRequirment",
      headerName: "Diesel Brooder Requirement",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "PowerSupply",
      headerName: "Power Supply",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "WaterSupply",
      headerName: "Water Available",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: isMobile ? 180 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      headerAlign: "center",
      headerClassName: isMobile ? "" : "fixedColumn--headeraction",
      cellClassName: isMobile ? "" : "fixedColumn--cellaction",
      responsive: true,
      sortable: false,
      disableColumnFilter: true,
      renderCell: (params) => <IconConvertCell {...params} />,
    },
  ];

  const IconConvertCell = ({ row }) => (
    <>
      <Tooltip title="View" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
            backgroundColor: "#097969",
          }}
          color="primary"
          onClick={() => handleOnConvertedViewClick(row)}
        >
          <VisibilityIcon
            sx={{ fontSize: "1rem", color: "#fff" }}
          ></VisibilityIcon>
        </IconButton>
      </Tooltip>

      <Tooltip title="Download All" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
          }}
          color="primary"
          onClick={() => handleDownloadClick(row)}
        >
          <CloudDownloadIcon
            sx={{ fontSize: "1rem", color: "#fff" }}
          ></CloudDownloadIcon>
        </IconButton>
      </Tooltip>
    </>
  );

  const [finalConvertedEstimationRow, setFinalConveretedEstimationRow] =
    useState([]);
  const handlingConvertedEstimationRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        sno: index + 1,
        status: rd.status,
        estimation_id: rd.id,
        EstimationRequirement:
          rd.EstimationRequirement == null ? "-" : rd.EstimationRequirement,
        CivilConstructedStarted:
          rd.CivilConstructedStarted == true ? "Yes" : "No",
        ShedLength: rd.ShedLength == null ? "-" : rd.ShedLength,
        EquipementPlacementLength:
          rd.EquipementPlacementLength == null
            ? "-"
            : rd.EquipementPlacementLength,
        ShedWidth: rd.ShedWidth == true ? "Yes" : "No",
        CShape: rd.CShape == true ? "Yes" : "No",
        ModalType: rd.ModalType == null ? "-" : rd.ModalType,
        SideWallColumnToColumnGap:
          rd.SideWallColumnToColumnGap == null
            ? "-"
            : rd.SideWallColumnToColumnGap,
        NumberOfSheds: rd.NumberOfSheds,
        CurtainRequirment: rd.CurtainRequirment == true ? "Yes" : "No",
        DiselBrooderRequirment:
          rd.DiselBrooderRequirment == true ? "Yes" : "No",
        PowerSupply: rd.PowerSupply == true ? "Yes" : "No",
        WaterSupply: rd.WaterSupply == true ? "Yes" : "No",
        Remarks: rd.Remarks == null ? "-" : rd.Remarks,
        category: rd.category == null ? "-" : rd.category,
        sub_category: rd.sub_category == null ? "-" : rd.sub_category,
        follow_up_date:
          rd.follow_up_date == null ? "-" : formatDate(rd.follow_up_date),
        closure_month: rd.closure_month == null ? "-" : rd.closure_month,
        createdAt: rd.createdAt == null ? "-" : formatDate(rd.createdAt),
        LeadDetailId: rd.LeadDetailId,
        ho_document: rd.ho_document,
        estimationNumber:
          rd.estimationNumber === null ? "-" : rd.estimationNumber,
        estimation_amount:
          rd.estimation_amount === null ? "-" : rd.estimation_amount,
        firm_farmer_name:
          rd.firm_farmer_name === null ? "-" : rd.firm_farmer_name,
        Estimation_generated_date:
          rd.Estimation_generated_date === null
            ? "-"
            : formatDate(rd.Estimation_generated_date),
        Estimation_request_date:
          rd.Estimation_request_date === null
            ? "-"
            : formatDate(rd.Estimation_request_date),
        Ho_executive_id: rd.Ho_executive_id,
        Is_downloadable: rd.Is_downloadable,
      };
      transformedRows.push(a);
    });
    setFinalConveretedEstimationRow(transformedRows);
  };
  console.log(leadDetails.category);
  return (
    <>
      {loading && <Loader open={loading} />}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Lead Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <Stack direction={isMobile ? "column" : "row"}>
              {isMobile && (
                <div>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className={`float-right`}
                  >
                    Action
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {PAGE_SECTIONS.map((eq) => (
                      <MenuItem
                        onClick={handleClickList}
                        value={eq.value}
                        disableRipple
                      >
                        {eq.label}
                      </MenuItem>
                    ))}
                  </StyledMenu>
                </div>
              )}

              <Grid container spacing={2} p={3}>
                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Customer Name:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.CustomerName}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Mobile Number:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.MobileNo}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Alternate Mobile Number:
                  </Typography>

                  <Typography variant="body2">
                    {storedLeadDetails.AlternateMobileNo}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Whatsapp Number:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.WhatsappNo}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={"flex"}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Customer Mail ID:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.CustomerMailId}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Postal Pincode:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.pincode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    State:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.state_name}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Region Name:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.region_name}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    City/Village/Location:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.location}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Site Location Address:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.site_location_address}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Follow-up Date:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.follow_up_date === "-" ||
                    leadDetails.follow_up_date === null
                      ? "-"
                      : leadDetails.follow_up_date}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Lead Transfer Date:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.lead_transfer_date === "-" ||
                    leadDetails.lead_transfer_date === null
                      ? "-"
                      : leadDetails.lead_transfer_date}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Campaign:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.source_of_lead_generated}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Lead Shared By:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.lead_owner}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Agent Remarks:
                  </Typography>
                  <Typography variant="body2">
                    <LongTextComponent
                      text={
                        storedLeadDetails.agent_remark == null
                          ? "-"
                          : storedLeadDetails.agent_remark
                      }
                      maxLength={50}
                    />
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Last Remarks:
                  </Typography>
                  <Typography variant="body2">
                    <LongTextComponent
                      text={
                        storedLeadDetails.bdm_remark == null
                          ? "-"
                          : storedLeadDetails.bdm_remark
                      }
                      maxLength={50}
                    />
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} xl={6} className={`flex`}>
                  <Typography
                    variant="body2"
                    className={`${leadFormStyles.label_margin}`}
                  >
                    Last Action:
                  </Typography>
                  <Typography variant="body2">
                    {storedLeadDetails.last_action}
                  </Typography>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  className={`float-right`}
                >
                  <SettingsIcon sx={{fontSize: '1rem', paddingRight: '2px'}} ></SettingsIcon>Action
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {PAGE_SECTIONS.map((eq) => (
                    <MenuItem
                      onClick={handleClickList}
                      value={eq.value}
                      disableRipple
                    >
                      {eq.label}
                    </MenuItem>
                  ))}
                </StyledMenu>

                <Button
                  style={{
                    marginTop: "5px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => handletimelineClick()}
                >
                  <VisibilityIcon  color="inherit" sx={{fontSize: '1rem', paddingRight: '2px'}} /> Timeline
                </Button>
              </div>
            </Stack>

            {showEditForm && <EditMasterDetails></EditMasterDetails>}
          </Box>
        </AccordionDetails>
      </Accordion>

      {finalOnCallRow.length == 0 ? (
        <></>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${leadFormStyles.accordian_heading} h-auto`}
          >
            <Typography>Remarks List</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
            >
              <DataGrid
                rows={finalOnCallRow}
                columns={columnsOnCall}
                columnHeaderHeight={50}
                rowHeight={40}
                autoHeight={finalOnCallRow.length < 10}
                disableExtendRowFullWidth
                // hideFooterPagination={theme.breakpoints.down('md')}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                disableRowSelectionOnClick
                disableDensitySelector
                sx={{
                  height: "400px",
                  "& .MuiDataGrid-cell": {
                    textAlign: isMobile ? "left" : "center",
                    placeContent: "center",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#EEF0F4",
                  },
                  "& .super-app-theme--header": {
                    // width: isMobile == true ? '200px !important' : '150px !important',
                    // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                  },
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion defaultExpanded id="onCall_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>On-Call Discussion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isMobile ? (
            <OnCallFormComponent
              roleType={"BDM"}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenSnackbar={setOpenSnackbar}
              leadDetails={leadDetails}
              setRunEffect={setRunEffect}
            ></OnCallFormComponent>
          ) : (
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={`${bdmFormStyles.table_container}`}>
                <table className={`${leadFormStyles.table}`}>
                  <thead>
                    <tr>
                      <th style={{ fontSize: "0.8rem" }}>Category</th>
                      <th style={{ fontSize: "0.8rem" }}>Sub-Category</th>
                      <th style={{ fontSize: "0.8rem" }}>Follow-up Date</th>
                      <th style={{ fontSize: "0.8rem" }}>
                        Expected Closure Month
                      </th>
                      <th style={{ fontSize: "0.8rem" }}>Remarks</th>
                      <th style={{ fontSize: "0.8rem" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label={"Category"}
                          value={category}
                          onChange={handleCategoryChange}
                          // helperText="Please select your currency"
                          size="small"
                          required
                          error={!!categoryError}
                          helperText={categoryError}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontSize: "0.65rem",
                              marginLeft: "0",
                            },
                          }}
                        >
                          {CATEGORY.map((c) => (
                            <MenuItem
                              value={c.value}
                              sx={{
                                borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                                marginBottom: "8px",
                                marginTop: "8px",
                              }}
                            >
                              {c.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </td>
                      <td>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Sub-Category"
                          value={subCategory}
                          onChange={handleSubCategoryChange}
                          size="small"
                          required
                          error={!!subCategoryError}
                          helperText={subCategoryError}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              fontSize: "0.65rem",
                              marginLeft: "0",
                            },
                          }}
                        >
                          {category.toLowerCase() == "hot" &&
                            HOT_SUB_CATEGORY.map((h) => (
                              <MenuItem
                                value={h.label}
                                sx={{
                                  borderBottom:
                                    "thin solid rgba(0, 0, 0, 0.12)",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {h.label}
                              </MenuItem>
                            ))}
                          {category.toLowerCase() == "warm" &&
                            WARM_SUB_CATEGORY.map((h) => (
                              <MenuItem
                                value={h.label}
                                sx={{
                                  borderBottom:
                                    "thin solid rgba(0, 0, 0, 0.12)",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {h.label}
                              </MenuItem>
                            ))}
                          {category.toLowerCase() == "cold" &&
                            COLD_SUB_CATEGORY.map((h) => (
                              <MenuItem
                                value={h.label}
                                sx={{
                                  borderBottom:
                                    "thin solid rgba(0, 0, 0, 0.12)",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {h.label}
                              </MenuItem>
                            ))}
                          {category.toLowerCase() == "pending" &&
                            PENDING_SUB_CATEGORY.map((h) => (
                              <MenuItem
                                value={h.label}
                                sx={{
                                  borderBottom:
                                    "thin solid rgba(0, 0, 0, 0.12)",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {h.label}
                              </MenuItem>
                            ))}
                          {category.toLowerCase() == "closed" &&
                            CLOSED_SUB_CATEGORY.map((h) => (
                              <MenuItem
                                value={h.label}
                                sx={{
                                  borderBottom:
                                    "thin solid rgba(0, 0, 0, 0.12)",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {h.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </td>

                      <td>
                        {(enableField || showField) && (
                          <DateTimePicker
                            label={
                              <span
                                className={`${bdmFormStyles.custom_required_star}`}
                              >
                                Follow-up Date
                              </span>
                            }
                            name="startDateTime"
                            onChange={handleFollowupDateTime}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error,
                              },
                            }}
                            disablePast
                          />
                        )}
                      </td>
                      <td>
                        {enableField && (
                          <DatePicker
                            label={"Closure Month"}
                            views={["month", "year"]}
                            onChange={handleChangeExpectedClosureMonth}
                            disablePast
                          />
                        )}
                      </td>
                      {/* <td>
            <DatePicker 
            label="Lead Transfer Date" 
            onChange={(e)=>{{
                const date = new Date(e.$d);
                const year = date.getFullYear();
                const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so we add 1
                const day = ("0" + date.getDate()).slice(-2);
                const formattedDate = `${year}-${month}-${day}`;
                setLeadTransferDate(formattedDate)}}}/>
            </td> */}
                      {/* <td>
            {enableField && <TextField
            label="Lead Owner"
            select
            id="outlined-size-small"
            value={leadOwner}
            onChange={(e)=>{
                setLeadOwner(e.target.value);
                BDM_LIST.map((item)=>{
                    if (item.label == e.target.value){
                        setBdmId(item.value);
                    }
                })
                
            }}
            size="small"
            >
            {BDM_LIST.map((ls) => (
                <MenuItem value={ls.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{ls.label}</MenuItem>
            ))}
            </TextField>}
            </td> */}
                      {/* <td>
            {enableField && <TextField
            label="Lead Source"
            select
            id="outlined-size-small"
            value={leadSource}
            onChange={(e)=>setLeadSource(e.target.value)}
            size="small"
            error={!!leadSourceError}
            helperText={leadSourceError}
                sx={{
                '& .MuiFormHelperText-root':{
                    fontSize: '0.65rem',
                    marginLeft: '0'
                }
            }}
            > 
            {empDetails.Campaigns.map((ls) => (
                <MenuItem value={ls.CampaignId} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{ls.CampaignName}</MenuItem>
            ))}
            </TextField>}
            </td> */}
                      <td>
                        <div className={`${leadFormStyles.fields_padding}`}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Remarks"
                            multiline
                            rows={4}
                            fullWidth
                            value={bdmRemark}
                            onChange={(e) => {
                              if (e.target.value.length <= 250) {
                                setBdmRemark(e.target.value);
                              }
                            }}
                            inputProps={{
                              inputProps: {
                                maxLength: "250",
                              },
                            }}
                            style={{ resize: "both", overflow: "auto" }}
                            required
                            error={!!bdmRemarkError}
                            helperText={bdmRemarkError}
                            sx={{
                              "& .MuiFormHelperText-root": {
                                fontSize: "0.65rem",
                                marginLeft: "0",
                              },
                            }}
                          />
                          <Typography
                            variant="body2"
                            color={
                              bdmRemark.length > 250
                                ? "error"
                                : "text.secondary"
                            }
                            sx={{ marginLeft: "7px" }}
                          >
                            {bdmRemark.length}/250
                          </Typography>
                        </div>
                      </td>
                      <td>
                        <Button
                          type="submit"
                          onClick={handleOnCallFromSubmit}
                          variant="contained"
                          size="small"
                        >
                          <SaveIcon sx={{fontSize: '1rem', paddingRight: '2px'}}></SaveIcon>
                          Save
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded id="meeting_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Meeting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <Button
                variant="contained"
                className={`float-right ${bdmFormStyles.button_space}`}
                onClick={handleNewMeetingClick}
                size="small"
              >
                <AddIcon sx={{fontSize: '1rem'}}></AddIcon>&nbsp;
                Meeting
              </Button>
            </div>

            <DataGrid
              rows={finalMeetingRow}
              columns={columnsMeeting}
              columnHeaderHeight={50}
              rowHeight={40}
              autoHeight
              disableExtendRowFullWidth
              // hideFooterPagination={theme.breakpoints.down('md')}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "0.8rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#EEF0F4",
                  fontSize: "0.8rem",
                },
                "& .super-app-theme--header": {
                  // width: isMobile == true ? '200px !important' : '150px !important',
                  // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },
              }}
            />
          </Box>

          {showMeetingForm && (
            <div className={`mt-2 p-2`} id="meeting-form">
              <MeetingFormComponent
                setShowMeetingForm={setShowMeetingForm}
                leadDetailsId={leadDetails.leadId}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpenSnackbar={setOpenSnackbar}
                setLoading={setLoading}
                setRunEffect={setRunEffect}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded id="siteVisit_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Site Visit</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <Button
                variant="contained"
                className={`float-right ${bdmFormStyles.button_space}`}
                onClick={handleNewSiteVisitClick}
                size="small"
              >
                <AddIcon sx={{fontSize: '1rem'}}></AddIcon>&nbsp;
                Site Visit
              </Button>
            </div>
            <DataGrid
              rows={finalSiteVisitRow}
              columns={columnsSiteVisit}
              columnHeaderHeight={50}
              rowHeight={40}
              autoHeight
              disableExtendRowFullWidth
              // hideFooterPagination={theme.breakpoints.down('md')}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "0.8rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#EEF0F4",
                  fontSize: "0.8rem",
                },
                "& .super-app-theme--header": {
                  // width: isMobile == true ? '200px !important' : '150px !important',
                  // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },
              }}
            />
          </Box>

          {showSiteVisitForm && (
            <div className={`mt-2 p-2`}>
              <SiteVisitFormComponent
                setSiteVisitForm={setSiteVisitForm}
                leadDetailsId={leadDetails.leadId}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpenSnackbar={setOpenSnackbar}
                setRunEffect={setRunEffect}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded id="estimate_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>P-Card Estimation Request</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <Button
                variant="contained"
                className={`float-right ${bdmFormStyles.button_space}`}
                onClick={handleNewEStimationClick}
                size="small"
              >
                <AddIcon sx={{fontSize: '1rem'}}></AddIcon>&nbsp;
                Estimation
              </Button>
            </div>
            <DataGrid
              rows={finalEstimationRow}
              columns={columnsEstimation}
              columnHeaderHeight={50}
              getRowHeight={() => "auto"}
              autoHeight
              disableExtendRowFullWidth
              // hideFooterPagination={theme.breakpoints.down('md')}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "0.8rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#EEF0F4",
                  fontSize: "0.8rem",
                },
                "& .super-app-theme--header": {
                  // width: isMobile == true ? '200px !important' : '150px !important',
                  // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },
                "& .fixedColumn--headeraction": {
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#EEF0F4", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
                "& .fixedColumn--cellaction": {
                  // minWidth: 50,
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#fff", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
              }}
            />
          </Box>

          {showEstimationForm && (
            <div className={`mt-2 p-2`}>
              <EstimationFormComponent
                setEstimationForm={setEstimationForm}
                finalEstimationRow={finalEstimationRow}
                finalGenEstimationRow={finalGenEstimationRow}
                finalSharedEstimationRow={finalSharedEstimationRow}
                finalConvertedEstimationRow={finalConvertedEstimationRow}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setOpenSnackbar={setOpenSnackbar}
                setRunEffect={setRunEffect}
                leadDetailsId={leadDetails.leadId}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded id="estimate_generated_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>P-Card Estimation Generated</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DataGrid
              rows={finalGenEstimationRow}
              columns={columnsGenEstimation}
              columnHeaderHeight={50}
              getRowHeight={() => "auto"}
              autoHeight
              disableExtendRowFullWidth
              // hideFooterPagination={theme.breakpoints.down('md')}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "0.8rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#EEF0F4",
                  fontSize: "0.8rem",
                },
                "& .super-app-theme--header": {
                  // width: isMobile == true ? '200px !important' : '150px !important',
                  // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },
                "& .fixedColumn--headeraction": {
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#EEF0F4", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
                "& .fixedColumn--cellaction": {
                  // minWidth: 50,
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#fff", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded id="estimate_shared_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>P-Card Estimation Shared</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DataGrid
              rows={finalSharedEstimationRow}
              columns={columnsSharedEstimation}
              columnHeaderHeight={50}
              getRowHeight={() => "auto"}
              autoHeight
              disableExtendRowFullWidth
              // hideFooterPagination={theme.breakpoints.down('md')}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "0.8rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#EEF0F4",
                  fontSize: "0.8rem",
                },
                "& .super-app-theme--header": {
                  // width: isMobile == true ? '200px !important' : '150px !important',
                  // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },
                "& .fixedColumn--headeraction": {
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#EEF0F4", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
                "& .fixedColumn--cellaction": {
                  // minWidth: 50,
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#fff", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded id="converted_element">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Converted</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DataGrid
              rows={finalConvertedEstimationRow}
              columns={columnsConvertedEstimation}
              columnHeaderHeight={50}
              getRowHeight={() => "auto"}
              autoHeight
              disableExtendRowFullWidth
              // hideFooterPagination={theme.breakpoints.down('md')}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "0.8rem",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#EEF0F4",
                  fontSize: "0.8rem",
                },
                "& .super-app-theme--header": {
                  // width: isMobile == true ? '200px !important' : '150px !important',
                  // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },
                "& .fixedColumn--headeraction": {
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#EEF0F4", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
                "& .fixedColumn--cellaction": {
                  // minWidth: 50,
                  // maxWidth: 50,
                  flex: "0 0 auto",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  backgroundColor: "#fff", // Background color for the fixed column
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
                },
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Fab
        className={`${leadFormStyles.fab_position} bottom-2 right-2`}
        color="primary"
        onClick={scrollToTop}
      >
        <ArrowUpwardIcon />
      </Fab>

      <TimelineDialog
        open={timelineOpen}
        setOpen={setTimelineOpen}
        timelineLogs={timelineLogs}
        custData={leadDetails}
      ></TimelineDialog>
    </>
  );
}

export default BDMDetailsPage;
