import React, {useState, useEffect, useRef, useMemo, useContext} from 'react';
import axios from 'axios';
import { AUDIT_STATUS_UPDATE, BULK_UPLOAD, BULK_UPLOAD_AUDIT, CATEGORY, DOWNLOAD_EXCEL, GET_ALL_LEADS, GET_ESTIMATIONS_SV, GET_MEETING_SV, GET_ON_CALL_DISCUSSION, GET_ON_CALL_DISCUSSION_SV, GET_SITE_VISIT_SV, GET_TIMELINE, REGION_WISE_AUDIT_FILTER, REGIONS } from '../../constants';

import { Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Menu, MenuItem, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import {Stack, Snackbar} from '@mui/material';
import ModalComponent from '../reusable Component/modalComponent';
import * as XLSX from 'xlsx';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import LongTextComponent from '../Leads Page/LongTextComponent';
import Loader from '../reusable Component/Loader';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { saveAs } from 'file-saver';
import { LoginContext } from '../LoginPage/LoginContext';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import auditFormStyles from '../Audit/auditFromStyles.module.css';
import FileUploadIcon from '@mui/icons-material/FileUpload';


function AuditMasterList({}) {

    const { setleadDetails } = useContext(LoginContext);

    const fileInputRef = useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [rowAll, setRowAll] = useState([]);
    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const [runEffect,setRunEffect] = useState(false);
    
    
    useEffect(()=>{
        setLoading(true);
        
        fetchAuditLeadList();
    },[runEffect]);

  
    const [auditStatus, setAuditStatus] = useState('open'); // Default status
    const [regionName, setRegionName] = useState('ANDHRA PRADESH-I'); // Default region

    const fetchAuditLeadList = (region = regionName, status = auditStatus) => {
      setLoading(true); // Set loading to true before fetching
      const page = 1;
      const limit = 1000000;
      
      const sortBy = 'updatedAt';
      const sortOrder = 'DESC';

      axios.get(`${REGION_WISE_AUDIT_FILTER}?page=${page}&limit=${limit}&region=${region}&status=${status}&sortBy=${sortBy}&sortOrder=${sortOrder}`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
      })
      .then(response => {
          const { data } = response.data;
          handleAllRowData(data); // Assume this function handles the response data
          console.log(data);
      })
      .catch(error => {
          console.error('Error fetching audit leads:', error);
          // Handle error (e.g., show a message to the user)
      })
      .finally(() => {
          setLoading(false); // Set loading to false after fetch
      });
  };

      const handleAllRowData = (data) =>{
        
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
            a = {
            "id": index + 1 ,
            "SNo": index + 1,
            "Zone_Name": rd.Zone_Name || '-',
            "Region_Description": rd.Region_Description || '-',
            "Branch": rd.Branch || '-',
            "Branch_Name": rd.Branch_Name || '-',
            "Farmer_Name": rd.Farmer_Name || '-',
            "Lot_Number": rd.Lot_Number || '-',
            "Vendor": rd.Vendor || '-',
            "Shed_Type": rd.Shed_Type || '-',
            "Hatch_Date": rd.Hatch_Date || '-',
            "Mean_Age": rd.Mean_Age || '-',
            "Placed_Qty": rd.Placed_Qty || '-',
            "Lifted_Birds": rd.Lifted_Birds || '-',
            "Bal_Birds": rd.Bal_Birds || '-',
            "Total_Mortality": rd.Total_Mortality || '-',
            "Total_Mortality_Percentage": rd.Total_Mortality_Percentage || '-',
            "FCR": rd.FCR || '-',
            "LR": rd.LR || '-',
            "CA": rd.CA || '-',
            "Age_SAP": rd.Age_SAP || '-',
            "Diff": rd.Diff || '-',
            "first_Week_M": rd.first_Week_M || '-',
            "First_Week_Mortality_Percentage": rd.First_Week_Mortality_Percentage || '-',
            "Lifting_EA": rd.Lifting_EA || '-',
            "Lift_Percentage": rd.Lift_Percentage || '-',
            "ABWT": rd.ABWT || '-',
            "BWT_Age": rd.BWT_Age || '-',
            "Feed_Cons": rd.Feed_Cons || '-',
            "Avg_Lift_Wt": rd.Avg_Lift_Wt || '-',
            "Prev_Grade": rd.Prev_Grade || '-',
            "Mobile": rd.Mobile || '-',
            "Line": rd.Line || '-',
            "Hatchery_Name": rd.Hatchery_Name || '-',
            "createdAt": rd.createdAt || '-',
            "updatedAt": formatDate(rd.updatedAt) || '-',
            "status": rd.status || '-',
            "last_action_date": rd.last_action_date === null ? '-' : formatDate(rd.last_action_date)
        }
        transformedRows.push(a);
        })
        setRowAll(transformedRows);
    };

    const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');


        const columns = useMemo(
            () => [
            //   {
            //     accessorKey: "SNo", //simple recommended way to define a column
            //     header: "S No",
            //     // muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
            //     // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            //   },
            {
              accessorKey: "SNo", // Unique ID for the column
            header: "SNo",
            size: 50,
            enableSorting: false, // Disable sorting
            enableColumnFilter: false, // Disable filtering
            enableHiding: false, // Disable hiding
            muiTableBodyCellProps: {
                sx: {
                  position: 'sticky',
                  left: 0, // Stick to the left
                  zIndex: 10, // Ensure it stays above other columns
                  backgroundColor: 'white', // Keep the background consistent
                  borderRight: '1px solid rgba(224, 224, 224, 1)', // Optional: Add a border for separation
                  textAlign: 'center',
                },
              },
              muiTableHeadCellProps: {
                sx: {
                  position: 'sticky',
                  left: 0, // Stick to the left in the header as well
                  zIndex: 11, // Ensure the header stays above the body cells
                  backgroundColor: '#EEF0F4', // Match the header background color
                },
              },
            },
            {
            // accessorFn: (row) => row.age, //alternate way
            // id: "age", //id required if you use accessorFn instead of accessorKey
            accessorKey: "last_action_date",
            header: "Last Action Date",
            size: 150,
            // Header: <i style={{ color: "red" }}>Age</i> //optional custom markup
            muiTableBodyCellProps: {
                sx: {
                  position: 'sticky',
                  left: 80, // Stick to the left
                  zIndex: 10, // Ensure it stays above other columns
                  backgroundColor: 'white', // Keep the background consistent
                  borderRight: '1px solid rgba(224, 224, 224, 1)', // Optional: Add a border for separation
                  textAlign: 'center',
                },
              },
              muiTableHeadCellProps: {
                sx: {
                  position: 'sticky',
                  left: 80, // Stick to the left in the header as well
                  zIndex: 11, // Ensure the header stays above the body cells
                  backgroundColor: '#EEF0F4', // Match the header background color
                },
              },
            },
            {
                // accessorFn: (row) => row.age, //alternate way
                // id: "age", //id required if you use accessorFn instead of accessorKey
                accessorKey: "Zone_Name",
                header: "Zone Name",
                size: 150,
                // Header: <i style={{ color: "red" }}>Age</i> //optional custom markup
            },
            {
                accessorKey: "Lot_Number",
                header: "Lot Number",
                size: 200,
            },
            {
                accessorKey: "Farmer_Name",
                header: "Farmer Name",
                size: 150,
            },
            {
                accessorKey: "Branch_Name",
                header: "Branch Name",
                size: 180,
            },
            {
                accessorKey: "Vendor",
                header: "Vendor",
                size: 150,
            },
            {
                accessorKey: "Shed_Type",
                header: "Shed Type",
                size: 150,
            },
            {
                accessorKey: "Placed_Qty",
                header: "Placed Quantity",
                size: 250,
            },
            {
                accessorKey: "CA",
                header: "CA",
            },
            {
                accessorKey: "Age_SAP",
                header: "Age (SAP)",
                size: 200,
            },
            {
                accessorKey: "Diff",
                header: "Diff",
                size: 200,
            },
            {
                accessorKey: "first_Week_M",
                header: "First Week Mortality",
                size: 240,
            },
            {
                accessorKey: "First_Week_Mortality_Percentage",
                header: "First Week Mortality Percentage",
                size: 300,
            },
            {
                accessorKey: "Total_Mortality",
                header: "Mortality Quantity",
                size: 150,
            },
            {
                accessorKey: "Total_Mortality_Percentage",
                header: "Mortality Percentage",
                size: 250,
            },
            {
                accessorKey: "Lifting_EA",
                header: "Lifting EA",
                size: 250,
            },
            {
                accessorKey: "Lift_Percentage",
                header: "Lift Percentage",
                size: 180,
            },
            {
                accessorKey: "Bal_Birds",
                header: "Balance Birds",
                size: 180,
            },
            {
                accessorKey: "FCR",
                header: "FCR",
                size: 180,
            },
            {
                accessorKey: "ABWT",
                header: "Avg Body Weight",
                size: 180,
            },
            {
                accessorKey: "BWT_Age",
                header: "BWT Age",
                size: 200,
            },
            {
                accessorKey: "Feed_Cons",
                header: "Feed Cons",
                size: 150,
            },
            {
                accessorKey: "Avg_Lift_Wt",
                header: "Lifted Avg Weight",
                size: 200,
            },
            {
                accessorKey: "Prev_Grade",
                header: "Prev Grade",
                size: 150,
            },
            {
                accessorKey: "Mobile",
                header: "Mobile Number",
                size: 150,
            },
            {
                accessorKey: "Hatch_Date",
                header: "Hatch Date",
                size: 180,
            },
            {
                accessorKey: "Line",
                header: "Line",
                size: 180,
            },
            {
                accessorKey: "Hatchery_Name",
                header: "Hatchery Name",
                size: 180,
            },
            {
                accessorKey: "updatedAt",
                header: "Last Modified On",
                size: 180,
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 80,
                Cell: ({ cell }) => {
                const rowData = cell.row.original; // Get the entire row data
            
                return (
                    <>
                    {cell.getValue() === '-' ? (
                        <p>-</p>
                    ) : (
                        <Chip
                        key={`index_${cell.getValue()}`}
                        size="small"
                        label={cell.getValue()}
                        color={getColorForTag(cell.getValue())}
                        sx={{ textTransform: 'capitalize', height: '20px' }}
                        />
                    )}
                    </>
                );
                },

                muiTableBodyCellProps: {
                    sx: {
                    position: 'sticky',
                    right: 0, // Stick to the right
                    zIndex: 10, // Ensure it has a higher z-index than other columns
                    backgroundColor: 'white', // Consistent background
                    borderLeft: '1px solid rgba(224, 224, 224, 1)', // Add border if needed
                    textAlign: 'center'
                    },
                },
                muiTableHeadCellProps: {
                    sx: {
                    position: 'sticky',
                    right: 0, // Make the header stick as well
                    zIndex: 11, // Ensure it stays on top of body cells
                    backgroundColor: '#EEF0F4', // Match table header background
                    
                    },
                },
            },
            
            ],
            []
        );

   

   const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

   // Custom export to Excel function
   const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowAll);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Audit Data");
    XLSX.writeFile(workbook, "Audit_Leads.xlsx");
    handleMenuClose();
};

      // Initialize the table with corrected data and other configurations
    const table = useMaterialReactTable({
        data: rowAll, // Corrected from 'row' to 'data'
        columns,
        enableStickyHeader: true,
        defaultColumn: {
          minSize: 100, // Adjust as needed
          maxSize: 300, // Adjust as needed
          size: 150,     // Adjust as needed or remove
        },
        getRowId: (row) => row.id, // Ensure each row has a unique ID
  
        muiTableHeadCellProps: {
          sx: {
            // textAlign: 'center',
            backgroundColor: '#EEF0F4',
            // Add box-sizing if necessary
            boxSizing: 'border-box',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            // padding: '8px',
            // textAlign: 'center',
            boxSizing: 'border-box',
          },
        },
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({ table }) => (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Tooltip title="Export Options">
              <Button
                variant="text"
                onClick={handleMenuClick}
                startIcon={<FileDownloadIcon />}
              >
                Export
              </Button>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => exportToExcel(table.getRowModel().rows)}>
                Export to Excel
              </MenuItem>
              {/* <MenuItem onClick={handleExportData}>Export to CSV</MenuItem> */}
              {/* Add more menu items for additional export options if needed */}
            </Menu>
  
            {/* <Button
            variant="text"
            color="primary"
            startIcon={<UploadIcon />}
            onClick={handleButtonClick}
            sx={{
              float: 'right',
              marginRight: '16px',
              fontSize: '14px',
            }}
          >
            Bulk Upload
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          /> */}
          </Box>
        ),
      });


      const getColorForTag = (tag) => {
        
        switch (tag.toLowerCase()) {
          case 'open':
            return 'error';
          case 'closed':
            return 'success';
          default:
            return 'default';
        }
      };
  
      const [statusLotNumber, setStatusLotNumber] = useState('');
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


  const [open, setOpen] = useState(false);
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [file, setFile] = useState(null);

      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          setOpen(true);
          setFile(file);
          // uploadFile(file);
      }
      
    };

    const handleUpload = () => {
      setUploading(true);
      setProgress(0);
      
      // Simulate file upload
      const uploadInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(uploadInterval);
            uploadFile(file);
            // setTimeout(() => setOpen(false), 1000);
            return 100;
          }
          return prevProgress + 10;
        });
      }, 500);
    };

    const uploadFile = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      axios.post(BULK_UPLOAD_AUDIT, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
      })
      .then(response => {
          console.log('File uploaded successfully:', response.data);
          setSeverity('success');
          setAlertTitle(response.data.message);
          setMessage('Updated Count:' + response.data.updatedCount);
          setOpenSnackbar(true);
          
          setUploading(false);
          setOpen(false);

          fileInputRef.current.value = null;
          setRunEffect(true);
          // navigate(0);
          
      })
      .catch(error => {
          console.error('Error uploading file:', error);
          // setRunEffect(false);
          setSeverity('error');
          setMessage(error.response.data.message);
          setOpenSnackbar(true);
          
      });
    };
 
    const handleButtonClick = () => {
        fileInputRef.current.click();
      };

        const handleCloseDialog = () => {
            if (!uploading) {
            setOpen(false);
            setFile(null);
            }
        };

        const handleDateFilter = (e) => {
          setRegionName(e.target.value);
          fetchAuditLeadList(e.target.value, auditStatus); // Fetch data on region change
      };
  
      const handleStatusFilter = (e) => {
          setAuditStatus(e.target.value);
          fetchAuditLeadList(regionName, e.target.value); // Fetch data on status change
      };

        const handleTraderClick = (e) => {
            navigate('/auditTraderList');
      };
  
      const handleInHouseClick = (e) =>{
        
          e.preventDefault();
      };

      const [downloadProgress, setDownloadProgress] = useState(0);
      const [isDownloading, setIsDownloading] = useState(false);
      
      const handleCloseModal = () => {
          setIsDownloading(false);
      };
      
      const handleExport = () => {
        setIsDownloading(true); // Open progress modal
        setDownloadProgress(0); // Reset progress
        
        let artificialProgressInterval;
    
        // Start artificial progress if the real progress is too fast
        artificialProgressInterval = setInterval(() => {
            setDownloadProgress((prevProgress) => {
                // Stop artificial progress at 90%
                if (prevProgress >= 70) {
                    clearInterval(artificialProgressInterval);
                    return prevProgress;
                }
                return prevProgress + 10; // Increment by 10 every 100ms
            });
        }, 100); // Update every 100ms
    
        axios.get(DOWNLOAD_EXCEL, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            timeout: 60000, // 1-minute timeout
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const realProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    
                    // Stop artificial progress when real progress updates
                    clearInterval(artificialProgressInterval);
                    
                    // Update progress based on real data, but ensure it doesn't go backwards
                    setDownloadProgress((prevProgress) => Math.max(prevProgress, realProgress));
                } else {
                    console.warn('Unable to calculate download progress');
                }
            }
        })
        .then(response => {
            clearInterval(artificialProgressInterval); // Ensure artificial progress is cleared
    
            // Create file download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
    
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'downloaded-file.xlsx';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="(.+)"/);
                if (matches && matches.length > 1) {
                    fileName = matches[1];
                }
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            // Close modal and show success
            setIsDownloading(false);
            setDownloadProgress(0);
            // setSeverity('success');
            // setMessage('File downloaded successfully');
            // setOpenSnackbar(true);
        })
        .catch(error => {
            clearInterval(artificialProgressInterval);
            console.error('Error downloading file:', error);
            setIsDownloading(false);
            setDownloadProgress(0);
            setSeverity('error');
            setMessage('Failed to download the file');
            setOpenSnackbar(true);
        });
    };
    

    return(
        <>
        {loading && <Loader open={loading} />}

        <div className={`${auditFormStyles.upload_div}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* <Box display="flex" gap={2}>
          <Button  variant="contained"
                color="primary"
                onClick={handleInHouseClick}>
                In-House Farmer
            </Button>
            <Button variant="contained"
                color="secondary"
                onClick={handleTraderClick}>
                Trader List
            </Button>
            </Box> */}
            <div></div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Export Button */}
                <Button
                    variant='outlined'
                    onClick={handleExport}
                    className="mr-2"
                    sx={{ mr: 2 }} // Adds margin to the right
                > 
                    <GetAppIcon />
                    Export
                </Button>

                {/* File Input and Bulk Upload Button */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                />
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={handleButtonClick}
                    className="mr-2"
                    sx={{ mr: 2 }} // Adds margin to the right
                >
                    <FileUploadIcon />
                    Bulk Upload
                </Button>

                {/* Filter Dropdown for Status */}
            <TextField
                id="outlined-select-status"
                select
                label="Status"
                value={auditStatus}
                size="small"
                className="w-36"
                onChange={handleStatusFilter}
                sx={{ ml: 2 }} // Adds margin between the button and dropdown
            >
                <MenuItem
                    key={'open'}
                    value={'open'}
                    sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}
                >
                    Open
                </MenuItem>
                <MenuItem
                    key={'closed'}
                    value={'closed'}
                    sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}
                >
                    Closed
                </MenuItem>
            </TextField>

            {/* Filter Dropdown for Region */}
            <TextField
                id="outlined-select-region"
                select
                label="Region"
                value={regionName}
                size="small"
                className="w-36"
                onChange={handleDateFilter}
                sx={{ ml: 2 }} // Adds margin between the button and dropdown
            >
                {REGIONS.map((eq) => (
                    <MenuItem
                        key={eq.label}
                        value={eq.label}
                        sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}
                    >
                        {eq.label}
                    </MenuItem>
                ))}
            </TextField>
            </div>

          </div>
            
            <MaterialReactTable table={table}  />


            <Dialog open={isDownloading} 
            onClose={(event, reason) => {
              // Disable closing when clicking outside the modal or pressing 'Escape'
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                  handleCloseModal();
              }
          }}
          >
          <DialogTitle>File Download in Progress...</DialogTitle>
          <DialogContent>
              <Box 
                  sx={{
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      minWidth: '250px', 
                      minHeight: '150px'
                  }}
              >
                  {/* Circular Progress with larger size */}
                  <CircularProgress 
                      variant="determinate" 
                      value={downloadProgress} 
                      size={80} 
                      thickness={4}
                      sx={{ color: downloadProgress < 100 ? '#1976d2' : '#4caf50' }} // Progress color change
                  />
                  <Typography 
                      variant="h6" 
                      sx={{ mt: 2, fontWeight: 'bold' }}
                  >
                      {downloadProgress}%
                  </Typography>
                  {/* Optional text for completed state */}
                  {downloadProgress === 100 && (
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                          Download complete! Preparing your file...
                      </Typography>
                  )}
              </Box>
          </DialogContent>
      </Dialog>
   


      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="sm"
      >
       <DialogTitle>{"Confirm Upload"}</DialogTitle>
        <DialogContent>
        <DialogContentText>
            {uploading ? "Uploading..." : "Are you sure you want to upload the selected file?"}
          </DialogContentText>
          {uploading && (
            <Box sx={{ width: '100%', mt: 2, height: '30px' }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
        {!uploading && (
            <>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpload} color="primary" autoFocus>
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
     


    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
    >
        {/* <AlertTitle>{alertTitle}</AlertTitle> */}
        {alertTitle}
    </Alert>
    </Snackbar>
        </>
    );

}

export default AuditMasterList;