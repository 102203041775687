import React, { createContext, useEffect, useState } from 'react';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [sessionActive, setSessionActive] = useState(() => {
        return localStorage.getItem('sessionActive') === 'true';
    });

    const handleTimeout = () => {
        setSessionActive(false);
        localStorage.setItem('sessionActive', 'false');
        // Handle logout logic here
        alert('Session Logout');
        window.location.href = '/'; // Navigate to the URL
    };

    const resetTimeout = () => {
        if (sessionActive) {
            clearTimeout(window.sessionTimeout);
            window.sessionTimeout = setTimeout(handleTimeout, 30 * 60 * 1000); // 30 minutes
        }
    };

    useEffect(() => {
        resetTimeout();

        const events = ['mousemove', 'keydown', 'click', 'touchstart', 'touchmove', 'touchend'];
        events.forEach(event => window.addEventListener(event, resetTimeout));

        return () => {
            events.forEach(event => window.removeEventListener(event, resetTimeout));
            clearTimeout(window.sessionTimeout);
        };
    }, [sessionActive]);

    return (
        <SessionContext.Provider value={{ sessionActive, setSessionActive }}>
            {children}
        </SessionContext.Provider>
    );
};
