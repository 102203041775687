import { combineReducers } from 'redux';
import authReducer from './Reducers/authReducer'; // Your authentication reducer
import detailsPageReducer from './Reducers/detailsPageReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  detailspage: detailsPageReducer
  // Add other reducers as needed
});

export default rootReducer;