import * as React from "react";
import { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  MenuItem,
  FormControl,
  Select,
  useMediaQuery,
  useTheme,
  Snackbar,
  IconButton,
  Tooltip,
  Menu,
  Button,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import ModalComponent from "../reusable Component/modalComponent";
import {
  GET_DETAILS_BY_MOBILE_NUMBER,
  GET_LEAD_BY_BDM_API,
  GET_TIMELINE,
} from "../../constants";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { LoginContext } from "../LoginPage/LoginContext";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Chip from "@mui/material/Chip";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LongTextComponent from "../Leads Page/LongTextComponent";
import Loader from "../reusable Component/Loader";
import EditIcon from "@mui/icons-material/Edit";
import TimelineDialog from "../reusable Component/TimelineDialog";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { GridToolbarContainer } from "@mui/x-data-grid";
import moment from "moment";

const CustomToolbar = ({ data, columns }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Custom export to Excel function
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      columns.map((column) => column.headerName),
      ...data.map((row) => columns.map((column) => row[column.field])),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Crm_Leads.xlsx");
    handleMenuClose(); // Close the menu after exporting
  };

  const exportToCSV = () => {
    const csvData = [
      columns.map((column) => column.headerName).join(","), // CSV Header
      ...data.map((row) =>
        columns.map((column) => row[column.field]).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Crm_Lead.csv");
    handleMenuClose(); // Close the menu after exporting
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },

        }}
      /> */}
      <Tooltip title="Export Options">
        <Button variant="text" onClick={handleMenuClick}>
          <SaveAltIcon
            sx={{ fontSize: "1.3rem", paddingRight: "2px" }}
          ></SaveAltIcon>{" "}
          Export
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
        <MenuItem onClick={exportToCSV}>Export to CSV</MenuItem>
        {/* Add more menu items for additional export options if needed */}
      </Menu>
      <Box sx={{ flexGrow: 1 }}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

function AssignedCustomerTable({ hotLeadRowData }) {
  const { setleadDetails } = useContext(LoginContext);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [rowDetails, setRowDetails] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const IconCell = ({ row }) => (
    <>
      <Tooltip title="Timeline" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
          }}
          color="primary"
          onClick={() => handletimelineClick(row)}
        >
          <VisibilityIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Edit" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
          }}
          color="primary"
          onClick={() => handleIconClick(row)}
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );

  const [leadDetails, setLeadDetails] = useState([]);

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const handleIconClick = (row) => {
    axios
      .get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${row.MobileNo}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { lead } = response.data;

        // Pass the data to handlingRowData
        handlingRowData(lead);
      })
      .catch((error) => {
        console.error("Error fetching lead details:", error);
      });
  };

  const handlingRowData = (data) => {
    if (!data || data.length === 0) {
      console.warn("No lead data available to transform.");
      return;
    }
    const transformedRows = [data].map((rd, index) => ({
      id: index + 1,
      SNo: index + 1,
      leadId: rd.id,
      CustomerName: rd.CustomerName?.trim() || "-",
      MobileNo: rd.MobileNo || "-",
      AlternateMobileNo: rd.AlternateMobileNo || "-",
      state_name: rd.state_name || "-",
      source_of_lead_generated: rd.Campaign?.CampaignName || "-",
      category: rd.category || "-",
      AgentId: rd.AgentId,
      BDMId: rd.BDMId,
      CustomerMailId: rd.CustomerMailId || "-",
      InquiryType: rd.InquiryType || "-",
      Project: rd.Project || "-",
      Superviser: rd.Superviser,
      SuperviserID: rd.SuperviserID,
      WhatsappNo: rd.WhatsappNo || "-",
      agent_remark: rd.agent_remark || "-",
      close_month: rd.close_month || "-",
      follow_up_date: rd.follow_up_date ? formatDate(rd.follow_up_date) : "-",
      lead_owner: rd.BDM?.EmployeeName || "-",
      lead_transfer_date: rd.lead_transfer_date
        ? formatDate(rd.lead_transfer_date)
        : "-",
      location: rd.location || "-",
      site_location_address: rd.site_location_address || "-",
      region_name: rd.region_name || "-",
      sub_category: rd.sub_category || "-",
      Agent: rd.Agent,
      AgentName: rd.Agent?.EmployeeName || "-",
      BDM: rd.BDM,
      pincode: rd.pincode || "-",
      call_status: rd.call_status,
      call_type: rd.call_type,
      createdDate: rd.createdAt ? formatDate(rd.createdAt) : "-",
      bdm_remark: rd.bdm_remark || "-",
      lead_calling_date: "-",
      bdm_calling_date: "-",
      updatedAt: rd.updatedAt ? formatDate(rd.updatedAt) : "-",
      last_action: rd.last_action || "-",
    }));

    // Store transformed data in localStorage
    localStorage.setItem("leadDetails", JSON.stringify(transformedRows[0]));

    setTimeout(() => navigate("/bdmDetails"), 100);
  };

  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineLogs, setTimelineLogs] = useState([]);
  const [custData, setCustData] = useState([]);

  const handletimelineClick = (row) => {
    setTimelineOpen(true);
    setCustData(row);
    axios
      .get(`${GET_TIMELINE}/${row.leadId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadLogs } = response.data;
        setTimelineLogs(leadLogs);
      })
      .catch((error) => {});
  };

  const columns = [
    {
      field: "",
      headerName: "SNo",
      width: isMobile ? 20 : 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
      type: "string",
      headerAlign: "center",
      responsive: true,
      disableColumnFilter: true,
      headerClassName: isMobile ? "" : "fixedColumn--header",
      cellClassName: isMobile ? "" : "fixedColumn--cell",
    },
    {
      field: "last_action",
      headerName: "Last Action",
      width: isMobile ? 120 : 150,
      type: "string",
      headerAlign: "center",
      headerClassName: isMobile ? "" : "fixedColumn--header1",
      cellClassName: isMobile ? "" : "fixedColumn--cell1",
      responsive: true,
    },
    {
      field: "updatedAt",
      headerName: "Last Action Date",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "string",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "CustomerName",
      headerName: "Farmer Name",
      width: isMobile ? 120 : 150,
      type: "string",
      headerAlign: "center",
      responsive: true,
    },
    {
      field: "MobileNo",
      headerName: "Mobile Number",
      width: isMobile ? 120 : 150,
      type: "string",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      responsive: true,
    },
    {
      field: "AlternateMobileNo",
      headerName: "Alternate Number",
      width: isMobile ? 120 : 150,
      type: "string",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      responsive: true,
    },
    {
      field: "location",
      headerName: "City/Village/Location",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "number",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "category",
      headerName: "Category",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Chip
          key={`index_${params.value}`}
          size="small"
          color={`${getColorForTag(params.value)}`}
          label={params.value}
          sx={{ textTransform: "capitalize", height: "20px" }}
        ></Chip>
      ),
    },
    {
      field: "sub_category",
      headerName: "Sub-Category",
      width: isMobile ? 200 : 150,
      headerAlign: "center",
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "agent_remark",
      headerName: "CSE Remarks",
      minWidth: 300,
      maxWidth: 800,
      flex: 1,
      headerAlign: "center",
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "bdm_remark",
      headerName: "BDM/BI Remark",
      minWidth: 300,
      maxWidth: 800,
      flex: 1,
      type: "string",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      responsive: true,
      renderCell: (params) => (
        <LongTextComponent
          text={params.value == null ? "" : params.value}
          maxLength={50}
        />
      ),
    },
    {
      field: "region_name",
      headerName: "Region",
      width: isMobile ? 120 : 150,
      type: "string",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      responsive: true,
    },
    {
      field: "createdDate",
      headerName: "Date of First Calling",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "number",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Project",
      headerName: "Project",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "number",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    // { field: 'lead_owner', headerName: 'Lead Owner',width: isMobile ? 120 : 150, type: 'string',  headerAlign: 'center', headerClassName: 'super-app-theme--header', responsive: true},
    {
      field: "CustomerMailId",
      headerName: "Mail ID",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "string",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "follow_up_date",
      headerName: "Follow-up Date",
      width: isMobile ? 120 : 150,
      type: "string",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      responsive: true,
    },
    {
      field: "close_month",
      headerName: "Closure Month",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lead_transfer_date",
      headerName: "Date of Lead Transfer",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bdm_calling_date",
      headerName: "Date of BDM Calling",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "source_of_lead_generated",
      headerName: "Campaign Name",
      width: isMobile ? 120 : 150,
      headerAlign: "center",
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "AgentName",
      headerName: "CSE Name",
      headerAlign: "center",
      width: isMobile ? 120 : 150,
      type: "string",
      responsive: true,
      headerClassName: "super-app-theme--header",
    },
    // { field: 'estimate_value', headerName: 'Estimate Value',width: isMobile ? 120 : 150, headerAlign: 'center' , type: 'string', responsive: true, headerClassName: 'super-app-theme--header'},

    {
      field: "action",
      headerName: "Action",
      width: 80,
      headerAlign: "center",
      headerClassName: isMobile ? "" : "fixedColumn--headeraction",
      cellClassName: isMobile ? "" : "fixedColumn--cellaction",
      responsive: true,
      sortable: false,
      renderCell: (params) => <IconCell {...params} />,
    },
  ];

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "hot":
        return "error"; // React blue
      case "warm":
        return "warning"; // JavaScript yellow
      case "cold":
        return "info"; // UI dark blue
      default:
        return "#888"; // Default gray
    }
  };

  const ActionSelect = ({ value, onChange, children, label }) => (
    <FormControl variant="standard" style={{ width: "100%" }}>
      <Select
        value={value}
        onChange={onChange}
        label="Action"
        className={`mt-1`}
      >
        {children}
      </Select>
    </FormControl>
  );

  const [modalTitle, setModalTitle] = useState("");

  const handleStatusChange = (id, newStatus, rowDetails) => {
    // Handle status change logic here (e.g., update state, API call, etc.)

    if (newStatus == "meeting") {
      setModalTitle("Meeting Details");
      setOpen(true);
    }
    if (newStatus == "sitevisit") {
      setModalTitle("Site Visit Details");
      setOpen(true);
    }
    if (newStatus == "estimate") {
      setModalTitle("Estimation Details");
      setOpen(true);
    }
    if (newStatus == "bdm_remark") {
      setModalTitle("On Call Discussion");
      setOpen(true);
    }

    setRowDetails(rowDetails);
  };

  const [open, setOpen] = useState(false);

  const isXs = useMediaQuery("(max-width:600px)"); // 0px - 600px
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // 600px - 900px
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // 900px - 1200px
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl")); // 1200px - 1536px
  const isXl = useMediaQuery(theme.breakpoints.up("xl")); // 1536px and up

  const getHeight = () => {
    if (isXs) return 400;
    if (isSm) return 400;
    if (isMd) return 500;
    if (isLg) return 600;
    if (isXl) return "100vh";
    return "auto";
  };

  // Set page size based on screen size
  const getPageSize = () => {
    let pageSize;
    if (isLg) {
      pageSize = 50; // 1200px and up
    } else if (isMd) {
      pageSize = 15; // 900px - 1200px
    } else if (isSm) {
      pageSize = 15; // 600px - 900px
    } else if (isXs) {
      pageSize = 10; // 0px - 600px
    } else {
      pageSize = 25;
    }

    return pageSize;
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      <Box
        sx={{
          height: "auto",
          width: "100%",
          maxWidth: "100vw",
          overflowX: "hidden",
        }}
      >
        <DataGrid
          rows={hotLeadRowData}
          columns={columns}
          columnHeaderHeight={50}
          getRowHeight={() => "auto"}
          autoHeight={hotLeadRowData.length < 10}
          disableExtendRowFullWidth
          // hideFooterPagination={theme.breakpoints.down('md')}
          slots={{
            toolbar: (props) => (
              <CustomToolbar
                data={hotLeadRowData}
                columns={columns}
                {...props}
              />
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true }, //Disable the print option in export
            },
          }}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: getPageSize(), page: 0 },
            },
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
          }}
          responsive={true}
          disableRowSelectionOnClick
          disableDensitySelector
          sx={{
            height: hotLeadRowData.length > 10 ? getHeight() : "auto", // here when a fixed height for the table was set the issue with the scoll was fixed
            "& .MuiDataGrid-cell": {
              textAlign: "center",
              placeContent: "center",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#EEF0F4",
            },
            "& .super-app-theme--header": {
              // width: isMobile == true ? '200px !important' : '150px !important',
              // maxWidth: isMobile == true ? '200px !important' : '150px !important'
            },
            "& .fixedColumn--header": {
              // minWidth: 50,
              // maxWidth: 50,
              flex: "0 0 auto",
              position: "sticky",
              left: 0,
              zIndex: 1,
              backgroundColor: "#EEF0F4", // Background color for the fixed column
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
            },
            "& .fixedColumn--header1": {
              // minWidth: 50,
              // maxWidth: 50,
              flex: "0 0 auto",
              position: "sticky",
              left: 50,
              zIndex: 1,
              backgroundColor: "#EEF0F4", // Background color for the fixed column
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
            },
            "& .fixedColumn--headeraction": {
              // maxWidth: 50,
              flex: "0 0 auto",
              position: "sticky",
              right: 0,
              zIndex: 1,
              backgroundColor: "#EEF0F4", // Background color for the fixed column
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
            },
            "& .fixedColumn--cell": {
              // minWidth: 50,
              // maxWidth: 50,
              flex: "0 0 auto",
              position: "sticky",
              left: 0,
              zIndex: 1,
              backgroundColor: "#fff", // Background color for the fixed column
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
            },
            "& .fixedColumn--cell1": {
              // minWidth: 50,
              // maxWidth: 50,
              flex: "0 0 auto",
              position: "sticky",
              left: 50,
              zIndex: 1,
              backgroundColor: "#fff", // Background color for the fixed column
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
            },
            "& .fixedColumn--cellaction": {
              // minWidth: 50,
              // maxWidth: 50,
              flex: "0 0 auto",
              position: "sticky",
              right: 0,
              zIndex: 1,
              backgroundColor: "#fff", // Background color for the fixed column
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Optional: Add shadow for separation
            },
          }}
        />
      </Box>
      <ModalComponent
        open={open}
        modalTitle={modalTitle}
        setOpen={setOpen}
        rowDetails={rowDetails}
        setOpenSnackbar={setOpenSnackbar}
        setSeverity={setSeverity}
        setMessage={setMessage}
      ></ModalComponent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <TimelineDialog
        open={timelineOpen}
        setOpen={setTimelineOpen}
        timelineLogs={timelineLogs}
        custData={custData}
      ></TimelineDialog>
    </>
  );
}

export default AssignedCustomerTable;
