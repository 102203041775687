import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { GET_ON_CALL_DISCUSSION_SV } from "../../constants";
import axios from "axios";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LongTextComponent from "../Leads Page/LongTextComponent";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function OnCallListComponent({ dateFilter }) {
  const [rows, setRow] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState({
    lead_owner: "",
    CustomerName: "",
    MobileNo: "",
    region_name: "",
    // Add other filters as needed
  });

  useEffect(() => {
    fetchOnCallData();
  }, [page, rowsPerPage, dateFilter]);

  const fetchOnCallData = () => {
    const currentPage = page + 1; // Convert zero-based index to one-based
    const limit = rowsPerPage;

    let url = `${GET_ON_CALL_DISCUSSION_SV}?page=${currentPage}&limit=${limit}`;
    if (Array.isArray(dateFilter)) {
      const [StartDate, EndDate] = dateFilter;
      url += `&StartDate=${StartDate}&EndDate=${EndDate}`;
    } else if (dateFilter) {
      url += `&date=${dateFilter}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads, pagination } = response.data;

        handlingRowData(leads);
        setTotalCount(pagination.total || 0); // Update the total count for pagination
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handlingRowData = (rowData) => {
    const transformedRows = [];
    var a = {};
    rowData.map((rd, index) => {
      a = {
        id: index + 1,
        SNo: index + 1,
        leadId: rd.id,
        CustomerName: rd.CustomerName == "" ? "-" : rd.CustomerName,
        MobileNo: rd.MobileNo == "" ? "-" : rd.MobileNo,
        AlternateMobileNo:
          rd.AlternateMobileNo == null ? "-" : rd.AlternateMobileNo,
        state_name: rd.state_name == "" ? "-" : rd.state_name,
        source_of_lead_generated:
          rd.Campaign == null ? "-" : rd.Campaign.CampaignName,
        category: rd.category == "" ? "-" : rd.category,
        AgentId: rd.AgentId,
        BDMId: rd.BDMId,
        CustomerMailId: rd.CustomerMailId == "" ? "-" : rd.CustomerMailId,
        InquiryType: rd.InquiryType,
        Project: rd.Project,
        Superviser: rd.Superviser,
        SuperviserID: rd.SuperviserID,
        WhatsappNo: rd.WhatsappNo == null ? "-" : rd.WhatsappNo,
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        close_month: rd.close_month,
        follow_up_date:
          rd.follow_up_date == "" ? "-" : formatDate(rd.follow_up_date),
        lead_owner: rd.BDM == null ? "-" : rd.BDM.EmployeeName,
        lead_transfer_date:
          rd.lead_transfer_date == "" || rd.lead_transfer_date == null
            ? "-"
            : formatDate(rd.lead_transfer_date),
        location: rd.location == "" ? "-" : rd.location,
        site_location_address:
          rd.site_location_address == "" ? "-" : rd.site_location_address,
        region_name: rd.region_name == "" ? "-" : rd.region_name,
        sub_category: rd.sub_category == "" ? "-" : rd.sub_category,
        Agent: rd.Agent,
        AgentName: rd.Agent == null ? "-" : rd.Agent.EmployeeName,
        BDM: rd.BDM,
        pincode: rd.pincode == "" ? "-" : rd.pincode,
        call_status: rd.call_status,
        call_type: rd.call_type,
        createdDate: rd.createdAt == "" ? "-" : formatDate(rd.createdAt),
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        bdm_remark: rd.bdm_remark == "" ? "-" : rd.bdm_remark,
        lead_calling_date: "-",
        bdm_calling_date: "-",
        Updates:
          rd.Updatess == undefined ? [] : handleSubArrayData(rd.Updatess),
      };
      transformedRows.push(a);
    });
    setRow(transformedRows);
  };

  const handleSubArrayData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        sno: index + 1,
        category: rd.category,
        closure_month: rd.closure_month,
        createdAt: formatDate(rd.createdAt),
        follow_up_date:
          rd.follow_up_date === null ? "-" : formatDate(rd.follow_up_date),
        remark: rd.remark,
        id: rd.id,
        sub_category: rd.sub_category,
        updatedAt: rd.updatedAt,
        BDM: rd.BDM,
      };
      transformedRows.push(a);
    });
    return transformedRows;
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const handleRowClick = (index) => {
    const newExpandedRows = expandedRows.includes(index)
      ? expandedRows.filter((i) => i !== index)
      : [...expandedRows, index];
    setExpandedRows(newExpandedRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Trigger data fetch via `useEffect`
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const handleExport = () => {
    // Combine main row data and expanded row data
    const exportData = rows.flatMap((row, index) => {
      // Base row data
      const mainRow = {
        SNo: row.SNo,
        "Lead Owner": row.lead_owner,
        "Farmer Name": row.CustomerName,
        "Mobile Number": row.MobileNo,
        Region: row.region_name,
        Category: row.category,
        "Sub-Category": row.sub_category,
        "CSE Remarks": row.agent_remark || "-",
        "BDM/BI Remarks": row.bdm_remark || "-",
        "City/Village/Location": row.location,
        State: row.state_name,
        "Mail ID": row.CustomerMailId,
        "Date of First Calling": row.createdDate,
        "Follow-up Date": row.follow_up_date,
        "Closure Month": row.close_month,
        "Date of Lead Transfer": row.lead_transfer_date,
        "Date of BDM Calling": row.bdm_calling_date,
        "Campaign Name": row.source_of_lead_generated,
        "CSE Name": row.AgentName,
        Project: row.Project,
        "Alternate Number": row.AlternateMobileNo,
        "Whatsapp Number": row.WhatsappNo,
      };

      // If expanded data exists, add it as separate rows
      const expandedRows = row.Updates
        ? row.Updates.map((exp, expIndex) => ({
            ...mainRow,
            "Date of Discussion": exp.createdAt, // Example field from expanded data
            "Follow-up Date": exp.follow_up_date, // Example field from expanded data
            Category: exp.category || "-", // Example field from expanded data
            "Sub-Category": exp.sub_category,
            "Closure Month": exp.closure_month,
            Remark: exp.remark,
            "Created By": exp.BDM.EmployeeName,
          }))
        : [];

      // Return combined main row and expanded rows
      return [mainRow, ...expandedRows];
    });

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BDMActionList");

    // Export to Excel file
    XLSX.writeFile(workbook, "BDMActionList.xlsx");
  };

  const rowExpansionTemplate = (data) => (
    <>
      <Typography sx={{ fontWeight: "700", marginBottom: "3px" }}>
        On-Call Discussions ({data.Updates.length})
      </Typography>
      <TableContainer
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
        }}
      >
        <Table
          sx={{ minWidth: 450, height: "100%", tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
              <TableCell width={50} align="center">
                SNo
              </TableCell>
              <TableCell width={80} align="center">
                Date of Discussion
              </TableCell>
              <TableCell width={120} align="center">
                Follow-up Date
              </TableCell>
              <TableCell width={80} align="center">
                Category
              </TableCell>
              <TableCell width={120} align="center">
                Sub-Category
              </TableCell>
              <TableCell width={120} align="center">
                Closure Month
              </TableCell>
              <TableCell width={150} align="center">
                Remark
              </TableCell>
              <TableCell width={80} align="center">
                Shared By
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.Updates.map((update, index) => (
              <TableRow key={index}>
                <TableCell width={50} align="center">
                  {update.sno}
                </TableCell>
                <TableCell width={80} align="center">
                  {update.createdAt}
                </TableCell>
                <TableCell width={120} align="center">
                  {update.follow_up_date}
                </TableCell>
                <TableCell
                  width={80}
                  align="center"
                  sx={{ textTransform: "capitalize" }}
                >
                  {update.category}
                </TableCell>
                <TableCell width={120} align="center">
                  {update.sub_category}
                </TableCell>
                <TableCell width={120} align="center">
                  {update.closure_month}
                </TableCell>
                <TableCell width={150} align="center">
                  <LongTextComponent
                    text={update.remark || "-"}
                    maxLength={30}
                  ></LongTextComponent>
                </TableCell>
                <TableCell width={80} align="center">
                  {update.BDM.EmployeeName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      <Paper>
        {/* Export Button */}
        <IconButton
          onClick={handleExport}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "35px", // Increased width for a more comfortable click area
            height: "35px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            float: "right",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FileDownloadIcon /> {/* Change icon based on state */}
        </IconButton>

        <TableContainer
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            margin: "8px 0",
          }}
        >
          <Table
            sx={{ minWidth: 650, height: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  align="right"
                  sx={{
                    width: 30,
                    padding: 0,
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 50,
                    padding: 1,
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  <TableSortLabel active={false}>Lead Owner</TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Farmer Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Region
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Sub-Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  CSE Remarks
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  BDM/BI Remarks
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  City/Village/Location
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  State
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Mail ID
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of First Calling
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Follow-up Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Closure Month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of Lead Transfer
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of BDM Calling
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Campaign Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  CSE Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Project
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Alternate Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Whatsapp Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableCell colSpan={22}>No Data Available Yet.</TableCell>
              ) : (
                rows.map((row, index) => (
                  <React.Fragment key={row.SNo}>
                    <TableRow sx={{ cursor: "pointer" }}>
                      {/* Expand Action */}
                      <TableCell align="right" sx={{ padding: 0 }}>
                        <button onClick={() => handleRowClick(index)}>
                          {expandedRows.includes(index) ? (
                            <RemoveIcon></RemoveIcon>
                          ) : (
                            <AddIcon></AddIcon>
                          )}
                        </button>
                      </TableCell>

                      {/* Other Columns */}
                      <TableCell
                        align="center"
                        sx={{
                          width: 50,
                          padding: 0,
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.lead_owner}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.CustomerName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.MobileNo}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.region_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                          textTransform: "capitalize",
                        }}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                          textTransform: "capitalize",
                        }}
                      >
                        {row.sub_category}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 200,
                          padding: 0,
                        }}
                      >
                        <LongTextComponent
                          text={row.agent_remark || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 200,
                          padding: 0,
                        }}
                      >
                        <LongTextComponent
                          text={row.bdm_remark || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.location}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.state_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.CustomerMailId}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.createdDate}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.follow_up_date}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.close_month}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.lead_transfer_date}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.bdm_calling_date}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.source_of_lead_generated}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.AgentName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.Project}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.AlternateMobileNo}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: 150,
                          padding: 0,
                        }}
                      >
                        {row.WhatsappNo}
                      </TableCell>
                    </TableRow>

                    {/* Collapse Row */}
                    {expandedRows.includes(index) && (
                      <TableRow>
                        <TableCell colSpan={23} sx={{ padding: 0 }}>
                          <Collapse in={expandedRows.includes(index)}>
                            <Box
                              sx={{
                                padding: 2,
                                width: "50%", // Set to a specific width if needed, e.g., "80%"
                                overflowX: "auto", // Add horizontal scrolling if content exceeds the container width
                              }}
                            >
                              {rowExpansionTemplate(row)}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount} // Use the total count from API
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default OnCallListComponent;
