import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const TaskTable = ({ tasks, setTaskSections }) => {
  const [openPostponeModal, setOpenPostponeModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [postponeRemark, setPostponeRemark] = useState('');
  const [expandedRemarks, setExpandedRemarks] = useState({}); // Track which remarks are expanded

  const handlePostpone = (taskId, newDate, remark) => {
    setTaskSections(prevSections =>
      prevSections.map(section => ({
        ...section,
        tasks: section.tasks.map(task =>
          task.id === taskId ? { ...task, postponedDate: newDate, postponedRemark: remark, disabled: true } : task
        )
      }))
    );
  };

  const handleConfirm = (taskId, option) => {
    setTaskSections(prevSections =>
      prevSections.map(section => ({
        ...section,
        tasks: section.tasks.map(task =>
          task.id === taskId ? { ...task, confirmed: true, bdm_remark: option, disabled: true } : task
        )
      }))
    );
  };

  const today = dayjs();

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate && newDate.isValid() ? newDate : null);
  };

  const handleConfirmPostpone = () => {
    if (currentTaskId !== null && selectedDate && postponeRemark) {
      handlePostpone(currentTaskId, selectedDate.toDate(), postponeRemark); // Pass the postpone remark
      setOpenPostponeModal(false);
      setSelectedDate(null);
      setPostponeRemark('');
    } else {
      alert("Please select a valid date and enter a remark.");
    }
  };

  const handleConfirmSubmit = () => {
    if (currentTaskId !== null && selectedOption) {
      handleConfirm(currentTaskId, selectedOption);
      setOpenConfirmModal(false);
      setSelectedOption('');
    } else {
      alert("Please select an option.");
    }
  };

  const toggleRow = (taskId) => {
    setExpandedRow(expandedRow === taskId ? null : taskId);
  };

  const toggleRemarkExpansion = (taskId) => {
    setExpandedRemarks(prev => ({ ...prev, [taskId]: !prev[taskId] }));
  };

  const renderRemark = (remark, taskId) => {
    const isValidRemark = typeof remark === 'string';
    const words = isValidRemark ? remark.split(' ') : [];
    const isExpanded = expandedRemarks[taskId];
  
    if (words.length <= 5) {
      return <p>{isValidRemark ? remark : 'No remark available.'}</p>;
    } else {
      return (
        <>
          <p>{isExpanded ? remark : words.slice(0, 5).join(' ') + '...'}
            <Button onClick={() => toggleRemarkExpansion(taskId)} color="primary">
              {isExpanded ? 'See Less' : 'See More'}
            </Button>
          </p>
        </>
      );
    }
  };

  return (
    <div className="w-full bg-white shadow-lg rounded-md overflow-hidden">
      <table className="w-full border-collapse p-2">
        <thead>
          <tr className="bg-green-500 text-white font-bold">
            <th className="p-2 border-b-2 border-gray-400">S No.</th>
            <th className="p-2 border-b-2 border-gray-400">Customer Name</th>
            <th className="p-2 border-b-2 border-gray-400">Confirm</th>
            <th className="p-2 border-b-2 border-gray-400">Reschedule</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <React.Fragment key={task.id}>
              <tr
                className={`border-b border-gray-200 ${task.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => toggleRow(task.id)}
              >
                <td className="p-2 whitespace-nowrap">
                  {index + 1}
                  <span className={`ml-2 cursor-pointer ${expandedRow === task.id ? 'rotate-180' : ''}`}>
                    ▼
                  </span>
                </td>
                <td className="p-2">{task.CustomerName}</td>
                <td className="p-2">
                  <Button 
                    variant="contained" 
                    color="success" 
                    size="small" 
                    onClick={() => {
                      setCurrentTaskId(task.id);
                      setOpenConfirmModal(true);
                    }} 
                    disabled={task.disabled}
                    startIcon={<CheckIcon />}
                  >
                  </Button>
                </td>
                <td className="p-2">
                  {task.postponedDate ? (
                    <span className="text-blue-600">
                      {new Date(task.postponedDate).toLocaleDateString()} - {task.postponedRemark}
                    </span>
                  ) : (
                    <Button 
                      variant="contained" 
                      color="error" 
                      size="small" 
                      onClick={() => {
                        setOpenPostponeModal(true);
                        setCurrentTaskId(task.id);
                      }} 
                      disabled={task.disabled}
                      startIcon={<CancelIcon />}
                    >
                    </Button>
                  )}
                </td>
              </tr>
              {expandedRow === task.id && (
                <tr>
                  <td colSpan="4" className="p-2 bg-gray-100">
                    <div>
                      <p><strong>Mobile No:</strong> {task.MobileNo}</p>
                      <p><strong>Location:</strong> {task.location}</p>
                      <p><strong>Category:</strong> {task.category}</p>
                      <p><strong>Sub Category:</strong> {task.sub_category}</p>
                      <p><strong>Agent Remark:</strong> {renderRemark(task.agent_remark, task.id)}</p>
                      <p><strong>BDM Remark:</strong> {renderRemark(task.bdm_remark, task.id)}</p>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Postpone Modal */}
      <Dialog open={openPostponeModal} onClose={() => setOpenPostponeModal(false)}>
        <DialogTitle>Postpone Task</DialogTitle>
        <DialogContent>
          <DatePicker
            label="New Date"
            value={selectedDate}
            onChange={handleDateChange}
            minDate={today}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          <TextField
            label="Remarks"
            value={postponeRemark}
            onChange={(e) => setPostponeRemark(e.target.value)}
            fullWidth
            margin="normal"
            error={!postponeRemark && selectedDate !== null}
            helperText={!postponeRemark && selectedDate !== null ? "This field is required." : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPostponeModal(false)} color="primary">Cancel</Button>
          <Button 
            onClick={handleConfirmPostpone} 
            color="primary" 
            variant="contained" 
            disabled={!selectedDate || !postponeRemark}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Modal */}
      <Dialog 
        open={openConfirmModal} 
        onClose={() => setOpenConfirmModal(false)} 
        maxWidth="md" 
        fullWidth 
      >
        <DialogTitle>Confirm Task</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Task</InputLabel>
            <Select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              label="Select Option"
            >
              <MenuItem value="">Select an option</MenuItem>
              <MenuItem value="On Call Discussion">On Call Discussion</MenuItem>
              <MenuItem value="Site Visit">Site Visit</MenuItem>
              <MenuItem value="Meeting">Meeting</MenuItem>
              {/* <MenuItem value="Individual Meeting">Individual Meeting</MenuItem> */}
              {/* <MenuItem value="Group Meeting">Group Meeting</MenuItem> */}
              <MenuItem value="Estimation Request">Estimation Request</MenuItem>
              {/* <MenuItem value="Conversion Follow up & Meeting">Conversion Follow up & Meeting</MenuItem> */}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmModal(false)} color="primary">Cancel</Button>
          <Button onClick={handleConfirmSubmit} color="primary" variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TaskTable;
