import React, { useEffect } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MenuItem, FormControl, Select , useMediaQuery, useTheme} from '@mui/material';
import LogTimelineComponent from './Timeline';



function TimelineDialog({open, setOpen, timelineLogs, custData}) {

    const handleClose = () => {
      setOpen(false);
    };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


const [timelineData, setTimelineData] = useState([]);

useEffect(()=>{
    const extractedData = timelineLogs.map(log => ({
        action_type: log.action_type,
        category: log.category,
        sub_category: log.sub_category,
        createdAt: log.createdAt,
        performed_by: log.PerformedBy.EmployeeName,
        remarks: log.remarks,
        follow_up_date: log.follow_up_date

      }));

      setTimelineData(extractedData);
},[timelineLogs]);

  return (
    <>
    <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen} // Adjusts dialog size based on screen width
        maxWidth="md" // Limits maximum width of the dialog
        fullWidth // Ensures dialog takes full width of the screen
        PaperProps={{
        sx: {
            borderRadius: '10px', // Rounded corners for the dialog
            overflow: 'hidden', // Prevent content overflow
            boxShadow: '0 8px 16px rgba(0,0,0,0.2)', // Add a soft shadow
        },
        }}
    >
        <DialogTitle
            sx={{
                background: '#fff', // Gradient background for the title
                padding: '16px 24px', // Add padding for spacing
                fontWeight: 'bold', // Bold title
                textAlign: 'center', // Center the title text
            }}
        >
            Timeline - {(custData?.CustomerName === null || custData?.CustomerName === undefined ) ? '' : custData?.CustomerName}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#000', // White icon color to match the title
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        
        <DialogContent
            sx={{
                backgroundColor: '#f0f0f0', // Subtle background color for content
                padding: '24px', // Add padding for spacing
                borderTop: '4px solid #ff7e5f', // Add a top border for a decorative touch
            }}
        >
            <LogTimelineComponent timelineData={timelineData} />
        </DialogContent>
    </Dialog>

         
    </>
  );
}

export default TimelineDialog;