import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import './mobileDatatableStyles.css';

function MobileMeetingDatatable({data}) {

    useEffect(() => {
  
        // Initialize DataTable
        const tableId = '#example_meeting';
          
        // Destroy any existing DataTable instance before reinitializing
        if ($.fn.DataTable.isDataTable(tableId)) {
          $(tableId).DataTable().clear().destroy();
        }
    
          const table = $('#example_meeting').DataTable({
            responsive: true,
            data: data ,
            columns: [
            { data: 'sno', title: 'SNo.' },
            { data: 'createdAt', title: 'Created Date'},
            { data: 'follow_up_date', title: 'Follow-up Date' },
            { data: 'closure_month', title: 'Closure Month' },
            { data: 'category', title: 'Category' },
            { data: 'sub_category', title: 'Sub Category' },
            { data: 'BirdsCapacity', title: 'Birds Capacity' },
            { data: 'LandDimension', title: 'Land Dimension' },
            { data: 'ShedSize', title: 'Shed Size' },
            { data: 'IsLandDirectionEastWest', title: 'Is Land Direction East West' },
            { data: 'DirectionDeviationDegree', title: 'Direction Deviation Degree' },
            { data: 'ElectricityPower', title: 'Electricity Power' },
            { data: 'Water', title: 'Water' },
            { data: 'ApproachRoad', title: 'Approach Road' },
            { data: 'ModelType', title: 'Model Type' },
            { data: 'EstimationRequirement', title: 'Estimation Requirement' },
          ],
            rowReorder: {
              selector: 'td:nth-child(2)'
            }
            ,
            // scrollY: '400px',
          scrollCollapse: true
          });

          var dtLengthElement = document.querySelector('.dt-length');
          var dtSearchElement = document.querySelector('.dt-search');
            if (dtLengthElement) {
                // Get the outer div
                var outerDiv = dtLengthElement.closest('.col-md-auto.me-auto');
                var outerDiv1 = dtSearchElement.closest('.col-md-auto.ms-auto ');
                outerDiv.style.width = '10%';
                outerDiv1.style.width = '70%';
                // Now you can do something with the outerDiv
            
            }
    
          // Cleanup function to destroy the DataTable instance when the component unmounts
          return () => {
           table.destroy();
          };
        
        
      }, [data]);

    return(
        <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/2.0.8/css/dataTables.dataTables.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/rowreorder/1.5.0/css/rowReorder.dataTables.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/responsive/3.0.2/css/responsive.dataTables.css"
        />
        <style>
          {`
            /* Hide the label "Entries per page" */
            .dt-length label {
              display: none;
            }
            /* Hide the label "Search" */
            .dt-search label {
              display: none;
            }

            .dt-container .dt-search {
                 margin-top: 0px !important;
            }
            
          `}
        </style>
      </Helmet>
      <div className='dt-layout-row dt-layout-table' >
        <div className='dt-layout-cell '>
          <table id="example_meeting" className="table table-striped table-hover table-bordered" style={{ width: '100%', position: 'relative', fontSize: '0.8rem' }}>
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Created Date</th>
                <th>Follow-up Date</th>
                <th>Closure Month</th>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>Birds Capacity</th>
                <th>Land Dimension</th>
                <th>Shed Size</th>
                <th>Is Land Direction East West</th>
                <th>Direction Deviation Degree</th>
                <th>Electricity Power</th>
                <th>Water</th>
                <th>Approach Road</th>
                <th>Model Type</th>
                <th>Estimation Requirement</th>
              </tr>
            </thead>
          </table>
      </div>
      </div>
    </>
    );

}

export default MobileMeetingDatatable;