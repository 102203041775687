// import React, { useState } from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   Grid,
//   CircularProgress,
// } from '@mui/material';
// import TaskTable from './TaskTable';

// const TaskListModal = ({ open, onClose, taskSections, setTaskSections }) => {
//   const [remark, setRemark] = useState('');
//   const [selectedOtherTask, setSelectedOtherTask] = useState('');
//   const [otherTasks, setOtherTasks] = useState([]);
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const storedId = localStorage.getItem('username');

//   const handleAddTask = () => {
//     if (selectedOtherTask && remark) {
//       const newTask = {
//         id: new Date().getTime(),
//         task_name: selectedOtherTask,
//         remarks: remark,
//       };

//       setOtherTasks(prevTasks => [newTask, ...prevTasks]);
//       setSelectedOtherTask('');
//       setRemark('');
//       setError('');
//     } else {
//       setError('Please select a task and enter a remark.');
//     }
//   };

//   const allRequiredTasksHandled = () => {
//     return taskSections.every(section => 
//       section.tasks.every(task => 
//         task.confirmed || task.postponedDate
//       )
//     );
//   };

//   const handleSubmitActions = async () => {
//     setLoading(true);
//     const HOActions = [];
//     const selfActions = [];
//     const otherTaskActions = [];

//     taskSections.forEach(section => {
//       section.tasks.forEach(task => {
//         if (task.confirmed) {
//           const action = {
//             id: task.id,
//             action_type: "confirm",
//             specific_action: task.bdm_remark || 'No remarks',
//             remarks: 'No remarks',
//           };
//           if (task.isSelfTask) {
//             selfActions.push(action);
//           } else {
//             HOActions.push(action);
//           }
//         } else if (task.postponedDate) {
//           const postponedDate = new Date(task.postponedDate);
//           const adjustedDate = new Date(postponedDate.setHours(0, 0, 0, 0)); // Normalize to midnight
          
//           const postponedAction = {
//             id: task.id,
//             action_type: "postpone",
//             new_follow_up_date: adjustedDate.toISOString(), // Use ISO string format
//             remarks: task.postponedRemark,
//           };
//           if (task.isSelfTask) {
//             selfActions.push(postponedAction);
//           } else {
//             HOActions.push(postponedAction);
//           }
//         }
//       });
//     });

//     otherTasks.forEach(task => {
//       otherTaskActions.push({
//         task_name: task.task_name,
//         remarks: task.remarks,
//       });
//     });

//     if (selectedOtherTask && remark) {
//       otherTaskActions.push({
//         task_name: selectedOtherTask,
//         remarks: remark,
//       });
//     }

//     const payload = {
//       bdmId: storedId,
//       HO_task: HOActions,
//       self_task: selfActions,
//       other_task: otherTaskActions,
//       attendanceType: "IN",
//       latitude: 12.9716,
//       longitude: 77.5946,
//     };

//     try {
//       const response = await fetch(`${storedId}/api/batch-lead-actions`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(payload),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || 'Network response was not ok');
//       }

//       const result = await response.json();
//       console.log('Success:', result);
//       setOtherTasks([]); // Clear other tasks on success
//       setRemark(''); // Clear remark input
//       onClose(); // Close modal on success
//     } catch (error) {
//       console.error('Error submitting actions:', error);
//       setError(error.message); // Display the error message
//     } finally {
//       setLoading(false); // Reset loading state
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose} fullScreen>
//       <DialogTitle>Attendance List</DialogTitle>
//       <DialogContent>
//         {taskSections.map((section, index) => (
//           <div key={index} className="mb-4">
//             {section.tasks.length > 0 && (
//               <>
//                 <h2 className="font-bold text-lg">{section.title}</h2>
//                 <TaskTable tasks={section.tasks} setTaskSections={setTaskSections} />
//               </>
//             )}
//           </div>
//         ))}

//         <div className="mt-6">
//           <h2 className="font-bold text-lg">Other Task</h2>
//           {otherTasks.length > 0 && (
//             <div className="mt-4">
//               <ul>
//                 {otherTasks.map((task) => (
//                   <li key={task.id} className="mt-2">
//                     {task.task_name} - {task.remarks}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//           <Grid container spacing={2} alignItems="center" className="mt-4">
//             <Grid item xs={6}>
//               <FormControl fullWidth error={!!error}>
//                 <InputLabel>Select Other Task</InputLabel>
//                 <Select
//                   value={selectedOtherTask}
//                   onChange={(e) => setSelectedOtherTask(e.target.value)}
//                   label="Select Other Task"
//                 >
//                   <MenuItem value="">Select Task</MenuItem>
//                   <MenuItem value="Agreement">Agreement</MenuItem>
//                   <MenuItem value="Customer Creation">Customer Creation</MenuItem>
//                   <MenuItem value="Dispatch Planning">Dispatch Planning</MenuItem>
//                 </Select>
//                 {error && <p className="text-red-500">{error}</p>}
//               </FormControl>
//             </Grid>

//             <Grid item xs={6}>
//               <TextField
//                 label="Remark"
//                 value={remark}
//                 onChange={(e) => setRemark(e.target.value)}
//                 fullWidth
//                 error={!!error}
//               />
//             </Grid>

//             <Grid item xs={2}>
//               <Button onClick={handleAddTask} variant="contained" color="primary" fullWidth>
//                 +
//               </Button>
//             </Grid>
//           </Grid>
//         </div>
//       </DialogContent>
//       <DialogActions>
//         {loading ? (
//           <CircularProgress />
//         ) : (
//           <Button onClick={handleSubmitActions} color="primary" disabled={!allRequiredTasksHandled() || loading}>
//             Attendance IN
//           </Button>
//         )}
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default TaskListModal;
















import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  CircularProgress,
} from '@mui/material';
import TaskTable from './TaskTable';
import { BASE_URL } from '../../constants';

const TaskListModal = ({ open, onClose, taskSections, setTaskSections }) => {
  const [remark, setRemark] = useState('');
  const [selectedOtherTask, setSelectedOtherTask] = useState('');
  const [otherTasks, setOtherTasks] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const storedId = localStorage.getItem('username');
  const userData = localStorage.getItem('userData');
  const storedName = userData ? JSON.parse(userData).EmployeeName : null;
  
  const handleAddTask = () => {
    if (selectedOtherTask && remark) {
      const newTask = {
        id: new Date().getTime(),
        task_name: selectedOtherTask,
        remarks: remark,
      };

      setOtherTasks(prevTasks => [newTask, ...prevTasks]);
      setSelectedOtherTask('');
      setRemark('');
      setError('');
    } else {
      setError('Please select a task and enter a remark.');
    }
  };

  const allRequiredTasksHandled = () => {
    return taskSections.every(section => 
      section.tasks.every(task => 
        task.confirmed || task.postponedDate
      )
    );
  };

  const handleSubmitActions = async () => {
    setLoading(true);
    const HOActions = [];
    const selfActions = [];
    const otherTaskActions = [];

    taskSections.forEach(section => {
      section.tasks.forEach(task => {
        if (task.confirmed) {
          const action = {
            id: task.id,
            action_type: "confirm",
            specific_action: task.bdm_remark || 'No remarks',
            remarks: 'No remarks',
          };
          if (task.isSelfTask) {
            selfActions.push(action);
          } else {
            HOActions.push(action);
          }
        } else if (task.postponedDate) {
          const postponedDate = new Date(task.postponedDate);
          const adjustedDate = new Date(postponedDate);
          adjustedDate.setDate(adjustedDate.getDate() + 1); // Add one day

          const postponedAction = {
            id: task.id,
            action_type: "postpone",
            new_follow_up_date: adjustedDate.toISOString(), // Use ISO string format
            remarks: task.postponedRemark,
          };
          if (task.isSelfTask) {
            selfActions.push(postponedAction);
          } else {
            HOActions.push(postponedAction);
          }
        }
      });
    });

    otherTasks.forEach(task => {
      otherTaskActions.push({
        task_name: task.task_name,
        remarks: task.remarks,
      });
    });

    if (selectedOtherTask && remark) {
      otherTaskActions.push({
        task_name: selectedOtherTask,
        remarks: remark,
      });
    }

    const payload = {
      bdmId: storedId,
      HO_task: HOActions,
      self_task: selfActions,
      other_task: otherTaskActions,
      attendanceType: "IN",
      latitude: 12.9716,
      longitude: 77.5946,
    };

    try {
      const response = await fetch(`${BASE_URL}/batch-lead-actions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const result = await response.json();
      console.log('Success:', result);
      setOtherTasks([]); // Clear other tasks on success
      setRemark(''); // Clear remark input
      onClose(); // Close modal on success
    } catch (error) {
      console.error('Error submitting actions:', error);
      setError(error.message); // Display the error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Today List of {storedName} </DialogTitle>
      <DialogContent>
        {taskSections.map((section, index) => (
          <div key={index} className="mb-4">
            {section.tasks.length > 0 && (
              <>
                <h2 className="font-bold text-lg">{section.title}</h2>
                <TaskTable tasks={section.tasks} setTaskSections={setTaskSections} />
              </>
            )}
          </div>
        ))}

        <div className="mt-6">
          <h2 className="font-bold text-lg">Other Task</h2>
          {otherTasks.length > 0 && (
            <div className="mt-4">
              <ul>
                {otherTasks.map((task) => (
                  <li key={task.id} className="mt-2">
                    {task.task_name} - {task.remarks}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <Grid container spacing={2} alignItems="center" className="mt-4">
            <Grid item xs={6}>
              <FormControl fullWidth error={!!error}>
                <InputLabel>Select Other Task</InputLabel>
                <Select
                  value={selectedOtherTask}
                  onChange={(e) => setSelectedOtherTask(e.target.value)}
                  label="Select Other Task"
                >
                  <MenuItem value="">Select Task</MenuItem>
  <MenuItem value="Agreement">Agreement</MenuItem>
  <MenuItem value="Customer Creation">Customer Creation</MenuItem>
  <MenuItem value="Dispatch Planning">Dispatch Planning</MenuItem>
  <MenuItem value="Installation Planning">Installation Planning</MenuItem>
  <MenuItem value="Complaint/ Service">Complaint/ Service</MenuItem>
  <MenuItem value="Consent 3rd Party">Consent 3rd Party</MenuItem>
  <MenuItem value="HO Visit">HO Visit</MenuItem>
  <MenuItem value="RO Visit">RO Visit</MenuItem>
  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {error && <p className="text-red-500">{error}</p>}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                fullWidth
                error={!!error}
              />
            </Grid>

            <Grid item xs={2}>
              <Button onClick={handleAddTask} variant="contained" color="primary" fullWidth>
                +
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress />
        ) : (
<Button 
  onClick={handleSubmitActions} 
  style={{
    border: `2px solid ${(!allRequiredTasksHandled() || loading) ? 'gray' : 'green'}`, // Change border color based on disabled state
    backgroundColor: 'white',
    color: 'green',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: (!allRequiredTasksHandled() || loading) ? 'not-allowed' : 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    opacity: (!allRequiredTasksHandled() || loading) ? 0.6 : 1,
  }} 
  disabled={!allRequiredTasksHandled() || loading}
  onMouseEnter={(e) => {
    if (!(!allRequiredTasksHandled() || loading)) {
      e.currentTarget.style.backgroundColor = 'green';
      e.currentTarget.style.color = 'white';
    }
  }} 
  onMouseLeave={(e) => {
    if (!(!allRequiredTasksHandled() || loading)) {
      e.currentTarget.style.backgroundColor = 'white';
      e.currentTarget.style.color = 'green';
    }
  }}
>
  Attendance IN
</Button>

        )}
      </DialogActions>
    </Dialog>
  );
};

export default TaskListModal;
