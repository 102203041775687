const events = {};

export const on = (event, listener) => {
  if (!events[event]) {
    events[event] = [];
  }
  events[event].push(listener);
};

export const emit = (event, data) => {
  if (events[event]) {
    events[event].forEach((listener) => listener(data));
  }
};

export const off = (event, listener) => {
  if (!events[event]) return;
  events[event] = events[event].filter((l) => l !== listener);
};