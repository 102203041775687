import React, { useState } from "react";
import { Grid, Card, Typography, Box, Button, Breadcrumbs } from "@mui/material";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import SourceIcon from '@mui/icons-material/Source';
import MapIcon from '@mui/icons-material/Map';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import CSEComponent from "./CSEComponent";
import BDMComponent from "./BDMComponent";
import ZMComponent from "./ZMComponent";
import SourceOfLeadComponent from "./SourceOfLeadComponent";
import CategorySubCategoryComponent from "./CategorySubCategoryComponent";
import RegionComponent from "./RegionComponent";
import EmployeeComponent from "./EmployeeComponent";
import { Link } from "react-router-dom";

function SettingsComponent() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [label, setLabel] = useState("");

  const menuItems = [
    { label: "CSE", icon: <AccountBoxIcon />, component: <CSEComponent /> },
    { label: "BDM", icon: <PersonIcon />, component: <BDMComponent /> },
    { label: "Zonal Manager", icon: <MapIcon />, component: <ZMComponent /> },
    { label: "Source of Lead", icon: <SourceIcon />, component: <SourceOfLeadComponent /> },
    { label: "Category Sub-Category", icon: <CategoryIcon />, component: <CategorySubCategoryComponent /> },
    { label: "Region", icon: <AddLocationAltIcon />, component: <RegionComponent /> },
    { label: "Employee", icon: <PersonIcon />, component: <EmployeeComponent /> },
  ];

  const handleClick = (item) => {
    setLabel(item.label);
    setSelectedItem(item.component);
  };

  const handleBack = () => {
    setSelectedItem(null);
  };

  return (
    <>
      {selectedItem === null ? (
        <>
          <Typography sx={{ fontWeight: 700, fontSize: '2rem', mb: 3 }}>
            Admin Panel
          </Typography>
          <Grid container spacing={3}>
            {menuItems.map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.label}>
                <Card
                  sx={{
                    cursor: "pointer",
                    padding: 3,
                    textAlign: "center",
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: "#f5f5f5",  // Subtle background color for contrast
                    "&:hover": {
                      boxShadow: 6,
                      backgroundColor: "#e0e0e0",  // Slightly darker on hover
                    },
                  }}
                  onClick={() => handleClick(item)}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                    {item.icon}
                  </Box>
                  <Typography sx={{ fontSize: '1.2rem', fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.label}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" to="/settings" onClick={handleBack}>
              Admin Panel
            </Link>
            <Typography key="2" color="text.primary">
              {label}
            </Typography>
          </Breadcrumbs>
          {selectedItem}
        </>
      )}
    </>
  );
}

export default SettingsComponent;
