import React from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { LEAD_SOURCE } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function SourceOfLeadComponent() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionRow, setActionRow] = useState(null);

  // Open the dialog for editing
  const handleEditClick = (row) => {
    setEditData(row); // Populate with the existing row data
    setIsEdit(true); // Set to edit mode
    setEditOpen(true);
  };

  // Open the dialog for adding
  const handleAddClick = () => {
    setEditData({ name: "" }); // Initialize empty data for adding
    setIsEdit(false); // Set to add mode
    setEditOpen(true);
  };

  // Handle field changes
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle save functionality
  const handleSave = () => {
    if (isEdit) {
      // Logic to update an existing entry
      console.log("Editing data:", editData);
    } else {
      // Logic to add a new entry
      console.log("Adding new data:", editData);
    }
    setEditOpen(false); // Close dialog
  };

  const handleActionClick = (row) => {
    console.log(row);
    
    setActionRow(row);
    setConfirmOpen(true);
  };

  const handleConfirmAction = () => {
    // Simulate status change
    actionRow.status = actionRow.status === "Active" ? "Inactive" : "Active";
    setConfirmOpen(false);
    setActionRow(null);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setActionRow(null);
  };

  return (
    <>
      {/* Edit Dialog */}
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        fullScreen={fullScreen} // Adjusts dialog size based on screen width
      >
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: "#fff",
            padding: "8px 24px",
          }}
        >
          {isEdit ? "Edit BDM" : "Add BDM"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setEditOpen(false)}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            name="label"
            value={editData?.label || ""} // Value reflects name for both Add/Edit
            onChange={handleFieldChange}
            margin="normal"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setEditOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} color="primary">
            {isEdit ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          Are you sure you want to{" "}
          <strong>
            {actionRow?.status === "Active" ? "disable" : "enable"}
          </strong>{" "}
           {actionRow?.label}?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
          marginTop: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Source of Lead
        </Typography>

        <Button
          variant="contained"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
          onClick={() => handleAddClick()}
        >
          <AddIcon />
          Add Source of Lead
        </Button>
      </Box>
      <Paper sx={{ boxShadow: 3, borderRadius: 2 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 600,
              tableLayout: "fixed",
              "& .MuiTableHead-root": {
                backgroundColor: "#f5f5f5",
                color: "#000",
              },
              "& .MuiTableCell-root": {
                fontSize: "0.875rem",
                textAlign: "center",
                padding: "12px",
              },
              "& .MuiTableRow-root:hover": { backgroundColor: "#f5f5f5" },
            }}
            aria-label="fancy table"
          >
            <TableHead>
              <TableRow>
                <TableCell width={50} sx={{ fontWeight: "bold" }} align="left">
                  SNo
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="left">
                  Source
                </TableCell>
                <TableCell width={80} sx={{ fontWeight: "bold" }} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {LEAD_SOURCE.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{item.label}</TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          sx={{
                            color: "info.main",
                            "&:hover": { color: "info.dark" },
                            border: "1px solid",
                            borderRadius: "4px",
                            padding: "2px",
                            width: "24px",
                            height: "24px",
                            marginRight: "5px",
                          }}
                          onClick={() => handleEditClick(item)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={item.status === "Active" ? "Disable" : "Enable"}
                        arrow
                      >
                        <IconButton
                          onClick={() => handleActionClick(item)}
                          sx={{
                            color:
                              item.status === "Active"
                                ? "error.main"
                                : "success.main",
                            border: "1px solid",
                            borderRadius: "4px",
                            padding: "2px",
                            width: "24px",
                            height: "24px",
                          }}
                        >
                          {item.status === "Active" ? (
                            <BlockIcon fontSize="small" />
                          ) : (
                            <CheckCircleOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default SourceOfLeadComponent;
