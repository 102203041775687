import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const PivotTable = forwardRef(({ bdmStatisticsList, prevfilters }, ref) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc"); // "asc" or "desc"

  const navigate = useNavigate();

  const handleNav = (bdmid) => {
    navigate(`/bdmReport/details/${bdmid}`, { state: { prevfilters } });
  };

  const handleViewClick = (bdmid) => {
    navigate(`/bdmReport/details/${bdmid}`, { state: { prevfilters } });
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortColumn(column);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const sortedData = [...bdmStatisticsList].sort((a, b) => {
    if (!sortColumn) return 0;

    let valueA, valueB;

    switch (sortColumn) {
      case "name":
        valueA = a.bdm_name.toLowerCase();
        valueB = b.bdm_name.toLowerCase();
        break;
      case "confirmed":
        valueA = a.statistics.confirmed.count;
        valueB = b.statistics.confirmed.count;
        break;
      case "postponed":
        valueA = a.statistics.postponed.count;
        valueB = b.statistics.postponed.count;
        break;
      case "completed":
        valueA = a.statistics.completed.count;
        valueB = b.statistics.completed.count;
        break;
      case "notCompleted":
        valueA = a.statistics.not_completed.count;
        valueB = b.statistics.not_completed.count;
        break;
      case "achievement":
        const achievementA =
          a.statistics.confirmed.count > 0
            ? (a.statistics.completed.count / a.statistics.confirmed.count) *
              100
            : 0;
        const achievementB =
          b.statistics.confirmed.count > 0
            ? (b.statistics.completed.count / b.statistics.confirmed.count) *
              100
            : 0;
        valueA = achievementA;
        valueB = achievementB;
        break;
      default:
        return 0;
    }

    if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
    if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const exportToExcel = () => {
    const exportData = sortedData.map((item) => {
      const confirmedCount = item.statistics.confirmed.count;
      const completedCount = item.statistics.completed.count;
      const achievementPercentage =
        confirmedCount > 0
          ? Math.round((completedCount / confirmedCount) * 100)
          : 0;

      return {
        "BDM Name": item.bdm_name,
        Confirmed: confirmedCount,
        Postponed: item.statistics.postponed.count,
        Completed: completedCount,
        "Not-Completed": item.statistics.not_completed.count,
        Achievement: `${achievementPercentage}%`,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BDM Statistics");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "BDM_Statistics.xlsx");
  };

  // Expose exportToExcel method to parent
  useImperativeHandle(ref, () => ({
    exportToExcel,
  }));

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#EEF0F4" }}>
              <TableSortLabel
                active={sortColumn === "name"}
                direction={sortDirection}
                onClick={() => handleSort("name")}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  BDM Name
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ backgroundColor: "#EEF0F4" }}>
              <TableSortLabel
                active={sortColumn === "confirmed"}
                direction={sortDirection}
                onClick={() => handleSort("confirmed")}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Confirmed
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ backgroundColor: "#EEF0F4" }}>
              <TableSortLabel
                active={sortColumn === "postponed"}
                direction={sortDirection}
                onClick={() => handleSort("postponed")}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Postponed
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ backgroundColor: "#EEF0F4" }}>
              <TableSortLabel
                active={sortColumn === "completed"}
                direction={sortDirection}
                onClick={() => handleSort("completed")}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Completed
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ backgroundColor: "#EEF0F4" }}>
              <TableSortLabel
                active={sortColumn === "notCompleted"}
                direction={sortDirection}
                onClick={() => handleSort("notCompleted")}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Not-Completed
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ backgroundColor: "#EEF0F4" }}>
              <TableSortLabel
                active={sortColumn === "achievement"}
                direction={sortDirection}
                onClick={() => handleSort("achievement")}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Achievement
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ backgroundColor: "#EEF0F4" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Total Row */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", backgroundColor: "#EEF0F4" }}>
              Total
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", backgroundColor: "#EEF0F4" }}
            >
              {sortedData.reduce(
                (sum, item) => sum + item.statistics.confirmed.count,
                0
              )}
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", backgroundColor: "#EEF0F4" }}
            >
              {sortedData.reduce(
                (sum, item) => sum + item.statistics.postponed.count,
                0
              )}
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", backgroundColor: "#EEF0F4" }}
            >
              {sortedData.reduce(
                (sum, item) => sum + item.statistics.completed.count,
                0
              )}
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", backgroundColor: "#EEF0F4" }}
            >
              {sortedData.reduce(
                (sum, item) => sum + item.statistics.not_completed.count,
                0
              )}
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", backgroundColor: "#EEF0F4" }}
            >
              {/* You can calculate an overall achievement if needed */}-
            </TableCell>
            <TableCell
              align="center"
              sx={{ backgroundColor: "#EEF0F4" }}
            ></TableCell>
          </TableRow>
          
          {sortedData.map((item) => {
            const confirmedCount = item.statistics.confirmed.count;
            const completedCount = item.statistics.completed.count;
            const achievementPercentage =
              confirmedCount > 0
                ? Math.round((completedCount / confirmedCount) * 100)
                : 0;

            return (
              <TableRow key={item.bdm_id}>
                <TableCell sx={{ backgroundColor: "#EEF0F4" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      cursor: "pointer",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    onClick={() => handleNav(item.bdm_id)}
                  >
                    {item.bdm_name}
                  </Typography>
                </TableCell>
                <TableCell align="center">{confirmedCount}</TableCell>
                <TableCell align="center">
                  {item.statistics.postponed.count}
                </TableCell>
                <TableCell align="center">{completedCount}</TableCell>
                <TableCell align="center">
                  {item.statistics.not_completed.count}
                </TableCell>
                <TableCell align="center">
                  {`${achievementPercentage}%`}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="View" arrow>
                    <IconButton
                      style={{
                        border: "1px solid",
                        borderRadius: "4px",
                        padding: "2px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#00A36C",
                      }}
                      onClick={() => handleViewClick(item.bdm_id)}
                    >
                      <VisibilityIcon
                        sx={{ fontSize: "1rem", color: "#fff" }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}

          
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default PivotTable;
