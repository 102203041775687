import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import WavesIcon from "@mui/icons-material/Waves";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LockIcon from "@mui/icons-material/Lock";
import { GET_LEAD_BY_AGENT_API, GET_LEAD_BY_BDM_API, PENDING_AND_COLD_LEAD } from "../../constants";
import axios from "axios";
import { Alert, AlertTitle, Button, Fab, Snackbar, Stack, useMediaQuery } from "@mui/material";
import WarmLeadTable from "./WarmLeadTable";
import ColdLeadTable from "./ColdLeadTable";
import PendingLeadTable from "./PendingLeadTable";
import ClosedLeadTable from "./ClosedLeadTable";
import AllLeadTable from "./AllLeadTable";
import Loader from "../reusable Component/Loader";
import ModalComponent from "../reusable Component/modalComponent";
import moment from "moment";
import HotLeadTable from "./HotLeadTable";
import { useTheme } from "@emotion/react";
import MobileWarmDatatable from "../MobileView/MobileWarmDatatable";
import MobileDatatable from "../MobileView/MobileDatatable";
import MobileColdDatatable from "../MobileView/MobileColdDatatable";
import MobilePendingDatatable from "../MobileView/MobilePendingDatable";
import MobileClosedDatatable from "../MobileView/MobileClosedDatatable";
import MobileAllDatatable from "../MobileView/MobileAllDatatable";
import AddIcon from "@mui/icons-material/Add";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function ZonalManagerComponent() {
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");

  const [loading, setLoading] = useState(false);

  const [runEffect, setRunEffect] = useState(false);

  const empId = localStorage.getItem("username");

  const [hotLeadCount, setHotCountLead] = useState(0);
  const [warmLeadCount, setWarmCountLead] = useState(0);
  const [coldLeadCount, setColdCountLead] = useState(0);
  const [pendingLeadCount, setPendingCountLead] = useState(0);
  const [closedLeadCount, setClosedCountLead] = useState(0);
  const [allLeadCount, setAllLeadCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    
    axios
      .get(`${GET_LEAD_BY_BDM_API}/${empId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { categoryStats, totalCount } = response.data;
        setHotCountLead(categoryStats.hot);
        setWarmCountLead(categoryStats.warm);
        setColdCountLead(categoryStats.cold);
        setClosedCountLead(categoryStats.closed);
        setPendingCountLead(categoryStats.pending);
        setAllLeadCount(totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenSnackbar(true);
        setLoading(false);
      });
  }, [runEffect]);

 
  


  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
    {loading && <Loader open={loading} />}
    <Box
      sx={{
        display: isMobile ? "flex" : "",
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "stretch" : "center",
        justifyContent: isMobile ? "center" : "space-between",
        padding: isMobile ? "0px" : "16px",
      }}
    >
      {/* Regular Button for non-mobile view */}
      {!isMobile && (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          sx={{
            fontSize: "1rem",
            fontWeight: "700",
            borderWidth: "1.5px",
            marginBottom: 0,
            float: "right",
          }}
        >
          + Add Lead
        </Button>
      )}

      {/* Floating Action Button for mobile view */}
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: `calc(16px + ${isMobile ? "56px" : "0px"})`,
            right: 16,
            zIndex: 10,
          }}
        >
          <Fab
            color="primary"
            aria-label="add lead"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "primary", // Customize FAB color
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
          >
            <AddIcon />
          </Fab>
        </Box>
      )}
      <ModalComponent
        open={open}
        modalTitle={"BDM Lead Generation"}
        setOpen={setOpen}
        rowDetails={{}}
        setOpenSnackbar={setOpenSnackbar}
        setSeverity={setSeverity}
        setMessage={setMessage}
      ></ModalComponent>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant={isMobile ? "scrollable" : "standard"} // Scrollable tabs on mobile
        scrollButtons="auto"
        sx={{
          flexGrow: 1,
          "& .MuiTab-root": {
            minWidth: isMobile ? 80 : 100, // Adjust min-width for tabs on mobile
            fontSize: "0.875rem",
            padding: "8px 6px",
          },
          "& .MuiTabs-indicator": {
    display: "none", // Hide the indicator line
  },
        }}
      >
        <Tab
          label={`Hot (${hotLeadCount})`}
          id="tab-0"
          icon={<LocalFireDepartmentIcon />}
          iconPosition="start"
          aria-controls="tabpanel-1"
        />
        <Tab
          label={`Warm (${warmLeadCount})`}
          id="tab-1"
          icon={<WavesIcon />}
          iconPosition="start"
          aria-controls="tabpanel-1"
        />
        <Tab
          label={`Cold (${coldLeadCount})`}
          id="tab-2"
          icon={<AcUnitIcon />}
          iconPosition="start"
          aria-controls="tabpanel-2"
        />
        <Tab
          label={`Pending (${pendingLeadCount})`}
          id="tab-3"
          icon={<PendingActionsIcon />}
          iconPosition="start"
          aria-controls="tabpanel-3"
        />
        <Tab
          label={`Closed (${closedLeadCount})`}
          id="tab-4"
          icon={<LockIcon />}
          iconPosition="start"
          aria-controls="tabpanel-4"
        />
        <Tab
          label={`All (${allLeadCount})`}
          id="tab-5"
          icon={<LockIcon />}
          iconPosition="start"
          aria-controls="tabpanel-5"
        />
      </Tabs>
    </Box>

    <TabPanel value={value} index={0}>
      {isMobile ? (
        <MobileDatatable setLoading={setLoading} empId={empId} />
      ) : (
        <HotLeadTable setLoading={setLoading} empId={empId}></HotLeadTable>
      )}
    </TabPanel>
    <TabPanel value={value} index={1}>
      {isMobile ? (
        <MobileWarmDatatable
          setLoading={setLoading}
          empId={empId}
        ></MobileWarmDatatable>
      ) : (
        <WarmLeadTable setLoading={setLoading} empId={empId} />
      )}
    </TabPanel>
    <TabPanel value={value} index={2}>
      {isMobile ? (
        <MobileColdDatatable
          setLoading={setLoading}
          empId={empId}
        ></MobileColdDatatable>
      ) : (
        <ColdLeadTable setLoading={setLoading} empId={empId} />
      )}
    </TabPanel>
    <TabPanel value={value} index={3}>
      {isMobile ? (
        <MobilePendingDatatable
          setLoading={setLoading}
          empId={empId}
        ></MobilePendingDatatable>
      ) : (
        <PendingLeadTable setLoading={setLoading} empId={empId} />
      )}
    </TabPanel>
    <TabPanel value={value} index={4}>
      {isMobile ? (
        <MobileClosedDatatable
          setLoading={setLoading}
          empId={empId}
        ></MobileClosedDatatable>
      ) : (
        <ClosedLeadTable setLoading={setLoading} empId={empId} />
      )}
    </TabPanel>
    <TabPanel value={value} index={5}>
      {isMobile ? (
        <MobileAllDatatable
          setLoading={setLoading}
          empId={empId}
        ></MobileAllDatatable>
      ) : (
        <AllLeadTable setLoading={setLoading} empId={empId} />
      )}
    </TabPanel>

    <Snackbar
      open={openSnackbar}
      autoHideDuration={3000} // Snackbar will auto hide after 6 seconds
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  </>
  );
}

export default ZonalManagerComponent;
