import React, { useState, useContext, useEffect } from "react";
// import Header from './HeaderComponent';
// import SidebarComponent from './SidebarComponent';
import BdmDashboardComponent from "./BdmDashboard/BdmDashboardComponent";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  AccountCircle,
  AccountCircleTwoTone,
  HistoryEdu,
} from "@mui/icons-material";
import { LoginContext } from "./LoginPage/LoginContext";
import { Avatar, Badge, CircularProgress, ListItemAvatar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Person2Icon from "@mui/icons-material/Person2";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import ib_logo from "../icons/IB_logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import Tooltip from "@mui/material/Tooltip";
import { NavLink as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../Actions/LoginAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import leadFormStyles from "./Leads Page/leadFormStyles.module.css";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { CALL_DISCONNECTION, GET_LEAD_BY_BDM_API, ROLES } from "../constants";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
import dayjs from "dayjs";
import MessageIcon from "@mui/icons-material/Message";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useSocket } from "./SocketContext";
import { emit } from "./eventEmitter";
import CloudIcon from "@mui/icons-material/Cloud";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#097969",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function MainComponent({ onLogout, user, logout }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();

  const socket = useSocket();

  useEffect(() => {
    if (!socket) return;

    console.log("MainComponent socket initialized:", socket);

    // Listener for incoming calls
    socket.on("incomingCall", (data) => {
      console.log("Incoming call received:", data);
      const currentAgent = JSON.parse(
        localStorage.getItem("userData")
      )?.EmployeePhone;

      if (
        data.agentNumber === currentAgent &&
        data.eventType === "incoming_call"
      ) {
        // Save incoming call data in localStorage
        const callStartTime = Date.now();
        localStorage.setItem("incomingCallData", JSON.stringify(data));
        localStorage.setItem("isIncoming", "true");
        localStorage.setItem("callId", data.callId);
        localStorage.setItem("phoneNumber", data.callerNumber);
        localStorage.setItem("isCalling", "true");
        localStorage.setItem("callStartTime", callStartTime);

        // Emit the "incomingCall" event instead of reloading
        emit("incomingCall", data);
        console.log("Emitting incoming call event:", data);

        // Check if the current path is /dashboard
        // if (location.pathname === "/dashboard") {
        //   window.location.reload(); // Reload the page
        // }
        // else {
        //   navigate("/dashboard");
        // }
      }
    });

    // Listener for incoming call status updates
    socket.on("incomingCallStatus", (data) => {
      console.log("Incoming call status update:", data);

      const currentAgent = JSON.parse(
        localStorage.getItem("userData")
      )?.EmployeePhone;
      const storedCallId = localStorage.getItem("callId");

      if (data.agentNumber === currentAgent && storedCallId === data.callId) {
        if (data.eventType === "oncallconnect") {
          // Update localStorage with connection status
          localStorage.setItem("incomingCallConnected", "true");
          localStorage.setItem("isConnected", "true");

          // Update webhook data if needed
          localStorage.setItem("B_DIAL_STATUS", "Connected");

          // Emit an event for the caller component
          emit("incomingCallStatusUpdate", { isConnected: true });
        } else if (data.eventType === "call_ended") {
          // Reset call state in localStorage on call end
          resetCallStates();
          emit("incomingCallStatusUpdate", { isConnected: false });
        }
      }
    });

    // Cleanup listener
    return () => {
      socket.off("incomingCall");
      socket.off("incomingCallStatus");
    };
  });

  // Function to reset call states in localStorage
  const resetCallStates = () => {
    // Clear localStorage values related to call state
    localStorage.removeItem("incomingCallData");
    localStorage.removeItem("isIncoming");
    localStorage.removeItem("incomingCallConnected");
    localStorage.removeItem("isConnected");
    localStorage.removeItem("callId");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("isCalling");
    localStorage.removeItem("refreshPanel");
    localStorage.removeItem("B_DIAL_STATUS");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleprofileNavigate = () => {
    navigate("/profile");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();

    navigate("/");
    navigate(0);
  };

  const [menuEl, setMenuEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleNotificationOpen = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setMenuEl(null);
  };

  useEffect(() => {
    // const empId = localStorage.getItem("username");
    // setLoading(true);
    // axios
    //   .get(`${GET_LEAD_BY_BDM_API}/${empId}`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    //     },
    //   })
    //   .then((response) => {
    //     const { leads } = response.data;
    //     // setNotifications(leads);
    //     handleNotification(leads);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching notifications:", error);
    //   });
  }, []);

  const handleNotification = (data) => {
    const today = dayjs().startOf("day");
    const filteredData = data.filter((rd) =>
      dayjs(rd.updatedAt).isSame(today, "day")
    );
    setNotifications(filteredData);
    setUnreadCount(filteredData.length);
  };

  const handleNotificationClick = (id) => {
    setUnreadCount(unreadCount - 1);
    navigate(`/bdmAssigned`);
    handleNotificationClose();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [bdmAssignedPage, setBdmAssignedPage] = useState(false);
  const [bdmToDoPage, setBdmToDoPage] = useState(false);
  const [leadpage, setLeadPage] = useState(false);
  const [supPage, setSupPage] = useState(false);
  const [auPage, setAuPage] = useState(false);
  const [biPage, setBipage] = useState(false);
  const [supbiPage, setSupBiPage] = useState(false);

  const [newOpen, setNewOpen] = useState(false);
  const toggleDrawer = () => {
    setNewOpen(true);
  };

  const storedRole = localStorage.getItem("empRole");

  useEffect(() => {
    if (storedRole == 2) {
      setBdmAssignedPage(true);
      setBdmToDoPage(true);
    }
    if (storedRole == 1) {
      setLeadPage(true);
    }
    if (storedRole == 5) {
      setSupPage(true);
    }
    if (storedRole == 201) {
      setSupBiPage(true);
    }
    if (storedRole == 200) {
      setBipage(true);
    }
    if (storedRole == 100) {
      setAuPage(true);
    }
  }, []);

  const path = window.location.pathname;

  const DrawerList = (
    <>
      <Box
        sx={{
          overflow: open ? "auto" : "hidden",
          backgroundColor: "#fff",
          height: "100%",
        }}
      >
        <List>
          {storedRole != 6 && (
            <ListItem
              key={"dashboard"}
              button
              component={RouterLink}
              to="/dashboard"
              disablePadding
              className={
                path === "/dashboard"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className={
                  path === "/dashboard" ? `${leadFormStyles.active}` : ``
                }
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Dashboard" arrow>
                  <SpeedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {(leadpage || storedRole == 3) && (
            <ListItem
              key={"lead"}
              disablePadding
              button
              component={RouterLink}
              to="/lead"
              className={
                path === "/lead"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className={path === "/lead" ? `${leadFormStyles.active}` : ``}
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Category-Wise Lead" arrow>
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"Category-Wise Lead"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {leadpage && (
            <>
              <ListItem
                key={"enquiry_data"}
                disablePadding
                button
                component={RouterLink}
                to="/enquiry_data"
                className={
                  path === "/enquiry_data"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="QR Enquiry Leads" arrow>
                    <QrCodeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"QR Enquiry Leads"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {bdmAssignedPage && (
            <ListItem
              key={"bdm_assigned"}
              disablePadding
              button
              component={RouterLink}
              to="/bdmAssigned"
              className={
                path === "/bdmAssigned"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Category-Wise Lead" arrow>
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"Category-Wise Lead"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {(bdmToDoPage || storedRole == 3)  && (
            <ListItem
              key={"bdm_todo"}
              disablePadding
              button
              component={RouterLink}
              to="/bdmtodo" // Ensure the route matches your routing setup
              className={
                path === "/bdmtodo"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="To Do List" arrow>
                  <CheckBoxOutlineBlankIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"To Do List"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {supbiPage && (
            <>
              <ListItem
                key="bi_Dayop"
                disablePadding
                button
                component={RouterLink}
                to="/biMaster"
                className={
                  path === "/biMaster"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ""
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={path === "/biMaster" ? leadFormStyles.active : ""}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="DayOp" arrow>
                    <PersonAddAltIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="DayOp" sx={{ opacity: open ? 1 : 0 }} />
              </ListItem>

              <ListItem
                key={"bi_trader"}
                disablePadding
                button
                component={RouterLink}
                className={
                  path == "/biMasterBrooding"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                to="/biMasterBrooding"
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={
                    path == "/biMasterBrooding"
                      ? `${leadFormStyles.active}`
                      : ``
                  }
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Brooding" arrow>
                    <ListIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Brooding"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {biPage && (
            <>
              <ListItem
                key={"bi_lead"}
                disablePadding
                button
                component={RouterLink}
                className={
                  path == "/biLead"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                to="/biLead"
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={
                    path == "/biLead" ? `${leadFormStyles.active}` : ``
                  }
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Day Op" arrow>
                    <CloudIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Day Op"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>

              <ListItem
                key={"bi_trader"}
                disablePadding
                button
                component={RouterLink}
                className={
                  path == "/biTraderList"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                to="/biTraderList"
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={
                    path == "/biTraderList" ? `${leadFormStyles.active}` : ``
                  }
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="BI" arrow>
                    <ListIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"BI"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItem>
            </>
          )}

          {supPage && (
            <>
              <ListItem
                key={"lead_master"}
                disablePadding
                button
                component={RouterLink}
                to="/leadMaster"
                className={
                  path === "/leadMaster"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Leads Master" arrow>
                    <PersonAddAltIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Leads Master"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {supPage && (
            <>
              <ListItem
                key={"lead_action"}
                disablePadding
                button
                component={RouterLink}
                to="/leadAction"
                className={
                  path === "/leadAction"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Action by BDM" arrow>
                    <DashboardIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Action by BDM"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {supPage && (
            <>
              <ListItem
                key={"bdm_report"}
                disablePadding
                button
                component={RouterLink}
                to="/bdmReport"
                className={
                  path === "/bdmReport"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="BDM Report" arrow>
                    <SummarizeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"BDM Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {supPage && (
            <>
              <ListItem
                key={"qr_report"}
                disablePadding
                button
                component={RouterLink}
                to="/qrReport"
                className={
                  path === "/qrReport"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="QR Enquiry Report" arrow>
                    <QrCodeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"QR Enquiry Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {supPage && (
            <>
              <ListItem
                key={"settings"}
                disablePadding
                button
                component={RouterLink}
                to="/settings"
                className={
                  path === "/settings"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Settings" arrow>
                    <SettingsIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Settings"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </>
          )}

          {auPage && (
            <>
              <ListItem
                key={"audit_lead"}
                disablePadding
                button
                component={RouterLink}
                to="/auditLead"
                className={
                  path === "/auditLead"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={
                    path === "/auditLead" ? `${leadFormStyles.active}` : ``
                  }
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Audit Lead" arrow>
                    <VerifiedUserIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Audit Lead"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>

              {/* <ListItem
                  key={'audit_trader'}
                  disablePadding
                  button
                  component={RouterLink}
                  to="/auditTraderList"
                  className={path === '/auditTraderList' ? `${leadFormStyles.active_back} ${leadFormStyles.active}` : ``}
                  sx={{
                    display: 'flex',
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    className={path === '/auditTraderList' ? `${leadFormStyles.active}` : ``}
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title="Trader" arrow>
                      <ListIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={'Trader'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItem> */}
            </>
          )}

          {storedRole === "101" && (
            <ListItem
              key={"auditMaster"}
              disablePadding
              button
              component={RouterLink}
              to="/auditMaster"
              className={
                path === "/auditMaster"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className={
                  path === "/auditMaster" ? `${leadFormStyles.active}` : ``
                }
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Audit Master" arrow>
                  <VerifiedUserIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"Audit Master"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {/* {storedRole == '101' && <ListItem key={'auditTraderMaster'} 
              disablePadding 
              button component={RouterLink} 
              className={path == '/auditTraderList' ? `${leadFormStyles.active_back} ${leadFormStyles.active}` : ``}
              to="/auditTraderList"
              sx={{ display: 'flex',
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
               }}>
                <ListItemIcon
                className={ (path == '/auditTraderList' ? `${leadFormStyles.active}` : ``)}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title="Trader" arrow>
                  <PersonIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={'Trader'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItem>} */}

          {storedRole === 6 && (
            <ListItem
              key={"ho_approval"}
              disablePadding
              button
              component={RouterLink}
              to="/estimateGeneration"
              className={
                path === "/estimateGeneration"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className={
                  path === "/estimateGeneration"
                    ? `${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Estimate List" arrow>
                  <DomainVerificationIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"P-Card Estimation Requested"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {storedRole == 6 && (
            <ListItem
              key={"ho_approval"}
              disablePadding
              button
              component={RouterLink}
              to="/estimateGeneration"
              className={
                path === "/estimateGeneration"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                className={
                  path === "/estimateGeneration"
                    ? `${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Estimate List" arrow>
                  <DomainVerificationIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"P-Card Estimation Requested"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          )}

          {/* {storedRole !== "101" &&
            storedRole !== "100" &&
            storedRole != 6 &&
            storedRole != 200 &&
            storedRole != 201 &&
            storedRole != 2 && (
              <ListItem
                key={"lead"}
                disablePadding
                button
                component={RouterLink}
                className={
                  path == "/leadActionWise"
                    ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                    : ``
                }
                to="/leadActionWise"
                sx={{
                  display: "flex",
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  className={
                    path == "/leadActionWise" ? `${leadFormStyles.active}` : ``
                  }
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Action-wise Leads" arrow>
                    <DashboardIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={"Action-Wise Lead"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            )} */}
        </List>
      </Box>
    </>
  );

  const roles =
    JSON.parse(localStorage.getItem("userData")) == null
      ? ""
      : ROLES.filter(
          (item) =>
            item.id == JSON.parse(localStorage.getItem("userData")).EmployeeRole
        );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          {open == false && (
            <img src={ib_logo} loading="lazy" alt="onCall" className={`w-10`} />
          )}

          <div style={{ marginLeft: "auto" }}>
            {localStorage.getItem("empRole") == 2 && (
              <>
                <IconButton color="inherit" onClick={handleNotificationOpen}>
                  <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={menuEl}
                  open={Boolean(menuEl)}
                  onClose={handleNotificationClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "40ch",
                    },
                  }}
                >
                  {loading ? (
                    <MenuItem>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : notifications.length === 0 ? (
                    <MenuItem onClick={handleClose}>
                      No new notifications
                    </MenuItem>
                  ) : (
                    notifications.map((notification) => (
                      <MenuItem
                        key={notification.id}
                        onClick={handleNotificationClick}
                      >
                        {/* New Lead Assigned !!
                Farmer Name: {notification.CustomerName}
                 */}
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar sx={{ marginTop: "15px" }}>
                            <Avatar>N</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={"New Lead Assigned !!"}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="subtitle2"
                                  color="text.primary"
                                >
                                  Farmer Name: {notification.CustomerName}
                                </Typography>
                                <br></br>
                                {`Mobile Number: ${notification.MobileNo}`}
                                <br></br>
                                {`Assigned By: ${
                                  notification.Agent == null
                                    ? notification.BDM.EmployeeName
                                    : notification.Agent.EmployeeName
                                }`}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </MenuItem>
                    ))
                  )}
                </Menu>
              </>
            )}

            <IconButton onClick={handleClick} color="inherit">
              <AccountCircleTwoTone sx={{ fontSize: "2rem !important" }} />
              <div className={`flex-row ml-1 mr-1`}>
                <p style={{ fontSize: "0.8rem", textTransform: "capitalize" }}>
                  {JSON.parse(localStorage.getItem("userData")) == null
                    ? ""
                    : JSON.parse(localStorage.getItem("userData")).EmployeeName}
                </p>
                <p style={{ fontSize: "0.6rem", textAlign: "left" }}>
                  {roles == "" ? "" : roles[0].role}
                </p>
              </div>
              <ExpandMoreIcon sx={{ fontSize: "1.2rem !important" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={`w-100`}
            >
              <MenuItem onClick={handleprofileNavigate}>
                <AccountCircle className={`mr-2`} />
                Profile
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <LogoutIcon className={`mr-2`} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer variant={"permanent"} open={open}>
        <DrawerHeader sx={{ placeContent: "space-between" }}>
          {open == true && (
            <img src={ib_logo} loading="lazy" alt="onCall" className={`w-10`} />
          )}
          <IconButton onClick={handleDrawerClose} sx={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {DrawerList}
      </Drawer>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
