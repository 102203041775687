import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "./mobileDatatableStyles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../LoginPage/LoginContext";
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import TimelineDialog from "../reusable Component/TimelineDialog";
import axios from "axios";
import { GET_LEAD_BY_BDM_API, GET_TIMELINE } from "../../constants";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LongTextComponent from "../Leads Page/LongTextComponent";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MobileDatatable = ({ empId, setLoading }) => {
  const navigate = useNavigate();
  const { setleadDetails } = useContext(LoginContext);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); // Page index (starts from 0 for MUI Pagination)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [totalCount, setTotalCount] = useState(0); // Total items
  const [expandedRows, setExpandedRows] = useState([]);

  const fetchHotLeadRowData = (page = 1, pageSize = 10) => {
    setLoading(true); // Set loading to true when starting the API call

    axios
      .get(
        `${GET_LEAD_BY_BDM_API}/${empId}?category=hot&page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const { leads, totalCount } = response.data;
        setData(leads); // Update the state with the fetched data
        setTotalCount(totalCount); // Update the total count for pagination
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching hot lead data:", error);
        setLoading(false);
      });
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  useEffect(() => {
    fetchHotLeadRowData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineLogs, setTimelineLogs] = useState([]);
  const [custData, setCustData] = useState([]);

  const handletimelineClick = (row) => {
    setTimelineOpen(true);
    setCustData(row);
    axios
      .get(`${GET_TIMELINE}/${row.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadLogs } = response.data;
        setTimelineLogs(leadLogs);
      })
      .catch((error) => {});
  };

  const handleRowClick = (index) => {
    const newExpandedRows = expandedRows.includes(index)
      ? expandedRows.filter((i) => i !== index)
      : [...expandedRows, index];
    setExpandedRows(newExpandedRows);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage); // Pagination starts from 1, DataTable starts from 0
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  const handleExport = () => {
    // Map the data into an array of objects for Excel export
    const exportData = data.map((row, index) => ({
      SNo: index + 1,
      "Last Action": row.last_action,
      "Last Action Date": row.updatedAt,
      "Lead Owner": row.lead_owner,
      "Farmer Name": row.CustomerName,
      "Mobile Number": row.MobileNo,
      Region: row.region_name,
      Category: row.category,
      "Sub-Category": row.sub_category,
      "CSE Remarks": row.agent_remark || "-",
      "BDM/BI Remarks": row.bdm_remark || "-",
      "City/Village/Location": row.location,
      State: row.state_name,
      "Mail ID": row.CustomerMailId,
      "Date of First Calling": row.createdDate,
      "Follow-up Date": row.follow_up_date,
      "Closure Month": row.close_month,
      "Date of Lead Transfer": row.lead_transfer_date,
      "Date of BDM Calling": row.bdm_calling_date,
      "Campaign Name": row.source_of_lead_generated,
      "CSE Name": row.AgentName,
      Project: row.Project,
      "Alternate Number": row.AlternateMobileNo,
      "Whatsapp Number": row.WhatsappNo,
    }));

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, "LeadsData.xlsx");
  };

  const IconCell = ({ row }) => (
    <>
      <Tooltip title="Timeline" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handletimelineClick(row)}
        >
          <VisibilityIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Edit" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handleIconClick(row)}
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );

  const handleIconClick = (rowData) => {
    setleadDetails(rowData);
    navigate("/mobilebdmpage");
  };

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "hot":
        return "error"; // React blue
      case "warm":
        return "warning"; // JavaScript yellow
      case "cold":
        return "info"; // UI dark blue
      case "pending":
        return "warning";
      default:
        return "default"; // Default gray
    }
  };

  
  const rowExpansionTemplate = (data) => (
    <>
      <Grid container spacing={1} p={1}>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Region:
          </Typography>
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {data.region_name}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Lead Owner:
          </Typography>
          <Typography variant="body2">{data.lead_owner}</Typography>
        </Grid>

        

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Location:
          </Typography>
          <Typography variant="body2">{data.location}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            State:
          </Typography>
          <Typography variant="body2">{data.state_name}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Mail ID:
          </Typography>
          <Typography variant="body2" sx={{textDecoration: 'none'}}>{data.CustomerMailId}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Date of First Calling:
          </Typography>
          <Typography variant="body2">{formatDate(data.createdAt)}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Follow-up Date:
          </Typography>
          <Typography variant="body2">{formatDate(data.follow_up_date)}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Closure Month:
          </Typography>
          <Typography variant="body2">{data.close_month}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Date of Lead Transfer:
          </Typography>
          <Typography variant="body2">{formatDate(data.lead_transfer_date)}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Date of BDM Calling:
          </Typography>
          <Typography variant="body2">{data.bdm_calling_date || '-'}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Campaign Name:
          </Typography>
          <Typography variant="body2">
            {data.source_of_lead_generated}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            CSE Name:
          </Typography>
          <Typography variant="body2">{data.AgentName}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Project:
          </Typography>
          <Typography variant="body2">{data.Project}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Alternate Mobile Number:
          </Typography>
          <Typography variant="body2">{data.AlternateMobileNo || '-'}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Whatsapp Number:
          </Typography>
          <Typography variant="body2">{data.WhatsappNo || '-'}</Typography>
        </Grid>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Category:
          </Typography>
          <Typography variant="body2">
            <Chip
              label={data.category}
              color={getColorForTag(data.category)}
              size="small"
              sx={{ textTransform: "capitalize" }}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Sub-Category:
          </Typography>
          <Typography variant="body2">{data.sub_category}</Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            Agent Remark:
          </Typography>
          <Typography variant="body2" sx={{textAlign: 'end'}}>
            <LongTextComponent
              text={data.agent_remark == null ? "-" : data.agent_remark}
              maxLength={50}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            variant="body2"
            className={`${leadFormStyles.label_margin}`}
          >
            BDM Remark:
          </Typography>
          <Typography variant="body2" sx={{textAlign: 'end'}}>
            <LongTextComponent
              text={data.agent_remark == null ? "-" : data.agent_remark}
              maxLength={50}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Paper>
        {/* Export Button */}
        
        {/* <IconButton
          onClick={handleExport}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "35px", // Increased width for a more comfortable click area
            height: "35px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            float: "right",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FileDownloadIcon /> 
        </IconButton> */}

        <TableContainer
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            margin: "8px 0",
          }}
        >
          <Table
            sx={{
              minWidth: 250,
              height: "100%",
              tableLayout: "fixed", // Ensures equal column width distribution
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  align="left"
                  sx={{
                    padding: 0,
                    fontWeight: "bold",
                    width: 10,
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: 1,
                    width: 30,
                    fontWeight: "bold",
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Farmer Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: 1,
                    fontWeight: "bold",
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: 1,
                    fontWeight: "bold",
                    backgroundColor: "#EEF0F4",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Data Available Yet.
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <React.Fragment key={row.SNo}>
                    <TableRow sx={{ cursor: "pointer" }}>
                      {/* Expand Action */}
                      <TableCell align="left" sx={{ padding: 0 }}>
                        <button onClick={() => handleRowClick(index)}>
                          {expandedRows.includes(index) ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowRightIcon />
                          )}
                        </button>
                      </TableCell>

                      {/* Other Columns */}
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="center" sx={{wordBreak: 'break-word'}}>{row.CustomerName}</TableCell>
                      <TableCell align="center" sx={{textDecoration: 'none'}}>{row.MobileNo}</TableCell>
                      <TableCell align="center" sx={{backgroundColor: "#EEF0F4",}}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <IconCell row={row} />
                        </Box>
                      </TableCell>
                    </TableRow>

                    {/* Collapse Row */}
                    {expandedRows.includes(index) && (
                      <TableRow>
                        <TableCell colSpan={5} sx={{ padding: 0 }}>
                          <Collapse in={expandedRows.includes(index)}>
                            <Box
                              sx={{
                                padding: 2,
                                overflowX: "auto", // Add horizontal scrolling if content exceeds container width
                              }}
                            >
                              {rowExpansionTemplate(row)}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount} // Use the total count from API
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      <TimelineDialog
        open={timelineOpen}
        setOpen={setTimelineOpen}
        timelineLogs={timelineLogs}
        custData={custData}
      ></TimelineDialog>
    </>
  );
};

export default MobileDatatable;
