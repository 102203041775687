import React, { createContext, useState , useEffect,} from 'react';


export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [empDetails, setEmpDetails] = useState([]);
  const [leadDetails, setLeadDetails] = useState([]);


  const login = (role, userData) => {
    // Perform your login logic here...
    // For demonstration, let's assume login is successful if username and password are not empty
    const loginTime = new Date().getTime();
    setEmpDetails(userData);
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('loginTime', loginTime);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('loginTime');
    localStorage.removeItem('leadDetails');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
  }

  const setleadDetails = (data) =>{
  // Update lead details in local storage
  localStorage.setItem('leadDetails', JSON.stringify(data));
                
 
  }



  return (
    <LoginContext.Provider value={{ isLoggedIn, login, logout, empDetails, setleadDetails, leadDetails , setIsLoggedIn}}>
      {children}
    </LoginContext.Provider>
  );
};