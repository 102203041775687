import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  Container,
  Divider,
  Paper,
  Collapse,
  Avatar,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import axios from "axios";
import CallEndIcon from "@mui/icons-material/CallEnd";
import CallIcon from "@mui/icons-material/Call";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  B_PARTY_CONNECTION,
  CALL_DISCONNECTION,
  CALL_END_STATUS,
  CALL_INITIATE,
  HOLD_OR_RESUME,
  INCOMING_CALL,
  // fetchCallEndStatus,
  MERGE_CALL,
} from "../../constants";
import ModalComponent from "../reusable Component/modalComponent";
import { useSocket } from "../SocketContext";
import { off, on } from "../eventEmitter";

// WebhookDataPanel Component
const WebhookDataPanel = ({ webhookData, isExpanded, onToggle }) => {
  if (!webhookData) return null;
};
function CallerComponent({
  tableMobile,
  setFormOpen,
  setFormMobileNo,
  auditMobNo,
  setAuditMobNum
}) {
  // Socket related states
  // const [socket, setSocket] = useState(null);

  const [webhookData, setWebhookData] = useState(null);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(true);
  const [callIdMatched, setCallIdMatched] = useState(false);
  const [isIncoming, setIsIncoming] = useState(false);
  const [incomingCallData, setIncomingCallData] = useState(null);
  const [incomingCallConnected, setIncomingCallConnected] = useState(false);
  // Existing states
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isCalling, setIsCalling] = useState(false);
  const [timer, setTimer] = useState(0);
  const [error, setError] = useState(null);
  const [isOnHold, setIsOnHold] = useState(false);
  const [callId, setCallId] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [connectionIntervalId, setConnectionIntervalId] = useState(null);
  const [callEndIntervalId, setCallEndIntervalId] = useState(null);
  const [ringingIntervalId, setRingingIntervalId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [isConferenceActive, setIsConferenceActive] = useState(false);
  const [conferenceNumber, setConferenceNumber] = useState("");
  const [activeField, setActiveField] = useState("phoneNumber");
  const [isConferenceAdded, setIsConferenceAdded] = useState(false);
  const [conferenceParticipants, setConferenceParticipants] = useState([]);
  // UI states
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  // Constants
  const bearerToken = localStorage.getItem("clickToCallToken");
  const aPartyNo = JSON.parse(localStorage.getItem("userData"));
  const empRole = localStorage.getItem("empRole");
  const cliNumber = empRole == 1 ? "8517009997" : "8517009998";
  // Add audioRef to your component
  const audioRef = useRef(null);

  const [mobNo, setMobNo] = useState("");

  const socket = useSocket();

  // Initialize Socket.IO connection
  // useEffect(() => {

  //   const newSocket = io("https://crmapi.abisexport.com", {
  //     transports: ["websocket"],
  //     reconnection: true,
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000,
  //   });
  //   newSocket.on("connect", () => {
  //     console.log("Connected to WebSocket server-1");
  //     setSocket(newSocket);
  //   });
  //   newSocket.on("connect_error", (error) => {
  //     console.error("Socket connection error:", error);
  //     setError("Unable to connect to call service");
  //   });
  //   return () => {
  //     if (newSocket) {
  //       newSocket.disconnect();
  //     }
  //   };
  // }, []);

  const [timeoutId, setTimeoutId] = useState(null); // Track the 18-second timeout

  // useEffect(() => {
  //   // Retrieve stored incoming call data on component mount
  //   const storedCallData = JSON.parse(localStorage.getItem("incomingCallData"));
  //   const storedIsIncoming = localStorage.getItem("isIncoming") === "true";
  //   const storedIsConnected = localStorage.getItem("isConnected") === "true";
  //   const callStartTime = parseInt(localStorage.getItem("callStartTime"), 10);

  //   const storedincomingCallConnected =
  //     localStorage.getItem("incomingCallConnected") === "true";

  //   if (storedCallData) {
  //     if (storedincomingCallConnected) {
  //       setIncomingCallConnected(storedincomingCallConnected);
  //       setInterval(() => {
  //         setTimer((prev) => prev + 1);
  //       }, 1000);
  //     } else {
  //       setIsConnected(storedIsConnected);

  //       // Calculate remaining time for the 18-second disconnect logic
  //       const remainingTime = 18000 - (Date.now() - callStartTime);
  //       if (remainingTime > 0) {
  //         const timer = setTimeout(() => {
  //           console.log("No response within 18 seconds, attempting to disconnect call");
  //           handleEndCallClick();
  //           // emit("incomingCallStatusUpdate", { isConnected: false });
  //         }, remainingTime);

  //         // Save timer ID to clear if the call connects before timeout
  //         setTimeoutId(timer);
  //       }
  //     }
  //     setIncomingCallData(storedCallData);
  //     setCallId(storedCallData.callId);
  //     setIsIncoming(storedIsIncoming);
  //     // setFormMobileNo(storedCallData.callerNumber);
  //     setAuditMobNum(storedCallData.callerNumber);
  //     // setFormOpen(true);
  //   }
  // }, []);

  useEffect(() => {
    
    const handleIncomingCall = (data) => {
      console.log("Handle Incoming Call Triggered", data);  // Add this log
      // Retrieve stored incoming call data on component mount
    const storedCallData = JSON.parse(localStorage.getItem("incomingCallData"));
    const storedIsIncoming = localStorage.getItem("isIncoming") === "true";
    const storedIsConnected = localStorage.getItem("isConnected") === "true";
    const callStartTime = parseInt(localStorage.getItem("callStartTime"), 10);

    const storedincomingCallConnected =
      localStorage.getItem("incomingCallConnected") === "true";

     if (storedCallData) {
      if (storedincomingCallConnected) {
        setIncomingCallConnected(storedincomingCallConnected);
        setIncomingCallData(storedCallData);
        setInterval(() => {
          setTimer((prev) => prev + 1);
        }, 1000);
      } else {
        setIsConnected(storedIsConnected);
      }
      setIncomingCallData(storedCallData);
      setCallId(storedCallData.callId);
      setIsIncoming(storedIsIncoming);
      // setFormMobileNo(storedCallData.callerNumber);
      setAuditMobNum(storedCallData.callerNumber);
      // setFormOpen(true);
    }
    };


    const handleIncomingCallStatusUpdate = (data) => {
      const storedincomingCallConnected =
        localStorage.getItem("incomingCallConnected") === "true";
      const storedIsConnected = localStorage.getItem("isConnected") === "true";
      const storedIsIncoming = localStorage.getItem("isIncoming") === "true";
      const storedCallData = JSON.parse(
        localStorage.getItem("incomingCallData")
      );
      setIncomingCallConnected(storedincomingCallConnected);
      setIsConnected(storedIsConnected);
      setIsIncoming(storedIsIncoming);
      setWebhookData((prev) => ({
        ...prev,
        B_DIAL_STATUS: localStorage.getItem("B_DIAL_STATUS"),
      }));

      if (storedCallData) {
        clearTimeout(timeoutId);
        setFormMobileNo(storedCallData.callerNumber);
        setFormOpen(true);
      }
    };

    // Listen for incoming call status updates
    on("incomingCall", handleIncomingCall);
    on("incomingCallStatusUpdate", handleIncomingCallStatusUpdate);

    // Cleanup listener on unmount
    return () => {
      console.log("Cleaning up incoming call listener");
      off("incomingCall", handleIncomingCall);
      off("incomingCallStatusUpdate", handleIncomingCallStatusUpdate);
    };
  }, [socket]);

  const renderIncomingCallUI = () => (
    <Box
      p={3}
      sx={{
        maxWidth: { xs: "100%", sm: "250px", md: "250px" },
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: { xs: "300px", sm: "400px", md: "400px" },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {!incomingCallConnected && (
          <AccountCircleIcon
            sx={{
              width: "50%",
              height: "30%",
              margin: "0 auto",
              mb: 2, // margin bottom for spacing
              color: "primary.main", // Icon color
            }}
          />
        )}

        <Typography
          variant="h5"
          gutterBottom
          color={incomingCallConnected ? "success.main" : "primary"}
          sx={{
            animation: isRinging ? "blink 1s infinite" : "none",
            "@keyframes blink": {
              "0%": { opacity: 1 },
              "50%": { opacity: 0.5 },
              "100%": { opacity: 1 },
            },
            fontWeight: 600, // Make the font bold
          }}
        >
          {incomingCallConnected ? "Incoming Connected" : "Incoming Call"}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {incomingCallData?.callerNumber}
        </Typography>

        {/* <Typography variant="body1" color="text.secondary" gutterBottom>
          Call ID: {incomingCallData?.callId}
        </Typography> */}

        {incomingCallConnected && (
          <Typography variant="h6" gutterBottom>
            {formatTime(timer)}
          </Typography>
        )}
      </Box>

      {/* Check if conference is active to determine which buttons to show */}
      {incomingCallConnected && !isConferenceActive && (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            sx={{ marginTop: "8px" }}
          >
            {/* Hold/Resume Button */}
            <Grid item>
              <IconButton
                color="primary"
                size="large"
                sx={{
                  borderRadius: "50%",
                  width: { xs: "40px", sm: "50px" },
                  height: { xs: "40px", sm: "50px" },
                  fontSize: "20px",
                  marginBottom: 1,
                  borderColor: isOnHold ? "orange" : "#097969",
                  border: "1px solid",
                  color: isOnHold ? "orange" : "#097969",
                }}
                onClick={handleHoldClick}
                disabled={webhookData?.B_DIAL_STATUS !== "Connected"}
              >
                {isOnHold ? <PlayArrowOutlinedIcon /> : <PauseOutlinedIcon />}
              </IconButton>
            </Grid>
            {/* Conference Call Button */}
            <Grid item>
              <IconButton
                color="primary"
                size="large"
                sx={{
                  borderRadius: "50%",
                  width: { xs: "40px", sm: "50px" },
                  height: { xs: "40px", sm: "50px" },
                  borderColor: "#097969",
                  border: "1px solid",
                }}
                onClick={handleCallConference}
                disabled={
                  webhookData?.B_DIAL_STATUS !== "Connected" ||
                  isConferenceAdded
                }
              >
                <AddIcCallOutlinedIcon />
              </IconButton>
            </Grid>
            {/* Merge Call Button */}
            <Grid item>
              <IconButton
                color="primary"
                size="large"
                sx={{
                  borderRadius: "50%",
                  width: { xs: "40px", sm: "50px" },
                  height: { xs: "40px", sm: "50px" },
                  borderColor: "#097969",
                  border: "1px solid",
                }}
                disabled={true}
              >
                <MergeTypeIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}

      {/* Conference Input Section */}
      {isConferenceActive && (
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Add Participant:
          </Typography>
          <TextField
            fullWidth
            value={conferenceNumber}
            onFocus={() => setActiveField("conferenceNumber")}
            variant="outlined"
            placeholder="Enter Number"
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={handleAddConferenceParticipant}
            disabled={conferenceNumber.length !== 10}
          >
            Add to Call
          </Button>
        </Box>
      )}

      {/* End Call Button - always visible */}
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleEndCallClick}
        startIcon={<CallEndIcon />}
        size="small"
        sx={{
          marginTop: 1,
          fontSize: "1rem",
          fontWeight: "bold",
          bgcolor: "error.main", // Ensure the button color matches your theme
          "&:hover": {
            bgcolor: "error.dark", // Darken on hover
          },
        }}
      >
        End Call
      </Button>
    </Box>
  );

  const handleAcceptCall = () => {
    try {
      setIsIncoming(false);
      setIsConnected(true);
      setTimer(0);
      setIsCalling(true);

      // Emit socket event for call acceptance
      if (socket?.connected) {
        socket.emit("acceptCall", {
          callId: incomingCallData?.callId,
          agentNumber: JSON.parse(localStorage.getItem("userData"))
            ?.EmployeePhone,
        });
      }

      // You can add API call here if needed
      // const response = await axios.post(ACCEPT_CALL_ENDPOINT, {
      //   callId: incomingCallData?.callId,
      //   agentNumber: JSON.parse(localStorage.getItem("userData"))?.EmployeePhone,
      // });
    } catch (error) {
      console.error("Error accepting call:", error);
      setError("Unable to accept call");
    }
  };

  // Reject Call Handler
  const handleRejectCall = () => {
    try {
      setIsIncoming(false);
      setIncomingCallData(null);
      setIsCalling(false);
      setCallId("");
      setPhoneNumber("");

      // Emit socket event for call rejection
      if (socket?.connected) {
        socket.emit("rejectCall", {
          callId: incomingCallData?.callId,
          agentNumber: JSON.parse(localStorage.getItem("userData"))
            ?.EmployeePhone,
        });
      }

      // You can add API call here if needed
      // const response = await axios.post(REJECT_CALL_ENDPOINT, {
      //   callId: incomingCallData?.callId,
      //   agentNumber: JSON.parse(localStorage.getItem("userData"))?.EmployeePhone,
      // });
    } catch (error) {
      console.error("Error rejecting call:", error);
      setError("Unable to reject call");
    }
  };

  useEffect(() => {
    if (!socket) return;
    socket.on("callStatusUpdate", (data) => {
      console.log("Webhook data received in frontend:", data);

      // Check if the callId matches
      if (data.CALL_ID === String(callId)) {
        setWebhookData(data);
        setCallIdMatched(true);

        // Handle different event types
        switch (data.EVENT_TYPE) {
          case "B party Connected/Notconnected":
            if (data.B_DIAL_STATUS === "Connected") {
              setIsConnected(true);
              // Start timer when call is connected
              setTimer(0);
            } else {
              setIsConnected(false);
            }
            break;
          case "Call End":
            // Handle call end
            resetCallStates();

            handleEndCallClick();
            break;
          default:
            break;
        }
      } else {
        console.log("Call ID mismatch:", {
          webhook: data.CALL_ID,
          current: callId,
        });
        setCallIdMatched(false);
      }
    });
    return () => {
      socket.off("callStatusUpdate");
    };
  }, [socket, callId]);
  useEffect(() => {
    let timerId;

    // Only start timer when connected status is confirmed by webhook
    if (webhookData?.B_DIAL_STATUS === "Connected" && callIdMatched) {
      timerId = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [webhookData?.B_DIAL_STATUS, callIdMatched]);

  const getCallStatus = () => {
    if (webhookData && callIdMatched) {
      if (webhookData.EVENT_TYPE === "B party Connected/Notconnected") {
        return webhookData.B_DIAL_STATUS === "Connected"
          ? "Connected"
          : "Connecting...";
      }
    }
    return isOnHold
      ? "On Hold..."
      : isDisconnecting
      ? "Disconnecting..."
      : isConferenceAdded
      ? "Conference Active"
      : isConnected
      ? "Connected"
      : "Calling...";
  };
  <Typography variant="h5" gutterBottom>
    {getCallStatus()}
  </Typography>;
  const ConnectionStatus = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        mb: 2,
      }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: "50%",
          backgroundColor:
            webhookData?.B_DIAL_STATUS === "Connected"
              ? "success.main"
              : "warning.main",
        }}
      />
      <Typography
        variant="body2"
        color={
          webhookData?.B_DIAL_STATUS === "Connected"
            ? "success.main"
            : "text.secondary"
        }
      >
        {webhookData?.B_DIAL_STATUS || "Waiting for connection..."}
      </Typography>
    </Box>
  );
  // Add this inside your active call UI section
  <Box sx={{ flexGrow: 1 }}>
    {webhookData && callIdMatched && <ConnectionStatus />}
    <Typography variant="h5" gutterBottom>
      {getCallStatus()}
    </Typography>
    <Typography variant="h5" gutterBottom>
      {isConferenceAdded
        ? `${phoneNumber} + ${conferenceParticipants.join(" + ")}`
        : phoneNumber}
    </Typography>
    <Typography variant="h6" gutterBottom>
      {isConnected || webhookData?.B_DIAL_STATUS === "Connected"}
    </Typography>
  </Box>;

  // Initial setup effects
  useEffect(() => {
    if (tableMobile) setPhoneNumber(tableMobile);
  }, [tableMobile]);
  useEffect(() => {
    if (auditMobNo) setPhoneNumber(auditMobNo);
  }, [auditMobNo]);
  // Timer effect
  useEffect(() => {
    let timerId;
    if (isConnected || isRinging) {
      timerId = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timerId);
  }, [isConnected, isRinging]);
  // Call handling functions
  const handleCallClick = async () => {
    setError(null);
console.log("Call Click");

    if (!socket?.connected) {
      setError("Call service not connected");
      return;
    }
    try {
      const response = await axios.post(
        CALL_INITIATE,
        {
          cli: cliNumber,
          apartyno: aPartyNo.EmployeePhone,
          // apartyno: "7880164063",
          bpartyno: phoneNumber,
          reference_id: "123",
          dtmfflag: 0,
          recordingflag: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const message = response.data?.message;
      if (
        message?.Response ===
          "Unable to initiate call now. maximum channel limit reached" ||
        message?.Response === "Agent not available"
      ) {
        console.log('----------', message?.Response);
        
        setError("Please try again in a few minutes");
        // setTimeout(() => window.location.reload(), 3000);
        return;
      }
      if (message?.callid) {
        setCallId(message.callid);
        setIsCalling(true);
        setFormMobileNo(phoneNumber);
        setFormOpen(true);
        // Register call with socket
        socket.emit("registerCall", {
          callId: message.callid,
          phoneNumber: phoneNumber,
        });
        // Start connection status check
        const intervalId = setInterval(fetchConnectionStatus, 1000);
        setConnectionIntervalId(intervalId);
      } else {
        throw new Error("Invalid response from call service");
      }
    } catch (error) {
      console.error("Error initiating call:", error);
      setError("Unable to place call. Please try again.");
    }
  };
  const fetchConnectionStatus = async () => {
    if (!callId) return;
    try {
      const response = await axios.get(
        `${B_PARTY_CONNECTION}${callId}/b-party-connection`
      );
      console.log("Connection Status:", response.data);
      if (response.data.bDialStatus === "Connected" && !isConnected) {
        setIsConnected(true);
        setTimer(0);
        if (connectionIntervalId) clearInterval(connectionIntervalId);
        // const newIntervalId = setInterval(fetchCallEndStatus, 1000);
        // setCallEndIntervalId(newIntervalId);
      } else if (response.data.bDialStatus === "Disconnected") {
        setIsCalling(false);
        setIsConnected(false);
        clearInterval(connectionIntervalId);
        setConnectionIntervalId(null);
      }
    } catch (error) {
      console.error("Error fetching connection status:", error);
    }
  };

  const handleEndCallClick = async () => {
    setIsDisconnecting(true);

    try {
      await axios.post(
        CALL_DISCONNECTION,
        {
          cli: cliNumber,
          call_id: String(callId),
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Reset all states
      resetCallStates();
    } catch (error) {
      console.error("Error ending call:", error);
      setError("Unable to end call properly");
    } finally {
      setIsDisconnecting(false);
    }
  };
  const resetCallStates = () => {
    setIsCalling(false);
    setIsConnected(false);
    setPhoneNumber("");
    setIncomingCallConnected(false);
    setTimer(0);
    setIsOnHold(false);
    setCallId("");
    setConferenceParticipants([]);
    setIncomingCallData(null);
    setIsConferenceActive(false);
    setIsConferenceAdded(false);
    setWebhookData(null);
    setCallIdMatched(false);
    setIsIncoming(false);
    setFormMobileNo("");

    // Clear localStorage values related to call state
    localStorage.removeItem("incomingCallData");
    localStorage.removeItem("isIncoming");
    localStorage.removeItem("incomingCallConnected");
    localStorage.removeItem("isConnected");
    localStorage.removeItem("callId");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("isCalling");
    localStorage.removeItem("refreshPanel");
    localStorage.removeItem("B_DIAL_STATUS");
    localStorage.removeItem("callStartTime")

    // Clear any existing intervals
    if (connectionIntervalId) {
      clearInterval(connectionIntervalId);
      setConnectionIntervalId(null);
    }
    if (callEndIntervalId) {
      clearInterval(callEndIntervalId);
      setCallEndIntervalId(null);
    }
    if (ringingIntervalId) {
      clearInterval(ringingIntervalId);
      setRingingIntervalId(null);
    }
  };
  const CallTimer = () => {
    if (!webhookData?.B_DIAL_STATUS === "Connected" || !callIdMatched) {
      return null;
    }

    return (
      <Typography variant="h6" gutterBottom>
        {formatTime(timer)}
      </Typography>
    );
  };

  const handleHoldClick = async () => {
    try {
      await axios.post(
        HOLD_OR_RESUME,
        {
          cli: cliNumber,
          call_id: String(callId),
          HoldorResume: isOnHold ? "0" : "1",
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsOnHold(!isOnHold);

      if (socket?.connected) {
        socket.emit("holdStatusChange", {
          callId,
          isOnHold: !isOnHold,
        });
      }
    } catch (error) {
      console.error("Error toggling hold:", error);
      setError("Unable to change hold status");
    }
  };
  const handleCallConference = () => {
    setIsConferenceActive(!isConferenceActive);
    setActiveField("conferenceNumber");
  };
  const handleAddConferenceParticipant = async () => {
    if (conferenceNumber.length !== 10) {
      setError("Please enter a valid 10-digit number");
      return;
    }
    try {
      const response = await axios.post(
        MERGE_CALL,
        {
          cli: cliNumber,
          call_id: String(callId),
          cparty_number: conferenceNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Conference added") {
        setIsConferenceAdded(true);
        setIsConferenceActive(false);
        setConferenceParticipants((prev) => [...prev, conferenceNumber]);
        setConferenceNumber("");
        if (socket?.connected) {
          socket.emit("conferenceAdded", {
            callId,
            conferenceNumber,
          });
        }
      } else {
        throw new Error("Conference addition failed");
      }
    } catch (error) {
      console.error("Error in conference call:", error);
      setError("Unable to add conference participant");
      setIsConferenceAdded(false);
      setIsConferenceActive(false);
    }
  };
  // Updated handleButtonClick function for validation
  const handleButtonClick = (value) => {
    if (activeField === "phoneNumber" && phoneNumber.length < 10) {
      setPhoneNumber((prev) => prev + value);
    } else if (
      activeField === "conferenceNumber" &&
      conferenceNumber.length < 10
    ) {
      setConferenceNumber((prev) => prev + value);
    }
  };
  const handleDelete = () => {
    if (activeField === "phoneNumber") {
      setPhoneNumber((prev) => prev.slice(0, -1));
    } else {
      setConferenceNumber((prev) => prev.slice(0, -1));
    }
  };
  // Adjusted handleKeyDown for 10-digit validation
  const handleKeyDown = (event) => {
    const key = event.key;

    if (key === "Backspace") {
      event.preventDefault();
      handleDelete();
      return;
    }

    // Allow only * and # key, prevent default for these characters only
    if (/[*#]/.test(key)) {
      event.preventDefault();
      if (phoneNumber.length < 10) {
        handleButtonClick(key);
      }
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };
  const handleRingingEndCall = () => {
    setIsRinging(false);
    setPhoneNumber("");
    setWebhookData(null);
    setCallIdMatched(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;

    // Only allow digits, *, and #
    if (/^[0-9*#]*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // Render function
  return (
    <>
      <Container onKeyDown={handleKeyDown} maxWidth="xs">
        <Box
          p={2}
          sx={{
            border: "1px solid",
            borderRadius: "10px",
            boxShadow: 3,
            backgroundColor: "background.paper",
            maxWidth: { xs: "100%", sm: "300px", md: "250px", xl: "250px" },
            width: "100%",
            margin: "auto",
          }}
        >
          {/* Conditional Rendering based on call state */}
          {isIncoming ? (
            // Incoming Call UI
            renderIncomingCallUI()
          ) : isCalling ? (
            // Active Call UI
            <Box
              p={3}
              sx={{
                backgroundColor: "background.paper",
                maxWidth: { xs: "100%", sm: "250px", md: "250px" },
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                {/* Call Status */}
                <Typography variant="h5" gutterBottom>
                  {webhookData?.B_DIAL_STATUS === "Connected"
                    ? "Outgoing Connected"
                    : isOnHold
                    ? "On Hold..."
                    : isDisconnecting
                    ? "Disconnecting..."
                    : isConferenceAdded
                    ? "Conference Active"
                    : "Calling..."}
                </Typography>
                {/* Phone Number Display */}
                <Typography variant="h5" gutterBottom>
                  {isConferenceAdded
                    ? `${phoneNumber} + ${conferenceParticipants.join(" + ")}`
                    : phoneNumber}
                </Typography>
                {/* Timer Display */}
                {webhookData?.B_DIAL_STATUS === "Connected" && (
                  <Typography variant="h6" gutterBottom>
                    {formatTime(timer)}
                  </Typography>
                )}
              </Box>
              {/* Call Control Buttons */}
              {!isConferenceActive && (
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  sx={{ marginTop: "8px" }}
                >
                  {/* Hold/Resume Button */}
                  <Grid item>
                    <IconButton
                      color="primary"
                      size="large"
                      sx={{
                        borderRadius: "50%",
                        width: { xs: "40px", sm: "50px" },
                        height: { xs: "40px", sm: "50px" },
                        fontSize: "20px",
                        marginBottom: 1,
                        borderColor: isOnHold ? "orange" : "#097969",
                        border: "1px solid",
                        color: isOnHold ? "orange" : "#097969",
                      }}
                      onClick={handleHoldClick}
                      disabled={!webhookData?.B_DIAL_STATUS === "Connected"}
                    >
                      {isOnHold ? (
                        <PlayArrowOutlinedIcon />
                      ) : (
                        <PauseOutlinedIcon />
                      )}
                    </IconButton>
                  </Grid>
                  {/* Conference Call Button */}
                  <Grid item>
                    <IconButton
                      color="primary"
                      size="large"
                      sx={{
                        borderRadius: "50%",
                        width: { xs: "40px", sm: "50px" },
                        height: { xs: "40px", sm: "50px" },
                        borderColor: "#097969",
                        border: "1px solid",
                      }}
                      onClick={handleCallConference}
                      disabled={
                        !webhookData?.B_DIAL_STATUS === "Connected" ||
                        isConferenceAdded
                      }
                    >
                      <AddIcCallOutlinedIcon />
                    </IconButton>
                  </Grid>
                  {/* Merge Call Button */}
                  <Grid item>
                    <IconButton
                      color="primary"
                      size="large"
                      sx={{
                        borderRadius: "50%",
                        width: { xs: "40px", sm: "50px" },
                        height: { xs: "40px", sm: "50px" },
                        borderColor: "#097969",
                        border: "1px solid",
                      }}
                      disabled={!isConferenceAdded}
                    >
                      <MergeTypeIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {/* Conference Input Section */}
              {isConferenceActive && (
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Add Participant:
                  </Typography>
                  <TextField
                    fullWidth
                    value={conferenceNumber}
                    onFocus={() => setActiveField("conferenceNumber")}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/\D/g, ""); // Only numbers
                      if (inputValue.length <= 10) {
                        setConferenceNumber(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                    onPaste={(e) => {
                      const pasteData = e.clipboardData
                        .getData("text")
                        .replace(/\D/g, ""); // Only numbers
                      if (pasteData.length <= 10) {
                        e.preventDefault();
                        setConferenceNumber(pasteData);
                      } else {
                        e.preventDefault(); // Ignore if pasted data exceeds limit
                      }
                    }}
                    variant="outlined"
                    placeholder="Enter Number"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    onClick={handleAddConferenceParticipant}
                    disabled={conferenceNumber.length !== 10}
                  >
                    Add to Call
                  </Button>
                </Box>
              )}
              {/* End Call Button */}
              <Button
                variant="contained"
                color="error"
                size="medium"
                sx={{
                  marginTop: "8px",
                  width: "100%",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
                startIcon={<CallEndIcon />}
                onClick={handleEndCallClick}
              >
                End Call
              </Button>
            </Box>
          ) : (
            // Dialer UI
            <Box>
              {/* Number Input Field */}
              <Grid container alignItems="center">
                <Grid item xs>
                  <TextField
                    fullWidth
                    value={phoneNumber}
                    onFocus={() => setActiveField("phoneNumber")}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/\D/g, ""); // Only numbers
                      if (inputValue.length <= 10) {
                        setPhoneNumber(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                    onPaste={(e) => {
                      const pasteData = e.clipboardData
                        .getData("text")
                        .replace(/\D/g, ""); // Only numbers
                      if (pasteData.length <= 10) {
                        e.preventDefault();
                        setPhoneNumber(pasteData);
                      } else {
                        e.preventDefault(); // Ignore if pasted data exceeds limit
                      }
                    }}
                    variant="standard"
                    placeholder="Enter Number"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleDelete}
                          disabled={!phoneNumber}
                        >
                          <BackspaceIcon />
                        </IconButton>
                      ),
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ borderColor: "grey.600", width: "100%" }} />
              {/* Dialer Pad */}
              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((number) => (
                  <Grid item xs={4} key={number}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => handleButtonClick(number)}
                      sx={{
                        borderRadius: "50%",
                        height: "50px",
                        fontSize: "1rem",
                        backgroundColor: "grey.200",
                        boxShadow: 3,
                        color: "grey.700",
                        "&:hover": {
                          backgroundColor: "grey.400",
                          boxShadow: 6,
                        },
                      }}
                    >
                      {number}
                    </Button>
                  </Grid>
                ))}
                {/* Call Button */}
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleCallClick}
                    disabled={
                      !socket?.connected ||
                      isCalling ||
                      phoneNumber.length !== 10
                    }
                    sx={{
                      borderRadius: "50%",
                      height: { xs: "50px", sm: "60px" },
                      width: { xs: "50px", sm: "60px" },
                      fontSize: "1.5rem",
                    }}
                  >
                    <CallIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>

      {/* Modal Component */}
      <ModalComponent
        open={open}
        setOpen={setOpen}
        modalTitle="Lead Generation"
        setOpenSnackbar={setOpenSnackbar}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setalertTitle={setalertTitle}
      />
    </>
  );
}
export default CallerComponent;
