import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { BDM_REPORT_GET } from "../../constants";
import axios from "axios";
import HoTaskTable from "./HoTaskTable";
import SelfTaskTable from "./SelfTaskTable";
import OtherTaskTable from "./OtherTaskTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FilterComponent from "./FilterComponent";
import dayjs from "dayjs";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import for the filter icon
import ConfirmedTaskTable from "./ConfirmedTaskTable";
import PostponedTaskTable from "./PostponedTaskTable";
import CompletedTaskTable from "./CompletedTaskTable";
import NotCompletedTaskTable from "./NotCompletedTaskTable";
import DataDisplayCard from "./DataDisplayCard";
import PivotTable from "./PivotTable";
import { useRef } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function BdmListComponent({ setLoading }) {
  const [bdmStatisticsList, setBdmStatisticsList] = useState([]);
  const [filteredBdmList, setFilteredBdmList] = useState([]);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    bdmName: "",
  });

  useEffect(() => {
    fetchBdmReportData();
  }, [filters]); // Fetch data whenever filters change

  const fetchBdmReportData = () => {
    const { startDate, endDate, bdmName } = filters;
    setLoading(true);
  
    // Determine yesterday's date
    const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
  
    // Format startDate and endDate, defaulting to yesterday if not provided
    const formattedStartDate = startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : yesterday;
    const formattedEndDate = endDate
      ? dayjs(endDate).format("YYYY-MM-DD")
      : yesterday;
  
    // Build query params
    const queryParams = new URLSearchParams();
    queryParams.append("startDate", formattedStartDate);
    queryParams.append("endDate", formattedEndDate);
    if (bdmName && bdmName.length > 0) {
      queryParams.append("bdmIds", bdmName.join(",")); // Join IDs with commas
    }
  
    axios
      .get(`${BDM_REPORT_GET}?${queryParams.toString()}`)
      .then((response) => {
        const { data } = response.data;
        setBdmStatisticsList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [filterShow, setFilterShow] = useState(false);

  const pivotTableRef = useRef(); // Ref to call export from PivotTable

  const handleExport = () => {
    // Call exportToExcel method in PivotTable
    if (pivotTableRef.current) {
      pivotTableRef.current.exportToExcel();
    }
  };

  return (
    <>
      {/* Container with flex layout to align items */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, paddingLeft: "12px", flexGrow: 1 }}
        >
          BDM Daily Task Report
        </Typography>

        {/* IconButton aligned to the right */}
        <IconButton
          onClick={() => setFilterShow((prev) => !prev)}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "40px", // Increased width for a more comfortable click area
            height: "40px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FilterListIcon />
        </IconButton>

        {/* IconButton aligned to the right */}
        <IconButton
          onClick={handleExport}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "40px", // Increased width for a more comfortable click area
            height: "40px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FileDownloadIcon />
        </IconButton>
      </Box>

      {filterShow && (
        <FilterComponent
          filters={filters}
          setFilters={setFilters}
          bdmStatisticsList={bdmStatisticsList}
        />
      )}

      <PivotTable
       ref={pivotTableRef}
        bdmStatisticsList={bdmStatisticsList}
        prevfilters={filters}
      ></PivotTable>

      {/* <DataDisplayCard bdmStatisticsList={bdmStatisticsList}></DataDisplayCard> */}
    </>
  );
}

export default BdmListComponent;
