import React from "react";
import EnquiryListTable from "./EnquiryListTable";

function EnquiryLeadsPageComponent() {
  return (
    <>
      <EnquiryListTable></EnquiryListTable>
    </>
  );
}

export default EnquiryLeadsPageComponent;
