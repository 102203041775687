import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingRequestsListComponent from "./PendingRequestsList";
import axios from "axios";
import { GET_ESTIMATIONS_HO } from "../../constants";
import Loader from "../reusable Component/Loader";
import NeedForApprovalListComponent from "./NeedForApprovalRequestList";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AllEstimationRequestsListComponent from "./AllEstimationRequestList";
import GeneratedListComponent from "./GeneratedRequestList";
import ConvertedListComponent from "./ConvertedListComponent";
import RejectedListComponent from "./RejectedListComponent";
import SharedListComponent from "./SharedListComponent";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import LoopIcon from '@mui/icons-material/Loop';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

function EstimationComponent() {
    const [value, setValue] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [needApprovalCount, setNeedApprovalCount] = useState(0);
    const [allCount, setAllCount] = useState(0);
    const [generatedCount, setGeneratedCount] = useState(0);
    const [convertedCount, setConvertedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);
    const [sharedCount, setSharedCount] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        axios.get(GET_ESTIMATIONS_HO,{
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        })
          .then(response => {
            const { data } = response.data;
            handlingEstimationRowData(data);
            handlingPendingEstimationRowData(data);
            handlingNFAEstimationRowData(data);
            handlingGeneratedEstimationRowData(data);
            handlingConvertedEstimationRowData(data);
            handlingRejectedEstimationRowData(data);
            handlingSharedEstimationRowData(data);
            setLoading(false);
          }) 
          .catch(error => {
            setLoading(false);
          });
  
      },[]);

      const formatDate = (dateString) =>{
        const date = new Date(dateString);
    
        // Get day, month, and year from the date object
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        // Return the formatted date string
        return `${day}-${month}-${year}`;
    };

    const [finalEstimationRow, setEstimationRow] = useState([]);
    
    const handlingEstimationRowData = (data) =>{
        setAllCount(data.length);
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth ,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.folxlow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : formatDate(rd.createdAt),
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setEstimationRow(transformedRows); 
    }

    const [finalPendingEstimationRow, setPendingEstimationRow] = useState([]);
    
    const handlingPendingEstimationRowData = (data) =>{
        // Filter the data to include only those records where status is 'Pending'
        const filteredData = data.filter((rd) => rd.status === 'Pending');
        setPendingCount(filteredData.length);
        const transformedRows = [];
        var a = {};
        filteredData.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : formatDate(rd.createdAt),
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setPendingEstimationRow(transformedRows); 
    }

    const [finalNFAEstimationRow, setNFAEstimationRow] = useState([]);
    const handlingNFAEstimationRowData = (data) =>{
        // Filter the data to include only those records where status is 'Need for Approval'
        const filteredData = data.filter((rd) => rd.status === 'Need for Approval');
        setNeedApprovalCount(filteredData.length);
        const transformedRows = [];
        var a = {};
        filteredData.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : formatDate(rd.createdAt),
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setNFAEstimationRow(transformedRows); 
    }

    const [finalGeneratedEstimationRow, setGeneratedEstimationRow] = useState([]);
    const handlingGeneratedEstimationRowData = (data) =>{
        // Filter the data to include only those records where status is 'Generated'
        const filteredData = data.filter((rd) => rd.status === 'Generated');
        setGeneratedCount(filteredData.length);
        const transformedRows = [];
        var a = {};
        filteredData.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth ,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : rd.createdAt,
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setGeneratedEstimationRow(transformedRows); 
    }

    const [finalConvertedEstimationRow, setConvertedEstimationRow] = useState([]);
    const handlingConvertedEstimationRowData = (data) =>{
        // Filter the data to include only those records where status is 'Generated'
        const filteredData = data.filter((rd) => rd.status === 'converted');
        setConvertedCount(filteredData.length);
        const transformedRows = [];
        var a = {};
        filteredData.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : rd.createdAt,
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setConvertedEstimationRow(transformedRows); 
    }

    const [finalRejectedEstimationRow, setRejectedEstimationRow] = useState([]);
    const handlingRejectedEstimationRowData = (data) =>{
        // Filter the data to include only those records where status is 'Generated'
        const filteredData = data.filter((rd) => rd.status === 'rejected');
        setRejectedCount(filteredData.length);
        const transformedRows = [];
        var a = {};
        filteredData.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : rd.createdAt,
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setRejectedEstimationRow(transformedRows); 
    }

    const [finalSharedEstimationRow, setSharedEstimationRow] = useState([]);
    const handlingSharedEstimationRowData = (data) =>{
        // Filter the data to include only those records where status is 'Generated'
        const filteredData = data.filter((rd) => rd.status === 'Estimation Shared');
        setSharedCount(filteredData.length);
        const transformedRows = [];
        var a = {};
        filteredData.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth,
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : rd.follow_up_date,
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : rd.createdAt,
            "LeadDetailId": rd.LeadDetailId,
            "status": rd.status,
            "LeadDetail": rd.LeadDetail,
            "farmerName": rd.LeadDetail == null ? '-' : rd.LeadDetail.CustomerName,
            "MobNo": rd.LeadDetail == null ? '-' : rd.LeadDetail.MobileNo,
            "Project": rd.LeadDetail == null ? '-' : rd.LeadDetail.Project,
            "estimationId": rd.id,
            "estimationNumber": rd.estimationNumber == null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount == null ? '-' : rd.estimation_amount,
            "estimation_remarks": rd.estimation_remarks == null ? '-' : rd.estimation_remarks,
            "last_estimation_number": rd.last_estimation_number == null ? '-' : rd.last_estimation_number,
            "Estimation_generated_date": rd.Estimation_generated_date == null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date == null ? '-' : formatDate(rd.Estimation_request_date),
            "firm_farmer_name": rd.firm_farmer_name == null ? '-' : rd.firm_farmer_name,
            "ho_document": rd.ho_document,
            "BdmName": rd.BdmName === null ? '-' : rd.BdmName
        }
        transformedRows.push(a);
        })
        setSharedEstimationRow(transformedRows); 
    }


    return(
        <>
        {loading && <Loader open={loading} />}
        <Tabs
        value={value}
        onChange={handleChange}
        variant={"scrollable"} // Scrollable for small screens
        scrollButtons={"auto"}   // Enable scroll buttons automatically
        aria-label="responsive tabs example"
        >
            <Tab label={`All Requests (${allCount})`} icon={<ContentPasteIcon />} iconPosition="start" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label={`Pending Requests (${pendingCount})`} icon={<PendingActionsIcon />} iconPosition="start" id="tab-1" aria-controls="tabpanel-1" />
            <Tab label={`Need For Approval (${needApprovalCount})`} icon={<AssignmentTurnedInIcon />} iconPosition="start" id="tab-2" aria-controls="tabpanel-2" />
            <Tab label={`Estimation Generated (${generatedCount})`} icon={<CheckCircleOutlineIcon />} iconPosition="start" id="tab-3" aria-controls="tabpanel-3" />
            <Tab label={`Shared (${sharedCount})`} icon={<ShareIcon />} iconPosition="start" id="tab-4" aria-controls="tabpanel-4" />
            <Tab label={`Converted (${convertedCount})`} icon={<LoopIcon />} iconPosition="start" id="tab-5" aria-controls="tabpanel-5" />
            <Tab label={`Rejected (${rejectedCount})`} icon={<DoDisturbIcon />} iconPosition="start" id="tab-6" aria-controls="tabpanel-6" />
        </Tabs>

        <TabPanel value={value} index={0}>
                <AllEstimationRequestsListComponent finalEstimationRow={finalEstimationRow} setLoading={setLoading}></AllEstimationRequestsListComponent>
        </TabPanel>

        <TabPanel value={value} index={1}>
                <PendingRequestsListComponent finalPendingEstimationRow={finalPendingEstimationRow} setLoading={setLoading} ></PendingRequestsListComponent>
        </TabPanel>

        <TabPanel  value={value} index={2}>
            <NeedForApprovalListComponent finalNFAEstimationRow={finalNFAEstimationRow} setLoading={setLoading}></NeedForApprovalListComponent>
        </TabPanel>

        <TabPanel  value={value} index={3}>
            <GeneratedListComponent finalGeneratedEstimationRow={finalGeneratedEstimationRow} setLoading={setLoading}></GeneratedListComponent>
        </TabPanel>

        <TabPanel  value={value} index={4}>
            <SharedListComponent finalSharedEstimationRow={finalSharedEstimationRow} setLoading={setLoading}></SharedListComponent>
        </TabPanel>

        <TabPanel  value={value} index={4}>
            <SharedListComponent finalSharedEstimationRow={finalSharedEstimationRow} ></SharedListComponent>
        </TabPanel>
        
        <TabPanel  value={value} index={5}>
            <ConvertedListComponent finalConvertedEstimationRow={finalConvertedEstimationRow} ></ConvertedListComponent>
        </TabPanel>

        <TabPanel  value={value} index={6}>
            <RejectedListComponent finalRejectedEstimationRow={finalRejectedEstimationRow} ></RejectedListComponent>
        </TabPanel>

        </>
    );

}

export default EstimationComponent;