import * as React from 'react';
import AssignedCustomerTable from '../BDM Assigned Page/assignedCustomerTable';
import BDMPerformance from './bdmPerformance';
import { Grid } from '@mui/material';

function BdmDashboardComponent() {

return (
      <>
      <Grid container spacing={2}  className={`mb-4`} wrap='wrap'>
      
      <Grid item xs={12}  xl={6} sm={6}>
         <BDMPerformance />
      </Grid>
      </Grid>
      </>
);
}

export default BdmDashboardComponent;