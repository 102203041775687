 





import React, { useState } from "react";
import axios from "axios";
import companySymbol from '../../icons/company-symbol.png';
// import companyLogo from '../../icons/chicks.jfif';
import { BASE_URL } from '../../constants';

const ChicksInquiryForm = () => {
  // Form Data State
  const [formData, setFormData] = useState({
    CustomerName: "",
    MobileNumber: "",
    whatsappNo: "",
    sameAsContact: false,
    Occupation: "",
    chicks_range: "",
  });

  // UI State Management
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  // Event Handlers
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle different input types
    if (name === "sameAsContact") {
      setFormData((prevData) => ({
        ...prevData,
        sameAsContact: checked,
        whatsappNo: checked ? prevData.MobileNumber : ""
      }));
    } 
    else if (name === "MobileNumber") {
      const phoneNumber = value.slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        MobileNumber: phoneNumber,
        whatsappNo: prevData.sameAsContact ? phoneNumber : prevData.whatsappNo
      }));
    }
    else if (name === "whatsappNo") {
      const phoneNumber = value.slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        whatsappNo: phoneNumber
      }));
    }
    else if (name === "CustomerName") {
      // Only allow letters and spaces in customer name
      const nameValue = value.replace(/[^A-Za-z\s]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        CustomerName: nameValue
      }));
    }
    else if (name === "chicks_range") {
      // Only allow numbers and limit to 9999
      const chicksValue = value.replace(/[^0-9]/g, "").slice(0, 4);
      setFormData((prevData) => ({
        ...prevData,
        chicks_range: chicksValue
      }));
    }
    else {
      // Handle all other fields normally
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleBack = () => {
    setFormSubmitted(false);
    setFormData({
      CustomerName: "",
      MobileNumber: "",
      whatsappNo: "",
      sameAsContact: false,
      Occupation: "",
      chicks_range: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate customer name before submission
    const customerName = formData.CustomerName.trim();
    if (!customerName || customerName.length === 0) {
      alert("Please enter a valid customer name");
      return;
    }
  
    if (!/^[A-Za-z]+[A-Za-z\s]*[A-Za-z]+$|^[A-Za-z]+$/.test(customerName)) {
      alert("Customer name must start and end with letters and contain only letters and spaces");
      return;
    }
  
    setLoading(true);
    try {
      const submissionData = {
        ...formData,
        CustomerName: customerName, // Use the trimmed value
      };
  
      const response = await axios.post(
        `${BASE_URL}/chicks-inquiry`,
        submissionData
      );
      console.log(response.data);
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
      alert("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Success Modal
  if (formSubmitted) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-8 max-w-md w-full">
          <div className="text-center">
            <div className="mb-4">
              <svg
                className="mx-auto h-16 w-16 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Thank You for Your Submission!
            </h2>
            <p className="text-gray-600 mb-8">
              We have received your information and will get back to you soon.
            </p>
            <button
              onClick={handleBack}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 focus:outline-none transition-colors"
            >
              Back to Form
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Form Header
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md">
      <div className="bg-gradient-to-r from-yellow-600 to-yellow-500 py-4 px-6 rounded-t-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <img
              src={companySymbol}
              alt="Company Symbol"
              className="h-14 w-14 p-1 bg-white rounded-full shadow-lg"
            />
            <div className="h-12 w-px bg-white/30 mx-2" />
            <h2 className="text-xl sm:text-2xl font-bold text-white tracking-wide drop-shadow-md">
              CHICKS INQUIRY FORM
            </h2>
          </div>
          <div className="bg-white/12 p-1 rounded-lg">
            {/* <img src={companyLogo} alt="Company Logo" className="h-16 " /> */}
          </div>
        </div>
      </div>
      <form
  onSubmit={handleSubmit}
  className="grid grid-cols-1 md:grid-cols-2 gap-3.5 p-3"
>
  {/* Customer Name */}
  <div>
    <label
      htmlFor="CustomerName"
      className="block mb-1 font-bold text-gray-700"
    >
      Customer Name
    </label>
    <input
      type="text"
      id="CustomerName"
      name="CustomerName"
      value={formData.CustomerName}
      onChange={handleChange}
      onKeyDown={(e) => {
        const key = e.key;
        if (
          !/^[A-Za-z\s]$/.test(key) &&
          key !== "Backspace" &&
          key !== "Delete" &&
          key !== "ArrowLeft" &&
          key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>

  {/* Mobile Number */}
  <div>
    <div className="block mb-1">
      <div className="flex items-center gap-2">
        <label
          htmlFor="MobileNumber"
          className="font-bold text-gray-700"
        >
          Mobile Number
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            id="sameAsContact"
            name="sameAsContact"
            checked={formData.sameAsContact}
            onChange={handleChange}
            className="mr-2"
          />
          <span className="text-gray-700">Same No. For WhatsApp</span>
        </label>
      </div>
    </div>
    <input
      type="tel"
      id="MobileNumber"
      name="MobileNumber"
      value={formData.MobileNumber}
      onChange={(e) => {
        handleChange(e);
        setFormData((prevData) => ({
          ...prevData,
          MobileNumber: e.target.value.slice(0, 10),
          whatsappNo: prevData.sameAsContact
            ? e.target.value.slice(0, 10)
            : prevData.whatsappNo,
        }));
        if (e.target.value.length < 10) {
          e.target.setCustomValidity(
            "Mobile Number must be 10 digits"
          );
        } else {
          e.target.setCustomValidity("");
        }
      }}
      onKeyDown={(e) => {
        const key = e.key;
        if (
          !/^\d$/.test(key) &&
          key !== "Backspace" &&
          key !== "Delete" &&
          key !== "ArrowLeft" &&
          key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      maxLength={10}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>

  {/* WhatsApp Number (if not same as mobile) */}
  {!formData.sameAsContact && (
    <div>
      <label
        htmlFor="whatsappNo"
        className="block mb-1 font-bold text-gray-700"
      >
        WhatsApp Number
      </label>
      <input
        type="tel"
        id="whatsappNo"
        name="whatsappNo"
        value={formData.whatsappNo}
        onChange={(e) => {
          handleChange(e);
          setFormData((prevData) => ({
            ...prevData,
            whatsappNo: e.target.value.slice(0, 10),
          }));
          if (e.target.value.length < 10) {
            e.target.setCustomValidity(
              "WhatsApp Number must be 10 digits"
            );
          } else {
            e.target.setCustomValidity("");
          }
        }}
        onKeyDown={(e) => {
          const key = e.key;
          if (
            !/^\d$/.test(key) &&
            key !== "Backspace" &&
            key !== "Delete" &&
            key !== "ArrowLeft" &&
            key !== "ArrowRight"
          ) {
            e.preventDefault();
          }
        }}
        maxLength={10}
        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
      />
    </div>
  )}

  {/* Occupation */}
  <div>
    <label
      htmlFor="Occupation"
      className="block mb-1 font-bold text-gray-700"
    >
      Occupation
    </label>
    <select
      id="Occupation"
      name="Occupation"
      value={formData.Occupation}
      onChange={handleChange}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    >
      <option value="">Select an occupation</option>
      <option value="Farmer">Farmer</option>
      <option value="Trader">Trader</option>
      <option value="Dealer">Dealer</option>
      <option value="Integrator">Integrator</option>
      <option value="Other">Other</option>
    </select>
  </div>

  {/* No. of Chicks Required */}
  <div>
    <label
      htmlFor="chicks_range"
      className="block mb-1 font-bold text-gray-700"
    >
      No. of Chicks Required
    </label>
    <input
      type="text"
      id="chicks_range"
      name="chicks_range"
      value={formData.chicks_range}
      onChange={handleChange}
      onKeyDown={(e) => {
        const key = e.key;
        if (
          !/^\d$/.test(key) &&
          key !== "Backspace" &&
          key !== "Delete" &&
          key !== "ArrowLeft" &&
          key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      maxLength={4}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>

  {/* Submit Button */}
  <div className="md:col-span-2">
    <button
      type="submit"
      disabled={loading}
      className="w-full px-4 py-2 font-bold text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none disabled:bg-yellow-400"
    >
      {loading ? "Please wait..." : "Submit"}
    </button>
  </div>
</form>
    </div>
  );
};

export default ChicksInquiryForm;