import React from 'react';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const DetailPanelTable = ({ data }) => {
  if (!data || data.length === 0) return <Typography>No updates available.</Typography>;

  const getColorForTag = (tag) => {
    switch (tag) {
      case 'hot':
        return 'error'; // React blue
      case 'warm':
        return 'warning'; // JavaScript yellow
      case 'cold':
        return 'info'; // UI dark blue
       case 'closed':
        return 'default'
      default:
        return 'default'; // Default gray
    }
  };


  return (
    <TableContainer component={Paper} sx={{width: 'fit-content'}}>
    <Table sx={{ width: 'fit-content' }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: '#EEF0F4'}}>
        <TableRow>
          <TableCell style={{textAlign: 'center', minWidth: '20px'}}>S.No</TableCell>
          <TableCell style={{textAlign: 'center', minWidth:'120px'}}>Date of Discussion</TableCell>
          <TableCell style={{textAlign: 'center', minWidth:'60px'}}>Follow Up Date</TableCell>
          <TableCell style={{textAlign: 'center', minWidth:'100px', textTransform: 'capitalize'}}>Category</TableCell>
          <TableCell  style={{textAlign: 'center', minWidth:'100px'}}>Sub Category</TableCell>
          <TableCell style={{textAlign: 'center', minWidth:'150px'}}>Closure Month</TableCell>
          <TableCell style={{textAlign: 'center', minWidth:'350px'}}>Remark</TableCell>
          <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Shared By</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell style={{textAlign: 'center', minWidth: '20px'}}>{row.sno}</TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'120px'}}>{row.createdAt}</TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'60px'}}>{row.follow_up_date}</TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'100px', textTransform: 'capitalize'}}>
                <Chip key={`index_${row.category}`} size='small' color={`${getColorForTag(row.category)}`} label={row.category} sx={{textTransform: 'capitalize', height: '20px'}}></Chip>
            </TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'100px'}}>{row.sub_category}</TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'150px'}}>{row.closure_month}</TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'350px'}}>{row.remark}</TableCell>
            <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.BDM}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
};

export default DetailPanelTable;
