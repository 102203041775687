import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Box,
    Chip,
    OutlinedInput,
    ListItemText,
    Checkbox,
    FormHelperText
} from '@mui/material';

const medicineOptions = {
    'General Medicine': ['IB cid', 'Clean tab'],
    'Supportive': ['Heptocare', 'IB lyte', 'VVaa e boost', 'IB B complex', 'Neftone', 'Cosbac', 'Parcetamol', 'Sodium salicilate IB CON', 'IMMUTAS', 'IB COMPLEX'],
    'ANTIBIOTICS': ['Neo plus', 'Enrocine(20%)', 'Ciprofloxacine/Levofloxacin', 'IB sulpha', 'amprolium'],
    'Injectable': ['Amikacin', 'Gentamycine', 'ceftrazone tazobactum'],
    'Cleaning disinfection': ['Formaline', 'Bleaching', 'Cu sulphate', 'Viru 100']
};

const MedicineListMultiSelect = ({ onChange, error }) => {
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedMedicines, setSelectedMedicines] = useState({});
    const [open, setOpen] = useState(false);
    const textFieldRefs = useRef({});

    const prevSelectedMedicinesRef = useRef({});
    const prevSelectedTypesRef = useRef([]);

    useEffect(() => {
         
        const hasTypeChanged = JSON.stringify(selectedTypes) !== JSON.stringify(prevSelectedTypesRef.current);
        const hasMedicineChanged = JSON.stringify(selectedMedicines) !== JSON.stringify(prevSelectedMedicinesRef.current);

        if (hasTypeChanged || hasMedicineChanged) {
            const selectedData = {
                medicine_type: selectedTypes,
                medicine_with_dose: Object.keys(selectedMedicines).map((medicine) => ({
                    medicine,
                    dose: selectedMedicines[medicine]
                }))
            };
            onChange(selectedData);
            prevSelectedTypesRef.current = selectedTypes;
            prevSelectedMedicinesRef.current = selectedMedicines;
        }
    
    }, [selectedMedicines, selectedTypes, onChange]);

    const handleTypeChange = (event, type) => {
        const newSelectedTypes = event.target.checked
            ? [...selectedTypes, type]
            : selectedTypes.filter(t => t !== type);
        setSelectedTypes(newSelectedTypes);
    };

    const handleMedicineToggle = (medicine) => {
        setSelectedMedicines(prev => {
            const newState = { ...prev };
            if (newState[medicine]) {
                delete newState[medicine];
            } else {
                newState[medicine] = '';
            }
            return newState;
        });
    };

    const handleDosageChange = (medicine, dosage) => {
        setSelectedMedicines(prev => {
            const newState = { ...prev, [medicine]: dosage };
            return newState;
        });
    };

    const availableMedicines = selectedTypes.flatMap(type => medicineOptions[type]);

    const renderSelectedMedicines = () => {
        return selectedTypes.map(type => {
            const medicinesInType = availableMedicines
                .filter(medicine => selectedMedicines[medicine])
                .map(medicine => `${medicine}: ${selectedMedicines[medicine]}`)
                .join(', ');

            return `${type}: ${medicinesInType}`;
        }).join(' ');
    };

    // Render medicines with dosage in an orderly format
    const renderMedicinesWithDosage = () => {
        return selectedTypes.map(type => {
            const medicinesInType = availableMedicines
                .filter(medicine => selectedMedicines[medicine] && medicineOptions[type].includes(medicine))
                .map(medicine => (
                    <li key={medicine} style={{ display: 'flex', gap: '10px', marginBottom: '5px' }}>
                        <span style={{ fontWeight: 'bold' }}>{medicine}:</span>
                        <span style={{ color: 'gray' }}>{selectedMedicines[medicine]}</span>
                    </li>
                ));

            return medicinesInType.length > 0 ? (
                <Box key={type} sx={{ marginBottom: 2 }}>
                    <h4>{type}</h4>
                    <ul style={{ paddingLeft: '20px' }}>
                        {medicinesInType}
                    </ul>
                </Box>
            ) : null;
        });
    };

    return (
        <Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth error={error} size="small" margin="normal">
                <InputLabel>Medicine Type</InputLabel>
                <Select
                    multiple
                    value={selectedTypes}
                    input={<OutlinedInput label="Medicine Type" />}
                    size='small'
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} size='small' />
                            ))}
                        </Box>
                    )}
                >
                    {Object.keys(medicineOptions).map((type) => (
                        <MenuItem key={type} value={type}>
                            <Checkbox 
                                checked={selectedTypes.indexOf(type) > -1}
                                onChange={(event) => handleTypeChange(event, type)}
                            />
                            <ListItemText primary={type} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedTypes.length > 0 && (
                <FormControl fullWidth error={error} size="small" margin="normal">
                    <InputLabel>Medicines</InputLabel>
                    <Select
                        multiple
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        value={Object.keys(selectedMedicines)}
                        input={<OutlinedInput label="Medicines" />}
                        renderValue={renderSelectedMedicines}
                        size='small'
                    >
                        {availableMedicines.map((medicine) => (
                            <MenuItem key={medicine} value={medicine} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Checkbox 
                                    checked={!!selectedMedicines[medicine]}
                                    onChange={(event) => {
                                        event.stopPropagation();
                                        handleMedicineToggle(medicine);
                                    }}
                                />
                                <ListItemText 
                                    primary={medicine} 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleMedicineToggle(medicine);
                                    }}
                                />
                                
                                <TextField
                                    inputRef={(el) => textFieldRefs.current[medicine] = el}
                                    size="small"
                                    label="Dosage"
                                    value={selectedMedicines[medicine] || ''}
                                    onChange={(e) => handleDosageChange(medicine, e.target.value)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!selectedMedicines[medicine]) {
                                            handleMedicineToggle(medicine);
                                        }
                                        setTimeout(() => {
                                            textFieldRefs.current[medicine]?.select();
                                        }, 0);
                                    }}
                                    error={!selectedMedicines[medicine] && !!selectedMedicines[medicine]} // Error state for empty dosage
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: !selectedMedicines[medicine] && !!selectedMedicines[medicine] ? 'red' : undefined,
                                            },
                                        },
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            )}
            </Box>
           {/* Render the selected medicines with their dosages */}
            {Object.keys(selectedMedicines).length > 0 && (
                <Box sx={{ marginTop: 2, padding: 2, borderRadius: 2, backgroundColor: '#f5f5f5' }}>
                    {/* <h3>Selected Medicines with Dosage:</h3> */}
                    {renderMedicinesWithDosage()}
                </Box>
            )}
        
        </Box>
    );
};

export default MedicineListMultiSelect;
