import React, { useContext, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import MedicineListMultiSelect from "./MedicineListMultiSelect";
import biFormStyles from "./BiFromStyles.module.css";
import {
  Divider,
  Stack,
  Grid,
  Button,
  Snackbar,
  Card,
  Autocomplete,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { GET_BI_REMARK_LIST, POST_BI_REMARK } from "../../constants";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  MenuItem,
  FormControl,
  Select,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../reusable Component/Loader";
import LongTextComponent from "../Leads Page/LongTextComponent";
import StatusChangeDialog from "./StatusChangeDialog";

function BiDetailsPageComponent() {
  const storedLeadDetails = JSON.parse(localStorage.getItem("leadDetails"));
  const empDetails = JSON.parse(localStorage.getItem("userData"));
  const [selectedDate, setSelectedDate] = useState(dayjs("2024-01-01"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const initialFormValues = {
    SNo: "", // Serial Number
    Lot_Number: "", // Lot Number
    medicine_type: "", // Medicine Type
    medicine: "", // Medicine List
    disease_name: "", // Disease Name
    dosage: "", // Dosage
    Remarks: "", // Remarks
    updatedAt: "", // Added AgentId
  };

  const [formValues, setFormValues] = useState({
    SNo: "", // Serial Number
    Lot_Number: "", // Lot Number
    medicine_type: "", // Medicine Type
    medicine: "", // Medicine List
    disease_name: "", // Disease Name
    dosage: "", // Dosage
    Remarks: "", // Remarks
    updatedAt: "", // Last Modified On
    Follow_up_date: null, // For the DatePicker
  });

  // Handle changes to form values
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Ensure the name matches a key in formValues
    if (name in formValues) {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };
  const [formErrors, setFormErrors] = useState({});

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    handleChange({ target: { name: "Follow_up_date", value: newValue } });
  };

  const medicineTypeOptions = [
    "General Medicine",
    "Supportive",
    "ANTIBIOTICS",
    "Injectable",
    "Cleaning disinfection",
  ];

  const MultiSelectEditComponent = ({ id, value, field, api }) => {
    const handleChange = (event, newValue) => {
      api.setEditCellValue({ id, field, value: newValue.join(", ") }, event);
      if (!event.key) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, "view");
      }
    };

    return (
      <Autocomplete
        multiple
        options={medicineTypeOptions}
        defaultValue={value ? value.split(", ") : []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        sx={{ width: "100%" }}
      />
    );
  };

  const onSubmit = (e) => {
    // e.preventDefault();
    // if (validate()) {

    axios
      .post(POST_BI_REMARK, formValues, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setSeverity("success");
        setAlertTitle(response.data.message);
        setOpenSnackbar(true);
        resetForm();
        setRunEffect(true);
      })
      .catch((error) => {
        setSeverity("error");
        setAlertTitle(error.message);
        setOpenSnackbar(true);
        resetForm();
      });
  };

  // To reset formValues to initial state
  const resetForm = () => {
    setFormValues(initialFormValues);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [row, setRow] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { field: "SNo", headerName: "SNo", width: 50, headerAlign: "center" },
    {
      field: "Lot_Number",
      headerName: "Lot Number",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "medicine",
      headerName: "Medicine List",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "disease_name",
      headerName: "Disease Name",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: 190,
      headerAlign: "center",
    },
    {
      field: "updatedAt",
      headerName: "Last Modified On",
      width: 150,
      type: "dateTime",
      headerAlign: "center",
    },
  ];

  const [runEffect, setRunEffect] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log("Fetching data from:", GET_BI_REMARK_LIST);

    axios
      .get(`${GET_BI_REMARK_LIST}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data); // Log the entire response
        const { auditLeadRemarks } = response.data; // Adjust to get 'auditLeadRemarks'

        if (auditLeadRemarks) {
          handleRowData(auditLeadRemarks); // Make sure this function can handle the new structure
        } else {
          console.warn("No remarks found in response");
        }
      })
      .catch((error) => {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
      })
      .finally(() => {
        setLoading(false); // Ensure loading state is reset
        setRunEffect(false);
      });
  }, [runEffect]);

  const handleRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      const a = {
        id: index + 1,
        SNo: index + 1,
        CH: rd.CH,
        AGE: rd.AGE,
        BWT: rd.BWT,
        M_QTY: rd.M_QTY,
        REASON: rd.REASON,
        MED: rd.MED,
        FEED: rd.FEED,
        STOCK: rd.STOCK,
        IFFT_IN: rd.IFFT_IN,
        IFFT_OUT: rd.IFFT_OUT,
        LS_VISIT: rd.LS_VISIT,
        BM_VISIT: rd.BM_VISIT,
        DAILY_ENT: rd.DAILY_ENT,
        FEED_ENT: rd.FEED_ENT,
        MORT_ENT: rd.MORT_ENT,
        BWT_ENT: rd.BWT_ENT,
        MED_ENT: rd.MED_ENT,
        REMARKS: rd.REMARKS, // Old remarks (optional)
        disease_name: rd.disease_name, // Added disease_name
        Remarks: rd.Remarks, // New Remarks field
        AgentId: rd.AgentId, // Included AgentId
        AgentName: rd.Agent.EmployeeName, // New AgentName field
        Lot_Number: rd.Lot_Number, // Lot Number
        medicine_type: rd.medicine_type, // Added medicine_type
        medicine_list: rd.medicine_list, // Added medicine_list
        dosage: rd.dosage, // Added dosage
        createdAt: new Date(rd.createdAt),
        updatedAt: new Date(rd.updatedAt),
      };

      transformedRows.push(a);
    });
    setRow(transformedRows);
  };

  //   const handleMedicineTypeChange = (event, newValue) => {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       medicine_type: newValue,
  //     }));
  //   };

  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
  const [status, setStatus] = useState(""); // To store the selected status

  const handleOpenDialog = (e) => {
    e.preventDefault();
    setDialogOpen(true); // Open the dialog on submit
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${biFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Lead Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <Grid container spacing={2} p={3}>
              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Branch:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Branch Description:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Farm Name:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Farmer Mobile:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Lot Number:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Age:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Chicks Housed Quantity:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality Quantity:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality Percentage:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Balance Birds:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality On Date:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality Date 1:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality Date 2:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality Date 3:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${biFormStyles.label_margin}`}
                >
                  Mortality Date 4:
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>

              {/* <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Remarks:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Remarks}
        </Typography>
    </Grid> */}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${biFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Remarks List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper>
            <TableContainer sx={{ overflowX: "auto" }}>
              <Table
                sx={{ minWidth: 450, tableLayout: "fixed" }}
                aria-label="medicine table"
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f0f4f8" }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align={column.headerAlign}
                        sx={{
                          width: column.width,
                          fontWeight: "bold",
                          color: "text.primary",
                        }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                        }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.field}
                            align={column.headerAlign}
                          >
                            {column.type === "dateTime"
                              ? new Date(row[column.field]).toLocaleString()
                              : row[column.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {row.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        sx={{
                          color: "text.secondary",
                          py: 3,
                          textAlign: "center",
                        }}
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={row.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                borderTop: "1px solid #e0e0e0",
                ".MuiTablePagination-actions": { color: "primary.main" },
              }}
            />
          </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${biFormStyles.accordian_heading} h-auto`}
        >
          <Typography>Remark Form</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <form>
              <Grid container spacing={3} p={3}>
                {/* Medicine Multi-Select */}
                <Grid item xs={12}>
                  <MedicineListMultiSelect
                    value={formValues.medicine}
                    onChange={(newValue) => {
                      setFormValues((prev) => ({
                        ...prev,
                        medicine: newValue,
                      }));
                    }}
                    error={!!formErrors.medicine}
                  />
                </Grid>

                {/* Disease Name Field */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Disease Name"
                    id="outlined-size-small"
                    name="disease_name"
                    value={formValues.disease_name}
                    onChange={handleChange}
                    error={!!formErrors.disease_name}
                    helperText={formErrors.disease_name}
                    size="small"
                    fullWidth
                  />
                </Grid>

                {/* Follow Up Date Picker */}
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Follow-Up Date"
                      value={formValues.Follow_up_date}
                      onChange={(newValue) => {
                        setFormValues((prev) => ({
                          ...prev,
                          Follow_up_date: newValue, // Update date in formValues
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!formErrors.Follow_up_date}
                          helperText={formErrors.Follow_up_date}
                          size="small"
                          fullWidth
                        />
                      )}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Remarks Field */}
                <Grid item xs={12}>
                  <TextField
                    label="Remarks"
                    id="outlined-size-small"
                    name="Remarks"
                    value={formValues.Remarks}
                    onChange={handleChange}
                    error={!!formErrors.Remarks}
                    helperText={formErrors.Remarks}
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>

                {/* Save Button */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={(e) => handleOpenDialog(e)}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* <AlertTitle>{alertTitle}</AlertTitle> */}
          {alertTitle}
        </Alert>
      </Snackbar>

      <StatusChangeDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        formValues={formValues}
        setFormValues={setFormValues}
        setOpenSnackbar={setOpenSnackbar}
        setSeverity={setSeverity}
        setAlertTitle={setAlertTitle}
        resetForm={resetForm}
        setRunEffect={setRunEffect}
        empDetails={empDetails}
      ></StatusChangeDialog>
    </>
  );
}

export default BiDetailsPageComponent;
