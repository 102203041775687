import React, {useContext, useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { LoginContext } from '../LoginPage/LoginContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import MobileRemarkDatatable from "./MobileRemarkDatatable";
import axios from "axios";
import { ENQUIRY_TYPE, PROJECT, CATEGORY,LEAD_SOURCE,CLOSED_SUB_CATEGORY, COLD_SUB_CATEGORY, HOT_SUB_CATEGORY, PENDING_SUB_CATEGORY, WARM_SUB_CATEGORY, CREATE_LEAD_API , STATE_API, CALL_TYPE, TITLE, BDM_LIST, CALL_STATUS, BDM_REMARK_UPDATE , GET_REMARK_LIST, UPDATE_STATUS_AGENT, PAGE_SECTIONS, GET_MEETING, GET_SITE_VISIT, GET_ESTIMATION, GET_ON_CALL_LIST } from '../../constants';
import OnCallFormComponent from "../BDM Assigned Page/OnCallForm";
import Loader from "../reusable Component/Loader";
import moment from "moment";
import { Alert, Snackbar } from "@mui/material";


function MobileOnCallSection({leadDetails}) {

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [runEffect, setRunEffect] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => { 
      setLoading(true);
        axios.get(`${GET_ON_CALL_LIST}/${leadDetails.id}`,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
            .then(response => {
              const { leadUpdates } = response.data;
              handlingOnCallRowData(leadUpdates);
              setLoading(false);
              
            }) 
            .catch(error => {
              setLoading(false);
            });

    },[runEffect]);

    const [finalOnCallRow, setOnCallRow] = useState([]);
    const handlingOnCallRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "category": rd.category === null ? '-' : rd.category.toUpperCase(),
            "sub_category": rd.sub_category === null ? '-' : rd.sub_category,
            "closure_month": rd.closure_month === null ? '-' : rd.closure_month,
            "follow_up_date": rd.follow_up_date === null ? '-' : formatDate(rd.follow_up_date),
            "remark": rd.remark,
            "AddedBy": rd.AgentName == null ? '-' : rd.AgentName.EmployeeName,
            "LeadDetailId": rd.LeadDetailId,
            "createdAt": rd.createdAt === null ? '-' : formatDate(rd.createdAt)
        }
        transformedRows.push(a);
        })
        setOnCallRow(transformedRows); 
    }

    const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
    };

return(
    <>
    {loading && <Loader open={loading} />}
    <Accordion style={{marginTop: '5px'}} defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Remarks List</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '8px 9px 16px'}}>
            <MobileRemarkDatatable data={finalOnCallRow}></MobileRemarkDatatable>
            <OnCallFormComponent roleType={'BDM'} setSeverity={setSeverity} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} setRunEffect={setRunEffect} leadDetails={leadDetails} ></OnCallFormComponent>
        </AccordionDetails>
        </Accordion>


        <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
    </Snackbar>
    </>
);

}

export default MobileOnCallSection;
