import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import axios from "axios";
import { AUDIT_STATUS_UPDATE, GET_AUDIT_LIST } from "../../constants";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../LoginPage/LoginContext";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../reusable Component/Loader";
import CallIcon from '@mui/icons-material/Call';
import RuleBasedFilterPanel from "./RuleBasedFilterPanel";

function OpenDataListComponent({ setStatusCounts, setStatusCounts1 }) {
  const empDetails = JSON.parse(localStorage.getItem("userData"));

  const { setleadDetails } = useContext(LoginContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [message, setMessage] = useState("");

  const [openStatusDialog, setOpenStatusDialog] = useState(false);

  const [row, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  // Filter and Sort states
  const [lotNumber, setLotNumber] = useState(
    localStorage.getItem("lotNumber") || ""
  );
  const [branchName, setBranchName] = useState(
    localStorage.getItem("branchName") || ""
  );
  const [farmerName, setFarmerName] = useState(
    localStorage.getItem("farmerName") || ""
  );
  const [zoneName, setZoneName] = useState(
    localStorage.getItem("zoneName") || ""
  );
  const [line, setLine] = useState(localStorage.getItem("line") || "");
  const [hatcheryName, setHatcheryName] = useState(
    localStorage.getItem("hatcheryName") || ""
  );
  //  const [sortFCROrder, setSortFCROrder] = useState(localStorage.getItem("sortFCROrder") || "asc");
  //  const [sortBirdAgeOrder, setSortBirdAgeOrder] = useState(localStorage.getItem("sortBirdAgeOrder") || "asc");
  const [sortFCROrder, setSortFCROrder] = useState("asc");
  const [sortBirdAgeOrder, setSortBirdAgeOrder] = useState("asc");
  const [commonSearch, setCommonSearch] = useState(
    localStorage.getItem("commonSearch") || ""
  );

  const [runEffect, setRunEffect] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    fetchAuditLeadList();
  }, [page, rowsPerPage, runEffect]);

  const fetchAuditLeadList = () => {
    setLoading(true);
    axios
      .get(
        `${GET_AUDIT_LIST}?agentId=${empDetails.EmployeeId}&page=${
          page + 1
        }&limit=${rowsPerPage}&lotNumber=${lotNumber}&branchName=${branchName}&farmerName=${farmerName}&zoneName=${zoneName}&line=${line}&hatcheryName=${hatcheryName}&sortFCR=${sortFCROrder}&sortBirdAge=${sortBirdAgeOrder}&commonSearch=${commonSearch}&status=open`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const { data, pagination, totalStatusCounts, filteredStatusCounts } =
          response.data;
        handleOpenRowData(data);
        setTotalCount(pagination.totalCount);

        // Update status counts based on whether we are searching or not
        setStatusCounts(
          isSearching ? filteredStatusCounts.open : totalStatusCounts.open
        );

        setStatusCounts1(totalStatusCounts.closed);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data", error);
      });
  };

  const handleOpenRowData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        SNo: index + 1,
        Zone_Name: rd.Zone_Name || "-",
        Region_Description: rd.Region_Description || "-",
        Branch: rd.Branch || "-",
        Branch_Name: rd.Branch_Name || "-",
        Farmer_Name: rd.Farmer_Name || "-",
        Lot_Number: rd.Lot_Number || "-",
        Vendor: rd.Vendor || "-",
        Shed_Type: rd.Shed_Type || "-",
        Hatch_Date: rd.Hatch_Date || "-",
        Mean_Age: rd.Mean_Age || "-",
        Placed_Qty: rd.Placed_Qty || "-",
        Lifted_Birds: rd.Lifted_Birds || "-",
        Bal_Birds: rd.Bal_Birds || "-",
        Total_Mortality: rd.Total_Mortality || "-",
        Total_Mortality_Percentage: rd.Total_Mortality_Percentage || "-",
        FCR: rd.FCR || "-",
        LR: rd.LR || "-",
        CA: rd.CA || "-",
        Age_SAP: rd.Age_SAP || "-",
        Diff: rd.Diff || "-",
        first_Week_M: rd.first_Week_M || "-",
        First_Week_Mortality_Percentage:
          rd.First_Week_Mortality_Percentage || "-",
        Lifting_EA: rd.Lifting_EA || "-",
        Lift_Percentage: rd.Lift_Percentage || "-",
        ABWT: rd.ABWT || "-",
        BWT_Age: rd.BWT_Age || "-",
        Feed_Cons: rd.Feed_Cons || "-",
        Avg_Lift_Wt: rd.Avg_Lift_Wt || "-",
        Prev_Grade: rd.Prev_Grade || "-",
        Mobile: rd.Mobile || "-",
        Line: rd.Line || "-",
        Hatchery_Name: rd.Hatchery_Name || "-",
        createdAt: formatDate(rd.createdAt) || "-",
        updatedAt: formatDate(rd.updatedAt) || "-",
        status: rd.status || "-",
        last_action_date:
          rd.last_action_date === null ? "-" : formatDate(rd.last_action_date),
      };
      transformedRows.push(a);
    });
    setRow(transformedRows);
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "open":
        return "error";
      case "closed":
        return "success";
      default:
        return "default";
    }
  };

  const IconCell = ({ row }) => (
    <>
    <IconButton
      style={{
        border: "1px solid",
        borderRadius: "4px",
        padding: "2px",
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
        marginRight: '5px'
      }}
      color="primary"
      onClick={() => handleIconClick(row)}
    >
      <EditIcon fontSize="small" color="primary" />
    </IconButton>

    <IconButton
      style={{
        border: "1px solid",
        borderRadius: "4px",
        padding: "2px",
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
      color="primary"
      onClick={() => handleCallIconClick(row)}
    >
      <CallIcon fontSize="small" color="primary" />
    </IconButton>
    </>
  );

  const handleIconClick = (row) => {
    setleadDetails(row);
    navigate("/auditLeadDetails");
  };

  const handleCallIconClick = (row) => {
    navigate('/dashboard', {
      state: {
        mobNo: row.Mobile,
        lotNum: row.Lot_Number
      }
    });
  };

  const [statusLotNumber, setStatusLotNumber] = useState("");

  const handleChipClick = (row) => {
    if (row.status == "closed") {
      setSeverity("warning");
      setAlertTitle(
        `The status for this Lot Number ${row.Lot_Number} is already closed.`
      );
      setOpenSnackbar(true);
    } else {
      setOpenStatusDialog(true);
      setStatusLotNumber(row.Lot_Number);
    }
  };

  const handleStatusChange = () => {
    const formData = {
      lotNumber: statusLotNumber,
      newStatus: "closed",
    };

    axios
      .put(AUDIT_STATUS_UPDATE, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setSeverity("success");
        setAlertTitle(response.data.message);
        setOpenSnackbar(true);
        setOpenStatusDialog(false);

        setRunEffect((prev) => !prev); // Toggle the state
      })
      .catch((error) => {
        setSeverity("error");
        setAlertTitle(error.message);
        setOpenSnackbar(true);
        setOpenStatusDialog(false);
      });
  };

  const handleStatusDialogClose = () => {
    setOpenStatusDialog(false);
  };

  const handleSearch = () => {
    setPage(0); // Reset to first page when a new search is conducted

    setIsSearching(true);
    setRunEffect((prev) => !prev);

    // Save each field to localStorage
    localStorage.setItem("lotNumber", lotNumber);
    localStorage.setItem("branchName", branchName);
    localStorage.setItem("farmerName", farmerName);
    localStorage.setItem("zoneName", zoneName);
    localStorage.setItem("line", line);
    localStorage.setItem("hatcheryName", hatcheryName);
    //  localStorage.setItem("sortFCROrder", sortFCROrder);
    //  localStorage.setItem("sortBirdAgeOrder", sortBirdAgeOrder);
    localStorage.setItem("commonSearch", commonSearch);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClearLotNumber = () => {
    setIsSearching(false);
    setLotNumber(""); // Clear the lot number field
    localStorage.removeItem("lotNumber");
  };

  const handleClearBranchName = () => {
    setIsSearching(false);
    setBranchName("");
    localStorage.removeItem("branchName");
  };

  const handleClearFarmerName = () => {
    setIsSearching(false);
    setFarmerName("");
    localStorage.removeItem("farmerName");
  };

  const handleClearZoneName = () => {
    setIsSearching(false);
    setZoneName("");
    localStorage.removeItem("zoneName");
  };

  const handleClearLine = () => {
    setIsSearching(false);
    setLine("");
    localStorage.removeItem("line");
  };

  const handleClearHatcheryName = () => {
    setIsSearching(false);
    setHatcheryName("");
    localStorage.removeItem("hatcheryName");
  };

  const handleClearCommonSearch = () => {
    setIsSearching(false);
    setCommonSearch("");
    localStorage.removeItem("commonSearch");
  };

  // Fetch data whenever filter values change
  // useEffect(() => {
  //     fetchAuditLeadList();
  //   }, [lotNumber, branchName, farmerName, zoneName, line, hatcheryName, sortFCR, sortBirdAge, commonSearch]);

  const handleClearAll = () => {
    setLotNumber("");
    setBranchName("");
    setFarmerName("");
    setZoneName("");
    setLine("");
    setHatcheryName("");
    setSortFCROrder("asc");
    setSortBirdAgeOrder("asc");
    setCommonSearch("");

    // Remove them from localStorage
    localStorage.removeItem("lotNumber");
    localStorage.removeItem("branchName");
    localStorage.removeItem("farmerName");
    localStorage.removeItem("zoneName");
    localStorage.removeItem("line");
    localStorage.removeItem("hatcheryName");
    //  localStorage.removeItem("sortFCROrder");
    //  localStorage.removeItem("sortBirdAgeOrder");
    localStorage.removeItem("commonSearch");

    setIsSearching(false);
    setRunEffect((prev) => !prev); // Trigger a re-fetch with cleared filters
  };

  // Define your column widths in a mapping
  const columnWidths = {
    SNo: "50px",
    "Last Action Date": "150px",
    "Zone Name": "150px",
    "Lot Number": "150px",
    "Farmer Name": "150px",
    "Branch Name": "150px",
    Vendor: "100px",
    "Shed Type": "100px",
    "Placed Quantity": "100px",
    CA: "100px",
    "Age (SAP)": "80px",
    Diff: "50px",
    "First Week Mortality": "120px",
    "First Week Mortality Percentage": "180px",
    "Mortality Quantity": "150px",
    "Mortality Percentage": "150px",
    "Lifting EA": "120px",
    "Lift Percentage": "120px",
    "Balance Birds": "120px",
    FCR: "150px",
    "Avg Body Weight": "120px",
    "BWT Age": "120px",
    "Feed Cons": "120px",
    "Lifted Avg Weight": "120px",
    "Prev Grade": "120px",
    "Mobile Number": "120px",
    "Hatch Date": "120px",
    Line: "120px",
    "Hatchery Name": "150px",
    "Last Modified On": "150px",
    Status: "120px",
    Action: "120px",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      {loading && <Loader open={loading} />}
      {/* Filter Panel */}
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Lot Number"
            value={lotNumber}
            onChange={(e) => setLotNumber(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearLotNumber} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Branch Name"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearBranchName} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Farmer Name"
            value={farmerName}
            onChange={(e) => setFarmerName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearFarmerName} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Zone Name"
            value={zoneName}
            onChange={(e) => setZoneName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearZoneName} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Line"
            value={line}
            onChange={(e) => setLine(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearLine} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Hatchery Name"
            value={hatcheryName}
            onChange={(e) => setHatcheryName(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearHatcheryName} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>

        {/* Sort FCR */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Sort FCR"
            value={sortFCROrder}
            onChange={(e) => setSortFCROrder(e.target.value)}
            fullWidth
            size="small"
          >
            <MenuItem value="asc">Low to High</MenuItem>
            <MenuItem value="desc">High to Low</MenuItem>
          </TextField>
        </Grid>

        {/* Sort Bird Age */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Sort Bird Age"
            value={sortBirdAgeOrder}
            onChange={(e) => setSortBirdAgeOrder(e.target.value)}
            fullWidth
            size="small"
            endAdornment={
              <IconButton onClick={() => setSortFCROrder("asc")} size="small">
                <ClearIcon fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="asc">Low to High</MenuItem>
            <MenuItem value="desc">High to Low</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Search"
            value={commonSearch}
            onChange={(e) => setCommonSearch(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClearCommonSearch} size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            fullWidth
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClearAll}
            fullWidth
          >
            Clear All
          </Button>
        </Grid>
      </Grid>

      {/* <RuleBasedFilterPanel></RuleBasedFilterPanel> */}

      {/* Table */}
      <Paper>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            margin: "16px 0",
          }}
        >
          <Table
            sx={{ minWidth: 650, height: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["SNo"] || "auto", padding: 0 }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Last Action Date"] || "auto",
                    padding: 0,
                  }}
                >
                  Last Action Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Zone Name"] || "auto",
                    padding: 0,
                  }}
                >
                  Zone Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Lot Number"] || "auto",
                    padding: 0,
                  }}
                >
                  Lot Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Farmer Name"] || "auto",
                    padding: 0,
                  }}
                >
                  Farmer Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Branch Name"] || "auto",
                    padding: 0,
                  }}
                >
                  Branch Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["Vendor"] || "auto", padding: 0 }}
                >
                  Vendor
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Shed Type"] || "auto",
                    padding: 0,
                  }}
                >
                  Shed Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Placed Quantity"] || "auto",
                    padding: 0,
                  }}
                >
                  Placed Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["CA"] || "auto", padding: 0 }}
                >
                  CA
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Age (SAP)"] || "auto",
                    padding: 0,
                  }}
                >
                  Age (SAP)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["Diff"] || "auto", padding: 0 }}
                >
                  Diff
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["First Week Mortality"] || "auto",
                    padding: 0,
                  }}
                >
                  First Week Mortality
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width:
                      columnWidths["First Week Mortality Percentage"] || "auto",
                    padding: 0,
                  }}
                >
                  First Week Mortality Percentage
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Mortality Quantity"] || "auto",
                    padding: 0,
                  }}
                >
                  Mortality Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Mortality Percentage"] || "auto",
                    padding: 0,
                  }}
                >
                  Mortality Percentage
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Lifting EA"] || "auto",
                    padding: 0,
                  }}
                >
                  Lifting EA
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Lift Percentage"] || "auto",
                    padding: 0,
                  }}
                >
                  Lift Percentage
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Balance Birds"] || "auto",
                    padding: 0,
                  }}
                >
                  Balance Birds
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["FCR"] || "auto", padding: 0 }}
                >
                  FCR
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Avg Body Weight"] || "auto",
                    padding: 0,
                  }}
                >
                  Avg Body Weight
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["BWT Age"] || "auto", padding: 0 }}
                >
                  BWT Age
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Feed Cons"] || "auto",
                    padding: 0,
                  }}
                >
                  Feed Cons
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Lifted Avg Weight"] || "auto",
                    padding: 0,
                  }}
                >
                  Lifted Avg Weight
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Prev Grade"] || "auto",
                    padding: 0,
                  }}
                >
                  Prev Grade
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Mobile Number"] || "auto",
                    padding: 0,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Hatch Date"] || "auto",
                    padding: 0,
                  }}
                >
                  Hatch Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: columnWidths["Line"] || "auto", padding: 0 }}
                >
                  Line
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Hatchery Name"] || "auto",
                    padding: 0,
                  }}
                >
                  Hatchery Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Last Modified On"] || "auto",
                    padding: 0,
                  }}
                >
                  Last Modified On
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: columnWidths["Status"] || "auto",
                    padding: 0,
                    position: "sticky", // Make the cell sticky
                    right: "120px", // Align it next to the Action column
                    backgroundColor: "#EEF0F4", // Set background color for visibility
                    zIndex: 10, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "120px",
                    position: "sticky", // Make the cell sticky
                    right: 0, // Fix to the right side
                    backgroundColor: "#EEF0F4", // Set background color for visibility
                    zIndex: 100, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    top: 0,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["SNo"] || "auto", padding: 0 }}
                  >
                    {row.SNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Last Action Date"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.last_action_date}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Zone Name"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Zone_Name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Lot Number"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Lot_Number}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Farmer Name"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Farmer_Name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Branch Name"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Branch_Name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["Vendor"] || "auto", padding: 0 }}
                  >
                    {row.Vendor}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Shed Type"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Shed_Type}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Placed Quantity"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Placed_Qty}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["CA"] || "auto", padding: 0 }}
                  >
                    {row.CA}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Age (SAP)"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Age_SAP}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["Diff"] || "auto", padding: 0 }}
                  >
                    {row.Diff}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["First Week Mortality"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.first_Week_M}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width:
                        columnWidths["First Week Mortality Percentage"] ||
                        "auto",
                      padding: 0,
                    }}
                  >
                    {row.First_Week_Mortality_Percentage}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Mortality Quantity"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Total_Mortality}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Mortality Percentage"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Total_Mortality_Percentage}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Lifting EA"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Lifting_EA}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Lift Percentage"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Lift_Percentage}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Balance Birds"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Bal_Birds}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["FCR"] || "auto", padding: 0 }}
                  >
                    {row.FCR}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Avg Body Weight"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.ABWT}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["BWT Age"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.BWT_Age}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Feed Cons"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Feed_Cons}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Lifted Avg Weight"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Avg_Lift_Wt}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Prev Grade"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Prev_Grade}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Mobile Number"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Mobile}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Hatch Date"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Hatch_Date}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: columnWidths["Line"] || "auto", padding: 0 }}
                  >
                    {row.Line}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Hatchery Name"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.Hatchery_Name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Last Modified On"] || "auto",
                      padding: 0,
                    }}
                  >
                    {row.updatedAt}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: columnWidths["Status"] || "auto",
                      padding: 0,
                      position: "sticky", // Make the cell sticky
                      right: "120px", // Align it next to the Action column
                      backgroundColor: "#EEF0F4", // Set background color for visibility
                      zIndex: 10, // Higher z-index to stay above other cells
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    }}
                  >
                    {row.status === "-" ? (
                      "-"
                    ) : (
                      <Chip
                        label={row.status}
                        color={`${getColorForTag(row.status)}`}
                        size="small"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => handleChipClick(row)}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "120px",
                      padding: 1,
                      position: "sticky", // Make the cell sticky
                      right: 0, // Fix to the right side
                      backgroundColor: "#fff", // Set background color for visibility
                      zIndex: 10, // Higher z-index to stay above other cells
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Add a shadow to the column
                    }}
                  >
                    <IconCell row={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalCount} // Adjust this to reflect the total count of records, if available
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        open={openStatusDialog}
        onClose={handleStatusDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{"Confirm Status Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to change the status for this ${statusLotNumber}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStatusChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* <AlertTitle>{alertTitle}</AlertTitle> */}
          {alertTitle}
        </Alert>
      </Snackbar>
    </>
  );
}

export default OpenDataListComponent;
