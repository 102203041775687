import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import './mobileDatatableStyles.css';
import { useNavigate } from 'react-router-dom';
import { DOWNLOAD_AND_GET_CONVERTED, ESTIMATION_DOC_DOWNLOAD } from '../../constants';
import axios from 'axios';

function MobileConvertedDatatable({data, setSeverity, setMessage, setOpenSnackbar, setLoading}) {

  const navigate = useNavigate();

    useEffect(() => {
  
        // Initialize DataTable
        const tableId = '#example_meeting';
          
        // Destroy any existing DataTable instance before reinitializing
        if ($.fn.DataTable.isDataTable(tableId)) {
          $(tableId).DataTable().clear().destroy();
        }
    
          const table = $('#example_meeting').DataTable({
            responsive: true,
            data: data ,
            columns: [
            { data: 'sno', title: 'SNo.', orderable: false},
            {
              data: 'status',
              title: 'Status',
              render: function(data, type, row) {
                  let className = '';
                  let chipText = data;

                  switch (data) {
                      case 'Pending':
                          className = 'chip chip-pending';
                          break;
                      case 'Generated':
                          className = 'chip chip-completed';
                          break;
                      case 'Need for Approval':
                          className = 'chip chip-delayed';
                          break;
                       case 'converted':
                            className = 'chip chip-completed';
                            break;
                      default:
                          className = 'chip';
                          break;
                  }

                  return `<span class="${className}">${chipText}</span>`;
              }
            },
           
            { data: 'estimationNumber', title: 'Estimation Number' },
            { data: null,
                title: 'Action',
                orderable: false,
                render: function(data, type, row) {
                    return '<button class="btn action-btn view-btn"><i class="fa fa-eye"></i></button> <button class="btn action-btn download-btn"><i class="fa fa-download"></i></button>';
                  
                }
              },
            { data: 'estimationNumber', title: 'Last Estimation Number' },
            { data: 'estimation_amount', title: 'Estimation Value' },
            { data: 'firm_farmer_name', title: 'Firm/Farmer Name' },
            { data: 'EstimationRequirement', title: 'Estimation Requirement' },
            { data: 'follow_up_date', title: 'Follow-up Date' },
            { data: 'closure_month', title: 'Closure Month' },
            { data: 'category', title: 'Category' },
            { data: 'sub_category', title: 'Sub Category' },
            { data: 'createdAt', title: 'Created Date'},
            { data: 'CivilConstructedStarted', title: 'Civil Construction Started' },
            { data: 'ShedLength', title: 'Shed Length' },
            { data: 'EquipementPlacementLength', title: 'Equipment Placement Length' },
            { data: 'ShedWidth', title: 'Shed Width' },
            { data: 'CShape', title: 'C-Shape' },
            { data: 'ModalType', title: 'Model Type' },
            { data: 'SideWallColumnToColumnGap', title: 'Side Wall Column to Column Gap' },
            { data: 'NumberOfSheds', title: 'Number of Sheds' },
            { data: 'CurtainRequirment', title: 'Curtain Requirement' },
            { data: 'DiselBrooderRequirment', title: 'Diesel Brooder Requirement' },
            { data: 'PowerSupply', title: 'Power Supply' },
            { data: 'WaterSupply', title: 'Water Available' },
            { data: 'Remarks', title: 'Remarks' },
          ],
            rowReorder: {
              selector: 'td:nth-child(2)'
            }
            ,
            // scrollY: '400px',
          scrollCollapse: true
          });

          var dtLengthElement = document.querySelector('.dt-length');
          var dtSearchElement = document.querySelector('.dt-search');
            if (dtLengthElement) {
                // Get the outer div
                var outerDiv = dtLengthElement.closest('.col-md-auto.me-auto');
                var outerDiv1 = dtSearchElement.closest('.col-md-auto.ms-auto ');
                outerDiv.style.width = '10%';
                outerDiv1.style.width = '70%';
                // Now you can do something with the outerDiv
            
            }
          
            // Event listener for action buttons
            // $(tableId).on('click', 'button.nav-btn', function () {
            //   const rowData = table.row($(this).closest('tr')).data();
            //   if (rowData) {
            //     console.log('Button clicked for row:', rowData);
            //     navigate('/mobilebdmpage/converted');
            //   } else {
            //     console.error("Row data not found for button click");
            //   }
            // });

            $(tableId).on('click', 'button.download-btn', function () {
              const rowData = table.row($(this).closest('tr')).data();
              if (rowData) {
                console.log('Button clicked for row:', rowData);
                handleDownloadClick(rowData);
              } else {
                console.error("Row data not found for button click");
              }
            });

            $(tableId).on('click', 'button.view-btn', function () {
                const rowData = table.row($(this).closest('tr')).data();
                if (rowData) {
                  console.log('Button clicked for row:', rowData);
                  navigate('/mobilebdmpage/converted/view');
                  // handleDownloadDoc(rowData);
                } else {
                  console.error("Row data not found for button click");
                }
              });
    
          // Cleanup function to destroy the DataTable instance when the component unmounts
          return () => {
           table.destroy();
          };
        
        
      }, [data]);

      const handleDownloadClick = (row) => {
        setLoading(true);
        axios.get(`${DOWNLOAD_AND_GET_CONVERTED}?LeadDetailId=${row.LeadDetailId}&zip=true`,{
            responseType: 'blob',
        }).then(response => {
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Lead_documents.zip'); // Specify the filename here
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url); // Clean up after download
            
            setLoading(false);
            
            setSeverity('success');
            setMessage('Documents Downloaded Successfully');
            setOpenSnackbar(true);

            }).catch(error => {
                setSeverity('error');
                setMessage('Unable to Download.');
                setOpenSnackbar(true);
                setLoading(false);
            });
    };


    return(
        <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/2.0.8/css/dataTables.dataTables.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/rowreorder/1.5.0/css/rowReorder.dataTables.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/responsive/3.0.2/css/responsive.dataTables.css"
        />
        <style>
          {`
          .chip {
                display: inline-block;
                padding: 0.25rem 0.5rem;
                font-size: 0.75rem;
                font-weight: 600;
                border-radius: 12px;
                text-align: center;
                color: white;
            }
            .chip-pending {
                background-color: #ffc107; /* Yellow */
            }
            .chip-completed {
                background-color: #4caf50; /* Green */
            }
            .chip-delayed {
                background-color: #f44336; /* Red */
            }

            /* Hide the label "Entries per page" */
            .dt-length label {
              display: none;
            }
            /* Hide the label "Search" */
            .dt-search label {
              display: none;
            }

            .dt-container .dt-search {
                 margin-top: 0px !important;
            }

            
          `}
        </style>
      </Helmet>
      <div className='dt-layout-row dt-layout-table' >
        <div className='dt-layout-cell '>
          <table id="example_meeting" className="table table-striped table-hover table-bordered" style={{ width: '100%', position: 'relative', fontSize: '0.8rem' }}>
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Status</th>
                <th>Action</th>
                <th>Estimation Number</th>
                <th>Last Estimation Number</th>
                <th>Estimation Value</th>
                <th>Firm/Farmer Name</th>
                <th>Estimation Requirement</th>
                <th>Follow-up Date</th>
                <th>Closure Month</th>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>Created Date</th>
                <th>Civil Construction Started</th>
                <th>Shed Length</th>
                <th>Equipment Placement Length</th>
                <th>Shed Width</th>
                <th>C-Shape</th>
                <th>Model Type</th>
                <th>Side Wall Column to Column Gap</th>
                <th>Number of Sheds</th>
                <th>Curtain Requirement</th>
                <th>Diesel Brooder Requirement</th>
                <th>Power Supply</th>
                <th>Water Available</th>
                <th>Remarks</th>
              </tr>
            </thead>
          </table>
      </div>
      </div>
    </>
    );

}

export default MobileConvertedDatatable;