import React from 'react';
import { DataGrid, GridRowsProp, GridColDef , GridToolbar, GridCellParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';

function WarmDispositionSummaryComponent() {
const rows = [
    { id: 1, 
        col1: 'Raj Sharma', 
        col2: '349298479284',
        col3: '834738439834',
        col4: 'Chhattisgarh',
        col5: 'Campaign1',
        col6: ['Warm'],
     },
     { id: 2, 
        col1: 'Pratik Sharma', 
        col2: '349298479284',
        col3: '834738439834',
        col4: 'Chhattisgarh',
        col5: 'Campaign1',
        col6: ['Warm'],
     },
  ];


  const columns = [
    { field: 'col1', headerName: 'Customer Name',flex: 1, headerAlign: 'center', type: 'string', headerClassName: 'super-app-theme--header', responsive: true},
    { field: 'col2', headerName: 'Mobile Number',flex: 1, headerAlign: 'center', type: 'number', headerClassName: 'super-app-theme--header', responsive: true },
    { field: 'col3', headerName: 'Alternate Number', flex: 1, headerAlign: 'center', type: 'number', headerClassName: 'super-app-theme--header', responsive: true },
    { field: 'col4', headerName: 'State' , flex: 1, headerAlign: 'center', type: 'string', headerClassName: 'super-app-theme--header', responsive: true},
    { field: 'col5', headerName: 'Campaign', flex: 1, headerAlign: 'center' , type: 'string', headerClassName: 'super-app-theme--header', responsive: true},
    { field: 'col6', headerName: 'Category', flex: 1, headerAlign: 'center' , type: 'string', headerClassName: 'super-app-theme--header', responsive: true,
        renderCell: (params) => (
        <div>
          {params.value.map((tag, index) => (
            <span
              key={index}
              style={{
                display: 'inline-block',
                backgroundColor: getColorForTag(tag), // Function to determine tag color
                color: '#fff',
                padding: '2px 8px',
                borderRadius: '4px',
                margin: '4px 4px',
              }}
            >
              {tag}
            </span>
          ))}
        </div>
      ),},
  ];

  const getColorForTag = (tag) => {
    switch (tag) {
      case 'Hot':
        return '#61DAFB'; // React blue
      case 'Warm':
        return '#f0db4f'; // JavaScript yellow
      case 'Cold':
        return '#0081cb'; // UI dark blue
      default:
        return '#888'; // Default gray
    }
  };

return (
    <>

    <h5 className={`text-xl mb-4`}>Warm Disposition Summary</h5>

    <Box
      sx={{
        height: 'auto',
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden'
      }}
    >
      <DataGrid 
      columnHeaderHeight={50} 
      getRowHeight={() => 'auto'} 
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}   
      rows={rows} 
      columns={columns} 
      sx={{
        boxShadow: 2,
        borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {
          color: 'primary.main',
        },
        '& .MuiDataGrid-cell': {
          textAlign: 'center',
        },
        '& .MuiDataGrid-columnHeader':{
            backgroundColor: '#EEF0F4'
        }
        
      }}
      autoHeight
      disableExtendRowFullWidth

    //   checkboxSelection='true'
      />
    </Box>
    </>
);
}

export default WarmDispositionSummaryComponent;