import * as React from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import newClass from "../../icons/new_calls.png";
import meeting from "../../icons/meeting.png";
import call_enquiry from "../../icons/call_enquiry.png";
import confirmation from "../../icons/confirmation.png";
import site_visit from "../../icons/site_visit.png";
import estimate from "../../icons/estimate.png";
import closed from "../../icons/closed.png";
import hot from "../../icons/hot.png";
import cold from "../../icons/cold.png";
import { Stack, Grid } from "@mui/material";
import bdmDashboardStyles from "./bdmDashboardStyles.module.css";
import { useMediaQuery, useTheme, Chip } from "@mui/material";
import axios from "axios";
import { GET_LEAD_BY_BDM_API } from "../../constants";
import dashboradStyles from "../Agent Dashboard/dashboardStyles.module.css";
import { useState } from "react";
import { useEffect } from "react";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import WavesIcon from "@mui/icons-material/Waves";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import FunctionsIcon from "@mui/icons-material/Functions";

function BDMPerformance() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [hotLeadCount, setHotCountLead] = useState(0);
  const [warmLeadCount, setWarmCountLead] = useState(0);
  const [coldLeadCount, setColdCountLead] = useState(0);
  const [pendingLeadCount, setPendingCountLead] = useState(0);
  const [closedLeadCount, setClosedCountLead] = useState(0);
  const [allLeadCount, setAllLeadCount] = useState(0);

  useEffect(() => {
    const empId = localStorage.getItem("username");
    const requestBody = {};
    axios
      .get(`${GET_LEAD_BY_BDM_API}/${empId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads, counts } = response.data;

        if (counts.length == 0 || !counts || counts == undefined) {
          // Expected keys with default values
          setHotCountLead(0);
          setWarmCountLead(0);
          setColdCountLead(0);
          setClosedCountLead(0);
          setPendingCountLead(0);
          setAllLeadCount(0);
        } else {
          const expectedKeys = [
            "total",
            "warm",
            "hot",
            "cold",
            "pending",
            "closed",
          ];
          console.log(counts);
          // Normalize the keys and ensure all expected keys are present
          const normalizedCounts = expectedKeys.reduce((acc, key) => {
            const normalizedKey = key.toLowerCase(); // Normalize expected key to lowercase
            const value = Object.entries(counts).reduce(
              (sum, [currentKey, currentValue]) => {
                return normalizedKey === currentKey.toLowerCase()
                  ? sum + currentValue
                  : sum;
              },
              0
            );
            acc[normalizedKey] = value; // Assign the summed value or 0 if not found
            return acc;
          }, {});

          setHotCountLead(normalizedCounts.hot);
          setWarmCountLead(normalizedCounts.warm);
          setColdCountLead(normalizedCounts.cold);
          setClosedCountLead(normalizedCounts.closed);
          setPendingCountLead(normalizedCounts.pending);
          setAllLeadCount(normalizedCounts.total);
        }
      })
      .catch((error) => {});
  }, []);

  // Function to handle card click and navigate with state
  const handleCardClick = (label) => {
    let status;
    switch (label) {
      case "Hot":
        status = 0;
        break;
      case "Warm":
        status = 1;
        break;
      case "Cold":
        status = 2;
        break;
      case "Pending":
        status = 3;
        break;
      case "Closed":
        status = 4;
        break;
      case "Total":
        status = 5;
        break;
      default:
        status = null;
    }
    navigate("/bdmAssigned", { state: { tab: status } });
  };

  return (
    <>
      <Grid container spacing={1} className={`mb-4`}>
        {[
          {
            label: "Hot",
            value: hotLeadCount,
            imgSrc: (
              <WhatshotIcon
                className={`w-3/4 float-right ${dashboradStyles.total_calls_icon} ${dashboradStyles.font_size}`}
              ></WhatshotIcon>
            ),
          },
          {
            label: "Warm",
            value: warmLeadCount,
            imgSrc: (
              <WavesIcon
                className={`w-3/4 float-right ${dashboradStyles.font_size}`}
                color="secondary"
              ></WavesIcon>
            ),
          },
          {
            label: "Cold",
            value: coldLeadCount,
            imgSrc: (
              <AcUnitIcon
                className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`}
              ></AcUnitIcon>
            ),
          },
          {
            label: "Pending",
            value: pendingLeadCount,
            imgSrc: (
              <PendingActionsIcon
                className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`}
                color="disabled"
              />
            ),
          },
          {
            label: "Closed",
            value: closedLeadCount,
            imgSrc: (
              <CloseIcon
                className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`}
                sx={{ color: "#000" }}
              ></CloseIcon>
            ),
          },
          {
            label: "Total",
            value: allLeadCount,
            imgSrc: (
              <FunctionsIcon
                className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`}
                color="primary"
              ></FunctionsIcon>
            ),
          },
        ].map((item, index) => (
          <Grid item xs={6} sm={3}>
            <Paper
              className={`flex flex-col place-items-center justify-center h-full p-1`}
              onClick={() => handleCardClick(item.label)} // Attach click handler
            >
              <div className={`w-full text-center`}>
                <p
                  className={`font-bold ${smallScreen ? "text-xs" : "text-sm"}`}
                >
                  {item.label}
                </p>
                <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                <p className={`font-bold text-xl`}>{item.value}</p>{" "}
                {/* Smaller count font */}
              </div>
              {item.imgSrc}
              {/* <img src={item.imgSrc} loading="lazy" alt='totalCalls' className={`w-1/4`} /> Smaller image */}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default BDMPerformance;
