import React, {useContext, useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { LoginContext } from '../LoginPage/LoginContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import MobileRemarkDatatable from "./MobileRemarkDatatable";
import axios from "axios";
import { ENQUIRY_TYPE, PROJECT, CATEGORY,LEAD_SOURCE,CLOSED_SUB_CATEGORY, COLD_SUB_CATEGORY, HOT_SUB_CATEGORY, PENDING_SUB_CATEGORY, WARM_SUB_CATEGORY, CREATE_LEAD_API , STATE_API, CALL_TYPE, TITLE, BDM_LIST, CALL_STATUS, BDM_REMARK_UPDATE , GET_REMARK_LIST, UPDATE_STATUS_AGENT, PAGE_SECTIONS, GET_MEETING, GET_SITE_VISIT, GET_ESTIMATION } from '../../constants';
import MobileSiteVisitDatatable from "./MobileSiteVisitDatatable";
import SiteVisitFormComponent from "../BDM Assigned Page/siteVisitForm";
import MobileEstimationDatatable from "./MobileEstimationDatatable";
import EstimationFormComponent from "../BDM Assigned Page/estimationForm";
import Loader from "../reusable Component/Loader";
import moment from "moment";
import { Alert, Snackbar } from "@mui/material";


function MobileEstimationSection({setEstimationForm,leadDetails}) {

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const [runEffect, setRunEffect] = useState(false);

    useEffect(() => { 
      setLoading(true);
        axios.get(`${GET_ESTIMATION}/${leadDetails.id}/estimation`,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
        .then(response => {
          setRunEffect(false);
          handlingEstimationRowData(response.data);
          setLoading(false);
        }) 
        .catch(error => {
          setLoading(false);
        });

    },[runEffect]);

    const [finalEstimationRow, setEstimationRow] = useState([]);
    const handlingEstimationRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "status": rd.status,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement.toUpperCase(),
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth == true ? 'Yes' : 'No',
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply == true ? 'Yes' : 'No',
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category.toUpperCase(),
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : formatDate(rd.follow_up_date),
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : formatDate(rd.createdAt),
            "LeadDetailId": rd.LeadDetailId
        }
        transformedRows.push(a);
        })
        setEstimationRow(transformedRows); 
    };

    const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
    };


return(
    <>
    {loading && <Loader open={loading} />}
    <Accordion style={{marginTop: '5px'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Estimation List</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '8px 9px 16px'}}>
                <MobileEstimationDatatable data={finalEstimationRow}></MobileEstimationDatatable>
        </AccordionDetails>
    </Accordion>

    <Accordion style={{marginTop: '5px'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Estimation Form</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '8px 9px 16px'}}>
            <EstimationFormComponent  setEstimationForm={setEstimationForm} setSeverity={setSeverity} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} setRunEffect={setRunEffect} leadDetailsId={leadDetails.id}/>
        </AccordionDetails>
    </Accordion>

    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
    </Snackbar>
    </>
);

}

export default MobileEstimationSection;