import React, { useState, useEffect, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BiLeadListComponent from './BiLeadList';
import { GET_BI_LIST } from '../../constants';
import Loader from '../reusable Component/Loader';
import axios from 'axios';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import BiClosedTableComponent from './BiClosedTableComponent';
import BiAllTableComponent from './AllStatusTableComponent';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

function BiTabComponent() {
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const empDetails = JSON.parse(localStorage.getItem('userData'));
    const [loading, setLoading] = useState(false);
    const [rowOpen, setRowOpen] = useState([]);
    const [rowClosed, setRowClosed] = useState([]);
    const [rowAll, setRowAll] = useState([]);

    useEffect(() => {
        setLoading(true);
        fetchBiLeadList();
    }, []);

    const fetchBiLeadList = () => {
        axios.get(`${GET_BI_LIST}?agentId=10015276`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        })
        .then(response => {
            const { data } = response.data;
            handleRowData(data);
            setTimeout(() => setLoading(false), 200);
        })
        .catch(error => {
            console.error('Error fetching BI list:', error);
            setLoading(false);
        });
    };

    const handleRowData = (data) => {
        const transformRow = (rd, index) => ({
            id: index + 1,
            SNo: index + 1,
            Branch: rd.Branch_Name,
            Branch_Description: rd.Branch_Description,
            Farm_Name: rd.Farm_Name,
            Farmer_Mob: rd.Farmer_Mob,
            Lot_Number: rd.Lot_Number,
            Age: rd.Age,
            Chicks_Housed_Quantity: rd.Chicks_Housed_Quantity,
            Mortality_Quantity: rd.Mortality_Quantity,
            Mortality_Percentage: rd.Mortality_Percentage,
            Balance_Birds: rd.Balance_Birds,
            Mort_Percentage_On_Date: rd.Mort_Percentage_On_Date,
            Mort_Percentage_Date_1: rd.Mort_Percentage_Date_1,
            Mort_Percentage_Date_2: rd.Mort_Percentage_Date_2,
            Mort_Percentage_Date_3: rd.Mort_Percentage_Date_3,
            Mort_Percentage_Date_4: rd.Mort_Percentage_Date_4,
            last_action_date: rd.last_action_date,
            createdAt: rd.createdAt,
            updatedAt: new Date(rd.updatedAt),
            status: rd.status
        });

        setRowOpen(data.filter(rd => rd.status === 'open').map(transformRow));
        setRowClosed(data.filter(rd => rd.status === 'closed').map(transformRow));
        setRowAll(data.map(transformRow));
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTraderClick = () => {
        navigate('/biTraderList');
    };

    const handleInHouseClick = (e) => {
        e.preventDefault();
        // Add your logic for InHouse click here
    };

    return (
        <>        
            {loading && <Loader open={loading} />}
            
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={2}>
                    <Button variant="contained" color="primary" onClick={handleInHouseClick}>
                        DayOp
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleTraderClick}>
                        BI
                    </Button>
                </Box>
            </Box>

            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="BI status tabs"
            >
                <Tab label={`Open (${rowOpen.length})`} id="tab-0" icon={<NewReleasesIcon />} iconPosition="start" />
                <Tab label={`Closed (${rowClosed.length})`} id="tab-1" icon={<VerifiedIcon />} iconPosition="start" />
                {/* <Tab label={`All (${rowAll.length})`} id="tab-2" /> */}
            </Tabs>

            <TabPanel value={value} index={0}>
                <BiLeadListComponent rowOpen={rowOpen} setRunEffect={() => fetchBiLeadList()} />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <BiClosedTableComponent rowClosed={rowClosed} setRunEffect={() => fetchBiLeadList()} />
            </TabPanel>

            {/* <TabPanel value={value} index={2}>
                <BiAllTableComponent rowAll={rowAll} setRunEffect={() => fetchBiLeadList()} />
            </TabPanel> */}
        </>
    );
}

export default BiTabComponent;