 








import React, { useState, useEffect } from "react";
import axios from "axios";
import companySymbol from '../../icons/company-symbol.png';  // Adjust the path based on your component's location
import companyLogo from '../../icons/company-logo.png';
import ParivartanPDF from '../../icons/Parivartan_2024.pdf';
import GenNxtPDF from '../../icons/parivartan_Gn_Nxt_2024.pdf' 
import { BASE_URL } from '../../constants';

const CustomerLeadForm = () => {
  const [formData, setFormData] = useState({
    CustomerName: "",
    ContactNumber: "",
    pincode: "",
    StateName: "",
    location: "",
    otherLocation: "",
    CustomerMailId: "",
    EC_Shed_Plan: "",
    LandAvailable: null,
    Land_Size: "",
    Unit: "",
    Electricity: "",
    WaterAvailabilty: null,
    ApproachableRoad: null,
    IntegrationCompany: "",
    ShedSize: "",
    CurrentShedDirection: "",
    ElectricityPhase: "",
    CurrentBirdCapacity: "",
    Investment_Budget: "",
    Project: "", // Add the new "Project" field
    NUmberOfShed: "",
    Source: "",
    Remark: "",
  });
  const [pincodeData, setPincodeData] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
         `${BASE_URL}/customer-lead`,
        formData
      );
      console.log(response.data);
      // alert("Form submitted successfully!");
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again.");
    }
  };
  useEffect(() => {
    const fetchPincodeData = async () => {
      if (formData.pincode.length === 6) {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${formData.pincode}`
          );
          setPincodeData(response.data[0]);
          if (response.data[0]?.PostOffice) {
            setFormData((prevData) => ({
              ...prevData,
              StateName: response.data[0]?.PostOffice[0]?.State || "",
            }));
            setLocationOptions(
              response.data[0]?.PostOffice.map((office) => office.Name)
            );
          } else {
            setFormData((prevData) => ({
              ...prevData,
              StateName: "",
              location: "",
            }));
            setLocationOptions([]);
          }
        } catch (error) {
          console.error("Error fetching pincode data:", error);
          setFormData((prevData) => ({
            ...prevData,
            StateName: "",
            location: "",
          }));
          setLocationOptions([]);
        }
      } else {
        setFormData((prevData) => ({
          ...prevData,
          StateName: "",
          location: "",
        }));
        setPincodeData(null);
        setLocationOptions([]);
      }
    };

    fetchPincodeData();
  }, [formData.pincode]);

  const handleBack = () => {
    setFormSubmitted(false);
    setFormData({
      CustomerName: "",
      ContactNumber: "",
      WhatsAppNumber: "",
      SameAsContact: false,
      pincode: "",
      StateName: "",
      location: "",
      otherLocation: "",
      CustomerMailId: "",
      EC_Shed_Plan: "",
      LandAvailable: null,
      Land_Size: "",
      Unit: "",
      Electricity: "",
      WaterAvailabilty: null,
      ApproachableRoad: null,
      IntegrationCompany: "",
      ShedSize: "",
      CurrentShedDirection: "",
      ElectricityPhase: "",
      CurrentBirdCapacity: "",
      Investment_Budget: "",
      NUmberOfShed: "",
      Source: "",
      Remark: ""
    });
  };
  if (formSubmitted) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-8 max-w-md w-full">
          <div className="text-center">
            <div className="mb-4">
              <svg
                className="mx-auto h-16 w-16 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Thank You for Your Inquiry!
            </h2>
            <p className="text-gray-600 mb-8">
              We have received your information and will get back to you soon.
            </p>
            <div className="flex justify-between items-center">
              <button
                onClick={handleBack}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 focus:outline-none transition-colors"
              >
                Back to Form
              </button>
              <a
                href="https://www.ibgroup.co.in/business-solutions"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none transition-colors"
              >
                Visit Our Website
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const clearShedPlan = () => {
    // handleFieldChange("EC_Shed_Plan", "");
    console.log("ShedPlan cleared", formData); // Log clearing action
  };

  // const resetFields = () => {
  //   setFormData(initialFormData);
  // };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenSnackbar(false);
  };

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md">
      <div className="bg-gradient-to-r from-green-600 to-green-500 py-4 px-6 mb-6 rounded-t-lg">
    <div className="flex items-center justify-between">
      {/* Left Side - Company Symbol with Name */}
      <div className="flex items-center gap-4">
        <img 
          // src="src/assets/company-symbol.png"
          src={companySymbol}
          alt="Company Symbol" 
          className="h-14 w-14 p-1 bg-white rounded-full shadow-lg"
        />
        <div className="h-12 w-px bg-white/30 mx-2"/> {/* Vertical Divider */}
        
        <h2 className="text-xl sm:text-2xl font-bold text-white tracking-wide drop-shadow-md">
  INQUIRY FORM
</h2>


      </div>
      
      {/* Right Side - Logo with background */}
      <div className="bg-white/12 p-1 rounded-lg">
        <img 
         src={companyLogo} 
          alt="Company Logo" 
          className="h-12 w-auto"
        />
      </div>
    </div>

    
  </div>
 
<div className="flex justify-end mt-2">
    <div className="flex flex-wrap justify-end gap-2">
     <span className="text-black-600 text-sm whitespace-nowrap">Download Brochure -</span>
      <a 
      
      href={ParivartanPDF}
      download="Parivartan.pdf"
      target="_blank"
      rel="noopener noreferrer"
      className="text-green-600 text-sm underline hover:text-green-600 whitespace-nowrap"
    >
        Parivartan
      </a>
     
      <a
       href={GenNxtPDF}
       download="Gen_Nxt.pdf"
       target="_blank"
       rel="noopener noreferrer"
       className="text-green-600 text-sm underline hover:text-green-600 whitespace-nowrap mr-3"
       >
        Gen Nxt
      </a>
    </div>
  </div>


  

      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-3.5 p-3"
      >
        <div >
          <label
            htmlFor="CustomerName"
            className="block mb-1 font-bold text-gray-700"
          >
            Customer Name
          </label>

          <input
            type="text"
            id="CustomerName"
            name="CustomerName"
            value={formData.CustomerName}
            onChange={handleChange}
            onKeyDown={(e) => {
              const key = e.key;
              if (
                !/^[A-Za-z\s]$/.test(key) &&
                key !== "Backspace" &&
                key !== "Delete" &&
                key !== "ArrowLeft" &&
                key !== "ArrowRight"
              ) {
                e.preventDefault();
              }
            }}
            required
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          />
        </div>
        <div>
  <div className="block mb-1">
    <div className="flex items-center gap-2">
      <label
        htmlFor="ContactNumber"
        className="font-bold text-gray-700"
      >
        Contact Number
      </label>
      <label className="flex items-center">
        <input
          type="checkbox"
          id="SameAsContact"
          name="SameAsContact"
          checked={formData.SameAsContact}
          onChange={handleChange}
          className="mr-2"
        />
        <span className="text-gray-700">Same No. For WhatsApp</span>
      </label>
    </div>
  </div>
  <input
    type="tel"
    id="ContactNumber"
    name="ContactNumber"
    value={formData.ContactNumber}
    onChange={(e) => {
      handleChange(e);
      setFormData((prevData) => ({
        ...prevData,
        ContactNumber: e.target.value.slice(0, 10),
        WhatsAppNumber: prevData.SameAsContact
          ? e.target.value.slice(0, 10)
          : prevData.WhatsAppNumber,
      }));
      if (e.target.value.length < 10) {
        e.target.setCustomValidity(
          "Contact Number must be 10 digits"
        );
      } else {
        e.target.setCustomValidity("");
      }
    }}
    onKeyDown={(e) => {
      const key = e.key;
      if (
        !/^\d$/.test(key) &&
        key !== "Backspace" &&
        key !== "Delete" &&
        key !== "ArrowLeft" &&
        key !== "ArrowRight"
      ) {
        e.preventDefault();
      }
    }}
    maxLength={10}
    required
    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
  />
</div>

{!formData.SameAsContact && (
  <div>
    <label
      htmlFor="WhatsAppNumber"
      className="block mb-1 font-bold text-gray-700"
    >
      WhatsApp Number
    </label>
    <input
      type="tel"
      id="WhatsAppNumber"
      name="WhatsAppNumber"
      value={formData.WhatsAppNumber}
      onChange={(e) => {
        handleChange(e);
        setFormData((prevData) => ({
          ...prevData,
          WhatsAppNumber: e.target.value.slice(0, 10),
        }));
        if (e.target.value.length < 10) {
          e.target.setCustomValidity(
            "WhatsApp Number must be 10 digits"
          );
        } else {
          e.target.setCustomValidity("");
        }
      }}
      onKeyDown={(e) => {
        const key = e.key;
        if (
          !/^\d$/.test(key) &&
          key !== "Backspace" &&
          key !== "Delete" &&
          key !== "ArrowLeft" &&
          key !== "ArrowRight"
        ) {
          e.preventDefault();
        }
      }}
      maxLength={10}
      required
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
    />
  </div>
)}

        <div>
          <label
            htmlFor="pincode"
            className="block mb-1 font-bold text-gray-700"
          >
            Pincode
          </label>
         

<input
  type="text"
  id="pincode"
  name="pincode"
  value={formData.pincode}
  maxLength={6}
  onChange={(e) => {
    // Only allow numeric values
    const value = e.target.value.replace(/[^0-9]/g, '');
    setFormData(prev => ({
      ...prev,
      pincode: value
    }));
  }}
  onKeyDown={(e) => {
    // Prevent non-numeric key inputs except for backspace, delete, and arrow keys
    if (
      !/^\d$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }
  }}
  onInvalid={(e) => {
    e.target.setCustomValidity("");
    if (!e.target.validity.valid) {
      e.target.setCustomValidity(
        "Please enter a valid 6-digit pincode"
      );
    }
  }}
  onInput={(e) => e.target.setCustomValidity("")}
  pattern="\d{6}"
  required
  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
/>
          <p className="text-red-500 text-sm mt-1 hidden" id="pincodeError">
            Please enter a valid 6-digit pincode
          </p>
        </div>
        <div>
          <label
            htmlFor="StateName"
            className="block mb-1 font-bold text-gray-700"
          >
            State
          </label>
          <input
            type="text"
            id="StateName"
            name="StateName"
            value={formData.StateName}
            onChange={handleChange}
            required
            readOnly
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none bg-gray-100"
          />
        </div>
        <div>
          <label
            htmlFor="location"
            className="block mb-1 font-bold text-gray-700"
          >
            Location
          </label>
          <select
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required={formData.StateName !== ""}
            disabled={formData.StateName === ""}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          >
            <option value="">Select a location</option>
            {locationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
            {formData.StateName !== "" && <option value="Other">Other</option>}
          </select>
        </div>
        {formData.location === "Other" && formData.StateName !== "" && (
          <div>
            <label
              htmlFor="otherLocation"
              className="block mb-1 font-bold text-gray-700"
            >
              Other Location
            </label>
            <input
              type="text"
              id="otherLocation"
              name="otherLocation"
              value={formData.otherLocation}
              onChange={handleChange}
              pattern="^[A-Za-z\s]+$"
              title="Please enter only letters and spaces"
              required
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            />
          </div>
        )}

        <div>
          <label
            htmlFor="CustomerMailId"
            className="block mb-1 font-bold text-gray-700"
          >
            Customer Email
          </label>
          <input
            type="email"
            id="CustomerMailId"
            name="CustomerMailId"
            value={formData.CustomerMailId}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          />
        </div>
        <div className="md:col-span-2">
          <label
            htmlFor="EC_Shed_Plan"
            className="block mb-1 font-bold text-gray-700"
          >
            EC Shed Plan
          </label>
          <select
            id="EC_Shed_Plan"
            name="EC_Shed_Plan"
            value={formData.EC_Shed_Plan}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          >
            <option value="">Select an option</option>
            <option value="Planning New EC Shed">Planning New EC Shed</option>
            <option value="Open to EC Shed">Open to EC Shed</option>
          </select>
        </div>
        {formData.EC_Shed_Plan === "Planning New EC Shed" && (
          <>
            <div className="md:col-span-2">
              <label
                htmlFor="LandAvailable"
                className="block mb-1 font-bold text-gray-700"
              >
                Land Available (East West Direction)
              </label>
              <select
                id="LandAvailable"
                name="LandAvailable"
                value={
                  formData.LandAvailable === null
                    ? ""
                    : formData.LandAvailable.toString()
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    LandAvailable: e.target.value === "true",
                  })
                }
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              >
                <option value="">Select an option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            {formData.LandAvailable === true && (
              <>
                <div>
                  <label
                    htmlFor="Unit"
                    className="block mb-1 font-bold text-gray-700"
                  >
                    Unit
                  </label>
                  <select
                    id="Unit"
                    name="Unit"
                    value={formData.Unit}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        Unit: e.target.value,
                        Land_Size: "",
                      }));
                    }}
                    required
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  >
                    <option value="">Select an option</option>
                    <option value="Acres">Acres</option>
                    <option value="Beegha">Beegha</option>
                    <option value="Sq.ft.">Sq.ft.</option>
                  </select>
                </div>
                {/* <div>
                  <label
                    htmlFor="Land_Size"
                    className="block mb-1 font-bold text-gray-700"
                  >
                    Land Size
                    {formData.Unit === "Sq.ft." ? " (e.g., 200x300)" : ""}
                  </label>
                  <input
                    type="text"
                    id="Land_Size"
                    name="Land_Size"
                    required
                    value={formData.Land_Size}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (formData.Unit === "Sq.ft.") {
                        value = value.replace(/[^0-9X]/g, ""); // Remove non-digit and non-X characters
                        if (value.length <= 3) {
                          setFormData((prevData) => ({
                            ...prevData,
                            Land_Size: value,
                          }));
                        } else if (value.length === 4 && !value.includes("X")) {
                          value = value.slice(0, 3) + "X" + value.slice(3);
                          setFormData((prevData) => ({
                            ...prevData,
                            Land_Size: value,
                          }));
                        } else if (value.length <= 7 && value.includes("X")) {
                          setFormData((prevData) => ({
                            ...prevData,
                            Land_Size: value,
                          }));
                        }
                      } else {
                        value = value.replace(/[^0-9]/g, ""); // Remove non-digit characters
                        setFormData((prevData) => ({
                          ...prevData,
                          Land_Size: value,
                        }));
                      }
                    }}
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  />
                </div> */}
                <div>
  <label
    htmlFor="Land_Size"
    className="block mb-1 font-bold text-gray-700"
  >
    Land Size
    {formData.Unit === "Sq.ft." ? " (e.g., 200x300)" : ""}
  </label>
  <input
    type="text"
    id="Land_Size"
    name="Land_Size"
    required
    value={formData.Land_Size}
    onChange={(e) => {
      let value = e.target.value;
      
      if (formData.Unit === "Sq.ft.") {
        // Existing Sq.ft. validation
        value = value.replace(/[^0-9X]/g, ""); // Remove non-digit and non-X characters
        if (value.length <= 3) {
          setFormData((prevData) => ({
            ...prevData,
            Land_Size: value,
          }));
        } else if (value.length === 4 && !value.includes("X")) {
          value = value.slice(0, 3) + "X" + value.slice(3);
          setFormData((prevData) => ({
            ...prevData,
            Land_Size: value,
          }));
        } else if (value.length <= 7 && value.includes("X")) {
          setFormData((prevData) => ({
            ...prevData,
            Land_Size: value,
          }));
        }
      } else if (formData.Unit === "Acres" || formData.Unit === "Beegha") {
        // For Acres and Beegha: only allow numbers up to 4 digits
        value = value.replace(/[^0-9]/g, ""); // Remove non-digit characters
        if (value.length <= 4) { // Limit to 4 digits
          setFormData((prevData) => ({
            ...prevData,
            Land_Size: value,
          }));
        }
      }
    }}
    onKeyDown={(e) => {
      // Prevent entering more than 4 digits for Acres and Beegha
      if (formData.Unit !== "Sq.ft." && 
          formData.Land_Size.length >= 4 && 
          e.key !== 'Backspace' && 
          e.key !== 'Delete' && 
          e.key !== 'ArrowLeft' && 
          e.key !== 'ArrowRight') {
        e.preventDefault();
      }
    }}
    maxLength={formData.Unit === "Sq.ft." ? 7 : 4}
    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
  />
</div>
                <div>
                  <label
                    htmlFor="Electricity"
                    className="block mb-1 font-bold text-gray-700"
                  >
                    Electricity
                  </label>
                  <select
                    id="Electricity"
                    name="Electricity"
                    value={formData.Electricity}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  >
                    <option value="">Select an option</option>
                    <option value="Single Phase">Single Phase</option>
                    <option value="Three Phase">Three Phase</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="WaterAvailabilty"
                    className="block mb-1 font-bold text-gray-700"
                  >
                    Water Availability (10,000 liter / Day)
                  </label>
                  <select
                    id="WaterAvailabilty"
                    name="WaterAvailabilty"
                    value={
                      formData.WaterAvailabilty === null
                        ? ""
                        : formData.WaterAvailabilty.toString()
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        WaterAvailabilty: e.target.value === "true",
                      })
                    }
                    required
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  >
                    <option value="">Select an option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="md:col-span-2">
                  <label
                    htmlFor="ApproachableRoad"
                    className="block mb-1 font-bold text-gray-700"
                  >
                    Approachable Road
                  </label>
                  <select
                    id="ApproachableRoad"
                    name="ApproachableRoad"
                    value={
                      formData.ApproachableRoad === null
                        ? ""
                        : formData.ApproachableRoad.toString()
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ApproachableRoad: e.target.value === "true",
                      })
                    }
                    required
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  >
                    <option value="">Select an option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </>
            )}
          </>
        )}
        {formData.EC_Shed_Plan === "Open to EC Shed" && (
          <>
            <div>
              <label
                htmlFor="IntegrationCompany"
                className="block mb-1 font-bold text-gray-700"
              >
                Integration Company
              </label>
              <select
                id="IntegrationCompany"
                name="IntegrationCompany"
                value={formData.IntegrationCompany}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              >
                <option value="">Select an option</option>
                <option value="IB Group">IB Group</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="ShedSize"
                className="block mb-1 font-bold text-gray-700"
              >
                Shed Size (Length X Width)
              </label>
              <input
                type="text"
                id="ShedSize"
                name="ShedSize"
                value={formData.ShedSize}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^0-9X]/g, ""); // Remove non-digit and non-X characters
                  if (value.length <= 3) {
                    setFormData((prevData) => ({
                      ...prevData,
                      ShedSize: value,
                    }));
                  } else if (value.length === 4 && !value.includes("X")) {
                    value = value.slice(0, 3) + "X" + value.slice(3);
                    setFormData((prevData) => ({
                      ...prevData,
                      ShedSize: value,
                    }));
                  } else if (value.length <= 6 && value.includes("X")) {
                    setFormData((prevData) => ({
                      ...prevData,
                      ShedSize: value,
                    }));
                  }
                }}
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              />
            </div>
            <div>
              <label
                htmlFor="CurrentShedDirection"
                className="block mb-1 font-bold text-gray-700"
              >
                Current Shed Direction
              </label>
              <select
                id="CurrentShedDirection"
                name="CurrentShedDirection"
                value={formData.CurrentShedDirection}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              >
                <option value="">Select an option</option>
                <option value="East West">East West</option>
                <option value="North South">North South</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="ElectricityPhase"
                className="block mb-1 font-bold text-gray-700"
              >
                Electricity Phase
              </label>
              <select
                id="ElectricityPhase"
                name="ElectricityPhase"
                value={formData.ElectricityPhase}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              >
                <option value="">Select an option</option>
                <option value="Single Phase">Single Phase</option>
                <option value="Three Phase">Three Phase</option>
              </select>
            </div>
            <div>
  <label
    htmlFor="CurrentBirdCapacity"
    className="block mb-1 font-bold text-gray-700"
  >
    Current Bird Capacity
  </label>
  <input
    type="text"   
    id="CurrentBirdCapacity"
    name="CurrentBirdCapacity"
    value={formData.CurrentBirdCapacity}
    onChange={(e) => {
      let value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
      if (value.length <= 5) {  // Limit to 5 digits
        setFormData(prev => ({
          ...prev,
          CurrentBirdCapacity: value
        }));
      }
    }}
    onKeyDown={(e) => {
      // Prevent entering more than 5 digits
      if (formData.CurrentBirdCapacity?.length >= 5 && 
          e.key !== 'Backspace' && 
          e.key !== 'Delete' && 
          e.key !== 'ArrowLeft' && 
          e.key !== 'ArrowRight') {
        e.preventDefault();
      }
    }}
    maxLength={5}
    required
    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
  />
</div>
          </>
        )}
        <div>
          <label
            htmlFor="Investment_Budget"
            className="block mb-1 font-bold text-gray-700"
          >
            Investment Budget
          </label>
          <select
            id="Investment_Budget"
            name="Investment_Budget"
            value={formData.Investment_Budget}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          >
            <option value="">Select an option</option>
            <option value="Upto 50 lacs">Upto 50 lacs</option>
            <option value="Between 50 lacs to 1 Cr">
              Between 50 lacs to 1 Cr
            </option>
            <option value="Between 1Cr to 1.50 Cr">
              Between 1Cr to 1.50 Cr
            </option>
            <option value="Between 1.50Cr to 2Cr">Between 1.50Cr to 2Cr</option>
            <option value="Above 2 Cr">Above 2 Cr</option>
          </select>
        </div>

        <div>
  <label
    htmlFor="Project"
    className="block mb-1 font-bold text-gray-700"
  >
    Project
  </label>
  <select
    id="Project"
    name="Project"
    value={formData.Project}
    onChange={handleChange}
   
    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
  >
    <option value="">Select a project</option>
    <option value="Parivartan">Parivartan</option>
    <option value="Gen Nxt">Gen Nxt</option>
    <option value="Don't know">Don't know</option>
  </select>
</div>
        <div>
          <label
            htmlFor="NUmberOfShed"
            className="block mb-1 font-bold text-gray-700"
          >
            Number of Sheds
          </label>
          <input
            type="number"
            id="NUmberOfShed"
            name="NUmberOfShed"
            value={formData.NUmberOfShed}
            onChange={handleChange}
            onInput={(e) => {
              if (e.target.value.length > 2 || parseInt(e.target.value) > 10) {
                e.target.value = e.target.value.slice(0, 2);
              }
            }}
            required
            min="1"
            max="10"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          />
        </div>

        <div>
          <label
            htmlFor="Source"
            className="block mb-1 font-bold text-gray-700"
          >
            Source
          </label>
          <select
            id="Source"
            name="Source"
            value={formData.Source}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          >
            <option value="">Select a source</option>
            <option value="Hyderabad Expo 24">Hyderabad Expo 24</option>
            <option value="Lucknow Expo 24">Lucknow Expo 24</option>
            <option value="Company Website">Company Website</option>
            <option value="Friends">Friends</option>
            <option value="Other Farmers">Other Farmers</option>
            <option value="Facebook">Facebook</option>
            <option value="WhatsApp Messages">WhatsApp Messages</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="md:col-span-2">
          <label
            htmlFor="Remark"
            className="block mb-1 font-bold text-gray-700"
          >
            Remark
          </label>
          <textarea
            id="Remark"
            name="Remark"
            value={formData.Remark}
            maxLength={250}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          ></textarea>
          <p className="text-sm text-gray-500">
            {formData.Remark.length} characters
          </p>
        </div>
        <div className="md:col-span-2">
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
export default CustomerLeadForm;





