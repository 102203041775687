import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Stack, Grid } from "@mui/material";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import bdmFormStyles from "./bdmFormStyles.module.css";
import dayjs from "dayjs";
import axios from "axios";
import {
  ENQUIRY_TYPE,
  PROJECT,
  CATEGORY,
  LEAD_SOURCE,
  HOT_SUB_CATEGORY,
  WARM_SUB_CATEGORY,
  COLD_SUB_CATEGORY,
  CLOSED_SUB_CATEGORY,
  PENDING_SUB_CATEGORY,
  STATE_API,
  BDM_REMARK_UPDATE,
} from "../../constants";

function BdmFormComponent({
  rowDetails,
  setOpen,
  setOpenSnackbar,
  setSeverity,
  setMessage,
}) {
  console.log(rowDetails);
  // const [enquiryType, setEnquiryType] = useState(Object.keys(rowDetails).length > 0 ? rowDetails.row.enquiry_type : '');
  // const [project, setProject] = useState(Object.keys(rowDetails).length > 0 ? rowDetails.row.project : '');
  const [name, setName] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.CustomerName : ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.MobileNo : ""
  );
  const [whatsappNumber, setWhatsappNumber] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.WhatsappNo : ""
  );
  const [alternateNumber, setAlternateNumber] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.AlternateMobileNo : ""
  );
  const [mailId, setMailId] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.CustomerMailId : ""
  );
  const [pincode, setPincode] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.pincode : ""
  );
  const [state, setState] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.state_name : ""
  );
  const [region, setRegion] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.region_name : ""
  );
  const [location, setLocation] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.location : ""
  );
  const [leadTransferDate, setLeadTransferDate] = useState(
    Object.keys(rowDetails).length > 0
      ? rowDetails.row.lead_transfer_date
      : undefined
  );
  const [cseAssigned, setCseAssigned] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.lead_owner : ""
  );
  const [leadSource, setLeadSource] = useState(
    Object.keys(rowDetails).length > 0
      ? rowDetails.row.source_of_lead_generated
      : ""
  );
  const [bdmRemark, setBDMRemark] = useState("");
  const [category, setCategory] = useState(
    Object.keys(rowDetails).length > 0
      ? rowDetails.row.category.toLowerCase()
      : ""
  );
  const [subCategory, setSubCategory] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.sub_category : ""
  );
  const [followUpDate, setFollowUpDate] = useState(
    Object.keys(rowDetails).length > 0
      ? rowDetails.row.follow_up_date
      : undefined
  );
  const [closureMonth, setClosureMonth] = useState(
    Object.keys(rowDetails).length > 0 ? rowDetails.row.close_month : null
  );

  const [stateList, setStateList] = useState({});
  const [placesList, setPlacesList] = useState([]);
  const [cityList, setCityList] = useState({});

  function getFirstDayOfMonth(monthName) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = monthNames.findIndex((name) => name === monthName);

    // if (monthIndex === -1) {
    //   throw new Error('Invalid month name');
    // }

    const month = monthIndex + 1;
    console.log(month);
    return month;
  }

  const handlePincodeApi = (value) => {
    if (value.length == 6) {
      axios
        .get(`${STATE_API}/${value}`, {})
        .then((response) => {
          const { stateCode, stateName, cityId, cityName, places } =
            response.data;
          setStateList({
            stateCode,
            stateName,
          });
          setCityList({
            cityId,
            cityName,
          });
          setPlacesList(places);
        })
        .catch((error) => {});
    }
  };

  const handleClose = () => {
    setOpen(false);
    // if(viewMode){
    // handleViewMode(false);}
  };

  const handleSubmit = (e) => {
    const formData = {
      bdm_remark: bdmRemark,
    };
    console.log("formData", formData);

    axios
      .put(
        `${BDM_REMARK_UPDATE}/${rowDetails.row.leadID}/bdm-remarks`,
        formData
      )
      .then((response) => {
        console.log(response.data);
        handleClose();
        setSeverity("success");
        setMessage(response.data.message);
        setOpenSnackbar(true);
      })
      .catch((error) => {});
  };
  return (
    <>
      <DialogContent>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${leadFormStyles.accordian_heading} h-auto`}
          >
            <Typography>Customer Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} p={0}>
                {/* <Grid item xs={12} md={6} xl={6}>
                    <TextField
                    id="outlined-select-currency"
                    select
                    label="Enquiry Type"
                    value={enquiryType}
                    onChange={(e)=> setEnquiryType(e.target.value)}
                    // helperText="Please select your currency"
                    size='small'
                    >
                    {ENQUIRY_TYPE.map((eq) => (
                    <MenuItem value={eq.label}>{eq.label}</MenuItem>
                    ))}
                    </TextField>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} >
                    <TextField
                    id="outlined-select-currency"
                    select
                    label="Project"
                    defaultValue=""
                    size='small'
                    value={project}
                    onChange={(e)=> setProject(e.target.value)}
                    // helperText="Please select your project"
                    >
                    {PROJECT.map((p)=>(    
                    <MenuItem value={p.label}>{p.label}</MenuItem>
                    ))}
                    </TextField>
                    </Grid> */}

                <Grid item xs={12} md={6} xl={6}>
                  <TextField
                    label="Customer Name"
                    id="outlined-size-small"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={6} xl={6}>
                  <TextField
                    label="Mobile Number"
                    id="outlined-size-small"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Whatsapp Number"
                    id="outlined-size-small"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Alternate Number"
                    id="outlined-size-small"
                    value={alternateNumber}
                    onChange={(e) => setAlternateNumber(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Farmer Mail ID"
                    id="outlined-size-small"
                    value={mailId}
                    onChange={(e) => setMailId(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Postal Pincode"
                    id="outlined-size-small"
                    value={pincode}
                    onChange={(e) => {
                      setPincode(e.target.value);
                      // handlePincodeApi(e.target.value)
                    }}
                    size="small"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    id="outlined-select-currency"
                    label="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    // helperText="Please select your currency"
                    size="small"
                  />
                  {/* {stateList && [stateList].map((sl) => (
                        <MenuItem value={sl.stateCode}>{sl.stateName}</MenuItem>
                        ))} */}
                  {/* </TextField> */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    id="outlined-select-currency"
                    // select
                    label="Region Name"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    // helperText="Please select your currency"
                    size="small"
                  />
                  {/* {placesList && placesList.map((pl) => (
                        <MenuItem value={pl.placeId}>{pl.placeName}</MenuItem>
                        ))}
                        </TextField> */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Location"
                    id="outlined-size-small"
                    // select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    size="small"
                  />
                  {/* {cityList && [cityList].map((cl) => (
                        <MenuItem value={cl.cityId}>{cl.cityName}</MenuItem>
                        ))}
                    </TextField> */}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${leadFormStyles.accordian_heading} h-auto`}
          >
            <Typography>Disposition Summary</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    // helperText="Please select your currency"
                    size="small"
                  >
                    {CATEGORY.map((c) => (
                      <MenuItem
                        value={c.value}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {c.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6} xl={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Sub-Category"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    // helperText="Please select your currency"
                    size="small"
                  >
                    {category == "hot" &&
                      HOT_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category == "warm" &&
                      WARM_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category == "cold" &&
                      COLD_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category == "pending" &&
                      PENDING_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category == "closed" &&
                      CLOSED_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <DatePicker
                    label="Follow-up Date"
                    value={dayjs(followUpDate)}
                    onChange={(e) => setFollowUpDate(e)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <DatePicker
                    label={"Closure Month"}
                    openTo="month"
                    views={["month"]}
                    value={dayjs(closureMonth)}
                  />
                </Grid>

                {/* <Grid item xs={12} md={6} xl={6} className={`${bdmFormStyles.fields_padding}`}>
                    <DatePicker 
                        label="Lead Transfer Date" 
                        value={dayjs(leadTransferDate)}
                        onChange={(e)=>{setLeadTransferDate(e)}}/>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} className={`${bdmFormStyles.fields_padding}`}>
                    <TextField
                        label="Source of Lead Generated"
                        id="outlined-size-small"
                        select
                        value={leadSource}
                        onChange={(e)=>setLeadSource(e.target.value)}
                        size="small"
                        >
                    {LEAD_SOURCE.map((ls) => (
                        <MenuItem value={ls.value} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{ls.label}</MenuItem>
                    ))}
                    </TextField>
                    </Grid> */}

                {/* <Grid item xs={12} md={6} xl={6} className={`${bdmFormStyles.fields_padding}`}>
                    <TextField
                        id="outlined-select-currency"
                        label="CSE Assigned"
                        value={cseAssigned}
                        onChange={(e)=>setCseAssigned(e.target.value)}
                        // helperText="Please select your currency"
                        size='small'
                        />
                    </Grid> */}

                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  className={`${bdmFormStyles.fields_padding}`}
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Remarks"
                    multiline
                    rows={4}
                    sx={{ width: "100%" }}
                    value={bdmRemark}
                    onChange={(e) => setBDMRemark(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {/* {viewMode == true ? (<></>) : ( */}
        <Button type="submit" onClick={handleSubmit}>
          Save
        </Button>
        {/* )} */}
      </DialogActions>
    </>
  );
}

export default BdmFormComponent;
