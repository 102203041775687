import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table';
import LongTextComponent from '../Leads Page/LongTextComponent';
import { Box, Button, Chip, Menu, MenuItem, Table, Tooltip, Typography } from '@mui/material';
import EstimationDetailPanelTable from './EstimationDetailPanel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

function EstimationSharedTable({estimatedData}) {

    const [row , setRow] = useState([]);

    const handlingRowData = () => {
        const transformedRows = estimatedData
          .map((rd, index) => {
            // Filter estimations to only include those with status 'converted'
            const filteredEstimations = rd.estimations?.filter(est => est.status === 'Estimation Shared') || [];
      
            // If there are no converted estimations, return null to exclude the row
            if (filteredEstimations.length === 0) return null;
      
            // Return the transformed row if estimations are available
            return {
              id: index + 1,
              SNo: index + 1,
              leadId: rd.id,
              CustomerName: rd.CustomerName === '' ? '-' : rd.CustomerName,
              MobileNo: rd.MobileNo === '' ? '-' : rd.MobileNo,
              AlternateMobileNo: rd.AlternateMobileNo === '' ? '-' : rd.AlternateMobileNo,
              state_name: rd.state_name === '' ? '-' : rd.state_name,
              source_of_lead_generated: rd.Campaign == null ? '-' : rd.Campaign.CampaignName,
              category: rd.category === '' ? '-' : rd.category,
              AgentId: rd.AgentId,
              BDMId: rd.BDMId,
              CustomerMailId: rd.CustomerMailId === '' ? '-' : rd.CustomerMailId,
              InquiryType: rd.InquiryType,
              Project: rd.Project,
              Superviser: rd.Superviser,
              SuperviserID: rd.SuperviserID,
              WhatsappNo: rd.WhatsappNo === '' ? '-' : rd.WhatsappNo,
              agent_remark: rd.agent_remark === '' ? '-' : rd.agent_remark,
              close_month: rd.close_month,
              follow_up_date: rd.follow_up_date === '' ? '-' : formatDate(rd.follow_up_date),
              lead_owner: rd.BDM == null ? '-' : rd.BDM.EmployeeName,
              lead_transfer_date: (rd.lead_transfer_date === '' || rd.lead_transfer_date === null) ? '-' : formatDate(rd.lead_transfer_date),
              location: rd.location === '' ? '-' : rd.location,
              site_location_address: rd.site_location_address === '' ? '-' : rd.site_location_address,
              region_name: rd.region_name === '' ? '-' : rd.region_name,
              sub_category: rd.sub_category === '' ? '-' : rd.sub_category,
              Agent: rd.Agent,
              AgentName: rd.Agent == null ? '-' : rd.Agent.EmployeeName,
              BDM: rd.BDM,
              pincode: rd.pincode === '' ? '-' : rd.pincode,
              call_status: rd.call_status,
              call_type: rd.call_type,
              createdDate: rd.createdAt === '' ? '-' : formatDate(rd.createdAt),
              bdm_remark: '-',
              lead_calling_date: '-',
              bdm_calling_date: '-',
              estimations: filteredEstimations
                .map((est, index) => ({
                  sno: index + 1,
                  category: est.category,
                  closure_month: est.closure_month === null ? '-' : est.closure_month,
                  createdAt: formatDate(est.createdAt),
                  follow_up_date: est.follow_up_date === null ? '-' : formatDate(est.follow_up_date),
                  id: est.id,
                  sub_category: est.sub_category === '' ? '-' : est.sub_category,
                  updatedAt: est.updatedAt,
                  CShape: est.CShape == true ? 'Yes' : 'No',
                  CivilConstructedStarted: est.CivilConstructedStarted == true ? 'Yes' : 'No',
                  CurtainRequirment: est.CurtainRequirment == true ? 'Yes' : 'No',
                  DiselBrooderRequirment: est.DiselBrooderRequirment == true ? 'Yes' : 'No',
                  EquipementPlacementLength: est.EquipementPlacementLength,
                  EstimationRequirement: est.EstimationRequirement,
                  MOdalType: est.ModalType === null ? '-' : est.ModalType,
                  NumberOfSheds: est.NumberOfSheds,
                  PowerSupply: est.PowerSupply == true ? 'Yes' : 'No',
                  Remarks: est.Remarks === null ? '-' : est.Remarks,
                  ShedLength: est.ShedLength,
                  ShedWidth: est.ShedWidth == true ? 'Yes' : 'No',
                  SideWallColumnToColumnGap: est.SideWallColumnToColumnGap,
                  WaterSupply: est.WaterSupply == true ? 'Yes' : 'No',
                  status: est.status
                }))
            };
          })
          .filter(row => row !== null); // Remove rows with no estimations
      
        setRow(transformedRows);
      };

    
     
      const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

    useEffect(()=>{
      handlingRowData();
  },[estimatedData]);

  const columns = useMemo(
      () => [
      //   {
      //     accessorKey: "SNo", //simple recommended way to define a column
      //     header: "S No",
      //     // muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
      //     // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
      //   },
        {
          id: "SNo", // Unique ID for the column
          header: "SNo",
          size: 50,
          // Create a dynamic serial number based on row index
          Cell: ({ row, table }) => {
            // Calculate SNo based on current pageIndex and pageSize
            const { pageIndex, pageSize } = table.getState().pagination;
            return pageIndex * pageSize + row.index + 1;
          },
          enableSorting: false, // Disable sorting
          enableColumnFilter: false, // Disable filtering
          enableHiding: false, // Disable hiding
          
        },
        {
          // accessorFn: (row) => row.age, //alternate way
          // id: "age", //id required if you use accessorFn instead of accessorKey
          accessorKey: "lead_owner",
          header: "Lead Owner",
          size: 150,
          // Header: <i style={{ color: "red" }}>Age</i> //optional custom markup
        },
        {
          accessorKey: "CustomerName",
          header: "Customer Name",
          size: 200,
        },
        {
          accessorKey: "MobileNo",
          header: "Mobile Number",
          size: 150,
        },
        {
          accessorKey: "region_name",
          header: "Region Name",
          size: 180,
        },
        {
          accessorKey: "category",
          header: "Category",
          size: 150,
          filterFn: 'equals',
          Cell: ({ cell }) => (
              <Chip
                size="small"
                color={getColorForTag(cell.getValue())}
                label={cell.getValue()}
                sx={{ textTransform: 'capitalize', height: '20px' }}
              />
          ),
        },
        {
          accessorKey: "sub_category",
          header: "Sub-Category",
          size: 150,
        },
        {
          accessorKey: "agent_remark",
          header: "CSE Remark",
          size: 250,
          enableColumnFilter: false,
          Cell: ({ cell }) => (
              <LongTextComponent text={cell.getValue() || ''} maxLength={50} />
          ),
        },
        {
          accessorKey: "bdm_remark",
          header: "BDM Remark",
          filterFn: 'contains', // Use a string filter
          size: 250,
          enableColumnFilter: false,
          Cell: ({ cell }) => (
              <LongTextComponent text={cell.getValue() || ''} maxLength={50} />
            ),
        },
        {
          accessorKey: "location",
          header: "Location",
          size: 200,
        },
        {
          accessorKey: "state_name",
          header: "State",
          size: 150,
        },
        {
          accessorKey: "CustomerMailId",
          header: "Customer Mail ID",
          size: 200,
        },
        {
          accessorKey: "createdDate",
          header: "Date of First Calling",
          size: 180,
        },
        {
          accessorKey: "follow_up_date",
          header: "Follow-Up Date",
          size: 180,
        },
        {
          accessorKey: "close_month",
          header: "Closure Month",
          size: 180,
        },
        {
          accessorKey: "lead_transfer_date",
          header: "Date of Lead Transfer",
          size: 180,
        },
        {
          accessorKey: "bdm_calling_date",
          header: "Date of BDM Calling",
          size: 180,
        },
        {
          accessorKey: "source_of_lead_generated",
          header: "Campaign Name",
          size: 200,
        },
        {
          accessorKey: "AgentName",
          header: "CSE Name",
          size: 150,
        },
        {
          accessorKey: "Project",
          header: "Project",
          size: 200,
        },
        {
          accessorKey: "AlternateMobileNo",
          header: "Alt Mobile Number",
          size: 150,
        },
        {
          accessorKey: "WhatsappNo",
          header: "Whatsapp Number",
          size: 150,
        },
        {
          accessorKey: "updatedAt",
          header: "Lead Updated On",
          size: 180,
        }
      ],
      []
    );

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      useKeysAsHeaders: true,
    });

    const flattenData = (data) => {
      return data.map((item) => {
          return {
              ...item,
              estimations: item.estimations ? JSON.stringify(item.estimations) : '', // Flatten the `estimations` array
              Agent: item.Agent ? JSON.stringify(item.Agent) : '', // Flatten the `Agent` object
              BDM: item.BDM ? JSON.stringify(item.BDM) : '' // Flatten the `BDM` object
          };
        });
    };
  

    const handleExportData = () => {
      const flattenedData = flattenData(row);
      const csv = generateCsv(csvConfig)(flattenedData);
      download(csvConfig)(csv);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

   // Custom export to Excel function
    const exportToExcel = (data) => {
      const wb = XLSX.utils.book_new();
     // Prepare the worksheet data
      const wsData = [
        columns.map(column => column.header),  // Extract headers
        ...data.map(row => columns.map(column => row.original[column.accessorKey])) // Extract row data
      ];
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      XLSX.utils.book_append_sheet(wb, ws, 'Data');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, 'Crm_Leads.xlsx');
      handleMenuClose(); // Close the menu after exporting
    };


    const table = useMaterialReactTable({
      data: row, // This should be data, not row,
      columns,
      enableStickyHeader: true,
      defaultColumn: {
          minSize: 80, 
          maxSize: 400, 
          size: 180, 
        },
        getRowId: (row) => row.id, // ensure each row has a unique ID
        enableExpanding: true, // Enable expanding
          renderDetailPanel: ({ row }) => (
             <EstimationDetailPanelTable data={row.original.estimations}></EstimationDetailPanelTable>   
          ),
        muiTableHeadCellProps: {
            sx: {
                textAlign: 'center',
                backgroundColor: '#EEF0F4',
                placeContent: 'center',
                '& .Mui-TableCell-root': {
                    justifyContent: 'center',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                // textAlign: 'center',
                padding: '8px',
            },
        },
       
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({ table }) => (
          <>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Tooltip title="Export Options">
              <Button
                variant="text"
                onClick={handleMenuClick}
                startIcon={<FileDownloadIcon />}
              >
              Export
              </Button>
            </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => exportToExcel(table.getRowModel().rows)}>Export to Excel</MenuItem>
                <MenuItem onClick={handleExportData}>Export to CSV</MenuItem>
                {/* Add more menu items for additional export options if needed */}
              </Menu>
            </Box>
            </>
            ),
    });

    const getColorForTag = (tag) => {
      switch (tag.toLowerCase()) {
        case 'hot':
          return 'error'; // React blue
        case 'warm':
          return 'warning'; // JavaScript yellow
        case 'cold':
          return 'info'; // UI dark blue
        default:
          return 'default'; // Default gray
      }
    };

    return(
        <>
        <MaterialReactTable table={table} enableExpanding   />
        </>
    );
}

export default EstimationSharedTable;