import { Box, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Paper, styled, Tab, Tabs, Typography } from "@mui/material";
import React, { useMemo, useEffect } from "react";
import ConfirmedTaskTable from "./ConfirmedTaskTable";
import HoTaskTable from "./HoTaskTable";
import SelfTaskTable from "./SelfTaskTable";
import OtherTaskTable from "./OtherTaskTable";
import PostponedTaskTable from "./PostponedTaskTable";
import NotCompletedTaskTable from "./NotCompletedTaskTable";
import CompletedTaskTable from "./CompletedTaskTable";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// FancyCard (Styled Paper)
const FancyCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(2),
    textAlign: "center",
  }));
  
  // InfoBox (Styled Box for task info)
  const InfoBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    display: "inline-block",
    width: "100%",
    textAlign: "center",
  }));
  
  const ExpandIcon = styled(IconButton)(({ theme, expand }) => ({
    transform: expand ? "rotate(180deg)" : "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

const DataDisplayCard = ({bdmStatisticsList}) => {
    const [isExpanded, setIsExpanded] = useState(true); // Single card starts expanded

const handleExpandClick = () => {
  setIsExpanded((prev) => !prev); // Toggle the expanded state
};
      
    
      const [tabValues, setTabValues] = useState({}); // Object to track each card's tab state
    
      const handleTabChange = (index, newValue) => {
        setTabValues((prevValues) => ({
          ...prevValues,
          [index]: newValue, // Update the specific card's tab state
        }));
      };

    return(
        <>
         {/* Data Display */}
      <Grid container spacing={2} sx={{ padding: 2 }}>
      {bdmStatisticsList.map((item, index) => (
        <Grid item xs={12} key={item.bdm_id}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CardHeader
              title={
                <div>
                  <Grid container spacing={2} sx={{ marginTop: "-4px" }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600 }}
                      >
                        {item.bdm_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <InfoBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "6px 12px", // Adjust padding for better spacing
                              backgroundColor: "#f5f5f5",
                              // border: '2px #9c27b0 solid',
                              borderRadius: "8px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="black"
                              sx={{ fontWeight: "bold" }}
                            >
                              Confirmed
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#2196f3"
                              sx={{ fontWeight: "bold" }}
                            >
                              {item.statistics.confirmed.count}
                            </Typography>
                          </InfoBox>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <InfoBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "6px 12px", // Adjust padding for better spacing
                              backgroundColor: "#f5f5f5",
                              // border: '2px #FF8C00 solid',
                              borderRadius: "8px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="black"
                              sx={{ fontWeight: "bold" }}
                            >
                              Postponed
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#FF8C00"
                              sx={{ fontWeight: "bold" }}
                            >
                              {item.statistics.postponed.count}
                            </Typography>
                          </InfoBox>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <InfoBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "6px 12px", // Adjust padding for better spacing
                              backgroundColor: "#f5f5f5",
                              // border: '2px solid #4caf50' ,
                              borderRadius: "8px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="black"
                              sx={{ fontWeight: "bold" }}
                            >
                              Completed
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#4caf50"
                              sx={{ fontWeight: "bold" }}
                            >
                              {item.statistics.completed.count}
                            </Typography>
                          </InfoBox>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <InfoBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "6px 12px", // Adjust padding for better spacing
                              backgroundColor: "#f5f5f5",
                              // border: '2px solid #e53935',
                              borderRadius: "8px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="caption"
                              color="black"
                              sx={{ fontWeight: "bold" }}
                            >
                              Not-Completed
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#e53935"
                              sx={{ fontWeight: "bold" }}
                            >
                              {item.statistics.not_completed.count}
                            </Typography>
                          </InfoBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
              action={
                <ExpandIcon
                  expand={isExpanded}
                  onClick={handleExpandClick}
                >
                  <ExpandMoreIcon />
                </ExpandIcon>
              }
              sx={{
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid #e0e0e0",
                padding: "0px 12px", // Reduced padding to make header smaller
                height: "auto", // Ensures the header height adjusts dynamically based on content
                "& .MuiCardHeader-action": {
                  marginTop: "5px",
                },
              }}
            />
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <CardContent sx={{ paddingTop: 0 }}>
                <Tabs
                  value={tabValues[index] || 0} // Default to 0 if not set
                  onChange={(event, newValue) =>
                    handleTabChange(index, newValue)
                  }
                  aria-label="wrapped label tabs example"
                >
                  <Tab
                    label={`Confirmed (${item.statistics.confirmed.count})`}
                    id={`tab-${index}-0`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-0`}
                  />
                  <Tab
                    label={`HO Task (${item.statistics.task_types.HO_task.count})`}
                    id={`tab-${index}-1`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-1`}
                  />
                  <Tab
                    label={`Self Task(${item.statistics.task_types.self_task.count})`}
                    id={`tab-${index}-2`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-2`}
                  />
                  <Tab
                    label={`Other Task(${item.statistics.task_types.other_task.count})`}
                    id={`tab-${index}-3`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-3`}
                  />
                   <Tab
                    label={`Postponed(${item.statistics.postponed.count})`}
                    id={`tab-${index}-4`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-4`}
                  />
                  <Tab
                    label={`Completed(${item.statistics.completed.count})`}
                    id={`tab-${index}-5`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-5`}
                  />
                  <Tab
                    label={`Not-Completed(${item.statistics.not_completed.count})`}
                    id={`tab-${index}-6`}
                    iconPosition="start"
                    aria-controls={`tabpanel-${index}-6`}
                  />
                </Tabs>

                <TabPanel value={tabValues[index] || 0} index={0}>
                  <ConfirmedTaskTable taskData={item.statistics.confirmed.details} />
                </TabPanel>
                <TabPanel value={tabValues[index] || 0} index={1}>
                  <HoTaskTable
                    taskData={item.statistics.task_types.HO_task.details}
                  />
                </TabPanel>
                <TabPanel value={tabValues[index] || 0} index={2}>
                  <SelfTaskTable
                    selftaskData={
                      item.statistics.task_types.self_task.details
                    }
                  />
                </TabPanel>
                <TabPanel value={tabValues[index] || 0} index={3}>
                  <OtherTaskTable
                    othertaskData={
                      item.statistics.task_types.other_task.details
                    }
                  />
                </TabPanel>
                <TabPanel value={tabValues[index] || 0} index={4}>
                  <PostponedTaskTable taskData={item.statistics.postponed.details} /> 
                </TabPanel>
                <TabPanel value={tabValues[index] || 0} index={5}>
                  <CompletedTaskTable taskData={item.statistics.completed.details} />
                </TabPanel>
                <TabPanel value={tabValues[index] || 0} index={6}>
                  <NotCompletedTaskTable taskData={item.statistics.not_completed.details} />
                </TabPanel>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      ))}
    </Grid>
        </>
    );
};

export default DataDisplayCard;