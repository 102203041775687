export const ROLES = [
  { id: 1, role: "CSE" },
  { id: 2, role: "BDM" },
  { id: 5, role: "Coordinator" },
  { id: 100, role: "Agent" },
  { id: 6, role: "HO Executive" },
  { id: 101, role: "Audit Supervisor" },
  { id: 200, role: "BI Agent" },
  { id: 201, role: "BI Supervisor" },
  { id: 3, role: "Zonal Manager" },
];

export const ENQUIRY_TYPE = [
  { id: 1, type: "outNS", label: "New Sales Inquiry" },
  // {id: 2, type: 'inNs', label: 'Inbound-New Sales Inquiry'},
  { id: 3, type: "InComp", label: "Complaint" },
  { id: 4, type: "InSC", label: "Service Call" },
  { id: 5, type: "InEx", label: "Existing Customer new Inquiry" },
];

export const PROJECT = [
  { id: 1, value: "Parivartan", label: "Parivartan" },
  { id: 2, value: "Gen Nxt", label: "Gen Nxt" },
  { id: 3, value: "Open Shed", label: "Open Shed" },
];

export const CALL_TYPE = [
  { id: 1, value: "in", label: "Inbound" },
  { id: 2, value: "out", label: "OutBound" },
];

export const CALL_STATUS = [
  { id: 1, value: "conn", label: "Connected" },
  { id: 2, value: "failed", label: "Failed" },
];

export const TITLE = [
  { id: 1, value: "mr", label: "Mr" },
  { id: 2, value: "ms", label: "Ms" },
  { id: 3, value: "mrs", label: "Mrs" },
  { id: 4, value: "dr", label: "Dr" },
  { id: 4, value: "m/s", label: "M/s" },
];

export const LEAD_SOURCE = [
  { id: 1, value: "SN", label: "SN Desk" },
  { id: 2, value: "EOC", label: "Enquiry on Call" },
  { id: 3, value: "IN", label: "In-House" },
  { id: 4, value: "PFM", label: "Parivartan Farmer Meeting" },
  { id: 5, value: "PAUP", label: "Paper Ad_UP" },
  { id: 6, value: "PAAWB", label: "Paper Ad_Assam & WB" },
  { id: 7, value: "DMV", label: "Digital Marketing_Vendor" },
  { id: 8, value: "DMI", label: "Digital Marketing_Inhouse" },
  { id: 9, value: "DMF", label: "Digital Marketing_Facebook" },
  { id: 10, value: "KE2024", label: "Kolkata_Expo_2024" },
  { id: 11, value: "HE2022", label: "Hyderabad_Expo_2022" },
  { id: 12, value: "HE2023", label: "Hyderabad_Expo_2023" },
  { id: 13, value: "HE2023", label: "BDM_Lead" },
];

export const CATEGORY = [
  { id: 1, value: "hot", label: "Hot" },
  { id: 2, value: "warm", label: "Warm" },
  { id: 3, value: "cold", label: "Cold" },
  { id: 4, value: "pending", label: "Pending" },
  { id: 5, value: "closed", label: "Closed" },
];

export const HOT_SUB_CATEGORY = [
  { id: 1, value: "", label: "Payment within 1 Week" },
  { id: 2, value: "", label: "Payment within 15 Days" },
  { id: 3, value: "", label: "Payment by end of Month" },
  { id: 4, value: "", label: "Payment by next Month" },
];

export const WARM_SUB_CATEGORY = [
  { id: 1, value: "", label: "Already constructed/under process" },
  { id: 2, value: "", label: "Approval for NOC/PCB PENDING" },
  { id: 3, value: "", label: "Bank Loan Under Process" },
  { id: 4, value: "", label: "Blocked_Marketing issues" },
  { id: 5, value: "", label: "Compare Estimation To Other" },
  { id: 6, value: "", label: "Confirmation after Discussion with Family" },
  { id: 7, value: "", label: "Confirmation after Discussion with BDM" },
  { id: 8, value: "", label: "Integration not available" },
  { id: 9, value: "", label: "Investment Scheme - CIO" },
  { id: 10, value: "", label: "Language Barrier" },
  { id: 11, value: "", label: "Site Visit Required" },
  { id: 12, value: "", label: "Start Construction after 1 Year" },
  { id: 13, value: "", label: "Start Construction in few Days" },
  { id: 14, value: "", label: "Start Construction within 3-6 Months" },
  { id: 15, value: "", label: "Start Construction within 6-12 Months" },
  { id: 16, value: "", label: "Want Estimation(New/Revised)" },
  { id: 17, value: "", label: "Want Meetings With BDM" },
  { id: 18, value: "", label: "Will arrange Basics in Future" },
];

export const COLD_SUB_CATEGORY = [
  { id: 1, value: "", label: "Complaint" },
  { id: 2, value: "", label: "Integration not available" },
  { id: 3, value: "", label: "Interested in Layer Farming" },
  { id: 4, value: "", label: "Invalid/Incorrect Number" },
  { id: 5, value: "", label: "Just Enquiry" },
  { id: 6, value: "", label: "Land on Lease" },
  { id: 7, value: "", label: "Land/ Shed not Suitable" },
  { id: 8, value: "", label: "Lost To Competitor" },
  { id: 9, value: "", label: "No Budget" },
  { id: 10, value: "", label: "No Electricity" },
  { id: 11, value: "", label: "No Land" },
  { id: 12, value: "", label: "No Road" },
  { id: 13, value: "", label: "North South Direction" },
  { id: 14, value: "", label: "Not Interested" },
  { id: 15, value: "", label: "Other Business Inquiry" },
  { id: 16, value: "", label: "Rejected by BI Team" },
  { id: 17, value: "", label: "RNR Multiple  Times" },
  { id: 18, value: "", label: "Want Only Integration" },
  { id: 19, value: "", label: "Incoming Not Available" },
];

export const PENDING_SUB_CATEGORY = [
  { id: 1, value: "", label: "Busy_Need to Recall" },
  { id: 2, value: "", label: "No Call Response" },
  { id: 3, value: "", label: "Number Switched Off" },
];

export const CLOSED_SUB_CATEGORY = [
  { id: 1, value: "", label: "Converted_P" },
  { id: 2, value: "", label: "LOI Done_PGN" },
];

export const MODEL_TYPE = [
  { id: 1, value: "full_ec", label: "Full EC" },
  { id: 1, value: "semi_ec_type1", label: "Semi EC Type 1" },
  { id: 1, value: "semi_ec_type2", label: "Semi EC Type 2" },
  { id: 1, value: "basic_ec", label: "Basic EC" },
  { id: 1, value: "gen_nxt", label: "Gen Nxt" },
];

export const SIDE_WALL_COLUMN_GAP = [
  { id: 1, value: "8ft", label: "8 ft" },
  { id: 2, value: "9ft", label: "9 ft" },
  { id: 3, value: "10ft", label: "10 ft" },
  { id: 4, value: "11ft", label: "11 ft" },
  { id: 5, value: "12ft", label: "12 ft" },
];

export const NO_OF_SHEDS = [
  { id: 1, value: 1, label: "1" },
  { id: 2, value: 2, label: "2" },
  { id: 3, value: 3, label: "3" },
  { id: 4, value: 4, label: "4" },
  { id: 5, value: "more", label: "More" },
];

export const BDM_LIST1 = [{ id: 1, value: 10005413, label: "Krishan Gopal" }];

export const BDM_LIST = [
  { id: 1, value: 10005413, label: "Krishan Gopal" },
  { id: 2, value: 10005300, label: "Priyadatta Kumar Swain" },
  { id: 3, value: 10014559, label: "Baki Tarun Kumar" },
  { id: 4, value: 10001801, label: "C M Lokesh" },
  { id: 5, value: 10013244, label: "Karan Singh Dedialla" },
  { id: 6, value: 10013346, label: "Vishal Kumar Gupta" },
  { id: 7, value: 10013531, label: "Ranjan Rahi" },
  { id: 8, value: 10009604, label: "Yallappa Ajjodi" },
  { id: 9, value: 10023327, label: "Rahul Das" },
  { id: 10, value: 10019059, label: "Deepanker Kumar Dubey" },
  { id: 11, value: 10003754, label: "Girish Gopal Lakkewar" },
  { id: 12, value: 10020353, label: "Sachin Suresh Nangare" },
  { id: 13, value: 10007894, label: "Abhijit Saha" },
  { id: 14, value: 10013212, label: "Somnath Chatterjee" },
  { id: 15, value: 10021250, label: "Manish Kumar" },
  { id: 16, value: 10006206, label: "Mrutyunjaya Samal" },
  { id: 17, value: 10021958, label: "Narayan Behera" },
  { id: 18, value: 10023999, label: "Vikas Singh" },
  { id: 19, value: 10004733, label: "Anup Sarkar" },
  { id: 20, value: 10005917, label: "Kanhu Charan Sardarsingh" },
  { id: 21, value: 10009829, label: "Dharmaraj R" },
  { id: 22, value: 10015791, label: "Shaik Shafiuddin" },
  { id: 23, value: 10020477, label: "Jang Bahadur" },
  { id: 24, value: 10014191, label: "Anup Sarkar" },
  { id: 25, value: 10004733, label: "Rajan Pandey" },
  { id: 26, value: 10019564, label: "Firoz Khan" },
];

export const PAGE_SECTIONS = [
  { id: 1, value: "onCall", label: "On-Call Discussion" },
  { id: 2, value: "meeting", label: "Meeting" },
  { id: 3, value: "siteVisit", label: "Site Visit" },
  { id: 4, value: "estimate", label: "Estimation Request" },
  { id: 5, value: "estimate_gen", label: "Estimation Generated" },
  { id: 6, value: "estimate_shared", label: "Estimation Shared" },
  { id: 7, value: "converted", label: "Converted" },
];

export const REGIONS = [
  { id: 1, label: "ANDHRA PRADESH-I" },
  { id: 2, label: "ANDHRA PRADESH-II" },
  { id: 3, label: "CHHATTISGARH" },
  { id: 4, label: "GUJARAT" },
  { id: 5, label: "HARYANA - I" },
  { id: 6, label: "HARYANA - II" },
  { id: 7, label: "JHARKHAND" },
  { id: 8, label: "KARNATAKA" },
  { id: 9, label: "KARNATAKA 2" },
  { id: 10, label: "LOWER ASSAM" },
  { id: 11, label: "MADHYA PRADESH - I" },
  { id: 12, label: "MADHYA PRADESH - II" },
  { id: 13, label: "MAHARASHTRA - I" },
  { id: 14, label: "MAHARASHTRA - II" },
  { id: 15, label: "NORTH BENGAL I" },
  { id: 16, label: "NORTH BENGAL II" },
  { id: 17, label: "NORTH BIHAR" },
  { id: 18, label: "ODISHA1" },
  { id: 19, label: "ODISHA2" },
  { id: 20, label: "PUNJAB-I" },
  { id: 21, label: "PUNJAB-II" },
  { id: 22, label: "RAJASTHAN" },
  { id: 23, label: "SOUTH BENGAL 1" },
  { id: 24, label: "SOUTH BENGAL 2" },
  { id: 25, label: "SOUTH BIHAR" },
  { id: 26, label: "TAMILNADU" },
  { id: 27, label: "TAMILNADU 2" },
  { id: 28, label: "TELANGANA 1" },
  { id: 29, label: "TELANGANA 2" },
  { id: 30, label: "UPPER ASSAM" },
  { id: 31, label: "UTTAR PRADESH - I" },
  { id: 32, label: "UTTAR PRADESH - II" },
  { id: 33, label: "UTTAR PRADESH - III" },
  { id: 34, label: "UTTARAKHAND" },
];

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const AUTH_API = BASE_URL + "/auth/login";
export const CREATE_LEAD_API = BASE_URL + "/create/leads";
export const GET_LEAD_BY_AGENT_API = BASE_URL + "/leads/agent";
export const STATE_API = BASE_URL + "/places";
export const GET_LEAD_BY_BDM_API = BASE_URL + "/customer/lead";
export const BDM_REMARK_UPDATE = BASE_URL + "/leads";
export const CREATE_MEETING = BASE_URL + "/create/meeting";
export const UPDATE_STATUS_AGENT = BASE_URL + "/update/remark";
export const GET_REMARK_LIST = BASE_URL + "/remark/list";
export const GET_MEETING = BASE_URL + "/leads";
export const GET_SITE_VISIT = BASE_URL + "/leads";
export const CREATE_SITE_VISIT = BASE_URL + "/create/sitevisit";
export const CREATE_ESTIMATION = BASE_URL + "/create/estimations";
export const GET_ESTIMATION = BASE_URL + "/leads";
export const GET_SITE_VISIT_SV =
  BASE_URL + "/supervisor/leads-with-site-visits";
export const GET_MEETING_SV = BASE_URL + "/bdm/lead-meetings";
export const GET_ON_CALL_DISCUSSION_SV = BASE_URL + "/call-on-discussion";
export const GET_ESTIMATIONS_SV = BASE_URL + "/bdm/estimation";
export const GET_ALL_LEADS = BASE_URL + "/get/leads";
export const BULK_UPLOAD = BASE_URL + "/upload-leads";
export const BULK_UPLOAD_AUDIT = BASE_URL + "/upload-audit-leads";
export const GET_AUDIT_LIST = BASE_URL + "/audit-leads";
export const POST_AUDIT_REMARK = BASE_URL + "/audit-remarks";
export const GET_AUDIT_REMARK_LIST = BASE_URL + "/get-audit-remarks";
export const GET_ESTIMATIONS_HO = BASE_URL + "/estimations";
export const HO_STATUS_UPDATE = BASE_URL + "/estimations";
export const GET_DETAILS_BY_MOBILE_NUMBER = BASE_URL + "/lead";
export const GET_REGIONS = BASE_URL + "/region?stateName=";
export const SUPERVISOR_DASHBORAD_AUDIT = BASE_URL + "/supervisor-dashboard";
export const AUDIT_STATUS_UPDATE = BASE_URL + "/update-status";
export const REGION_WISE_AUDIT_FILTER = BASE_URL + "/all/leads";
export const LEAD_CONVERTED = BASE_URL + "/lead-documents";
export const PENDING_AND_COLD_LEAD = BASE_URL + "/leads/agent";
export const DOWNLOAD_AND_GET_CONVERTED = BASE_URL + "/lead-documents";
export const AUDIT_TRADER_LIST = BASE_URL + "/audit-trader";
export const TRADER_LIST_BULK_UPLOAD = BASE_URL + "/upload-trader-leads";
export const FOLLOW_UP_BY_AGENT = BASE_URL + "/agent/follow-up";
export const ON_CALL_DISCUSSION_BDM = BASE_URL + "/BDM/on-call-discussion";

export const GET_TIMELINE = BASE_URL + "/lead-detail";
export const GET_ESTIMATION_GENERATED = BASE_URL + "/by-lead-detail";
export const ESTIMATION_DOC_DOWNLOAD =
  BASE_URL + "/estimation/download-complete";
export const GET_ESTIMATION_SHARED = BASE_URL + "/shared/by-lead-detail";
export const GET_ON_CALL_LIST = BASE_URL + "/on-call/list";
export const GET_CONVERTED_LIST = BASE_URL + "/convert";

export const LEAD_ANALYTICS = BASE_URL + "/lead-analytics";
export const LEAD_MEETING_COUNT_FILTER = BASE_URL + "/region-meeting-count";
export const DOWNLOAD_EXCEL = BASE_URL + "/download-audit-leads";
export const HO_DOCUMENT_DOWNLOAD = BASE_URL + "/download-estimation";

export const STATE_TO_REGION = BASE_URL + "/regions";
export const REGION_TO_BDM = BASE_URL + "/employee";

export const EXCEL_EXPORT_ALL_DATA_SUPERVISOR = BASE_URL + "/superviser/export";
export const GET_INQUIRY_TYPE = BASE_URL + "/filter/list/InquiryType";
export const GET_REGION_LIST = BASE_URL + "/filter/list/region_name";
export const GET_PROJECT = BASE_URL + "/filter/list/Project";
export const GET_CATEGORY = BASE_URL + "/filter/list/category";
export const GET_CAMPAIGN_NAME = BASE_URL + "/filter/list/campaignName";
export const GET_AUDIT_DETAIL_BY_LOT_NUMBER = BASE_URL + "/get-audit-detail";
export const CALL_STATISTICS = BASE_URL + "/call-statistics";

export const B_PARTY_CONNECTION = BASE_URL + "/call-logs/";
export const CALL_END_STATUS = BASE_URL + "/call-logs/";
export const GET_AUTH_TOKEN = BASE_URL + "/get-auth-token";
export const CALL_INITIATE = BASE_URL + "/initiate-call";
export const HOLD_OR_RESUME = BASE_URL + "/hold-or-resume";
export const CALL_DISCONNECTION = BASE_URL + "/call-disconnection";
export const INCOMING_CALL = BASE_URL + "/latest-agent-call";
export const MERGE_CALL = BASE_URL + "/merge-call";

export const AUDIT_NEW_FARMER_CREATE = BASE_URL + "/create-lead/new-farmer";
export const GET_AUDIT_BY_MOBILE_NO = BASE_URL + "/audit-lead/mobile";

export const BULK_UPLOAD_BI = BASE_URL + "/upload-bi-leads";
export const BULK_UPLOAD_Day = BASE_URL + "/upload-dayop-lead";
export const GET_BI_LIST = BASE_URL + "/bi-day-op-leads";
export const GET_Brooding_LIST = BASE_URL + "/bi-leads";
export const POST_BI_REMARK = BASE_URL + "/bi-day-op-remarks";
export const POST_Brooding_REMARK = BASE_URL + "/bi-remarks";
export const GET_BI_REMARK_LIST = BASE_URL + "/get-bi-remarks/B102A00103";
export const GET_Brooding_REMARK_LIST = BASE_URL + "/get-bi-remarks";
export const SUPERVISOR_DASHBORAD_BI = BASE_URL + "/supervisor-dashboard-bi";
export const BI_STATUS_UPDATE = BASE_URL + "/update-status-bi";
export const REGION_WISE_BI_FILTER = BASE_URL + "/all/leads";
export const BI_TRADER_LIST = BASE_URL + "/bi-trader";
export const BULK_UPLOAD_ENDPOINT = BASE_URL + "/upload-bi-leads";

export const QR_FORM_SUBMIT = BASE_URL + "/customer-lead ";
export const GET_QR_DATA = BASE_URL + "/customers";
export const EXPORT_QR_DATA = BASE_URL + "/customers/export";
export const GET_ENQUIRY_DATA_BY_AGENT = BASE_URL + '/inquiries/employee';

export const BDM_REPORT_GET = BASE_URL + "/bdm-report";

export const GET_ALL_REGION = BASE_URL + "/get-all-region";
export const GET_ALL_ZONAL_MANAGER = BASE_URL + '/get-all-zonal-manager';
export const ADD_ZONAL_MANAGER = BASE_URL + '/zonal-manager';
export const ZONAL_MANAGER_STATUS = BASE_URL + '/zonal-managers/status';
