import React, {useState, useRef, useEffect} from 'react';

import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, Grid, Typography , useMediaQuery, useTheme,} from '@mui/material';
import { Stack, TextField, MenuItem, Divider } from '@mui/material';
import axios from 'axios';
import { SUPERVISOR_DASHBORAD_AUDIT } from '../../constants';
import PlaceIcon from '@mui/icons-material/Place';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AgentPerformance from '../Agent Dashboard/agentPerformance';
import AgentOfferingTime from '../Agent Dashboard/agentOfferingTime';
import TeamPerformance from '../SupervisorDashboard/TeamPerformance';
import TeamOfferingTime from '../SupervisorDashboard/TeamOfferingTime';
import Loader from '../reusable Component/Loader';
import CountListComponent from './CountList';


function AuditSupervisorDashboardComponent({dateFilter}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [cardData, setCardData] = useState([]);
    const [row, setRow] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        axios.get(SUPERVISOR_DASHBORAD_AUDIT,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
              .then(response => {
                const { data } = response.data;
                setCardData(data);
                handleRowData(data);
                setLoading(false);
              })
              .catch(error => {
                console.error('Error fetching notifications:', error);
                setLoading(false);
              }); 
    },[]);

    const columns= [
        { field: 'SNo', headerName: 'SNo',width: 50, type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'agentName', headerName: 'Agent Name',flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
        // { field: 'mappedRegions', headerName: 'Mapped Region',flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'openCount', headerName: 'Open',flex: 1, type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'closedCount', headerName: 'Closed',flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'totalLeads', headerName: 'Total',flex: 1 , type: 'string',  headerAlign: 'center', responsive: true},
    ];

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
            a = {
            "id": index + 1 ,
            "SNo": index + 1,
            "agentName": rd.agentName,
            "mappedRegions": rd.mappedRegions,
            "totalLeads": rd.totalLeads,
            "openCount": rd.openCount,
            "closedCount": rd.closedCount,
        }
        transformedRows.push(a);
        })

        setRow(transformedRows); // Append the total row to the rows
    }

  // const op = useRef(null);

  // const [dateRange, setDateRange] = useState(null);
  // const [dateFil, setDateFil] = useState('')

  // const getTodayDate = () =>{
  //   const today = new Date();
  //         const year = today.getFullYear();
  //         const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  //         const day = String(today.getDate()).padStart(2, '0');
  //         const formattedDate = `${year}-${month}-${day}`;

  //         return formattedDate;
  // }

  // const [dateFilter, setDateFilter] = useState(getTodayDate());

  // const handleDateFilter = (type) =>{
  //   if(type === 'today'){
  //         const today = new Date();
  //         const year = today.getFullYear();
  //         const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  //         const day = String(today.getDate()).padStart(2, '0');
  //         const formattedDate = `${year}-${month}-${day}`;
  //         setDateFilter(formattedDate);
  //   } else if(type == 'yesterday'){
  //         // Get today's date
  //         const today = new Date();

  //         // Subtract one day from today's date
  //         const yesterday = new Date(today);
  //         yesterday.setDate(today.getDate() - 1);

  //         // Extract year, month, and day
  //         const year = yesterday.getFullYear();
  //         const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  //         const day = String(yesterday.getDate()).padStart(2, '0');

  //         // Format the date as yyyy-mm-dd
  //         const formattedDate = `${year}-${month}-${day}`;
  //         setDateFilter(formattedDate);
  //   }else if(type == 'dateRange'){
  //     console.log(dateRange);
  //     const date = new Date(dateRange[0]);
  //     const date1 = new Date(dateRange[1])
  //     // Extract year, month, and day
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
  //     const day = String(date.getDate()).padStart(2, '0');

  //     const year1 = date1.getFullYear();
  //     const month1 = String(date1.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
  //     const day1 = String(date1.getDate()).padStart(2, '0');

  //     // Return formatted date
  //     const startDate =  `${year}-${month}-${day}`;
  //     const endDate = `${year1}-${month1}-${day1}`;

  //     const date_arr = [];
  //     date_arr.push(startDate);
  //     date_arr.push(endDate);
  //     setDateFilter(date_arr);
  //   }
  // };

  // const [dateVar, setDateVar] = useState(null);

  // const handleApply = () =>{
  //     if(dateVar === 'today' ){
  //       handleDateFilter('today')
  //     }
  //     if(dateVar === 'yesterday'){
  //       handleDateFilter('yesterday')
  //     }
  //     if(dateVar === 'dateRange'){
  //       handleDateFilter('dateRange')
  //     }

  //     // console.log('nothing selected')
  // };

  // const handleClear = () =>{
  //   console.log('here')
  //   setDateVar('');
  //   setDateFil('');
  //   setDateRange('');
  //   setDateFilter(getTodayDate());
    
  // }

  return (
    <>
    {loading && <Loader open={loading} />}
    <Grid container spacing={2} className="mb-4" wrap="wrap">
        <Grid item xs={6} sm={6} md={6} lg={5} xl={6}>
        <TeamPerformance />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={5} xl={6}>
        <TeamOfferingTime />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={5} xl={6}>
        <CountListComponent row={row} ></CountListComponent>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={5} xl={6}>
        </Grid>
    </Grid>
      
    
    </>
  );
}

export default AuditSupervisorDashboardComponent;