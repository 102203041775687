import React, {useContext, useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { LoginContext } from '../LoginPage/LoginContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import leadFormStyles from './leadFormStyles.module.css';
import { Divider, Stack, Grid, Button, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs  from 'dayjs';
import { ENQUIRY_TYPE, PROJECT, CATEGORY,LEAD_SOURCE,CLOSED_SUB_CATEGORY, COLD_SUB_CATEGORY, HOT_SUB_CATEGORY, PENDING_SUB_CATEGORY, WARM_SUB_CATEGORY, CREATE_LEAD_API , STATE_API, CALL_TYPE, TITLE, BDM_LIST, CALL_STATUS , UPDATE_STATUS_AGENT, GET_REMARK_LIST, FOLLOW_UP_BY_AGENT, GET_TIMELINE, GET_DETAILS_BY_MOBILE_NUMBER} from '../../constants';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import bdmFormStyles from '../BDM Assigned Page/bdmFormStyles.module.css';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OnCallFormComponent from "../BDM Assigned Page/OnCallForm";
import Loader from '../reusable Component/Loader';
import moment from 'moment';
import LongTextComponent from "./LongTextComponent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineDialog from "../reusable Component/TimelineDialog";
import SaveIcon from '@mui/icons-material/Save';

function LeadDetailsPage() {

    const [runEffect, setRunEffect] = useState(false);
    // const data = useSelector(state => state.leadDetails);
   
    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const leadDetails = JSON.parse(localStorage.getItem('leadDetails'));

    const [storedLeadDetails, setStoredLeadDetails] = useState(leadDetails || null);

  useEffect(() => {
    const storedLeadDetails = JSON.parse(localStorage.getItem('leadDetails'));
    
    if (storedLeadDetails) {
      axios.get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${storedLeadDetails.MobileNo}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(response => {
        const { lead } = response.data;
        handlingRowData(lead);
      }).catch(error => {
        console.error("Error fetching lead details:", error);
      });
    }
  }, [runEffect]);

  const handlingRowData = (data) => {
    if (!data) {
      console.warn("No lead data available to transform.");
      return;
    }
    
    const transformedRow = {
      "id": 1,
      "SNo": 1,
      "leadId": data.id,
      "CustomerName": data.CustomerName?.trim() || '-',
      "MobileNo": data.MobileNo || '-',
      "AlternateMobileNo": data.AlternateMobileNo || '-',
      "state_name": data.state_name || '-',
      "source_of_lead_generated": data.Campaign?.CampaignName || '-',
      "category": data.category || '-',
      "AgentId": data.AgentId,
      "BDMId": data.BDMId,
      "CustomerMailId": data.CustomerMailId || '-',
      "InquiryType": data.InquiryType || '-',
      "Project": data.Project || '-',
      "Superviser": data.Superviser,
      "SuperviserID": data.SuperviserID,
      "WhatsappNo": data.WhatsappNo || '-',
      "agent_remark": data.agent_remark || '-',
      "close_month": data.close_month || '-',
      "follow_up_date": data.follow_up_date ? formatDate(data.follow_up_date) : '-',
      "lead_owner": data.BDM?.EmployeeName || '-',
      "lead_transfer_date": data.lead_transfer_date ? formatDate(data.lead_transfer_date) : '-',
      "location": data.location || '-',
      "site_location_address": data.site_location_address || '-',
      "region_name": data.region_name || '-',
      "sub_category": data.sub_category || '-',
      "Agent": data.Agent,
      "AgentName": data.Agent?.EmployeeName || '-',
      "BDM": data.BDM,
      "pincode": data.pincode || '-',
      "call_status": data.call_status,
      "call_type": data.call_type,
      "createdDate": data.createdAt ? formatDate(data.createdAt) : '-',
      "bdm_remark": data.bdm_remark || '-',
      "lead_calling_date": '-',
      "bdm_calling_date": '-',
      "updatedAt": data.updatedAt ? formatDate(data.updatedAt) : '-',
      "last_action": data.last_action || '-'
    };

    // Update state and localStorage with transformed data
    setStoredLeadDetails(transformedRow);
    localStorage.setItem('leadDetails', JSON.stringify(transformedRow));
  };
   
    // console.log('context', contextLeadDetails);

    // useEffect(() => {
    //     // Update leadDetails when contextLeadDetails changes
    //     setLeadDetails(contextLeadDetails);
    //     console.log('useEffect run')

    // }, [contextLeadDetails]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const handleCategoryChange = (e) => {
        const value = e.target.value;
        setSubCategory('');
        feildsShow(value);
        if (value.trim() === '') {
            setCategory(value);
            setCategoryError('State is required.');
        } else {
            setCategory(value);
            setCategoryError('');
        }
    };

    const [showField, setShowField] = useState(false);
    const [enableField, setEnableField] = useState(false);

    const feildsShow = (cat) =>{
        if(cat == 'hot' || cat == 'warm'){
            setEnableField(true);
        } else{
            setEnableField(false);
        }
        if (cat == 'pending'){
            setShowField(true);
        } else{
            setShowField(false);
        }
    }

    const [subCategory, setSubCategory] = useState('');
    const [subCategoryError, setSubCategoryError] = useState('');
    const handleSubCategoryChange = (e) => {
        const value = e.target.value;
        
        if (value.trim() === '') {
            setSubCategory(value);
            setSubCategoryError('Sub-Category is required.');
        } else {
            setSubCategory(value);
            setSubCategoryError('');
        }
    };

    const [followUpDate, setFollowUpDate] =  useState(undefined);
    const [closureMonth, setClosureMonth] =  useState(undefined);
    const [leadTransferDate, setLeadTransferDate] = useState(undefined);
    const [leadOwner, setLeadOwner] = useState('');
    const [leadSource, setLeadSource] = useState('');
    const [leadSourceError, setLeadSourceError] = useState('');
    const [bdmID, setBdmId] = useState('');
    const [agentRemark, setAgentRemark] = useState('');
    const [agentRemarkError, setAgentRemarkError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const handleFollowupDateTime = (e) =>{
        const dateString = e.$d;

        // Create a new Date object from the given string
        const date = new Date(dateString);

        // Function to pad numbers with leading zeros
        const pad = (num) => (num < 10 ? '0' + num : num);

        // Extract date components
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1);
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = pad(date.getMinutes());
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)

        // Formatted date and time string
        const formattedDate = `${year}-${month}-${day}`;
        setFollowUpDate(formattedDate);
        // console.log(formattedDate);
    };

    const handleChangeExpectedClosureMonth = (e) =>{
        const dateString = e.$d;

        // Create a new Date object from the given string
        const date = new Date(dateString);

        // Array of month names
        const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
        ];

        // Extract month and year
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Formatted string
        const formattedDate = `${monthName} ${year}`;

        
        setClosureMonth(formattedDate);
    }

    

    const handleFollowUpSubmit = ()=>{
        let isValid = true;

        if (category.trim() === '') {
            setCategoryError('Category is required.');
            isValid = false;
        }

        if (subCategory.trim() === '') {
            setSubCategoryError('Sub-Category is required.');
            isValid = false;
        }

        if (agentRemark.trim() === ''){
            setAgentRemarkError('Remark is required.');
            isValid = false;
        }

        if (isValid) {
        const formData ={
            "follow_up_date": followUpDate,
            "category": category,
            "sub_category": subCategory,
            "LeadDetailId": leadDetails.leadId,
            "AgentId": empDetails.EmployeeId,
            "remark": agentRemark,
            "closure_month": closureMonth
        };
        console.log('formData', formData);
    axios.post(FOLLOW_UP_BY_AGENT, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })
      .then(response => {
          setSeverity('success');
          setMessage(response.data.message);
          setOpenSnackbar(true);
          setAgentRemark('');
          setCategory('');
          setSubCategory('');
          setFollowUpDate(undefined);
          setClosureMonth(undefined);
          setEnableField(false);
          setShowField(false);
          setRunEffect(true);
      }) 
      .catch(error => {
        if (error.response) {
          if(error.response.status == 404){
          setSeverity('error');
          setMessage('404 Not Found.');
          setOpenSnackbar(true);
          setRunEffect(false);
          } else{
          setSeverity('error');
          setMessage(error.response.data.message);
          setOpenSnackbar(true);
          setRunEffect(false);
        }
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Request:', error.request);
          setSeverity('error');
          setMessage("Internal Server Error");
          setOpenSnackbar(true);
          setRunEffect(false);
        }
      });
    } else{
        console.log('Form submission blocked due to validation errors.');
            setSeverity('error');
            setMessage("Please check for missing fields.");
            setOpenSnackbar(true);
    }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };

    const [remarkList, setRemarkList] = useState([]);

 useEffect(() => { 
    setLoading(true);
        axios.get(`${GET_REMARK_LIST}/${leadDetails.leadId}`,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
            .then(response => {
              const { leadUpdates } = response.data;
              setRemarkList(leadUpdates);
              console.log("remarkList",remarkList);
              setRunEffect(false);
              setLoading(false);
            }) 
            .catch(error => {
                setLoading(false);
            });
            
    },[runEffect]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top of the page
      };

      const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

      const [timelineOpen, setTimelineOpen] = useState(false);
      const [timelineLogs, setTimelineLogs] = useState([]);
      
      const handletimelineClick = () => {
        setTimelineOpen(true);
      
        axios.get(`${GET_TIMELINE}/${leadDetails.leadId}`,{
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        }).then(response => {
          const { leadLogs } = response.data;
            setTimelineLogs(leadLogs);
          }) 
          .catch(error => {
          });
      
      };
    
    return (
        <>
        {loading && <Loader open={loading} />}
        

        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={`${leadFormStyles.accordian_heading} h-auto`}
            >
        <Typography>Lead Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    sx={{
                        position: 'relative', // Allow absolute positioning for the button
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    {/* Button at the top right */}
                    <Button
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            marginTop: '5px'
                        }}
                        color='primary'
                        variant="contained"
                        onClick={() => handletimelineClick()}
                        size="small"
                    >
                        <VisibilityIcon color='inherit' sx={{fontSize: '1rem', paddingRight: '2px'}} /> Timeline
                    </Button>

                    <Grid container spacing={2} p={3}>
                        {[
                            { label: "Customer Name", value: storedLeadDetails.CustomerName },
                            { label: "Mobile Number", value: storedLeadDetails.MobileNo },
                            { label: "Alternate Mobile Number", value: storedLeadDetails.AlternateMobileNo },
                            { label: "Whatsapp Number", value: storedLeadDetails.WhatsappNo },
                            { label: "Customer Mail ID", value: storedLeadDetails.CustomerMailId },
                            { label: "Postal Pincode", value: storedLeadDetails.pincode },
                            { label: "State", value: storedLeadDetails.state_name },
                            { label: "Region Name", value: storedLeadDetails.region_name },
                            { label: "Site Location Address", value: storedLeadDetails.site_location_address },
                            { label: "Follow-up Date", value: storedLeadDetails.follow_up_date == "-" ? '-' : storedLeadDetails.follow_up_date },
                            { label: "Lead Transfer Date", value: storedLeadDetails.lead_transfer_date == "-" ? '-' : storedLeadDetails.lead_transfer_date },
                            { label: "Date of First Calling", value: storedLeadDetails.createdDate == "-" ? '-' : storedLeadDetails.createdDate },
                            { label: "Campaign", value: storedLeadDetails.source_of_lead_generated },
                            { label: "Lead Owner", value: storedLeadDetails.lead_owner },
                            { label: "Remark", value: <LongTextComponent text={storedLeadDetails.agent_remark === null ? '-' : storedLeadDetails.agent_remark} maxLength={40} /> },
                            { label: "Last Action", value: storedLeadDetails.last_action }
                        ].map(({ label, value }) => (
                            <Grid item xs={12} md={4} xl={6} className="flex" key={label}>
                                <Typography variant='body2' className={`${leadFormStyles.label_margin}`}>
                                    {label}:
                                </Typography>
                                <Typography variant='body2' sx={{wordBreak: 'break-all'}}>
                                    {value}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>




        {remarkList.length == 0 ? (<></>) : (<Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Remarks List</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            >
        <TableContainer component={Paper} sx={{ boxShadow: 2, borderRadius: '8px', margin: '16px 0' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow sx={{ backgroundColor: '#EEF0F4' }}>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>S No</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Follow-up Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Closure Month</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Category</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Sub-Category</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Remarks</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Added By</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {remarkList.map((row, index) => (
                    <TableRow
                    key={row.id}
                    sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#F9FAFC' },
                        '&:last-child td, &:last-child th': { border: 0 }
                    }}
                    >
                    <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.follow_up_date === null ? '-' : formatDate(row.follow_up_date)}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.closure_month === null ? '-' : row.closure_month}</TableCell>
                    <TableCell sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{row.category}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.sub_category}</TableCell>
                    <TableCell component="th" scope="row" sx={{ width: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <LongTextComponent text={row.remark === null ? '-' : row.remark} maxLength={40}></LongTextComponent>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                        {row.AgentId == null ? row.BDM.EmployeeName : row.AgentName.EmployeeName}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

        
        
            </Box>
        </AccordionDetails>
        </Accordion>)}

        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Follow-up Details</Typography>
        </AccordionSummary>
            <AccordionDetails>
            {isMobile ? (
                <OnCallFormComponent roleType={'Agent'} setSeverity={setSeverity} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} leadDetails={leadDetails} setRunEffect={setRunEffect}></OnCallFormComponent>
            ) : (<Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            >
            <div className={`${bdmFormStyles.table_container}`}>
            <table className={`${leadFormStyles.table}`}>
            <thead>
            <tr>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>Follow-up Date</th>
                <th>Expected Closure Month</th>
                <th>Remarks</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <TextField
                id="outlined-select-currency"
                select
                label="Category"
                value={category}
                onChange={
                    handleCategoryChange
                }
                // helperText="Please select your currency"
                size='small'
                required
                error={!!categoryError}
                helperText={categoryError}
                    sx={{
                    '& .MuiFormHelperText-root':{
                        fontSize: '0.65rem',
                        marginLeft: '0'
                    }
                    }}
                >
                {CATEGORY.map((c) => (
                <MenuItem value={c.value} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{c.label}</MenuItem>
                ))}
                </TextField>
                </td>
                <td>
                <TextField
                id="outlined-select-currency"
                select
                label="Sub-Category"
                value={subCategory}
                onChange={handleSubCategoryChange}
                size='small'
                required
                error={!!subCategoryError}
                helperText={subCategoryError}
                    sx={{
                    '& .MuiFormHelperText-root':{
                        fontSize: '0.65rem',
                        marginLeft: '0'
                    }
                    }}
                >
                {category.toLowerCase() == 'hot' && (HOT_SUB_CATEGORY.map((h) => (
                <MenuItem value={h.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{h.label}</MenuItem>
                )))}
                {category.toLowerCase() == 'warm' && (WARM_SUB_CATEGORY.map((h) => (    
                <MenuItem value={h.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{h.label}</MenuItem>
                )))}
                {category.toLowerCase() == 'cold' && (COLD_SUB_CATEGORY.map((h) => (  
                <MenuItem value={h.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{h.label}</MenuItem>
                )))}
                {category.toLowerCase() == 'pending' && (PENDING_SUB_CATEGORY.map((h) => (
                <MenuItem value={h.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{h.label}</MenuItem>
                )))}
                {category.toLowerCase() == 'closed' && (CLOSED_SUB_CATEGORY.map((h) => ( 
                <MenuItem value={h.label} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>{h.label}</MenuItem>
                )))}
                
                </TextField>
                </td>
                <td>
                {(enableField || showField) &&             
                <DateTimePicker label="Follow-up Date" name="startDateTime" onChange={handleFollowupDateTime} disablePast />}
                </td>
                <td>
                {/* {enableField && <DatePicker 
                label="Expected Closure Month" 
                value={dayjs(closureMonth)}
                onChange={(e)=>{
                    const date = new Date(e.$d);
                    const year = date.getFullYear();
                    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so we add 1
                    const day = ("0" + date.getDate()).slice(-2);
                    const formattedDate = `${year}-${month}-${day}`;
                    setClosureMonth(formattedDate)}}/>} */}
            {enableField && <DatePicker label={'Expected Closure Month'} views={['month', 'year']} onChange={handleChangeExpectedClosureMonth} disablePast />}
                </td>
                <td>
                <div className={`${leadFormStyles.fields_padding}`}>
                <TextField
                    id="outlined-multiline-static"
                    label="Remarks"
                    multiline
                    rows={4}
                    fullWidth
                    value={agentRemark}
                    required
                    onChange={(e)=> {
                        if (e.target.value.length <= 250) {
                        setAgentRemark(e.target.value)
                        }
                    }}
                    error={!!agentRemarkError}
                    helperText={agentRemarkError}
                    inputProps={{ 
                        inputProps: {
                            maxLength: '250'
                        }
                    }}
                    style={{ resize: 'both', overflow: 'auto' }}
                    sx={{
                    '& .MuiFormHelperText-root':{
                        fontSize: '0.65rem',
                        marginLeft: '0'
                    }
                    }}
                    />
                    <Typography variant="body2" color={agentRemark.length > 250 ? 'error' : 'text.secondary'} sx={{marginLeft: '7px'}}>
                            {agentRemark.length}/250
                    </Typography>
                </div>
                </td>
                <td>
                    <Button variant="contained" onClick={handleFollowUpSubmit} size="small"><SaveIcon sx={{fontSize: '1rem', paddingRight: '2px'}}></SaveIcon>Save</Button>
                </td>
            </tr>
            </tbody>
            </table>
            </div>
            </Box>)}
        </AccordionDetails>
        </Accordion>

        <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
    </Snackbar>

      <Fab className={`${leadFormStyles.fab_position} bottom-2 right-2`} color="primary" onClick={scrollToTop}>
        <ArrowUpwardIcon />
      </Fab>

      <TimelineDialog open={timelineOpen} setOpen={setTimelineOpen} timelineLogs={timelineLogs} custData={leadDetails}></TimelineDialog>
        </>
    );
}

export default LeadDetailsPage;