import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LongTextComponent from "../Leads Page/LongTextComponent";
import FilterComponent from "./FilterComponent";
import FilterListIcon from "@mui/icons-material/FilterList";
import { EXPORT_QR_DATA, GET_QR_DATA } from "../../constants";
import Loader from "../reusable Component/Loader";
import axios from "axios";
import dayjs from "dayjs";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";

function QRReportComponent({}) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filterShow, setFilterShow] = useState(false);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    shed_plan: "",
    project: "",
    source: "",
    search: "",
  });

  const fetchQrData = () => {
    const { startDate, endDate, shed_plan, project, source, search } = filters; // Destructure filters
    // setLoading(true);
    // Convert dates to YYYY-MM-DD format using dayjs
    const formattedStartDate = startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? dayjs(endDate).format("YYYY-MM-DD")
      : null;

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("startDate", formattedStartDate);
    if (endDate) queryParams.append("endDate", formattedEndDate);
    if (shed_plan) queryParams.append("EC_Shed_Plan", shed_plan);
    if (project) queryParams.append("project", project);
    if (source) queryParams.append("source", source);
    if (search) queryParams.append("search", search);

    // Add pagination params
    queryParams.append("page", page + 1); // API might be 1-indexed
    queryParams.append("pageSize", rowsPerPage);

    axios
      .get(`${GET_QR_DATA}?${queryParams.toString()}`)
      .then((response) => {
        const { data, pagination } = response.data;
        setRows(data);
        setPage(pagination.currentPage - 1);
        setRowsPerPage(pagination.pageSize);
        setTotalItems(pagination.totalItems);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: value,
    }));
  };

  useEffect(() => {
    fetchQrData();
  }, [filters, page, rowsPerPage]); // Fetch data whenever filters, page, or rowsPerPage changes

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update the page number
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
    setPage(0); // Reset to page 0 when changing rows per page
  };

  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCloseModal = () => {
    setIsDownloading(false);
  };

  const handleExport = () => {
    setIsDownloading(true); // Open progress modal
    setDownloadProgress(0); // Reset progress

    let artificialProgressInterval;

    // Start artificial progress if the real progress is too fast
    artificialProgressInterval = setInterval(() => {
      setDownloadProgress((prevProgress) => {
        // Stop artificial progress at 90%
        if (prevProgress >= 70) {
          clearInterval(artificialProgressInterval);
          return prevProgress;
        }
        return prevProgress + 10; // Increment by 10 every 100ms
      });
    }, 100); // Update every 100ms

    const { startDate, endDate, shed_plan, project, source } = filters; // Destructure filters
    // setLoading(true);
    // Convert dates to YYYY-MM-DD format using dayjs
    const formattedStartDate = startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? dayjs(endDate).format("YYYY-MM-DD")
      : null;

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("startDate", formattedStartDate);
    if (endDate) queryParams.append("endDate", formattedEndDate);
    if (shed_plan) queryParams.append("EC_Shed_Plan", shed_plan);
    if (project) queryParams.append("project", project);
    if (source) queryParams.append("source", source);


    axios
      .get(`${EXPORT_QR_DATA}?${queryParams.toString()}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        timeout: 60000, // 1-minute timeout
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const realProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // Stop artificial progress when real progress updates
            clearInterval(artificialProgressInterval);

            // Update progress based on real data, but ensure it doesn't go backwards
            setDownloadProgress((prevProgress) =>
              Math.max(prevProgress, realProgress)
            );
          } else {
            console.warn("Unable to calculate download progress");
          }
        },
      })
      .then((response) => {
        clearInterval(artificialProgressInterval); // Ensure artificial progress is cleared

        // Create file download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        let fileName = "all-lead-file.xlsx";
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches.length > 1) {
            fileName = matches[1];
          }
        }
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Close modal and show success
        setIsDownloading(false);
        setDownloadProgress(0);
        // setSeverity('success');
        // setMessage('File downloaded successfully');
        // setOpenSnackbar(true);
      })
      .catch((error) => {
        clearInterval(artificialProgressInterval);
        console.error("Error downloading file:", error);
        setIsDownloading(false);
        setDownloadProgress(0);
        // setSeverity('error');
        // setMessage('Failed to download the file');
        // setOpenSnackbar(true);
      });
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  return (
    <>
      {loading && <Loader open={loading} />}

      <Dialog
        open={isDownloading}
        onClose={(event, reason) => {
          // Disable closing when clicking outside the modal or pressing 'Escape'
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleCloseModal();
          }
        }}
      >
        <DialogTitle>File Download in Progress...</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "250px",
              minHeight: "150px",
            }}
          >
            {/* Circular Progress with larger size */}
            <CircularProgress
              variant="determinate"
              value={downloadProgress}
              size={80}
              thickness={4}
              sx={{ color: downloadProgress < 100 ? "#1976d2" : "#4caf50" }} // Progress color change
            />
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              {downloadProgress}%
            </Typography>
            {/* Optional text for completed state */}
            {downloadProgress === 100 && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Download complete! Preparing your file...
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "10px" }}
      >
        {/* Left Section: QR Enquiry Report and Total Leads */}
        <Grid item sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            QR Enquiry Report
          </Typography>
          <Box
            sx={{
              backgroundColor: "#f5f5f5", // Grey background
              padding: "8px 16px", // Add some padding for spacing
              borderRadius: "8px", // Rounded corners
              border: "1px solid #ccc", // Light border color
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Elevated box shadow
              display: "inline-block", // Ensure it takes only as much space as needed
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold", // Make the text bold
                fontSize: "1.2rem", // Increase font size for prominence
                color: "#333", // Set a darker color for better visibility
              }}
            >
              Total Leads: {totalItems}
            </Typography>
          </Box>
        </Grid>

        {/* Right Section: Search Box and Filter Icon */}
        <Grid item sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={filters.search}
            onChange={handleSearchChange}
            sx={{
              width: "300px",
            }}
          />

                  {/* Export Button */}
        <IconButton
          onClick={() => handleExport()}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            // margin: "5px",
            width: "40px", // Increased width for a more comfortable click area
            height: "40px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FileDownloadIcon /> {/* Change icon based on state */}
        </IconButton>

          <IconButton
            onClick={() => setFilterShow((prev) => !prev)}
            sx={{
              border: "1px solid #fff",
              borderRadius: "4px",
              margin: "5px",
              width: "40px",
              height: "40px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#097969",
              color: "#fff",
              transition: "background-color 0.3s, transform 0.2s",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#0B9B73", // Darker shade on hover
                transform: "scale(1.05)", // Slightly scale up on hover
              },
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Paper
        elevation={4}
        sx={{
          overflow: "hidden",
          borderRadius: "12px",
          margin: "16px 0",
          padding: "8px",
        }}
      >
        {filterShow && (
          <FilterComponent
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        )}

        <TableContainer>
          <Table
            sx={{
              minWidth: 600,
              tableLayout: "fixed",
              border: "1px solid #e0e0e0",
              "& .MuiTableHead-root": {
                backgroundColor: "#f5f5f5",
              },
              "& .MuiTableCell-root": {
                fontSize: "0.875rem",
                textAlign: "center",
              },
            }}
            aria-label="fancy table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  width={50}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Enquiry Date
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Pincode
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  State
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Location
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Mail ID
                </TableCell>
                <TableCell
                  width={200}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Shed Plan
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Land Available?
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Land Size (LxW)
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Electricity
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Water Availability
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Approachable Road
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Integration Company
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Shed Size (LxW)
                </TableCell>
                <TableCell
                  width={180}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Current Shed Direction
                </TableCell>
                <TableCell
                  width={120}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Electricity Phase
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Current Bird Capacity
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Investment Budget
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  No of Shed
                </TableCell>
                <TableCell
                  width={150}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Source
                </TableCell>
                <TableCell
                  width={180}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#333",
                    padding: 0,
                  }}
                >
                  Remark
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                      "&:hover": { backgroundColor: "#e8f0fe" },
                      transition: "background-color 0.3s",
                    }}
                  >
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{formatDate(row.createdAt) || "-"}</TableCell>
                    <TableCell>{row.CustomerName || "-"}</TableCell>
                    <TableCell>{row.ContactNumber || "-"}</TableCell>
                    <TableCell>{row.pincode || "-"}</TableCell>
                    <TableCell>{row.StateName || "-"}</TableCell>
                    <TableCell>{row.location || "-"}</TableCell>
                    <TableCell
                      sx={{
                        wordWrap: "break-word",
                        maxWidth: "200px",
                        whiteSpace: "normal",
                      }}
                    >
                      {row.CustomerMailId || "-"}
                    </TableCell>
                    <TableCell>{row.EC_Shed_Plan || "-"}</TableCell>
                    <TableCell>
                      {row.LandAvailable === true ? "Yes" : "No" || "-"}
                    </TableCell>
                    <TableCell>{row.Land_Size || "-"}</TableCell>
                    <TableCell>{row.Unit || "-"}</TableCell>
                    <TableCell>{row.Electricity || "-"}</TableCell>
                    <TableCell>
                      {row.WaterAvailabilty === true ? "Yes" : "No" || "-"}
                    </TableCell>
                    <TableCell>
                      {row.ApproachableRoad === true ? "Yes" : "No" || "-"}
                    </TableCell>
                    <TableCell>{row.IntegrationCompany || "-"}</TableCell>
                    <TableCell>{row.ShedSize || "-"}</TableCell>
                    <TableCell>{row.CurrentShedDirection || "-"}</TableCell>
                    <TableCell>{row.ElectricityPhase || "-"}</TableCell>
                    <TableCell>{row.CurrentBirdCapacity || "-"}</TableCell>
                    <TableCell>{row.Investment_Budget || "-"}</TableCell>
                    <TableCell>{row.NUmberOfShed || "-"}</TableCell>
                    <TableCell>{row.Source || "-"}</TableCell>
                    <TableCell>
                      <LongTextComponent
                        text={row.Remark || "-"}
                        maxLength={30}
                      ></LongTextComponent>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={24}
                    sx={{ textAlign: "left", padding: "16px" }}
                  >
                    No Data Available Yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems} // Use totalItems from the response
          rowsPerPage={rowsPerPage} // Use pageSize from the response
          page={page} // Adjusting for 0-based page index
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-toolbar": {
              minHeight: "56px",
              backgroundColor: "#f5f5f5",
            },
            "& .MuiTablePagination-caption": {
              fontSize: "0.875rem",
              color: "#555",
            },
          }}
        />
      </Paper>
    </>
  );
}

export default QRReportComponent;
