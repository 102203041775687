import React, { useEffect, useState, useContext } from "react";
import Typography from '@mui/material/Typography';
import LongTextComponent from "../Leads Page/LongTextComponent";
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { useMediaQuery, useTheme, Chip, IconButton, Tooltip, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Snackbar, Alert} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../LoginPage/LoginContext";
import Loader from "../reusable Component/Loader";
import axios from "axios";
import { BI_STATUS_UPDATE } from "../../constants";

function BIAllTableComponent({rowAll, setRunEffect}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const empDetails = JSON.parse(localStorage.getItem('userData'));
    const navigate = useNavigate(); 

    const { setleadDetails } = useContext(LoginContext);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');

    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [loading, setLoading] = useState(false);


    const IconCell = ({ row }) => (
        <IconButton
          style={{
            border: '1px solid',
            borderRadius: '4px',
            padding: '2px',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color='primary'
          onClick={() => handleIconClick(row)}
        >
          <EditIcon fontSize="small" color='primary'/>
        </IconButton>
      );

      const handleIconClick = (row) =>{
        console.log(row);
        setleadDetails(row);
        navigate('/biLeaddayop');
    };

    const columns = [
      { field: 'SNo', headerName: 'SNo', width: 50, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Branch', headerName: 'Branch Name', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Branch_Description', headerName: 'Branch Description', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Farm_Name', headerName: 'Farm Name', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Farmer_Mob', headerName: 'Farmer Mobile', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Lot_Number', headerName: 'Lot Number', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Age', headerName: 'Age', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Chicks_Housed_Quantity', headerName: 'Chicken House Qty', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mortality_Quantity', headerName: 'Mortality Qty', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mortality_Percentage', headerName: 'Mortality %', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Balance_Birds', headerName: 'Balance Birds', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mort_Percentage_On_Date', headerName: 'Mort(%):OnDate', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mort_Percentage_Date_1', headerName: 'Mort(%):OnDate-1', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mort_Percentage_Date_2', headerName: 'Mort(%):OnDate-2', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mort_Percentage_Date_3', headerName: 'Mort(%):OnDate-3', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'Mort_Percentage_Date_4', headerName: 'Mort(%):OnDate-4', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
      { field: 'status', headerName: 'Status', width: isMobile ? 180 : 80, type: 'string', headerAlign: 'center', responsive: true, headerClassName: 'fixedColumn--headerstatus', cellClassName: 'fixedColumn--cellstatus',
          renderCell: (params) => (
              <>{params.value === '-' ? (<p>-</p>) : (<Chip key={`index_${params.value}`} size='small' label={params.value} color={`${getColorForTag(params.value)}`} sx={{ textTransform: 'capitalize', height: '20px' }} onClick={() => handleChipClick(params.row)}></Chip>)}</>
          ),
      },
      { field: 'action', headerName: 'Action', width: 80, disableColumnFilter: true, type: 'string', headerAlign: 'center', headerClassName: 'fixedColumn--headeraction', cellClassName: 'fixedColumn--cellaction',
          sortable: false,
          renderCell: (params) => <IconCell {...params} />
      },
  ];
  

    const getColorForTag = (tag) => {
        
      switch (tag.toLowerCase()) {
        case 'open':
          return 'error';
        case 'closed':
          return 'success';
        default:
          return 'default';
      }
    };

    const [statusLotNumber, setStatusLotNumber] = useState('');

    const handleChipClick = (row) =>{
      if (row.status == 'closed'){
          setSeverity('warning');
          setAlertTitle(`The status for this Lot Number ${row.Lot_Number} is already closed.`);
          setOpenSnackbar(true);
      } else{
        setOpenStatusDialog(true);
        setStatusLotNumber(row.Lot_Number);
    }
    };

    const handleStatusChange = () =>{
      const formData = {
        "lotNumber": statusLotNumber,
        "newStatus": "closed"
      };

      axios.put(BI_STATUS_UPDATE, formData,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      })
      .then(response => {
            setSeverity('success');
            setAlertTitle(response.data.message);
            setOpenSnackbar(true);
            setOpenStatusDialog(false);
            setRunEffect(true);
      }).catch(error => {
            setSeverity('error');
            setAlertTitle(error.message);
            setOpenSnackbar(true);
            setOpenStatusDialog(false);
      });
    }

    const handleStatusDialogClose = () => {
        setOpenStatusDialog(false);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };
    

    return(
        <>
        {loading && <Loader open={loading} />}

        <Box sx={{
        height: 'auto',
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        }}>
        <DataGrid 
        rows={rowAll} 
        columns={columns} 
        columnHeaderHeight={50}  
        getRowHeight={() => 'auto'}
        autoHeight={rowAll.length < 10}
        disableExtendRowFullWidth
        // hideFooterPagination={theme.breakpoints.down('md')}
        slots={{ toolbar: GridToolbar }}
        
        slotProps={{
            toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true } //Disable the print option in export
            },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{
            pagination: {
            paginationModel: { pageSize: 10, page: 0 },
            },
        }} 
        disableRowSelectionOnClick
        disableDensitySelector
        sx={{
            height: '450px',
            '& .MuiDataGrid-cell': {
            textAlign: 'center',
            placeContent: 'center',
            minHeight: 50,
            fontSize: '0.8rem'
            },
            '& .MuiDataGrid-columnHeader':{
                backgroundColor: '#EEF0F4',
                fontSize: '0.8rem'
            },
            '& .super-app-theme--header':{
                // width: isMobile == true ? '200px !important' : '150px !important',
                // maxWidth: isMobile == true ? '200px !important' : '150px !important'
            },

            '& .fixedColumn--headerstatus': {
            // maxWidth: 50,
            flex: '0 0 auto',
            position: 'sticky',
            right: 80,
            zIndex: 1,
            backgroundColor: '#EEF0F4', // Background color for the fixed column
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
            },
            '& .fixedColumn--cellstatus': {
            // minWidth: 50,
            // maxWidth: 50,
            flex: '0 0 auto',
            position: 'sticky',
            right: 80,
            zIndex: 1,
            backgroundColor: '#fff', // Background color for the fixed column
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
            },
            
            '& .fixedColumn--headeraction': {
            // maxWidth: 50,
            flex: '0 0 auto',
            position: 'sticky',
            right: 0,
            zIndex: 1,
            backgroundColor: '#EEF0F4', // Background color for the fixed column
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
            },
            
            '& .fixedColumn--cellaction': {
            // minWidth: 50,
            // maxWidth: 50,
            flex: '0 0 auto',
            position: 'sticky',
            right: 0,
            zIndex: 1,
            backgroundColor: '#fff', // Background color for the fixed column
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
            }
        }} 
        />
    
        </Box>

        <Dialog
        open={openStatusDialog}
        onClose={handleStatusDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
       <DialogTitle>{"Confirm Status Change"}</DialogTitle>
        <DialogContent>
        <DialogContentText>
           {`Are you sure you want to change the status for this ${statusLotNumber}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
       
              <Button onClick={handleStatusDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleStatusChange} color="primary" autoFocus>
                Confirm
              </Button>
        </DialogActions>
      </Dialog>


        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {/* <AlertTitle>{alertTitle}</AlertTitle> */}
            {alertTitle}
        </Alert>
        </Snackbar>
        </>
    );

}

export default BIAllTableComponent;