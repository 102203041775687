import React, { useEffect, useContext, useState } from 'react';
import MobileMeetingDatatable from './MobileMeetingDatatable';
import { LoginContext } from '../LoginPage/LoginContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import MobileRemarkDatatable from "./MobileRemarkDatatable";
import axios from "axios";
import { ENQUIRY_TYPE, PROJECT, CATEGORY,LEAD_SOURCE,CLOSED_SUB_CATEGORY, COLD_SUB_CATEGORY, HOT_SUB_CATEGORY, PENDING_SUB_CATEGORY, WARM_SUB_CATEGORY, CREATE_LEAD_API , STATE_API, CALL_TYPE, TITLE, BDM_LIST, CALL_STATUS, BDM_REMARK_UPDATE , GET_REMARK_LIST, UPDATE_STATUS_AGENT, PAGE_SECTIONS, GET_MEETING, GET_SITE_VISIT, GET_ESTIMATION } from '../../constants';
import MeetingFormComponent from '../BDM Assigned Page/meetingForm';
import Loader from '../reusable Component/Loader';
import moment from 'moment';
import { Alert, Snackbar } from '@mui/material';


function MobileMeetingPageComponent({leadDetails, setShowMeetingForm}) {

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const [runEffect, setRunEffect] = useState(false);

    useEffect(() => {
      setLoading(true);
            axios.get(`${GET_MEETING}/${leadDetails.id}/meetings`,{
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
              })
            .then(response => {

              handlingMeetingRowData(response.data)
              setLoading(false);
            }) 
            .catch(error => {
              setLoading(false);
            });

    },[runEffect]);

   
    const [finalMeetingRow, setFinalMeetingRow] = useState([]);
    const handlingMeetingRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "BirdsCapacity":rd.BirdsCapacity === null ? '-' : rd.BirdsCapacity,
            "LandDimension":rd.LandDimension === '' ? '-' : rd.LandDimension,
            "ShedSize": rd.ShedSize === '' ? '-' : rd.ShedSize,
            "IsLandDirectionEastWest": rd.IsLandDirectionEastWest === true ? 'Yes' : 'No',
            "DirectionDeviationDegree":rd.DirectionDeviationDegree === null ? '-' : rd.DirectionDeviationDegree,
            "ElectricityPower": rd.ElectricityPower === true ? 'Yes' : 'No',
            "Water": rd.Water === true ? 'Yes' : 'No',
            "ApproachRoad": rd.ApproachRoad === true ? 'Yes' : 'No',
            "ModelType": rd.ModelType === null ? '-' : rd.ModelType,
            "EstimationRequirement": rd.EstimationRequirement === true ? 'Yes' : 'No',
            "Image": rd.Image,
            "category": rd.category === null ? '-' : rd.category.toUpperCase(),
            "sub_category": rd.sub_category === null ? '-' : rd.sub_category,
            "closure_month": rd.closure_month === null ? '-' : rd.closure_month,
            "follow_up_date": rd.follow_up_date === null ? '-' : formatDate(rd.follow_up_date),
            "LeadDetailId": rd.LeadDetailId,
            "createdAt": rd.createdAt === null ? '-' : formatDate(rd.createdAt)
        }
        transformedRows.push(a);
        })
        setFinalMeetingRow(transformedRows); 
    }
    const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
    };
    
    return(
        <>
        {loading && <Loader open={loading} />}
        <Accordion style={{marginTop: '5px'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Meeting</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '8px 9px 16px'}}>
          <MobileMeetingDatatable data={finalMeetingRow} ></MobileMeetingDatatable>
        </AccordionDetails>
        </Accordion>

        <Accordion style={{marginTop: '5px'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Meeting Form</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '8px 9px 16px'}}>
        <MeetingFormComponent  setShowMeetingForm={setShowMeetingForm} leadDetailsId={leadDetails.id} setSeverity={setSeverity} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} setRunEffect={setRunEffect}/>
        </AccordionDetails>
        </Accordion>

        <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
    </Snackbar>
        </>

        
    );

}

export default MobileMeetingPageComponent;