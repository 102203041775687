import React, { useEffect, useMemo, useState } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table';
import LongTextComponent from "../Leads Page/LongTextComponent";
import { Box, Button, Chip, Menu, MenuItem, Table, Tooltip, Typography } from "@mui/material";
import DetailPanelTable from "./DetailPaneTable";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from "moment";
  

function OnCallTable({onCallData}) {

    const [row , setRow] = useState([]);


    const handlingRowData = () =>{
        const transformedRows = onCallData.map((rd, index) => ({
            id: index + 1,
            SNo: index + 1,
            leadId: rd.id,
            CustomerName: rd.CustomerName === '' ? '-' : rd.CustomerName,
            MobileNo: rd.MobileNo === '' ? '-' : rd.MobileNo,
            AlternateMobileNo: rd.AlternateMobileNo === '' ? '-' : rd.AlternateMobileNo,
            state_name: rd.state_name === '' ? '-' : rd.state_name,
            source_of_lead_generated: rd.Campaign == null ? '-' : rd.Campaign.CampaignName,
            category: rd.category === '' ? '-' : rd.category,
            AgentId: rd.AgentId,
            BDMId: rd.BDMId,
            CustomerMailId: rd.CustomerMailId === '' ? '-' : rd.CustomerMailId,
            InquiryType: rd.InquiryType,
            Project: rd.Project,
            Superviser: rd.Superviser,
            SuperviserID: rd.SuperviserID,
            WhatsappNo: rd.WhatsappNo === '' ? '-' : rd.WhatsappNo,
            agent_remark: rd.agent_remark === '' ? '-' : rd.agent_remark,
            close_month: rd.close_month,
            follow_up_date: rd.follow_up_date === '' ? '-' : formatDate(rd.follow_up_date),
            lead_owner: rd.BDM == null ? '-' : rd.BDM.EmployeeName,
            lead_transfer_date: rd.lead_transfer_date === '' ? '-' : formatDate(rd.lead_transfer_date),
            location: rd.location === '' ? '-' : rd.location,
            site_location_address: rd.site_location_address === '' ? '-' : rd.site_location_address,
            region_name: rd.region_name === '' ? '-' : rd.region_name,
            sub_category: rd.sub_category === '' ? '-' : rd.sub_category,
            Agent: rd.Agent,
            AgentName: rd.Agent == null ? '-' : rd.Agent.EmployeeName,
            BDM: rd.BDM,
            pincode: rd.pincode === '' ? '-' : rd.pincode,
            call_status: rd.call_status,
            call_type: rd.call_type,
            createdDate: rd.createdAt === '' ? '-' : formatDate(rd.createdAt),
            bdm_remark: '-',
            lead_calling_date: '-',
            bdm_calling_date: '-',
            Updates: rd.Updatess === undefined ? [] : rd.Updatess.map((update, index) => ({
                sno: index + 1,
                category: update.category === '' ? '-' : update.category,
                closure_month: update.closure_month === null ? '-' : update.closure_month,
                createdAt: formatDate(update.createdAt),
                follow_up_date: update.follow_up_date === null ? '-' : formatDate(update.follow_up_date),
                remark: update.remark  === null ? '-' : update.remark,
                id: update.id,
                sub_category: update.sub_category === '' ? '-' :update.sub_category ,
                updatedAt: update.updatedAt === '' ? '-' : formatDate(update.updatedAt),
                BDM: update.BDM === null ? '-' : update.BDM.EmployeeName,
            }))
        }));
        setRow(transformedRows);
      }

      const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

useEffect(()=>{
    handlingRowData();
},[onCallData]);

    const columns = useMemo(
        () => [
        //   {
        //     accessorKey: "SNo", //simple recommended way to define a column
        //     header: "S No",
        //     // muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        //     // Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
        //   },
          {
            id: "SNo", // Unique ID for the column
            header: "SNo",
            size: 50,
            // Create a dynamic serial number based on row index
            Cell: ({ row, table }) => {
              // Calculate SNo based on current pageIndex and pageSize
              const { pageIndex, pageSize } = table.getState().pagination;
              return pageIndex * pageSize + row.index + 1;
            },
            enableSorting: false, // Disable sorting
            enableColumnFilter: false, // Disable filtering
            enableHiding: false, // Disable hiding
            
          },
          {
            // accessorFn: (row) => row.age, //alternate way
            // id: "age", //id required if you use accessorFn instead of accessorKey
            accessorKey: "lead_owner",
            header: "Lead Owner",
            size: 150,
            // Header: <i style={{ color: "red" }}>Age</i> //optional custom markup
          },
          {
            accessorKey: "CustomerName",
            header: "Customer Name",
            size: 200,
          },
          {
            accessorKey: "MobileNo",
            header: "Mobile Number",
            size: 150,
          },
          {
            accessorKey: "region_name",
            header: "Region Name",
            size: 180,
          },
          {
            accessorKey: "category",
            header: "Category",
            size: 150,
            filterFn: 'equals',
            Cell: ({ cell }) => (
                <Chip
                  size="small"
                  color={getColorForTag(cell.getValue())}
                  label={cell.getValue()}
                  sx={{ textTransform: 'capitalize', height: '20px' }}
                />
            ),
          },
          {
            accessorKey: "sub_category",
            header: "Sub-Category",
            size: 150,
          },
          {
            accessorKey: "agent_remark",
            header: "CSE Remark",
            size: 250,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <LongTextComponent text={cell.getValue() || ''} maxLength={50} />
            ),
          },
          {
            accessorKey: "bdm_remark",
            header: "BDM Remark",
            filterFn: 'contains', // Use a string filter
            size: 250,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <LongTextComponent text={cell.getValue() || ''} maxLength={50} />
              ),
          },
          {
            accessorKey: "location",
            header: "Location",
            size: 200,
          },
          {
            accessorKey: "state_name",
            header: "State",
            size: 150,
          },
          {
            accessorKey: "CustomerMailId",
            header: "Customer Mail ID",
            size: 200,
          },
          {
            accessorKey: "createdDate",
            header: "Date of First Calling",
            size: 250,
          },
          {
            accessorKey: "follow_up_date",
            header: "Follow-Up Date",
            size: 180,
          },
          {
            accessorKey: "close_month",
            header: "Closure Month",
            size: 180,
          },
          {
            accessorKey: "lead_transfer_date",
            header: "Date of Lead Transfer",
            size: 250,
          },
          {
            accessorKey: "bdm_calling_date",
            header: "Date of BDM Calling",
            size: 180,
          },
          {
            accessorKey: "source_of_lead_generated",
            header: "Campaign Name",
            size: 200,
          },
          {
            accessorKey: "AgentName",
            header: "CSE Name",
            size: 150,
          },
          {
            accessorKey: "Project",
            header: "Project",
            size: 200,
          },
          {
            accessorKey: "AlternateMobileNo",
            header: "Alt Mobile Number",
            size: 150,
          },
          {
            accessorKey: "WhatsappNo",
            header: "Whatsapp Number",
            size: 150,
          },
          {
            accessorKey: "updatedAt",
            header: "Lead Updated On",
            size: 180,
          }
        ],
        []
      );

      const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      });
  
      const flattenData = (data) => {
        return data.map((item) => {
            return {
                ...item,
                Updates: item.estimations ? JSON.stringify(item.Updates) : '', // Flatten the `estimations` array
                Agent: item.Agent ? JSON.stringify(item.Agent) : '', // Flatten the `Agent` object
                BDM: item.BDM ? JSON.stringify(item.BDM) : '' // Flatten the `BDM` object
            };
          });
      };
    
  
      const handleExportData = () => {
        const flattenedData = flattenData(row);
        const csv = generateCsv(csvConfig)(flattenedData);
        download(csvConfig)(csv);
        handleMenuClose();
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

      const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleMenuClose = () => {
        setAnchorEl(null);
      };

       // Custom export to Excel function
        const exportToExcel = (data) => {
          const wb = XLSX.utils.book_new();
         // Prepare the worksheet data
          const wsData = [
            columns.map(column => column.header),  // Extract headers
            ...data.map(row => columns.map(column => row.original[column.accessorKey])) // Extract row data
          ];
          const ws = XLSX.utils.aoa_to_sheet(wsData);

          XLSX.utils.book_append_sheet(wb, ws, 'Data');

          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
          saveAs(blob, 'Crm_Leads.xlsx');
          handleMenuClose(); // Close the menu after exporting
        };

      const table = useMaterialReactTable({
        data: row, // This should be data, not row,
        columns,
        enableStickyHeader: true,
        defaultColumn: {
            minSize: 80, 
            maxSize: 400, 
            size: 180, 
          },
          getRowId: (row) => row.id, // ensure each row has a unique ID
          enableExpanding: true, // Enable expanding
            renderDetailPanel: ({ row }) => (
               <DetailPanelTable data={row.original.Updates}></DetailPanelTable>   
            ),
            muiTableHeadCellProps: {
              sx: {
                  padding: '8px',
                  textAlign: 'center',
                  backgroundColor: '#EEF0F4',
              },
          },
          muiTableBodyCellProps: {
              sx: {
                  padding: '8px',
                 
              },
          },
          paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({ table }) => (
          <>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Tooltip title="Export Options">
              <Button
                variant="text"
                onClick={handleMenuClick}
                startIcon={<FileDownloadIcon />}
              >
              Export
              </Button>
            </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => exportToExcel(table.getRowModel().rows)}>Export to Excel</MenuItem>
                <MenuItem onClick={handleExportData}>Export to CSV</MenuItem>
                {/* Add more menu items for additional export options if needed */}
              </Menu>
            </Box>
            </>
            ),
      });

      const getColorForTag = (tag) => {
        switch (tag.toLowerCase()) {
          case 'hot':
            return 'error'; // React blue
          case 'warm':
            return 'warning'; // JavaScript yellow
          case 'cold':
            return 'info'; // UI dark blue
          default:
            return 'default'; // Default gray
        }
      };
    return(
        <>
        <MaterialReactTable table={table} enableExpanding />
        </>
    );
}

export default OnCallTable;