import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LongTextComponent from "../Leads Page/LongTextComponent";
import axios from "axios";
import {
  GET_DETAILS_BY_MOBILE_NUMBER,
  GET_LEAD_BY_BDM_API,
  GET_TIMELINE,
} from "../../constants";
import CallIcon from "@mui/icons-material/Call";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import FilterPanel from "./FilterPanel";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import TimelineDialog from "../reusable Component/TimelineDialog";

function HotLeadTable({ empId, setLoading }) {
  const navigate = useNavigate();

  const [hotLeadRowData, setHotLeadRowData] = useState([]);
  

  const [page, setPage] = useState(0); // Page index (starts from 0 for MUI Pagination)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [totalCount, setTotalCount] = useState(0); // Total items

  const fetchHotLeadRowData = (
    page = 1,
    pageSize = 10,
    search = "",
    filters = {}
  ) => {
    // Convert filters object into query string parameters
    const filterParams = Object.entries(filters)
      .filter(([key, value]) => value) // Include only filters with values
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    setLoading(true); // Set loading to true when starting the API call

    axios
      .get(
        `${GET_LEAD_BY_BDM_API}/${empId}?category=hot&page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(
          search
        )}&${filterParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((response) => {
        const { leads, totalCount } = response.data;
        setHotLeadRowData(leads); // Update the state with the fetched data
        setFilteredData(leads);
        setTotalCount(totalCount); // Update the total count for pagination
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching hot lead data:", error);
        setLoading(false);
      });
  };

  const handleRowData = (data, type) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        id: index + 1,
        // "SNo": index + 1,
        leadId: rd.id,
        CustomerName: rd.CustomerName == "" ? "-" : rd.CustomerName,
        MobileNo: rd.MobileNo == "" || rd.MobileNo === null ? "-" : rd.MobileNo,
        AlternateMobileNo:
          rd.AlternateMobileNo == "" || rd.AlternateMobileNo === null
            ? "-"
            : rd.AlternateMobileNo,
        state_name:
          rd.state_name == "" || rd.state_name === null ? "-" : rd.state_name,
        source_of_lead_generated:
          rd.Campaign == null || rd.Campaign === ""
            ? "-"
            : rd.Campaign.CampaignName,
        category: rd.category == "" || rd.category === null ? "-" : rd.category,
        AgentId: rd.AgentId,
        BDMId: rd.BDMId,
        CustomerMailId:
          rd.CustomerMailId == "" || rd.CustomerMailId === null
            ? "-"
            : rd.CustomerMailId,
        InquiryType: rd.InquiryType,
        Project: rd.Project,
        Superviser: rd.Superviser,
        SuperviserID: rd.SuperviserID,
        WhatsappNo:
          rd.WhatsappNo == "" || rd.WhatsappNo == null ? "-" : rd.WhatsappNo,
        agent_remark:
          rd.agent_remark == "" || rd.agent_remark == null
            ? "-"
            : rd.agent_remark,
        close_month:
          rd.close_month == "" || rd.close_month === null
            ? "-"
            : rd.close_month,
        follow_up_date:
          rd.follow_up_date == "" || rd.follow_up_date === null
            ? "-"
            : formatDate(rd.follow_up_date),
        lead_owner: rd.BDM == null ? "-" : rd.BDM.EmployeeName,
        lead_transfer_date:
          rd.lead_transfer_date == null || rd.lead_transfer_date == ""
            ? "-"
            : formatDate(rd.lead_transfer_date),
        location: rd.location == "" || rd.location == null ? "-" : rd.location,
        site_location_address:
          rd.site_location_address == "" || rd.site_location_address == null
            ? "-"
            : rd.site_location_address,
        region_name:
          rd.region_name == "" || rd.region_name == null ? "-" : rd.region_name,
        sub_category:
          rd.sub_category == "" || rd.sub_category == null
            ? "-"
            : rd.sub_category,
        Agent: rd.Agent,
        AgentName: rd.Agent == null ? "-" : rd.Agent.EmployeeName,
        BDM: rd.BDM,
        pincode: rd.pincode == "" ? "-" : rd.pincode,
        call_status:
          rd.call_status == "" || rd.call_status === null
            ? "-"
            : rd.call_status,
        call_type:
          rd.call_type == "" || rd.call_type === null ? "-" : rd.call_type,
        createdDate: rd.createdAt == null ? "-" : formatDate(rd.createdAt),
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        bdm_remark:
          rd.bdm_remark === "" || rd.bdm_remark === null ? "-" : rd.bdm_remark,
        lead_calling_date: "-",
        bdm_calling_date: "-",
        updatedAt: rd.updatedAt == "" ? "-" : formatDate(rd.updatedAt),
        last_action:
          rd.last_action === null || rd.last_action === ""
            ? "-"
            : rd.last_action,
      };
      // console.log(a)
      transformedRows.push(a);
    });
    setHotLeadRowData(transformedRows);
  };

  useEffect(() => {
    fetchHotLeadRowData(page + 1, rowsPerPage); // Convert 0-based index to 1-based for API
  }, [page, rowsPerPage]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(hotLeadRowData);

  // useEffect(() => {
  //   if (hotLeadRowData && hotLeadRowData.length > 0) {
  //     setFilteredData(hotLeadRowData);
  //   }
  // }, [hotLeadRowData]);

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "hot":
        return "error"; // React blue
      case "warm":
        return "warning"; // JavaScript yellow
      case "cold":
        return "info"; // UI dark blue
      case "pending":
        return "warning";
      default:
        return "default"; // Default gray
    }
  };

  const theme = useTheme();
  const isXs = useMediaQuery("(max-width:600px)"); // 0px - 600px
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md")); // 600px - 900px
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg")); // 900px - 1200px
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl")); // 1200px - 1536px
  const isXl = useMediaQuery(theme.breakpoints.up("xl")); // 1536px and up

  const getHeight = () => {
    if (isXs) return 400;
    if (isSm) return 450;
    if (isMd) return 500;
    if (isLg) return 500;
    if (isXl) return "100vh";
    return "auto";
  };

  const columnWidths = {
    SNo: "50px",
    "Last Action": "180px",
    "Last Action Date": "150px",
    "Lead Owner": "180px",
    "Farmer Name": "150px",
    "Mobile Number": "150px",
    Region: "150px",
    Category: "150px",
    "Sub-Category": "150px",
    "CSE Remarks": "250px",
    "BDM/BI Remarks": "250px",
    "City/Village/Location": "150px",
    State: "150px",
    "Mail ID": "180px",
    "Date of First Calling": "150px",
    "Follow-up Date": "150px",
    "Closure Month": "150px",
    "Date of Lead Transfer": "150px",
    "Date of BDM Calling": "150px",
    "Campaign Name": "150px",
    "CSE Name": "150px",
    Project: "150px",
    "Alternate Number": "150px",
    "Whatsapp Number": "150px",
  };

  const IconCell = ({ row }) => (
    <>
      <Tooltip title="Timeline" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5px",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handletimelineClick(row)}
        >
          <VisibilityIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Edit" arrow>
        <IconButton
          style={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "2px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
          color="primary"
          onClick={() => handleIconClick(row)}
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );

  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineLogs, setTimelineLogs] = useState([]);
  const [custData, setCustData] = useState([]);

  const handletimelineClick = (row) => {
    setTimelineOpen(true);
    setCustData(row);
    axios
      .get(`${GET_TIMELINE}/${row.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leadLogs } = response.data;
        setTimelineLogs(leadLogs);
      })
      .catch((error) => {});
  };

  const handleCallIconClick = (row) => {
    navigate("/dashboard", {
      state: {
        mobNo: row.MobileNo,
        lotNum: row.MobileNo,
      },
    });
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const handleIconClick = (row) => {
    axios
      .get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${row.MobileNo}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { lead } = response.data;

        // Pass the data to handlingRowData
        handlingRowData(lead);
      })
      .catch((error) => {
        console.error("Error fetching lead details:", error);
      });
  };

  const handlingRowData = (data) => {
    if (!data || data.length === 0) {
      console.warn("No lead data available to transform.");
      return;
    }
    const transformedRows = [data].map((rd, index) => ({
      id: index + 1,
      SNo: index + 1,
      leadId: rd.id,
      CustomerName: rd.CustomerName?.trim() || "-",
      MobileNo: rd.MobileNo || "-",
      AlternateMobileNo: rd.AlternateMobileNo || "-",
      state_name: rd.state_name || "-",
      source_of_lead_generated: rd.Campaign?.CampaignName || "-",
      category: rd.category || "-",
      AgentId: rd.AgentId,
      BDMId: rd.BDMId,
      CustomerMailId: rd.CustomerMailId || "-",
      InquiryType: rd.InquiryType || "-",
      Project: rd.Project || "-",
      Superviser: rd.Superviser,
      SuperviserID: rd.SuperviserID,
      WhatsappNo: rd.WhatsappNo || "-",
      agent_remark: rd.agent_remark || "-",
      close_month: rd.close_month || "-",
      follow_up_date: rd.follow_up_date ? formatDate(rd.follow_up_date) : "-",
      lead_owner: rd.BDM?.EmployeeName || "-",
      lead_transfer_date: rd.lead_transfer_date
        ? formatDate(rd.lead_transfer_date)
        : "-",
      location: rd.location || "-",
      site_location_address: rd.site_location_address || "-",
      region_name: rd.region_name || "-",
      sub_category: rd.sub_category || "-",
      Agent: rd.Agent,
      AgentName: rd.Agent?.EmployeeName || "-",
      BDM: rd.BDM,
      pincode: rd.pincode || "-",
      call_status: rd.call_status,
      call_type: rd.call_type,
      createdDate: rd.createdAt ? formatDate(rd.createdAt) : "-",
      bdm_remark: rd.bdm_remark || "-",
      lead_calling_date: "-",
      bdm_calling_date: "-",
      updatedAt: rd.updatedAt ? formatDate(rd.updatedAt) : "-",
      last_action: rd.last_action || "-",
    }));

    // Store transformed data in localStorage
    localStorage.setItem("leadDetails", JSON.stringify(transformedRows[0]));

    setTimeout(() => navigate("/bdmDetails"), 100);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchHotLeadRowData(newPage + 1, rowsPerPage, searchTerm); // Adjust for 0-based indexing
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setPage(0); // Reset to the first page
  };

  const [filters, setFilters] = useState({
    call_status: "",
    fromDate: null,
    toDate: null,
    sortBy: "",
    sortOrder: "",
  });

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters); // Update the filters state
    fetchHotLeadRowData(page + 1, rowsPerPage, searchTerm, newFilters); // Fetch data with updated filters
  };

  const handleExport = () => {
    // Map the data into an array of objects for Excel export
    const exportData = hotLeadRowData.map((row, index) => ({
      SNo: index + 1,
      "Last Action": row.last_action,
      "Last Action Date": row.updatedAt,
      "Lead Owner": row.lead_owner,
      "Farmer Name": row.CustomerName,
      "Mobile Number": row.MobileNo,
      Region: row.region_name,
      Category: row.category,
      "Sub-Category": row.sub_category,
      "CSE Remarks": row.agent_remark || "-",
      "BDM/BI Remarks": row.bdm_remark || "-",
      "City/Village/Location": row.location,
      State: row.state_name,
      "Mail ID": row.CustomerMailId,
      "Date of First Calling": row.createdDate,
      "Follow-up Date": row.follow_up_date,
      "Closure Month": row.close_month,
      "Date of Lead Transfer": row.lead_transfer_date,
      "Date of BDM Calling": row.bdm_calling_date,
      "Campaign Name": row.source_of_lead_generated,
      "CSE Name": row.AgentName,
      Project: row.Project,
      "Alternate Number": row.AlternateMobileNo,
      "Whatsapp Number": row.WhatsappNo,
    }));

    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, "LeadsData.xlsx");
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Trigger a new API fetch with the updated search term
    fetchHotLeadRowData(page + 1, rowsPerPage, value);
  };

  return (
    <>
      <TimelineDialog
        open={timelineOpen}
        setOpen={setTimelineOpen}
        timelineLogs={timelineLogs}
        custData={custData}
      ></TimelineDialog>

      <Paper>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{display: 'flex'}}>
            {/* Export Button */}
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleExport}
              style={{
                border: "1px solid #fff", // Change border color to white for contrast
                borderRadius: "4px",
                margin: "5px",
                width: "40px", // Increased width for a more comfortable click area
                height: "40px", // Increased height for a more comfortable click area
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#097969",
                color: "#fff",
                transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
                e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#097969"; // Original color
                e.currentTarget.style.transform = "scale(1)"; // Reset scale
              }}
            >
              <FileDownloadIcon />
            </IconButton>

            <FilterPanel
              filters={filters}
              setFilters={setFilters}
              onApplyFilters={handleApplyFilters}
            />
          </div>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            size="small"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            // margin: "16px 0",
            height: filteredData.length > 10 ? getHeight() : "auto",
          }}
        >
          <Table
            sx={{ minWidth: 650, height: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "50px",
                    position: "sticky",
                    top: 0,
                    left: 0, // Sticky to the left
                    zIndex: 2,
                    backgroundColor: "#EEF0F4",
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "150px", // Adjust width as needed
                    position: "sticky",
                    top: 0,
                    left: "50px", // Positioned to follow "SNo"
                    zIndex: 2,
                    backgroundColor: "#EEF0F4",
                  }}
                >
                  Last Action
                </TableCell>
                {[
                  "Last Action Date",
                  "Lead Owner",
                  "Farmer Name",
                  "Mobile Number",
                  "Region",
                  "Category",
                  "Sub-Category",
                  "CSE Remarks",
                  "BDM/BI Remarks",
                  "City/Village/Location",
                  "State",
                  "Mail ID",
                  "Date of First Calling",
                  "Follow-up Date",
                  "Closure Month",
                  "Date of Lead Transfer",
                  "Date of BDM Calling",
                  "Campaign Name",
                  "CSE Name",
                  "Project",
                  "Alternate Number",
                  "Whatsapp Number",
                ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      position: "relative",
                      width: columnWidths[header] || "auto", // Default to auto if width not defined
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Show ellipsis for overflowing text
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: "#EEF0F4",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "120px",
                    position: "sticky", // Make the cell sticky
                    right: 0, // Fix to the right side
                    backgroundColor: "#EEF0F4", // Set background color for visibility
                    zIndex: 100, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    top: 0,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={22}> No Data available Yet.</TableCell>
                </TableRow>
              ) : (
                filteredData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        width: "50px",
                        position: "sticky",
                        top: 0,
                        left: 0, // Sticky to the left
                        zIndex: 1,
                        backgroundColor: "#EEF0F4",
                      }}
                    >
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        width: "150px", // Adjust width as needed
                        position: "sticky",
                        top: 0,
                        left: "50px", // Positioned to follow "SNo"
                        zIndex: 1,
                        backgroundColor: "#EEF0F4",
                      }}
                    >
                      {row.last_action}
                    </TableCell>
                    <TableCell align="center">{formatDate(row.updatedAt)}</TableCell>
                    <TableCell align="center">{row.BDM?.EmployeeName || '-'}</TableCell>
                    <TableCell align="center">{row.CustomerName || '-'}</TableCell>
                    <TableCell align="center">{row.MobileNo || '-'}</TableCell>
                    <TableCell align="center">{row.region_name || "-"}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={row.category}
                        color={getColorForTag(row.category)}
                        size="small"
                        sx={{ textTransform: "capitalize" }}
                      />
                    </TableCell>
                    <TableCell align="center">{row.sub_category || '-'}</TableCell>
                    <TableCell align="center">
                      <LongTextComponent
                        text={
                          row.agent_remark === null ? "-" : row.agent_remark
                        }
                        maxLength={50}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <LongTextComponent
                        text={row.bdm_remark === null ? "-" : row.bdm_remark}
                        maxLength={50}
                      />
                    </TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                    <TableCell align="center">{row.state_name}</TableCell>
                    <TableCell align="center">{row.CustomerMailId || '-'}</TableCell>
                    <TableCell align="center">{formatDate(row.createdDate)}</TableCell>
                    <TableCell align="center">{formatDate(row.follow_up_date)}</TableCell>
                    <TableCell align="center">{row.close_month}</TableCell>
                    <TableCell align="center">
                      {formatDate(row.lead_transfer_date)}
                    </TableCell>
                    <TableCell align="center">{formatDate(row.bdm_calling_date) || '-'}</TableCell>
                    <TableCell align="center">
                      {row.Campaign?.CampaignName}
                    </TableCell>
                    <TableCell align="center">{row.Agent?.EmployeeName}</TableCell>
                    <TableCell align="center">{row.Project || '-'}</TableCell>
                    <TableCell align="center">
                      {row.AlternateMobileNo || '-'}
                    </TableCell>
                    <TableCell align="center">{row.WhatsappNo || "-"}</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "120px",
                        padding: 1,
                        position: "sticky", // Make the cell sticky
                        right: 0, // Fix to the right side
                        backgroundColor: "#EEF0F4", // Set background color for visibility
                        zIndex: 10, // Higher z-index to stay above other cells
                        boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Add a shadow to the column
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "5px",
                        }}
                      >
                        <IconCell row={row} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount} // Use the total count from the API
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default HotLeadTable;
