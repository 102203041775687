import React, { useContext, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import bdmFormStyles from "./bdmFormStyles.module.css";
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import { DOWNLOAD_AND_GET_CONVERTED, LEAD_CONVERTED } from "../../constants";
import axios from "axios";
import pdf_img from "../../icons/pdf.png";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../reusable Component/Loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";

function ConvertedViewPage() {
  const storedLeadDetails = JSON.parse(localStorage.getItem("leadDetails"));

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const initialDocuments = [
    {
      id: 1,
      key: "payment_amount",
      name: "Payment Amount",
      type: "input",
      file: [],
      isMandatory: true,
    },
    {
      id: 2,
      key: "payment_slip",
      name: "Payment Slip Document",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 3,
      key: "customer_creation_form",
      name: "Customer Creation Form",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 4,
      key: "pan_card",
      name: "PAN Card",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 5,
      key: "aadhar_card",
      name: "Aadhar Card",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 6,
      key: "land_certificate",
      name: "Land Certificate for unloading address",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 7,
      key: "gst_certificate",
      name: "GST Certificate",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 8,
      key: "bank_account_details",
      name: "Bank Account Details",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 8,
      key: "bank_cheques",
      name: "3 Bank Cheques",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 10,
      key: "legal_agreement_copy",
      name: "Legal Agreement Copy",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 11,
      key: "affidavit_property",
      name: "Affidavit (Property), If applicable",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 12,
      key: "consent_letter_dispatch",
      name: "Consent Letter for dispatch",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 13,
      key: "consent_letter_third_party_payment",
      name: "Consent Letter for 3rd Party payment, if applicable",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 14,
      key: "estimation",
      name: "Estimation ",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 15,
      key: "final_quotation",
      name: "Final Quotation",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 16,
      key: "annexure",
      name: "Annexure",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 17,
      key: "udyam_registration_certificate",
      name: "Udyam Registration Certificate",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 18,
      key: "gram_panchayat_noc",
      name: "Gram Panchayat NOC",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    // { id: 19, key: 'payment_amount' ,name: 'Other',type: 'upload', file: [] , isMandatory: false},
    {
      id: 19,
      key: "remark",
      name: "Remarks",
      type: "input",
      file: [],
      isMandatory: true,
    },
  ];

  const [documents, setDocuments] = useState(initialDocuments);

  useEffect(() => {
    axios
      .get(
        `${DOWNLOAD_AND_GET_CONVERTED}?LeadDetailId=${storedLeadDetails.leadId}`
      )
      .then((response) => {
        const data = response.data;
        console.log("here");
        const updatedDocuments = initialDocuments.map((doc) => {
          const key = doc.key;
          if (data[key]) {
            return {
              ...doc,
              file: Array.isArray(data[key]) ? data[key] : [data[key]], // For 'input' type like payment_amount
            };
          }
          return doc;
        });
        setDocuments(updatedDocuments);
        setLoading(false);
      })
      .catch((error) => {
        setSeverity("error");
        setMessage("Unable to Get Documents.");
        setOpenSnackbar(true);
      });
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleBackButton = () => {
    navigate("/mobilebdmpage");
  };

  return (
    <>
      {loading && <Loader open={loading} />}

      {isMobile && (
        <IconButton onClick={handleBackButton}>
          <ArrowBackIcon></ArrowBackIcon>
        </IconButton>
      )}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
          sx={{
            "& .Mui-expanded": {
              margin: "10px 0 !important",
            },
          }}
        >
          <Typography>Lead Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <Grid container spacing={2} p={3}>
              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Customer Name:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.CustomerName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Mobile Number:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.MobileNo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Alternate Mobile Number:
                </Typography>

                <Typography variant="body2">
                  {storedLeadDetails.AlternateMobileNo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Whatsapp Number:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.WhatsappNo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={"flex"}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Customer Mail ID:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.CustomerMailId}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Postal Pincode:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.pincode}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  State:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.state_name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Region Name:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.region_name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  City/Village/Location:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.location}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Site Location Address:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.site_location_address}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Follow-up Date:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.follow_up_date === "-"
                    ? "-"
                    : storedLeadDetails.follow_up_date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Lead Transfer Date:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.lead_transfer_date === "-"
                    ? "-"
                    : storedLeadDetails.lead_transfer_date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Campaign:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.source_of_lead_generated}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Lead Shared By:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.lead_owner}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
          sx={{
            "& .Mui-expanded": {
              margin: "10px 0 !important",
            },
          }}
        >
          <Typography>Converted Form</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ justifyContent: "center" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  SNo
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  Document Name
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Document
                </th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                  <td
                    data-label="SNo"
                    style={{
                      padding: "8px",
                      width: "3%",
                      border: "1px solid #ddd",
                    }}
                  >
                    {index + 1}.
                  </td>
                  <td
                    data-label="Document"
                    style={{
                      padding: "8px",
                      width: "30%",
                      border: "1px solid #ddd",
                    }}
                  >
                    {document.name}{" "}
                    {document.isMandatory && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      width: "50%",
                      border: "1px solid #ddd",
                    }}
                  >
                    {document.type === "input" ? (
                      <p style={{ wordBreak: "break-all" }}>{document.file}</p>
                    ) : document.file && document.file.length > 0 ? (
                      <div style={{ display: "flex", gap: "10px" }}>
                        {document.file.map((fileUrl, index) => (
                          <div
                            key={index}
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            {fileUrl.endsWith(".pdf") ? (
                              <div>
                                <img
                                  src={pdf_img}
                                  alt={`Preview_pdf ${index}`}
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => window.open(fileUrl, "_blank")}
                                />
                              </div>
                            ) : (
                              <img
                                src={fileUrl}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "cover",
                                }}
                                onClick={() => window.open(fileUrl, "_blank")}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No File Available</p> // Fallback if file array is empty
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConvertedViewPage;
