import { io } from "socket.io-client";

let socket; // Declare socket instance

export const initializeSocket = () => {
  if (!socket) {
    socket = io("https://crmapi.abisexport.com", {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });
  }
  return socket;
};