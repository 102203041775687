import React, {useContext, useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { LoginContext } from '../LoginPage/LoginContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import bdmFormStyles from '../BDM Assigned Page/bdmFormStyles.module.css';
import { Divider, Stack, Grid, Button , Snackbar, IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs  from 'dayjs';
import { useMediaQuery, useTheme, Chip} from '@mui/material';
import { ENQUIRY_TYPE, PROJECT, CATEGORY,LEAD_SOURCE,CLOSED_SUB_CATEGORY, COLD_SUB_CATEGORY, HOT_SUB_CATEGORY, PENDING_SUB_CATEGORY, WARM_SUB_CATEGORY, CREATE_LEAD_API , STATE_API, CALL_TYPE, TITLE, BDM_LIST, CALL_STATUS, BDM_REMARK_UPDATE , GET_REMARK_LIST, UPDATE_STATUS_AGENT, PAGE_SECTIONS, GET_MEETING, GET_SITE_VISIT, GET_ESTIMATION, GET_TIMELINE } from '../../constants';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MobileOnCallSection from "./MobileOnCallSection";
import MobileMeetingPageComponent from "./MobileMeetingPageComponent";
import MobileSiteVisitSection from "./MobileSiteVisitSection";
import MobileEstimationSection from "./MobileEstimationSection";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import TimelineDialog from "../reusable Component/TimelineDialog";
import axios from "axios";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MobileEstimationGeneratedSection from "./MobileEstimationGeneratedSection";
import MobileEstimationSharedSection from "./MobileEstimationSharedSection";
import MobileConvertedDatatable from "./MobileConvertedDatatable";
import MobileEstimationConvertedSection from "./MobileConvertedSection";
import moment from "moment";

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));


function MobileBDMDetailsPage({}) {
    const navigate = useNavigate();

    const empDetails = JSON.parse(localStorage.getItem('userData'));
    const leadDetails = JSON.parse(localStorage.getItem('leadDetails'));

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [showMeetingForm, setShowMeetingForm]= useState(false);
    const [showSiteVisitForm, setSiteVisitForm]= useState(false);
    const [showEstimationForm, setEstimationForm]= useState(false);

    const [OnCallSectionStatus, setOnCallSectionStatus] = useState(false);
    const [meetingSection, setMeetingSection] = useState(false);
    const [siteVisitSection, setSiteVisitSection] = useState(false);
    const [estimationSection, setEstimationSection] = useState(false);
    const [estimationGenSection, setEstimationGenSection] = useState(false);
    const [estimationSharedSection, setEstimationSharedSection] = useState(false);
    const [estimationConvertedSection, setEstimationConvertedSection] = useState(false);
    

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClickList = (event) => {
        const value = event.currentTarget.textContent;
        if(value === 'On-Call Discussion'){
         setOnCallSectionStatus(true);
         setMeetingSection(false);
         setSiteVisitSection(false);
         setEstimationSection(false);
         setEstimationGenSection(false);
         setEstimationSharedSection(false);
         setEstimationConvertedSection(false);
         handleClose();
        }
        if(value === 'Meeting'){
            setMeetingSection(true);
            setOnCallSectionStatus(false);
            setEstimationSection(false);
            setSiteVisitSection(false);
            setShowMeetingForm(true);
            setEstimationGenSection(false);
            setEstimationSharedSection(false);
            setEstimationConvertedSection(false);
            handleClose();
        }
        if(value === 'Site Visit'){
            setSiteVisitSection(true);
            setMeetingSection(false);
            setOnCallSectionStatus(false);
            setEstimationSection(false);
            setSiteVisitForm(true);
            setEstimationGenSection(false);
            setEstimationSharedSection(false);
            setEstimationConvertedSection(false);
            handleClose();
        }
        if(value === 'Estimation Request'){
            setEstimationSection(true);
            setOnCallSectionStatus(false);
            setMeetingSection(false);
            setSiteVisitSection(false);
            setEstimationForm(true);
            setEstimationGenSection(false);
            setEstimationSharedSection(false);
            setEstimationConvertedSection(false);
            handleClose();
        }
        

        if(value === 'Estimation Generated'){
            setEstimationGenSection(true);
            setEstimationSection(false);
            setOnCallSectionStatus(false);
            setMeetingSection(false);
            setSiteVisitSection(false);
            setEstimationForm(false);
            setEstimationSharedSection(false);
            setEstimationConvertedSection(false);
            handleClose();
        }

        if(value === 'Estimation Shared'){
            setEstimationSharedSection(true);
            setEstimationGenSection(false);
            setEstimationSection(false);
            setOnCallSectionStatus(false);
            setMeetingSection(false);
            setSiteVisitSection(false);
            setEstimationForm(false);
            setEstimationConvertedSection(false);
            handleClose();
        }

        if(value === 'Converted'){
            setEstimationConvertedSection(true);
            setEstimationGenSection(false);
            setEstimationSection(false);
            setOnCallSectionStatus(false);
            setMeetingSection(false);
            setSiteVisitSection(false);
            setEstimationForm(false);
            setEstimationSharedSection(false);
            handleClose();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
      };

      const handleBackButton = () =>{
        navigate('/bdmAssigned');
      };

      const [timelineOpen, setTimelineOpen] = useState(false);
      const [timelineLogs, setTimelineLogs] = useState([]);
      
      const handletimelineClick = () => {
        setTimelineOpen(true);
      
        axios.get(`${GET_TIMELINE}/${leadDetails.leadId}`,{
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        }).then(response => {
          const { leadLogs } = response.data;
            setTimelineLogs(leadLogs);
          }) 
          .catch(error => {
          });
      
      };

      const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

    return(
        <>
        <IconButton onClick={handleBackButton}>
            <ArrowBackIcon></ArrowBackIcon>
        </IconButton>
        <Accordion defaultExpanded style={{margin: '0px'}} >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Lead Details</Typography>
        </AccordionSummary>
            <AccordionDetails style={{padding: '8px 9px 16px'}}>
            <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            >
            <Stack direction={"row"}>
            
            <Grid container >
            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography  className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Customer Name:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.CustomerName} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6}  className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Mobile Number:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.MobileNo} 
                </Typography>
            </Grid> 

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Alternate Mobile Number:
                </Typography>

                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.AlternateMobileNo} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Whatsapp Number:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.WhatsappNo} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={'flex'}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Customer Mail ID:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.CustomerMailId} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Postal Pincode:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.pincode} 
                </Typography>
            </Grid>
            
            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    State:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.state_name} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2' className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Region Name:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.region_name} 
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Site Location Address:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.site_location_address}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Follow-up Date:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.follow_up_date === "-" ? '-' : formatDate(leadDetails.follow_up_date)
                    }
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Lead Transfer Date:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.lead_transfer_date === "-" ? '-' : formatDate(leadDetails.lead_transfer_date)
                    }
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Campaign:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.source_of_lead_generated}
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography variant='body2'className={`${leadFormStyles.label_margin} ${leadFormStyles.label_font_size}`}>
                    Lead Shared By:
                </Typography>
                <Typography variant='body2' className={`${leadFormStyles.label_font_size}`}>
                    {leadDetails.lead_owner}
                </Typography>
            </Grid>
            
            </Grid>

        <div>
            <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className={`float-right`}
            sx={{ minWidth: '30px', fontSize: '0.8rem', padding: '6px 6px'}}
        >
            Action
        </Button>
        <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        >
        
        {PAGE_SECTIONS.map((eq) => (<MenuItem onClick={handleClickList} value={eq.value} disableRipple>{eq.label}</MenuItem>))}
        </StyledMenu>

                <Button
                    sx={{ minWidth: '30px', fontSize: '0.8rem', padding: '6px 6px', marginTop: '5px', float: 'right'}}
                    color='primary'
                    variant="contained"
                    onClick={() => handletimelineClick()}
                    >
                    <VisibilityIcon fontSize="small" color='inherit'/> Timeline
                    </Button>
        </div>

            </Stack>

            </Box>
            </AccordionDetails>
        </Accordion>

        {OnCallSectionStatus && <MobileOnCallSection leadDetails={leadDetails}></MobileOnCallSection>}

        {meetingSection && <MobileMeetingPageComponent setShowMeetingForm={setShowMeetingForm} leadDetails={leadDetails}></MobileMeetingPageComponent>}

        {siteVisitSection && <MobileSiteVisitSection setSiteVisitForm={setSiteVisitForm} leadDetails={leadDetails}></MobileSiteVisitSection>}

        {estimationSection && <MobileEstimationSection setEstimationForm={setEstimationForm} leadDetails={leadDetails}></MobileEstimationSection>}

        {estimationGenSection && <MobileEstimationGeneratedSection leadDetails={leadDetails}></MobileEstimationGeneratedSection>}

        {estimationSharedSection && <MobileEstimationSharedSection leadDetails={leadDetails}></MobileEstimationSharedSection>}

        {estimationConvertedSection && <MobileEstimationConvertedSection leadDetails={leadDetails}></MobileEstimationConvertedSection>}

        <TimelineDialog open={timelineOpen} setOpen={setTimelineOpen} timelineLogs={timelineLogs} custData={leadDetails}></TimelineDialog>
        </>
    );
}

export default  MobileBDMDetailsPage;