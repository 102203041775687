import React, { useContext, useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import auditFormStyles from "../Audit/auditFromStyles.module.css";
import StatusChangeDialogCaller from "./StatusChangeDialogCaller";
import {
  GET_AUDIT_BY_MOBILE_NO,
  GET_AUDIT_DETAIL_BY_LOT_NUMBER,
} from "../../constants";
import axios from "axios";
import NewUserAuditFormComponent from "./NewUserAuditForm";

function AuditFormComponent({
  setFormOpen,
  setOpenSnackbar,
  setSeverity,
  setMessage,
  setalertTitle,
  mobNo,
  lotNum,
  mobNum,
}) {
  const empDetails = JSON.parse(localStorage.getItem("userData"));

  const [storedLeadDetails, setStoredLeadDetails] = useState([]);
  const [runEffect, setRunEffect] = useState(false);

  const [newForm, setnewForm] = useState(false);
  const [gotLotNum, setGotLotNum] = useState("");

  const [loading, setLoading] = useState(true); // State to track loading

  const fetchLotByMobNo = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${GET_AUDIT_BY_MOBILE_NO}/${mobNum}`);

      // Check if response indicates no records found
      if (response.data.success === false) {
        console.log(response.data.message);
        setnewForm(true); // Open the new form if no records are found
      } else {
        const data = response.data.data[0]; // Assuming a single item in data array

        if (data) {
          handleLotLogic(data.Lot_Number); // Process based on fetched Lot_Number
        }
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setLoading(false); // Stop loading after response
    }
  };

  const fetchLotDetails = async (lotNumber) => {
    try {
      const response = await axios.get(
        `${GET_AUDIT_DETAIL_BY_LOT_NUMBER}/${lotNumber}`
      );

      setStoredLeadDetails(response.data.data.details);
    } catch (error) {
      console.error("Error getting data:", error);
    }
  };

  // Use lotNum or fetch it from mobNum
  const determineLotNumber = async () => {
    if (lotNum) {
      handleLotLogic(lotNum);
    } else if (mobNum) {
      fetchLotByMobNo(mobNum);
    }
  };

  const handleLotLogic = (lotNumber) => {
    if (lotNumber.includes("LOT")) {
      setnewForm(true); // Open the new form if "LOT" is present in Lot_Number
    } else {
      setGotLotNum(lotNumber);
      fetchLotDetails(lotNumber); // Fetch additional details otherwise
    }
  };

  useEffect(() => {
    determineLotNumber();
  }, [lotNum, mobNum]);

  console.log("lotNum-------", lotNum);
  console.log("mobNum-------", mobNum);

  const initialFormValues = {
    CH: "",
    AGE: "",
    BWT: "",
    M_QTY: "",
    REASON: "",
    MED: "",
    FEED: "",
    STOCK: "",
    IFFT_IN: "",
    IFFT_OUT: "",
    LS_VISIT: "",
    BM_VISIT: "",
    DAILY_ENT: "",
    FEED_ENT: "",
    MORT_ENT: "",
    BWT_ENT: "",
    MED_ENT: "",
    REMARKS: "",
    DATE: null,
    Lot_Number: lotNum,
  };

  const [formValues, setFormValues] = useState({
    CH: "",
    AGE: "",
    BWT: "",
    M_QTY: "",
    REASON: "",
    MED: "",
    FEED: "",
    STOCK: "",
    IFFT_IN: "",
    IFFT_OUT: "",
    LS_VISIT: "",
    BM_VISIT: "",
    DAILY_ENT: "",
    FEED_ENT: "",
    MORT_ENT: "",
    BWT_ENT: "",
    MED_ENT: "",
    REMARKS: "",
    DATE: null,
    Lot_Number: lotNum,
    AgentId: empDetails.EmployeeId,
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (newDate) => {
    setFormValues({
      ...formValues,
      DATE: newDate,
    });
  };

  // To reset formValues to initial state
  const resetForm = () => {
    setFormValues(initialFormValues);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
  const [status, setStatus] = useState(""); // To store the selected status

  const handleOpenDialog = (e) => {
    e.preventDefault();
    setDialogOpen(true); // Open the dialog on submit
  };

  console.log("newForm-----------", newForm);

  return (
    <>
      {loading ? (
        <p>Loading...</p> // Display a loading message or spinner while fetching
      ) : (
        <>
          {newForm ? (
            <NewUserAuditFormComponent
              setOpenSnackbar={setOpenSnackbar}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setalertTitle={setalertTitle}
              setnewForm={setnewForm}
            ></NewUserAuditFormComponent>
          ) : (
            <>
              {/* leadDetails */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className={`${auditFormStyles.accordian_heading} h-auto`}
                >
                  <Typography>Lot Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Lot Number:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Lot_Number}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Farmer Name:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Farmer_Name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Mobile Number:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Mobile}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Zone Name:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Zone_Name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Branch Name:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Branch_Name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Vendor:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Vendor}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Shed Type:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Shed_Type}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Hatch Date:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Hatch_Date}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Chicks Housed Quantity:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Placed_Qty}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          CA:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.CA}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Age:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Age_SAP}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Diff:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Diff}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          First Week Mortality Quantity:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.first_Week_M}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          First Week Mortality Percentage:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.First_Week_Mortality_Percentage}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Total Mortality Quantity:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Total_Mortality}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Total Mortality Percentage:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Total_Mortality_Percentage}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Lifting EA:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Lifting_EA}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Lifting Percentage:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Lift_Percentage}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Average Lifted Weight:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Avg_Lift_Wt}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Balance Birds:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Bal_Birds}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Average Body Weight:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.ABWT}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          BWT Age:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.BWT_Age}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Feed Cons:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Feed_Cons}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Previous Grade:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Prev_Grade}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          FCR:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.FCR}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Line:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Line}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} xl={6} className={`flex`}>
                        <Typography
                          variant="body2"
                          className={`${auditFormStyles.label_margin}`}
                        >
                          Hatchery Name:
                        </Typography>
                        <Typography variant="body2">
                          {storedLeadDetails.Hatchery_Name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/* Remark Form */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className={`${auditFormStyles.accordian_heading} h-auto`}
                >
                  <Typography>Remark Form</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card>
                    <form>
                      <Grid container spacing={2} p={3}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Lot Number"
                            id="outlined-size-small"
                            name="Lot_Number"
                            value={formValues.Lot_Number}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            size="small"
                            fullWidth
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Chicks Housed"
                            id="outlined-size-small"
                            name="CH"
                            value={formValues.CH}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            error={!!formErrors.CH}
                            helperText={formErrors.CH}
                            size="small"
                            fullWidth
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Mean Age"
                            id="outlined-size-small"
                            name="AGE"
                            value={formValues.AGE}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            error={!!formErrors.AGE}
                            helperText={formErrors.AGE}
                            size="small"
                            fullWidth
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Body Weight"
                            id="outlined-size-small"
                            name="BWT"
                            value={formValues.BWT}
                            onChange={(e) => {
                              if (/^\d?(\.\d{0,3})?$/.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            error={!!formErrors.BWT}
                            helperText={formErrors.BWT}
                            size="small"
                            fullWidth
                            inputProps={{
                              inputMode: "decimal",
                              pattern: "\\d?(\\.\\d{0,3})?",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Mortality Quantity"
                            id="outlined-size-small"
                            name="M_QTY"
                            value={formValues.M_QTY}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                handleChange(e);
                              }
                            }}
                            error={!!formErrors.M_QTY}
                            helperText={formErrors.M_QTY}
                            size="small"
                            fullWidth
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Reason"
                            id="outlined-size-small"
                            name="REASON"
                            multiline
                            rows={4}
                            value={formValues.REASON}
                            onChange={handleChange}
                            error={!!formErrors.REASON}
                            helperText={formErrors.REASON}
                            size="small"
                            fullWidth
                            style={{ resize: "both", overflow: "auto" }}
                            sx={{
                              "& .MuiInputLabel-root": {
                                top: "0", // Adjust to align the label properly
                                "&.MuiInputLabel-shrink": {
                                  top: "6px", // Ensure the label is fully visible when it shrinks
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Medicine"
                            id="outlined-size-small"
                            name="MED"
                            multiline
                            rows={4}
                            value={formValues.MED}
                            onChange={handleChange}
                            error={!!formErrors.MED}
                            helperText={formErrors.MED}
                            size="small"
                            fullWidth
                            style={{ resize: "both", overflow: "auto" }}
                            sx={{
                              "& .MuiInputLabel-root": {
                                top: "0", // Adjust to align the label properly
                                "&.MuiInputLabel-shrink": {
                                  top: "6px", // Ensure the label is fully visible when it shrinks
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Feed"
                            id="outlined-size-small"
                            name="FEED"
                            value={formValues.FEED}
                            onChange={handleChange}
                            error={!!formErrors.FEED}
                            helperText={formErrors.FEED}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Stock"
                            id="outlined-size-small"
                            name="STOCK"
                            value={formValues.STOCK}
                            onChange={handleChange}
                            error={!!formErrors.STOCK}
                            helperText={formErrors.STOCK}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="IFFT In"
                            id="outlined-size-small"
                            name="IFFT_IN"
                            value={formValues.IFFT_IN}
                            onChange={handleChange}
                            error={!!formErrors.IFFT_IN}
                            helperText={formErrors.IFFT_IN}
                            size="small"
                            multiline
                            rows={4}
                            fullWidth
                            style={{ resize: "both", overflow: "auto" }}
                            sx={{
                              "& .MuiInputLabel-root": {
                                top: "0", // Adjust to align the label properly
                                "&.MuiInputLabel-shrink": {
                                  top: "6px", // Ensure the label is fully visible when it shrinks
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="IFFT Out"
                            id="outlined-size-small"
                            name="IFFT_OUT"
                            value={formValues.IFFT_OUT}
                            onChange={handleChange}
                            error={!!formErrors.IFFT_OUT}
                            helperText={formErrors.IFFT_OUT}
                            size="small"
                            multiline
                            rows={4}
                            fullWidth
                            style={{ resize: "both", overflow: "auto" }}
                            sx={{
                              "& .MuiInputLabel-root": {
                                top: "0", // Adjust to align the label properly
                                "&.MuiInputLabel-shrink": {
                                  top: "6px", // Ensure the label is fully visible when it shrinks
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="LS Visit"
                            id="outlined-size-small"
                            name="LS_VISIT"
                            value={formValues.LS_VISIT}
                            onChange={handleChange}
                            error={!!formErrors.LS_VISIT}
                            helperText={formErrors.LS_VISIT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="BM Visit"
                            id="outlined-size-small"
                            name="BM_VISIT"
                            value={formValues.BM_VISIT}
                            onChange={handleChange}
                            error={!!formErrors.BM_VISIT}
                            helperText={formErrors.BM_VISIT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Daily Ent"
                            id="outlined-size-small"
                            name="DAILY_ENT"
                            value={formValues.DAILY_ENT}
                            onChange={handleChange}
                            error={!!formErrors.DAILY_ENT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Feed Ent"
                            id="outlined-size-small"
                            name="FEED_ENT"
                            value={formValues.FEED_ENT}
                            onChange={handleChange}
                            error={!!formErrors.FEED_ENT}
                            helperText={formErrors.FEED_ENT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Mortality Ent"
                            id="outlined-size-small"
                            name="MORT_ENT"
                            value={formValues.MORT_ENT}
                            onChange={handleChange}
                            error={!!formErrors.MORT_ENT}
                            helperText={formErrors.MORT_ENT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="BWT Ent"
                            id="outlined-size-small"
                            name="BWT_ENT"
                            value={formValues.BWT_ENT}
                            onChange={handleChange}
                            error={!!formErrors.BWT_ENT}
                            helperText={formErrors.BWT_ENT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Medicine Ent"
                            id="outlined-size-small"
                            name="MED_ENT"
                            value={formValues.MED_ENT}
                            onChange={handleChange}
                            error={!!formErrors.MED_ENT}
                            helperText={formErrors.MED_ENT}
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                    <DesktopDatePicker
                        label="Select Date"
                        inputFormat="MM/DD/YYYY"
                        value={formValues.DATE}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                            //   helperText: 'MM/DD/YYYY',
                              clearable: true
                            },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: '100%'}}
                    />
                    </Grid> */}

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Remarks"
                            id="outlined-size-small"
                            name="REMARKS"
                            multiline
                            rows={4}
                            value={formValues.REMARKS}
                            onChange={handleChange}
                            error={!!formErrors.REMARKS}
                            helperText={formErrors.REMARKS}
                            size="small"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Box display="flex" justifyContent="flex-end" p={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={(e) => handleOpenDialog(e)}
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  </Card>
                </AccordionDetails>
              </Accordion>

              <StatusChangeDialogCaller
                open={dialogOpen}
                setOpen={setDialogOpen}
                formValues={formValues}
                setFormValues={setFormValues}
                setOpenSnackbar={setOpenSnackbar}
                setSeverity={setSeverity}
                setAlertTitle={setMessage}
                resetForm={resetForm}
                setRunEffect={setRunEffect}
                empDetails={empDetails}
                lotNum={lotNum}
                setFormOpen={setFormOpen}
              ></StatusChangeDialogCaller>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AuditFormComponent;
