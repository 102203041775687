import React, { useMemo, useEffect } from "react";
import { Grid, TextField, Button, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import dayjs from "dayjs"; // Ensure dayjs is imported

const DetailsFilterComponent = ({
  filters,
  setFilters,
  bdmStatisticsList,
  applyFilters,
}) => {
  const today = dayjs().startOf("day"); // Get today's date at midnight

  const [filtersData, setFiltersData] = useState({
    startDate: filters.startDate ? dayjs(filters.startDate) : today, // Convert to dayjs if it's not already
    endDate: filters.endDate ? dayjs(filters.endDate) : today, // Convert to dayjs if it's not already
  });

  const handleInputChange = (key, value) => {
    // When the startDate is changed, update endDate if necessary
    if (key === "startDate") {
      setFiltersData((prevFilters) => ({
        ...prevFilters,
        startDate: value,
        endDate: value.isBefore(prevFilters.endDate)
          ? value
          : prevFilters.endDate, // If the endDate is before the startDate, set endDate to startDate
      }));
    } else {
      setFiltersData((prevFilters) => ({
        ...prevFilters,
        [key]: value,
      }));
    }
  };

  const handleApply = () => {
    // Ensure both startDate and endDate are valid before applying
    if (filtersData.startDate.isValid() && filtersData.endDate.isValid()) {
      setFilters({
        startDate: filtersData.startDate.toDate(), // Ensure it's a plain Date object for submission
        endDate: filtersData.endDate.toDate(), // Ensure it's a plain Date object for submission
      });
    } else {
      // Show an error if dates are not valid (can show a toast or alert)
      console.error("Please ensure both dates are valid before applying.");
    }
  };

  const handleClear = () => {
    setFilters({
      startDate: today.toDate(), // Set to today's date as a Date object
      endDate: today.toDate(), // Set to today's date as a Date object
    });
    setFiltersData({
      startDate: today, // Set to today's date (dayjs object)
      endDate: today, // Set to today's date (dayjs object)
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f0f4f8",
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
        marginBottom: 3,
      }}
    >
      <Grid container spacing={2}>
        {/* Start Date Picker */}
        <Grid item xs={12} sm={4}>
          <DatePicker
            label="Start Date"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "40px", // Matches the height of TextField
                padding: "0 10px", // Adjust padding for alignment
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Consistent border radius
              },
              "& .MuiInputLabel-root": {
                fontSize: "0.875rem", // Matches label size
              },
            }}
            value={filtersData.startDate} // Pass dayjs object directly
            format="DD/MM/YYYY"
            onChange={(newValue) => handleInputChange("startDate", newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>

        {/* End Date Picker */}
        <Grid item xs={12} sm={4}>
          <DatePicker
            label="End Date"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                height: "40px", // Matches the height of TextField
                padding: "0 10px", // Adjust padding for alignment
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Consistent border radius
              },
              "& .MuiInputLabel-root": {
                fontSize: "0.875rem", // Matches label size
              },
            }}
            value={filtersData.endDate} // Pass dayjs object directly
            format="DD/MM/YYYY"
            onChange={(newValue) => handleInputChange("endDate", newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleClear}
              variant="outlined"
              color="secondary"
              sx={{ marginRight: 2 }}
            >
              Clear
            </Button>
            <Button onClick={handleApply} variant="contained" color="primary">
              Apply
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailsFilterComponent;
