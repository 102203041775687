import React, {useState, useRef, useEffect} from 'react';
import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Snackbar, Typography , useMediaQuery, useTheme,} from '@mui/material';
import { Stack, TextField, MenuItem, Divider } from '@mui/material';
import axios from 'axios';
import { BULK_UPLOAD_AUDIT, DOWNLOAD_EXCEL, GET_AUDIT_LIST, REGION_WISE_AUDIT_FILTER, REGIONS, SUPERVISOR_DASHBORAD_AUDIT } from '../../constants';
import PlaceIcon from '@mui/icons-material/Place';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Loader from '../reusable Component/Loader';
import auditFormStyles from '../Audit/auditFromStyles.module.css';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';

function AuditMasterDataComponent({dateFilter}) {

    const fileInputRef = useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [rowAll, setRowAll] = useState([]);
    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const [runEffect,setRunEffect] = useState(false);
    
    useEffect(()=>{
        setLoading(true);
        
        fetchAuditLeadList();
    },[runEffect]);

  

    const fetchAuditLeadList = (regionName) =>{
        const page = 1;
        const limit = 10000;
        const region = regionName || 'ANDHRA PRADESH-I';
        const status = 'open';
        const sortBy = 'updatedAt';
        const sortOrder = 'DESC';
        axios.get(`${REGION_WISE_AUDIT_FILTER}?page=${page}&limit=${limit}&region=${region}&status=${status}&sortBy=${sortBy}&sortOrder=${sortOrder}`,{
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        })
        .then(response => {
            const {data} = response.data;
            handleAllRowData(data);
            setLoading(false);
        }).catch(error => {
        });
      };

      const handleAllRowData = (data) =>{
        
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
            a = {
            "id": index + 1 ,
            "SNo": index + 1,
            "Zone_Name": rd.Zone_Name,
            "Region_Description": rd.Region_Description,
            "Branch": rd.Branch,
            "Branch_Name": rd.Branch_Name,
            "Farmer_Name": rd.Farmer_Name,
            "Lot_Number": rd.Lot_Number,
            "Vendor": rd.Vendor,
            "Shed_Type": rd.Shed_Type,
            "Hatch_Date": rd.Hatch_Date,
            "Mean_Age": rd.Mean_Age,
            "Placed_Qty": rd.Placed_Qty,
            "Lifted_Birds": rd.Lifted_Birds,
            "Bal_Birds": rd.Bal_Birds,
            "Total_Mortality": rd.Total_Mortality,
            "Total_Mortality_Percentage": rd.Total_Mortality_Percentage,
            "FCR": rd.FCR,
            "LR": rd.LR,
            "CA": rd.CA,
            "Age_SAP": rd.Age_SAP,
            "Diff": rd.Diff,
            "first_Week_M": rd.first_Week_M,
            "First_Week_Mortality_Percentage": rd.First_Week_Mortality_Percentage,
            "Lifting_EA": rd.Lifting_EA,
            "Lift_Percentage": rd.Lift_Percentage,
            "ABWT": rd.ABWT,
            "BWT_Age": rd.BWT_Age,
            "Feed_Cons": rd.Feed_Cons,
            "Avg_Lift_Wt": rd.Avg_Lift_Wt,
            "Prev_Grade": rd.Prev_Grade,
            "Mobile": rd.Mobile,
            "Line": rd.Line,
            "Hatchery_Name": rd.Hatchery_Name,
            "createdAt": rd.createdAt,
            "updatedAt": new Date(rd.updatedAt),
            "status": rd.status,
            "last_action_date": rd.last_action_date === null ? '-' : formatDate(rd.last_action_date)
        }
        transformedRows.push(a);
        })
        setRowAll(transformedRows);
    };

    const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

    const columns = [
        { field: 'SNo', headerName: 'SNo',width: 50, type: 'string',  headerAlign: 'center', responsive: true, headerClassName:isMobile ? '' : 'fixedColumn--header', cellClassName: isMobile ? '' : 'fixedColumn--cell',},
        { field: 'last_action_date', headerName: 'Last Action Date',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true, headerClassName: isMobile ? '' : 'fixedColumn--header1', cellClassName: isMobile ? '' : 'fixedColumn--cell1'},
        { field: 'Zone_Name', headerName: 'Zone Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Lot_Number', headerName: 'Lot Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Farmer_Name', headerName: 'Farmer Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Branch_Name', headerName: 'Branch Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Vendor', headerName: 'Vendor',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Shed_Type', headerName: 'Shed Type',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Placed_Qty', headerName: 'Placed Quantity',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'CA', headerName: 'CA',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Age_SAP', headerName: 'Age(SAP)',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Diff', headerName: 'Diff',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'first_Week_M', headerName: 'First Week Mortality',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'First_Week_Mortality_Percentage', headerName: 'First Week Mortality Percentage',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Total_Mortality', headerName: 'Mortality Quantity',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Total_Mortality_Percentage', headerName: 'Mortality Percentage',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Lifting_EA', headerName: 'Lifting EA',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Lift_Percentage', headerName: 'Lift Percentage',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Bal_Birds', headerName: 'Balance Birds',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'FCR', headerName: 'FCR',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'ABWT', headerName: 'Avg Body Weight',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'BWT_Age', headerName: 'BWT Age',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Feed_Cons', headerName: 'Feed Cons',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Avg_Lift_Wt', headerName: 'Lifted Avg Weight',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Prev_Grade', headerName: 'Prev Grade',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Mobile', headerName: 'Mobile Number',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},  
        { field: 'Hatch_Date', headerName: 'Hatch Date',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},
        { field: 'Line', headerName: 'Line',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},  
        { field: 'Hatchery_Name', headerName: 'Hatchery Name',width: isMobile ? 120 : 150 , type: 'string',  headerAlign: 'center', responsive: true},  
        { field: 'updatedAt', headerName: 'Last Modified On',width: isMobile ? 120 : 150 , type: 'dateTime',  headerAlign: 'center', responsive: true,  }, 
        { field: 'status', headerName: 'Status', width: isMobile ? 180 : 100, type: 'string',  headerAlign: 'center',   responsive: true,headerClassName: 'fixedColumn--headerstatus', cellClassName: 'fixedColumn--cellstatus',
          renderCell: (params) => (
                    <>{params.value === '-' ? (<p>-</p> ) : (<Chip key={`index_${params.value}`} size='small'  label={params.value} color={`${getColorForTag(params.value)}`} sx={{textTransform: 'capitalize', height: '20px'}}></Chip>)}</>
                ),
        }, 
    ];

    const getColorForTag = (tag) => {
        
        switch (tag.toLowerCase()) {
          case 'open':
            return 'error';
          case 'closed':
            return 'success';
          default:
            return 'default';
        }
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };

      const [open, setOpen] = useState(false);
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [file, setFile] = useState(null);

      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          setOpen(true);
          setFile(file);
          // uploadFile(file);
      }
      
    };

    const handleUpload = () => {
      setUploading(true);
      setProgress(0);
      
      // Simulate file upload
      const uploadInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(uploadInterval);
            uploadFile(file);
            // setTimeout(() => setOpen(false), 1000);
            return 100;
          }
          return prevProgress + 10;
        });
      }, 500);
    };

    const uploadFile = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      axios.post(BULK_UPLOAD_AUDIT, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
      })
      .then(response => {
          console.log('File uploaded successfully:', response.data);
          setSeverity('success');
          setAlertTitle(response.data.message);
          setMessage('Updated Count:' + response.data.updatedCount);
          setOpenSnackbar(true);
          
          setUploading(false);
          setOpen(false);

          fileInputRef.current.value = null;
          setRunEffect(true);
          // navigate(0);
          
      })
      .catch(error => {
          console.error('Error uploading file:', error);
          // setRunEffect(false);
          setSeverity('error');
          setMessage(error.response.data.message);
          setOpenSnackbar(true);
          
      });
    };
 
    const handleButtonClick = () => {
        fileInputRef.current.click();
      };

        const handleCloseDialog = () => {
            if (!uploading) {
            setOpen(false);
            setFile(null);
            }
        };

        const handleDateFilter = (e) =>{
            // setRegionName(e.target.value);
            fetchAuditLeadList(e.target.value);
        };

        const handleTraderClick = (e) => {
            navigate('/auditTraderList');
      };
  
      const handleInHouseClick = (e) =>{
        
          e.preventDefault();
      };

      const [downloadProgress, setDownloadProgress] = useState(0);
      const [isDownloading, setIsDownloading] = useState(false);
      
      const handleCloseModal = () => {
          setIsDownloading(false);
      };
      
      const handleExport = () => {
        setIsDownloading(true); // Open progress modal
        setDownloadProgress(0); // Reset progress
        
        let artificialProgressInterval;
    
        // Start artificial progress if the real progress is too fast
        artificialProgressInterval = setInterval(() => {
            setDownloadProgress((prevProgress) => {
                // Stop artificial progress at 90%
                if (prevProgress >= 70) {
                    clearInterval(artificialProgressInterval);
                    return prevProgress;
                }
                return prevProgress + 10; // Increment by 10 every 100ms
            });
        }, 100); // Update every 100ms
    
        axios.get(DOWNLOAD_EXCEL, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            timeout: 60000, // 1-minute timeout
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const realProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    
                    // Stop artificial progress when real progress updates
                    clearInterval(artificialProgressInterval);
                    
                    // Update progress based on real data, but ensure it doesn't go backwards
                    setDownloadProgress((prevProgress) => Math.max(prevProgress, realProgress));
                } else {
                    console.warn('Unable to calculate download progress');
                }
            }
        })
        .then(response => {
            clearInterval(artificialProgressInterval); // Ensure artificial progress is cleared
    
            // Create file download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
    
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'downloaded-file.xlsx';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="(.+)"/);
                if (matches && matches.length > 1) {
                    fileName = matches[1];
                }
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            // Close modal and show success
            setIsDownloading(false);
            setDownloadProgress(0);
            // setSeverity('success');
            // setMessage('File downloaded successfully');
            // setOpenSnackbar(true);
        })
        .catch(error => {
            clearInterval(artificialProgressInterval);
            console.error('Error downloading file:', error);
            setIsDownloading(false);
            setDownloadProgress(0);
            setSeverity('error');
            setMessage('Failed to download the file');
            setOpenSnackbar(true);
        });
    };
    
    
      
      
      
    

    return(
        <>
        {loading && <Loader open={loading} />}

        <Dialog open={isDownloading} 
            onClose={(event, reason) => {
              // Disable closing when clicking outside the modal or pressing 'Escape'
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                  handleCloseModal();
              }
          }}
          >
          <DialogTitle>File Download in Progress...</DialogTitle>
          <DialogContent>
              <Box 
                  sx={{
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      minWidth: '250px', 
                      minHeight: '150px'
                  }}
              >
                  {/* Circular Progress with larger size */}
                  <CircularProgress 
                      variant="determinate" 
                      value={downloadProgress} 
                      size={80} 
                      thickness={4}
                      sx={{ color: downloadProgress < 100 ? '#1976d2' : '#4caf50' }} // Progress color change
                  />
                  <Typography 
                      variant="h6" 
                      sx={{ mt: 2, fontWeight: 'bold' }}
                  >
                      {downloadProgress}%
                  </Typography>
                  {/* Optional text for completed state */}
                  {downloadProgress === 100 && (
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                          Download complete! Preparing your file...
                      </Typography>
                  )}
              </Box>
          </DialogContent>
      </Dialog>


        <div className={`${auditFormStyles.upload_div}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* <Box display="flex" gap={2}>
          <Button  variant="contained"
                color="primary"
                onClick={handleInHouseClick}>
                In-House Farmer
            </Button>
            <Button variant="contained"
                color="secondary"
                onClick={handleTraderClick}>
                Trader List
            </Button>
            </Box> */}
            <div></div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Export Button */}
                <Button
                    variant='outlined'
                    onClick={handleExport}
                    className="mr-2"
                    sx={{ mr: 2 }} // Adds margin to the right
                > 
                    <GetAppIcon />
                    Export
                </Button>

                {/* File Input and Bulk Upload Button */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                />
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={handleButtonClick}
                    className="mr-2"
                    sx={{ mr: 2 }} // Adds margin to the right
                >
                    <FileUploadIcon />
                    Bulk Upload
                </Button>

                {/* Filter Dropdown */}
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Filter"
                    defaultValue={'ANDHRA PRADESH-I'}
                    size='small'
                    className="w-36"
                    onChange={handleDateFilter}
                    sx={{ ml: 2 }} // Adds margin between the button and dropdown
                >
                    {REGIONS.map((eq) => (
                        <MenuItem
                            key={eq.label}
                            value={eq.label}
                            sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}
                        >
                            {eq.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

          </div>


        

            <Box sx={{
            height: 'auto',
            width: '100%',
            maxWidth: '100vw',
            overflowX: 'auto',
            }}>
            <DataGrid 
            rows={rowAll} 
            columns={columns} 
            columnHeaderHeight={50}  
            getRowHeight={() => 'auto'}
            autoHeight={rowAll.length < 10}
            disableExtendRowFullWidth
            // hideFooterPagination={theme.breakpoints.down('md')}
            slots={{ toolbar: GridToolbar }}

            slotProps={{
                toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true } //Disable the print option in export
                },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            initialState={{
                pagination: {
                paginationModel: { pageSize: 10, page: 0 },
                },
            }} 
            disableRowSelectionOnClick
            disableDensitySelector
            sx={{
                height: '450px',
                '& .MuiDataGrid-cell': {
                textAlign: 'center',
                placeContent: 'center',
                minHeight: 50,
                fontSize: '0.8rem'
                },
                '& .MuiDataGrid-columnHeader':{
                    backgroundColor: '#EEF0F4',
                    fontSize: '0.8rem'
                },
                '& .super-app-theme--header':{
                    // width: isMobile == true ? '200px !important' : '150px !important',
                    // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                },

                '& .fixedColumn--headerstatus': {
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                right: 0,
                zIndex: 1,
                backgroundColor: '#EEF0F4', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
                },
                '& .fixedColumn--cellstatus': {
                // minWidth: 50,
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                right: 0,
                zIndex: 1,
                backgroundColor: '#fff', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
                },
                
                '& .fixedColumn--headeraction': {
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                right: 0,
                zIndex: 1,
                backgroundColor: '#EEF0F4', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
                },
                
                '& .fixedColumn--cellaction': {
                // minWidth: 50,
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                right: 0,
                zIndex: 1,
                backgroundColor: '#fff', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
                },

                '& .fixedColumn--header': {
                // minWidth: 50,
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                left: 0,
                zIndex: 1,
                backgroundColor: '#EEF0F4', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
              },
              '& .fixedColumn--header1': {
                // minWidth: 50,
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                left: 50,
                zIndex: 1,
                backgroundColor: '#EEF0F4', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
              },
              '& .fixedColumn--cell': {
                // minWidth: 50,
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                left: 0,
                zIndex: 1,
                backgroundColor: '#fff', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
              },
              '& .fixedColumn--cell1': {
                // minWidth: 50,
                // maxWidth: 50,
                flex: '0 0 auto',
                position: 'sticky',
                left: 50,
                zIndex: 1,
                backgroundColor: '#fff', // Background color for the fixed column
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
              },
            }} 
            />

            </Box>

    <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="sm"
      >
       <DialogTitle>{"Confirm Upload"}</DialogTitle>
        <DialogContent>
        <DialogContentText>
            {uploading ? "Uploading..." : "Are you sure you want to upload the selected file?"}
          </DialogContentText>
          {uploading && (
            <Box sx={{ width: '100%', mt: 2, height: '30px' }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
        {!uploading && (
            <>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpload} color="primary" autoFocus>
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
    >
        {/* <AlertTitle>{alertTitle}</AlertTitle> */}
        {alertTitle}
    </Alert>
    </Snackbar>


    
        </>
    );

}

export default AuditMasterDataComponent;