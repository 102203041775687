import * as React from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import BdmDashboardComponent from "./BdmDashboard/BdmDashboardComponent";
import { useState, useEffect, useContext } from "react";
import AgentDashboardComponent from "./Agent Dashboard/AgentDashboardComponent";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DialpadIcon from "@mui/icons-material/Dialpad";
import bdmDashboardStyles from "./BdmDashboard/bdmDashboardStyles.module.css";
import IconButton from "@mui/material/IconButton";
import { Stack, Snackbar, Grid, Card, Typography, CardContent, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { LoginContext } from "./LoginPage/LoginContext";
import SupervisorDashboard from "./SupervisorDashboard/SupervisorDashboard";
import CallerComponent from "./Dialer/callerComponent";
import { Divider } from "@mui/material";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import AuditSupervisorDashboardComponent from "./AuditSupervisor/AuditSupervisorDashboard";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function DashboardComponent({ username }) {
  const navigate = useNavigate();
  const op = useRef(null);

  const [agDashboard, setAgDashboard] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);
  const [supDashboard, setSupDashboard] = useState(false);
  const [bdDashboard, setBdDashboard] = useState(false);

  const storedRole = localStorage.getItem("empRole");

  useEffect(() => {
    if (storedRole == 1) {
      setAgDashboard(true);
    } else if (storedRole == 5) {
      setSupDashboard(true);
    } else if (storedRole == 2) {
      setBdDashboard(true);
    }
    // You may want to decrypt the password if it's encrypted
    // setPassword(decrypt(storedPassword));
  }, []);

  useEffect(() => {
    // Check if login was successful (could be based on a context or prop from the previous page)
    if (isLoggedIn) {
      setOpenSnackbar(true);
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [sessionActive, setSessionActive] = useState(true);

  const handleTimeout = () => {
    setSessionActive(false);
    // alert('Your session timed out.')
    navigate("/");
    navigate(0);
    // Redirect or handle session expiration
  };

  const resetTimeout = () => {
    if (sessionActive) {
      clearTimeout(window.sessionTimeout);
      window.sessionTimeout = setTimeout(handleTimeout, 30 * 60 * 1000); // 30 minutes
    }
  };

  useEffect(() => {
    resetTimeout(); // Initialize timeout on mount

    const events = [
      "mousemove",
      "keydown",
      "click", // Desktop event
      "touchstart",
      "touchmove",
      "touchend", // Mobile events
    ];

    events.forEach((event) => window.addEventListener(event, resetTimeout));

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
      clearTimeout(window.sessionTimeout);
    };
  }, [sessionActive]);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const [dateRange, setDateRange] = useState(null);
  const [dateFil, setDateFil] = useState("");

  const [dateFilter, setDateFilter] = useState(getTodayDate());
  const [changedDate, setChangedDate] = useState("");

  const handleDateFilter = (type) => {
    if (type === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setDateFilter(formattedDate);
    } else if (type == "yesterday") {
      // Get today's date
      const today = new Date();

      // Subtract one day from today's date
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Extract year, month, and day
      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(yesterday.getDate()).padStart(2, "0");

      // Format the date as yyyy-mm-dd
      const formattedDate = `${year}-${month}-${day}`;
      setDateFilter(formattedDate);
    } else if (type == "dateRange") {
      const date = new Date(dateRange[0]);
      const date1 = new Date(dateRange[1]);
      // Extract year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
      const day = String(date.getDate()).padStart(2, "0");

      const year1 = date1.getFullYear();
      const month1 = String(date1.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
      const day1 = String(date1.getDate()).padStart(2, "0");

      // Return formatted date
      const startDate = `${year}-${month}-${day}`;
      const endDate = `${year1}-${month1}-${day1}`;

      const date_arr = [];
      date_arr.push(startDate);
      date_arr.push(endDate);
      setDateFilter(date_arr);
    }
  };

  const [dateVar, setDateVar] = useState(null);

  const handleApply = () => {
    console.log(dateVar);
    if (dateVar === "today") {
      handleDateFilter("today");
    }
    if (dateVar === "yesterday") {
      handleDateFilter("yesterday");
    }
    if (dateVar === "dateRange") {
      handleDateFilter("dateRange");
    }

    // console.log('nothing selected')
  };

  const handleClear = () => {
    setDateVar("");
    setDateFil("");
    setDateRange("");
    setDateFilter(getTodayDate());
    setChangedDate("");
    setShowRange(false);
  };

  const handleDateField = (type) => {
    if (type === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      setChangedDate(formattedDate);
    } else if (type == "yesterday") {
      // Get today's date
      const today = new Date();

      // Subtract one day from today's date
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Extract year, month, and day
      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(yesterday.getDate()).padStart(2, "0");

      // Format the date as yyyy-mm-dd
      const formattedDate = `${year}-${month}-${day}`;

      setChangedDate(formattedDate);
    } else if (type == "dateRange") {
      console.log(dateRange);
      const date = new Date(dateRange[0]);
      const date1 = new Date(dateRange[1]);
      // Extract year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
      const day = String(date.getDate()).padStart(2, "0");

      const year1 = date1.getFullYear();
      const month1 = String(date1.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
      const day1 = String(date1.getDate()).padStart(2, "0");

      // Return formatted date
      const startDate = `${year}-${month}-${day}`;
      const endDate = `${year1}-${month1}-${day1}`;

      const date_arr = [];
      date_arr.push(startDate);
      date_arr.push(endDate);

      setChangedDate(`${startDate} - ${endDate}`);
    }
  };

  const [showRange, setShowRange] = useState(false);

  return (
    <>
      <Grid container spacing={2}>
        {(agDashboard || bdDashboard || supDashboard) && (
          <Grid item xs={12} md={12} xl={12}>
            <div className={`float-right`} style={{ marginRight: "15px" }}>
              <div className="card flex flex-column align-items-center gap-3">
                <Button
                  type="button"
                  icon="pi pi-filter"
                  label=""
                  onClick={(e) => op.current.toggle(e)}
                />
                <OverlayPanel ref={op} showCloseIcon dismissable>
                  <div className="mb-2">
                    <TextField
                      id="outlined-size-small"
                      size="small"
                      value={changedDate}
                      fullWidth
                    ></TextField>
                  </div>
                  <div className="">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Filter"
                      // defaultValue={'today'}
                      value={dateFil}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (e.target.value == "custom") {
                          setShowRange(true);
                          setDateFil(e.target.value);
                        } else {
                          setDateFil(e.target.value);
                          setDateVar(e.target.value);
                          handleDateField(e.target.value);
                        }
                      }}
                      variant="outlined"
                    >
                      <MenuItem
                        value={"today"}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        Today
                      </MenuItem>
                      <MenuItem
                        value={"yesterday"}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        Yesterday
                      </MenuItem>
                      <MenuItem value={"custom"}>Custom</MenuItem>
                    </TextField>
                    <Divider sx={{ margin: "16px 0" }} />
                    {showRange ? (
                      <div className="p-fluid">
                        <label htmlFor="daterange" className="p-d-block">
                          Select Custom Date Range
                        </label>
                        <div className="flex justify-between">
                          <Calendar
                            id="daterange"
                            value={dateRange}
                            onChange={(e) => {
                              setDateVar("dateRange");
                              setDateRange(e.value);
                            }}
                            selectionMode="range"
                            readOnlyInput
                            hideOnRangeSelection
                            className="inputText p-d-block"
                          />
                          <IconButton
                            sx={{
                              borderColor: "grey",
                              borderRadius: "5px",
                              border: "1px solid",
                            }}
                            onClick={(e) => {
                              handleDateField("dateRange");
                            }}
                          >
                            <DoneIcon></DoneIcon>
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="buttonBar mt-3">
                      <Button
                        type="button"
                        severity="success"
                        outlined
                        className="buttonStyle p-mr-2"
                        onClick={handleApply}
                      >
                        Apply
                      </Button>
                      <Button
                        type="button"
                        outlined
                        className="buttonStyle p-button-secondary"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </OverlayPanel>
              </div>
            </div>
          </Grid>
        )}

        {localStorage.getItem("empRole") == 3 && (<Grid item xs={12} md={12} xl={12}>
          <Card
            sx={{
              maxWidth: 500,
              margin: "auto",
              textAlign: "center",
              padding: 4,
              borderRadius: 4,
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              backgroundColor: "#f9f9f9",
              marginTop: 3
            }}
          >
            <Box sx={{ mb: 2 }}>
              <AccessTimeIcon
                sx={{
                  fontSize: 64,
                  color: "#ff9800",
                  animation: "pulse 2s infinite",
                }}
              />
            </Box>
            <CardContent>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                Coming Soon
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Exciting new features are on the way! Stay tuned for updates.
              </Typography>
            </CardContent>
          </Card>
        </Grid>)}

        <Grid item xs={12} md={12} xl={12}>
          {agDashboard && <AgentDashboardComponent />}
          {bdDashboard && <BdmDashboardComponent />}
          {supDashboard && <SupervisorDashboard dateFilter={dateFilter} />}
        </Grid>
      </Grid>

      <>
        {localStorage.getItem("empRole") == 100 && <AgentDashboardComponent />}
        {localStorage.getItem("empRole") == 101 && (
          <AuditSupervisorDashboardComponent></AuditSupervisorDashboardComponent>
        )}
      </>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000} // Snackbar will auto hide after 3 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Login Successful !!
        </Alert>
      </Snackbar>
    </>
  );
}

export default DashboardComponent;
