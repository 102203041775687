import React, { useState , useEffect, useRef} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CallIcon from '@mui/icons-material/Call';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaceIcon from '@mui/icons-material/Place';
import CalculateIcon from '@mui/icons-material/Calculate';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import { GET_ESTIMATIONS_SV, GET_MEETING_SV, GET_ON_CALL_DISCUSSION_SV, GET_SITE_VISIT_SV } from '../../constants';
import OnCallTable from './OnCallTable';
import LockIcon from '@mui/icons-material/Lock';
import MeetingTable from './MeetingTable';
import SiteVisitTable from './SiteVisitTable';
import EstimationGeneratedTable from './EstimationGeneratedTable';
import EstimationRequestTable from './EstimationRequestTable';
import { useLocation } from 'react-router-dom';
import EstimationSharedTable from './EstimationSharedTable';
import EstimationConvertedTable from './EstimationConvertedTable';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

function ActionTabComponent() {

    const location = useLocation();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (location.state && location.state.tab !== undefined) {
            setValue(location.state.tab);
        }
        // if(location.state && location.state.dateFilter !== undefined){
        //   setFilter(location.state.dateFilter);
        // }
    }, [location.state]);

    const [onCallData, setOnCallData] = useState([]);
    const [meetingData, setMeetingData] = useState([]);
    const [siteVisitData, setSiteVisitData] = useState([]);
    const [estimatedData, setEstimatedData] = useState([]);

    const [onCallCount, setOnCallCount] = useState(0);
    const [meetingCount, setMeetingCount] = useState(0);
    const [siteVisitCount, setSiteVisitCount] = useState(0);
    const [estimateCount , setEstimateCount] = useState(0);
    const [estConvertedCount, setEstConvertedCount] = useState(0);
    const [estSharedCount, setEstSharedCount] = useState(0);
    const [estGenerateCount, setEstGenerateCount] = useState(0);

    useEffect(()=>{
        axios.get(`${GET_ON_CALL_DISCUSSION_SV}`,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }})
            .then(response => {
                const { leads, callOnDiscussionCount } =  response.data;
                setOnCallData(leads);
                setOnCallCount(callOnDiscussionCount);

            })
            .catch(error => {
            });

            axios.get(`${GET_MEETING_SV}`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }})
            .then(response => {
                const { total_meetings, leads } = response.data;
                setMeetingData(leads);
                setMeetingCount(total_meetings);
            })
            .catch(error => {
            });

            axios.get(`${GET_SITE_VISIT_SV}`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }})
            .then(response => {
                const { total_site_visits, leads } = response.data;
                setSiteVisitData(leads);
                setSiteVisitCount(total_site_visits);
            })
            .catch(error => {
            });
                
            axios.get(`${GET_ESTIMATIONS_SV}`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }})
            .then(response => {
                const { total_estimations, leads } = response.data;
                setEstimatedData(leads);
                

                // Count the number of estimations with the status 'converted'
                let convertedEstimationsCount = 0;
                let shardEstimationsCount = 0;
                let generatedEstimationCount = 0;
                let requestEstimationCount = 0;

                // Iterate over each lead and their estimations
                leads.forEach(lead => {
                // Filter and count the estimations that have status 'converted'
                const convertedCount = lead.estimations?.filter(est => est.status === 'converted').length || 0;
                convertedEstimationsCount += convertedCount;

                const sharedCount = lead.estimations?.filter(est => est.status === 'Estimation Shared').length || 0;
                shardEstimationsCount += sharedCount;

                const generateCount = lead.estimations?.filter(est => est.status === 'Generated').length || 0;
                generatedEstimationCount += generateCount;

                const requestCount = lead.estimations?.filter(est =>est.status === 'Pending' || est.status === 'Need for Approval').length || 0;
                requestEstimationCount += requestCount;
                });

                // Set the count of converted estimations
                setEstConvertedCount(convertedEstimationsCount);
                setEstSharedCount(shardEstimationsCount);
                setEstGenerateCount(generatedEstimationCount);
                setEstimateCount(requestEstimationCount);
            })
            .catch(error => {
            });
    },[]);

    

    return(
        <>

            <Tabs
            value={value}
            onChange={handleChange}
            variant={"scrollable"} // Scrollable for small screens
            scrollButtons={"auto"}   // Enable scroll buttons automatically
            aria-label="responsive tabs example"
            >
                <Tab label={`On-Call (${onCallCount})`} id="tab-0" icon={<CallIcon />} iconPosition="start" aria-controls="tabpanel-1" />
                <Tab label={`Meeting (${meetingCount})`} id="tab-1" icon={<GroupsIcon />} iconPosition="start" aria-controls="tabpanel-1" />
                <Tab label={`Site-Visit (${siteVisitCount})`} id="tab-2" icon={<PlaceIcon />} iconPosition="start" aria-controls="tabpanel-2" />
                <Tab label={`Estimation Requested (${estimateCount})`} id="tab-3" icon={<CalculateIcon />} iconPosition="start" aria-controls="tabpanel-3" />
                <Tab label={`Estimation Generated (${estGenerateCount})`} id="tab-4" icon={<CalculateIcon />} iconPosition="start" aria-controls="tabpanel-4" />
                <Tab label={`Estimation Shared (${estSharedCount})`} id="tab-5" icon={<CalculateIcon />} iconPosition="start" aria-controls="tabpanel-4" />
                <Tab label={`Converted (${estConvertedCount})`} id="tab-6" icon={<DoneIcon />} iconPosition="start" aria-controls="tabpanel-5" />
                

            </Tabs>
            <TabPanel value={value} index={0}>
                <OnCallTable onCallData={onCallData}></OnCallTable>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <MeetingTable meetingData={meetingData}></MeetingTable>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <SiteVisitTable siteVisitData={siteVisitData}></SiteVisitTable>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <EstimationRequestTable estimatedData={estimatedData} ></EstimationRequestTable>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <EstimationGeneratedTable estimatedData={estimatedData} ></EstimationGeneratedTable>
            </TabPanel>

            <TabPanel value={value} index={5}>
                <EstimationSharedTable estimatedData={estimatedData}></EstimationSharedTable>
            </TabPanel>

            <TabPanel value={value} index={6}>
                <EstimationConvertedTable estimatedData={estimatedData}></EstimationConvertedTable>
            </TabPanel>
       
        </>
    );
}
export default ActionTabComponent;