import React, { useContext, useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import LongTextComponent from "../Leads Page/LongTextComponent";
import { GridToolbar, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { useMediaQuery, useTheme, Chip, IconButton, Tooltip, Button, Menu, MenuItem, Box} from '@mui/material';
import axios from "axios";
import { GET_ESTIMATIONS_HO } from "../../constants";
import Loader from "../reusable Component/Loader";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { LoginContext } from "../LoginPage/LoginContext";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { GridToolbarContainer } from '@mui/x-data-grid';

const CustomToolbar = ({ data, columns }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Custom export to Excel function
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      columns.map(column => column.headerName),
      ...data.map(row => columns.map(column => row[column.field])),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Crm_Leads.xlsx');
    handleMenuClose(); // Close the menu after exporting
  };

  const exportToCSV = () => {
    const csvData = [
      columns.map(column => column.headerName).join(','), // CSV Header
      ...data.map(row => columns.map(column => row[column.field]).join(','))
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Crm_Lead.csv');
    handleMenuClose(); // Close the menu after exporting
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },

        }}
      /> */}
      <Tooltip title="Export Options">
        <Button
          variant="text"
          onClick={handleMenuClick}
        >
         <SaveAltIcon sx={{fontSize: '1.3rem', paddingRight: '2px'}}></SaveAltIcon> Export
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
        <MenuItem onClick={exportToCSV}>Export to CSV</MenuItem>
        {/* Add more menu items for additional export options if needed */}
      </Menu>
      <Box sx={{flexGrow: 1}}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

function RejectedListComponent({finalRejectedEstimationRow}) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const { setleadDetails } = useContext(LoginContext);

    const IconCell = ({ row }) => (
      <div className={`flex m-2`}>
      <Tooltip title="Edit" arrow>
      <IconButton
        style={{
          border: '1px solid',
          borderRadius: '4px',
          padding: '2px',
          width: '24px',
          height: '24px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        color='primary'
        onClick={() => handleIconClick(row)}
      >
        <EditIcon fontSize="small" color='primary'/>
      </IconButton>
      </Tooltip>
      <Tooltip title="Reject" arrow>
      <IconButton
      style={{
        border: '1px solid',
        borderRadius: '4px',
        padding: '2px',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '4px'
      }}
      color='error'
      // onClick={() => handleIconClick(row)}
      >
      <DangerousIcon fontSize="small" color='error'/>
      </IconButton>
      </Tooltip>
      </div>
    );
  
    const handleIconClick = (row) =>{
      setleadDetails(row);
      navigate('/estimateDetails')          
  };
    
    const columnsEstimation = [
        { field: 'sno', headerName: 'SNo', width: isMobile ? 30 : 50 , type: 'string',  headerAlign: 'center', responsive: true, disableColumnFilter: true,},
        { field: 'EstimationRequirement', headerName: 'Estimation Requirement', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center', responsive: true,
            renderCell: (params) => (
                <>{params.value === '-' ? (<p>-</p> ) : (<Typography key={`index_${params.value}`} size='small' label={params.value} sx={{textTransform: 'capitalize', height: '20px', fontWeight:'700', fontSize: '0.8rem'}}>{params.value}</Typography>)}</>
            ),
        },
        { field: 'status', headerName: 'Status', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true,
          renderCell: (params) => (
              <>{params.value === '-' ? (<p>-</p> ) : (<Chip key={`index_${params.value}`} size='small' color={`${getColorForTag(params.value)}`} label={params.value} sx={{textTransform: 'capitalize', height: '20px', margin: '10px'}}></Chip>)}</>
          ),
      },
      { field: 'last_estimation_number', headerName: 'Last Estimation Number', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'estimationNumber', headerName: 'Estimation Number', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'estimation_amount', headerName: 'Estimation Value', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'firm_farmer_name', headerName: 'Firm/Farmer Name', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'Estimation_generated_date', headerName: 'Estimation Generated Date & Time', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'Estimation_request_date', headerName: 'Estimation Request Date & Time', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'BdmName', headerName: 'Requested By', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'farmerName', headerName: 'Farmer Name', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'MobNo', headerName: 'Mobile Number', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
      { field: 'Project', headerName: 'Project', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        
        // { field: 'follow_up_date', headerName: 'Follow-up Date', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        // { field: 'closure_month', headerName: 'Closure Month', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        // { field: 'category', headerName: 'Category', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true,
        //     renderCell: (params) => (
        //         <>{params.value === '-' ? (<p>-</p> ) : (<Chip key={`index_${params.value}`} size='small' color={`${getColorForTag(params.value)}`} label={params.value} sx={{textTransform: 'capitalize', height: '20px'}}></Chip>)}</>
        //     ),
        // },
        // { field: 'sub_category', headerName: 'Sub Category', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        
        { field: 'CivilConstructedStarted', headerName: 'Civil Construction Started', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'ShedLength', headerName: 'Shed Length', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'EquipementPlacementLength', headerName: 'Equipment Placement Length', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'ShedWidth', headerName: 'Shed Width', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'CShape', headerName: 'C-Shape', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'ModalType', headerName: 'Model Type', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'SideWallColumnToColumnGap', headerName: 'Side Wall Column to Column Gap', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'NumberOfSheds', headerName: 'Number of Sheds', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'CurtainRequirment', headerName: 'Curtain Requirement', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'DiselBrooderRequirment', headerName: 'Diesel Brooder Requirement', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'PowerSupply', headerName: 'Power Supply', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'WaterSupply', headerName: 'Water Available', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true},
        { field: 'Remarks', headerName: 'Remarks', width: isMobile ? 180 : 150, type: 'string',  headerAlign: 'center',   responsive: true,
            renderCell: (params) => (
                <LongTextComponent text={params.value == null ? '' : params.value} maxLength={50} />
              ),
        },
        // { field: 'action', headerName: 'Action', width: 80, headerAlign: 'center',headerClassName: isMobile ? '' : 'fixedColumn--headeraction', cellClassName: isMobile ? '' : 'fixedColumn--cellaction', responsive: true, sortable: false,
        //     renderCell: (params) => <IconCell {...params}/>
        //   } 
    ];

    const getColorForTag = (tag) => {
        switch (tag.toLowerCase()) {
          case 'rejected':
            return 'error';
          case 'revised':
            return '#ff007f';
          default:
            return 'default';
        }
      };

    return(
    <>
                <DataGrid 
                rows={finalRejectedEstimationRow} 
                columns={columnsEstimation} 
                columnHeaderHeight={50}  
                getRowHeight={() => 'auto'}
                autoHeight
                disableExtendRowFullWidth
                // hideFooterPagination={theme.breakpoints.down('md')}
                slots={{
                  toolbar: (props) => <CustomToolbar data={finalRejectedEstimationRow} columns={columnsEstimation} {...props} />
                }}
                slotProps={{
                    toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true }
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                    pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                    },
                }} 
                disableRowSelectionOnClick
                disableDensitySelector
                sx={{
                    '& .MuiDataGrid-cell': {
                    textAlign: 'center',
                    placeContent: 'center',
                    fontSize: '0.8rem'
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#EEF0F4',
                        fontSize: '0.8rem'
                    },
                    '& .super-app-theme--header':{
                        // width: isMobile == true ? '200px !important' : '150px !important',
                        // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                    },
                    '& .fixedColumn--headeraction': {
                        // maxWidth: 50,
                        flex: '0 0 auto',
                        position: 'sticky',
                        right: 0,
                        zIndex: 1,
                        backgroundColor: '#EEF0F4', // Background color for the fixed column
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
                      },
                    '& .fixedColumn--cellaction': {
                      // minWidth: 50,
                      // maxWidth: 50,
                      flex: '0 0 auto',
                      position: 'sticky',
                      right: 0,
                      zIndex: 1,
                      backgroundColor: '#fff', // Background color for the fixed column
                      boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional: Add shadow for separation
                    }
                }} 
                />
    </>
);
}

export default RejectedListComponent;