import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { Divider, Stack, Grid, Card, styled } from "@mui/material";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import bdmFormStyles from "./bdmFormStyles.module.css";
import { CREATE_MEETING, MODEL_TYPE } from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import DispositionModalComponent from "../reusable Component/DispositionModalComponent";
import { useMediaQuery, useTheme, Chip } from "@mui/material";

const ResizableTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    "& textarea": {
      resize: "both", // Allow resizing both horizontally and vertically
      overflow: "auto", // Show scrollbars when necessary
      minHeight: "100px", // Set a minimum height for the textarea
    },
  },
});

function MeetingFormComponent({
  rowDetails,
  setShowMeetingForm,
  leadDetailsId,
  setSeverity,
  setMessage,
  setOpenSnackbar,
  setLoading,
  setRunEffect,
}) {
  const [birdCapacity, setBirdCapacity] = useState("");
  const [landDimension, setLandDimension] = useState("");
  const [shedSize, setShedSize] = useState("");
  const [modelType, setModelType] = useState("");
  const [landDirection, setLandDirection] = useState("");
  const [directionDeviation, setDirectionDeviation] = useState(null);
  const [electricityPower, setElectricityPower] = useState("");
  const [water, setWater] = useState("");
  const [approachRoad, setApproachRoad] = useState("");
  const [estimationReq, setEstimationReq] = useState("");
  const [images, setImages] = useState([]);

  const [dDShow, setDDShow] = useState(false);

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [bdmSelfie, setBdmSelfie] = useState(null);
  const [bdmSelfieShow, setBdmSelfieShow] = useState(null);
  const [customerSelfie, setCustomerSelfie] = useState(null);
  const [customerSelfieShow, setCustomerSelfieShow] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [otherImagesShow, setOtherImagesShow] = useState([]);
  const [imagesError, setImagesError] = useState("");
  const [imagesCustomerError, setImagesCustomerError] = useState("");

  const handleBDMSelfieChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const imageUrl = URL.createObjectURL(file);
      setBdmSelfie(file);
      setBdmSelfieShow(imageUrl);
      setImagesError("");
    } else {
      setImagesError("Only PNG and JPEG files are allowed.");
    }
  };

  const handleCustomerSelfieImageChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const imageUrl = URL.createObjectURL(file);
      setCustomerSelfie(file);
      setCustomerSelfieShow(imageUrl);
      setImagesCustomerError("");
    } else {
      setImagesCustomerError("Only PNG and JPEG files are allowed.");
    }
  };

  const handleMultipleUpload = (event) => {
    const files = Array.from(event.target.files);

    const validImages = files.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    if (validImages.length !== files.length) {
      setImagesError("Only PNG and JPEG files are allowed.");
      return;
    }

    const newImagesShow = validImages.map((file) => URL.createObjectURL(file));
    setOtherImagesShow([...otherImagesShow, ...newImagesShow]);
    const newImages = validImages;
    setOtherImages([...otherImages, ...newImages]);
    setImagesError("");
  };

  const handleBDMSelfieRemoveImage = () => {
    setBdmSelfie(null);
    setBdmSelfieShow(null);
  };

  const handleCustomerSelfieRemoveImage = () => {
    setCustomerSelfie(null);
    setCustomerSelfieShow(null);
  };

  const handleRemoveMultipleImage = (index) => {
    const updatedImages = otherImages.filter((_, i) => i !== index);
    setOtherImages(updatedImages);

    const updatedImages1 = otherImagesShow.filter((_, i) => i !== index);
    setOtherImagesShow(updatedImages1);
  };

  const [remark, setRemark] = useState("");
  const [remarkError, setRemarkError] = useState("");

  const handleChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  const validateRemark = (value) => {
    if (value == "") {
      setRemarkError("Feild is required.");
      return false;
    } else {
      setRemarkError("");
      return true;
    }
  };

  const [landDirectionError, setLandDirectionError] = useState("");

  const validateLandDirection = (value) => {
    if (!value) {
      setLandDirectionError("Land Direction is required");
      return false;
    } else {
      setLandDirectionError("");
      return true;
    }
  };

  const handleChangeLandDirection = (e) => {
    const value = e.target.value;
    setLandDirection(value);
    validateLandDirection(value);
    if (value == "true") {
      setDDShow(false);
    } else {
      setDDShow(true);
    }
  };

  const [electricityPowerError, setElectricityPowerError] = useState("");
  const validateElectricityPower = (value) => {
    if (value == "") {
      setElectricityPowerError("Electricity Power is required");
      return false;
    } else {
      setElectricityPowerError("");
      return true;
    }
  };

  const handleChangeElectricityPower = (e) => {
    const value = e.target.value;
    setElectricityPower(value);
    validateElectricityPower(value);
  };

  const [waterError, setWaterError] = useState("");
  const validateWater = (value) => {
    if (!value) {
      setWaterError("Water is required.");
      return false;
    } else {
      setWaterError("");
      return true;
    }
  };

  const handleChangeWater = (e) => {
    const value = e.target.value;
    setWater(value);
    validateWater(value);
  };

  const [approachRoadError, setapproachRoadError] = useState("");
  const validateApproachRoad = (value) => {
    if (!value) {
      setapproachRoadError("Approachable Road is required.");
      return false;
    } else {
      setapproachRoadError("");
      return true;
    }
  };

  const handleChangeApproachRoad = (e) => {
    const value = e.target.value;
    setApproachRoad(value);
    validateApproachRoad(value);
  };

  const handleShedSizeChange = (e) => {
    let value = e.target.value.replace(/[^0-9X]/g, ""); // Allow only numbers and 'X'

    // Automatically insert 'X' after 3 characters if it's not there already
    if (value.length > 3 && !value.includes("X")) {
      value = value.slice(0, 3) + "X" + value.slice(3);
    }

    setShedSize(value);
  };

  const [EstimationReqError, setEstimationReqError] = useState("");
  const validateEstimationReq = (value) => {
    if (!value) {
      setEstimationReqError("Estimation Requirement is required.");
      return false;
    } else {
      setEstimationReqError("");
      return true;
    }
  };

  const handleChangeEstimationReq = (e) => {
    const value = e.target.value;
    setEstimationReq(value);
    validateEstimationReq(value);
  };

  const [ModelTypeError, setModelTypeError] = useState("");
  const validateModelType = (value) => {
    if (value == "") {
      setModelTypeError("Model Type is required.");
      return false;
    } else {
      setModelTypeError("");
      return true;
    }
  };

  const handleModelTypeChange = (e) => {
    const value = e.target.value;
    setModelType(value);
    validateModelType(value);
  };

  const [birdCapacityError, setBirdCapacityError] = useState("");

  const handleBirdCapacityChange = (e) => {
    let value = e.target.value;

    if (/^\d*$/.test(value)) {
      setBirdCapacity(e.target.value);
    }
  };

  const validateBirdCapacity = (value) => {
    if (value == "") {
      setBirdCapacityError("Feild is required.");
      return false;
    } else {
      setBirdCapacityError("");
      return true;
    }
  };

  const [landDimensionError, setLandDimensionError] = useState("");

  const validatelandDimension = (value) => {
    if (value == "") {
      setLandDimensionError("Feild is required.");
      return false;
    } else {
      setLandDimensionError("");
      return true;
    }
  };

  const [ShedSizeError, setShedSizeError] = useState("");

  const validateShedSize = (value) => {
    if (value == "") {
      setShedSizeError("Feild is required.");
      return false;
    } else {
      setShedSizeError("");
      return true;
    }
  };

  const [bdmSelfieError, setBdmSelfieError] = useState("");
  const [customerSelfieError, setCustomerSelfieError] = useState("");

  const validateBdmSelfie = (value) => {
    if (!value) {
      setBdmSelfieError("Image is required.");
      return false;
    } else {
      setBdmSelfieError("");
      return true;
    }
  };

  const validateCustomerSelfie = (value) => {
    if (!value) {
      setCustomerSelfieError("Image is required.");
      return false;
    } else {
      setCustomerSelfieError("");
      return true;
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowMeetingForm(false);
    // if(viewMode){
    // handleViewMode(false);}
  };

  const [meetingFormData, setMeetingFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform all validations
    const isbirdCapacityValid = validateBirdCapacity(birdCapacity);
    const isLandDimensionValid = validatelandDimension(landDimension);
    const isShedSizeValid = validateShedSize(shedSize);
    const islandDirectionValid = validateLandDirection(landDirection);
    const isElectricityPowerValid = validateElectricityPower(electricityPower);
    const isWaterValid = validateWater(water);
    const isApproachRoadValid = validateApproachRoad(approachRoad);
    const isEstimationReqValid = validateEstimationReq(estimationReq);
    const isModelTypeValid = validateModelType(modelType);
    const isBdmSelfieValid = validateBdmSelfie(bdmSelfie);
    const isCustomerSelfieValid = validateCustomerSelfie(customerSelfie);
    const isRemarkValid = validateRemark(remark);

    // Check if any of the validation flags are false (meaning the form is invalid)
    if (
      !isbirdCapacityValid ||
      !isLandDimensionValid ||
      !isShedSizeValid ||
      !islandDirectionValid ||
      !isElectricityPowerValid ||
      !isWaterValid ||
      !isApproachRoadValid ||
      !isEstimationReqValid ||
      !isModelTypeValid ||
      !isBdmSelfieValid ||
      !isCustomerSelfieValid ||
      !isRemarkValid
    ) {
      console.log("Form not submitted, validation failed");
      return; // Stop form submission if any validation fails
    }

    // Proceed with form submission if all validations pass
    const formData = new FormData();
    formData.append("LeadDetailId", leadDetailsId);
    formData.append("BDMId", localStorage.getItem("username"));
    formData.append("BirdsCapacity", birdCapacity);
    formData.append("LandDimension", landDimension);
    formData.append("ShedSize", shedSize);
    formData.append("ModelType", modelType);
    formData.append("IsLandDirectionEastWest", landDirection === "true");
    formData.append("DirectionDeviationDegree", directionDeviation);
    formData.append("ElectricityPower", electricityPower === "true");
    formData.append("Water", water === "true");
    formData.append("ApproachRoad", approachRoad === "true");
    formData.append("EstimationRequirement", estimationReq === "true");
    formData.append("ActionType", "meeting");
    formData.append("remark", remark);

    // Append images to the formData (ensure multiple uploads are handled correctly)
    formData.append("images", bdmSelfie);
    formData.append("images", customerSelfie);
    otherImages.forEach((image) => {
      formData.append("images", image);
    });

    console.log("formData", formData);
    setMeetingFormData(formData);
    setOpen(true);
  };

  const resetForm = () => {
    setBirdCapacity("");
    setLandDimension("");
    setShedSize("");
    setModelType("");
    setLandDirection(null);
    setDirectionDeviation("");
    setElectricityPower(null);
    setWater(null);
    setApproachRoad(null);
    setEstimationReq(null);
    setImages([]);
    setRemark("");
    setBdmSelfie(null);
    setCustomerSelfie(null);
    setOtherImages([]);
  };

  return (
    <>
      <Card className={`${bdmFormStyles.form_card_style} mt-2`}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3}>
            <Grid item xs={2} md={4} xl={6}>
              <TextField
                label="Bird Capacity"
                id="outlined-size-small"
                value={birdCapacity}
                onChange={handleBirdCapacityChange}
                error={!!birdCapacityError}
                helperText={birdCapacityError}
                required
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Land Dimension(Sqft)"
                id="outlined-size-small"
                value={landDimension}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setLandDimension(e.target.value);
                  }
                }}
                error={!!landDimensionError}
                helperText={landDimensionError}
                required
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Grid>
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Shed Size (L X W)"
                id="outlined-size-small"
                value={shedSize}
                onChange={handleShedSizeChange}
                size="small"
                error={!!ShedSizeError}
                helperText={ShedSizeError}
                required
              />
            </Grid>

            <Grid item xs={12} md={4} xl={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Model Type"
                value={modelType}
                onChange={handleModelTypeChange}
                error={!!ModelTypeError}
                helperText={ModelTypeError}
                required
                // helperText="Please select your currency"
                size="small"
              >
                {MODEL_TYPE.map((mt) => (
                  <MenuItem
                    value={mt.label}
                    sx={{
                      borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {mt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Is land direction East West?{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Is land direction East West?"
                  name="gender"
                  value={landDirection}
                  onChange={handleChangeLandDirection}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {landDirectionError && (
                  <FormHelperText error>{landDirectionError}</FormHelperText>
                )}
              </div>
            </Grid>

            {dDShow && (
              <Grid item xs={12} md={4} xl={6}>
                <TextField
                  label="Direction Deviation Degree"
                  id="outlined-size-small"
                  value={directionDeviation}
                  onChange={(e) => setDirectionDeviation(e.target.value)}
                  size="small"
                />
              </Grid>
            )}

            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Electricity Power (Minimum 30kVa){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Electricity Power (Minimum 30kVa)"
                  name="epower"
                  value={electricityPower}
                  onChange={handleChangeElectricityPower}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {electricityPowerError && (
                  <FormHelperText error>{electricityPowerError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Water(Minimum 10000 lit./day){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Water(Minimum 10000 lit./day)"
                  name="water"
                  value={water}
                  onChange={handleChangeWater}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {waterError && (
                  <FormHelperText error>{waterError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Approachable Road <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Approach Road"
                  name="approch_road"
                  value={approachRoad}
                  onChange={handleChangeApproachRoad}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {approachRoadError && (
                  <FormHelperText error>{approachRoadError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4} xl={6}>
              <div className={`px-2`}>
                <Typography variant="body2">
                  Estimation Requirement <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Estimation Requirement"
                  name="ereq"
                  value={estimationReq}
                  onChange={handleChangeEstimationReq}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {EstimationReqError && (
                  <FormHelperText error>{EstimationReqError}</FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={12} xl={6}>
              <ResizableTextField
                label={"Remarks"}
                value={remark}
                onChange={(e) => {
                  if (e.target.value.length <= 250) {
                    handleChangeRemark(e);
                  }
                }}
                multiline
                fullWidth
                variant="outlined" // Use 'outlined' for better border control
                style={{ width: "100%" }}
                required
                error={!!remarkError}
                helperText={remarkError}
              />
              <Typography
                variant="body2"
                color={remark.length > 250 ? "error" : "text.secondary"}
                sx={{ marginLeft: "7px" }}
              >
                {remark.length}/250
              </Typography>
            </Grid>

            <Grid container spacing={2} p={2}>
              {/* Label for the entire upload section */}
              <Grid item xs={12}>
                <Typography variant="body2">
                  Upload Images <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>

              {/* BDM Selfie */}
              <Grid item xs={12} md={4} xl={6}>
                <div className="px-2">
                  {/* <Typography variant="body2">BDM Selfie</Typography> */}
                  {!bdmSelfie ? (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{
                          width: "60%",
                          marginTop: "8px",
                          textTransform: "none",
                          border: "2px dashed black",
                          backgroundColor: "lightgray",
                          "&:hover": {
                            backgroundColor: "gray",
                          },
                        }}
                      >
                        BDM Selfie
                        <input
                          accept=".png, .jpeg, .jpg"
                          type="file"
                          onChange={(e) => handleBDMSelfieChange(e)}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <p style={{ color: "gray", fontSize: "0.875rem" }}>
                        Only PNG and JPEG files are allowed.
                      </p>
                      {imagesError && (
                        <FormHelperText error>{imagesError}</FormHelperText>
                      )}
                      {bdmSelfieError && (
                        <FormHelperText error>{bdmSelfieError}</FormHelperText>
                      )}
                    </>
                  ) : (
                    <div>
                      <img
                        src={bdmSelfieShow}
                        alt="Uploaded BDM Selfie"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                      />
                      <IconButton
                        onClick={() => handleBDMSelfieRemoveImage()}
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>

              {/* Customer Selfie */}
              <Grid item xs={12} md={4} xl={6}>
                <div className="px-2">
                  {/* <Typography variant="body2">Customer Selfie</Typography> */}
                  {!customerSelfie ? (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{
                          width: "60%",
                          marginTop: "8px",
                          textTransform: "none",
                          border: "2px dashed black",
                          backgroundColor: "lightgray",
                          "&:hover": {
                            backgroundColor: "gray",
                          },
                        }}
                      >
                        BDM with Customer Selfie
                        <input
                          accept=".png, .jpeg, .jpg"
                          type="file"
                          onChange={(e) => handleCustomerSelfieImageChange(e)}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <p style={{ color: "gray", fontSize: "0.875rem" }}>
                        Only PNG and JPEG files are allowed.
                      </p>
                      {imagesCustomerError && (
                        <FormHelperText error>
                          {imagesCustomerError}
                        </FormHelperText>
                      )}
                      {customerSelfieError && (
                        <FormHelperText error>
                          {customerSelfieError}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    <div>
                      <img
                        src={customerSelfieShow}
                        alt="Uploaded Customer Selfie"
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                      />
                      <IconButton
                        onClick={() => handleCustomerSelfieRemoveImage()}
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>

              {/* Other Images */}
              <Grid item xs={12} md={4} xl={6}>
                <div className="px-2">
                  {/* <Typography variant="body2">Other Images (Max 3)</Typography> */}
                  {otherImages.length > 0 ? (
                    <Grid container spacing={1}>
                      {otherImagesShow.map((image, index) => (
                        <Grid item key={index}>
                          <img
                            src={image}
                            alt={`Other ${index}`}
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                          <IconButton
                            onClick={() => handleRemoveMultipleImage(index)}
                            aria-label="delete"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{
                          width: "60%",
                          marginTop: "8px",
                          textTransform: "none",
                          border: "2px dashed black",
                          backgroundColor: "lightgray",
                          "&:hover": {
                            backgroundColor: "gray",
                          },
                        }}
                      >
                        Other Images
                        <input
                          accept=".png, .jpeg, .jpg"
                          type="file"
                          multiple
                          onChange={handleMultipleUpload}
                          style={{ display: "none" }}
                        />
                      </Button>
                      <p style={{ color: "gray", fontSize: "0.875rem" }}>
                        Only PNG and JPEG files are allowed.
                      </p>
                      {imagesError && (
                        <FormHelperText error>{imagesError}</FormHelperText>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className={`float-right p-2`}>
            {!isMobile && (
              <Button
                onClick={handleClose}
                variant="contained"
                className={`${bdmFormStyles.button_space_style}`}
              >
                Cancel
              </Button>
            )}
            {/* {viewMode == true ? (<></>) : ( */}
            <Button type="submit" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Box>

        {/* )} */}
      </Card>

      <DispositionModalComponent
        open={open}
        setOpen={setOpen}
        meetingFormData={meetingFormData}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setOpenSnackbar={setOpenSnackbar}
        setShowMeetingForm={setShowMeetingForm}
        formType={"meeting"}
        setRunEffect={setRunEffect}
        setLoading={setLoading}
        resetForm={resetForm}
      />
    </>
  );
}

export default MeetingFormComponent;
