import React, { useEffect, useState } from "react";
import {
  IconButton,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Grid,
  Autocomplete,
  Typography,
  Drawer,
  Toolbar,
  Paper,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {
  EXCEL_EXPORT_ALL_DATA_SUPERVISOR,
  GET_CAMPAIGN_NAME,
  GET_CATEGORY,
  GET_INQUIRY_TYPE,
  GET_PROJECT,
  GET_REGION_LIST,
} from "../../constants";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import for the filter icon
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const FilterPanel = ({ filters, setFilters, onApplyFilters }) => {
  const [inquiryTypes, setInquiryTypes] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer visibility

  // Load filters from localStorage on mount
  useEffect(() => {
    const savedFilters = localStorage.getItem("filters");
    if (savedFilters) {
      onApplyFilters(JSON.parse(localStorage.getItem("filters")));
      setFilters(JSON.parse(savedFilters));
    }
  }, [setFilters]);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const fetchInquiryTypes = async () => {
    if (inquiryTypes.length === 0) {
      // Only fetch if options are not already loaded
      setLoading(true);
      try {
        const response = await axios.get(GET_INQUIRY_TYPE);
        setInquiryTypes(response.data);
      } catch (error) {
        console.error("Error fetching inquiry types:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      InquiryType: newValue ? newValue.InquiryType : "",
    }));
  };

  const fetchRegionList = async () => {
    if (regionList.length === 0) {
      // Only fetch if options are not already loaded
      setRegionLoading(true);
      try {
        const response = await axios.get(GET_REGION_LIST);
        setRegionList(response.data);
      } catch (error) {
        console.error("Error fetching inquiry types:", error);
      } finally {
        setRegionLoading(false);
      }
    }
  };

  const handleRegionAutocompleteChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      region: newValue ? newValue.region_name : "",
    }));
  };

  const fetchCategoryList = async () => {
    if (categoryList.length === 0) {
      // Only fetch if options are not already loaded
      setCategoryLoading(true);
      try {
        const response = await axios.get(GET_CATEGORY);
        setCategoryList(response.data);
      } catch (error) {
        console.error("Error fetching inquiry types:", error);
      } finally {
        setCategoryLoading(false);
      }
    }
  };

  const handleCategoryAutocompleteChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: newValue ? newValue.category : "",
    }));
  };

  const fetchProjectList = async () => {
    if (projectList.length === 0) {
      // Only fetch if options are not already loaded
      setProjectLoading(true);
      try {
        const response = await axios.get(GET_PROJECT);
        setProjectList(response.data);
      } catch (error) {
        console.error("Error fetching inquiry types:", error);
      } finally {
        setProjectLoading(false);
      }
    }
  };

  const handleProjectAutocompleteChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Project: newValue ? newValue.Project : "",
    }));
  };

  const fetchCampaignList = async () => {
    if (campaignList.length === 0) {
      // Only fetch if options are not already loaded
      setCampaignLoading(true);
      try {
        const response = await axios.get(GET_CAMPAIGN_NAME);
        setCampaignList(response.data);
      } catch (error) {
        console.error("Error fetching inquiry types:", error);
      } finally {
        setCampaignLoading(false);
      }
    }
  };

  const handleCampaignAutocompleteChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      campaignName: newValue ? newValue.CampaignName : "",
    }));
  };

  const ApplyClearAll = () => {
    const clearedFilters = {
      InquiryType: "",
      Project: "",
      CustomerName: "",
      MobileNo: "",
      region: "",
      category: "",
      subcategory: "",
      campaignName: "",
      search: "",
      agentName: "",
      leadowner: ""
    };

    // Reset all Autocomplete components to null manually
    setFilters(clearedFilters);

    onApplyFilters(clearedFilters);

    localStorage.removeItem("filters");

    setDrawerOpen(false); // Close the drawer after clearing filters
  };

  // Function to check if any filter is active
  const isFilterActive = () => {
    return Object.values(filters).some((value) => value !== "");
  };

  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCloseModal = () => {
    setIsDownloading(false);
  };

  const handleExport = () => {
    setIsDownloading(true); // Open progress modal
    setDownloadProgress(0); // Reset progress

    let artificialProgressInterval;

    // Start artificial progress if the real progress is too fast
    artificialProgressInterval = setInterval(() => {
      setDownloadProgress((prevProgress) => {
        // Stop artificial progress at 90%
        if (prevProgress >= 70) {
          clearInterval(artificialProgressInterval);
          return prevProgress;
        }
        return prevProgress + 10; // Increment by 10 every 100ms
      });
    }, 100); // Update every 100ms

    axios
      .get(EXCEL_EXPORT_ALL_DATA_SUPERVISOR, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        timeout: 60000, // 1-minute timeout
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const realProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // Stop artificial progress when real progress updates
            clearInterval(artificialProgressInterval);

            // Update progress based on real data, but ensure it doesn't go backwards
            setDownloadProgress((prevProgress) =>
              Math.max(prevProgress, realProgress)
            );
          } else {
            console.warn("Unable to calculate download progress");
          }
        },
      })
      .then((response) => {
        clearInterval(artificialProgressInterval); // Ensure artificial progress is cleared

        // Create file download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        let fileName = "all-lead-file.xlsx";
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches.length > 1) {
            fileName = matches[1];
          }
        }
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Close modal and show success
        setIsDownloading(false);
        setDownloadProgress(0);
        // setSeverity('success');
        // setMessage('File downloaded successfully');
        // setOpenSnackbar(true);
      })
      .catch((error) => {
        clearInterval(artificialProgressInterval);
        console.error("Error downloading file:", error);
        setIsDownloading(false);
        setDownloadProgress(0);
        // setSeverity('error');
        // setMessage('Failed to download the file');
        // setOpenSnackbar(true);
      });
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        search: value,
      };

      // Apply the filter immediately when search text is updated
      onApplyFilters(newFilters);

      return newFilters;
    });
  };

  return (
    <div>
      <Dialog
        open={isDownloading}
        onClose={(event, reason) => {
          // Disable closing when clicking outside the modal or pressing 'Escape'
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleCloseModal();
          }
        }}
      >
        <DialogTitle>File Download in Progress...</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "250px",
              minHeight: "150px",
            }}
          >
            {/* Circular Progress with larger size */}
            <CircularProgress
              variant="determinate"
              value={downloadProgress}
              size={80}
              thickness={4}
              sx={{ color: downloadProgress < 100 ? "#1976d2" : "#4caf50" }} // Progress color change
            />
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              {downloadProgress}%
            </Typography>
            {/* Optional text for completed state */}
            {downloadProgress === 100 && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Download complete! Preparing your file...
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {/* Search Box */}
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={filters.search}
          onChange={handleSearchChange} // Trigger search on change
          sx={{ width: "300px" }}
        />

        {/* Filter Toggle Button with Badge */}
        <Badge
          color="secondary"
          variant="dot"
          invisible={!isFilterActive()}
          sx={{
            "& .MuiBadge-dot": {
              width: "10px", // Adjust badge width
              height: "10px", // Adjust badge height
              borderRadius: "50%", // Make it circular
              right: 0,
              top: "10px",
            },
          }}
        >
          <IconButton
            onClick={() => setDrawerOpen((prev) => !prev)}
            style={{
              border: "1px solid #fff", // Change border color to white for contrast
              borderRadius: "4px",
              margin: "5px",
              width: "40px", // Increased width for a more comfortable click area
              height: "40px", // Increased height for a more comfortable click area
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#097969",
              color: "#fff",
              transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
              e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#097969"; // Original color
              e.currentTarget.style.transform = "scale(1)"; // Reset scale
            }}
          >
            {drawerOpen ? <FilterListOffIcon /> : <FilterListIcon />}{" "}
            {/* Change icon based on state */}
          </IconButton>
        </Badge>

        {/* Export Button */}
        <IconButton
          onClick={() => handleExport()}
          style={{
            border: "1px solid #fff", // Change border color to white for contrast
            borderRadius: "4px",
            margin: "5px",
            width: "40px", // Increased width for a more comfortable click area
            height: "40px", // Increased height for a more comfortable click area
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#097969",
            color: "#fff",
            transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
            e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#097969"; // Original color
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
          }}
        >
          <FileDownloadIcon /> {/* Change icon based on state */}
        </IconButton>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)} // Close drawer on backdrop click
        sx={{
          width: 320,
          // zIndex: 1301,  // Higher zIndex than AppBar (default is 1100)
          overflowY: "auto",
        }} // Set width of the drawer
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Filter Options
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </Toolbar>

        <div
          style={{
            padding: 16,
            overflowY: "auto",
            maxHeight: "calc(100vh - 64px)",
          }}
        >
          {" "}
          {/* Added overflow and maxHeight */}
          <Grid
            container
            spacing={1}
            style={{ marginBottom: 16 }}
            direction="column"
          >
            <Grid item xs={12}>
              {/* Inquiry Type Filter */}
              <Autocomplete
                options={inquiryTypes}
                getOptionLabel={(option) => option.InquiryType || ""}
                value={
                  filters.InquiryType
                    ? inquiryTypes.find(
                        (type) => type.InquiryType === filters.InquiryType
                      ) || null
                    : null
                }
                onChange={handleAutocompleteChange}
                onOpen={fetchInquiryTypes} // Trigger API call on open
                loading={loading}
                PaperComponent={(props) => (
                  <Paper {...props} style={{ zIndex: 1400 }} /> // Set higher z-index for the dropdown
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Inquiry Type"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Customer Name Filter */}
              <TextField
                name="CustomerName"
                label="Customer Name"
                variant="outlined"
                size="small"
                fullWidth
                value={filters.CustomerName}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Mobile No Filter */}
              <TextField
                name="MobileNo"
                label="Mobile No."
                variant="outlined"
                size="small"
                fullWidth
                value={filters.MobileNo}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Region Filter */}
              <Autocomplete
                options={regionList}
                getOptionLabel={(option) => option.region_name || ""}
                value={
                  filters.region
                    ? regionList.find(
                        (type) => type.region_name === filters.region
                      ) || null
                    : null
                }
                onChange={handleRegionAutocompleteChange}
                onOpen={fetchRegionList} // Trigger API call on open
                loading={regionLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Region"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {regionLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Category Filter */}
              <Autocomplete
                options={categoryList}
                getOptionLabel={(option) => option.category || ""}
                value={
                  filters.category
                    ? categoryList.find(
                        (type) => type.category === filters.category
                      ) || null
                    : null
                }
                onChange={handleCategoryAutocompleteChange}
                onOpen={fetchCategoryList} // Trigger API call on open
                loading={categoryLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {categoryLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Project Filter */}
              <Autocomplete
                options={projectList}
                getOptionLabel={(option) => option.Project || ""}
                value={
                  filters.Project
                    ? projectList.find(
                        (type) => type.Project === filters.Project
                      ) || null
                    : null
                }
                onChange={handleProjectAutocompleteChange}
                onOpen={fetchProjectList} // Trigger API call on open
                loading={projectLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {projectLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Lead Source Filter */}
              <Autocomplete
                options={campaignList}
                getOptionLabel={(option) => option.CampaignName || ""}
                value={
                  filters.campaignName
                    ? campaignList.find(
                        (type) => type.CampaignName === filters.campaignName
                      ) || null
                    : null
                }
                onChange={handleCampaignAutocompleteChange}
                onOpen={fetchCampaignList} // Trigger API call on open
                loading={campaignLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lead Source"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {campaignLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Lead Owner Filter */}
              <TextField
                name="LeadOwner"
                label="Lead Owner"
                variant="outlined"
                size="small"
                fullWidth
                value={filters.leadowner}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              {/* CSE Filter */}
              <TextField
                name="agentName"
                label="CSE"
                variant="outlined"
                size="small"
                fullWidth
                value={filters.agentName}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              {/* Subcategory Filter */}
              <TextField
                name="subcategory"
                label="Sub-Category"
                variant="outlined"
                size="small"
                fullWidth
                value={filters.subcategory}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Apply and Clear Buttons */}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onApplyFilters(filters);
                  // setDrawerOpen(false);
                }}
              >
                Apply Filters
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={ApplyClearAll}
                style={{ marginLeft: 8 }}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterPanel;
