import React, {useContext, useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { LoginContext } from '../LoginPage/LoginContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import MobileRemarkDatatable from "./MobileRemarkDatatable";
import axios from "axios";
import Loader from "../reusable Component/Loader";
import moment from "moment";
import { Alert, Snackbar } from "@mui/material";
import { GET_ESTIMATION_GENERATED } from "../../constants";
import MobileEstimationGeneratedDatatable from "./MobileEstimationGeneratedDatatable";

function MobileEstimationGeneratedSection({leadDetails}) {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const [runEffect, setRunEffect] = useState(false);

    useEffect(() => { 
        setLoading(true);
        axios.get(`${GET_ESTIMATION_GENERATED}/${leadDetails.id}`,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
        .then(response => {

         const {data} = response.data;
          
          setRunEffect(false);
          handlingGeneratedEstimationRowData(data);
          setLoading(false);
        }) 
        .catch(error => {
          setLoading(false);
        });
  
      },[runEffect]);

      const [finalGenEstimationRow, setFinalGenEstimationRow] = useState([]);
    const handlingGeneratedEstimationRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
          a = {
            "id": index + 1 ,
            "sno": index + 1 ,
            "status": rd.status,
            "estimation_id": rd.id,
            "EstimationRequirement":rd.EstimationRequirement == null ? '-' : rd.EstimationRequirement,
            "CivilConstructedStarted": rd.CivilConstructedStarted == true ? 'Yes' : 'No',
            "ShedLength": rd.ShedLength == null ? '-' : rd.ShedLength,
            "EquipementPlacementLength": rd.EquipementPlacementLength == null ? '-' : rd.EquipementPlacementLength,
            "ShedWidth": rd.ShedWidth == true ? 'Yes' : 'No',
            "CShape": rd.CShape == true ? 'Yes' : 'No',
            "ModalType": rd.ModalType == null ? '-' : rd.ModalType,
            "SideWallColumnToColumnGap": rd.SideWallColumnToColumnGap == null ? '-' : rd.SideWallColumnToColumnGap,
            "NumberOfSheds":rd.NumberOfSheds,
            "CurtainRequirment": rd.CurtainRequirment == true ? 'Yes' : 'No',
            "DiselBrooderRequirment": rd.DiselBrooderRequirment == true ? 'Yes' : 'No',
            "PowerSupply": rd.PowerSupply == '' ? '-' : rd.PowerSupply,
            "WaterSupply": rd.WaterSupply == true ? 'Yes' : 'No',
            "Remarks": rd.Remarks == null ? '-' : rd.Remarks,
            "category": rd.category == null ? '-' : rd.category,
            "sub_category": rd.sub_category == null ? '-' : rd.sub_category,
            "follow_up_date": rd.follow_up_date == null ? '-' : formatDate(rd.follow_up_date),
            "closure_month": rd.closure_month == null ? '-' : rd.closure_month,
            "createdAt": rd.createdAt == null ? '-' : formatDate(rd.createdAt),
            "LeadDetailId": rd.LeadDetailId,
            "ho_document": rd.ho_document,
            "estimationNumber": rd.estimationNumber === null ? '-' : rd.estimationNumber,
            "estimation_amount": rd.estimation_amount === null ? '-' : rd.estimation_amount,
            "firm_farmer_name": rd.firm_farmer_name === null ? '-' : rd.firm_farmer_name,
            "Estimation_generated_date": rd.Estimation_generated_date === null ? '-' : formatDate(rd.Estimation_generated_date),
            "Estimation_request_date": rd.Estimation_request_date === null ? '-' : formatDate(rd.Estimation_request_date),
            "Ho_executive_id": rd.Ho_executive_id,
            "Is_downloadable": rd.Is_downloadable

        }
        transformedRows.push(a);
        })
        setFinalGenEstimationRow(transformedRows); 
    }

    const formatDate = (dateString) => moment(dateString).format('DD-MM-YYYY');

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };

    return(
        <>
        {loading && <Loader open={loading} />}
    <Accordion style={{marginTop: '5px'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${leadFormStyles.accordian_heading}`}
        sx={{ '& .MuiAccordionSummary-content.Mui-expanded': {margin: '0',},}}
        >
        <Typography>Estimation Generated</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '8px 9px 16px'}}>
                <MobileEstimationGeneratedDatatable data={finalGenEstimationRow} setSeverity={setSeverity} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} setLoading={setLoading} ></MobileEstimationGeneratedDatatable>
        </AccordionDetails>
    </Accordion>

    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
    </Snackbar>
        </>
    );
}

export default MobileEstimationGeneratedSection;