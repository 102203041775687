import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import * as React from 'react';
import { useMemo } from 'react';
import './analyticstable.css';
import { Box, Typography } from '@mui/material'; // Import Box for styling
import axios from 'axios';
import { LEAD_ANALYTICS } from '../../constants';

function BdmWiseListComponent({ bdmWiseList }) {

 // Calculate totals
 const totalRow = useMemo(() => {
    if (!bdmWiseList || bdmWiseList.length === 0) return null;

    return bdmWiseList.reduce(
        (acc, item) => {
            return {
                ...acc,
                hot_count: (acc.hot_count || 0) + (item.hot_count || 0),
                warm_count: (acc.warm_count || 0) + (item.warm_count || 0),
                cold_count: (acc.cold_count || 0) + (item.cold_count || 0),
                pending_count: (acc.pending_count || 0) + (item.pending_count || 0),
                closed_count: (acc.closed_count || 0) + (item.closed_count || 0),
                total: (acc.total || 0) + (item.total || 0),
            };
        },
        {
            bdm_name: 'Total',
            hot_count: 0,
            warm_count: 0,
            cold_count: 0,
            pending_count: 0,
            closed_count: 0,
            total: 0,
        }
    );
}, [bdmWiseList]);

const columns = useMemo(
    () => [
        {
            accessorKey: 'bdm_name',
            header: 'BDM Name',
            size: 150,
            footer: 'Total', // Static text for region_name column
        },
        {
            accessorKey: 'hot_count',
            header: 'Hot',
            size: 50,
            footer: totalRow?.hot_count ?? 0, // Show the total for hot_count
        },
        {
            accessorKey: 'warm_count',
            header: 'Warm',
            size: 50,
            footer: totalRow?.warm_count ?? 0, // Show the total for warm_count
        },
        {
            accessorKey: 'cold_count',
            header: 'Cold',
            size: 50,
            footer: totalRow?.cold_count ?? 0, // Show the total for cold_count
        },
        {
            accessorKey: 'pending_count',
            header: 'Pending',
            size: 50,
            footer: totalRow?.pending_count ?? 0, // Show the total for pending_count
        },
        {
            accessorKey: 'closed_count',
            header: 'Closed',
            size: 50,
            footer: totalRow?.closed_count ?? 0, // Show the total for closed_count
        },
        {
            accessorKey: 'total',
            header: 'Total',
            size: 50,
            footer: totalRow?.total ?? 0, // Show the total for total
        },
    ],
    [totalRow] // Make sure columns update when the totalRow changes
);

// Prepare data without the total row in the main table body
const data = useMemo(() => {
    return [...bdmWiseList]; // Regular data without total row
}, [bdmWiseList]);

const table = useMaterialReactTable({
    columns: columns,
    data: data,
    enableStickyHeader: true,
    enableStickyFooter: true, // Enable sticky footer
    enablePagination: false,
    enableGlobalFilter:false,
    enableColumnFilters: false,
    enableColumnActions: false, // Hide the column menu
    muiTableHeadCellProps:{
        sx: {
            padding: '8px',
            boxSizing: 'border-box',
            textAlign: 'center',
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
        },
    },
    muiTableBodyCellProps:({ row }) => ({
        sx: {
          
            padding: '8px',
            borderBottom: '1px solid #ddd',
            borderRight: '1px solid #ddd',
        },
    }),
    muiTableFooterCellProps:{
        sx: {
            
            padding: '8px',
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
        },
    },
        //Simply adding a table title to the top-left of the top toolbar
        renderTopToolbarCustomActions: () => (
            <Typography variant="h5">Lead Summary Report</Typography>
        ),
  });

    return (
        <MaterialReactTable
            table={table}
        />
    );
}

export default BdmWiseListComponent;
