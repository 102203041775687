import * as React from "react";
import AgentPerformance from "./agentPerformance";
import AgentOfferingTime from "./agentOfferingTime";
import CallLogTable from "./callLogTable";
import { Alert, Snackbar, Stack } from "@mui/material";
import CallerComponent from "../Dialer/callerComponent";
import { Grid } from "@mui/material";
import { useMediaQuery, useTheme, Chip } from "@mui/material";
import { useState } from "react";
import LeadFormComponent from "./LeadFormComponent";
import AuditFormComponent from "./AuditFormComponent";
import { useLocation } from "react-router-dom";
import { CALL_STATISTICS } from "../../constants";
import { useEffect } from "react";
import axios from "axios";
import NewUserAuditFormComponent from "./NewUserAuditForm";
import NewLeadFormComponent from "./NewLeadFormComponent";

function AgentDashboardComponent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const { mobNo, lotNum } = location.state || {}; // Destructure the state

  const [tableMobile, setTableMobile] = useState("");

  const [formOpen, setFormOpen] = useState(false);
  const [FormMobileNo, setFormMobileNo] = useState("");
  const [auditMobNum, setAuditMobNum] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");

  const [statisticsData, setStatisticsData] = useState([]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    handleCallStatistics();
  }, []);

  const handleCallStatistics = async () => {
    try {
      const response = await axios.get(
        `${CALL_STATISTICS}/${
          JSON.parse(localStorage.getItem("userData")).EmployeePhone
        }`
      );
      setStatisticsData(response.data.data);
    } catch (error) {
      console.error("Error disconnecting call:", error);
    }
  };

  return (
    <>
      <Grid container spacing={2} className="mb-4" wrap="wrap">
        {/* Left Column with Agent Performance and Agent Offering Time */}
        {formOpen ? (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            {localStorage.getItem("empRole") == 1 ? (
              <NewLeadFormComponent
                setFormOpen={setFormOpen}
                FormMobileNo={FormMobileNo}
                setOpenSnackbar={setOpenSnackbar}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setalertTitle={setalertTitle}
                mobNo={mobNo}
              />
            ) : (
              <AuditFormComponent
                setFormOpen={setFormOpen}
                setOpenSnackbar={setOpenSnackbar}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setalertTitle={setalertTitle}
                mobNo={mobNo}
                lotNum={lotNum}
                mobNum={auditMobNum}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <AgentPerformance statisticsData={statisticsData} />
              </Grid>
              <Grid item>
                <AgentOfferingTime />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Right Column with Caller Component */}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          style={{ position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 10,
              width: "max-content", // Adjust to fit the content
            }}
          >
            <CallerComponent
              tableMobile={tableMobile}
              setFormOpen={setFormOpen}
              setFormMobileNo={setFormMobileNo}
              auditMobNo={mobNo}
              setAuditMobNum={setAuditMobNum}
            />
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AgentDashboardComponent;
