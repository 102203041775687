import React from "react";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import Body from "./components/Routes/Body"; // Your main routing component
import { SessionProvider } from "./SessionContext"; // Your session context
import { SocketProvider } from "./components/SocketContext";

function App() {
  return (
    <SessionProvider>
      <SocketProvider>
        <Body />
      </SocketProvider>
    </SessionProvider>
  );
}

export default App;
