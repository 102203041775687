const initialState = {
    leadDetails: null,
  };
  
  const detilsPageReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LEAD_DETAILS':
        return {
          ...state,
          leadDetails: action.data,
        };
      case 'CLEAR_DATA':
            return {
              ...state,
              leadDetails: null,
            };
      default:
        return state;
    }
  };
  
  export default detilsPageReducer;