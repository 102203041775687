import React from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Divider,
} from "@mui/material";
import {
  CATEGORY,
  CLOSED_SUB_CATEGORY,
  COLD_SUB_CATEGORY,
  HOT_SUB_CATEGORY,
  PENDING_SUB_CATEGORY,
  WARM_SUB_CATEGORY,
} from "../../constants";
import { Edit, ToggleOn, ToggleOff } from "@mui/icons-material";
import { useState } from "react";

function CategorySubCategoryComponent() {
  // Mapping subcategories to categories
  const SUB_CATEGORY_MAPPING = {
    hot: HOT_SUB_CATEGORY,
    warm: WARM_SUB_CATEGORY,
    cold: COLD_SUB_CATEGORY,
    pending: PENDING_SUB_CATEGORY,
    closed: CLOSED_SUB_CATEGORY,
  };

  const [subCategories, setSubCategories] = useState(SUB_CATEGORY_MAPPING);

  const handleToggleEnable = (categoryKey, subCategoryId) => {
    setSubCategories((prev) => ({
      ...prev,
      [categoryKey]: prev[categoryKey].map((sub) =>
        sub.id === subCategoryId ? { ...sub, enabled: !sub.enabled } : sub
      ),
    }));
  };

  const handleEdit = (subCategory) => {
    alert(`Editing: ${subCategory.label}`);
  };

  return (
    <>
    <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
          marginTop: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Category Sub-Category List
        </Typography>
      </Box>

      <div>
        {CATEGORY.map((category) => (
           <div key={category.id} >
           <Box
             sx={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               background: "linear-gradient(90deg, #ff7e5f, #feb47b)",
               color: "#fff",
               borderTopLeftRadius: 2,
               borderTopRightRadius: 2,
               padding: "1rem",
               boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
             }}
           >
             <Typography
               variant="h5"
               sx={{
                 fontWeight: "bold",
                 letterSpacing: "0.05em",
                 textTransform: "uppercase",
               }}
             >
               {category.label}
             </Typography>
           </Box>
           {/* <Divider sx={{ margin: "1rem 0", backgroundColor: "#feb47b" }} /> */}
            <TableContainer component={Paper} elevation={6} sx={{marginBottom: '10px'}}>
              <Table>
                <TableHead style={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow>
                    <TableCell width={50} align="left">
                      <strong>SNo</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Sub-Category</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Status</strong>
                    </TableCell>
                    <TableCell align="center" width={200}>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subCategories[category.value]?.map((subCategory) => (
                    <TableRow key={subCategory.id}>
                      <TableCell>{subCategory.id}</TableCell>
                      <TableCell align="center">{subCategory.label}</TableCell>
                      <TableCell>
                        {subCategory.enabled ? (
                          <Typography color="green">Enabled</Typography>
                        ) : (
                          <Typography color="red">Disabled</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          variant="outlined"
                          startIcon={<Edit />}
                          onClick={() => handleEdit(subCategory)}
                          style={{ marginRight: "0.5rem" }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color={subCategory.enabled ? "secondary" : "primary"}
                          startIcon={
                            subCategory.enabled ? <ToggleOff /> : <ToggleOn />
                          }
                          onClick={() =>
                            handleToggleEnable(category.value, subCategory.id)
                          }
                        >
                          {subCategory.enabled ? "Disable" : "Enable"}
                        </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </div>
    </>
  );
}

export default CategorySubCategoryComponent;
