import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import {
  CALL_STATUS,
  CALL_TYPE,
  CATEGORY,
  CLOSED_SUB_CATEGORY,
  COLD_SUB_CATEGORY,
  CREATE_LEAD_API,
  ENQUIRY_TYPE,
  FOLLOW_UP_BY_AGENT,
  GET_DETAILS_BY_MOBILE_NUMBER,
  HOT_SUB_CATEGORY,
  PENDING_SUB_CATEGORY,
  REGION_TO_BDM,
  STATE_TO_REGION,
  TITLE,
  WARM_SUB_CATEGORY,
} from "../../constants";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dashboardStyles from "./dashboardStyles.module.css";

function NewLeadFormComponent({
  setOpen,
  setOpenSnackbar,
  setSeverity,
  setMessage,
  FormMobileNo,
  setFormOpen
}) {
  const empDetails = JSON.parse(localStorage.getItem("userData"));

  //Other
  const [bdmID, setBdmId] = useState("");
  const [regionName, setRegionName] = useState("");
  const [isPresent, setIsPresent] = useState(false);
  const [mobileLoading, setMobileLoading] = useState(false);
  const [isMandatory, setIsMandatory] = useState(true);
  const [regionNameList, setRegionNameList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [filteredLeadOwners, setFilteredLeadOwners] = useState([]);
  const [filteredProject, setFilteredProject] = useState([]);
  const [bdmList, setBdmList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [isOtherLocation, setIsOtherLocation] = useState(true);
  const [pincodeLoading, setPincodeLoading] = useState(false); // State for loader
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [leadId, setLeadId] = useState("");

  //Feilds
  const [project, setProject] = useState("");
  const [callType, setCallType] = useState("");
  const [title, setTitle] = useState("");
  const [siteLocationAddress, setSiteLocationAddress] = useState("");
  const [leadTransferDate, setLeadTransferDate] = useState(undefined);
  const [leadOwner, setLeadOwner] = useState("");
  const [agentRemark, setAgentRemark] = useState("");
  const [followUpDate, setFollowUpDate] = useState(undefined);
  const [closureMonth, setClosureMonth] = useState(undefined);
  const [callStatus, setCallStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState(FormMobileNo || "");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [mailId, setMailId] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [enquiryType, setEnquiryType] = useState("");
  const [location, setLocation] = useState("");

  //Validation Error
  const [agentRemarkError, setAgentRemarkError] = useState("");
  const [projectError, setProjectError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [whatsappNumberError, setWhatsappNumberError] = useState("");
  const [alternateNumberError, setAlternateNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [stateError, setStateError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const [leadSourceError, setLeadSourceError] = useState("");
  const [enquiryTypeError, setEnquiryTypeError] = useState("");
  const [callStatusError, setcCallStatusError] = useState("");
  const [callTypeError, setCallTypeError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [followUpDateError, setFollowUpDateError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [closureMonthError, setClosureMonthError] = useState("");

  const handleIsMandatory = (value) => {
    if (value === "Failed" || value === "pending") {
      setIsMandatory(false);
    } else {
      setIsMandatory(true);
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    // Validate for empty value
    if (value.trim() === "") {
      setMobileNumber("");
      handleReset();
      setMobileNumberError("Mobile Number is required");
    } else {
      // Validate for numeric characters only and length
      if (/^\d{0,10}$/.test(value)) {
        setMobileNumber(value);
        setMobileNumberError("");
        fetchDataByMobileNo(value);
      } else {
        setMobileNumberError("Mobile Number should be 10 digits");
      }
    }
  };

  const fetchDataByMobileNo = (mobNo) => {
    if (mobNo.length === 10) {
      setMobileLoading(true); // Start loading
      axios
        .get(`${GET_DETAILS_BY_MOBILE_NUMBER}/${mobNo}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          const { lead } = response.data;

          if (!lead || Object.keys(lead).length === 0) {
            // Handle case where lead is empty or null
            setIsPresent(false);
            setIsFollowUp(false);
            // setSeverity("warning");
            // setMessage("No lead data available for the given mobile number");
            // setOpenSnackbar(true);
            handleReset();
            return; // Exit early
          }

          if (
            response.data.message ===
            "Lead not found for the given mobile number"
          ) {
            setIsPresent(false);
            setIsFollowUp(false);
            setSeverity("success");
            setMessage("Lead not found for the given mobile number");
            setOpenSnackbar(true);
            handleReset();
          } else {
            // Populate the state variables with the fetched lead data
            setIsPresent(true);
            setIsFollowUp(true);
            setFetchedLeadData(lead, response.data.message);
          }
        })
        .catch((error) => {
          setMobileNumberError("Failed to fetch data. Please try again.");
        })
        .finally(() => {
          setMobileLoading(false); // Stop loading
        });
    }
  };

  const fetchStateAndRegionByPincode = (value) => {
    setPincodeLoading(true);
    axios
      .get(`https://api.postalpincode.in/pincode/${value}`)
      .then((response) => {
        console.log(response.data[0].PostOffice);
        if (response.data[0].PostOffice == null) {
          setSeverity("error");
          setMessage(response.data[0].Message);
          setOpenSnackbar(true);
          return;
        }
        setState(
          response.data[0].PostOffice[0].State === "Chattisgarh"
            ? "Chhattisgarh"
            : response.data[0].PostOffice[0].State
        );

        fetchRegionFromState(
          response.data[0].PostOffice[0].State === "Chattisgarh"
            ? "Chhattisgarh"
            : response.data[0].PostOffice[0].State
        );
        setStateError("");

        //Map region so can be used for region feild
        const namesArray = response.data[0].PostOffice.map((item) => ({
          names: item.Name,
        }));

        setLocationList(namesArray);
      })
      .catch((error) => {
        console.error("Error fetching pincode data:", error);
        setStateError("Failed to fetch state. Please try again.");
      })
      .finally(() => {
        setPincodeLoading(false); // Stop loading
      });
  };

  const fetchRegionFromState = (state_name) => {
    axios
      .get(`${STATE_TO_REGION}/${state_name}`)
      .then((response) => {
        setRegionNameList(response.data);
        if(response.data.length === 1){
          fetchLeadOwnerfromRegion(response.data[0].RegionId)
        }
      })
      .catch((error) => {
        console.error("Error fetching pincode data:", error);
      });
  };

  const fetchLeadOwnerfromRegion = (regionName) => {
    axios
      .get(`${REGION_TO_BDM}/${regionName}`)
      .then((response) => {
        const uniqueBdmList = Array.from(
          new Set(
            response.data.data.map((employee) =>
              JSON.stringify({
                EmployeeId: employee.EmployeeId,
                EmployeeName: employee.EmployeeName,
                leadSources: employee.leadSources || [], // Include lead sources
                project: employee.Project,
              })
            )
          )
        ).map(JSON.parse);
        console.log(uniqueBdmList);

        if (uniqueBdmList.length === 1) {
          setBdmId(uniqueBdmList[0].EmployeeId);
          setLeadOwner(uniqueBdmList[0].EmployeeName);

          setProject(uniqueBdmList[0].project);
        } else {
          setBdmList(uniqueBdmList);
        }

        // Process unique Projects
        const uniqueProjects = Array.from(
          new Set(response.data.data.map((employee) => employee.Project))
        ).map((project) => ({ label: project }));

        setProjectList(uniqueProjects);
      })
      .catch((error) => {
        console.error("Error fetching BDM data:", error);
        setSeverity("warning");
        setMessage(error.response.data.message);
        setOpenSnackbar(true);
        // Reset states on error
        setLeadOwner("");
        setBdmList([]);
        setProjectList([]);
      });
  };

  useEffect(() => {
    if (FormMobileNo !== "") {
      fetchDataByMobileNo(FormMobileNo);
    }
  }, [FormMobileNo]);

  // When Lead Owner is selected
  const handleProjectChange = (selectedProject) => {
    // Filter BDM list based on the selected lead source
    const filteredProject = bdmList.filter(
      (bdm) => bdm.EmployeeId === selectedProject
    );

    // Extract unique lead owners
    const uniqueProject = Array.from(
      new Set(filteredProject.map((owner) => owner.project))
    );

    setFilteredProject(uniqueProject);
  };

  const setFetchedLeadData = (lead, message) => {
    console.log(message, "----------");
    console.log(lead);

    setSeverity("success");
    setMessage(message);
    setOpenSnackbar(true);

    setLeadId(lead.id);
    setFilteredProject([lead.Project] || []);
    setCallType(lead.call_type || "");
    setTitle(""); // Assuming no title in the data
    setSiteLocationAddress(lead.site_location_address || "");
    setLeadTransferDate(lead.lead_transfer_date || undefined);
    setLeadOwner(lead.BDM?.EmployeeName || ""); // Lead Owner is BDM's name
    setAgentRemark(lead.agent_remark || "");
    setFollowUpDate(lead.follow_up_date || undefined);
    setClosureMonth(lead.close_month || undefined);
    setCallStatus(lead.call_status || "");
    setFirstName(lead.CustomerName?.split(" ")[1] || "");
    setMiddleName(""); // No middle name in the data
    setLastName(lead.CustomerName?.split(" ")[2] || "");
    setMobileNumber(lead.MobileNo || "");
    setWhatsappNumber(lead.WhatsappNo || "");
    setAlternateNumber(lead.AlternateMobileNo || "");
    setMailId(lead.CustomerMailId || "");
    setPincode(lead.pincode || "");
    setState(lead.state_name || "");
    setRegion(lead.region_name || "");
    setCategory(lead.category || "");
    setSubCategory(lead.sub_category || "");
    setLeadSource(lead.source_of_lead_generated || "");
    setEnquiryType(lead.InquiryType || "");
    setLocation(lead.location || "");
  };

  const formatDate = (dateString) => dayjs(dateString).format("YYYY-MM-DD");

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    console.log(isFollowUp);
    

    // Reset error states before validation
    setEnquiryTypeError("");
    setLeadSourceError("");
    setAgentRemarkError("");
    setcCallStatusError("");
    setCallTypeError("");
    setFirstNameError("");
    setMiddleNameError("");
    setLastNameError("");
    setTitleError("");
    setMobileNumberError("");
    setPincodeError("");
    setStateError("");
    setRegionError("");
    setLocationError("");
    setCategoryError("");
    setSubCategoryError("");
    setWhatsappNumberError("");
    setAlternateNumberError("");
    setFollowUpDateError("");
    setClosureMonthError("");

    if (isFollowUp === true) {
      if (category.trim() === "") {
        setCategoryError("Category is required.");
        isValid = false;
      }

      if (subCategory.trim() === "") {
        setSubCategoryError("Sub-Category is required.");
        isValid = false;
      }

      if (agentRemark.trim() === ''){
          setAgentRemarkError('Remark is required.');
          isValid = false;
      }

      if (isValid) {
        const formData = {
          follow_up_date: followUpDate,
          category: category,
          sub_category: subCategory,
          LeadDetailId: leadId,
          AgentId: empDetails.EmployeeId,
          remark: agentRemark,
          closure_month: closureMonth,
        };
        console.log("formData", formData);
        axios
          .post(FOLLOW_UP_BY_AGENT, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            setSeverity("success");
            setMessage(response.data.message);
            setOpenSnackbar(true);
            setFormOpen(false);
            setIsPresent(false);
          })
          .catch((error) => {
            setFormOpen(false);
            setIsPresent(false);
            if (error.response) {
              if (error.response.status == 404) {
                setSeverity("error");
                setMessage("404 Not Found.");
                setOpenSnackbar(true);
              } else {
                setSeverity("error");
                setMessage(error.response.data.message);
                setOpenSnackbar(true);
              }
            } else if (error.request) {
              // The request was made but no response was received
              console.log("Request:", error.request);
              setSeverity("error");
              setMessage("Internal Server Error");
              setOpenSnackbar(true);
            }
          });
      } else {
        console.log("Form submission blocked due to validation errors.");
        setSeverity("error");
        setMessage("Please check for missing fields.");
        setOpenSnackbar(true);
      }
    } else {
      // Common validation checks
      if (callStatus === "Failed" || category === "pending") {
        if (enquiryType.trim() === "") {
          setEnquiryTypeError("Enquiry Type is required.");
          isValid = false;
        }

        if (callStatus.trim() === "") {
          setcCallStatusError("Call Status is required.");
          isValid = false;
        }
        if (callType.trim() === "") {
          setCallTypeError("Call Type is required.");
          isValid = false;
        }

        if (empDetails.Campaigns.length > 1) {
          if (leadSource === "") {
            setLeadSourceError("Lead Source is required.");
            isValid = false;
          }
        } else {
          setLeadSource(empDetails.Campaigns[0].CampaignId);
        }

        if (category.trim() === "") {
          setCategoryError("Category is required.");
          isValid = false;
        }
        if (subCategory.trim() === "") {
          setSubCategoryError("Sub-Category is required.");
          isValid = false;
        }

        if (agentRemark.trim() === "") {
          setAgentRemarkError("Remark is required.");
          isValid = false;
        }
      } else {
        if (enquiryType.trim() === "") {
          setEnquiryTypeError("Enquiry Type is required.");
          isValid = false;
        }
        if (callStatus.trim() === "") {
          setcCallStatusError("Call Status is required.");
          isValid = false;
        }
        if (callType.trim() === "") {
          setCallTypeError("Call Type is required.");
          isValid = false;
        }
        if (firstName.trim() === "") {
          setFirstNameError("First Name is required.");
          isValid = false;
        }
        if (!/^[A-Za-z]*$/.test(middleName)) {
          setMiddleNameError("Only alphabetic characters are allowed.");
          isValid = false;
        }
        if (lastName.trim() === "") {
          setLastNameError("Last Name is required.");
          isValid = false;
        }
        if (title.trim() === "") {
          setTitleError("Title is required.");
          isValid = false;
        }
        if (mobileNumber.trim() === "") {
          setMobileNumberError("Mobile Number is required.");
          isValid = false;
        }
        if (mobileNumber.length < 10) {
          setMobileNumberError("Mobile Number should be 10 digits.");
          isValid = false;
        }
        if (pincode.trim() === "") {
          setPincodeError("Pincode is required.");
          isValid = false;
        }
        if (pincode.length < 6) {
          setPincodeError("Pincode should be 6 digits.");
          isValid = false;
        }
        if (state.trim() === "") {
          setStateError("State is required.");
          isValid = false;
        }
        if (region.trim() === "") {
          setRegionError("Region is required.");
          isValid = false;
        }
        if (location.trim() === "") {
          setLocationError("Location is required.");
          isValid = false;
        }
        if (category.trim() === "") {
          setCategoryError("Category is required.");
          isValid = false;
        }
        if (subCategory.trim() === "") {
          setSubCategoryError("Sub-Category is required.");
          isValid = false;
        }
        if (whatsappNumber !== "" && whatsappNumber.length < 10) {
          setWhatsappNumberError("Whatsapp Number should be 10 digits.");
          isValid = false;
        }
        if (alternateNumber !== "" && alternateNumber.length < 10) {
          setAlternateNumberError(
            "Alternate Mobile Number should be 10 digits."
          );
          isValid = false;
        }

        if (empDetails.Campaigns.length > 1) {
          if (leadSource === "") {
            setLeadSourceError("Lead Source is required.");
            isValid = false;
          }
        } else {
          setLeadSource(empDetails.Campaigns[0].CampaignId);
        }

        // Category-specific validation
        if (
          category === "hot" ||
          category === "warm" ||
          category === "pending"
        ) {
          if (!followUpDate || !dayjs(followUpDate).isValid()) {
            setFollowUpDateError("Follow-up date is required.");
            isValid = false;
          } else {
            setFollowUpDateError(""); // Clear error if valid
          }
        }

        if (category === "hot" || category === "warm") {
          if (!closureMonth) {
            setClosureMonthError("Closure Month is required.");
            isValid = false;
          }
        }

        if (agentRemark.trim() === "") {
          setAgentRemarkError("Remark is required.");
          isValid = false;
        }
      }

      if (isValid) {
        // Perform the form submission if valid
        const formData = {
          InquiryType: enquiryType === "" ? null : enquiryType,
          call_type: callType === "" ? callType : callType,
          Project: projectList.length === 1 ? projectList[0].label : project,
          CustomerName: `${title} ${firstName} ${middleName} ${lastName}`,
          MobileNo: mobileNumber,
          WhatsappNo: whatsappNumber === "" ? null : whatsappNumber,
          AlternateMobileNo: alternateNumber === "" ? null : alternateNumber,
          CustomerMailId: mailId === "" ? null : mailId,
          pincode: pincode === "" ? null : pincode,
          state_name: state === "" ? null : state,
          region_name: regionNameList.length === 1 ? regionNameList[0].RegionName : regionName,
          RegionId: regionNameList.length === 1 ? regionNameList[0].RegionId : region,
          location: location === "" ? null : location,
          siteLocationAddress:
            siteLocationAddress === "" ? null : siteLocationAddress,
          category: category === "" ? null : category,
          sub_category: subCategory === "" ? null : subCategory,
          agent_remark: agentRemark === "" ? null : agentRemark,
          follow_up_date:
            followUpDate == undefined ? null : formatDate(followUpDate),
          lead_transfer_date:
            leadTransferDate == undefined ? null : formatDate(leadTransferDate),
          lead_owner: leadOwner === "" ? null : leadOwner,
          // source_of_lead_generated: leadSource == "" ? null : leadSource,
          source_of_lead_generated:
            empDetails.Campaigns.length == 1
              ? empDetails.Campaigns[0].CampaignId
              : leadSource,
          close_month: closureMonth === "" ? null : closureMonth,
          AgentId: Number(localStorage.getItem("username")),
          lead_created_by: Number(localStorage.getItem("empRole")),
          call_status: callStatus === "" ? null : callStatus,
          BDMId:
            bdmID === ""
              ? Number(localStorage.getItem("username"))
              : Number(bdmID),
          created_by: Number(localStorage.getItem("username")),
        };

        axios
          .post(CREATE_LEAD_API, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setSeverity("success");
            setMessage(response.data.message);
            setOpenSnackbar(true);

            setFormOpen(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
              if (error.response.data.errors) {
                const errorData = error.response.data.errors;
                console.log(errorData);

                if (errorData.bdm_remark)
                  setAgentRemarkError(errorData.bdm_remark);
                if (errorData.project) setProjectError(errorData.project);
                if (errorData.first_name)
                  setFirstNameError(errorData.first_name);
                if (errorData.middle_name)
                  setMiddleNameError(errorData.middle_name);
                if (errorData.last_name) setLastNameError(errorData.last_name);
                if (errorData.mobile_number)
                  setMobileNumberError(errorData.mobile_number);
                if (errorData.whatsapp_number)
                  setWhatsappNumberError(errorData.whatsapp_number);
                if (errorData.alternate_number)
                  setAlternateNumberError(errorData.alternate_number);
                if (errorData.email) setEmailError(errorData.email);
                if (errorData.pincode) setPincodeError(errorData.pincode);
                if (errorData.state) setStateError(errorData.state);
                if (errorData.region) setRegionError(errorData.region);
                if (errorData.category) setCategoryError(errorData.category);
                if (errorData.sub_category)
                  setSubCategoryError(errorData.sub_category);
                if (errorData.lead_source)
                  setLeadSourceError(errorData.lead_source);
                if (errorData.InquiryType)
                  setEnquiryTypeError(errorData.InquiryType);
                if (errorData.call_status)
                  setcCallStatusError(errorData.call_status);
                if (errorData.call_type) setCallTypeError(errorData.call_type);
                if (errorData.location) setLocationError(errorData.location);
                if (errorData.follow_up_date)
                  setFollowUpDateError(errorData.follow_up_date);
                if (errorData.title) setTitleError(errorData.title);
                if (errorData.closure_month)
                  setClosureMonthError(errorData.closure_month);
              }

              setSeverity("error");
              setMessage(error.response.data.message);
              setOpenSnackbar(true);
            } else {
              console.log(error.message);
              setSeverity("error");
              setMessage("Internal Server Error.");
              setOpenSnackbar(true);
            }
          });
      } else {
        setSeverity("error");
        setMessage("Please check for missing fields.");
        setOpenSnackbar(true);
        console.log("Form submission blocked due to validation errors.");
      }
    }
  };

  //Reset the form Values
  const handleReset = () => {
    setEnquiryType("");
    setCallType("");
    setCallStatus("");
    setProject("");
    setFirstName("");
    setMiddleName("");
    setTitle("");
    setLastName("");
    setAlternateNumber("");
    setWhatsappNumber("");
    setMailId("");
    setPincode("");
    setState("");
    setRegion("");
    setLocation("");
    setSiteLocationAddress("");
    setCategory("");
    setSubCategory("");
    setAgentRemark("");
    setFollowUpDate(undefined);
    setLeadTransferDate(undefined);
    setLeadOwner("");
    setLeadSource("");
    setClosureMonth(undefined);
    setCallStatus("");
    setState("");
    setProject("");

    //Validation Error
    setAgentRemarkError("");
    setProjectError("");
    setAlternateNumberError("");
    setCategoryError("");
    setSubCategoryError("");
    setFirstNameError("");
    setLastNameError("");
    setMiddleNameError("");
    setPincodeError("");
    setLeadSourceError("");
    setRegionError("");
    setEnquiryTypeError("");
    setWhatsappNumberError("");
    setEmailError("");
    setcCallStatusError("");
    setCallTypeError("");
    setLocationError("");
    setMobileNumberError("");
    setTitleError("");
    setStateError("");

    //Other
    setBdmId("");
    setIsPresent(false);
    setRegionName("");
  };

  return (
    <>
      
      <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${dashboardStyles.accordian_heading} h-auto`}
            sx={{
              "& .Mui-expanded": {
                margin: "10px 0 !important",
              },
            }}
          > 
            <Typography>Contact Details</Typography>
            </AccordionSummary>
          <AccordionDetails>
          <Box component={"form"} noValidate autoComplete="off">
              <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mobile Number"
                    id="outlined-size-small"
                    value={mobileNumber}
                    autoComplete="off"
                    onChange={handleMobileNumberChange}
                    size="small"
                    fullWidth
                    error={!!mobileNumberError}
                    helperText={mobileNumberError}
                    required
                    InputProps={{
                      inputProps: { maxLength: 10 },
                      endAdornment: mobileLoading && (
                        <InputAdornment position="end">
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} >
                  <TextField
                    label="Call Status"
                    select
                    id="outlined-size-small"
                    value={callStatus}
                    fullWidth
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim() == "") {
                        setCallStatus("");
                        setcCallStatusError("Call Status is Required");
                      } else {
                        setCallStatus(value);
                        setcCallStatusError(""); // Clear error when valid
                      }
                      handleIsMandatory(e.target.value);
                    }}
                    size="small"
                    required
                    error={!!callStatusError}
                    helperText={callStatusError}
                  >
                    {CALL_STATUS.map((ls) => (
                      <MenuItem
                        value={ls.label}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {ls.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6} sx={{ paddingBottom: "16px"}}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Enquiry Type"
                    defaultValue=""
                    // helperText="Please select your currency"
                    size="small"
                    fullWidth
                    value={enquiryType}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim() == "") {
                        setEnquiryType("");
                        setEnquiryTypeError("Enquiry Type is Required");
                      } else {
                        setEnquiryType(value);
                        setEnquiryTypeError(""); // Clear error when valid
                      }
                    }}
                    error={!!enquiryTypeError}
                    helperText={enquiryTypeError}
                    required
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {ENQUIRY_TYPE.map((eq) => (
                      <MenuItem
                        value={eq.label}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {eq.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6} sx={{ paddingBottom: "16px"}}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Call Type"
                    defaultValue=""
                    size="small"
                    fullWidth
                    // helperText="Please select your project"
                    value={callType}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim() == "") {
                        setCallType("");
                        setCallTypeError("Call Type is Required");
                      } else {
                        setCallType(value);
                        setCallTypeError(""); // Clear error when valid
                      }
                    }}
                    required
                    error={!!callTypeError}
                    helperText={callTypeError}
                  >
                    {CALL_TYPE.map((p) => (
                      <MenuItem
                        value={p.label}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {p.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {isPresent ? (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ paddingBottom: "16px"}}
                    className={`${leadFormStyles.fields_padding}`}
                  >
                    <TextField
                      label="Customer Name"
                      id="outlined-size-small"
                      value={firstName}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingBottom: "16px"}}
                      className={`${leadFormStyles.fields_padding}`}
                    >
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Title"
                        defaultValue=""
                        size="small"
                        fullWidth
                        // helperText="Please select your project"
                        value={title}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.trim() == "") {
                            setTitle("");
                            setTitleError("Title is Required");
                          } else {
                            setTitle(value);
                            setTitleError(""); // Clear error when valid
                          }
                        }}
                        error={!!titleError}
                        helperText={titleError}
                        required={isMandatory}
                      >
                        {TITLE.map((p) => (
                          <MenuItem
                            value={p.label}
                            sx={{
                              borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                              marginBottom: "8px",
                              marginTop: "8px",
                            }}
                          >
                            {p.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingBottom: "16px"}}
                      className={`${leadFormStyles.fields_padding}`}
                    >
                      <TextField
                        label="Customer First Name"
                        id="outlined-size-small"
                        value={firstName}
                        autoComplete="off"
                        fullWidth
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[A-Za-z\s]*$/.test(value)) {
                            // Allow only alphabets and spaces
                            setFirstName(value);
                            setFirstNameError(""); // Clear error when valid
                          } else {
                            setFirstNameError(
                              "Only alphabetic characters and spaces are allowed"
                            );
                          }
                        }}
                        size="small"
                        error={!!firstNameError}
                        helperText={firstNameError}
                        required={isMandatory}
                        InputProps={{
                          inputProps: {
                            maxLength: 100,
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingBottom: "16px"}}
                      className={`${leadFormStyles.fields_padding}`}
                    >
                      <TextField
                        label="Customer Middle Name"
                        id="outlined-size-small"
                        value={middleName}
                        autoComplete="off"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[A-Za-z\s]*$/.test(value)) {
                            // Allow only alphabets and spaces
                            setMiddleName(value);
                            setMiddleNameError(""); // Clear error when valid
                          } else {
                            setMiddleNameError(
                              "Only alphabetic characters and spaces are allowed"
                            );
                          }
                        }}
                        size="small"
                        fullWidth
                        error={!!middleNameError}
                        helperText={middleNameError}
                        InputProps={{
                          inputProps: {
                            maxLength: 100,
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ paddingBottom: "16px"}}
                      className={`${leadFormStyles.fields_padding}`}
                    >
                      <TextField
                        label="Customer Last Name"
                        id="outlined-size-small"
                        value={lastName}
                        autoComplete="off"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[A-Za-z\s]*$/.test(value)) {
                            // Allow only alphabets and spaces
                            setLastName(value);
                            setLastNameError(""); // Clear error when valid
                          } else {
                            setLastNameError(
                              "Only alphabetic characters and spaces are allowed"
                            );
                          }
                        }}
                        size="small"
                        fullWidth
                        error={!!lastNameError}
                        helperText={lastNameError}
                        required={isMandatory}
                        InputProps={{
                          inputProps: {
                            maxLength: 100,
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Whatsapp Number"
                    id="outlined-size-small"
                    autoComplete="off"
                    value={whatsappNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Validate for numeric characters only and length
                      if (/^\d{0,10}$/.test(value)) {
                        setWhatsappNumber(value);
                        setWhatsappNumberError("");
                      } else {
                        setWhatsappNumberError(
                          "Whatsapp Number should be 10 digits"
                        );
                      }
                    }}
                    size="small"
                    fullWidth
                    error={!!whatsappNumberError}
                    helperText={whatsappNumberError}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Alternate Number"
                    id="outlined-size-small"
                    autoComplete="off"
                    value={alternateNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Validate for numeric characters only and length
                      if (/^\d{0,10}$/.test(value)) {
                        setAlternateNumber(value);
                        setAlternateNumberError("");
                      } else {
                        setAlternateNumberError(
                          "Alternate Number should be 10 digits"
                        );
                      }
                    }}
                    size="small"
                    fullWidth
                    error={!!alternateNumberError}
                    helperText={alternateNumberError}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Customer Mail ID"
                    id="outlined-size-small"
                    value={mailId}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      // Validate for email format
                      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        setMailId(value);
                        setEmailError("");
                      } else {
                        setMailId(value);
                        setEmailError("Invalid email format.");
                      }
                    }}
                    size="small"
                    fullWidth
                    error={!!emailError}
                    helperText={emailError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Postal Pincode"
                    id="outlined-size-small"
                    value={pincode}
                    onChange={(e) => {
                      const value = e.target.value.trim();

                      // Check if the field is empty
                      if (value === "") {
                        setPincode(value);
                        setPincodeError("Pincode is required");
                        return;
                      }
                      setState("");
                      setRegion("");
                      setLocation("");
                      setRegionNameList([]);
                      // Validate numeric input
                      if (/^\d{0,6}$/.test(value)) {
                        setPincode(value);
                        setPincodeError("");

                        // Call API only when the pincode length is valid
                        if (value.length === 6) {
                          fetchStateAndRegionByPincode(value);
                        }
                      } else {
                        setPincode(value);
                        setPincodeError("Invalid pincode format");
                      }
                    }}
                    size="small"
                    fullWidth
                    required={isMandatory}
                    autoComplete="off"
                    error={!!pincodeError}
                    helperText={pincodeError}
                    InputProps={{
                      inputProps: {
                        maxLength: 6, // Pincode should be 6 digits max
                        inputMode: "numeric", // Optimized for mobile numeric input
                      },
                      endAdornment: pincodeLoading ? (
                        <InputAdornment position="end">
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ) : null,
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    id="outlined-select-currency"
                    label="State"
                    value={state}
                    // onChange={handleStateChange}
                    // helperText="Please select your currency"
                    size="small"
                    fullWidth
                    required={isMandatory}
                    error={!!stateError}
                    helperText={stateError}
                    InputProps={{
                      inputProps: {
                        pattern: "^[A-Za-z]*$",
                        title: "Only alphabetic characters are allowed",
                        readOnly: true,
                      },
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  {isPresent ||
                  (regionNameList.length === 1 &&
                    regionNameList[0].RegionName) ? (
                    <TextField
                      id="outlined-select-currency"
                      label="Region"
                      value={isPresent ? region : regionNameList[0].RegionName}
                      size="small"
                      fullWidth
                      InputProps={{
                        readOnly: true, // Make the field read-only
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Region"
                      value={region}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.trim() === "") {
                          setRegion("");
                          setRegionError("Region is required.");
                        } else {
                          setRegion(value);
                          setRegionError("");

                          // Find the region name based on the selected ID
                          const selectedRegion = regionNameList.find(
                            (cl) => cl.RegionId === value
                          );
                          const regionName = selectedRegion?.RegionName || ""; // Default to empty string if not found
                          setRegionName(regionName);
                          fetchLeadOwnerfromRegion(value);
                        }
                      }}
                      size="small"
                      fullWidth
                      required={isMandatory}
                      error={!!regionError}
                      helperText={regionError}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          fontSize: "0.65rem",
                          marginLeft: "0",
                        },
                      }}
                    >
                      {regionNameList.map((cl) => (
                        <MenuItem key={cl.RegionId} value={cl.RegionId}>
                          {cl.RegionName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>

                {isOtherLocation && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ paddingBottom: "16px"}}
                    className={`${leadFormStyles.fields_padding}`}
                  >
                    {isPresent ? (
                      <TextField
                        id="outlined-select-currency"
                        label="Location"
                        value={location}
                        //  onChange={handleStateChange}
                        size="small"
                        fullWidth
                      />
                    ) : (
                      <TextField
                        label="Location"
                        select
                        id="outlined-size-small"
                        value={location}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.trim() === "") {
                            setLocation(value);
                            setLocationError("Location is required.");
                          } else {
                            setLocation(value);
                            setIsOtherLocation(
                              value === "Other" ? false : true
                            );
                            setLocationError("");
                          }
                        }}
                        size="small"
                        fullWidth
                        required={isMandatory}
                        error={!!locationError}
                        helperText={locationError}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            fontSize: "0.65rem",
                            marginLeft: "0",
                          },
                        }}
                      >
                        {locationList &&
                          locationList.map((pl) => (
                            <MenuItem value={pl.names}>{pl.names}</MenuItem>
                          ))}
                        <MenuItem value={"other"}>Other</MenuItem>
                      </TextField>
                    )}
                  </Grid>
                )}

                {location === "other" && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ paddingBottom: "16px"}}
                    className={`${leadFormStyles.fields_padding}`}
                  >
                    <TextField
                      label="Location"
                      id="outlined-size-small"
                      autoComplete="off"
                      // value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  {empDetails.Campaigns.length === 1 ? (
                    <TextField
                      label="Lead Source"
                      id="outlined-size-small"
                      value={empDetails.Campaigns[0].CampaignName}
                      size="small"
                      fullWidth
                      required
                      InputProps={{
                        readOnly: true, // Make it read-only
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "rgba(0, 0, 0, 0.87)", // Optional styling for read-only text
                        },
                        "& .MuiFormHelperText-root": {
                          fontSize: "0.65rem",
                          marginLeft: "0",
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      label="Lead Source"
                      select
                      id="outlined-size-small"
                      value={leadSource}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (value === "") {
                          setLeadSourceError("Lead source is required.");
                        } else {
                          setLeadSource(value);
                          setLeadSourceError(""); // Clear the error if valid
                        }
                      }}
                      size="small"
                      fullWidth
                      required
                      error={!!leadSourceError}
                      helperText={leadSourceError}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          fontSize: "0.65rem",
                          marginLeft: "0",
                        },
                      }}
                    >
                      {empDetails.Campaigns.map((p) => (
                        <MenuItem
                          key={p.CampaignId} // Ensure unique keys
                          value={p.CampaignId}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {p.CampaignName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Lead Owner"
                    id="outlined-size-small"
                    select={bdmList.length > 1} // Make it a dropdown only if multiple options exist
                    value={leadOwner} // Use ID as the value
                    onChange={(e) => {
                      const selectedEmployeeId = e.target.value;
                      setLeadOwner(selectedEmployeeId); // Set ID as the value
                      setBdmId(selectedEmployeeId); // Maintain consistency
                      handleProjectChange(selectedEmployeeId);
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: bdmList.length === 1, // Read-only for single value
                    }}
                    required={isMandatory}
                  >
                    {bdmList.length === 1 ? (
                      <MenuItem
                        key={bdmList[0].EmployeeId}
                        value={bdmList.EmployeeId} // Set ID as the value
                      >
                        {bdmList.EmployeeName} {/* Show name */}
                      </MenuItem>
                    ) : (
                      bdmList.map((p) => (
                        <MenuItem
                          key={p.EmployeeId}
                          value={p.EmployeeId} // Set ID as the value
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {p.EmployeeName} {/* Display name */}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  {/* Check if there's only one project and set it directly */}
                  {projectList.length === 1 ? (
                    <TextField
                      id="outlined-select-currency"
                      label="Project"
                      value={projectList[0].label} // Automatically set to the only project
                      size="small"
                      fullWidth
                      InputProps={{
                        readOnly: true, // Make the field read-only when only one project exists
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Project"
                      required={isMandatory}
                      error={!!projectError}
                      helperText={projectError}
                      size="small"
                      fullWidth
                      value={project}
                      onChange={(e) => {
                        setProject(e.target.value);
                      }}
                    >
                      {projectList.map((p) => (
                        <MenuItem
                          key={p.label}
                          value={p.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {p.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ paddingBottom: "16px"}}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    label="Site Location Address"
                    id="outlined-size-small"
                    multiline
                    rows={4}
                    autoComplete="off"
                    value={siteLocationAddress}
                    onChange={(e) => setSiteLocationAddress(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        maxLength: 100,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={`${leadFormStyles.accordian_heading} h-auto`}
            sx={{
              "& .Mui-expanded": {
                margin: "10px 0 !important",
              },
            }}
          >
            <Typography>Disposition Summary</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              autoComplete="off"
            >
              <Grid container spacing={2} >
                <Grid item xs={12} md={6} sx={{ paddingBottom: "16px"}}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Category"
                    value={category}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value.trim() === "") {
                        setCategory(value);
                        setCategoryError("State is required.");
                      } else {
                        setCategory(value);
                        setCategoryError("");
                      }
                      handleIsMandatory(e.target.value);
                    }}
                    // helperText="Please select your currency"
                    size="small"
                    fullWidth
                    required
                    error={!!categoryError}
                    helperText={categoryError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {CATEGORY.map((c) => (
                      <MenuItem
                        value={c.value}
                        sx={{
                          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {c.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Sub-Category"
                    value={subCategory}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.trim() === "") {
                        setSubCategory(value);
                        setSubCategoryError("Sub-Category is required.");
                      } else {
                        setSubCategory(value);
                        setSubCategoryError("");
                      }
                    }}
                    size="small"
                    fullWidth
                    required
                    error={!!subCategoryError}
                    helperText={subCategoryError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  >
                    {category.toLowerCase() == "hot" &&
                      HOT_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "warm" &&
                      WARM_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "cold" &&
                      COLD_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "pending" &&
                      PENDING_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                    {category.toLowerCase() == "closed" &&
                      CLOSED_SUB_CATEGORY.map((h) => (
                        <MenuItem
                          value={h.label}
                          sx={{
                            borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                            marginBottom: "8px",
                            marginTop: "8px",
                          }}
                        >
                          {h.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                {(category.toLowerCase() === "hot" ||
                  category.toLowerCase() === "warm") && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    className={`${leadFormStyles.fields_padding}`}
                  >
                    {isPresent ? (
                      <DatePicker
                        label="Lead Transfer Date"
                        value={dayjs(leadTransferDate)}
                      />
                    ) : (
                      <DatePicker
                        label="Lead Transfer Date"
                        onChange={(e) => {
                          {
                            const date = new Date(e.$d);
                            const year = date.getFullYear();
                            const month = ("0" + (date.getMonth() + 1)).slice(
                              -2
                            ); // Months are zero indexed, so we add 1
                            const day = ("0" + date.getDate()).slice(-2);
                            const formattedDate = `${year}-${month}-${day}`;
                            setLeadTransferDate(formattedDate);
                          }
                        }}
                        disablePast
                      />
                    )}
                  </Grid>
                )}

                {(category.toLowerCase() === "hot" ||
                  category.toLowerCase() === "warm" ||
                  category.toLowerCase() === "pending") && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    
                    className={`${leadFormStyles.fields_padding}`}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {isPresent ? (
                        <DateTimePicker
                          label="Follow-up Date"
                          name="startDateTime"
                          value={dayjs(followUpDate)}
                          disablePast
                        />
                      ) : (
                        <DateTimePicker
                          label="Follow-up Date"
                          name="startDateTime"
                          onChange={(value) => {
                            if (!value || !dayjs(value).isValid()) {
                              setFollowUpDateError(
                                "Follow-up date is required."
                              );
                            } else {
                              setFollowUpDate(
                                dayjs(value).format("YYYY-MM-DDTHH:mm:ss")
                              );
                              setFollowUpDateError("");
                            }
                          }}
                          slotProps={{
                            textField: {
                              error: !!followUpDateError,
                              helperText: followUpDateError,
                              required: true,
                            },
                          }}
                          disablePast
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    </LocalizationProvider>
                  </Grid>
                )}

                {(category.toLowerCase() === "hot" ||
                  category.toLowerCase() === "warm") && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    
                   
                  >
                    {isPresent ? (
                      <DatePicker
                        label={"Expected Closure Month"}
                        views={["month", "year"]}
                        value={dayjs(closureMonth)}
                        disableFuture // Optional: Disable future dates
                      />
                    ) : (
                      <DatePicker
                        label={"Closure Month"}
                        views={["month", "year"]}
                        onChange={(newValue) => {
                          // newValue is the selected date object
                          const formattedValue =
                            dayjs(newValue).format("YYYY-MMM");
                          setClosureMonth(formattedValue); // Set the formatted value to state

                          // Validation check
                          if (!newValue) {
                            setClosureMonthError("Closure Month is required.");
                          } else {
                            setClosureMonthError(""); // Clear the error if the date is valid
                          }
                        }}
                        slotProps={{
                          textField: {
                            error: !!closureMonthError,
                            helperText: closureMonthError, // Display the error message
                            required: isMandatory ? true : false,
                          },
                        }}
                        disablePast
                      />
                    )}
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={6}
                  className={`${leadFormStyles.fields_padding}`}
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Remarks"
                    multiline
                    rows={4}
                    fullWidth
                    value={agentRemark}
                    inputProps={{
                      maxLength: 250, // No need for inputProps nested object
                    }}
                    style={{ resize: "both", overflow: "auto" }}
                    onChange={(e) => {
                      const newRemark = e.target.value;

                      if (newRemark.length <= 250) {
                        setAgentRemark(newRemark);
                      }

                      // Remove the error message when the user starts typing
                      if (newRemark.length > 0 && agentRemarkError) {
                        setAgentRemarkError("");
                      }
                    }}
                    required
                    error={!!agentRemarkError}
                    helperText={agentRemarkError}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "0.65rem",
                        marginLeft: "0",
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    color={
                      agentRemark.length > 250 ? "error" : "text.secondary"
                    }
                    sx={{ marginLeft: "7px" }}
                  >
                    {agentRemark.length}/250
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
     
      <Box
          display="flex"
          justifyContent="flex-end"
          gap={2} // for spacing between buttons
          mt={2} // optional margin from top
        >
          <Button onClick={handleReset}  variant="contained">Reset</Button>
          <Button type="submit" onClick={handleSubmit}  variant="contained">
            Save
          </Button>
        </Box>
      
    </>
  );
}

export default NewLeadFormComponent;
