import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginComponent from '../LoginPage/LoginComponent';
import PrivateRoutes from './PrivateRoutes';
import CallerPage from '../Dialer/CallerPage';
import { LoginProvider } from '../LoginPage/LoginContext';
import QRFormComponent from '../QRForm/QRFormComponent';
import VistaarQRForm from '../QRForm/VistarQRForm';
import ChicksInquiryForm from '../QRForm/ChicksInquiryForm';

const routes = createBrowserRouter([
    {
        path: '/',
        element: <LoginComponent />
    },
    {
        path: '/qrInquiry',
        element: <QRFormComponent />
    },
    {
        path: '/qr/vistaar',
        element: <VistaarQRForm />
    },
    {
        path: '/qr/chicks',
        element: <ChicksInquiryForm />
    },
  
    {
        path: '/*',
        element: <PrivateRoutes />
    },
]);

function Body() {
    return (
      <>
        <LoginProvider>
          <RouterProvider router={routes} /> {/* No additional Router here */}
        </LoginProvider>
        </>
    );
}

export default Body;
