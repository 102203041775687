import React, { useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import { Alert, Button, Card, CardContent, CardHeader, FormHelperText, Grid, IconButton, MenuItem, Snackbar, TextField, Typography, useMediaQuery } from '@mui/material';
import leadFormStyles from '../Leads Page/leadFormStyles.module.css';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { HO_STATUS_UPDATE } from "../../constants";
import { useTheme } from "@emotion/react";
import Loader from "../reusable Component/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import LongTextComponent from "../Leads Page/LongTextComponent";


function EstimationDetailsPageComponent() {

    const storedLeadDetails = JSON.parse(localStorage.getItem('leadDetails'));
    const empDetails = JSON.parse(localStorage.getItem('userData'));

    console.log(storedLeadDetails);

    const location = useLocation();
    const { defaultStatus } = location.state || '';

    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const fileURLs = selectedFiles.map((file) => ({
          name: file.name,
          fileURL: URL.createObjectURL(file),
        }));
        setFiles([...files, ...selectedFiles]);
        setError('');
      };
    
      const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
      };

      //file validations
      const validateFiles = (selectedFiles) => {
        for (const file of selectedFiles) {
          if (file.type !== 'application/pdf') {
            // setError('Only PDF files are allowed.');
            return false;
          }
          if (file.size > 10485760) {
            // setError('File size should be less than 10MB.');
            return false;
          }
        }
        return true;
      };

      const [hideFields , setHideFields] = useState(false);
      const [status, setStatus] = useState('');

      const [showField, setShowField] = useState(false);
      const [showForNeedForApproval, setShowForNeedForApproval] = useState(false);

      const [approvalFrom, setApprovalFrom] = useState('');

      const handleChangeStatus = (e) =>{
        const value = e.target.value;
        setStatus(value);
        if(value == 'Need for Approval'){
            setHideFields(false);
            setShowField(true);
            setShowForNeedForApproval(true);
        } else if(value == 'Rejected'){
            setHideFields(false);
            setShowField(true);
            setShowForNeedForApproval(false);
        } else{
            setHideFields(true);
            setShowField(false);
            setShowForNeedForApproval(false);
        }
      }

      const [remark, setRemark] = useState('');

      const [estimationNumber, setEstimationNumber] = useState('');
      const [estimationNumberError, setestimationNumberError] = useState('');
      const [estimationAmount, setEstimationAmount] = useState('');
      const [estimationAmountError, setestimationAmountError] = useState('');
      
      const handleEstimationNumberChange = (e) =>{
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
            setEstimationNumber(value);
            setestimationNumberError('')
            }
      };

      const handleEstiamtionAmountChange = (e) =>{
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
            setEstimationAmount(value);
            setestimationAmountError('')
            }
      };

      const [error, setError] = useState('');

      const handleSubmit =(e)=>{
        e.preventDefault();
        setLoading(true);
        if(status === 'Generated'){
            handleGeneratedSubmit();
        
        } else{

            const formData = new FormData();
            formData.append("status", status);
            formData.append("LeadDetailId", storedLeadDetails.LeadDetailId);
            formData.append("estimationAmount", estimationAmount);
            formData.append("estimationNumber", estimationNumber);
            formData.append("employeeId", empDetails.EmployeeId);
            formData.append("Approval_from", approvalFrom);
            formData.append("remark", remark);
            files.forEach((image) => {
                formData.append("images", image);
            });
            
    
            axios.patch(`${HO_STATUS_UPDATE}/${storedLeadDetails.estimationId}/status`, formData,{
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
              })
            .then(response => {
                setOpenSnackbar(true);
                setSeverity('success');
                setMessage(response.data.message);
                resetForm();
                setLoading(false);

                setTimeout(() => {
                    navigate('/estimateGeneration'); 
                  }, 1000); // Delay for 1 seconds
    
            })
            .catch(error => {
                setOpenSnackbar(true);
                setSeverity('error');
                setMessage(error.response.data.message);
                resetForm()
                setLoading(false);
            });
        }
      };

      const [remarkError, setRemarkError] = useState('');

      const handleGeneratedSubmit =() =>{
        let isValid = true;

        if (estimationAmount.trim() === '') {
            setestimationAmountError('Estimation Amount is required.');
            isValid = false;
        }

        if (estimationNumber.trim() === '') {
            setestimationNumberError('Estimation Number is required.');
            isValid = false;
        }

        if (files.length === 0) {
            setError('No files selected for upload.');
            isValid = false;;
          }

          if(status === 'Need for Approval') {
            if (remark.trim() == ''){
                setRemarkError('Feild is Required.')
            }
          }

        if (isValid) {

            const formData = new FormData();
            formData.append("status", status);
            formData.append("LeadDetailId", storedLeadDetails.LeadDetailId);
            formData.append("estimation_amount", estimationAmount);
            formData.append("estimationNumber", estimationNumber);
            formData.append("employeeId", empDetails.EmployeeId);
            formData.append("Approval_from", approvalFrom);
            formData.append("remark", remark);
            files.forEach((image) => {
                formData.append("images", image);
            });

        axios.post(`${HO_STATUS_UPDATE}/${storedLeadDetails.estimationId}/status`, formData,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
          })
        .then(response => {
            setOpenSnackbar(true);
            setSeverity('success');
            setMessage(response.data.message);
            resetForm();
            setLoading(false);

            setTimeout(() => {
                navigate('/estimateGeneration'); 
              }, 1000); // Delay for 1 seconds

            
        })
        .catch(error => {
            setOpenSnackbar(true);
            setSeverity('error');
            setMessage(error.response.data.message);
            resetForm()
            setLoading(false);
        });
        }else{
            console.log('Form submission blocked due to validation errors.');
                setSeverity('error');
                setMessage("Please check for missing fields.");
                setOpenSnackbar(true);
                setLoading(false);
        }
      }

      const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };

      const resetForm = () =>{
        setStatus('');
        setEstimationAmount('');
        setEstimationNumber('');
        setFiles([]);
        if (fileInputRef.current) {
        fileInputRef.current.value = null;
        }
        setHideFields(false);
        setApprovalFrom('');
        setRemark('');
        setShowForNeedForApproval(false);
      };

      console.log(defaultStatus);
      useEffect(()=>{
        if(defaultStatus != undefined){
            setStatus(defaultStatus);
            setShowField(true);
        }
      },[defaultStatus]);

    return(
        <>
        {loading && <Loader open={loading} />}
         <Box sx={{ width: '100%', mt: isMobile? 1 : 3 }}>
            <Card sx={{ mb: 2 }}>
            <CardHeader
                title={<Typography variant="body1">Lead Details</Typography>}
                sx={{ backgroundColor: '#D9D9DC', cursor: 'pointer' , padding: '12px'}}
            />
            <CardContent>

            <Grid container spacing={isMobile ? 0:1}>
                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Estimation Type:
                    </Typography>
                    <Typography variant='body2' sx={{textTransform: 'capitalize',fontSize: isMobile ? '0.8rem' : ''}} >
                        {storedLeadDetails.EstimationRequirement} 
                    </Typography>
                </Grid>


                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Customer Name:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.farmerName} 
                    </Typography>
                </Grid>


                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Mobile Number:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.MobNo} 
                    </Typography>
                </Grid>


                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Project:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.Project} 
                    </Typography>
                </Grid>


                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Civil Construction Started:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.CivilConstructedStarted} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Shed Length:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.ShedLength} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                           Shed Width:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.ShedWidth} 
                    </Typography>
                </Grid>


                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Equipment Placement Length:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.EquipementPlacementLength} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`} >
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                            C-Shape:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.CShape} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                            Model Type:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.ModalType} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                            Side Wall Column to Column Gap:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.SideWallColumnToColumnGap} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Number of Sheds:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.NumberOfSheds} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`} >
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Curtain Requirement:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.CurtainRequirment} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        Diesel Brooder Requirement:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.DiselBrooderRequirment} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`} >
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                            Power Supply:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.PowerSupply} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                            Water Available:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.WaterSupply} 
                    </Typography>
                </Grid>

                

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                           Firm/Farmer Name:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.firm_farmer_name} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                           Requested By:
                    </Typography>
                    <Typography variant='body2' sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                        {storedLeadDetails.BdmName} 
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 12:4} sm={isSmall? 6 : 4} md={4} xl={6} className={`flex`}>
                    <Typography variant='body2' className={`${leadFormStyles.label_margin}`} sx={{fontSize: isMobile ? '0.8rem' : ''}}>
                            Remarks:
                    </Typography>
                    <Typography variant='body2'>
                        <LongTextComponent text={storedLeadDetails.Remarks === null ? '-' : storedLeadDetails.Remarks} maxLength={20} />
                    </Typography>
                </Grid>
            </Grid>
            </CardContent>
            </Card>
        </Box>

        <Box sx={{ width: '100%', mt: isMobile? 1 : 3 }}>
            <Card sx={{ mb: 2 }}>
            <CardHeader
                title={<Typography variant="body1">Estimation Update</Typography>}
                sx={{ backgroundColor: '#D9D9DC', cursor: 'pointer' , padding: '12px'}}
            />
            <CardContent>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                >

                        <Grid container spacing={1}>
                        
                        <Grid item xs={12} md={4} xl={6}>
                        <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={status}
                        size='small'
                        onChange={(e)=> handleChangeStatus(e)}
                        >
                        
                        <MenuItem value={'Need for Approval'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Need for Approval</MenuItem>
                        <MenuItem value={'Generated'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Generated</MenuItem>
                        <MenuItem value={'Rejected'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Rejected</MenuItem>
                        </TextField>

                        </Grid>

                        {showForNeedForApproval &&
                        <Grid item xs={12} md={4} xl={6}>
                         <TextField
                         id="outlined-select-currency"
                         select
                         label="Approval From"
                         value={approvalFrom}
                         size='small'
                         onChange={(e)=> setApprovalFrom(e.target.value)}
                         >
                         
                         <MenuItem value={'Shed Size'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Shed Size(Mubarak Sir)</MenuItem>
                         <MenuItem value={'Stablizer'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Stablizer (MF Khan Sir)</MenuItem>
                         <MenuItem value={'Electricity'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',  marginTop: '8px' }}>Electricity (RH)</MenuItem>
                         </TextField>
 
                         </Grid>}

                        {showField && <Grid item xs={12} md={4} xl={6} >
                        <TextField
                        label="Remarks"
                        id="outlined-size-small"
                        multiline
                        rows={4}
                        value={remark}
                        onChange={(e)=>{{setRemark(e.target.value)}}}
                        size="small"
                        required
                        error={!!remarkError}
                        helperText={remarkError}
                            sx={{
                            '& .MuiFormHelperText-root':{
                                fontSize: '0.65rem',
                                marginLeft: '0'
                            }
                            }}
                        
                        />
                        </Grid>}

                        {hideFields && 
                        <>
                        <Grid item xs={12} md={4} xl={6} >
                        <TextField
                        label="Estimation Number"
                        id="outlined-size-small"
                        value={estimationNumber}
                        onChange={(e)=>{ 
                            if(e.target.value.length <= 11){
                            handleEstimationNumberChange(e)}
                        }}
                        size="small"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 11, }}
                        required
                        error={!!estimationNumberError}
                        helperText={estimationNumberError}
                            sx={{
                            '& .MuiFormHelperText-root':{
                                fontSize: '0.65rem',
                                marginLeft: '0'
                            }
                            }}
                        />
                        </Grid>

                        <Grid item xs={12} md={4} xl={6}>
                        <TextField
                        label="Estimation Amount"
                        id="outlined-size-small"
                        value={estimationAmount}
                        onChange={(e)=>{
                            handleEstiamtionAmountChange(e)
                        }}
                        size="small"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',  }}
                        required
                        error={!!estimationAmountError}
                        helperText={estimationAmountError}
                            sx={{
                            '& .MuiFormHelperText-root':{
                                fontSize: '0.65rem',
                                marginLeft: '0'
                            }
                            }}
                        />
                        </Grid>
                        
                        

                        <Grid item xs={12} md={6} xl={6}>
                        <Box className="mt-4">
                            <Typography variant="body2">Upload PDFs</Typography>
                            <Box className="border-2 border-dashed w-auto border-black bg-gray-200 rounded text-center" sx={{height: '30px'}}>
                            <input
                                ref={fileInputRef}
                                accept="application/pdf"
                                style={{ display: 'none' }}
                                id="pdf-upload-button"
                                type="file"
                                multiple
                                onChange={handleFileChange}
                            />
                            <label htmlFor="pdf-upload-button">
                                <AddIcon className="upload_icon" />
                            </label>
                            {error && <FormHelperText error>{error}</FormHelperText>}
                            </Box>
                        </Box>

                        <Grid container spacing={2} p={2}>
                            {files.map((file, index) => (
                            <Grid item key={index} display="flex" flexDirection="column" alignItems="center">
                                <Box
                                component="iframe"
                                src={URL.createObjectURL(file)} // Use createObjectURL to generate a temporary URL for preview
                                title={`Uploaded PDF ${index}`}
                                style={{ width: '100px', height: '100px', border: 'none' }}
                                />
                                <Typography variant="body2" noWrap>
                                {file.name}
                                </Typography>
                                <IconButton onClick={() => handleRemoveFile(index)} aria-label="delete" size="small">
                                <DeleteIcon />
                                </IconButton>
                            </Grid>
                            ))}
                            {files.length === 0 && (
                            <Grid item>
                                <Typography variant="body1">No files selected</Typography>
                            </Grid>
                            )}
                        </Grid>
                        </Grid>
                        </>}
                        
                       {hideFields ? (
                        <></>
                       ) : (<Grid item xs={12} md={2} xl={6} >
                            <div className={`m-2`}>
                            <Button type="submit" variant='contained' onClick={handleSubmit}  >Save</Button>
                            </div>
                        </Grid>)}
                       
                        </Grid>

                       {hideFields ? (<div className={`m-2 p-2 float-right`}>
                            <Button type="submit" variant='contained' onClick={handleSubmit}  >Save</Button>
                        </div>) : (<></>)}
                </Box>
                
            </CardContent>
            </Card>
        </Box>

        <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
        </Snackbar>
        </>
    );
}

export default EstimationDetailsPageComponent;