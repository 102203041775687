import React, { useState , useEffect, useRef} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BiBoilerLeadListComponent from './BiBoilerLeadList';
import { GET_BI_LIST } from '../../constants';
import Loader from '../reusable Component/Loader';
import axios from 'axios';
import { Button, Grid, useMediaQuery, useTheme} from '@mui/material';
import BiClosedTableComponent from './BiClosedTableComponent';
import BiAllTableComponent from './AllStatusTableComponent';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid'; // Assuming you're using MUI DataGrid


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};


function BITraderListComponent() {
    const [value, setValue] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [loading, setLoading] = useState(false);

    const [row, setRow] = useState([]);
    const [rowClosed, setRowClosed] = useState([]);
    const [rowAll, setRowAll] = useState([]);
    const fileInputRef = useRef(null); // Added file input ref
    const columns = []; // You should define your columns

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const [runEffect,setRunEffect] = useState(false);

    //   useEffect(()=>{
    //     setLoading(true);
        
    //     fetchBiLeadList();
    //     },[runEffect]);
    
    //     const fetchBiLeadList = () =>{
    //       axios.get(`${GET_BI_LIST}?agentId=${empDetails.EmployeeId}`,{
    //         headers: {
    //           'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //         },
    //       })
    //       .then(response => {
    //           const {data} = response.data;
    //           handleOpenRowData(data);
    //           handleClosedRowData(data);
    //           handleAllRowData(data);
    //           setLoading(false);
    //       }).catch(error => {
    //       });
    //     };


    useEffect(() => {
        setLoading(true);
        
        fetchBiLeadList();
      }, [runEffect]);
      
      const fetchBiLeadList = () => {
        axios.get(`${GET_BI_LIST}?agentId=${empDetails.EmployeeId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        })
        .then(response => {
          const { data } = response.data;
          handleOpenRowData(data);
          handleClosedRowData(data);
          handleAllRowData(data);
          
          // Set timeout to wait for 200ms before setting loading to false
          setTimeout(() => {
            setLoading(false);
          }, 200);
        })
        .catch(error => {
          setLoading(false); // In case of error, stop the loader immediately
        });
      };



        const handleOpenRowData = (data) =>{
            // Filter the data to include only those records where status is 'open'
            const filteredData = data.filter((rd) => rd.status === 'open');
            const transformedRows = [];
            var a = {};
            filteredData.map((rd, index)=>{
                a = {
                "id": index + 1 ,
                "SNo": index + 1,
                "Zone_Name": rd.Zone_Name,
                "Region_Description": rd.Region_Description,
                "Branch": rd.Branch,
                "Branch_Name": rd.Branch_Name,
                "Farmer_Name": rd.Farmer_Name,
                "Lot_Number": rd.Lot_Number,
                "Vendor": rd.Vendor,
                "Shed_Type": rd.Shed_Type,
                "Hatch_Date": rd.Hatch_Date,
                "Mean_Age": rd.Mean_Age,
                "Placed_Qty": rd.Placed_Qty,
                "Lifted_Birds": rd.Lifted_Birds,
                "Bal_Birds": rd.Bal_Birds,
                "Total_Mortality": rd.Total_Mortality,
                "Total_Mortality_Percentage": rd.Total_Mortality_Percentage,
                "FCR": rd.FCR,
                "LR": rd.LR,
                "CA": rd.CA,
                "Age_SAP": rd.Age_SAP,
                "Diff": rd.Diff,
                "first_Week_M": rd.first_Week_M,
                "First_Week_Mortality_Percentage": rd.First_Week_Mortality_Percentage,
                "Lifting_EA": rd.Lifting_EA,
                "Lift_Percentage": rd.Lift_Percentage,
                "ABWT": rd.ABWT,
                "BWT_Age": rd.BWT_Age,
                "Feed_Cons": rd.Feed_Cons,
                "Avg_Lift_Wt": rd.Avg_Lift_Wt,
                "Prev_Grade": rd.Prev_Grade,
                "Mobile": rd.Mobile,
                "Line": rd.Line,
                "Hatchery_Name": rd.Hatchery_Name,
                "createdAt": rd.createdAt,
                "updatedAt": new Date(rd.updatedAt),
                "status": rd.status
            }
            transformedRows.push(a);
            })
            setRow(transformedRows);
        };

        const handleClosedRowData = (data) =>{
            // Filter the data to include only those records where status is 'closed'
            const filteredData = data.filter((rd) => rd.status === 'closed');
            const transformedRows = [];
            var a = {};
            filteredData.map((rd, index)=>{
                a = {
                "id": index + 1 ,
                "SNo": index + 1,
                "Zone_Name": rd.Zone_Name,
                "Region_Description": rd.Region_Description,
                "Branch": rd.Branch,
                "Branch_Name": rd.Branch_Name,
                "Farmer_Name": rd.Farmer_Name,
                "Lot_Number": rd.Lot_Number,
                "Vendor": rd.Vendor,
                "Shed_Type": rd.Shed_Type,
                "Hatch_Date": rd.Hatch_Date,
                "Mean_Age": rd.Mean_Age,
                "Placed_Qty": rd.Placed_Qty,
                "Lifted_Birds": rd.Lifted_Birds,
                "Bal_Birds": rd.Bal_Birds,
                "Total_Mortality": rd.Total_Mortality,
                "Total_Mortality_Percentage": rd.Total_Mortality_Percentage,
                "FCR": rd.FCR,
                "LR": rd.LR,
                "CA": rd.CA,
                "Age_SAP": rd.Age_SAP,
                "Diff": rd.Diff,
                "first_Week_M": rd.first_Week_M,
                "First_Week_Mortality_Percentage": rd.First_Week_Mortality_Percentage,
                "Lifting_EA": rd.Lifting_EA,
                "Lift_Percentage": rd.Lift_Percentage,
                "ABWT": rd.ABWT,
                "BWT_Age": rd.BWT_Age,
                "Feed_Cons": rd.Feed_Cons,
                "Avg_Lift_Wt": rd.Avg_Lift_Wt,
                "Prev_Grade": rd.Prev_Grade,
                "Mobile": rd.Mobile,
                "Line": rd.Line,
                "Hatchery_Name": rd.Hatchery_Name,
                "createdAt": rd.createdAt,
                "updatedAt": new Date(rd.updatedAt),
                "status": rd.status
            }
            transformedRows.push(a);
            })
            setRowClosed(transformedRows);
        };
       
    
        const handleBulkUploadClick = () => {
            fileInputRef.current.click();
        };
    
        const handleFileChange = (event) => {
            const file = event.target.files[0];
            console.log('File uploaded:', file);
            // Add file upload logic here
        };

        const handleAllRowData = (data) =>{
        
            const transformedRows = [];
            var a = {};
            data.map((rd, index)=>{
                a = {
                "id": index + 1 ,
                "SNo": index + 1,
                "Zone_Name": rd.Zone_Name,
                "Region_Description": rd.Region_Description,
                "Branch": rd.Branch,
                "Branch_Name": rd.Branch_Name,
                "Farmer_Name": rd.Farmer_Name,
                "Lot_Number": rd.Lot_Number,
                "Vendor": rd.Vendor,
                "Shed_Type": rd.Shed_Type,
                "Hatch_Date": rd.Hatch_Date,
                "Mean_Age": rd.Mean_Age,
                "Placed_Qty": rd.Placed_Qty,
                "Lifted_Birds": rd.Lifted_Birds,
                "Bal_Birds": rd.Bal_Birds,
                "Total_Mortality": rd.Total_Mortality,
                "Total_Mortality_Percentage": rd.Total_Mortality_Percentage,
                "FCR": rd.FCR,
                "LR": rd.LR,
                "CA": rd.CA,
                "Age_SAP": rd.Age_SAP,
                "Diff": rd.Diff,
                "first_Week_M": rd.first_Week_M,
                "First_Week_Mortality_Percentage": rd.First_Week_Mortality_Percentage,
                "Lifting_EA": rd.Lifting_EA,
                "Lift_Percentage": rd.Lift_Percentage,
                "ABWT": rd.ABWT,
                "BWT_Age": rd.BWT_Age,
                "Feed_Cons": rd.Feed_Cons,
                "Avg_Lift_Wt": rd.Avg_Lift_Wt,
                "Prev_Grade": rd.Prev_Grade,
                "Mobile": rd.Mobile,
                "Line": rd.Line,
                "Hatchery_Name": rd.Hatchery_Name,
                "createdAt": rd.createdAt,
                "updatedAt": new Date(rd.updatedAt),
                "status": rd.status
            }
            transformedRows.push(a);
            })
            setRowAll(transformedRows);
        };

        const handleTraderClick = () => {
            navigate('/biTraderList');
        };

        const handleInHouseClick = () =>{
            navigate('/biLead');
          
        };

    return (
        <>        
        {loading && <Loader open={loading} />}
        
        <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={2}>
                    <Button variant="contained" color="primary" onClick={handleInHouseClick}>
                        DayOp
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleTraderClick}>
                        BI
                    </Button>
                </Box>

                <div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                </div>

                {/* <Button variant="contained" onClick={handleBulkUploadClick}>
                    Bulk Upload
                </Button> */}
            </Box>
            <Box sx={{
                height: 'auto',
                width: '100%',
                maxWidth: '100vw',
                overflowX: 'auto',
            }}>
                
            </Box>

        <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        >
            <Tab label={`Open (${row.length})`} id="tab-0" icon={<NewReleasesIcon />} iconPosition="start" aria-controls="tabpanel-1" />
            <Tab label={`Closed (${rowClosed.length})`} id="tab-1" icon={<VerifiedIcon />} iconPosition="start" aria-controls="tabpanel-1" />
            <Tab label={`All (${rowAll.length})`} id="tab-2" aria-controls="tabpanel-2" />
    
        </Tabs>
        <TabPanel value={value} index={0}>
            <BiBoilerLeadListComponent rowOpen={row} setRunEffect={setRunEffect}></BiBoilerLeadListComponent>
        </TabPanel>

        <TabPanel value={value} index={1}>
            <BiClosedTableComponent rowClosed={rowClosed} setRunEffect={setRunEffect}></BiClosedTableComponent>
        </TabPanel>

        <TabPanel value={value} index={2}>
            <BiAllTableComponent rowAll={rowAll} setRunEffect={setRunEffect}></BiAllTableComponent>
        </TabPanel>
        
        
        </>
      );
    }
    
export default BITraderListComponent;