import { useEffect, useState } from 'react';
import axios from 'axios';
import { LEAD_ANALYTICS, LEAD_MEETING_COUNT_FILTER } from '../../constants';
import RegionWiseListComponent from './RegionWiseListComponent';
import BdmWiseListComponent from './BdmWiseListComponet';
import { FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import Loader from '../reusable Component/Loader';
import RegionWiseActionListComponent from './RegionWiseActionList';
import BdmWiseActionListComponent from './BdmWiseActionList';

function AnalyticsActionCountTable({ filter }) {
  
  const [regionWiseList, setRegionWiseList] = useState([]);
  const [bdmWiseList, setBdmWiseList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch data based on the selected filter (region or bdm)
  const fetchData = (filter) => {
    setLoading(true);
    axios.get(`${LEAD_MEETING_COUNT_FILTER}?filter=${filter}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then(response => {
        handleRowData(response.data, filter);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  // Handle transforming and assigning row data
  const handleRowData = (rowData, filter) => {
    const transformedRows = rowData.map((rd, index) => ({
      id: index + 1,
      SNo: index + 1,
      ...(filter === 'region' 
        ? { region_name: rd.region_name } 
        : { bdm_name: rd.bdm_name }), // Ensure the correct column is included
        meeting_count: rd.meeting_count,
    }));

    // Update the state based on the filter
    if (filter === 'region') {
      setRegionWiseList(transformedRows);
    } else {
      setBdmWiseList(transformedRows);
    }
  };

  // Fetch data on initial load
  useEffect(() => {
    fetchData(filter); // Fetch data for the initial view (default is 'region')
  }, [filter]); // Re-fetch data when the view changes

  return (
          <>
          {loading && <Loader open={loading} />}
            

            {filter === 'region' ? (
                <RegionWiseActionListComponent regionWiseList={regionWiseList} />
            ) : (
                <BdmWiseActionListComponent bdmWiseList={bdmWiseList} />
            )}

            </>
  );
}

export default AnalyticsActionCountTable;
