import * as React from "react";
import { useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import loginStyles from "./loginStyles.module.css";
import TextField from "@mui/material/TextField";
import ib_logo from "../../icons/IB_logo.png";
import ib_logo1 from "../../icons/ABIS_logo.png";
import { Divider, Typography, Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { LoginContext } from "./LoginContext";
import axios from "axios";
import { AUTH_API, GET_AUTH_TOKEN } from "../../constants";
import { connect } from "react-redux";
import { loginSuccess, loginFailure } from "../../Actions/LoginAction";
import { VisibilityOffOutlined } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { SessionContext } from "../../SessionContext";

const LoginComponent = ({
  onLogin,
  isAuthenticated,
  error,
  loginSuccess,
  loginFailure,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const { login, isLoggedIn, logout } = useContext(LoginContext);

  const { setSessionActive } = useContext(SessionContext);

  const handleLogin = () => {
    const requestBody = {
      EmployeeId: username,
      EmployeePassword: password,
    };
    // navigate('/dashboard');
    // Make POST request using Axios
    axios
      .post(AUTH_API, requestBody)
      .then((response) => {
        // Handle successful authentication
        console.log("Login successful:", response.data);
        if (response.data.status == "200") {
          const { employee } = response.data;
          login(response.data.employee.EmployeeRole, response.data.employee);
          loginSuccess(employee);
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem("empRole", response.data.employee.EmployeeRole);
          setSessionActive(true); // Update context state
          localStorage.setItem("sessionActive", "true"); // Persist state
          setSeverity("success");
          setMessage("Login Successful!!");
          setOpenSnackbar(true);
          if (response.data.employee.EmployeeRole == 6) {
            navigate("/estimateGeneration");
          } else if (response.data.employee.EmployeeRole == 2 || response.data.employee.EmployeeRole == 3) {
            navigate("/bdmtodo");
          } else {
            navigate("/dashboard");
          }

          

           // Fetch the additional AuthToken
          fetchAuthToken();

        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          loginFailure("Invalid username or password.");
          setSeverity("error");
          setMessage(error.response.data.message);
          setOpenSnackbar(true);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log('Response data:', error.response.data);
          // console.log('Response status:', error.response.status);
          // console.log('Response headers:', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Request:", error.request);
          setSeverity("error");
          setMessage("Internal Server Error");
          setOpenSnackbar(true);
        }
      });
  };

  // CORS Proxy URL (for development/testing only)
  const corsProxyUrl = 'https://cors-anywhere.herokuapp.com/';

  const fetchAuthToken = () => {
    const requestBody = {
      username: "ABIS123",
      password: "ABIS@123"
    };
    
    axios
      .post(GET_AUTH_TOKEN)
      .then((response) => {
        console.log("AuthToken retrieved:", response.data.token);
        localStorage.setItem("clickToCallToken", response.data.token.idToken); // Save the idToken in local storage
      })
      .catch((error) => {
        console.error("Error fetching AuthToken:", error);
      });
  };

  const logOuT = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div
        className={`h-screen ${loginStyles.background_color} place-content-center`}
      >
        <div className={`flex place-content-center`}>
          <Stack spacing={3} direction="row">
            {/* <div className={`place-content-center w-auto hidden sm:block`}>
            <img
                src={group}
                loading="lazy"
                alt='onCall'
                className={`w-auto h-72`}
            />
            </div> */}
            <Card
              className={`w-auto`}
              sx={{ "& .MuiPaper-root": { borderRadius: 0 } }}
            >
              <CardContent>
                <Stack spacing={3} direction="column" className={`py-4 px-4`}>
                  <div className={`flex place-content-center h-16 w-auto`}>
                    <img
                      src={ib_logo}
                      loading="lazy"
                      alt="onCall"
                      className={`w-16`}
                    />
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      className={`px-2 ${loginStyles.logo_divider}`}
                      flexItem
                    />
                    <img
                      src={ib_logo1}
                      loading="lazy"
                      alt="onCall"
                      className={`w-28 px-2 ${loginStyles.icon_height}`}
                    />
                  </div>

                  <Typography variant="h5" className={`text-center`}>
                    {" "}
                    Welcome to CRM
                  </Typography>

                  <TextField
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    variant="filled"
                    size="small"
                    className={`w-auto`}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: 0,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={`-ml-1`}>
                          <PersonIcon className={`${loginStyles.icon_size}`} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Password"
                    id="filled-size-normal"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="filled"
                    size="small"
                    className={`w-auto`}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: 0,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={`-ml-1`}>
                          <LockIcon className={`${loginStyles.icon_size}`} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" className={`-ml-1`}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Link
                    className={`text-end`}
                    variant="caption"
                    style={{ marginTop: "12px" }}
                  >
                    Forgot Password
                  </Link>

                  <Button
                    variant="contained"
                    className={`w-auto ${loginStyles.login_button}`}
                    onClick={handleLogin}
                    sx={{
                      "& .MuiButtonBase-root": {
                        borderRadius: 0,
                      },
                    }}
                  >
                    Login
                  </Button>

                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert
                      onClose={handleCloseSnackbar}
                      severity={severity}
                      sx={{ width: "100%" }}
                    >
                      {message}
                    </Alert>
                  </Snackbar>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
});

const mapDispatchToProps = {
  loginSuccess,
  loginFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
