import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Divider, Stack, Grid, Button } from "@mui/material";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Card,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  ENQUIRY_TYPE,
  PROJECT,
  CATEGORY,
  LEAD_SOURCE,
  CLOSED_SUB_CATEGORY,
  COLD_SUB_CATEGORY,
  HOT_SUB_CATEGORY,
  PENDING_SUB_CATEGORY,
  WARM_SUB_CATEGORY,
  CREATE_LEAD_API,
  STATE_API,
  CALL_TYPE,
  TITLE,
  BDM_LIST,
  CALL_STATUS,
  BDM_REMARK_UPDATE,
  GET_REMARK_LIST,
  UPDATE_STATUS_AGENT,
  PAGE_SECTIONS,
  GET_MEETING,
  GET_SITE_VISIT,
  GET_ESTIMATION,
  ON_CALL_DISCUSSION_BDM,
} from "../../constants";
import bdmFormStyles from "./bdmFormStyles.module.css";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import axios from "axios";
import Typography from "@mui/material/Typography";

function OnCallFormComponent({
  roleType,
  setSeverity,
  setMessage,
  setOpenSnackbar,
  setRunEffect,
  leadDetails,
}) {
  const empDetails = JSON.parse(localStorage.getItem("userData"));

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setSubCategory("");
    feildsShow(value);
    if (value.trim() === "") {
      setCategory(value);
      setCategoryError("State is required.");
    } else {
      setCategory(value);
      setCategoryError("");
    }
  };

  const [showField, setShowField] = useState(false);
  const [enableField, setEnableField] = useState(false);

  const feildsShow = (cat) => {
    if (cat == "hot" || cat == "warm") {
      setEnableField(true);
    } else {
      setEnableField(false);
    }
    if (cat == "pending") {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };

  const [subCategory, setSubCategory] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const handleSubCategoryChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setSubCategory(value);
      setSubCategoryError("Sub-Category is required.");
    } else {
      setSubCategory(value);
      setSubCategoryError("");
    }
  };

  const [followUpDate, setFollowUpDate] = useState(undefined);
  const [closureMonth, setClosureMonth] = useState(undefined);

  const handleFollowupDateTime = (e) => {
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Function to pad numbers with leading zeros
    const pad = (num) => (num < 10 ? "0" + num : num);

    // Extract date components
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Formatted date and time string
    const formattedDate = `${year}-${month}-${day}`;
    setFollowUpDate(formattedDate);
    // console.log(formattedDate);
  };

  const handleChangeExpectedClosureMonth = (e) => {
    const dateString = e.$d;

    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract month and year
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Formatted string
    const formattedDate = `${monthName} ${year}`;

    setClosureMonth(formattedDate);
  };

  const [bdmRemark, setBdmRemark] = useState("");
  const [bdmRemarkError, setBdmRemarkError] = useState("");

  const [agentRemark, setAgentRemark] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (category.trim() === "") {
      setCategoryError("Category is required.");
      isValid = false;
    }

    if (subCategory.trim() === "") {
      setSubCategoryError("Sub-Category is required.");
      isValid = false;
    }

    if (roleType === "BDM") {
      if (bdmRemark.trim() === "") {
        setBdmRemarkError("Remark is required.");
        isValid = false;
      }
    } else {
      if (agentRemark.trim() === "") {
        setBdmRemarkError("Remark is required.");
        isValid = false;
      }
    }

    if (isValid) {
      if (roleType == "BDM") {
        const fromData = {
          follow_up_date: followUpDate,
          category: category,
          sub_category: subCategory,
          LeadDetailId: leadDetails.id,
          BDMId: empDetails.EmployeeId,
          closure_month: closureMonth,
          remark: bdmRemark,
        };

        axios
          .post(ON_CALL_DISCUSSION_BDM, fromData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setSeverity("success");
            setMessage(response.data.message);
            setOpenSnackbar(true);
            setBdmRemark("");
            setCategory("");
            setSubCategory("");
            setFollowUpDate(undefined);
            setClosureMonth(undefined);
            setEnableField(false);
            setShowField(false);

            setRunEffect(true);
          })
          .catch((error) => {
            if (error.response) {
              setSeverity("error");
              setMessage(error.response.data.message);
              setOpenSnackbar(true);
              setRunEffect(false);
            } else if (error.request) {
              // The request was made but no response was received

              setSeverity("error");
              setMessage("Internal Server Error.");
              setOpenSnackbar(true);
              setRunEffect(false);
            }
          });
      } else {
        const formData = {
          follow_up_date: followUpDate,
          category: category,
          sub_category: subCategory,
          leadDetailId: leadDetails.id,
          agentId: empDetails.EmployeeId,
          remark: agentRemark,
          closure_month: closureMonth,
        };
        console.log("formData", formData);
        axios
          .post(UPDATE_STATUS_AGENT, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            setSeverity("success");
            setMessage(response.data.message);
            setOpenSnackbar(true);
            setAgentRemark("");
            setCategory("");
            setSubCategory("");
            setFollowUpDate(undefined);
            setClosureMonth(undefined);
            setEnableField(false);
            setShowField(false);
            setRunEffect(true);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                setSeverity("error");
                setMessage("404 Not Found.");
                setOpenSnackbar(true);
                setRunEffect(false);
              } else {
                setSeverity("error");
                setMessage(error.response.data.message);
                setOpenSnackbar(true);
                setRunEffect(false);
              }
            } else if (error.request) {
              // The request was made but no response was received
              console.log("Request:", error.request);
              setSeverity("error");
              setMessage("Internal Server Error");
              setOpenSnackbar(true);
              setRunEffect(false);
            }
          });
      }
    } else {
      console.log("Form submission blocked due to validation errors.");
      setSeverity("error");
      setMessage("Please check for missing fields.");
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Card className={`${bdmFormStyles.form_card_style} mt-2`}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              sx={{ display: "flex", placeContent: "center" }}
            >
              <TextField
                id="outlined-select-currency"
                select
                label="Category"
                value={category}
                onChange={handleCategoryChange}
                // helperText="Please select your currency"
                size="small"
                required
                error={!!categoryError}
                helperText={categoryError}
                sx={{
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.65rem",
                    marginLeft: "0",
                  },
                }}
              >
                {CATEGORY.map((c) => (
                  <MenuItem
                    value={c.value}
                    sx={{
                      borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {c.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              sx={{ display: "flex", placeContent: "center" }}
            >
              <TextField
                id="outlined-select-currency"
                select
                label="Sub-Category"
                value={subCategory}
                onChange={handleSubCategoryChange}
                size="small"
                required
                error={!!subCategoryError}
                helperText={subCategoryError}
                sx={{
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.65rem",
                    marginLeft: "0",
                  },
                }}
              >
                {category.toLowerCase() == "hot" &&
                  HOT_SUB_CATEGORY.map((h) => (
                    <MenuItem
                      value={h.label}
                      sx={{
                        borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
                {category.toLowerCase() == "warm" &&
                  WARM_SUB_CATEGORY.map((h) => (
                    <MenuItem
                      value={h.label}
                      sx={{
                        borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
                {category.toLowerCase() == "cold" &&
                  COLD_SUB_CATEGORY.map((h) => (
                    <MenuItem
                      value={h.label}
                      sx={{
                        borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
                {category.toLowerCase() == "pending" &&
                  PENDING_SUB_CATEGORY.map((h) => (
                    <MenuItem
                      value={h.label}
                      sx={{
                        borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
                {category.toLowerCase() == "closed" &&
                  CLOSED_SUB_CATEGORY.map((h) => (
                    <MenuItem
                      value={h.label}
                      sx={{
                        borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      {h.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              sx={{ display: "flex", placeContent: "center" }}
            >
              {(enableField || showField) && (
                <DateTimePicker
                  label="Follow-up Date"
                  name="startDateTime"
                  onChange={handleFollowupDateTime}
                  disablePast
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              sx={{ display: "flex", placeContent: "center" }}
            >
              {enableField && (
                <DatePicker
                  label={"Expected Closure Month"}
                  views={["month", "year"]}
                  onChange={handleChangeExpectedClosureMonth}
                  disablePast
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              xl={6}
              sx={{ display: "flex", placeContent: "center" }}
            >
              <div className={`${leadFormStyles.fields_padding}`}>
                <TextField
                  id="outlined-multiline-static"
                  label="Remarks"
                  multiline
                  rows={4}
                  fullWidth
                  value={roleType === "BDM" ? bdmRemark : agentRemark}
                  onChange={(e) => {
                    if (e.target.value.length <= 250) {
                      if (roleType === "BDM") {
                        setBdmRemark(e.target.value);
                        setBdmRemarkError("");
                      } else {
                        setAgentRemark(e.target.value);
                        setBdmRemarkError("");
                      }
                    }
                  }}
                  inputProps={{
                    inputProps: {
                      maxLength: "250",
                    },
                  }}
                  style={{ resize: "both", overflow: "auto" }}
                  required
                  error={!!bdmRemarkError}
                  helperText={bdmRemarkError}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      fontSize: "0.65rem",
                      marginLeft: "0",
                    },
                  }}
                />
                {roleType == "BDM" ? (
                  <Typography
                    variant="body2"
                    color={bdmRemark.length > 250 ? "error" : "text.secondary"}
                    sx={{ marginLeft: "7px" }}
                  >
                    {bdmRemark.length}/500
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color={
                      agentRemark.length > 250 ? "error" : "text.secondary"
                    }
                    sx={{ marginLeft: "7px" }}
                  >
                    {agentRemark.length}/250
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>

          <div className={`float-right p-2`}>
            <Button type="submit" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Box>
      </Card>
    </>
  );
}

export default OnCallFormComponent;
