import React, { useMemo, useEffect } from "react";
import { Grid, TextField, Button, Box, Select, MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import dayjs from "dayjs"; // Ensure dayjs is imported

const FilterComponent = ({
  filters,
  setFilters,
  bdmStatisticsList,
  applyFilters,
}) => {
  const today = dayjs().startOf("day");

  // Predefined date ranges
  const predefinedRanges = [
    { label: "Today", startDate: today, endDate: today },
    {
      label: "Yesterday",
      startDate: today.subtract(1, "day"),
      endDate: today.subtract(1, "day"),
    },
    {
      label: "Last 7 Days",
      startDate: today.subtract(6, "day"),
      endDate: today,
    },
    { label: "Custom", startDate: null, endDate: null },
  ];

  const [filtersData, setFiltersData] = useState({
    selectedRange: predefinedRanges[0], // Default to "Today"
    customStartDate: filters.startDate ? dayjs(filters.startDate) : null,
    customEndDate: filters.endDate ? dayjs(filters.endDate) : null,
    bdmName: filters.bdmName || [],
  });

  const bdmNameOptions = useMemo(() => {
    return bdmStatisticsList.map((item) => ({
      label: item.bdm_name,
      value: item.bdm_id,
    }));
  }, [bdmStatisticsList]);

  const handleInputChange = (key, value) => {
    setFiltersData((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleCustomDateChange = (key, value) => {
    setFiltersData((prevFilters) => ({
      ...prevFilters,
      [key]: dayjs(value),
    }));
  };

  const handleApply = () => {
    const { selectedRange, customStartDate, customEndDate } = filtersData;

    let startDate, endDate;

    if (selectedRange.label === "Custom") {
      startDate = customStartDate?.isValid() ? customStartDate.toDate() : null;
      endDate = customEndDate?.isValid() ? customEndDate.toDate() : null;
    } else {
      startDate = selectedRange.startDate.toDate();
      endDate = selectedRange.endDate.toDate();
    }

    if (startDate && endDate) {
      setFilters({
        startDate,
        endDate,
        bdmName: filtersData.bdmName,
      });
    } else {
      console.error("Please ensure the custom date range is valid.");
    }
  };

  const handleClear = () => {
    setFilters({
      startDate: today.toDate(),
      endDate: today.toDate(),
      bdmName: [],
    });
    setFiltersData({
      selectedRange: predefinedRanges[0],
      customStartDate: null,
      customEndDate: null,
      bdmName: [],
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f0f4f8",
        padding: 1,
        borderRadius: 2,
        boxShadow: 3,
        marginBottom: 3,
      }}
    >
      <Grid container spacing={2}>
        {/* Predefined Ranges */}
        <Grid item xs={12} sm={3}>
          <Select
            value={filtersData.selectedRange.label}
            onChange={(e) =>
              handleInputChange(
                "selectedRange",
                predefinedRanges.find((range) => range.label === e.target.value)
              )
            }
            fullWidth
            size="small"
          >
            {predefinedRanges.map((range) => (
              <MenuItem key={range.label} value={range.label}>
                {range.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Custom Date Inputs */}
        {filtersData.selectedRange.label === "Custom" && (
          <>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  filtersData.customStartDate
                    ? dayjs(filtersData.customStartDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) =>
                  handleCustomDateChange("customStartDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="End Date"
                type="date"
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  filtersData.customEndDate
                    ? dayjs(filtersData.customEndDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) =>
                  handleCustomDateChange("customEndDate", e.target.value)
                }
              />
            </Grid>
          </>
        )}

        {/* BDM Name Autocomplete */}
        <Grid item xs={12} sm={3}>
          <Autocomplete
            multiple
            options={bdmNameOptions}
            value={bdmNameOptions.filter((option) =>
              filtersData.bdmName.includes(option.value)
            )}
            onChange={(event, newValue) =>
              handleInputChange(
                "bdmName",
                newValue.map((item) => item.value)
              )
            }
            renderInput={(params) => (
              <TextField {...params} label="BDM Name" size="small" fullWidth />
            )}
            getOptionLabel={(option) => option.label}
          />
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12} sm={3}>
          <Box sx={{ display: "flex" }}>
            <Button
              onClick={handleClear}
              variant="outlined"
              color="secondary"
              sx={{ marginRight: 2 }}
            >
              Clear
            </Button>
            <Button onClick={handleApply} variant="contained" color="primary">
              Apply
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterComponent;
