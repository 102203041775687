import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import dashboardStyles from "./dashboardStyles.module.css";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { AUDIT_NEW_FARMER_CREATE } from "../../constants";

function NewUserAuditFormComponent({
  setOpenSnackbar,
  setSeverity,
  setMessage,
  setalertTitle,
  setnewForm,
}) {
  const [lotDetails, setLotDetails] = useState([]);

  const handleChange = (field, value) => {
    setLotDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const [mobileNumberError, setMobileNumberError] = useState("");
  const [farmerNameError, setFarmerNameError] = useState("");

  const handleSubmit = () => {
    let isValid = true;

    if (!lotDetails.Mobile) {
      setMobileNumberError("Mobile Number is required.");
      isValid = false;
    }

    if(!lotDetails.Farmer_Name){
      setFarmerNameError("Name is required.");
      isValid = false;
    }

    if (isValid) {
    axios
      .post(AUDIT_NEW_FARMER_CREATE, lotDetails, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        console.log("here", response);

        setSeverity("success");
        setMessage(response.data.message);
        setOpenSnackbar(true);

        setnewForm(false);
      })
      .catch((error) => {});

    }else {
      console.log("Form submission blocked due to validation errors.");
      setSeverity("error");
      setMessage("Please check for missing fields.");
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setLotDetails([]); // Reset lot details to initial values
    setMobileNumberError(""); // Clear mobile number error
    setFarmerNameError(""); // Clear farmer name error
  };

  return (
    <>
      {/* leadDetails Form */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${dashboardStyles.accordian_heading} h-auto`}
        >
          <Typography>Lot Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Card>
              <Grid container spacing={2} p={3}>
                {/* Basic Details */}
                {/* <Grid item xs={12}> */}
                {/* <Typography variant="h6" gutterBottom>
                  Basic Details
                </Typography> */}
                {/* </Grid> */}
                {/* <Grid item xs={12} md={6}>
                <TextField
                  label="Lot Number"
                  variant="outlined"
                  value={lotDetails.Lot_Number}
                  onChange={(e) => handleChange("Lot_Number", e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Farmer Name"
                    variant="outlined"
                    value={lotDetails.Farmer_Name}
                    onChange={(e) =>
                      handleChange("Farmer_Name", e.target.value)
                    }
                    fullWidth
                    size="small"
                    required
                    error={!!farmerNameError}
                    helperText={farmerNameError}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mobile Number"
                    variant="outlined"
                    value={lotDetails.Mobile}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("Mobile", e.target.value);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    required
                    error={!!mobileNumberError}
                    helperText={mobileNumberError}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Zone Name"
                    variant="outlined"
                    value={lotDetails.Zone_Name}
                    onChange={(e) => handleChange("Zone_Name", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Branch Name"
                    variant="outlined"
                    value={lotDetails.Branch_Name}
                    onChange={(e) =>
                      handleChange("Branch_Name", e.target.value)
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Vendor"
                    variant="outlined"
                    value={lotDetails.Vendor}
                    onChange={(e) => handleChange("Vendor", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Shed Type"
                    variant="outlined"
                    value={lotDetails.Shed_Type}
                    onChange={(e) => handleChange("Shed_Type", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Hatch Date"
                    variant="outlined"
                    value={lotDetails.Hatch_Date}
                    onChange={(e) => handleChange("Hatch_Date", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>

                {/* Mortality Details */}
                {/* <Grid item xs={12}> */}
                {/* <Typography variant="h6" gutterBottom>
                  Mortality Details
                </Typography> */}
                {/* </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Week Mortality Quantity"
                    variant="outlined"
                    value={lotDetails.first_Week_M}
                    onChange={(e) =>
                      handleChange("first_Week_M", e.target.value)
                    }
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Week Mortality Percentage"
                    variant="outlined"
                    value={lotDetails.First_Week_Mortality_Percentage}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange(
                          "First_Week_Mortality_Percentage",
                          e.target.value
                        );
                      }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Total Mortality Quantity"
                    variant="outlined"
                    value={lotDetails.Total_Mortality}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("Total_Mortality", e.target.value);
                      }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Total Mortality Percentage"
                    variant="outlined"
                    value={lotDetails.Total_Mortality_Percentage}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange(
                          "Total_Mortality_Percentage",
                          e.target.value
                        );
                      }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                {/* Weight and Feed Details */}
                {/* <Grid item xs={12}> */}
                {/* <Typography variant="h6" gutterBottom>
                  Weight and Feed Details
                </Typography> */}
                {/* </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Average Lifted Weight"
                    variant="outlined"
                    value={lotDetails.Avg_Lift_Wt}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("Avg_Lift_Wt", e.target.value);
                      }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Balance Birds"
                    variant="outlined"
                    value={lotDetails.Bal_Birds}
                    onChange={(e) => handleChange("Bal_Birds", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Average Body Weight"
                    variant="outlined"
                    value={lotDetails.ABWT}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("ABWT", e.target.value);
                      }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Feed Cons"
                    variant="outlined"
                    value={lotDetails.Feed_Cons}
                    onChange={(e) => handleChange("Feed_Cons", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="FCR"
                    variant="outlined"
                    value={lotDetails.FCR}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("FCR", e.target.value);
                      }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                {/* Other Details */}
                {/* <Grid item xs={12}> */}
                {/* <Typography variant="h6" gutterBottom>
                  Other Details
                </Typography> */}
                {/* </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Line"
                    variant="outlined"
                    value={lotDetails.Line}
                    onChange={(e) => handleChange("Line", e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Hatchery Name"
                    variant="outlined"
                    value={lotDetails.Hatchery_Name}
                    onChange={(e) =>
                      handleChange("Hatchery_Name", e.target.value)
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
              </Grid>
            </Card>

            {/* Remark Form */}
            <Card sx={{ marginTop: "10px" }}>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Lot Number"
                    id="outlined-size-small"
                    name="Lot_Number"
                    value={lotDetails.Lot_Number}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("Lot_Number", e.target.value);
                      }
                    }}
                    size="small"
                    fullWidth
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Chicks Housed"
                    id="outlined-size-small"
                    name="CH"
                    value={lotDetails.CH}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("CH", e.target.value);
                      }
                    }}
                    size="small"
                    fullWidth
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mean Age"
                    id="outlined-size-small"
                    name="AGE"
                    value={lotDetails.AGE}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("AGE", e.target.value);
                      }
                    }}
                    size="small"
                    fullWidth
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Body Weight"
                    id="outlined-size-small"
                    name="BWT"
                    value={lotDetails.BWT}
                    onChange={(e) => {
                      if (/^\d?(\.\d{0,3})?$/.test(e.target.value)) {
                        handleChange("BWT", e.target.value);
                      }
                    }}
                    size="small"
                    fullWidth
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "\\d?(\\.\\d{0,3})?",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mortality Quantity"
                    id="outlined-size-small"
                    name="M_QTY"
                    value={lotDetails.M_QTY}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange("M_QTY", e.target.value);
                      }
                    }}
                    size="small"
                    fullWidth
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Reason"
                    id="outlined-size-small"
                    name="REASON"
                    multiline
                    rows={4}
                    value={lotDetails.REASON}
                    onChange={(e) => {
                      handleChange("REASON", e.target.value);
                    }}
                    size="small"
                    fullWidth
                    style={{ resize: "both", overflow: "auto" }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        top: "0", // Adjust to align the label properly
                        "&.MuiInputLabel-shrink": {
                          top: "6px", // Ensure the label is fully visible when it shrinks
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Medicine"
                    id="outlined-size-small"
                    name="MED"
                    multiline
                    rows={4}
                    value={lotDetails.MED}
                    onChange={(e) => {
                      handleChange("MED", e.target.value);
                    }}
                    size="small"
                    fullWidth
                    style={{ resize: "both", overflow: "auto" }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        top: "0", // Adjust to align the label properly
                        "&.MuiInputLabel-shrink": {
                          top: "6px", // Ensure the label is fully visible when it shrinks
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Feed"
                    id="outlined-size-small"
                    name="FEED"
                    value={lotDetails.FEED}
                    onChange={(e) => {
                      handleChange("FEED", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Stock"
                    id="outlined-size-small"
                    name="STOCK"
                    value={lotDetails.STOCK}
                    onChange={(e) => {
                      handleChange("STOCK", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="IFFT In"
                    id="outlined-size-small"
                    name="IFFT_IN"
                    value={lotDetails.IFFT_IN}
                    onChange={(e) => {
                      handleChange("IFFT_IN", e.target.value);
                    }}
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                    style={{ resize: "both", overflow: "auto" }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        top: "0", // Adjust to align the label properly
                        "&.MuiInputLabel-shrink": {
                          top: "6px", // Ensure the label is fully visible when it shrinks
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="IFFT Out"
                    id="outlined-size-small"
                    name="IFFT_OUT"
                    value={lotDetails.IFFT_OUT}
                    onChange={(e) => {
                      handleChange("IFFT_OUT", e.target.value);
                    }}
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                    style={{ resize: "both", overflow: "auto" }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        top: "0", // Adjust to align the label properly
                        "&.MuiInputLabel-shrink": {
                          top: "6px", // Ensure the label is fully visible when it shrinks
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="LS Visit"
                    id="outlined-size-small"
                    name="LS_VISIT"
                    value={lotDetails.LS_VISIT}
                    onChange={(e) => {
                      handleChange("LS_VISIT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="BM Visit"
                    id="outlined-size-small"
                    name="BM_VISIT"
                    value={lotDetails.BM_VISIT}
                    onChange={(e) => {
                      handleChange("BM_VISIT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Daily Ent"
                    id="outlined-size-small"
                    name="DAILY_ENT"
                    value={lotDetails.DAILY_ENT}
                    onChange={(e) => {
                      handleChange("DAILY_ENT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Feed Ent"
                    id="outlined-size-small"
                    name="FEED_ENT"
                    value={lotDetails.FEED_ENT}
                    onChange={(e) => {
                      handleChange("FEED_ENT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mortality Ent"
                    id="outlined-size-small"
                    name="MORT_ENT"
                    value={lotDetails.MORT_ENT}
                    onChange={(e) => {
                      handleChange("MORT_ENT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="BWT Ent"
                    id="outlined-size-small"
                    name="BWT_ENT"
                    value={lotDetails.BWT_ENT}
                    onChange={(e) => {
                      handleChange("BWT_ENT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Medicine Ent"
                    id="outlined-size-small"
                    name="MED_ENT"
                    value={lotDetails.MED_ENT}
                    onChange={(e) => {
                      handleChange("MED_ENT", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                {/* <Grid item xs={12} md={6}>
                    <DesktopDatePicker
                        label="Select Date"
                        inputFormat="MM/DD/YYYY"
                        value={formValues.DATE}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                            //   helperText: 'MM/DD/YYYY',
                              clearable: true
                            },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: '100%'}}
                    />
                    </Grid> */}

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Remarks"
                    id="outlined-size-small"
                    name="REMARKS"
                    multiline
                    rows={4}
                    value={lotDetails.REMARKS}
                    onChange={(e) => {
                      handleChange("REMARKS", e.target.value);
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Box display="flex" justifyContent="flex-end" p={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  sx={{ marginRight: "5px" }}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Card>
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default NewUserAuditFormComponent;
