import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { POST_BI_REMARK } from '../../constants';
import dayjs from 'dayjs';

function StatusChangeDialog({ open, setOpen, formValues, setOpenSnackbar, setSeverity, setAlertTitle, resetForm, setRunEffect, empDetails }) {

    const storedLeadDetails = JSON.parse(localStorage.getItem('leadDetails'));
    console.log(storedLeadDetails)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false);
        }
    };

    const handleDialogClose = () => {
        setOpen(false); // Close dialog without taking action
    };

    const handleStatusChange = (newStatus) => {

        // Build the formValues object in raw JSON format

        const formData = {
            AgentId: empDetails.EmployeeId,
            medicine_type: formValues.medicine.medicine_type,   // Medicine Type
            disease_name: formValues.disease_name,
            medicine_with_dose: formValues.medicine.medicine_with_dose,   // Medicine List
            remarks: formValues.Remarks,                 // Remarks
            Lot_Number: String(storedLeadDetails.Lot_Number), // Ensure Lot_Number is a string
            follow_up_date: dayjs(formValues.Follow_up_date).format("YYYY-MM-DD")
        };

        console.log(formData);

        handleFormSubmit(formData);
    };

    const handleFormSubmit = (formData) => {
        axios.post(POST_BI_REMARK, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
            .then(response => {
                setSeverity('success');
                setAlertTitle(response.data.message);
                setOpenSnackbar(true);
                setRunEffect(true);
                resetForm();
                setOpen(false)
            }).catch(error => {
                setSeverity('error');
                setAlertTitle(error.message);
                setOpenSnackbar(true);
                resetForm();
            });
    }


    return (
        <Dialog open={open} onClose={handleDialogClose} fullScreen={fullScreen}>
            <DialogTitle>Change Record Status</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Do you want to change the status of this record to "Open" or "Closed"?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleStatusChange('Open')} color="primary">
                    Open
                </Button>
                <Button onClick={() => handleStatusChange('Closed')} color="primary">
                    Closed
                </Button>
                <Button onClick={handleDialogClose} color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default StatusChangeDialog;
