import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import call_enquiry from '../../icons/call_enquiry.png';
import meeting from '../../icons/meeting.png';
import site_visit from '../../icons/site_visit.png';
import estimate from '../../icons/estimate.png';
import { Grid } from '@mui/material';
import bdmDashboardStyles from '../BDM Assigned Page/bdmFormStyles.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';
import { 
    GET_ESTIMATIONS_SV, 
    GET_MEETING_SV, 
    GET_ON_CALL_DISCUSSION_SV, 
    GET_SITE_VISIT_SV 
} from '../../constants';

function BdmStatus({ dateFilter }) {
    const navigate = useNavigate();

    const handleOnCallLink = () => {
        navigate('/leadAction', { state: { tab: 0, dateFilter: dateFilter } });
    };

    const handleMeetingLink = () => {
        navigate('/leadAction', { state: { tab: 1, dateFilter: dateFilter } });
    };

    const handleSitevisitLink = () => {
        navigate('/leadAction', { state: { tab: 2, dateFilter: dateFilter } });
    };

    const handleEstimateLink = () => {
        navigate('/leadAction', { state: { tab: 3, dateFilter: dateFilter } });
    };

    const [callDiscussionCount, setCallDiscussionCount] = useState(0);
    const [meetingCount, setMeetingCount] = useState(0);
    const [siteVisitCount, setSiteVisitCount] = useState(0);
    const [estimationCount, setEstimationCount] = useState(0);

    useEffect(() => {
        const page = 1;
        const limit = 10;

        const fetchData = async (date) => {

            try {
                // Call each API separately and await their responses
                const callDiscussionResponse = await axios.get(
                    `${GET_ON_CALL_DISCUSSION_SV}?page=${page}&limit=${limit}&date=${date}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                    }
                );
                
                setCallDiscussionCount(callDiscussionResponse.data.callOnDiscussionCount);
        
                const meetingResponse = await axios.get(
                    `${GET_MEETING_SV}?page=${page}&limit=${limit}&date=${date}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                    }
                );
                
        
                const siteVisitResponse = await axios.get(
                    `${GET_SITE_VISIT_SV}?page=${page}&limit=${limit}&date=${date}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                    }
                );
                
        
                const estimationResponse = await axios.get(
                    `${GET_ESTIMATIONS_SV}?page=${page}&limit=${limit}&date=${date}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                    }
                );
                
        
                // Set counts based on individual responses
               
                setMeetingCount(meetingResponse.data.total_meetings);
                setSiteVisitCount(siteVisitResponse.data.total_site_visits);
                setEstimationCount(estimationResponse.data.total_estimations);
            } catch (error) {
                // Log error with more details
                console.error('Error fetching data:', error.message || error);
                console.error('Error details:', error.response ? error.response.data : error);
            }
        };

        if (Array.isArray(dateFilter)) {
            const [startDate, endDate] = dateFilter;
            fetchData(startDate);
        } else {
            fetchData(dateFilter);
        }
    }, [dateFilter]);

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Change breakpoint to 'sm', ""
    
    

    return (
        <>
            <h4 className={`font-bold text-lg mb-2`}>BDM Status</h4> {/* Smaller title */}
            <Grid container spacing={1} className={`mb-4`}>
                <Grid item xs={6} sm={3}>
                    <Paper className={`flex flex-col place-items-center justify-center h-full p-1`} onClick={handleOnCallLink}>
                        <div className={`w-full text-center`}>
                            <p className={`font-bold ${smallScreen ? 'text-xs' : 'text-sm'}`}>On-Call</p>
                            <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                            <p className={`font-bold text-xl`}>{callDiscussionCount}</p> {/* Smaller count font */}
                        </div>
                        <img src={call_enquiry} loading="lazy" alt='totalCalls' className={`w-1/4`} /> {/* Smaller image */}
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Paper className={`flex flex-col place-items-center justify-center h-full p-1`} onClick={handleMeetingLink}>
                        <div className={`w-full text-center`}>
                            <p className={`font-bold ${smallScreen ? 'text-xs' : 'text-sm'}`}>Meetings</p>
                            <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                            <p className={`font-bold text-xl`}>{meetingCount}</p>
                        </div>
                        <img src={meeting} loading="lazy" alt='totalMeetings' className={`w-1/4`} />
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Paper className={`flex flex-col place-items-center justify-center h-full p-1`} onClick={handleSitevisitLink}>
                        <div className={`w-full text-center`}>
                            <p className={`font-bold ${smallScreen ? 'text-xs' : 'text-sm'}`}>Site Visit</p>
                            <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                            <p className={`font-bold text-xl`}>{siteVisitCount}</p>
                        </div>
                        <img src={site_visit} loading="lazy" alt='totalSiteVisits' className={`w-1/4`} />
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Paper className={`flex flex-col place-items-center justify-center h-full p-1`} onClick={handleEstimateLink}>
                        <div className={`w-full text-center`}>
                            <p className={`font-bold ${smallScreen ? 'text-xs' : 'text-sm'}`}>Estimate</p>
                            <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                            <p className={`font-bold text-xl`}>{estimationCount}</p>
                        </div>
                        <img src={estimate} loading="lazy" alt='totalEstimations' className={`w-1/4`} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default BdmStatus;
