import * as React from "react";
import { useState, useContext, useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  MenuItem,
  FormControl,
  Select,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../LoginPage/LoginContext";
import axios from "axios";
import { BI_STATUS_UPDATE, BULK_UPLOAD_BI, GET_BI_LIST } from "../../constants";
import Button from "@mui/material/Button";
import biFormStyles from "./BiFromStyles.module.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { format, parseISO } from "date-fns";
import Loader from "../reusable Component/Loader";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";

function BiLeadListComponent({ rowOpen, setRunEffect }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const empDetails = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();

  const fileInputRef = useRef();

  const { setleadDetails } = useContext(LoginContext);

  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [message, setMessage] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openStatusDialog, setOpenStatusDialog] = useState(false);

  const IconCell = ({ row }) => (
    <IconButton
      style={{
        border: "1px solid",
        borderRadius: "4px",
        padding: "2px",
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
      color="primary"
      onClick={() => handleIconClick(row)}
    >
      <EditIcon fontSize="small" color="primary" />
    </IconButton>
  );

  const handleIconClick = (row) => {
    console.log(row);
    setleadDetails(row);
    navigate("/biLeadDetails");
  };

  const getColorForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "open":
        return "error";
      case "closed":
        return "success";
      default:
        return "default";
    }
  };

  const [statusLotNumber, setStatusLotNumber] = useState("");

  const handleChipClick = (row) => {
    if (row.status == "closed") {
      setSeverity("warning");
      setAlertTitle(
        `The status for this Lot Number ${row.Lot_Number} is already closed.`
      );
      setOpenSnackbar(true);
    } else {
      setOpenStatusDialog(true);
      setStatusLotNumber(row.Lot_Number);
    }
  };

  const handleStatusChange = () => {
    const formData = {
      lotNumber: statusLotNumber,
      newStatus: "closed",
    };

    axios
      .put(BI_STATUS_UPDATE, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        setSeverity("success");
        setAlertTitle(response.data.message);
        setOpenSnackbar(true);
        setOpenStatusDialog(false);
        setRunEffect(true);
      })
      .catch((error) => {
        setSeverity("error");
        setAlertTitle(error.message);
        setOpenSnackbar(true);
        setOpenStatusDialog(false);
      });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setOpen(true);
      setFile(file);
      // uploadFile(file);
    }
  };

  const handleUpload = () => {
    setUploading(true);
    setProgress(0);

    // Simulate file upload
    const uploadInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(uploadInterval);
          uploadFile(file);
          // setTimeout(() => setOpen(false), 1000);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 500);
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(BULK_UPLOAD_BI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        console.log("File uploaded successfully:", response.data);
        setSeverity("success");
        setAlertTitle(response.data.message);
        setMessage("Updated Count:" + response.data.updatedCount);
        setOpenSnackbar(true);

        setUploading(false);
        setOpen(false);

        fileInputRef.current.value = null;
        setRunEffect(true);
        // navigate(0);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // setRunEffect(false);
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenSnackbar(true);
      });
  };

  const handleCloseDialog = () => {
    if (!uploading) {
      setOpen(false);
      setFile(null);
    }
  };

  const handleStatusDialogClose = () => {
    setOpenStatusDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      {loading && <Loader open={loading} />}

      <Paper
        sx={{
          // border: 2,
          // borderColor: "primary.main",
          boxShadow: 3,
          borderRadius: "12px",
          padding: "16px",
          margin: "24px 0",
          backgroundColor: "#fafafa",
        }}
      >
        <TableContainer
          sx={{
            // boxShadow: 2,
            borderRadius: "8px",
            margin: "16px 0",
          }}
        >
          <Table
            sx={{
              minWidth: 450,
              tableLayout: "fixed",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f0f4f8" }}>
                <TableCell
                  align="center"
                  sx={{
                    width: "50px",
                    fontWeight: "bold",
                    color: "text.primary",
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Branch Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 180, fontWeight: "bold", color: "text.primary" }}
                >
                  Branch Description
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Farm Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Farmer Mobile
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Lot Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Age
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Chicken House Qty
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Mortality Qty
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Mortality %
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Balance Birds
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 150, fontWeight: "bold", color: "text.primary" }}
                >
                  Mort(%):OnDate
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 180, fontWeight: "bold", color: "text.primary" }}
                >
                  Mort(%):OnDate-1
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 180, fontWeight: "bold", color: "text.primary" }}
                >
                  Mort(%):OnDate-2
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 180, fontWeight: "bold", color: "text.primary" }}
                >
                  Mort(%):OnDate-3
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 180, fontWeight: "bold", color: "text.primary" }}
                >
                  Mort(%):OnDate-4
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 80,
                    padding: 0,
                    position: "sticky", // Make the cell sticky
                    right: 80, // Align it next to the Action column
                    backgroundColor: "#EEF0F4", // Set background color for visibility
                    zIndex: 10, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    fontWeight: 'bold',
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 80,
                    padding: 0,
                    position: "sticky", // Make the cell sticky
                    right: 0, // Align it next to the Action column
                    backgroundColor: "#EEF0F4", // Set background color for visibility
                    zIndex: 10, // Higher z-index to stay above other cells
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    fontWeight: 'bold'
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowOpen.length > 0 ? (
                <>
                  {rowOpen
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                        }}
                      >
                        <TableCell align="center">{row.SNo}</TableCell>
                        <TableCell align="center">{row.Branch}</TableCell>
                        <TableCell align="center">
                          {row.Branch_Description}
                        </TableCell>
                        <TableCell align="center">{row.Farm_Name}</TableCell>
                        <TableCell align="center">{row.Farmer_Mob}</TableCell>
                        <TableCell align="center">{row.Lot_Number}</TableCell>
                        <TableCell align="center">{row.Age}</TableCell>
                        <TableCell align="center">
                          {row.Chicks_Housed_Quantity}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mortality_Quantity}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mortality_Percentage}
                        </TableCell>
                        <TableCell align="center">
                          {row.Balance_Birds}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mort_Percentage_On_Date}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mort_Percentage_Date_1}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mort_Percentage_Date_2}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mort_Percentage_Date_3}
                        </TableCell>
                        <TableCell align="center">
                          {row.Mort_Percentage_Date_4}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: 80,
                            padding: 0,
                            position: "sticky", // Make the cell sticky
                            right: 80, // Align it next to the Action column
                            backgroundColor: "#EEF0F4", // Set background color for visibility
                            zIndex: 10, // Higher z-index to stay above other cells
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          {row.status === "-" ? (
                            <p>-</p>
                          ) : (
                            <Chip
                              size="small"
                              label={row.status}
                              color={getColorForTag(row.status)}
                              sx={{
                                textTransform: "capitalize",
                                height: "20px",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: 80,
                            padding: 0,
                            position: "sticky", // Make the cell sticky
                            right: 0, // Align it next to the Action column
                            backgroundColor: "#EEF0F4", // Set background color for visibility
                            zIndex: 10, // Higher z-index to stay above other cells
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          <IconCell row={row} />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={18}
                    sx={{ color: "text.secondary", py: 3 }}
                  >
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rowOpen.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: "1px solid #e0e0e0",
            ".MuiTablePagination-actions": { color: "primary.main" },
          }}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{"Confirm Upload"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {uploading
              ? "Uploading..."
              : "Are you sure you want to upload the selected file?"}
          </DialogContentText>
          {uploading && (
            <Box sx={{ width: "100%", mt: 2, height: "30px" }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {!uploading && (
            <>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpload} color="primary" autoFocus>
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStatusDialog}
        onClose={handleStatusDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{"Confirm Status Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to change the status for this ${statusLotNumber}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStatusChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* <AlertTitle>{alertTitle}</AlertTitle> */}
          {alertTitle}
        </Alert>
      </Snackbar>
    </>
  );
}

export default BiLeadListComponent;
