import React, { useState , useEffect, useRef} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SiteVisitPageComponent from './SiteVisitPage';
import MeetingpageComponent from './MeetingPage';
import EstimationPageComponent from './EstimationPage';
import OnCallPageComponent from './OnCallPage';
import { useLocation } from 'react-router-dom';
import bdmDashboardStyles from '../BdmDashboard/bdmDashboardStyles.module.css';
import IconButton from '@mui/material/IconButton';
import { Stack, Snackbar, Grid, Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import axios from 'axios';
import { GET_ESTIMATIONS_SV, GET_MEETING_SV, GET_ON_CALL_DISCUSSION, GET_ON_CALL_DISCUSSION_SV, GET_SITE_VISIT_SV } from '../../constants';
import CallIcon from '@mui/icons-material/Call';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaceIcon from '@mui/icons-material/Place';
import CalculateIcon from '@mui/icons-material/Calculate';
import DoneIcon from '@mui/icons-material/Done';
import OnCallListComponent from './OnCallList';
import MeetingListComponent from './MeetingList';
import SiteVisitListComponent from './SiteVisitList';
import EstimationListComponent from './EstimationList';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

function AllLeadsPage() {
    const [value, setValue] = useState(0);
    const location = useLocation();
   

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const getTodayDate = () =>{
    const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(today.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;

          return formattedDate;
  }
//   const [dateFilter, setDateFilter] = useState(getTodayDate());

  const [filter, setFilter] = useState(getTodayDate());

  useEffect(() => {
    if (location.state && location.state.tab !== undefined) {
        setValue(location.state.tab);
    }
    if(location.state && location.state.dateFilter !== undefined){
      setFilter(location.state.dateFilter);
    }
    console.log(location.state);
    
}, [location.state]);
  

  const [onCallCount, setonCallCount] = useState(0);
  const [meetingCount, setMeetingCount] = useState(0);
  const [siteVisitCount, setSiteVisitCount] = useState(0);
  const [estimateCount, setEstimateCount] = useState(0);


  const [selectedFilter, setSelectedFilter] = useState(null);
    // const [dateRange, setDateRange] = useState(null);

    useEffect(() => {
      const page = 1;
      const limit = 10;
      
      // Check if filter is undefined and return early if so
      if (filter === undefined) return;
      
      if (Array.isArray(filter)) {
          const StartDate = filter[0];
          const EndDate = filter[1];
  
          axios.get(`${GET_ON_CALL_DISCUSSION_SV}?page=${page}&limit=${limit}&StartDate=${StartDate}&EndDate=${EndDate}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { callOnDiscussionCount } = response.data;
              setonCallCount(callOnDiscussionCount);
          })
          .catch(error => { console.error(error); });
  
          axios.get(`${GET_MEETING_SV}?page=${page}&limit=${limit}&StartDate=${StartDate}&EndDate=${EndDate}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { total_meetings } = response.data;
              setMeetingCount(total_meetings);
          })
          .catch(error => { console.error(error); });
  
          axios.get(`${GET_SITE_VISIT_SV}?page=${page}&limit=${limit}&StartDate=${StartDate}&EndDate=${EndDate}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { total_site_visits } = response.data;
              setSiteVisitCount(total_site_visits);
          })
          .catch(error => { console.error(error); });
  
          axios.get(`${GET_ESTIMATIONS_SV}?page=${page}&limit=${limit}&StartDate=${StartDate}&EndDate=${EndDate}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { total_estimations } = response.data;
              setEstimateCount(total_estimations);
          })
          .catch(error => { console.error(error); });
  
      } else {
        console.log("filter", filter);
        
          const date = filter;
  
          axios.get(`${GET_ON_CALL_DISCUSSION_SV}?page=${page}&limit=${limit}&date=${date}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { leads, callOnDiscussionCount } = response.data;
              setonCallCount(callOnDiscussionCount);
          })
          .catch(error => { console.error(error); });
  
          axios.get(`${GET_MEETING_SV}?page=${page}&limit=${limit}&date=${date}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { total_meetings } = response.data;
              setMeetingCount(total_meetings);
          })
          .catch(error => { console.error(error); });
  
          axios.get(`${GET_SITE_VISIT_SV}?page=${page}&limit=${limit}&date=${date}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { total_site_visits } = response.data;
              setSiteVisitCount(total_site_visits);
          })
          .catch(error => { console.error(error); });
  
          axios.get(`${GET_ESTIMATIONS_SV}?page=${page}&limit=${limit}&date=${date}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          })
          .then(response => {
              const { total_estimations } = response.data;
              setEstimateCount(total_estimations);
          })
          .catch(error => { console.error(error); });
      }
  }, [filter]);
  

  const op = useRef(null);

  const [dateRange, setDateRange] = useState(null);
  const [dateFil, setDateFil] = useState('')

  

  const [dateFilter, setDateFilter] = useState(getTodayDate());
  const [changedDate, setChangedDate] = useState('');

  const handleDateFilter = (type) =>{
    if(type === 'today'){
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(today.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          setDateFilter(formattedDate);
    } else if(type == 'yesterday'){
          // Get today's date
          const today = new Date();

          // Subtract one day from today's date
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);

          // Extract year, month, and day
          const year = yesterday.getFullYear();
          const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(yesterday.getDate()).padStart(2, '0');

          // Format the date as yyyy-mm-dd
          const formattedDate = `${year}-${month}-${day}`;
          setDateFilter(formattedDate);
          setFilter(formattedDate)
    }else if(type == 'dateRange'){
      const date = new Date(dateRange[0]);
      const date1 = new Date(dateRange[1])
      // Extract year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
      const day = String(date.getDate()).padStart(2, '0');

      const year1 = date1.getFullYear();
      const month1 = String(date1.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
      const day1 = String(date1.getDate()).padStart(2, '0');

      // Return formatted date
      const startDate =  `${year}-${month}-${day}`;
      const endDate = `${year1}-${month1}-${day1}`;

      const date_arr = [];
      date_arr.push(startDate);
      date_arr.push(endDate);
      setDateFilter(date_arr);
      setFilter(date_arr)
    }
  };

  const [dateVar, setDateVar] = useState(null);

  const handleApply = () =>{
    console.log(dateVar)
      if(dateVar === 'today' ){
        handleDateFilter('today')
      }
      if(dateVar === 'yesterday'){
        handleDateFilter('yesterday')
      }
      if(dateVar === 'dateRange'){
        handleDateFilter('dateRange')
      }

      // console.log('nothing selected')
  };

  const handleClear = () =>{
    setDateVar('');
    setDateFil('');
    setDateRange('');
    setDateFilter(getTodayDate());
    setChangedDate('');
    setShowRange(false);
    setFilter(getTodayDate());
  }

  const handleDateField = (type) =>{
    if(type === 'today'){
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(today.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
        
          setChangedDate(formattedDate)
    } else if(type == 'yesterday'){
          // Get today's date
          const today = new Date();

          // Subtract one day from today's date
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);

          // Extract year, month, and day
          const year = yesterday.getFullYear();
          const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
          const day = String(yesterday.getDate()).padStart(2, '0');

          // Format the date as yyyy-mm-dd
          const formattedDate = `${year}-${month}-${day}`;
          
          setChangedDate(formattedDate)
    }else if(type == 'dateRange'){
      console.log(dateRange)
      const date = new Date(dateRange[0]);
      const date1 = new Date(dateRange[1])
      // Extract year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
      const day = String(date.getDate()).padStart(2, '0');

      const year1 = date1.getFullYear();
      const month1 = String(date1.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
      const day1 = String(date1.getDate()).padStart(2, '0');

      // Return formatted date
      const startDate =  `${year}-${month}-${day}`;
      const endDate = `${year1}-${month1}-${day1}`;

      const date_arr = [];
      date_arr.push(startDate);
      date_arr.push(endDate);
      
      setChangedDate(`${startDate} - ${endDate}`);
    }
  }

  const [showRange, setShowRange] = useState(false);



  return (
    <>
   
    {/* <div className={`float-right`}>
            <TextField
            id="outlined-select-currency"
            select
            label="Filter"
            defaultValue={'today'}
            // helperText="Please select your currency"
            size='small'
            className={`w-36`}
            onChange={handleDateFilter}
            >
            <MenuItem value={'today'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Today</MenuItem>
            <MenuItem value={'yesterday'} sx={{borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>Yesterday</MenuItem>
            </TextField>
    </div> */}

<div className={`float-right`} style={{marginRight: '15px'}}>
    <div className="card flex flex-column align-items-center gap-3">
            <Button type="button" icon="pi pi-filter" label="" onClick={(e) => op.current.toggle(e)} />
            <OverlayPanel ref={op} showCloseIcon dismissable>
                <div className='mb-2'>
                  <TextField
                  id="outlined-size-small"
                  size="small"
                  value={changedDate}
                  fullWidth
                  ></TextField>
                </div>
                <div className="">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Filter"
                        // defaultValue={'today'}
                        value={dateFil}
                        size='small'
                        fullWidth
                        onChange={(e) => {
                          if(e.target.value == 'custom'){
                              setShowRange(true);
                              setDateFil(e.target.value)
                          } else {
                            setDateFil(e.target.value)
                            setDateVar(e.target.value)
                            handleDateField(e.target.value)
                          }
                        }}
                        variant="outlined"
                    >
                        <MenuItem value={'today'} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>
                            Today
                        </MenuItem>
                        <MenuItem value={'yesterday'} sx={{ borderBottom: 'thin solid rgba(0, 0, 0, 0.12)', marginBottom: '8px', marginTop: '8px' }}>
                            Yesterday
                        </MenuItem>
                        <MenuItem value={'custom'}>
                            Custom
                        </MenuItem>
                    </TextField>
                    <Divider sx={{ margin: '16px 0' }} />
                    {showRange ? (<div className="p-fluid">
                        <label htmlFor="daterange" className="p-d-block">Select Custom Date Range</label>
                        <div className='flex justify-between'>
                        <Calendar
                            id="daterange"
                            value={dateRange}
                            onChange={(e) =>{ 
                              setDateVar('dateRange')
                              setDateRange(e.value) 
                            }}
                            selectionMode="range"
                            readOnlyInput
                            hideOnRangeSelection
                            className="inputText p-d-block"
                        />
                        <IconButton sx={{
                          borderColor: 'grey',
                          borderRadius: '5px',
                          border: '1px solid'
                        }} 
                        onClick={(e) => {handleDateField('dateRange')}}
                        >
                          <DoneIcon></DoneIcon>
                        </IconButton>
                        </div>
                    </div>) : (<></>)}
                    <div className="buttonBar mt-3">
                        <Button type="button" severity="success" outlined className="buttonStyle p-mr-2" onClick={handleApply}>Apply</Button>
                        <Button type="button" outlined className="buttonStyle p-button-secondary" onClick={handleClear}>Clear</Button>
                    </div>
                </div>
            </OverlayPanel>
        </div>
  </div>
    

    <Tabs
    value={value}
    onChange={handleChange}
    aria-label="wrapped label tabs example"
    >
        <Tab label={`On-Call Discussion (${onCallCount})`} id="tab-0" icon={<CallIcon />} iconPosition="start" aria-controls="tabpanel-1" />
        <Tab label={`Meeting (${meetingCount})`} id="tab-1" icon={<GroupsIcon />} iconPosition="start" aria-controls="tabpanel-1" />
        <Tab label={`Site Visit (${siteVisitCount})`} id="tab-2" icon={<PlaceIcon />} iconPosition="start" aria-controls="tabpanel-2" />
        <Tab label={`Estimation (${estimateCount})`} id="tab-3" icon={<CalculateIcon />} iconPosition="start" aria-controls="tabpanel-3" />

    </Tabs>
    <TabPanel value={value} index={0}>
        {/* <OnCallPageComponent dateFilter={filter} ></OnCallPageComponent> */}
        <OnCallListComponent dateFilter={filter} ></OnCallListComponent>
    </TabPanel>
    <TabPanel value={value} index={1}>
        {/* <MeetingpageComponent dateFilter={filter} ></MeetingpageComponent> */}
        <MeetingListComponent dateFilter={filter}></MeetingListComponent>
    </TabPanel>
    <TabPanel value={value} index={2}>
        {/* <SiteVisitPageComponent dateFilter={filter} ></SiteVisitPageComponent> */}
        <SiteVisitListComponent dateFilter={filter}></SiteVisitListComponent>
    </TabPanel>
    <TabPanel value={value} index={3}>
        {/* <EstimationPageComponent dateFilter={filter} ></EstimationPageComponent> */}
        <EstimationListComponent dateFilter={filter} ></EstimationListComponent>
    </TabPanel>
    </>
  );
}

export default AllLeadsPage;