import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  CssBaseline,
  Box,
  CircularProgress,
  ListItemAvatar,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  AccountCircle,
  AccountCircleTwoTone,
  HistoryEdu,
  Store,
} from "@mui/icons-material";
import { LoginContext } from "./LoginPage/LoginContext";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Person2Icon from "@mui/icons-material/Person2";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import ib_logo from "../icons/IB_logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import Tooltip from "@mui/material/Tooltip";
import { NavLink as RouterLink } from "react-router-dom";
import leadFormStyles from "./Leads Page/leadFormStyles.module.css";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { logout } from "../Actions/LoginAction";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import axios from "axios";
import { GET_LEAD_BY_BDM_API } from "../constants";
import dayjs from "dayjs";
import NotificationsIcon from "@mui/icons-material/Notifications";

const MobileMainComponent = ({ logout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleprofileNavigate = () => {
    navigate("/profile");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userData");
    // logout();
    navigate("/");
    navigate(0);
  };

  const [bdmAssignedPage, setBdmAssignedPage] = useState(false);
  const [bdmToDoPage, setBdmToDoPage] = useState(false);

  const [leadpage, setLeadPage] = useState(false);
  const [supPage, setSupPage] = useState(false);
  const [auPage, setAuPage] = useState(false);

  const storedRole = localStorage.getItem("empRole");

  useEffect(() => {
    if (storedRole == 2) {
      setBdmAssignedPage(true);
      setBdmToDoPage(true);
    }
    if (storedRole == 1) {
      setLeadPage(true);
    }
    if (storedRole == 5) {
      setSupPage(true);
    }
    if (storedRole == 100) {
      setAuPage(true);
    }
  }, []);

  const path = window.location.pathname;

  const handleDrawerClose = () => {
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const [menuEl, setMenuEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);


  const handleNotificationOpen = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setMenuEl(null);
  };

  useEffect(() => {
    const empId = localStorage.getItem("username");
    setLoading(true);
    axios
      .get(`${GET_LEAD_BY_BDM_API}/${empId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads } = response.data;
        // setNotifications(leads);
        handleNotification(leads);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, []);

  const handleNotification = (data) => {
    const today = dayjs().startOf("day");
    const filteredData = data.filter((rd) =>
      dayjs(rd.updatedAt).isSame(today, "day")
    );
    setNotifications(filteredData);
    setUnreadCount(filteredData.length);
  };

  const handleNotificationClick = (id) => {
    setUnreadCount(unreadCount - 1);
    navigate(`/bdmAssigned`);
    handleNotificationClose();
  };

  const DrawerList = (
    <>
      {/* <Toolbar /> */}
      <Box sx={{ overflow: "hidden", backgroundColor: "#fff", height: "100%" }}>
        <List>
          {storedRole != 6 && (
            <ListItem
              key={"dashboard"}
              button
              component={RouterLink}
              to="/dashboard"
              disablePadding
              className={
                path == "/dashboard"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                className={
                  path == "/dashboard" ? `${leadFormStyles.active}` : ``
                }
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Dashboard" arrow>
                  <SpeedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                sx={{ opacity: 1 }}
                className={``}
              />
            </ListItem>
          )}

          {leadpage && (
            <ListItem
              key={"lead"}
              disablePadding
              button
              component={RouterLink}
              className={
                path == "/lead"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              to="/lead"
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                className={path == "/lead" ? `${leadFormStyles.active}` : ``}
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Lead" arrow>
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"Lead"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {bdmAssignedPage && (
            <ListItem
              key={"bdm_assigned"}
              disablePadding
              button
              component={RouterLink}
              to="/bdmAssigned"
              className={
                path == "/bdmAssigned"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 2,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="BDM Assigned" arrow>
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"BDM Assigned"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {bdmToDoPage && (
            <ListItem
              key={"bdm_todo"}
              disablePadding
              button
              component={RouterLink}
              to="/bdmtodo"
              className={
                path === "/bdmtodo"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ""
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 2,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="To Do List" arrow>
                  <CheckBoxOutlineBlankIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"To Do List"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {storedRole == 6 && (
            <ListItem
              key={"ho_approval"}
              disablePadding
              button
              component={RouterLink}
              className={
                path == "/estimateGeneration"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              to="/estimateGeneration"
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                className={
                  path == "/estimateGeneration"
                    ? `${leadFormStyles.active}`
                    : ``
                }
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Estimate List" arrow>
                  <DomainVerificationIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"P-Card Estimation Requested"}
                sx={{ opacity: 1 }}
              />
            </ListItem>
          )}

          {storedRole == 101 && (
            <ListItem
              key={"auditMaster"}
              disablePadding
              button
              component={RouterLink}
              className={
                path == "/auditMaster"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              to="/auditMaster"
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                className={
                  path == "/auditMaster" ? `${leadFormStyles.active}` : ``
                }
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Audit Lead" arrow>
                  <VerifiedUserIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"Audit Master"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {/* {bdmAssignedPage && <ListItem key={'meetingPage'} 
                  disablePadding  
                  button component={RouterLink} 
                  to="/meetingPage"
                  className={path == '/meetingPage' ? `${leadFormStyles.active_back} ${leadFormStyles.active}` : ``}
                  sx={{ display: 'flex',
                    minHeight: 48,
                    justifyContent: 'center',
                    px: 2.5,
                   }}
                   onClick={handleDrawerClose}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 2,
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Meeting" arrow>
                      <GroupIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Meeting'} sx={{ opacity: 1  }} />
                </ListItem>} */}

          {supPage && (
            <ListItem
              key={"lead_master"}
              disablePadding
              button
              component={RouterLink}
              to="/leadMaster"
              className={
                path == "/leadMaster"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Leads Master" arrow>
                  <PersonAddAltIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"Leads Master"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {supPage && (
            <ListItem
              key={"leadFiltered"}
              disablePadding
              button
              component={RouterLink}
              to="/leadFiltered"
              className={
                path == "/leadFiltered"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Leads" arrow>
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"Leads"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {auPage && (
            <ListItem
              key={"audit_lead"}
              disablePadding
              button
              component={RouterLink}
              className={
                path == "/auditLead"
                  ? `${leadFormStyles.active_back} ${leadFormStyles.active}`
                  : ``
              }
              to="/auditLead"
              sx={{
                display: "flex",
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={handleDrawerClose}
            >
              <ListItemIcon
                className={
                  path == "/auditLead" ? `${leadFormStyles.active}` : ``
                }
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Audit Lead" arrow>
                  <VerifiedUserIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"Audit Lead"} sx={{ opacity: 1 }} />
            </ListItem>
          )}

          {/* <ListItem key={'hot_dis'} 
                  disablePadding 
                  button component={RouterLink} 
                  to="/hotDisposition"
                  sx={{ display: 'flex',
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                   }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Hot Disposition Summary" arrow>
                      <AssessmentIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Hot Disposition Summary'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItem> */}

          {/* <ListItem key={'warm_dis'} 
                disablePadding 
                button component={RouterLink} 
                  to="/warmDisposition"
                  sx={{ display: 'flex',
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                   }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="Warm Disposition Summary" arrow>
                      <AssessmentIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'Warm Disposition Summary'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItem> */}

          {/* <ListItem key={'history'} 
                disablePadding 
                button component={RouterLink} 
                  // to=""
                  sx={{ display: 'flex',
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                   }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip title="History" arrow>
                      <HistoryEdu />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={'History'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItem> */}
        </List>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <img
            src={ib_logo}
            loading="lazy"
            alt="onCall"
            className={`w-10`}
            style={{ marginLeft: "-18px" }}
          />
          <div style={{ marginLeft: "auto" }}>
            {localStorage.getItem("empRole") == 2 && (
              <>
                <IconButton color="inherit" onClick={handleNotificationOpen}>
                  <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={menuEl}
                  open={Boolean(menuEl)}
                  onClose={handleNotificationClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "40ch",
                    },
                  }}
                >
                  {loading ? (
                    <MenuItem>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : notifications.length === 0 ? (
                    <MenuItem onClick={handleClose}>
                      No new notifications
                    </MenuItem>
                  ) : (
                    notifications.map((notification) => (
                      <MenuItem
                        key={notification.id}
                        onClick={handleNotificationClick}
                      >
                        {/* New Lead Assigned !!
                Farmer Name: {notification.CustomerName}
                 */}
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar sx={{ marginTop: "15px" }}>
                            <Avatar>N</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={"New Lead Assigned !!"}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="subtitle2"
                                  color="text.primary"
                                >
                                  Farmer Name: {notification.CustomerName}
                                </Typography>
                                <br></br>
                                {`Mobile Number: ${notification.MobileNo}`}
                                <br></br>
                                {/* {`Assigned By: ${notification.Agent.EmployeeName}`} */}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </MenuItem>
                    ))
                  )}
                </Menu>
              </>
            )}

            <IconButton onClick={handleClick} color="inherit">
              {/* <Avatar > */}
              <AccountCircleTwoTone sx={{ fontSize: "2.5rem !important" }} />
              {/* <div className={`flex-row ml-1`}>
          <p style={{fontSize: '1rem', textTransform: 'capitalize'}}>{JSON.parse(localStorage.getItem('userData')).EmployeeName}</p>
          <p style={{fontSize: '0.8rem', textAlign: 'left'}}>{JSON.parse(localStorage.getItem('userData')).EmployeeRole == 1 ? 'CSE' : (JSON.parse(localStorage.getItem('userData')).EmployeeRole == 5 ? 'Supervisor' : 'BDM')}</p>
          </div> */}
              <ExpandMoreIcon />
              {/* </Avatar> */}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={`w-100`}
            >
              <MenuItem onClick={handleprofileNavigate}>
                <AccountCircle className={`mr-2`} />
                Profile
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <LogoutIcon className={`mr-2`} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={"temporary"}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: 180,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 180,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        {DrawerList}
      </Drawer>
    </Box>
  );
};

export default MobileMainComponent;
