import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Box,
  Typography,
  TablePagination,
  Paper,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import axios from "axios";
import { GET_ESTIMATIONS_SV } from "../../constants";
import moment from "moment";
import { useEffect } from "react";
import LongTextComponent from "../Leads Page/LongTextComponent";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const EstimationListComponent = ({ dateFilter }) => {
    const [expandedRows, setExpandedRows] = useState([]); // Ensure it's an array

    // Handle row expansion toggle
    const handleRowClick = (rowId) => {
      setExpandedRows((prev) => {
        if (prev.includes(rowId)) {
          return prev.filter((id) => id !== rowId); // Remove the row from expanded
        } else {
          return [...prev, rowId]; // Add the row to expanded
        }
      });
    };

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchEstimationData();
  }, [page, rowsPerPage, dateFilter]);

  const fetchEstimationData = () => {
    const currentPage = page + 1; // Convert zero-based index to one-based
    const limit = rowsPerPage;

    let url = `${GET_ESTIMATIONS_SV}?page=${currentPage}&limit=${limit}`;
    if (Array.isArray(dateFilter)) {
      const [StartDate, EndDate] = dateFilter;
      url += `&StartDate=${StartDate}&EndDate=${EndDate}`;
    } else if (dateFilter) {
      url += `&date=${dateFilter}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const { leads, pagination } = response.data;

        handlingRowData(leads);
        setTotalCount(pagination.total || 0); // Update the total count for pagination
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handlingRowData = (rowData) => {
    const transformedRows = [];
    var a = {};
    rowData.map((rd, index) => {
      a = {
        id: index + 1,
        SNo: index + 1,
        leadId: rd.id,
        CustomerName: rd.CustomerName == "" ? "-" : rd.CustomerName,
        MobileNo: rd.MobileNo == "" ? "-" : rd.MobileNo,
        AlternateMobileNo:
          rd.AlternateMobileNo == "" ? "-" : rd.AlternateMobileNo,
        state_name: rd.state_name == "" ? "-" : rd.state_name,
        source_of_lead_generated:
          rd.Campaign == null ? "-" : rd.Campaign.CampaignName,
        category: rd.category == "" ? "-" : rd.category,
        AgentId: rd.AgentId,
        BDMId: rd.BDMId,
        CustomerMailId: rd.CustomerMailId == "" ? "-" : rd.CustomerMailId,
        InquiryType: rd.InquiryType,
        Project: rd.Project,
        Superviser: rd.Superviser,
        SuperviserID: rd.SuperviserID,
        WhatsappNo: rd.WhatsappNo == "" ? "-" : rd.WhatsappNo,
        agent_remark: rd.agent_remark == "" ? "-" : rd.agent_remark,
        close_month: rd.close_month,
        follow_up_date:
          rd.follow_up_date == "" ? "-" : formatDate(rd.follow_up_date),
        lead_owner: rd.BDM == null ? "-" : rd.BDM.EmployeeName,
        lead_transfer_date:
          rd.lead_transfer_date == null ? "-" : formatDate(rd.lead_transfer_date),
        location: rd.location == "" ? "-" : rd.location,
        site_location_address:
          rd.site_location_address == "" ? "-" : rd.site_location_address,
        region_name: rd.region_name == "" ? "-" : rd.region_name,
        sub_category: rd.sub_category == "" ? "-" : rd.sub_category,
        Agent: rd.Agent,
        AgentName: rd.Agent == null ? "-" : rd.Agent.EmployeeName,
        BDM: rd.BDM,
        pincode: rd.pincode == "" ? "-" : rd.pincode,
        call_status: rd.call_status,
        call_type: rd.call_type,
        createdDate: rd.createdAt == "" ? "-" : formatDate(rd.createdAt),
        agent_remark: rd.agent_remark == null ? "-" : rd.agent_remark,
        bdm_remark:
          rd.bdm_remark == "" || rd.bdm_remark == null ? "-" : rd.bdm_remark,
        lead_calling_date: "-",
        bdm_calling_date: "-",
        estimations:
          rd.estimations == undefined ? [] : handleSubArrayData(rd.estimations),
      };
      transformedRows.push(a);
    });
    setRows(transformedRows);
  };

  const handleSubArrayData = (data) => {
    const transformedRows = [];
    var a = {};
    data.map((rd, index) => {
      a = {
        sno: index + 1,
        category: rd.category,
        closure_month: rd.closure_month,
        createdAt: formatDate(rd.createdAt),
        follow_up_date: formatDate(rd.follow_up_date),
        id: rd.id,
        sub_category: rd.sub_category,
        updatedAt: formatDate(rd.updatedAt),
        CShape: rd.CShape == true ? "Yes" : "No",
        CivilConstructedStarted:
          rd.CivilConstructedStarted == true ? "Yes" : "No",
        CurtainRequirment: rd.CurtainRequirment == true ? "Yes" : "No",
        DiselBrooderRequirment:
          rd.DiselBrooderRequirment == true ? "Yes" : "No",
        EquipementPlacementLength: rd.EquipementPlacementLength,
        EstimationRequirement: rd.EstimationRequirement,
        MOdalType: rd.ModalType,
        NumberOfSheds: rd.NumberOfSheds,
        PowerSupply: rd.PowerSupply == true ? "Yes" : "No",
        Remarks: rd.Remarks,
        ShedLength: rd.ShedLength,
        ShedWidth: rd.ShedWidth == true ? "Yes" : "No",
        SideWallColumnToColumnGap: rd.SideWallColumnToColumnGap,
        WaterSupply: rd.WaterSupply == true ? "Yes" : "No",
        status: rd.status,
      };
      transformedRows.push(a);
    });

    return transformedRows;
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Trigger data fetch via `useEffect`
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const rowExpansionTemplate = (data) => (
    <Box sx={{ padding: 1, backgroundColor: "#f9f9f9" }}>
      <Typography sx={{ fontWeight: "700", marginBottom: "3px" }} gutterBottom>
        Estimations ({data.estimations.length})
      </Typography>
      <TableContainer
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
        }}
      >
        <Table
          sx={{ minWidth: 450, height: "100%", tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
              <TableCell
                width={50}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 1 }}
              >
                SNo
              </TableCell>
              <TableCell
                width={80}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Estimation Submitted On
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Status
              </TableCell>
              <TableCell
                width={80}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Category
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Sub-Category
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Follow-up Date
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Closure Month
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Estimation Requirement
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Civil Constructed Started
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Shed Length
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Equipement Placement Length
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Shed Width
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                C-Shape
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Model Type
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Side Wall Column To Column Gap
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Number of Sheds
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Curtain Requirment
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Diesel Brooder Requirment
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Water Available
              </TableCell>
              <TableCell
                width={120}
                align="center"
                sx={{ fontSize: "0.8rem", padding: 0 }}
              >
                Remarks
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.estimations.map((visit, index) => (
              <TableRow key={index}>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.sno}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.createdAt}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.status}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "0.8rem", textTransform: "capitalize" }}
                >
                  {visit.category}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.sub_category}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.follow_up_date}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.closure_month}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.EstimationRequirement}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.CivilConstructedStarted}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.ShedLength}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.EquipementPlacementLength}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.ShedWidth}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.CShape}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.MOdalType}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.SideWallColumnToColumnGap}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.NumberOfSheds}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.CurtainRequirment}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.DiselBrooderRequirment}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  {visit.PowerSupply}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "0.8rem" }}>
                  <LongTextComponent
                          text={visit.Remarks || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <>
      <Paper>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, height: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#EEF0F4" }}>
                <TableCell
                  align="right"
                  sx={{
                    width: 30,
                    padding: 0,
                  }}
                />
                <TableCell
                  align="center"
                  sx={{
                    width: 50,
                    padding: 1,
                  }}
                >
                  SNo
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Lead Owner
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Region
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Sub-Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  CSE Remark
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  BDM/BI Remark
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  City/Village/Location
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  State
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Mail Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of First Calling
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Follow-up Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Closure Month
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of Lead Transfer
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Date of BDM Calling
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Campaign Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  CSE Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Project
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Alternate Number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 150,
                    padding: 0,
                  }}
                >
                  Whatsapp Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableCell colSpan={24}>No Data Available Yet.</TableCell>
              ) : (
                rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleRowClick(row.id)}
                        >
                          {expandedRows.includes(row.id)  ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="center">{row.lead_owner}</TableCell>
                      <TableCell align="center">{row.CustomerName}</TableCell>
                      <TableCell align="center">{row.MobileNo}</TableCell>
                      <TableCell align="center">{row.region_name}</TableCell>
                      <TableCell
                        align="center"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell align="center">{row.sub_category}</TableCell>
                      <TableCell align="center">
                        <LongTextComponent
                          text={row.agent_remark || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                      </TableCell>
                      <TableCell align="center">
                        <LongTextComponent
                          text={row.bdm_remark || "-"}
                          maxLength={30}
                        ></LongTextComponent>
                      </TableCell>
                      <TableCell align="center">{row.location}</TableCell>
                      <TableCell align="center">{row.state_name}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          wordWrap: "break-word",
                          maxWidth: "200px",
                          whiteSpace: "normal",
                        }}
                      >
                        {row.CustomerMailId}
                      </TableCell>
                      <TableCell align="center">{row.createdDate}</TableCell>
                      <TableCell align="center">{row.follow_up_date}</TableCell>
                      <TableCell align="center">{row.close_month}</TableCell>
                      <TableCell align="center">
                        {row.lead_transfer_date}
                      </TableCell>
                      <TableCell align="center">
                        {row.bdm_calling_date}
                      </TableCell>
                      <TableCell align="center">
                        {row.source_of_lead_generated}
                      </TableCell>
                      <TableCell align="center">{row.AgentName}</TableCell>
                      <TableCell align="center">{row.Project}</TableCell>
                      <TableCell align="center">
                        {row.AlternateMobileNo}
                      </TableCell>
                      <TableCell align="center">{row.WhatsappNo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={24}
                      >
                        <Collapse
                          in={expandedRows.includes(row.id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            sx={{
                              padding: 1,
                              width: "90%", // Set to a specific width if needed, e.g., "80%"
                              overflowX: "auto", // Add horizontal scrolling if content exceeds the container width
                            }}
                          >
                            {rowExpansionTemplate(row)}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount} // Use the total count from API
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default EstimationListComponent;
