import React from 'react';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const MeetingDetailPanelTable = ({ data }) => {
    if (!data || data.length === 0) return <Typography>No updates available.</Typography>;

  const getColorForTag = (tag) => {
    switch (tag) {
      case 'hot':
        return 'error'; // React blue
      case 'warm':
        return 'warning'; // JavaScript yellow
      case 'cold':
        return 'info'; // UI dark blue
       case 'closed':
        return 'default'
      default:
        return 'default'; // Default gray
    }
  };

    return(
        <>
         <TableContainer component={Paper} sx={{width: 'fit-content'}}>
            <Table sx={{ width: 'fit-content' }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#EEF0F4'}}>
                <TableRow>
                <TableCell style={{textAlign: 'center', minWidth: '20px'}}>S.No</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Date of Visit</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Follow Up Date</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px', textTransform: 'capitalize'}}>Category</TableCell>
                <TableCell  style={{textAlign: 'center', minWidth:'50px'}}>Sub Category</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Closure Month</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Birds Capacity</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Land Dimension</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Shed Size</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Is Land Direction East West</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Direction Deviation Degree</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Electricity Power</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Water</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Approach Road</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Model Type</TableCell>
                <TableCell style={{textAlign: 'center', minWidth:'50px'}}>Estimation Requirement</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell style={{textAlign: 'center', minWidth: '20px'}}>{row.sno}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.createdAt}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.follow_up_date}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px', textTransform: 'capitalize'}}>
                        <Chip key={`index_${row.category}`} size='small' color={`${getColorForTag(row.category)}`} label={row.category} sx={{textTransform: 'capitalize', height: '20px'}}></Chip>
                    </TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.sub_category}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.closure_month}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.BirdsCapacity}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.LandDimension}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.ShedSize}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.IsLandDirectionEastWest}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.DirectionDeviationDegree}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.ElectricityPower}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.Water}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.ApproachRoad}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.ModelType}</TableCell>
                    <TableCell style={{textAlign: 'center', minWidth:'50px'}}>{row.EstimationRequirement}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}

export default MeetingDetailPanelTable;