import * as React from 'react';
import bdmDashboardStyles from '../BdmDashboard/bdmDashboardStyles.module.css';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import  totalCalls from '../../icons/total_calls.png';
import connected from '../../icons/connected.png';
import notconnected from '../../icons/not_connected.png';
import followup from '../../icons/follow-up.png';
import { Grid } from '@mui/material';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { useMediaQuery, useTheme, Chip} from '@mui/material';


function TeamPerformance() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
       <>
       <div>
       <p className={`text-2xl font-bold`}>Team Performance</p>
       </div>
       <div>
        <Grid container spacing={2} className={`mb-4`} wrap='wrap'>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>Total Calls</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-xl' : 'text-3xl'}`}>23</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={totalCalls}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>Connected</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-xl' : 'text-3xl'}`}>23</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={connected}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>Not Connected</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-xl' : 'text-3xl'}`}>23</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={notconnected}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>Missed</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-xl' : 'text-3xl'}`}>23</p>
            </div>

            <div className={`w-1/2`}>
                {/* <img
                    src={missed}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                /> */}
                <PhoneMissedIcon className={`w-3/4 float-right ${bdmDashboardStyles.icon_font_size}`} />
            </div>
        </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
        <Paper className={`flex place-items-center justify-center h-full p-2`}>
            <div className={`w-1/2`}>
            <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>Follow-up</p>
            <Divider className={`${bdmDashboardStyles.p_inside_divider}`}/>
            <p className={`font-bold ${smallScreen ? 'text-xl' : 'text-3xl'}`}>23</p>
            </div>

            <div className={`w-1/2`}>
                <img
                    src={followup}
                    loading="lazy"
                    alt='totalCalls'
                    className={`w-3/4 float-right`}
                />
            </div>
        </Paper>
        </Grid>

        </Grid>
        </div>
       </>
      );
    }
    
    export default TeamPerformance;
    