import * as React from 'react';
import bdmDashboardStyles from '../BdmDashboard/bdmDashboardStyles.module.css';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import onCall from '../../icons/On_call.png';
import onHold from '../../icons/on_hold.png';
import onBreak from '../../icons/break.png';
import autoCallOff from '../../icons/ato_call_off.png';
import waiting from '../../icons/waiting.png';
import { Grid, IconButton, Stack } from '@mui/material';
import { useMediaQuery, useTheme, Chip} from '@mui/material';

function AgentOfferingTime() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
        <h4 className="font-bold text-2xl mb-2">Agent Offering Time</h4>
      
        <Grid container spacing={2} className="mb-4" wrap="wrap">
          {[
            { label: "On Call", time: "02:54:34", imgSrc: onCall },
            { label: "On Hold", time: "00:17:20", imgSrc: onHold },
            { label: "On Break", time: "02:13:59", imgSrc: onBreak },
            { label: "Waiting", time: "00:13:59", imgSrc: waiting },
            // Uncomment the following item if needed
            // { label: "Auto Call-off", time: "00:13:59", imgSrc: autoCallOff },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={6} xl={3} key={index}>
              <Paper className="flex place-items-center justify-center h-full p-2">
                <div className="w-1/2">
                  <p className={`font-bold ${smallScreen ? 'text-xs break-all' : 'text-sm'}`}>{item.label}</p>
                  <Divider className={`${bdmDashboardStyles.p_inside_divider}`} />
                  <p className={`font-bold ${smallScreen ? 'text-base' : 'text-lg'}`}>{item.time}</p>
                </div>
      
                <div className="w-1/2 flex items-center justify-end">
                  <img
                    src={item.imgSrc}
                    loading="lazy"
                    alt={item.label}
                    className="w-3/4"
                  />
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
      
      );
    }
    
export default AgentOfferingTime;