import React, { useContext, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import bdmFormStyles from "./bdmFormStyles.module.css";
import { Alert, Box, Grid, Snackbar, Stack } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import { LEAD_CONVERTED } from "../../constants";
import axios from "axios";
import pdf_img from "../../icons/pdf.png";
import { useNavigate } from "react-router-dom";
import Loader from "../reusable Component/Loader";
import { useTheme } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ConvertedActionComponent() {
  const storedLeadDetails = JSON.parse(localStorage.getItem("leadDetails"));

  console.log(storedLeadDetails);

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const initialDocuments = [
    {
      id: 1,
      key: "payment_amount",
      name: "Payment Amount",
      type: "input",
      file: [],
      isMandatory: true,
    },
    {
      id: 2,
      key: "payment_slip",
      name: "Payment Slip Document",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 3,
      key: "customer_creation_form",
      name: "Customer Creation Form",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 4,
      key: "pan_card",
      name: "PAN Card",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 5,
      key: "aadhar_card",
      name: "Aadhar Card",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 6,
      key: "land_certificate",
      name: "Land Certificate for unloading address",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 7,
      key: "gst_certificate",
      name: "GST Certificate",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 8,
      key: "bank_account_details",
      name: "Bank Account Details",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 8,
      key: "bank_cheques",
      name: "3 Bank Cheques",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 10,
      key: "legal_agreement_copy",
      name: "Legal Agreement Copy",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 11,
      key: "affidavit_property",
      name: "Affidavit (Property), If applicable",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 12,
      key: "consent_letter_dispatch",
      name: "Consent Letter for dispatch",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 13,
      key: "consent_letter_third_party_payment",
      name: "Consent Letter for 3rd Party payment, if applicable",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 14,
      key: "estimation",
      name: "Estimation ",
      type: "upload",
      file: [],
      isMandatory: true,
    },
    {
      id: 15,
      key: "final_quotation",
      name: "Final Quotation",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 16,
      key: "annexure",
      name: "Annexure",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 17,
      key: "udyam_registration_certificate",
      name: "Udyam Registration Certificate",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    {
      id: 18,
      key: "gram_panchayat_noc",
      name: "Gram Panchayat NOC",
      type: "upload",
      file: [],
      isMandatory: false,
    },
    // { id: 19, key: 'payment_amount' ,name: 'Other',type: 'upload', file: [] , isMandatory: false},
    {
      id: 19,
      key: "remark",
      name: "Remarks",
      type: "textarea",
      file: [],
      isMandatory: true,
    },
  ];

  const [documents, setDocuments] = useState(initialDocuments);

  const handleFileChange = (index, event) => {
    const newDocuments = [...documents];

    // Ensure the index is within bounds and that the object has a `file` property
    if (newDocuments[index] && Array.isArray(newDocuments[index].file)) {
      const newFiles = Array.from(event.target.files);
      const fileUrls = [];

      newFiles.forEach((file) => {
        if (file.size <= 1048576) {
          // Check if file size is less than or equal to 1MB (1MB = 1048576 bytes)
          fileUrls.push(URL.createObjectURL(file));
          newDocuments[index].file.push(file); // Add valid file
        } else {
          alert(`File ${file.name} is too large. Maximum size allowed is 1MB.`);
        }
      });

      setDocuments(newDocuments);

      // Reset the input field to allow re-uploading the same file
      event.target.value = null;
    } else {
      console.error(
        `Document at index ${index} is undefined or improperly structured.`
      );
    }
  };

  const handleRemoveRow = (index) => {
    const newDocuments = [...documents];

    // Revoke all URLs for the files in the row being removed
    newDocuments[index].file.forEach(({ url }) => URL.revokeObjectURL(url));

    newDocuments.splice(index, 1);
    setDocuments(newDocuments);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // Validation: Ensure mandatory documents are uploaded
    const mandatoryDocs = documents.filter((doc) => doc.isMandatory);
    const missingDocs = mandatoryDocs.filter(
      (doc) => doc.type === "upload" && doc.file.length === 0
    );

    if (missingDocs.length > 0) {
      setLoading(false);
      alert(
        `Please upload the following mandatory documents: ${missingDocs
          .map((doc) => doc.name)
          .join(", ")}`
      );
      return;
    } else {
      // Handle form submission logic here
      console.log("Submitted documents:", documents);
      // Create FormData object
      const formData = new FormData();
      formData.append("LeadDetailId", storedLeadDetails.leadId);
      formData.append("employeeId", localStorage.getItem("username"));
      formData.append("status", "Converted");
      formData.append("estimationId", localStorage.getItem("estimation_id"));

      // Append files to FormData using keys from `initialDocuments`
      documents.forEach((document) => {
        document.file.forEach((file, fileIndex) => {
          console.log(`Appending file: ${document.key}`, file);
          // Append each file with a unique key based on the document key and file index
          formData.append(`${document.key}`, file);
        });
      });

      axios
        .post(LEAD_CONVERTED, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setSeverity("success");
          setMessage(response.data.message);
          setOpenSnackbar(true);

          localStorage.removeItem("estimation_id");

          setTimeout(() => {
            navigate("/bdmDetails");
          }, 1000); // Delay for 1 seconds
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleRemoveFile = (docIndex, fileIndex) => {
    console.log("here", docIndex, fileIndex);
    const newDocuments = [...documents];
    // Safely check if the document and file exist
    if (newDocuments[docIndex] && newDocuments[docIndex].file[fileIndex]) {
      const [removedFile] = newDocuments[docIndex].file.splice(fileIndex, 1);

      // Revoke the object URL if it exists
      if (removedFile.url) {
        URL.revokeObjectURL(removedFile.url);
      }
      setDocuments(newDocuments);
    } else {
      console.error("File or Document not found");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleBackButton = () => {
    navigate("/mobilebdmpage");
  };

  return (
    <>
      {loading && <Loader open={loading} />}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
          sx={{
            "& .Mui-expanded": {
              margin: "10px 0 !important",
            },
          }}
        >
          <Typography>Lead Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <Grid container spacing={2} p={3}>
              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Customer Name:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.CustomerName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Mobile Number:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.MobileNo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Alternate Mobile Number:
                </Typography>

                <Typography variant="body2">
                  {storedLeadDetails.AlternateMobileNo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Whatsapp Number:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.WhatsappNo}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={"flex"}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Customer Mail ID:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.CustomerMailId}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Postal Pincode:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.pincode}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  State:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.state_name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Region Name:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.region_name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  City/Village/Location:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.location}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Site Location Address:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.site_location_address}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Follow-up Date:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.follow_up_date === "-"
                    ? "-"
                    : storedLeadDetails.follow_up_date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Lead Transfer Date:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.lead_transfer_date === "-"
                    ? "-"
                    : storedLeadDetails.lead_transfer_date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Campaign:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.source_of_lead_generated}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} xl={6} className={`flex`}>
                <Typography
                  variant="body2"
                  className={`${leadFormStyles.label_margin}`}
                >
                  Lead Shared By:
                </Typography>
                <Typography variant="body2">
                  {storedLeadDetails.lead_owner}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${leadFormStyles.accordian_heading} h-auto`}
          sx={{
            "& .Mui-expanded": {
              margin: "10px 0 !important",
            },
          }}
        >
          <Typography>Converted Form</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ justifyContent: "center" }}>
          <form onSubmit={handleSubmit}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              {/* <thead>
                                <tr>
                                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>SNo</th>
                                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Document</th>
                                    <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Action</th>
                                </tr>
                            </thead> */}
              <tbody>
                {documents.map((document, index) => (
                  <tr key={index} style={{ height: "90px" }}>
                    <td
                      data-label="SNo"
                      style={{ padding: "8px", width: "3%" }}
                    >
                      {index + 1}.
                    </td>
                    <td
                      data-label="Document"
                      style={{ padding: "8px", width: "30%" }}
                    >
                      {document.name}{" "}
                      {document.isMandatory && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </td>
                    <td
                      data-label="Action"
                      style={{ padding: "8px", width: "50%" }}
                    >
                      {document.type === "input" && (
                        <TextField
                          variant="outlined"
                          value={document.file[0] || ""}
                          onChange={(event) => {
                            if (/^\d*$/.test(event.target.value)) {
                              const newDocuments = [...documents];
                              newDocuments[index].file[0] = event.target.value;
                              setDocuments(newDocuments);
                            }
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      )}
                      {document.type === "textarea" && (
                        <TextField
                          multiline
                          rows={4}
                          variant="outlined"
                          value={document.file[0] || ""}
                          onChange={(event) => {
                            const newDocuments = [...documents];
                            newDocuments[index].file[0] = event.target.value;
                            setDocuments(newDocuments);
                          }}
                        />
                      )}
                      {document.type === "upload" && (
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {document.file.length === 0 ? (
                            <>
                              <Button
                                variant="contained"
                                component="label"
                                sx={{
                                  minWidth: "20px",
                                  // minHeight: '100px',
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <UploadFileIcon sx={{ fontSize: 20 }} />
                                {/* Upload */}
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  accept="application/pdf, image/jpeg, image/png"
                                  onChange={(event) =>
                                    handleFileChange(index, event)
                                  }
                                />
                              </Button>
                              {/* Helper Text */}
                              <Typography variant="caption" sx={{ ml: 2 }}>
                                Max file size: 1MB. Only PDF, JPEG, PNG formats
                                are allowed.
                              </Typography>
                            </>
                          ) : (
                            <Box>
                              {document.file.map((file, fileIndex) => (
                                <Stack
                                  key={fileIndex}
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography
                                    variant={"caption"}
                                    style={{
                                      display: "inline-block",
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {file.name}
                                  </Typography>
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveFile(index, fileIndex)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Stack>
                              ))}
                            </Box>
                          )}
                        </Stack>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Snackbar will auto hide after 6 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConvertedActionComponent;
