import { Routes, Route } from 'react-router-dom';
import { useState , useEffect ,useContext} from 'react';
import Dashboard from '../DashboardComponent';
import MainComponent from '../MainComponent';
import LeadComponent from '../Leads Page/leadComponent';
import HotDispositionSummaryComponent from '../Hot Disposition Summary/hotDispositionSummaryComponent';
import WarmDispositionSummaryComponent from '../Warm Disposition Summary/warmDispositionSummaryComponent';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BDMAssignedComponent from '../BDM Assigned Page/BDMAssignedComponent';
import BdmToDoComponent from '../ToDoList/TodoList';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import { LoginContext } from '../LoginPage/LoginContext';
import LeadDetailsPage from '../Leads Page/LeadDetailsPage';
import BDMDetailsPage from '../BDM Assigned Page/bdmDetailsPage';
import SiteVisitPageComponent from '../SupervisorDashboard/SiteVisitPage';
import AllLeadsPage from '../SupervisorDashboard/AllLeadspage';
import MasterDataComponent from '../SupervisorDashboard/MasterDataComponent';
import AuditLeadListComponent from '../Audit/AuditLeadList';
import AuditDetailsPageComponent from '../Audit/AuditDetailsPage';
import CallerPage from '../Dialer/CallerPage';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import MobileMainComponent from '../MobileMainComponent';
import { useNavigate } from 'react-router-dom';
import MobileBDMDetailsPage from '../MobileView/MobileBDMDetailsPage';
import EstimationComponent from '../HOExecutive/EstimationComponent';
import EstimationDetailsPageComponent from '../HOExecutive/EstimationDetailsPage';
import AuditTabComponent from '../Audit/AuditTabComponent';
import AuditMasterDataComponent from '../AuditSupervisor/AuditMasterData';
import ConvertedActionComponent from '../BDM Assigned Page/ConvertedActionComponent';
import LeadTabComponent from '../Leads Page/LeadTabComponent';
import MobileConvertedComponent from '../MobileView/MobileConveretedComponent';
import DemoComponent from '../BDM Assigned Page/demoComponent';
import ConvertedViewPage from '../BDM Assigned Page/ConvertedViewPage';
import BdmTabComponent from '../BDM Assigned Page/BdmTabComponent';

import ActionWiseLeadComponent from '../ActionWiseLeads/ActionWiseLeadComponen';
import TraderListComponent from '../Audit/TraderListComponent';
import AuditAllList from '../Audit/AuditAllList';
import AuditMasterList from '../AuditSupervisor/AuditMasterList';
import MasterListTableComponent from '../SupervisorDashboard/MasterListTable';
import BiDetailsPageComponent from '../BiAgent/BiDetailsPage';
import BroodingDetailsPageComponent from '../BiAgent/BiBoilerDetailsPage.jsx';

import BiDayopDetailsComponent from '../BiAgent/BiDetails';
import BiTabComponent from '../BiAgent/BiTabComponent';
import BiTraderListComponent from '../BiAgent/TraderListComponent';
import BiMasterDataComponent from '../BiSupervisor/BiMasterData';
import BiMasterBroodingComponent from '../BiSupervisor/BiMasterBrooding.jsx';
import BdmReportComponent from '../BDMReport/BdmReportComponent.jsx';
import QRReportComponent from '../QRFormReport/QRReportComponent.jsx';
import BdmDetailsComponent from '../BDMReport/BdmDetailsPage.jsx';
import SettingsComponent from '../Settings/SettingsComponent.jsx';
import EnquiryLeadsPageComponent from '../EnquiryLeadsPage/EnquiryLeadsPageComponent.jsx';
import ZonalManagerComponent from '../ZonalManager/ZonalManagerComponent.jsx';



const PrivateRoutes = () => {
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const { isLoggedIn, logout } = useContext(LoginContext);

  
    const handleLogin = (username) => {
      setUsername(username);
      // Additional login logic (e.g., store tokens in localStorage)
    };
  
    const handleLogout = (username) => {
      setRole('');
      // Additional logout logic (e.g., clear tokens from localStorage)
      // logout();
      // navigate('/');
    };

  

 return(  
 
      <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isMobile ? (
        
        <MobileMainComponent onLogout={handleLogout}></MobileMainComponent>
       
        ) : (<MainComponent onLogout={handleLogout} />)}
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
      {/* <DrawerHeader /> */}
      <Toolbar />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          {localStorage.getItem("empRole") == 3 ? (<Route path="/lead" element={<ZonalManagerComponent />} />) :(<Route path="/lead" element={<LeadTabComponent />} />)}
          <Route path="/hotDisposition" element={<HotDispositionSummaryComponent />} />
          <Route path="/warmDisposition" element={<WarmDispositionSummaryComponent />} />
          <Route path="/bdmAssigned" element={<BdmTabComponent />} />
          <Route path="/profile" element={<ProfileComponent />} />
          <Route path="/leadDetails" element={<LeadDetailsPage />} />
          <Route path="/bdmDetails" element={<BDMDetailsPage />} />
          {/* <Route path="/site_visit" element={<SiteVisitPageComponent />} /> */}
          <Route path="/leadFiltered" element={<AllLeadsPage />} />
          <Route path="/leadMaster" element={<MasterListTableComponent />} />
          <Route path="/leadAction" element={<AllLeadsPage />} />
          <Route path="/auditLead" element={<AuditTabComponent />} />
          <Route path="/auditLeadDetails" element={<AuditDetailsPageComponent />} />
          <Route path='/mobilebdmpage' element={<MobileBDMDetailsPage />} />
          <Route path='/estimateGeneration' element={<EstimationComponent />} />
          <Route path='/estimateDetails' element={<EstimationDetailsPageComponent />} />
          <Route path='/auditMaster' element={<AuditMasterList />} />
          <Route path='/bdmDetails/converted' element={<ConvertedActionComponent />} />
          <Route path='/bdmDetails/converted/view' element={<ConvertedViewPage />} />
          <Route path='/mobilebdmpage/converted' element={<MobileConvertedComponent />} />
          <Route path='/mobilebdmpage/converted/view' element={<ConvertedViewPage />} />
          <Route path='/leadActionWise' element={<ActionWiseLeadComponent />} />
          <Route path='/auditTraderList' element={<TraderListComponent />} />
          <Route path="/bdmtodo" element={<BdmToDoComponent />} />

          <Route path="/biLead" element={<BiTabComponent />} />
          <Route path="/biLeadDetails" element={<BiDetailsPageComponent />} />
          <Route path="/broodingLeadDetails" element={<BroodingDetailsPageComponent />} />
          <Route path="/biLeaddayop" element={<BiDayopDetailsComponent />} />
          <Route path='/biMaster' element={<BiMasterDataComponent />} />
          <Route path='/biMasterBrooding' element={<BiMasterBroodingComponent />} />
          <Route path='/biTraderList' element={<BiTraderListComponent />} />

          <Route path='/bdmReport' element={<BdmReportComponent />} />
          <Route path='/bdmReport/details/:bdm_id' element={<BdmDetailsComponent />} />
          <Route path='/qrReport' element={<QRReportComponent />} />
          <Route path='/settings' element={<SettingsComponent />} />

          <Route path='/enquiry_data' element={<EnquiryLeadsPageComponent></EnquiryLeadsPageComponent>} />
        </Routes>
        </Box>
        </Box>
  );
}

export default PrivateRoutes;