import { Box, Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";

function EditMasterDetails() {
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Customer Name"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Mobile Number"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Alternate Mobile Number"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Whatsapp Number"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Customer Mail ID"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Postal Pincode"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField label="State" id="outlined-size-small" size="small" />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Region Name"
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={6}
            sx={{ display: "flex", placeContent: "center" }}
          >
            <TextField
              label="Site Location Address"
              id="outlined-size-small"
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default EditMasterDetails;
