import React, { useEffect, useState } from "react";
import {
  IconButton,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Grid,
  Autocomplete,
  Typography,
  Drawer,
  Toolbar,
  Paper,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {
  EXCEL_EXPORT_ALL_DATA_SUPERVISOR,
  GET_CAMPAIGN_NAME,
  GET_CATEGORY,
  GET_INQUIRY_TYPE,
  GET_PROJECT,
  GET_REGION_LIST,
} from "../../constants";
import FilterListIcon from "@mui/icons-material/FilterList"; // Add this import for the filter icon
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";

const FilterPanel = ({ filters, setFilters, onApplyFilters }) => {
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer visibility

  const handleInputChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value instanceof dayjs ? formatDate(value) : value,  // Check if the value is a dayjs object
    }));
  };

  const ApplyClearAll = () => {
    const clearedFilters = {
      InquiryType: "",
      Project: "",
      CustomerName: "",
      MobileNo: "",
      region: "",
      category: "",
      subcategory: "",
      campaignName: "",
      search: "",
    };

    // Reset all Autocomplete components to null manually
    setFilters(clearedFilters);

    onApplyFilters(clearedFilters);

    setDrawerOpen(false); // Close the drawer after clearing filters
  };

  // Function to check if any filter is active
  const isFilterActive = () => {
    // return Object.values(filters).some((value) => value !== "");
  };

  const formatDate = (dateString) => moment(dateString).format("DD-MM-YYYY");

  return (
    <div>
      <div style={{ float: "right" }}>
        {/* Filter Toggle Button with Badge */}
        <Badge
          color="secondary"
          variant="dot"
          invisible={!isFilterActive()}
          sx={{
            "& .MuiBadge-dot": {
              width: "10px", // Adjust badge width
              height: "10px", // Adjust badge height
              borderRadius: "50%", // Make it circular
              right: 0,
              top: "10px",
            },
          }}
        >
          <IconButton
            onClick={() => setDrawerOpen((prev) => !prev)}
            style={{
              border: "1px solid #fff", // Change border color to white for contrast
              borderRadius: "4px",
              margin: "5px",
              width: "40px", // Increased width for a more comfortable click area
              height: "40px", // Increased height for a more comfortable click area
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#097969",
              color: "#fff",
              transition: "background-color 0.3s, transform 0.2s", // Smooth transition effects
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#0B9B73"; // Darker shade on hover
              e.currentTarget.style.transform = "scale(1.05)"; // Slightly scale up on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#097969"; // Original color
              e.currentTarget.style.transform = "scale(1)"; // Reset scale
            }}
          >
            {drawerOpen ? <FilterListOffIcon /> : <FilterListIcon />}{" "}
            {/* Change icon based on state */}
          </IconButton>
        </Badge>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)} // Close drawer on backdrop click
        sx={{
          width: 320,
          overflowY: "auto",
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Filter Options
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </Toolbar>

       <div style={{
            padding: 16,
            overflowY: "auto",
            maxHeight: "calc(100vh - 64px)",
          }}>
          {/* Filter Panel */}
          <Grid
            container
            spacing={1}
            style={{ marginBottom: 16 }}
            direction="column"
          >
            {/* Call Status Dropdown */}
            <Grid item xs={12}>
              <TextField
                select
                label="Call Status"
                fullWidth
                value={filters.call_status}
                onChange={(e) =>
                  handleInputChange("call_status", e.target.value)
                }
              >
                <MenuItem value="connected">Connected</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>\
              </TextField>
            </Grid>

            {/* From Date Picker */}
            <Grid item xs={12}>
              <DatePicker
                label="From Date"
                name="fromDate"
                value={filters.fromDate ? dayjs(filters.fromDate) : null}
                format="DD/MM/YYYY"
                onChange={(newDate) => handleInputChange("fromDate", newDate)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            {/* To Date Picker */}
            <Grid item xs={12}>
              <DatePicker
                label="To Date"
                name="toDate"
                value={filters.toDate ? dayjs(filters.toDate) : null}
                format="DD/MM/YYYY"
                onChange={(newDate) => handleInputChange("toDate", newDate)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            {/* Sort By Dropdown */}
            <Grid item xs={12}>
              <TextField
                select
                label="Sort By"
                fullWidth
                value={filters.sortBy}
                onChange={(e) => handleInputChange("sortBy", e.target.value)}
              >
                <MenuItem value="createdAt">Date of First Calling</MenuItem>
                <MenuItem value="updatedAt">Last Action Date</MenuItem>
              </TextField>
            </Grid>

            {/* Sort Order Dropdown */}
            <Grid item xs={12}>
              <TextField
                select
                label="Sort Order"
                fullWidth
                value={filters.sortOrder}
                onChange={(e) => handleInputChange("sortOrder", e.target.value)}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </TextField>
            </Grid>

            {/* Apply Filters Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>{ 
                  onApplyFilters(filters);
                  setDrawerOpen(false);
                }}
                sx={{ width: "100%" }}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
          </div>
      </Drawer>
    </div>
  );
};

export default FilterPanel;
