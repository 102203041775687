import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MenuItem, FormControl, Select , useMediaQuery, useTheme} from '@mui/material';
import AccordianComponent from './accordianComponent';
import LeadAccordianComponent from '../Leads Page/leadAccordianComponent';
import BdmFormComponent from '../BDM Assigned Page/bdmForm';
import MeetingFormComponent from '../BDM Assigned Page/meetingForm';
import EstimationFormComponent from '../BDM Assigned Page/estimationForm';
import SiteVisitFormComponent from '../BDM Assigned Page/siteVisitForm';
import BdmAddLead from '../BDM Assigned Page/BdmAddLead';
import AddLeadForm from '../BDM Assigned Page/AddLeadForm';
import AgentAddLeadForm from '../Leads Page/AddLeadForm';


function ModalComponent({open, modalTitle, setOpen, rowDetails, setRowDetails,  setOpenSnackbar, setSeverity, setMessage , rowData, setRunEffect, setalertTitle}) {

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false);
        }
    };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const [count, setCount]= useState(0);
  const getCount = ()=> {
    setCount(rowData.length)
  }

  return (
    <>
    <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown
            fullScreen={fullScreen} // Adjusts dialog size based on screen width
            maxWidth="sm" // Limits maximum width of the dialog
            fullWidth // Ensures dialog takes full width of the screen
        >
            <DialogTitle sx={{
                background: (theme) => theme.palette.primary.main,
                color: '#fff',
            }} >{modalTitle}</DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#fff',
            }}
            >
            <CloseIcon />
            </IconButton>
                {/* {modalTitle == 'Lead Generation' ? (<LeadAccordianComponent rowDetails={rowDetails} />) : (<AccordianComponent modalTitle={modalTitle} />) } */}
                {(() => {
                    if (modalTitle === 'Lead Generation'){
                        return(
                            // <LeadAccordianComponent rowDetails={rowDetails} setRowDetails={setRowDetails} setOpen={setOpen} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setMessage={setMessage} setRunEffect={setRunEffect} />
                            <AgentAddLeadForm rowDetails={rowDetails} setRowDetails={setRowDetails} setOpen={setOpen} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setMessage={setMessage} setRunEffect={setRunEffect}></AgentAddLeadForm>
                        );
                    } else if (modalTitle == 'On Call Discussion'){
                        return(
                            <BdmFormComponent rowDetails={rowDetails} setOpen={setOpen} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setMessage={setMessage}/>
                        );
                    } else if (modalTitle == 'Meeting Details') {
                        return(
                            <MeetingFormComponent  rowDetails={rowDetails} setOpen={setOpen}/>
                        );
                    } else if (modalTitle == 'Estimation Details') {
                        return(
                            <EstimationFormComponent rowDetails={rowDetails} setOpen={setOpen} />
                        );
                    }else if (modalTitle == 'Site Visit Details') {
                        return(
                            <SiteVisitFormComponent rowDetails={rowDetails} setOpen={setOpen} />
                        );
                    } else if(modalTitle == 'BDM Lead Generation'){
                        return(
                            // <BdmAddLead rowDetails={rowDetails} setRowDetails={setRowDetails} setOpen={setOpen} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setMessage={setMessage} setRunEffect={setRunEffect}></BdmAddLead>
                            <AddLeadForm rowDetails={rowDetails} setRowDetails={setRowDetails} setOpen={setOpen} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setMessage={setMessage} setRunEffect={setRunEffect}></AddLeadForm>
                        );
                    }
                })()}  
           
        </Dialog>
         
    </>
  );
}

export default ModalComponent;