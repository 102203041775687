import React, { useEffect, useState } from "react";
import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  DialogActions,
  useMediaQuery,
  styled,
  Chip,
  Checkbox,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  ADD_ZONAL_MANAGER,
  GET_ALL_REGION,
  GET_ALL_ZONAL_MANAGER,
  PROJECT,
  ZONAL_MANAGER_STATUS,
} from "../../constants";
import axios from "axios";

const FancySwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 30,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 2,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
        border: "none",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
    width: 26,
    height: 26,
    borderRadius: 13,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
  },
}));

function ZMComponent() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionRow, setActionRow] = useState(null);

  const [regionList, setRegionList] = useState([]);
  const [zmList, setZMList] = useState([]);

  const [addOpen, setAddOpen] = useState(false);
  const [employeeName, setEmployeeName] = useState({});
  const [regionName, setRegionName] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [projectName, setProjectName] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchAllRegion = async () => {
    try {
      const response = await axios.get(GET_ALL_REGION);
      setRegionList(response.data.data);
    } catch (error) {
      console.error("Error disconnecting call:", error);
    }
  };

  const fetchAllZonalManager = async () => {
    try {
      const response = await axios.get(GET_ALL_ZONAL_MANAGER);
      setZMList(response.data.data);
    } catch (error) {
      console.error("Error disconnecting call:", error);
    }
  };

  useEffect(() => {
    fetchAllRegion();
    fetchAllZonalManager();
  }, []);

  const handleActionClick = (row) => {
    setActionRow(row);
    setConfirmOpen(true);
  };

  const handleConfirmAction = async () => {
    console.log(actionRow);

    // Simulate status change
    const payload = {
      EmployeeId: actionRow.employeeId,
      RegionId: actionRow.regionId,
      is_active: actionRow.isActive === "Active" ? "Inactive" : "Active",
    };
    try {
      const response = await axios.post(ZONAL_MANAGER_STATUS, payload);

      // Check the application-level success flag
      if (response.data.success) {
        setSeverity("success");
        setMessage(response.data.message);
        setOpenSnackbar(true);
        // Optionally update the list if required
        // setZMList(response.data.data);
        handleReset();
        setConfirmOpen(false);
      } else {
        // Handle application-level errors
        setSeverity("error");
        setMessage(response.data.message || "Something went wrong!");
        setOpenSnackbar(true);
        handleReset();
        setConfirmOpen(false);
      }
    } catch (error) {
      console.error("Error :", error);
      // Handle HTTP-level errors
      setSeverity("error");
      setMessage(
        error.response?.data?.message || "An unexpected error occurred."
      );
      setOpenSnackbar(true);
      handleReset();
      setConfirmOpen(false);
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setActionRow(null);
  };

  const handleSaveAdd = async () => {
    const payload = {
      EmployeeName: employeeName.employeeName, // Extract employeeName
      EmployeeId: employeeName.employeeId, // Extract employeeId
      RegionIds: regionName.map((region) => region.RegionId), // Send RegionId array
    };

    try {
      const response = await axios.post(ADD_ZONAL_MANAGER, payload);

      if (response.data.success) {
        setSeverity("success");
        setMessage(response.data.message);
        setOpenSnackbar(true);
        handleReset();
        setAddOpen(false);
      } else {
        setSeverity("error");
        setMessage(response.data.message || "Something went wrong!");
        setOpenSnackbar(true);
        handleReset();
        setAddOpen(false);
      }
    } catch (error) {
      console.error("Error :", error);
      setSeverity("error");
      setMessage(
        error.response?.data?.message || "An unexpected error occurred."
      );
      setOpenSnackbar(true);
      handleReset();
      setAddOpen(false);
    }
  };

  const handleEditClick = () => {
    alert("You have clicked on edit");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleReset = () => {
    fetchAllZonalManager();
    setEmployeeId("");
    setEmployeeName({});
    setRegionName([]);
    setActionRow(null);
  };

  return (
    <>
      {/* Add Dialog */}
      <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        fullScreen={fullScreen} // Adjusts dialog size based on screen width
      >
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: "#fff",
            padding: "8px 24px",
          }}
        >
          Add Zonal Manager
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setAddOpen(false)}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Employee Name"
            name="employeeName"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            margin="normal"
            size="small"
          >
            {zmList
              .reduce((unique, current) => {
                if (
                  !unique.some((item) => item.employeeId === current.employeeId)
                ) {
                  unique.push(current);
                }
                return unique;
              }, [])
              .map((ls) => (
                <MenuItem
                  key={ls.employeeId}
                  value={ls} // Store the entire employee object
                  sx={{
                    borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  {ls.employeeName}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Project"
            name="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            margin="normal"
            size="small"
          >
            {PROJECT
              .map((ls) => (
                <MenuItem
                  key={ls.value}
                  value={ls} // Store the entire employee object
                  sx={{
                    borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  {ls.label}
                </MenuItem>
              ))}
          </TextField>

          <Autocomplete
            multiple
            id="region-select"
            options={regionList} // Replace with the provided region data array
            getOptionLabel={(option) => option.RegionName}
            value={regionName} // Selected regions state
            onChange={(event, newValue) => setRegionName(newValue)} // Update state
            isOptionEqualToValue={(option, value) =>
              option.RegionId === value.RegionId
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Regions"
                margin="normal"
                size="small"
                placeholder="Select Regions"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setAddOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveAdd} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          Are you sure you want to{" "}
          <strong>
            {actionRow?.status === "Active" ? "disable" : "enable"}
          </strong>{" "}
          Zonal Manager {actionRow?.employeeName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ padding: 3 }}>
        {/* Heading and Button Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Zonal Manager List
          </Typography>
          <Button
            variant="contained"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
            onClick={() => setAddOpen(true)}
          >
            <AddIcon />
            Add Zonal Manager
          </Button>
        </Box>

        {/* Table Section */}
        <Paper sx={{ boxShadow: 3, borderRadius: 2 }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 600,
                tableLayout: "fixed",
                "& .MuiTableHead-root": {
                  backgroundColor: "#f5f5f5",
                  color: "#000",
                },
                "& .MuiTableCell-root": {
                  fontSize: "0.875rem",
                  textAlign: "center",
                  padding: "12px",
                },
                "& .MuiTableRow-root:hover": { backgroundColor: "#f5f5f5" },
              }}
              aria-label="fancy table"
            >
              <TableHead>
                <TableRow>
                  <TableCell width={50} align="left">
                    SNo
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell width={80} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zmList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.employeeName || '-'}</TableCell>
                    <TableCell>{row.employeeId || '-'}</TableCell>
                    <TableCell>{row.regionName || '-'}</TableCell>
                    <TableCell>{row.projectName || '-'}</TableCell>
                    <TableCell>
                      <Chip
                        label={
                          row.isActive === "Active" ? "Active" : "InActive"
                        }
                        size="small"
                        color={row.isActive === "Active" ? "success" : "error"}
                        sx={{ margin: "2px" }}
                      />
                    </TableCell>
                    <TableCell>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip
                        title={
                          row.isActive === "Active"
                            ? "Click to deactivate"
                            : "Click to activate"
                        }
                        arrow
                      >
                        <IconButton
                          color={
                            row.isActive === "Active" ? "primary" : "error"
                          }
                          onClick={() => handleActionClick(row)}
                        >
                          {row.isActive === "Active" ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <BlockIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                      <IconButton onClick={() => handleEditClick(row)}>
                        <EditIcon />
                      </IconButton>
                      </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Snackbar will auto hide after 3 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ZMComponent;
