import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import './mobileDatatableStyles.css';


function MobileRemarkDatatable({data}) {

  
  useEffect(() => {
  
    // Initialize DataTable
    const tableId = '#example';
      
    // Destroy any existing DataTable instance before reinitializing
    if ($.fn.DataTable.isDataTable(tableId)) {
      $(tableId).DataTable().clear().destroy();
    }

      const table = $('#example').DataTable({
        responsive: true,
        data: data ,
        columns: [
        { data: 'sno', title: 'SNo.' },
        { data: 'createdAt', title: 'Created Date'},
        { data: 'follow_up_date', title: 'Follow-up Date' },
        { data: 'closure_month', title: 'Closure Month' },
        { data: 'category', title: 'Category' },
        { data: 'sub_category', title: 'Sub Category' },
        { data: 'remark', title: 'Remarks' },
        { data: 'AddedBy', title: 'Added By' },
           
      ],
        rowReorder: {
          selector: 'td:nth-child(2)'
        }
        ,
        // scrollY: '400px',
      scrollCollapse: true
      });

      var dtLengthElement = document.querySelector('.dt-length');
      var dtSearchElement = document.querySelector('.dt-search');
        if (dtLengthElement) {
            // Get the outer div
            var outerDiv = dtLengthElement.closest('.col-md-auto.me-auto');
            var outerDiv1 = dtSearchElement.closest('.col-md-auto.ms-auto ');
            outerDiv.style.width = '10%';
            outerDiv1.style.width = '70%';
            // Now you can do something with the outerDiv
            console.log(outerDiv1); // This will log the outer <div> element
        }

      // Cleanup function to destroy the DataTable instance when the component unmounts
      return () => {
       table.destroy();
      };
    
    
  }, [data]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/2.0.8/css/dataTables.dataTables.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/rowreorder/1.5.0/css/rowReorder.dataTables.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/responsive/3.0.2/css/responsive.dataTables.css"
        />
        <style>
          {`
            /* Hide the label "Entries per page" */
            .dt-length label {
              display: none;
            }
            /* Hide the label "Search" */
            .dt-search label {
              display: none;
            }

            .dt-container .dt-search {
                 margin-top: 0px !important;
            }
            
          `}
        </style>
      </Helmet>
      <div className='dt-layout-row dt-layout-table' >
        <div className='dt-layout-cell '>
          <table id="example" className="table table-striped table-hover table-bordered" style={{ width: '100%', position: 'relative', fontSize: '0.8rem' }}>
            <thead>
              <tr>
                <th>SNo.</th>
                <th>Created Date</th>
                <th>Follow-up Date</th>
                <th>Closure Month</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Remarks</th>
                <th>Added By</th>
              </tr>
            </thead>
          </table>
      </div>
      </div>
    </>
  );
}

export default MobileRemarkDatatable;