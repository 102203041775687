import * as React from "react";
import { useState } from "react";
import BdmListComponent from "./BdmListComponent";
import Loader from "../reusable Component/Loader";
import { Typography } from "@mui/material";

function BdmReportComponent() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <Loader open={loading} />}

      
      <BdmListComponent setLoading={setLoading}></BdmListComponent>
    </>
  );
}

export default BdmReportComponent;
