import React, { useState } from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { GET_ALL_REGION } from "../../constants";
import axios from "axios";
import { useEffect } from "react";

function RegionComponent() {
  const [regionList, setRegionList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionRow, setActionRow] = useState(null);
  const [editData, setEditData] = useState({ RegionName: "" });
  const [editOpen, setEditOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [regionName, setRegionName]= useState("");

  const fetchAllRegion = async () => {
    try {
      const response = await axios.get(GET_ALL_REGION);
      setRegionList(response.data.data);
    } catch (error) {
      console.error("Error disconnecting call:", error);
    }
  };

  useEffect(() => {
    fetchAllRegion();
  }, []);

  const handleActionClick = (row) => {
    setActionRow(row);
    setConfirmOpen(true);
  };

  const handleConfirmAction = () => {
    // Simulate status change
    actionRow.status = actionRow.status === "Active" ? "Inactive" : "Active";
    setConfirmOpen(false);
    setActionRow(null);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setActionRow(null);
  };

  const handleEditClick = (row) => {
    setEditData(row);
    setEditOpen(true);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSaveEdit = () => {
    console.log(editData);
  };

  const handleSaveAdd = () => {
    console.log(regionName);
    
  };

  return (
    <>
    {/* Add Dialog */}
    <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        fullScreen={fullScreen} // Adjusts dialog size based on screen width
      >
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: "#fff",
            padding: "8px 24px",
          }}
        >
          Edit Region
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setAddOpen(false)}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            fullWidth
            label="Region Name"
            name="RegionName"
            value={regionName}
            onChange={(e) =>
              setRegionName(e.target.value)
            }
            margin="normal"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setAddOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveAdd} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        fullScreen={fullScreen} // Adjusts dialog size based on screen width
      >
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: "#fff",
            padding: "8px 24px",
          }}
        >
          Edit Region
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setEditOpen(false)}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            fullWidth
            label="Region Name"
            name="RegionName"
            value={editData?.RegionName || ""}
            onChange={(e) =>
              setEditData((prevData) => ({
                ...prevData,
                RegionName: e.target.value, // Correctly updating the 'name' field
              }))
            }
            margin="normal"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setEditOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          Are you sure you want to{" "}
          <strong>
            {actionRow?.status === "Active" ? "disable" : "enable"}
          </strong>{" "}
          the region {actionRow?.RegionName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ padding: 3 }}>
        {/* Heading and Button Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Region List
          </Typography>
          <Button
            variant="contained"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
            onClick={() => setAddOpen(true)}
          >
            <AddIcon />
            Add Region
          </Button>
        </Box>

        {/* Table Section */}
        <Paper sx={{ boxShadow: 3, borderRadius: 2 }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 600,
                tableLayout: "fixed",
                "& .MuiTableHead-root": {
                  backgroundColor: "#f5f5f5",
                  color: "#000",
                },
                "& .MuiTableCell-root": {
                  fontSize: "0.875rem",
                  textAlign: "center",
                  padding: "12px",
                },
                "& .MuiTableRow-root:hover": { backgroundColor: "#f5f5f5" },
              }}
              aria-label="fancy table"
            >
              <TableHead>
                <TableRow>
                  <TableCell width={50} align="left">
                    SNo
                  </TableCell>
                  <TableCell>Region Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell width={80} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {regionList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.RegionName}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          row.status === "Active"
                            ? "Click to deactivate"
                            : "Click to activate"
                        }
                      >
                        <IconButton
                          color={row.status === "Active" ? "primary" : "error"}
                          onClick={() => handleActionClick(row)}
                        >
                          {row.status === "Active" ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <BlockIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(row)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}

export default RegionComponent;
