import React from 'react';
import CallerComponent from './callerComponent';
import { Grid } from '@mui/material';


function CallerPage() {
return(
    <>
    <Grid container spacing={2}  className={`mb-4`} wrap='wrap'>
    <Grid item xs={6} md={3} xl={4}>
    <CallerComponent></CallerComponent>
    </Grid>
    </Grid>
    </>
);
}

export default CallerPage;