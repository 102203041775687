import React from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState } from "react";
import { useTheme } from "@emotion/react";

const demoData = [
  {
    sno: 1,
    name: "John Doe",
    employeeId: "EMP1001",
    source: "Expo",
    status: "Active",
  },
  {
    sno: 2,
    name: "Jane Smith",
    employeeId: "EMP1002",
    source: "Expo",
    status: "Inactive",
  },
  {
    sno: 3,
    name: "Alice Brown",
    employeeId: "EMP1003",
    source: "Expo",
    status: "Active",
  },
  {
    sno: 4,
    name: "Bob Johnson",
    employeeId: "EMP1004",
    source: "Expo",
    status: "Active",
  },
  {
    sno: 5,
    name: "Charlie Lee",
    employeeId: "EMP1005",
    source: "Expo",
    status: "Inactive",
  },
];

function CSEComponent() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionRow, setActionRow] = useState(null);

  // Open edit dialog
  const handleEditClick = (row) => {
    setEditData(row);
    setEditOpen(true);
  };

  // Handle field changes in the edit dialog
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  // Save the updated data
  const handleSaveEdit = () => {
    console.log(editData);

    setEditOpen(false);
    setEditData(null);
  };

  const handleActionClick = (row) => {
    setActionRow(row);
    setConfirmOpen(true);
  };

  const handleConfirmAction = () => {
    // Simulate status change
    actionRow.status = actionRow.status === "Active" ? "Inactive" : "Active";
    setConfirmOpen(false);
    setActionRow(null);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setActionRow(null);
  };

  return (
    <>
      {/* Edit Dialog */}
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        fullScreen={fullScreen} // Adjusts dialog size based on screen width
      >
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: "#fff",
            padding: "8px 24px",
          }}
        >
          Edit CSE
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setEditOpen(false)}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={editData?.name || ""}
            onChange={handleFieldChange}
            margin="normal"
            size="small"
          />
          <TextField
            fullWidth
            label="Source"
            name="source"
            value={editData?.source || ""}
            onChange={handleFieldChange}
            margin="normal"
            size="small"
            select
          >
            {["Expo", "Expo2", "Expo3"].map((source) => (
              <option key={source} value={source}>
                {source}
              </option>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setEditOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

       {/* Confirmation Dialog */}
       <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          Are you sure you want to{" "}
          <strong>
            {actionRow?.status === "Active" ? "disable" : "enable"}
          </strong>{" "}
          the CSE {actionRow?.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
          marginTop: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          CSE List
        </Typography>
      </Box>

      <Paper sx={{ boxShadow: 3, borderRadius: 2 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 600,
              tableLayout: "fixed",
              "& .MuiTableHead-root": {
                backgroundColor: "#f5f5f5",
                color: "#000",
              },
              "& .MuiTableCell-root": {
                fontSize: "0.875rem",
                textAlign: "center",
                padding: "12px",
              },
              "& .MuiTableRow-root:hover": { backgroundColor: "#f5f5f5" },
            }}
            aria-label="fancy table"
          >
            <TableHead>
              <TableRow>
                <TableCell width={50} sx={{ fontWeight: "bold" }}>
                  SNo
                </TableCell>
                <TableCell width={200} sx={{ fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell width={150} sx={{ fontWeight: "bold" }}>
                  Employee ID
                </TableCell>
                <TableCell width={150} sx={{ fontWeight: "bold" }}>
                  Source
                </TableCell>
                <TableCell width={100} sx={{ fontWeight: "bold" }}>
                  Status
                </TableCell>
                <TableCell width={100} sx={{ fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {demoData.map((item) => (
                <TableRow key={item.sno}>
                  <TableCell>{item.sno}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.employeeId}</TableCell>
                  <TableCell>{item.source}</TableCell>
                  <TableCell>
                    <Chip
                      label={item.status}
                      color={item.status === "Active" ? "success" : "error"}
                      size="small"
                      sx={{ textTransform: "capitalize", borderRadius: "5px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          sx={{
                            color: "info.main",
                            "&:hover": { color: "info.dark" },
                            border: "1px solid",
                            borderRadius: "4px",
                            padding: "2px",
                            width: "24px",
                            height: "24px",
                            marginRight: "5px",
                          }}
                          onClick={() => handleEditClick(item)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={item.status === "Active" ? "Disable" : "Enable"}
                        arrow
                      >
                        <IconButton
                          onClick={() => handleActionClick(item)}
                          sx={{
                            color:
                              item.status === "Active"
                                ? "error.main"
                                : "success.main",
                            border: "1px solid",
                            borderRadius: "4px",
                            padding: "2px",
                            width: "24px",
                            height: "24px",
                          }}
                        >
                          {item.status === "Active" ? (
                            <BlockIcon fontSize="small" />
                          ) : (
                            <CheckCircleOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default CSEComponent;
