import React, {useContext, useState, useEffect} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import biFormStyles from './BiFromStyles.module.css';
import { Divider, Stack, Grid, Button, Snackbar, Card } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { GET_BI_REMARK_LIST, POST_BI_REMARK } from "../../constants";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { MenuItem, FormControl, Select , useMediaQuery, useTheme, IconButton} from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Loader from '../reusable Component/Loader';
import LongTextComponent from '../Leads Page/LongTextComponent';
import StatusChangeDialog from "./StatusChangeDialog";

function BroodingDetailsPageComponent() {

    const storedLeadDetails = JSON.parse(localStorage.getItem('leadDetails'));
    const empDetails = JSON.parse(localStorage.getItem('userData'));

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate(); 

    const initialFormValues = {
        Branch: '',
        Branch_Description: '',
        Farm_Name: '',
        Farmer_Mob: '',
        Lot_Number: '',
        Age: '',
        Chicks_Housed_Quantity: '',
        Mortality_Quantity: '',
        Mortality_Percentage: '',
        Balance_Birds: '',
        Mort_Percentage_On_Date: '',
        Mort_Date_1: '',
        Mort_Date_2: '',
        Mort_Date_3: '',
        Mort_Date_4: '',
        Remarks: ''
      };

    const [formValues, setFormValues] = useState({
        Branch: '',
        Branch_Description: '',
        Farm_Name: '',
        Farmer_Mob: '',
        Lot_Number: '',
        Age: '',
        Chicks_Housed_Quantity: '',
        Mortality_Quantity: '',
        Mortality_Percentage: '',
        Balance_Birds: '',
        Mort_Percentage_On_Date: '',
        Mort_Date_1: '',
        Mort_Date_2: '',
        Mort_Date_3: '',
        Mort_Date_4: '',
        Remarks: ''
      
      });
    
      const [formErrors, setFormErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };

      const handleDateChange = (newDate) => {
        setFormValues({
          ...formValues,
          DATE: newDate
        });
      };

    //   const validate = () => {
    //     const errors = {};
    
    //     if (!formValues.CH) errors.CH = 'Chicks Housed is required';
    //     if (!formValues.AGE) errors.AGE = 'Mean Age is required';
    //     if (!formValues.BWT) errors.BWT = 'Body Weight is required';
    //     if (!formValues.M_QTY) errors.M_QTY = 'Mortality Quantity is required';
    //     if (!formValues.REASON) errors.REASON = 'Reason is required';
    //     if (!formValues.MED) errors.MED = 'Medicine is required';
    //     if (!formValues.FEED) errors.FEED = 'Feed is required';
    //     if (!formValues.STOCK) errors.STOCK = 'Stock is required';
    //     if (!formValues.IFFT_IN) errors.IFFT_IN = 'IFFT In is required';
    //     if (!formValues.IFFT_OUT) errors.IFFT_OUT = 'IFFT Out is required';
    //     if (!formValues.LS_VISIT) errors.LS_VISIT = 'LS Visit is required';
    //     if (!formValues.BM_VISIT) errors.BM_VISIT = 'BM Visit is required';
    //     if (!formValues.DAILY_ENT) errors.DAILY_ENT = 'Daily Ent is required';
    //     if (!formValues.FEED_ENT) errors.FEED_ENT = 'Feed Ent is required';
    //     if (!formValues.MORT_ENT) errors.MORT_ENT = 'Mortality Ent is required';
    //     if (!formValues.BWT_ENT) errors.BWT_ENT = 'BWT Ent is required';
    //     if (!formValues.MED_ENT) errors.MED_ENT = 'Medicine Ent is required';
    //     if (!formValues.REMARKS) errors.REMARKS = 'Remarks is required';
    //     if (!formValues.DATE) errors.DATE = 'Date is required';
    
    //     setFormErrors(errors);
    //     return Object.keys(errors).length === 0;
    //   };

      const onSubmit = (e) => {
        // e.preventDefault();
        // if (validate()) {
          
          axios.post(POST_BI_REMARK, formValues, {
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }})
            .then(response => {
                setSeverity('success');
                setAlertTitle(response.data.message);
                setOpenSnackbar(true);
                resetForm();
                setRunEffect(true);
            }).catch(error => {
                setSeverity('error');
                setAlertTitle(error.message);
                setOpenSnackbar(true);
                resetForm();
            });
        
      };

      // To reset formValues to initial state
        const resetForm = () => {
            setFormValues(initialFormValues);
        };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

      const [row, setRow] = useState([]);

      const columns = [
        { field: 'SNo', headerName: 'S.NO', width: 50, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Zone', headerName: 'ZONE', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Region', headerName: 'REGION', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Branch', headerName: 'BRANCH', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Lot_Number', headerName: 'LOT NO', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Farmer_Name', headerName: 'FARMER NAME', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Age', headerName: 'AGE', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Shed_Type', headerName: 'SHED TYPE (EC/OPEN)', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Chicks_Housed_Quantity', headerName: 'CHICKS PLANNED/HOUSED', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Farmer_Contact_No', headerName: 'FARMER CONTACT NO', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
        { field: 'Lot_Status', headerName: 'LOT STATUS', width: isMobile ? 120 : 150, type: 'string', headerAlign: 'center', responsive: true },
    ];
    

    const [runEffect, setRunEffect] = useState(false);

    useEffect(()=>{
        setLoading(true);
    axios.get(`${GET_BI_REMARK_LIST}`,{
        headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }})
    .then(response => {
        const {biLeadRemarks} = response.data;
        handleRowData(biLeadRemarks);
        setLoading(false);
        setRunEffect(false);
    }).catch(error => {
        // setSeverity('error');
        //     setAlertTitle(error.message);
        //     setOpenSnackbar(true);
        setLoading(false);
        setRunEffect(false);
    });

    },[runEffect]);

    const handleRowData = (data) =>{
        const transformedRows = [];
        var a = {};
        data.map((rd, index)=>{
            a = {
            "id": index + 1 ,
            "SNo": index + 1,
            "CH": rd.CH,
            "AGE": rd.AGE,
            "BWT": rd.BWT,
            "M_QTY": rd.M_QTY,
            "REASON": rd.REASON,
            "MED": rd.MED,
            "FEED": rd.FEED,
            "STOCK": rd.STOCK,
            "IFFT_IN": rd.IFFT_IN,
            "IFFT_OUT": rd.IFFT_OUT,
            "LS_VISIT": rd.LS_VISIT,
            "BM_VISIT": rd.BM_VISIT,
            "DAILY_ENT": rd.DAILY_ENT,
            "FEED_ENT": rd.FEED_ENT,
            "MORT_ENT": rd.MORT_ENT,
            "BWT_ENT": rd.BWT_ENT,
            "MED_ENT": rd.MED_ENT,
            "REMARKS": rd.REMARKS,
            "DATE": rd.DATE,
            "AgentId":rd.AgentId,
            "AgentName": rd.Agent.EmployeeName,
            "Lot_Number": rd.Lot_Number,
            "createdAt": new Date(rd.createdAt),
            "updatedAt": new Date(rd.updatedAt)
        }
        transformedRows.push(a);
        })
        setRow(transformedRows);
    }

    const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
    const [status, setStatus] = useState(''); // To store the selected status

    const handleOpenDialog = (e) => {
        e.preventDefault();
        setDialogOpen(true); // Open the dialog on submit
    };
    
    return (
        <>
        {loading && <Loader open={loading} />}
        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${biFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Lead Details</Typography>
        </AccordionSummary>
            <AccordionDetails>
            <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            >

<Grid container spacing={2} p={3}>
    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Branch:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Branch}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Branch Description:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Branch_Description}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Farm Name:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Farm_Name}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Farmer Mobile:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Farmer_Mob}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Lot Number:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Lot_Number}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Age:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Age}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Chicks Housed Quantity:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Chicks_Housed_Quantity}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality Quantity:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mortality_Quantity}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality Percentage:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mortality_Percentage}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Balance Birds:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Balance_Birds}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality On Date:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mort_Percentage_On_Date}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality Date 1:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mort_Percentage_Date_1}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality Date 2:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mort_Percentage_Date_2}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality Date 3:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mort_Percentage_Date_3}
        </Typography>
    </Grid>

    <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Mortality Date 4:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Mort_Percentage_Date_4}
        </Typography>
    </Grid>

    {/* <Grid item xs={12} md={4} xl={6} className={`flex`}>
        <Typography variant='body2' className={`${biFormStyles.label_margin}`}>
            Remarks:
        </Typography>
        <Typography variant='body2'>
            {storedLeadDetails.Remarks}
        </Typography>
    </Grid> */}
</Grid>

            </Box>
        </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${biFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Remarks List</Typography>
        </AccordionSummary>
            <AccordionDetails>

            <DataGrid 
                rows={row} 
                columns={columns} 
                columnHeaderHeight={50}  
                getRowHeight={() => 'auto'} 
                autoHeight={row.length < 10}
                disableExtendRowFullWidth
                // hideFooterPagination={theme.breakpoints.down('md')}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true }
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                    pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                    },
                }} 
                
                disableRowSelectionOnClick
                disableDensitySelector
                sx={{
                    height: '400px',
                    '& .MuiDataGrid-cell': {
                    textAlign: 'center',
                    placeContent: 'center',
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#EEF0F4'
                    },
                    '& .super-app-theme--header':{
                        // width: isMobile == true ? '200px !important' : '150px !important',
                        // maxWidth: isMobile == true ? '200px !important' : '150px !important'
                    },
                }} 
                />    

            </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`${biFormStyles.accordian_heading} h-auto`}
        >
        <Typography>Remark Form</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Card>
    <form>
        <Grid container spacing={2} p={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Brooding Type"
                    name="brooding_type"
                    value={formValues.brooding_type}
                    onChange={handleChange}
                    error={!!formErrors.brooding_type}
                    helperText={formErrors.brooding_type}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Space per Chick"
                    name="space_per_chick"
                    value={formValues.space_per_chick}
                    onChange={handleChange}
                    error={!!formErrors.space_per_chick}
                    helperText={formErrors.space_per_chick}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Heating Source Drum/Bhukari"
                    name="heating_source_drum"
                    value={formValues.heating_source_drum}
                    onChange={handleChange}
                    error={!!formErrors.heating_source_drum}
                    helperText={formErrors.heating_source_drum}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Heating Source Gas"
                    name="heating_source_gas"
                    value={formValues.heating_source_gas}
                    onChange={handleChange}
                    error={!!formErrors.heating_source_gas}
                    helperText={formErrors.heating_source_gas}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Heating Source Tin"
                    name="heating_source_tin"
                    value={formValues.heating_source_tin}
                    onChange={handleChange}
                    error={!!formErrors.heating_source_tin}
                    helperText={formErrors.heating_source_tin}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Heating Source Diesel"
                    name="heating_source_diesel"
                    value={formValues.heating_source_diesel}
                    onChange={handleChange}
                    error={!!formErrors.heating_source_diesel}
                    helperText={formErrors.heating_source_diesel}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Total"
                    name="total"
                    value={formValues.total}
                    onChange={handleChange}
                    error={!!formErrors.total}
                    helperText={formErrors.total}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="No of Chicks Drinker Required"
                    name="no_of_chicks_drinker"
                    value={formValues.no_of_chicks_drinker}
                    onChange={handleChange}
                    error={!!formErrors.no_of_chicks_drinker}
                    helperText={formErrors.no_of_chicks_drinker}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="No of Chicks Feeders Required"
                    name="no_of_chicks_feeders"
                    value={formValues.no_of_chicks_feeders}
                    onChange={handleChange}
                    error={!!formErrors.no_of_chicks_feeders}
                    helperText={formErrors.no_of_chicks_feeders}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Curtain Availability Fall Ceiling"
                    name="curtain_availability_fall_ceiling"
                    value={formValues.curtain_availability_fall_ceiling}
                    onChange={handleChange}
                    error={!!formErrors.curtain_availability_fall_ceiling}
                    helperText={formErrors.curtain_availability_fall_ceiling}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Double Side Curtain"
                    name="double_side_curtain"
                    value={formValues.double_side_curtain}
                    onChange={handleChange}
                    error={!!formErrors.double_side_curtain}
                    helperText={formErrors.double_side_curtain}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Thermometer Drum/Bhukari"
                    name="thermometer_drum"
                    value={formValues.thermometer_drum}
                    onChange={handleChange}
                    error={!!formErrors.thermometer_drum}
                    helperText={formErrors.thermometer_drum}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Thermometer Gas"
                    name="thermometer_gas"
                    value={formValues.thermometer_gas}
                    onChange={handleChange}
                    error={!!formErrors.thermometer_gas}
                    helperText={formErrors.thermometer_gas}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Thermometer Tin"
                    name="thermometer_tin"
                    value={formValues.thermometer_tin}
                    onChange={handleChange}
                    error={!!formErrors.thermometer_tin}
                    helperText={formErrors.thermometer_tin}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Thermometer Diesel"
                    name="thermometer_diesel"
                    value={formValues.thermometer_diesel}
                    onChange={handleChange}
                    error={!!formErrors.thermometer_diesel}
                    helperText={formErrors.thermometer_diesel}
                    size="small"
                    fullWidth
                />
            </Grid>

            <Box display="flex" justifyContent="flex-end" p={3}>
                <Button type="submit" variant="contained" onClick={(e) => handleOpenDialog(e)}>
                    Save
                </Button>
            </Box>
        </Grid>
    </form>
</Card>

</AccordionDetails>

        </Accordion>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {/* <AlertTitle>{alertTitle}</AlertTitle> */}
            {alertTitle}
        </Alert>
        </Snackbar>

        <StatusChangeDialog open={dialogOpen} setOpen={setDialogOpen} formValues={formValues} setFormValues={setFormValues} setOpenSnackbar={setOpenSnackbar} setSeverity={setSeverity} setAlertTitle={setAlertTitle} resetForm={resetForm} setRunEffect={setRunEffect} empDetails={empDetails} storedLeadDetails={storedLeadDetails} ></StatusChangeDialog>
        </>
    );

}

export default BroodingDetailsPageComponent;