import React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {
  Divider,
  Stack,
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AddIcon from "@mui/icons-material/Add";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonalDetailsFormComponent from "../BDM Assigned Page/personalDetailsForm";
import leadFormStyles from "../Leads Page/leadFormStyles.module.css";
import bdmFormStyles from "./bdmFormStyles.module.css";
import Button from "@mui/material/Button";
import { MODEL_TYPE, NO_OF_SHEDS, SIDE_WALL_COLUMN_GAP } from "../../constants";
import DispositionModalComponent from "../reusable Component/DispositionModalComponent";
import { useMediaQuery, useTheme, Chip } from "@mui/material";

function EstimationFormComponent({
  rowDetails,
  setEstimationForm,
  finalEstimationRow,
  finalGenEstimationRow,
  finalSharedEstimationRow,
  finalConvertedEstimationRow,
  leadDetailsId,
  setSeverity,
  setMessage,
  setOpenSnackbar,
  setRunEffect,
}) {
  const [selectedValue, setSelectedValue] = useState("yes");

  const [modelType, setModelType] = useState("");
  const [sideWall, setSideWall] = useState("");
  const [noOfShed, setNoOFShed] = useState("");
  const [estimationReq, setEstimationReq] = useState("");
  const [civilConstruction, setCivilConstruction] = useState("");
  const [shedWidth, setShedWidth] = useState("");
  const [cShape, setCShape] = useState("");
  const [curtainReq, setCurtainReq] = useState("");
  const [dieselBrooder, setDieselBrooder] = useState("");
  const [waterSupply, setWaterSupply] = useState("");
  const [powerSupply, setPowerSupply] = useState("");
  const [remark, setRemark] = useState("");
  const [firmName, setFirmName] = useState("");
  const [lastEstNum, setLastEstNum] = useState("");
  const [isRevised, setIsRevised] = useState(false);

  const [modelTypeError, setModelTypeError] = useState("");
  const [sideWallError, setSideWallError] = useState("");
  const [noOfShedError, setNoOFShedError] = useState("");
  const [estimationReqError, setEstimationReqError] = useState("");
  const [civilConstructionError, setCivilConstructionError] = useState("");
  const [shedWidthError, setShedWidthError] = useState("");
  const [cShapeError, setCShapeError] = useState("");
  const [curtainReqError, setCurtainReqError] = useState("");
  const [dieselBrooderError, setDieselBrooderError] = useState("");
  const [waterSupplyError, setWaterSupplyError] = useState("");
  const [powerSupplyError, setPowerSupplyError] = useState("");
  const [remarkError, setRemarkError] = useState("");
  const [firmNameError, setFirmNameError] = useState("");
  const [lastEstNumError, setLastEstNumError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeEstimationReq = (e) => {
    setEstimationReq(e.target.value);
    if (e.target.value == "revised") {
      setIsRevised(true);
    } else {
      setIsRevised(false);
      setLastEstNum(""); // Reset lastEstNum when not revised
      setLastEstNumError("");
    }
  };

  const handleChangeCivilConstruction = (e) => {
    setCivilConstruction(e.target.value);
  };

  const [error, setError] = useState(false);
  const handleChangeShedWidth = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setShedWidth(value);
      if (value === "" || (Number(value) >= 20 && Number(value) <= 49)) {
        setShedWidthError("");
      } else {
        setShedWidthError("Value must be between 20 and 49");
      }
    }
  };

  const [shedLength, setShedLength] = useState("");
  const [equipmentPlacement, setEquipmentPlacement] = useState("");
  const [shedLengthError, setShedLengthError] = useState(false);

  const handleShedLengthChange = (e) => {
    const value = e.target.value;

    // Allow only numeric input
    if (/^\d*$/.test(value)) {
      setShedLength(value);

      // Validate the value as the user types
      const lengthValue = Number(value);
      if (lengthValue >= 150 && lengthValue <= 390) {
        setShedLengthError(false);
        const equipmentPlacementValue = Math.max(lengthValue - 15, 0); // Ensure it doesn't go below 0
        setEquipmentPlacement(equipmentPlacementValue.toString());
      } else {
        setShedLengthError(true);
        setEquipmentPlacement(""); // Clear equipment placement if invalid
      }
    }
  };

  const handleChangeCShape = (e) => {
    console.log(e.target.value);
    setCShape(e.target.value);
  };

  const handleCurtainRequirment = (e) => {
    setCurtainReq(e.target.value);
  };

  const handleDieselBrooder = (e) => {
    setDieselBrooder(e.target.value);
  };

  const handlePowerSupply = (e) => {
    setPowerSupply(e.target.value);
  };

  const handleWaterSupply = (e) => {
    setWaterSupply(e.target.value);
  };

  const handleChangeRemark = (e) => {
    setRemark(e.target.value);
    if (e.target.value.length > 250) {
      setRemarkError("Remarks cannot exceed 250 characters");
    } else {
      setRemarkError("");
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setEstimationForm(false);
    // if(viewMode){
    // handleViewMode(false);}
  };
  const [open, setOpen] = useState(false);
  const [estimateFormData, setEstimateFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset all error states
    setModelTypeError("");
    setSideWallError("");
    setNoOFShedError("");
    setEstimationReqError("");
    setCivilConstructionError("");
    setShedWidthError("");
    setCShapeError("");
    setCurtainReqError("");
    setDieselBrooderError("");
    setWaterSupplyError("");
    setPowerSupplyError("");
    setRemarkError("");
    setFirmNameError("");
    setLastEstNumError("");
    setShedLengthError(false);

    let isValid = true;

    // Validate Model Type
    if (!modelType) {
      setModelTypeError("Model Type is required");
      isValid = false;
    }

    // Validate Side Wall
    if (!sideWall) {
      setSideWallError("Side wall column gap is required");
      isValid = false;
    }

    // Validate Number of Sheds
    if (!noOfShed) {
      setNoOFShedError("Number of sheds is required");
      isValid = false;
    }

    // Validate Estimation Requirement
    if (!estimationReq) {
      setEstimationReqError("Estimation requirement is required");
      isValid = false;
    }

    // Validate Civil Construction Started
    if (civilConstruction === "") {
      setCivilConstructionError("Civil Construction status is required");
      isValid = false;
    }

    // Validate Shed Length
    if (!shedLength) {
      setShedLengthError(true);
      isValid = false;
    } else if (Number(shedLength) < 150 || Number(shedLength) > 390) {
      setShedLengthError(true);
      isValid = false;
    }

    // Validate Shed Width
    if (!shedWidth) {
      setShedWidthError("Shed Width is required");
      isValid = false;
    } else if (Number(shedWidth) < 20 || Number(shedWidth) > 49) {
      setShedWidthError("Value must be between 20 and 49");
      isValid = false;
    }

    // Validate C-Shape
    if (cShape === "") {
      setCShapeError("C-Shape requirement is required");
      isValid = false;
    }

    // Validate Curtain Requirement
    if (curtainReq === "") {
      setCurtainReqError("Curtain requirement is required");
      isValid = false;
    }

    // Validate Diesel Brooder Requirement
    if (dieselBrooder === "") {
      setDieselBrooderError("Diesel Brooder requirement is required");
      isValid = false;
    }

    // Validate Water Supply
    if (waterSupply === "") {
      setWaterSupplyError("Water supply status is required");
      isValid = false;
    }

    // Validate Power Supply
    if (!powerSupply) {
      setPowerSupplyError("Power supply is required");
      isValid = false;
    }

    // Validate Firm/Farmer Name
    if (!firmName.trim()) {
      setFirmNameError("Firm/Farmer Name is required");
      isValid = false;
    }

    // Validate Last Estimation Number if Revised
    if (isRevised) {
      if (!lastEstNum.trim()) {
        setLastEstNumError(
          "Last Estimation Number is required for revised estimates"
        );
        isValid = false;
      }
    }

    // Validate Remarks
    if (remark.length > 250) {
      setRemarkError("Remarks cannot exceed 250 characters");
      isValid = false;
    }

    if (remark === "") {
      setRemarkError("Remark is Required");
      isValid = false;
    }

    if (!isValid) {
      // If the form is not valid, do not proceed
      return;
    }

    // If all validations pass, prepare the form data
    const formData = {
      LeadDetailId: leadDetailsId, // Ensure this is defined in your component
      BDMId: localStorage.getItem("username"),
      ShedLength: shedLength,
      ShedWidth: shedWidth,
      ModalType: modelType,
      SideWallColumnToColumnGap: sideWall,
      EquipementPlacementLength: equipmentPlacement,
      NumberOfSheds: noOfShed,
      EstimationRequirement: estimationReq,
      firm_farmer_name: firmName,
      estimationNumber: isRevised ? lastEstNum : null,
      CivilConstructedStarted: civilConstruction === "true",
      CShape: cShape === "true",
      CurtainRequirment: curtainReq === "true",
      DiselBrooderRequirment: dieselBrooder === "true",
      PowerSupply: powerSupply,
      WaterSupply: waterSupply === "true",
      Remarks: remark,
      status: "Pending",
    };

    console.log(formData);

    // Proceed with form submission logic (e.g., API call)
    setEstimateFormData(formData);
    setOpen(true);
  };

  const resetForm = () => {
    setCivilConstruction(null);
    setShedLength("");
    setShedWidth(null);
    setModelType("");
    setSideWall(null);
    setEquipmentPlacement("");
    setNoOFShed("");
    setEstimationReq("");
    setCShape(null);
    setCurtainReq(null);
    setDieselBrooder(null);
    setPowerSupply(null);
    setWaterSupply(null);
    setRemark("");
    setFirmName("");
  };

  const shouldDisableRadioButton = () => {
    return finalEstimationRow != undefined
      ? finalEstimationRow.some(
          (record) =>
            record.status === "Pending" || record.status === "Need for Approval"
        )
      : false; // Return false if finalEstimationRow is undefined or empty
  };

  const isDisabled = shouldDisableRadioButton();

  // Function to gather estimation numbers
  const getEstimationNumbers = () => {
    const allRows = [
      ...(finalEstimationRow || []),
      ...(finalGenEstimationRow || []),
      ...(finalSharedEstimationRow || []),
      ...(finalConvertedEstimationRow || []),
    ];
    const estimationNumbers = allRows
      .filter((record) => record.estimationNumber) // Ensure the estimation_number exists
      .map((record) => record.estimationNumber); // Extract estimation_numbers
    return [...new Set(estimationNumbers)]; // Return unique values
  };

  const estimationNumbers = getEstimationNumbers();

  return (
    <>
      <Card className={`${bdmFormStyles.form_card_style} mt-2`}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" }, // Adjust width to 100% for better responsiveness
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid container spacing={isMobile ? 0 : 2} p={isMobile ? 0 : 3}>
            {/* Estimation Requirement */}
            <Grid item xs={12} md={4} xl={6}>
              <Box className="px-2">
                <Typography variant="body2">
                  Estimation Requirement <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Estimation Requirement"
                  name="estimationReq"
                  value={estimationReq}
                  onChange={handleChangeEstimationReq}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label="New"
                    disabled={
                      finalEstimationRow?.length > 0 ||
                      finalGenEstimationRow?.length > 0 ||
                      finalSharedEstimationRow?.length > 0 ||
                      finalConvertedEstimationRow?.length > 0
                        ? true
                        : false
                    } // Update disabled condition as needed
                  />
                  <FormControlLabel
                    value="revised"
                    control={<Radio />}
                    label="Revised"
                    disabled={isDisabled} // Corrected condition
                  />
                </RadioGroup>
                {estimationReqError && (
                  <Typography variant="caption" color="error">
                    {estimationReqError}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Last Estimation Number */}
            {isRevised && (
              <Grid item xs={12} md={4} xl={6}>
                <TextField
                  select
                  label="Last Estimation Number"
                  value={lastEstNum}
                  onChange={(e) => setLastEstNum(e.target.value)}
                  size="small"
                  error={!!lastEstNumError}
                  helperText={lastEstNumError}
                  required
                >
                  {estimationNumbers.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {/* Firm/Farmer Name */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Firm/Farmer Name"
                id="outlined-size-small"
                value={firmName}
                onChange={(e) => setFirmName(e.target.value)}
                size="small"
                error={!!firmNameError}
                helperText={firmNameError}
                required
              />
            </Grid>

            {/* Civil Construction Started */}
            <Grid item xs={12} md={4} xl={6}>
              <Box className="px-2">
                <Typography variant="body2">
                  Civil Construction Started{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Civil Construction Started"
                  name="civilConstruction"
                  value={civilConstruction}
                  onChange={handleChangeCivilConstruction}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {civilConstructionError && (
                  <Typography variant="caption" color="error">
                    {civilConstructionError}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Shed Length */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Shed Length (Range 150-390Ft)"
                id="outlined-size-small"
                value={shedLength}
                onChange={handleShedLengthChange}
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={shedLengthError}
                helperText={
                  shedLengthError ? "Value must be between 150 and 390" : ""
                }
                required
              />
            </Grid>

            {/* Equipment Placement Length */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Equipment Placement Length (Shed Length - 15)"
                id="outlined-size-small"
                value={equipmentPlacement}
                size="small"
                inputProps={{ readOnly: true }}
                required
              />
            </Grid>

            {/* Shed Width */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                label="Shed Width (Range 20-49ft)"
                id="outlined-size-small"
                value={shedWidth}
                onChange={handleChangeShedWidth}
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={!!shedWidthError}
                helperText={shedWidthError}
                required
              />
            </Grid>

            {/* C-Shape */}
            <Grid item xs={12} md={4} xl={6}>
              <Box className="px-2">
                <Typography variant="body2">
                  C-Shape (Mandatory for above 300'L & 40'W){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="C-Shape Requirement"
                  name="cShape"
                  value={cShape}
                  onChange={handleChangeCShape}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {cShapeError && (
                  <Typography variant="caption" color="error">
                    {cShapeError}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Model Type */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                id="outlined-select-model-type"
                select
                label="Model Type"
                value={modelType}
                onChange={(e) => setModelType(e.target.value)}
                size="small"
                error={!!modelTypeError}
                helperText={modelTypeError}
                required
              >
                {MODEL_TYPE.map((mt) => (
                  <MenuItem
                    key={mt.label}
                    value={mt.label}
                    sx={{
                      borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {mt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Side Wall Column Gap */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                id="outlined-select-side-wall"
                select
                label="Side wall column to column gap (Range 8-12Ft)"
                value={sideWall}
                onChange={(e) => setSideWall(e.target.value)}
                size="small"
                error={!!sideWallError}
                helperText={sideWallError}
                required
              >
                {SIDE_WALL_COLUMN_GAP.map((sw) => (
                  <MenuItem
                    key={sw.value}
                    value={sw.value}
                    sx={{
                      borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {sw.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Number of Sheds */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                id="outlined-select-no-of-sheds"
                select
                label="Number of Sheds"
                value={noOfShed}
                onChange={(e) => setNoOFShed(e.target.value)}
                size="small"
                error={!!noOfShedError}
                helperText={noOfShedError}
                required
              >
                {NO_OF_SHEDS.map((ns) => (
                  <MenuItem
                    key={ns.value}
                    value={ns.value}
                    sx={{
                      borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {ns.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Curtain Requirement */}
            <Grid item xs={12} md={4} xl={6}>
              <Box className="px-2">
                <Typography variant="body2">
                  Curtain Requirement <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Curtain Requirement"
                  name="curtainReq"
                  value={curtainReq}
                  onChange={handleCurtainRequirment}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {curtainReqError && (
                  <Typography variant="caption" color="error">
                    {curtainReqError}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Diesel Brooder Requirement */}
            <Grid item xs={12} md={4} xl={6}>
              <Box className="px-2">
                <Typography variant="body2">
                  Diesel Brooder Requirement{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Diesel Brooder Requirement"
                  name="dieselBrooder"
                  value={dieselBrooder}
                  onChange={handleDieselBrooder}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {dieselBrooderError && (
                  <Typography variant="caption" color="error">
                    {dieselBrooderError}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Power Supply */}
            <Grid item xs={12} md={4} xl={6}>
              <TextField
                id="outlined-select-power-supply"
                select
                label="Power Supply"
                value={powerSupply}
                onChange={handlePowerSupply}
                size="small"
                error={!!powerSupplyError}
                helperText={powerSupplyError}
                required
              >
                <MenuItem
                  value="single_phase"
                  sx={{
                    borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  Single Phase
                </MenuItem>
                <MenuItem
                  value="three_phase"
                  sx={{
                    borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  Three Phase
                </MenuItem>
              </TextField>
            </Grid>

            {/* Water Supply */}
            <Grid item xs={12} md={4} xl={6}>
              <Box className="px-2">
                <Typography variant="body2">
                  Water Available <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="Water Supply"
                  name="waterSupply"
                  value={waterSupply}
                  onChange={handleWaterSupply}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                {waterSupplyError && (
                  <Typography variant="caption" color="error">
                    {waterSupplyError}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Remarks */}
            <Grid item xs={12} md={4} xl={6}>
              <Box>
                <TextField
                  id="outlined-multiline-static"
                  label="Remarks"
                  multiline
                  rows={4}
                  value={remark}
                  onChange={handleChangeRemark}
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={!!remarkError}
                  helperText={remarkError || `${remark.length}/250`}
                  fullWidth
                  required
                />
              </Box>
            </Grid>
          </Grid>

          {/* Submit Buttons */}
          <Box
            className={`float-right p-2`}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 2,
            }}
          >
            {!isMobile && (
              <Button
                onClick={handleClose}
                variant="contained"
                className={`${bdmFormStyles.button_space_style}`}
              >
                Cancel
              </Button>
            )}
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      </Card>

      <DispositionModalComponent
        open={open}
        setOpen={setOpen}
        estimateFormData={estimateFormData}
        setSeverity={setSeverity}
        setMessage={setMessage}
        setOpenSnackbar={setOpenSnackbar}
        setEstimationForm={setEstimationForm}
        formType={"estimate"}
        setRunEffect={setRunEffect}
        resetForm={resetForm}
      />
    </>
  );
}

export default EstimationFormComponent;
